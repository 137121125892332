import { tdvCellphoneMask, removeSpecialCharactersAndNumbers } from 'utils/forms';

const baseMasks = {
  removeSpecialCharactersAndNumbers: (event) => {
    const value = event.target.value;
    event.target.value = removeSpecialCharactersAndNumbers(value);
  }
};

const fields = {
  'es-VE': {
    name: {
      displayLabel: true,
      mask: baseMasks['removeSpecialCharactersAndNumbers']
    },

    lastName: {
      displayLabel: true,
      mask: baseMasks['removeSpecialCharactersAndNumbers']
    },

    customerId: {
      displayLabel: true,
      mask: null
    },

    cellphone: {
      displayLabel: true,
      mask: (event) => {
        const value = event.target.value;
        event.target.value = tdvCellphoneMask(value);
      }
    },

    email: {
      displayLabel: true,
      mask: null
    },

    vehicleIdentificationNumber: {
      displayLabel: true,
      displayModal: true,
      modalImage: '/images/recall/tdv_vin.png',
      displaySubmitSearchButton: false,
      mask: null
    },

    submitButton: true
  },

  'es-AR': {
    vehicleIdentificationNumber: {
      displayLabel: false,
      displayModal: true,
      modalImage: '/images/recall/tasa_vin.png',
      displaySubmitSearchButton: true,
      mask: null
    },

    submitButton: false
  },

  'pt-BR': {
    vehicleIdentificationNumber: {
      displayLabel: false,
      displayModal: true,
      modalImage: '/images/recall/tdb_vin.png',
      displaySubmitSearchButton: true,
      mask: null
    },

    submitButton: false
  }
};

export default fields;
