import * as React from 'react';

const SvgIconDeleteOutline = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.3077 20.4998C6.81058 20.4998 6.38503 20.3228 6.03103 19.9688C5.67701 19.6148 5.5 19.1892 5.5 18.6921V5.99981H5.25C5.0375 5.99981 4.85938 5.9279 4.71563 5.78408C4.57188 5.64028 4.5 5.46208 4.5 5.24948C4.5 5.0369 4.57188 4.85882 4.71563 4.71523C4.85938 4.57163 5.0375 4.49983 5.25 4.49983H8.99998C8.99998 4.25497 9.08619 4.04632 9.25863 3.87388C9.43106 3.70145 9.63971 3.61523 9.88458 3.61523H14.1154C14.3602 3.61523 14.5689 3.70145 14.7413 3.87388C14.9138 4.04632 15 4.25497 15 4.49983H18.75C18.9625 4.49983 19.1406 4.57174 19.2843 4.71556C19.4281 4.85938 19.5 5.03758 19.5 5.25016C19.5 5.46276 19.4281 5.64085 19.2843 5.78443C19.1406 5.92802 18.9625 5.99981 18.75 5.99981H18.5V18.6921C18.5 19.1892 18.3229 19.6148 17.9689 19.9688C17.6149 20.3228 17.1894 20.4998 16.6923 20.4998H7.3077ZM17 5.99981H6.99997V18.6921C6.99997 18.7818 7.02883 18.8556 7.08653 18.9133C7.14423 18.971 7.21795 18.9998 7.3077 18.9998H16.6923C16.782 18.9998 16.8557 18.971 16.9134 18.9133C16.9711 18.8556 17 18.7818 17 18.6921V5.99981ZM10.1542 16.9998C10.3668 16.9998 10.5448 16.9279 10.6884 16.7842C10.832 16.6404 10.9038 16.4623 10.9038 16.2498V8.74979C10.9038 8.5373 10.8319 8.35918 10.6881 8.21543C10.5443 8.07168 10.3661 7.99981 10.1535 7.99981C9.9409 7.99981 9.76281 8.07168 9.61923 8.21543C9.47564 8.35918 9.40385 8.5373 9.40385 8.74979V16.2498C9.40385 16.4623 9.47576 16.6404 9.61958 16.7842C9.76338 16.9279 9.94158 16.9998 10.1542 16.9998ZM13.8465 16.9998C14.0591 16.9998 14.2371 16.9279 14.3807 16.7842C14.5243 16.6404 14.5961 16.4623 14.5961 16.2498V8.74979C14.5961 8.5373 14.5242 8.35918 14.3804 8.21543C14.2366 8.07168 14.0584 7.99981 13.8458 7.99981C13.6332 7.99981 13.4551 8.07168 13.3115 8.21543C13.1679 8.35918 13.0961 8.5373 13.0961 8.74979V16.2498C13.0961 16.4623 13.168 16.6404 13.3118 16.7842C13.4557 16.9279 13.6339 16.9998 13.8465 16.9998Z"
      fill="#EB0A1E"
    />
  </svg>
);

export default SvgIconDeleteOutline;
