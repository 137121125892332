import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import LoaderOverlay from 'components/molecules/loader-overlay';
import { IconCheck } from 'components/icons';
import { useMediaQuery } from 'react-responsive';
import i18n from 'utils/i18n';
import { useServiceAppointment, ACTIONS } from 'providers/service-appointment';
import { getVehiclesForCustomer } from 'utils/functions/appointment';
import { updatePageParam } from 'utils/forms';
import { useRouter } from 'next/router';

import css from './styles.module.scss';

/**
 * Molecule ServiceAppointmentVehicleStep
 *
 * <!-- TODO: add a description here! -->
 */
function ServiceAppointmentVehicleStep(props) {
  const t = i18n.useTranslations('components.service-appointment-vehicle-step');
  const router = useRouter();
  const { state, dispatch } = useServiceAppointment();
  const { className = '', onNextStep, ...other } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const listVehicles = state?.customerVehicles;

  const [vehiclesWithAppointments, setVehiclesWithAppointments] = useState([]);
  const [vehiclesWithoutAppointments, setVehiclesWithoutAppointments] = useState([]);
  const [selectedVehicleId, setSelectedVehicleId] = useState(state.customerVehicleId);
  const [isServicesStepDisabled, setIsServicesStepDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showAllVehicles, setShowAllVehicles] = useState(false);
  const vehicleRef = useRef(null);

  const fetchAllMyVehicles = async () => {
    setLoading(true);
    try {
      const vehicles = await getVehiclesForCustomer();

      if (Array.isArray(vehicles) && vehicles.length > 0) {
        dispatch({ type: ACTIONS.SET_CUSTOMER_VEHICLES, payload: vehicles });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllMyVehicles();
  }, []);

  useEffect(() => {
    if (listVehicles.length > 0) {
      const withAppointments = listVehicles.filter(vehicle => vehicle.appointments && vehicle.appointments.length > 0);
      const withoutAppointments = listVehicles.filter(vehicle => !vehicle.appointments || vehicle.appointments.length === 0);

      if (selectedVehicleId && withAppointments) {
        const selectedVehicle = withAppointments.find(vehicle => vehicle.id === selectedVehicleId);
        if (selectedVehicle) {
          const index = withAppointments.indexOf(selectedVehicle);
          withAppointments.splice(index, 1);
          withAppointments.unshift(selectedVehicle);
        }
      }
      setVehiclesWithAppointments(withAppointments);
      setVehiclesWithoutAppointments(withoutAppointments);
    }

    if (listVehicles.length === 1) {
      setSelectedVehicleId(listVehicles[0].id);
      dispatch({ type: ACTIONS.SET_SELECTED_VEHICLE_INFO, payload: listVehicles[0] });
    }
  }, [listVehicles]);

  useLayoutEffect(() => {
    setIsServicesStepDisabled(state?.listSteps?.find((step) => step?.type === 'SERVICES')?.disabled ?? true);
  }, [state.listSteps]);

  const handleSelectVehicle = (vehicle) => {
    if (listVehicles.length > 1) {
      if (vehicle?.id === selectedVehicleId) {
        setSelectedVehicleId(null);
      } else {
        setSelectedVehicleId(vehicle?.id);
      }
    }
    dispatch({ type: ACTIONS.SET_SELECTED_VEHICLE_NAME, payload: vehicle?.name });
  };

  const handleCommonConfirmation = (vehicle) => {
    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: ACTIONS.SET_SELECTED_VEHICLE_INFO, payload: vehicle });
    dispatch({ type: ACTIONS.SET_CUSTOMER_VEHICLE_ID, payload: vehicle?.id });
    onNextStep();
  };

  const handleConfirmation = (vehicleId) => {
    const selectedVehicle = listVehicles?.find((vehicle) => vehicle.id === vehicleId);


    if (selectedVehicle) {
      handleCommonConfirmation(selectedVehicle);
    }
  };

  function getImageUrl(vehicle, isMobile) {
    return (
      (isMobile && vehicle?.images?.small) || vehicle?.images?.large || '/images/my-vehicle/image-unavailable-tasa.png'
    );
  }

  const buildVehicleInfoData = (vehicle) => {
    return [
      {
        label: t('vehicle_patent'),
        value: vehicle?.patent
      },
      {
        label: t('vehicle_chassis'),
        value: vehicle?.chassi
      },
      {
        label: t('vehicle_year'),
        value: vehicle?.year
      }
    ];
  };

  return (
    <div className={`${css['molecule__service-appointment-vehicle-step-container']} ${className}`} {...other}>
      {loading && <LoaderOverlay content={t('content_loading')} />}
      {!loading && (
        <div className={css['vehicle-step-appointments']}>
          <div className={css['container-appointments']}>
            {vehiclesWithAppointments.length > 0 && (
              <div className={css['container-with-appointments']}>
                <div className={css['with-appointments-content']}>
                  <h3>{t('with_appointments_title')}</h3>
                  <span>{t('with_appointments_content')}</span>
                </div>

                <div className={css['with-appointments-vehicles']}>
                  {(showAllVehicles ? vehiclesWithAppointments : vehiclesWithAppointments.slice(0, 3)).map((vehicle, index) => (
                    <div
                      ref={selectedVehicleId === vehicle.id ? vehicleRef : null}
                      className={`${css['card-vehicle-with-appointment']} ${selectedVehicleId === vehicle.id ? css['selected'] : ''
                        }`}
                      onClick={() => handleSelectVehicle(vehicle)}
                      role="button"
                      tabIndex={0}
                      key={index}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleSelectVehicle(vehicle);
                        }
                      }}
                    >
                      <img
                        className={css['card-image-with-appointments']}
                        src={getImageUrl(vehicle, isMobile)}
                        title={vehicle?.name || ''}
                        alt={vehicle?.name || ''}
                      />
                      <div className={css['info-vehicle-container']}>
                        <div className={css['info-vehicle-content']}>
                          <p className={css['card-title-with-appointments']}>{vehicle.name}</p>
                          <div className={css['info-vehicle-chassi']}>
                            <span>{t('vehicle_chassis')}: </span>
                            <span>{vehicle?.chassi}</span>
                          </div>
                        </div>
                        <AnchorLink
                          className={css['button_make_request']}
                          linkLabel={t('label_make_request')}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectVehicle(vehicle);
                            handleConfirmation(vehicle.id);
                          }}
                          hasArrow={true}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                {vehiclesWithAppointments.length > 3 && (
                  <div className={css['container-toggle-vehicles']}>
                    <Button
                      className={css['toggle-button']}
                      onClick={() => setShowAllVehicles(!showAllVehicles)}
                      color="ghost-2"
                      disabled={false}
                    >
                      {showAllVehicles ? t('label_see_less') : t('label_see_more')}
                    </Button>
                  </div>
                )}
              </div>
            )}
            {vehiclesWithoutAppointments.length > 0 && (
              <div className={css['container-without-appointments']}>
                <div className={css['without-appointments-content']}>
                  <h3>{t('without_appointments_title')}</h3>
                  <span>{t('without_appointments_content')}</span>
                </div>
                <div className={css['without-appointments-vehicles']}>
                  {vehiclesWithoutAppointments.map((vehicle, index) => {
                    const selectedImage = getImageUrl(vehicle, isMobile);
                    const vehicleInfoData = buildVehicleInfoData(vehicle, t);
                    return (
                      <div
                        ref={selectedVehicleId === vehicle.id ? vehicleRef : null}
                        className={`${css['card-vehicle-without-appointment']} ${selectedVehicleId === vehicle.id ? css['selected'] : ''
                          }`}
                        key={index}
                      >
                        <div className={css['card-image-content']}>
                          <img
                            className={css['image-vehicle']}
                            src={selectedImage}
                            title={vehicle?.name || ''}
                            alt={vehicle?.name || ''}
                          />
                          <div className={css['info-vehicle']}>
                            <p className={css['info-vehicle-title']}>{vehicle.name}</p>

                            {vehicleInfoData.map(
                              (info, index) =>
                                info.value && (
                                  <div className={css['info-vehicle-content']} key={index}>
                                    <span className={css['info-vehicle-content_label']}>{info.label}: </span>
                                    <span className={css['info-vehicle-content_value']}>{info.value}</span>
                                  </div>
                                )
                            )}
                          </div>
                        </div>

                        <Button
                          color={selectedVehicleId === vehicle?.id ? 'primary' : 'ghost-black'}
                          onClick={() => handleSelectVehicle(vehicle)}
                          className={css['card-button']}
                        >
                          {selectedVehicleId ? (
                            <>
                              {t('label_selected_vehicle')}
                              <IconCheck className={css['button-icon']} />
                            </>
                          ) : (
                            t('label_select_vehicle')
                          )}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className={css['container_confirm_buttons']}>
            <AnchorLink
              className={css['confirm_anchor']}
              linkLabel={t('label_anchor_confirm')}
              onClick={() => updatePageParam('section', 'vehicles', router)}
              hasArrow={false}
            />
            {vehiclesWithoutAppointments.length > 0 && (
              <Button
                className={css['confirm_button']}
                onClick={() => handleConfirmation(selectedVehicleId)}
                disabled={
                  !selectedVehicleId ||
                  (selectedVehicleId === state?.customerVehicleId && !isServicesStepDisabled) ||
                  vehiclesWithAppointments.some(vehicle => vehicle.id === selectedVehicleId)
                }
              >
                {t('label_button_confirm')}
              </Button>
            )}
          </div>
        </div>

      )}
    </div>
  );
}

export default ServiceAppointmentVehicleStep;
