import * as React from 'react';

const SvgIconAttention = (props) => (
<svg width={props.width ? props.width : '64'} height={props.height ? props.height : '64'} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" >
<path xmlns="http://www.w3.org/2000/svg" d="M2.66699 55.9997L32.0003 5.33301L61.3337 55.9997H2.66699ZM9.60033 51.9997H54.4003L32.0003 13.333L9.60033 51.9997ZM32.2787 48.1997C32.8487 48.1997 33.3225 48.0069 33.7003 47.6213C34.0781 47.2357 34.267 46.758 34.267 46.188C34.267 45.618 34.0742 45.1441 33.6887 44.7663C33.3031 44.3886 32.8253 44.1997 32.2553 44.1997C31.6853 44.1997 31.2114 44.3925 30.8337 44.778C30.4559 45.1636 30.267 45.6414 30.267 46.2113C30.267 46.7813 30.4598 47.2552 30.8453 47.633C31.2309 48.0108 31.7087 48.1997 32.2787 48.1997ZM30.267 40.7997H34.267V25.8663H30.267V40.7997Z" fill="#DA2222"/>
</svg>
);

export default SvgIconAttention;

