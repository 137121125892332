import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor, spacingBottomMeta, spacingTopMeta } from 'utils/meta';

class FaqAccordion implements ContentBlock {
  label = 'FAQ Accordion';

  component = 'FaqAccordion';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    anchor,
    {
      name: 'title',
      label: 'Title',
      help: 'The title of the section',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Description',
      help: 'The description of the section',
      type: 'textarea',
      control: 'textarea',
      defaultValue: '',
      props: null
    },
    {
    name: 'bgColor',
    label: 'Wrapper backgroud color',
    help: '',
    type: 'String',
    control: 'select',
    options: [
      'white',
      'gray',
    ],
    defaultValue: 'gray'
  },
    spacingTopMeta,
    spacingBottomMeta
  ];

  properties = [
    {
      name: 'media',
      label: 'Icon image',
      help: 'Set the icon image',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    },
    {
      name: 'title',
      label: 'Title',
      help: 'The title of the content',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Content',
      help: 'The content of the item',
      type: 'String',
      control: 'editor',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    return {
      title: extra?.settings?.title,
      description: extra?.settings?.description,
      bgColor: extra?.settings?.bgColor,
      spacingTop: extra?.settings?.spacingTop,
      spacingBottom: extra?.settings?.spacingBottom,
      faqContent: {
        items: contents.map((content) => {
          return {
            title: content?.title,
            content: content?.description,
            media: content?.media,
          };
        })
      }
    };
  }
}

export { FaqAccordion };
