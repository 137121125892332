import React, { useState } from 'react';
import i18n from 'utils/i18n';
import config from './config';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import SubmitSearchButton from 'components/atoms/submit-search-button';
import Modal from 'components/molecules/simple-modal';
import RecallSearchData from '../recall-search-data';
import FormInput from 'components/atoms/form-input';
import Loader from 'components/atoms/loader';
import Title from 'components/atoms/title';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule RecallSearch
 */
function RecallSearch(props) {
  const t = i18n.useTranslations('components.recall-search');
  const { className = '', title, locale, ...other } = props;

  const { fields, validationSchema, functions } = config(locale);

  const [isOpenVIN, setIsOpenVIN] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recallData, setRecallData] = useState({
    searched: false,
    data: null,
    vin: null
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup
        .object()
        .shape(validationSchema ?? {})
        .required()
    ),
    defaultValues: {
      name: '',
      lastName: '',
      customerId: '',
      cellphone: '',
      email: '',
      vehicleIdentificationNumber: ''
    }
  });

  async function onSubmit(params) {
    setIsLoading(true);
    await functions?.onSubmit(params, setRecallData, reset);
    setIsLoading(false);
  }

  return (
    <>
      <div className={`${css['molecule__recall-search-container']} ${className}`} {...other}>
        <div className={css['search-container']}>
          <Title level={1} variant={7}>
            {title ? title : t('title')}
          </Title>

          <form onSubmit={handleSubmit(onSubmit, functions?.onError)} className={css['form-container']}>
            <div className={css['fields-container']}>
              {fields?.name && (
                <FormInput
                  id="name"
                  type="text"
                  displayLabel={fields?.name?.displayLabel}
                  dataErrors={errors['name']}
                  dataRegister={register('name')}
                  label={t('label_name')}
                  placeholder={t('placeholder_name')}
                  masking={fields?.name?.mask}
                />
              )}

              {fields?.lastName && (
                <FormInput
                  id="lastName"
                  type="text"
                  displayLabel={fields?.lastName?.displayLabel}
                  dataErrors={errors['lastName']}
                  dataRegister={register('lastName')}
                  label={t('label_last_name')}
                  placeholder={t('placeholder_last_name')}
                  masking={fields?.lastName?.mask}
                />
              )}

              {fields?.customerId && (
                <FormInput
                  id="customerId"
                  type="text"
                  displayLabel={fields?.customerId?.displayLabel}
                  dataErrors={errors['customerId']}
                  dataRegister={register('customerId')}
                  label={t('label_customer_id')}
                  placeholder={t('placeholder_customer_id')}
                  masking={fields?.customerId?.mask}
                />
              )}

              {fields?.cellphone && (
                <FormInput
                  id="cellphone"
                  type="text"
                  displayLabel={fields?.cellphone?.displayLabel}
                  dataErrors={errors['cellphone']}
                  dataRegister={register('cellphone')}
                  label={t('label_cellphone')}
                  placeholder={t('placeholder_cellphone')}
                  masking={fields?.cellphone?.mask}
                />
              )}

              {fields?.email && (
                <FormInput
                  id="email"
                  type="email"
                  displayLabel={fields?.email?.displayLabel}
                  dataErrors={errors['email']}
                  dataRegister={register('email')}
                  label={t('label_email')}
                  placeholder={t('placeholder_email')}
                  masking={fields?.email?.mask}
                />
              )}

              {fields?.vehicleIdentificationNumber && (
                <div className={css['container']}>
                  <div className={css['vin-container']}>
                    <FormInput
                      id="vehicleIdentificationNumber"
                      type="text"
                      displayLabel={fields?.vehicleIdentificationNumber?.displayLabel}
                      dataErrors={errors['vehicleIdentificationNumber']}
                      dataRegister={register('vehicleIdentificationNumber')}
                      label={t('label_vehicle_identification_number')}
                      placeholder={t('placeholder_vehicle_identification_number')}
                      masking={fields?.vehicleIdentificationNumber?.mask}
                      className={css['vin-input']}
                    />
                    {fields?.vehicleIdentificationNumber?.displaySubmitSearchButton && (
                      <SubmitSearchButton disabled={!isValid || isLoading} />
                    )}
                  </div>

                  {fields?.vehicleIdentificationNumber?.displayModal && (
                    <button className={css['modal-activate-button']} type="button" onClick={() => setIsOpenVIN(true)}>
                      {t('find_vehicle_identification_number')}
                    </button>
                  )}
                </div>
              )}
            </div>

            {fields?.submitButton && (
              <button className={css['search-button']} type="submit" disabled={!isValid || isLoading}>
                {t('search_button')}
              </button>
            )}
          </form>
        </div>

        {isLoading ? (
          <div className={css['loader-container']}>
            <Loader />
          </div>
        ) : (
          recallData.searched && <RecallSearchData data={recallData.data} locale={locale} />
        )}

        {isOpenVIN && (
          <Modal className={css['modal-container']} isOpen={isOpenVIN} onClose={() => setIsOpenVIN(false)}>
            <img src={fields?.vehicleIdentificationNumber?.modalImage} alt="VIN" />
          </Modal>
        )}
      </div>
    </>
  );
}

export default RecallSearch;
