import React, { useState } from 'react';
import i18n from 'utils/i18n';
import Button from 'components/atoms/button';
import FilterGroupPanel from 'components/molecules/filter-group-panel';
import CarCartSummary from 'components/molecules/car-cart-summary';
import CarInfoCard from '../car-info-card';
import Pagination from 'components/molecules/pagination';
import { vehiclesDataMock } from 'utils/__mocks__/VehiclesDataMock';

import css from './styles.module.scss';



/**
 * Molecule DirectSalesModelsStep
 *
 * <!-- TODO: add a description here! -->
 */
function DirectSalesModelsStep(props) {
  const t = i18n.useTranslations('components.direct-sales-models-step');

  const {
    className = "",
    hasQuantity = true,
    hidePrice = false,
    locale = 'pt-br',
    children,
    ...other
  } = props;


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const [carList, setCarList] = useState([]);
  const [filters, setFilters] = useState([]);

  const getCategoriesAndVehicles = (vehicles) => {
    const categories = [];

    vehicles.forEach((vehicle) => {
      const categoryId = vehicle.category.id;
      const categoryName = vehicle.category.name;

      if (!categories[categoryId]) {
        categories[categoryId] = {
          id: categoryId,
          name: categoryName,
          models: [],
        };
      }

      categories[categoryId].models.push({
        id: vehicle.id,
        name: vehicle.name,
      });
    });

    return Object.values(categories);
  };

  const filteredVehiclesCategories = getCategoriesAndVehicles(vehiclesDataMock);

  const groupByVehicleAndVersion = () => {
    const groupedData = {};

    vehiclesDataMock.forEach(vehicle => {
      vehicle.versions.forEach(version => {
        const key = `${vehicle.id}-${version.id}`;

        if (!groupedData[key]) {
          groupedData[key] = {
            ...vehicle,
            version: version.name,
            versionId: version.id,
            colors: []
          };
        }
        version.modelYears.forEach(modelYear => {
          groupedData[key]?.colors.push(...modelYear.vehicleColors.map(color => ({
            ...color.color,
            price: color.price,
            offers: color.offers,
            medias: color.modelYear.medias
          })));
        });
      });
    });

    return Object.values(groupedData).sort((a, b) =>
      `${a?.name} ${a?.version}`.localeCompare(`${b?.name} ${b?.version}`)
    );
  };
  const groupedCards = groupByVehicleAndVersion();

  const filteredCards = React.useMemo(() => {
    return filters?.length > 0
      ? groupedCards?.filter(card => filters?.includes(card.id))
      : groupedCards;
  }, [filters, groupedCards]);

  const calculateTotalPages = () => {
    return Math.ceil(filteredCards?.length / itemsPerPage);
  };

  const totalPages = calculateTotalPages();

  const getPaginatedCards = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredCards.slice(startIndex, endIndex);
  };

  const paginatedCards = getPaginatedCards();


  const applyFilters = (filteredVehicleIds) => {
    setFilters(filteredVehicleIds);
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setFilters([]);
    setCurrentPage(1);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const updateQuantity = (itemIndex, newQuantity) => {
    const updatedCarList = [...carList];
    updatedCarList[itemIndex].quantity = newQuantity;
    setCarList(updatedCarList);
    console.log(`Quantidade do item ${itemIndex} alterada para ${newQuantity}`);
  };

  const removeItem = (itemIndex) => {
    const updatedCarList = carList.filter((_, index) => index !== itemIndex);
    setCarList(updatedCarList);
    console.log(`Item ${itemIndex} removido`);
  };

  return <div
    className={`${css["molecule__direct-sales-models-step-container"]} ${className}`}
    {...other}
  >
    {hasQuantity &&
      <div className={css['header']}>
        <CarCartSummary
          className={css['cart-summary']}
          items={carList}
          onQuantityChange={updateQuantity}
          onRemoveItem={removeItem}
          hidePrice={hidePrice}
          locale={locale}
        />
      </div>
    }

    <div className={`${css['container-vehicle-filter']}`}>
      <FilterGroupPanel
        className={css['filter-group']}
        vehiclesCategories={filteredVehiclesCategories}
        setFilters={setFilters}
        onApplyFilters={applyFilters}
        clearFilters={clearFilters}
      />
      <div className={`${css['vehicles-pagination']}`}>
        <div className={`${css['vehicles-list']}`}>
          {paginatedCards?.map((vehicleData) => (
            <CarInfoCard
              key={`${vehicleData.id}-${vehicleData.versionId}`}
              vehicleData={{
                ...vehicleData,
                name: `${vehicleData.name} ${vehicleData.version}`,
                versionId: vehicleData.versionId,
                colors: vehicleData.colors
              }}
              carList={carList}
              setCarList={setCarList}
              initialValue={1}
              hasQuantity={hasQuantity}
              hidePrice={hidePrice}
              locale={locale}
            />
          ))}
        </div>
        {totalPages > 1 &&
          <Pagination
            className={`${css['pagination']}`}
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={changePage}
          />
        }
      </div>
    </div>
    <Button className={css['button-continue']}>{t("label_continue_button")}</Button>
    {children}
  </div >;
}

export default DirectSalesModelsStep;
