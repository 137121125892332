import React, { useState } from 'react';
import i18n from 'utils/i18n';
import css from './styles.module.scss';
import Wrapper from 'components/atoms/wrapper';
import InputNumber from 'components/atoms/input-number';
import SvgIconDeleteOutline from 'components/icons/IconDeleteOutline';
import { currency } from 'utils/functions';

/**
 * Molecule CarDetailsActionCard
 *
 * For the TDB Direct Sales functionality
 */
function CarDetailsActionCard(props) {
  const t = i18n.useTranslations('components.car-details-action-card');

  const {
    className = '',
    children,
    model,
    price,
    color,
    media,
    mediaTitle,
    showPrice,
    onDelete,
    id,
    locale,
    initialValue,
    onQuantityChange,
    ...other
  } = props;

  const [quantity, setQuantity] = useState(initialValue || 1);

  const handleDelete = () => {
    if (onDelete) {
      onDelete(id);
    }
  };

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
    if (onQuantityChange) {
      onQuantityChange(id, newQuantity);
    }
  };

  return (
    <Wrapper
      className={`${css['molecule__car-details-action-card-container']} ${className}`}
      {...other}
      show-price={showPrice}
      data-testid={`car-card-${id}`}
    >
      <div className={`${css['image-info-container']}`}>
        {media && <img className={`${css['car-image-information']}`} src={media} alt={mediaTitle} />}

        <div className={`${css['info-container']}`}>
          {model && (
            <dl>
              <dt>{t('label_model')}</dt>
              <dd>{model}</dd>
            </dl>
          )}
          {color && (
            <dl>
              <dt>{t('label_color')}</dt>
              <dd>{color}</dd>
            </dl>
          )}
        </div>
      </div>

      {showPrice && <p className={`${css['price']}`}>{currency(price, 'BRL')}</p>}

      <div className={`${css['disclaimer-buttons-price']}`}>
        <p className={`${css['disclaimer']}`}>{t('disclaimer')}</p>
        <InputNumber
          min={1}
          initialValue={quantity}
          incrementStep={1}
          decrementStep={1}
          className={`${css['input-number']}`}
          value={quantity}
          onChange={handleQuantityChange}
        />
        <button className={`${css['button-delete']}`} onClick={handleDelete} data-testid="button-delete">
          <SvgIconDeleteOutline />
        </button>
      </div>
    </Wrapper>
  );
}

export default CarDetailsActionCard;
