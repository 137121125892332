import fields from './fields';
import validations from './validations';
import functions from './functions';

const config = (locale) => {
  return {
    validationSchema: validations()[locale],
    functions: functions[locale],
    fields: fields[locale]
  };
};

export default config;
