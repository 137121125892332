import * as yup from 'yup';
import i18n from 'utils/i18n';

import { isValidTDVCellphone } from 'utils/forms';

const validations = () => {
  const t = i18n.useTranslations('components.recall-search');

  return {
    'es-VE': {
      name: yup
        .string()
        .trim()
        .required(t('message_error_required_name'))
        .min(1, t('message_error_min_char_name'))
        .max(40, t('message_error_max_char_name')),
      lastName: yup
        .string()
        .trim()
        .required(t('message_error_required_last_name'))
        .min(1, t('message_error_min_char_last_name'))
        .max(80, t('message_error_max_char_last_name')),
      email: yup
        .string()
        .trim()
        .required(t('message_error_required_email'))
        .email(t('message_error_invalid_email'))
        .max(200, t('message_error_max_char_email')),
      customerId: yup
        .string()
        .trim()
        .required(t('message_error_required_customer_id'))
        .min(5, t('message_error_min_char_customer_id'))
        .max(17, t('message_error_max_char_customer_id')),
      vehicleIdentificationNumber: yup
        .string()
        .trim()
        .required(t('message_error_required_vehicle_identification_number'))
        .min(10, t('message_error_min_char_vehicle_identification_number'))
        .max(20, t('message_error_max_char_vehicle_identification_number')),
      cellphone: yup
        .string()
        .trim()
        .required(t('message_error_required_cellphone'))
        .test('test-invalid-cellphone', t('message_error_invalid_cellphone'), (cellphone) =>
          isValidTDVCellphone(cellphone)
        )
    },

    'es-AR': null,

    'pt-BR': null
  };
};

export default validations;
