import React, { useEffect, useState } from 'react';
import { defaultCountriesCodes } from 'utils/__data__/default-countries-codes';
import { IconChevronBotton, IconChevronTop } from 'components/icons';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom PhoneInput
 *
 * A customizable phone input with country selection, search, formatting, and validation.
 */
function PhoneInput(props) {
  const {
    className = '',
    countriesOptions = defaultCountriesCodes,
    id = 'phone',
    locale = 'ar',
    disabled = false,
    label,
    dataRegister,
    defaultValue,
    dataErrors,
    displayLabel = true,
    displayError = true,
    ...other
  } = props;

  const [selectedCountry, setSelectedCountry] = useState(countriesOptions[0]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const filtered =
      searchQuery === ''
        ? countriesOptions
        : countriesOptions.filter((country) => country.name[locale].toLowerCase().includes(searchQuery.toLowerCase()));
    setFilteredCountries(filtered);
  }, [
    searchQuery,
    countriesOptions,
    locale
  ]);

  useEffect(() => {
    if (defaultValue) {
      const findCountryByCode = (phoneNumber) => {
        const cleanedPhoneNumber = phoneNumber.replace(/\s/g, '');
        for (const country of countriesOptions) {
          if (cleanedPhoneNumber.startsWith(country.code)) {
            return country;
          }
        }
        return countriesOptions[0];
      };
      const country = findCountryByCode(defaultValue);
      const countryCode = country.code;
      const cleanedPhoneNumber = defaultValue.replace(/\s/g, '');
      const localNumber = cleanedPhoneNumber.replace(countryCode, '');
      setPhoneNumber(localNumber);
      setSelectedCountry(country);
    }
  }, [defaultValue, countriesOptions]);

  const formatPhoneNumber = (value) => value.replace(/[^0-9()]/g, '');

  const getFormattedPhoneNumber = (phoneValue, countryCode) => {
    const cleanedPhone = formatPhoneNumber(phoneValue);
    const cleanedCountryCode = formatPhoneNumber(countryCode);
    return cleanedPhone.startsWith(cleanedCountryCode)
      ? cleanedPhone.substring(cleanedCountryCode.length)
      : cleanedPhone;
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhoneNumber = getFormattedPhoneNumber(e.target.value, selectedCountry.code);
    setPhoneNumber(updatedPhoneNumber);
    dataRegister.onChange(e);
  };

  const handleKeyDown = (e, action) => {
    if (['Enter', ' '].includes(e.key)) {
      action();
    }
  };

  const toggleDropdown = () => {
    setIsDropdownActive(isDropdownActive ? false : true);
  };

  const onCountrySelect = (country) => {
    setSelectedCountry(country);
    setIsDropdownActive(false);
  };

  return (
    <div className={`${css['atom__phone-input-container']} ${className}`} {...other}>
      {label && displayLabel && (
        <label
          className={`${css['ff-label']} ${disabled ? css['ff-disabled'] : ''} ${displayLabel ? '' : css['absent']}`}
          htmlFor={id}
          disabled={disabled}
          aria-labelledby={`label-${id}`}
          data-testid="phone-input-label"
        >
          {label}
        </label>
      )}
      <div className={`${css['code-selection-box']} ${isDropdownActive ? css['active'] : ''}`}>
        <div
          className={`${css['selected-option']} ${isDropdownActive ? css['active'] : ''}`}
          role="button"
          aria-haspopup="listbox"
          aria-expanded={isDropdownActive}
          data-testid="dropdown-button"
          tabIndex={0}
          onClick={toggleDropdown}
          onKeyDown={(e) => handleKeyDown(e, () => toggleDropdown())}
        >
          <div
            className={css['selected-option-button']}
            role="button"
            tabIndex={0}
            onClick={() => onCountrySelect(selectedCountry)}
            onKeyDown={(e) => handleKeyDown(e, () => onCountrySelect(selectedCountry))}
            aria-label={`Select ${selectedCountry.name[locale]}`}
          >
            <img
              className={css['selected-option-flag']}
              src={selectedCountry.flag}
              alt={`Flag of ${selectedCountry.code}`}
              aria-label={`Flag of ${selectedCountry.name[locale]}`}
            />

            {isDropdownActive ? <IconChevronTop /> : <IconChevronBotton />}
          </div>
          <input
            className={`${css['field-input-phone']} ${displayError ? css['ff-box'] : css['ff-box-clean']} ${
              dataErrors?.type ? css['ff-box-error'] : ''
            }`}
            {...dataRegister}
            {...other}
            id={id}
            type="tel"
            data-testid="phone-input"
            onChange={onPhoneNumberChange}
            onClick={(e) => e.stopPropagation()}
            value={`${selectedCountry.code} ${phoneNumber}`}
            disabled={disabled}
            aria-label="Phone number input"
            aria-describedby={dataErrors?.message ? 'phone-error' : undefined}
          />
        </div>

        <div className={css['dropdown-options']} data-testid="dropdown-options">
          <input
            className={css['dropdown-options_input']}
            type="hidden"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            aria-label="List for a country"
          />
          <ul role="listbox" tabIndex={0} className={css['dropdown-options-list']}>
            {filteredCountries.map((country) => (
              <li key={country.id} className={css['dropdown-options-item']}>
                <button
                  className={css['dropdown-options-item-button']}
                  type="button"
                  tabIndex={0}
                  onClick={() => onCountrySelect(country)}
                  onKeyDown={(e) => {
                    handleKeyDown(e, () => onCountrySelect(country));
                  }}
                  aria-label={`Select ${country.name[locale]}`}
                >
                  <img className={css['dropdown-options-flag']} src={country.flag} alt={`Flag of ${country.code}`} />
                  <span className={css['dropdown-options-coutry']}>{country.name[locale]}</span>

                  <span>{country.code}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {displayError && dataErrors?.message && (
        <span
          className={css['ff-error-message']}
          data-testid="error-message"
          id="phone-error"
          role="alert"
          aria-live="assertive"
          aria-describedby="phone-error"
        >
          {dataErrors.message}
        </span>
      )}
    </div>
  );
}

export default PhoneInput;
