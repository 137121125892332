/* eslint-disable no-unused-vars */
import { captchaToken } from 'utils/captcha/token';
import { cpfMask } from 'utils/validations';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import FormCheckbox from 'components/atoms/form-checkbox';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import getConfig from 'next/config';
import i18n from 'utils/i18n';
import { phoneMask } from 'utils/validations';
import React, { useCallback, useEffect, useState } from 'react';
import Script from 'next/script';
import Section from 'components/utils/section';
import ResultSubmitForm from 'components/molecules/result-submit-form';
import Wrapper from 'components/atoms/wrapper';
import FormRadioButton from 'components/atoms/form-radio-button';
import FormRadioGroup from 'components/atoms/form-radio-group';
import Title from 'components/atoms/title';
import { removeSpecialCharactersAndNumbers, getCitiesFromDealers, getDealersByCity, removeSpecialCharacters } from 'utils/forms';

// loading the sass style fot the component
import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

function DirectSalesForm(props) {
  const t = i18n.useTranslations('components.direct-sales-form');

  const {
    className = '',
    spacing,
    bgColor = 'white',
    title,
    description,
    formType = 'pf',
    dealers,
    states,
    ...other
  } = props;

  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);
  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [typeOfForm, setTypeOfForm] = useState(formType);

  useEffect(() => {
    if (formType === 'pf-pj') {
      setValue('type', 'pf');
      setTypeOfForm('pf');
    } else {
      setValue('type', formType);
      setTypeOfForm(formType);
    }
  }, []);

  const getSchemaConfigByType = () => {
    const baseSchema = {
      email: yup.string().required(t('message_error_required')).email(t('message_error_invalid_email')),
      telephone: yup.string().required(t('message_error_required')).min(15, t('message_error_min_char_telephone')),
      state: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required_state'))
        .required(t('message_error_required_state')),
      city: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required_city'))
        .required(t('message_error_required_city')),
      dealerId: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required_dealership'))
        .required(t('message_error_required_dealership')),
      agreement: yup.boolean().oneOf([true], t('message_error_required_agreement'))
    };

    switch (typeOfForm) {
      case 'pf':
        return {
          ...baseSchema,
          firstName: yup
            .string()
            .required(t('message_error_required'))
            .min(3, t('message_error_min_char_firstName')),
          lastName: yup
            .string()
            .required(t('message_error_required'))
            .min(1, t('message_error_min_char_lastName'))
            .max(80, t('message_error_max_char_lastName')),
          customerId: yup
            .string()
            .required(t('message_error_required')),
        };
      case 'pj':
        return {
          ...baseSchema,
          corporateName: yup
          .string()
          .required(t('message_error_required'))
          .min(3, t('message_error_min_char_corporateName')),
          cnpj: yup
          .string()
          .required(t('message_error_required'))
          .min(14, t('message_error_min_char_cnpj')),
        };
      default:
        return baseSchema;
    }
  };
  const validationSchemaConfig = () => {
    return getSchemaConfigByType();
  };

  const validationSchema = yup.object().shape(validationSchemaConfig());

  const {
    register,
    reset,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    formState,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      type: 'pf'
    }
  });

  const watchedState = watch('state');
  const watchedType = watch('type');
  const watchedFields = watch();

  useEffect(() => {
    const isAnyFieldPlaceholder = Object?.values(watchedFields).some((value) => value === 'placeholder');
    setIsDisable(isAnyFieldPlaceholder);
  }, [watchedFields]);

  const resetForm = useCallback(() => {
    reset({});
  }, [reset]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    data.type = formType.toUpperCase();

    const captchaPayload = await captchaToken('CONTACT', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');

    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsSubmitting(false);

      return false;
    }

    data.captchaPayload = captchaPayload;

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    const url = '/api/contact';

    fetch(url, opts)
      .then((res) => {
        if (res.status === 200) {
          setIsSubmitting(false);
          resetForm(reset);
          setIsStatusComponent('success');

          return;
        }
        setIsSubmitting(false);
        setIsStatusComponent('error');
      })
      .catch((err) => {
        setIsSubmitting(false);
        onError(err);
      });
  };

  const onSubmitTest = (data) => {
    setIsStatusComponent('success');
  };

  function onError(error) {
    console.log('Error: ', error);
  }

  function onBackForm() {
    setIsStatusComponent('default');
  }

  function loadCities(selectedState) {
    const resultCities = getCitiesFromDealers(dealers, selectedState);
    setListCities(resultCities);
  }

  function loadDealers(selectedCity) {
    const filtered = getDealersByCity(dealers, selectedCity);
    setListDealers(filtered);
  }

  function onChangeState(e) {
    setValue('city', 'placeholder');
    setValue('dealerId', 'placeholder');
    loadCities(e.target.value);
  }

  function onChangeCity(e) {
    setValue('dealerId', 'placeholder');
    clearErrors('city');
    loadDealers(e.target.value);
  }

  return (
    <div className={`${css['molecule__direct-sales-form-container']} ${className}`} {...other}>
      <Wrapper spacing={spacing} bgColor={bgColor}>
        <Section className={css['success-container']}>
          <Script
            src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
          ></Script>

          {isStatusComponent === 'success' && (
            <ResultSubmitForm
              status='success'
              titleResult={t('success_title')}
              contentResult={t('success_content')}
              contentBackHome={t('content_back_home')}
            />
          )}

          {isStatusComponent === 'error' && (
            <ResultSubmitForm
              status='error'
              onBackForm={onBackForm}
              titleResult={t('error_title')}
              contentResult={t('error_content')}
              contentBackForm={t('content_back_form')}
              contentBackHome={t('content_back_home')}
            />
          )}
          {isStatusComponent === 'default' && (
            <form
              className={css['form']}
              onSubmit={handleSubmit(onSubmitTest, onError)}
              data-grid
              data-grid-list='3'>
              <Title className={css['form-container__title']} variant={5} data-grid-full-width>
                {t('title_section')}
              </Title>
              <div className={css['form-container__section']} data-grid-full-width data-grid-list="3">
                {formType === 'pf-pj' &&
                  <FormRadioGroup name="group" dataRegister={register('type')} className={css['form-radio']} spacingGap='small'>
                    <FormRadioButton id="pf" value="pf" onClick={() => setTypeOfForm('pf')}>{t('label_pf')}</FormRadioButton>
                    <FormRadioButton id="pj" value="pj" onClick={() => setTypeOfForm('pj')}>{t('label_pj')}</FormRadioButton>
                  </FormRadioGroup>}

                {watchedType === 'pf' &&
                  <>
                    <Title className={css['form-container__subtitle']} variant={5} data-grid-full-width>
                      {t('title_section_personal_data')}
                    </Title>
                    <div className={css['form-container__body']} data-grid-full-width data-grid-list="3">
                      <FormInput
                        className={css['form-field']}
                        id="firstName"
                        label={t('label_firstName')}
                        placeholder={t('placeholder_firstName')}
                        dataRegister={register('firstName')}
                        maxLength="50"
                        dataErrors={errors['firstName']}
                        masking={(event) => {
                          const value = event.target.value;
                          event.target.value = removeSpecialCharactersAndNumbers(value);
                        }}
                        type="text"
                      />
                      <FormInput
                        className={css['form-field']}
                        id="lastName"
                        label={t('label_lastName')}
                        placeholder={t('placeholder_lastName')}
                        dataRegister={register('lastName')}
                        dataErrors={errors['lastName']}
                        maxLength="50"
                        masking={(event) => {
                          const value = event.target.value;
                          event.target.value = removeSpecialCharactersAndNumbers(value);
                        }}
                        type="text"
                      />

                      <FormInput
                        className={css['form-field']}
                        id="customerId"
                        label={t('label_customerId')}
                        placeholder={t('placeholder_customerId')}
                        type="text"
                        dataRegister={register('customerId')}
                        dataErrors={errors['customerId']}
                        maxLength='14'
                        masking={(event) => {
                          const value = event.target.value;
                          event.target.value = cpfMask(value);
                        }}
                      />
                    </div>
                    <div className={css['form-container__body']} data-grid-full-width data-grid-list="3">
                      <FormInput
                        className={css['form-field']}
                        id="email"
                        label={t('label_email')}
                        dataRegister={register('email')}
                        dataErrors={errors['email']}
                        type="text"
                        maxLength='50'
                        placeholder={t('placeholder_email')}
                      />

                      <FormInput
                        className={css['form-field']}
                        id="telephone"
                        label={t('label_telephone')}
                        placeholder={t('placeholder_telephone')}
                        dataRegister={register('telephone')}
                        dataErrors={errors['telephone']}
                        maxLength="15"
                        masking={(event) => {
                          const value = event.target.value;
                          event.target.value = phoneMask(value);
                        }}
                      />
                    </div>
                  </>}
                {watchedType === 'pj' &&
                  <>
                    <Title className={css['form-container__subtitle']} variant={5} data-grid-full-width>
                      {t('title_section_corporate')}
                    </Title>

                    <div className={css['form-container__body']} data-grid-full-width data-grid-list="3">
                      <FormInput
                        className={css['form-field']}
                        id="corporateName"
                        label={t('label_corporateName')}
                        placeholder={t('placeholder_corporateName')}
                        dataRegister={register('corporateName')}
                        maxLength="50"
                        dataErrors={errors['corporateName']}
                        masking={(event) => {
                          const value = event.target.value;
                          event.target.value = removeSpecialCharactersAndNumbers(value);
                        }}
                        type="text"
                      />
                      <FormInput
                        className={css['form-field']}
                        id="cnpj"
                        label={t('label_cnpj')}
                        placeholder={t('placeholder_cnpj')}
                        dataRegister={register('cnpj')}
                        dataErrors={errors['cnpj']}
                        maxLength="14"
                        type="text"
                        masking={(event) => {
                          const value = event.target.value;
                          event.target.value = removeSpecialCharacters(value);
                        }}
                      />

                      <FormInput
                        className={css['form-field']}
                        id="email"
                        label={t('label_email')}
                        dataRegister={register('email')}
                        dataErrors={errors['email']}
                        type="text"
                        maxLength='50'
                        placeholder={t('placeholder_email')}
                      />
                      </div>
                      <div className={css['form-container__body']} data-grid-full-width data-grid-list="3">
                      <FormInput
                        className={css['form-field']}
                        id="telephone"
                        label={t('label_telephone')}
                        placeholder={t('placeholder_telephone')}
                        dataRegister={register('telephone')}
                        dataErrors={errors['telephone']}
                        maxLength="15"
                        masking={(event) => {
                          const value = event.target.value;
                          event.target.value = phoneMask(value);
                        }}
                      />
                    </div>
                  </>
                }
              </div>
              <div className={css['form-container__section']} data-grid-full-width data-grid-list="3">
                <Title className={css['form-container__subtitle']} variant={5} data-grid-full-width>
                  {t('title_section_dealership')}
                </Title>
                <div className={css['form-container__body']} data-grid-full-width data-grid-list="3">
                  <FormSelect
                    className={css['form-container__input']}
                    id="state"
                    label={t('label_state')}
                    placeholder={t('placeholder_state')}
                    onChange={onChangeState}
                    dataRegister={register('state')}
                    dataErrors={errors['state']}
                  >
                    {states &&
                      states?.map((state, index) => {
                        return (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    className={css['form-container__input']}
                    id="city"
                    label={t('label_city')}
                    placeholder={t('placeholder_city')}
                    onChange={onChangeCity}
                    dataRegister={register('city')}
                    dataErrors={errors['city']}
                  >
                    {watchedState &&
                      listCities?.map((city, index) => {
                        return (
                          <option key={index} value={city}>
                            {city}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    className={css['form-container__input']}
                    id="dealerId"
                    label={t('label_dealership')}
                    placeholder={t('placeholder_dealership')}
                    dataRegister={register('dealerId')}
                    dataErrors={errors['dealerId']}
                  >
                    {listDealers &&
                      listDealers?.map((dealer, index) => {
                        return (
                          <option key={index} value={dealer.id}>
                            {dealer.name}
                          </option>
                        );
                      })}
                  </FormSelect>
                </div>
              </div>


              <div data-grid-full-width>
                <FormCheckbox
                  id="agreement"
                  dataRegister={register('agreement')}
                  dataErrors={errors['agreement']}
                  className={css['form-field__agreement']}
                >
                  {t('label_agreement')}&nbsp;
                  <AnchorLink
                    link="/politica-de-privacidade"
                    hasArrow={false}
                    linkLabel={t('link_label_agreement')}
                  />
                </FormCheckbox>
              </div>

              <div data-grid-full-width className={css['form-submit-container']}>
                <Button
                  type="submit"
                  className={css['submit_button']}
                  disabled={Object.keys(errors).length > 0 || isDisable || isSubmitting || !formState.isValid}
                >
                  {t('button_submit_form')}
                </Button>
              </div>
            </form>
          )}
        </Section>
      </Wrapper>
    </div>
  );
}

export default DirectSalesForm;
