import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import {
  anchor,
  formTitle,
  formDescription,
  formAttractingMedia,
  formTitleSuccess,
  formContentSuccess,
  formTitleError,
  formContentError,
  spacingTopBottom
} from 'utils/meta';

class DirectSalesForm implements ContentBlock {
  label = 'Direct Sales Form';

  component = 'DirectSalesForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    formTitle,
    formDescription,
    {
      name: 'formType',
      label: 'Form type (required)',
      help: 'The type of the form (required)',
      type: 'String',
      required: true,
      control: 'select',
      options: function (tenant) {
        if (tenant?.locale === 'pt-br') {
          return [
            'DREAM_CAR',
            'TOYOTA_TOUR_CONTACT',
            'SAC'
          ];
        } else {
          return ['RECALL_CONTACT', 'QUEJAS'];
        }
      },
      defaultValue: ''
    },
    {
      name: 'bgColor',
      label: 'Wrapper backgroud color',
      help: '',
      type: 'String',
      control: 'select',
      options: ['white', 'gray']
    },
    formAttractingMedia,
    {
      name: 'comments',
      label: 'Comments',
      help: 'Extra information for target',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    formTitleSuccess,
    formContentSuccess,
    formTitleError,
    formContentError,
    spacingTopBottom

  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const locale = extra?.context?.locale;

    return {
      locale: String(locale).toLowerCase(),
      title: extra?.settings?.formTitle,
      formType: extra?.settings?.formType,
      bgColor: extra?.settings?.bgColor,
      titleSuccess: extra?.settings?.formTitleSuccess,
      contentSuccess: extra?.settings?.formContentSuccess,
      titleError: extra?.settings?.formTitleError,
      contentError: extra?.settings?.formContentError,
      description: extra?.settings?.formDescription,
      headerSpacing: extra?.settings?.spacingTopBottom || '0',
      attractingMedia: extra?.settings?.formAttractingMedia || '',
      comments: extra?.settings?.comments || ''
    };
  }
}

export { DirectSalesForm };
