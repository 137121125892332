import React, { useState } from 'react';
import i18n from 'utils/i18n';
import config from './config';

import FormSelect from 'components/atoms/form-select';
import Button from 'components/atoms/button';
import Title from 'components/atoms/title';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule RecallList
 */
function RecallList(props) {
  const t = i18n.useTranslations('components.recall-list');

  const { className = '', title, locale, recallData = [], ...other } = props;

  const { fields } = config(locale);

  const visibleItemsDefaultSize = 5;
  const [visibleItems, setVisibleItems] = useState(visibleItemsDefaultSize);

  const [selectedModelYearFilter, setSelectedModelYearFilter] = useState('placeholder');

  const modelFilterOptions = [...new Set(recallData?.map((recall) => recall?.model))];
  const [modelYearFilterOptions, setModelYearFilterOptions] = useState([]);

  const [filteredModels, setFilteredModels] = useState(recallData);
  const [data, setData] = useState(recallData);

  function onChangeModelFilter(e) {
    const selected = e?.target?.value;

    let filteredData = recallData;
    let modelYearOptions = [];

    if (selected != 'all') {
      filteredData = recallData?.filter((recall) => recall?.model?.toLowerCase()?.trim() == selected);
      modelYearOptions = [...new Set(filteredData?.map((recall) => recall?.modelYear))].sort();
    }

    setVisibleItems(visibleItemsDefaultSize);
    setSelectedModelYearFilter('placeholder');
    setModelYearFilterOptions(modelYearOptions);
    setFilteredModels(filteredData);
    setData(filteredData);
  }

  function onChangeModelYearFilter(e) {
    const selected = e?.target?.value;

    let filteredData = filteredModels;

    if (selected != 'all') {
      filteredData = filteredModels?.filter((recall) => recall?.modelYear?.trim() == selected);
    }

    setVisibleItems(visibleItemsDefaultSize);
    setSelectedModelYearFilter(selected);
    setData(filteredData);
  }

  function loadMoreCards() {
    setVisibleItems((previousValue) =>
      previousValue + visibleItemsDefaultSize > data?.length ? data?.length : previousValue + visibleItemsDefaultSize
    );
  }

  return (
    <div className={`${css['molecule__recall-list-container']} ${className}`} {...other}>
      <Title level={1} variant={7}>
        {title ? title : t('title')}
      </Title>

      <div className={css['filter-container']}>
        <FormSelect
          className={css['filter']}
          id="modelFilter"
          label={t('label_search_input')}
          placeholder={t('placeholder_model_search_input')}
          onChange={onChangeModelFilter}
        >
          <option value="all">{t('option_model_search_input')}</option>

          {modelFilterOptions?.map((model, key) => (
            <option key={key} value={model?.toLowerCase()?.trim()}>
              {model}
            </option>
          ))}
        </FormSelect>

        {fields?.showModelYearFilter && (
          <FormSelect
            className={css['filter']}
            id="modelYearfilter"
            placeholder={t('placeholder_modelYear_search_input')}
            onChange={onChangeModelYearFilter}
            value={selectedModelYearFilter}
          >
            <option value="all">{t('option_modelYear_search_input')}</option>

            {modelYearFilterOptions?.map((year, key) => (
              <option key={key} value={year?.toLowerCase()?.trim()}>
                {year}
              </option>
            ))}
          </FormSelect>
        )}
      </div>

      <ul className={css['card-list-container']}>
        {data?.slice(0, visibleItems)?.map((item, key) => (
          <li className={css['card']} key={key}>
            <div className={css['card-items']}>
              {item?.model && (
                <div className={css['card-item']}>
                  <h2>{t('card_title_model')}</h2>
                  <p>{item?.model}</p>
                </div>
              )}

              {item?.modelYear && (
                <div className={css['card-item']}>
                  <h2>{t('card_title_modelYear')}</h2>
                  <p>{item?.modelYear}</p>
                </div>
              )}

              {item?.name && (
                <div className={css['card-item']}>
                  <h2>{t('card_title_recall')}</h2>
                  <p>{item?.name}</p>
                </div>
              )}
            </div>

            {item?.medias[0]?.url && (
              <div className={css['card-link']}>
                <a href={item.medias[0].url}>{t('card_risk_title_link')}</a>
                <img alt="download icon" src="/images/recall/icn-download.svg" />
              </div>
            )}
          </li>
        ))}
      </ul>

      {visibleItems < data.length && (
        <Button size="large" boldText color="ghost-2" className={css['button']} onClick={loadMoreCards}>
          {t('button_load_more')}
          <img src="/images/recall/icn-arrow-down.svg" alt="see more icon" />
        </Button>
      )}
    </div>
  );
}

export default RecallList;
