import React, { useState, useEffect } from 'react';
import css from './styles.module.scss';
import SvgIconPlus from 'components/icons/IconPlus';
import SvgIconMinus from 'components/icons/IconMinus';

/**
 * Atom InputNumber
 *
 * Input to increment and decrement numbers
 */
function InputNumber(props) {
  const {
    className = '',
    min,
    max,
    value: controlledValue,
    initialValue,
    incrementStep,
    decrementStep,
    onChange,
    children,
    ...other
  } = props;

  const sanitizedInitialValue = Number.isFinite(initialValue) ? initialValue : Number.isFinite(min) ? min : 1;
  const sanitizedIncrementStep = incrementStep || 1;
  const sanitizedDecrementStep = decrementStep || 1;

  const [value, setValue] = useState(controlledValue ?? sanitizedInitialValue);

  useEffect(() => {
    if (controlledValue !== undefined && Number.isFinite(controlledValue)) {
      setValue(controlledValue);
    }
  }, [controlledValue]);

  const handleIncrement = () => {
    const newValue = value + sanitizedIncrementStep;
    if (max === undefined || newValue <= max) {
      setValue(newValue);
      onChange?.(newValue);
    }
  };

  const handleDecrement = () => {
    const newValue = value - sanitizedDecrementStep;
    if (min === undefined || newValue >= min) {
      setValue(newValue);
      onChange?.(newValue);
    }
  };

  return (
    <div className={`${css['atom__input-number-container']} ${className}`} {...other}>
      <button
        className={`${css['input-number-button']} ${
          min !== undefined && value <= min ? css['input-number-button--disabled'] : ''
        }`}
        onClick={handleDecrement}
        disabled={min !== undefined && value <= min}
        data-testid="decrement-button"
      >
        <SvgIconMinus alt="Decrement" className={css['input-number-icon']} />
      </button>
      <div className={css['value-container']}>
        <span className={css['input-number-value']}>{value}</span>
      </div>
      <button
        className={`${css['input-number-button']} ${
          max !== undefined && value >= max ? css['input-number-button--disabled'] : ''
        }`}
        onClick={handleIncrement}
        disabled={max !== undefined && value >= max}
        data-testid="increment-button"
      >
        <SvgIconPlus alt="Increment" className={css['input-number-icon']} />
      </button>
      {children}
    </div>
  );
}

export default InputNumber;
