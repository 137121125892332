import * as React from 'react';

const SvgIconAttention = (props) => (
<svg width={props.width ? props.width : '24'} height={props.height ? props.height : '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
<path d="M1 21L12 2L23 21H1ZM3.6 19.5H20.4L12 5L3.6 19.5ZM12.1044 18.075C12.3181 18.075 12.4958 18.0027 12.6375 17.8581C12.7792 17.7135 12.85 17.5344 12.85 17.3206C12.85 17.1069 12.7777 16.9292 12.6331 16.7875C12.4885 16.6458 12.3094 16.575 12.0956 16.575C11.8819 16.575 11.7042 16.6473 11.5625 16.7919C11.4208 16.9365 11.35 17.1156 11.35 17.3294C11.35 17.5431 11.4223 17.7208 11.5669 17.8625C11.7115 18.0042 11.8906 18.075 12.1044 18.075ZM11.35 15.3H12.85V9.7H11.35V15.3Z" fill={props.color ? props.color : 'black'}/>
</svg>
);

export default SvgIconAttention;

