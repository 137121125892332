import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import FaqList from 'components/molecules/faq-list';

/**
 * Organism FaqSection
 *
 * <!-- TODO: add a description here! -->
 */
function FaqSection(props) {
  const {
    spacingTop = '6',
    spacingBottom = '6',
    bgColor = 'default',
    sectionTitle = '',
    sectionWidth = 'default',
    invertArrow = false,
    faqContent = null,
    type = null,
    ...other
  } = props;

  return (
    <>
      <Wrapper bgColor={bgColor} data-spacing-top={spacingTop} data-spacing-bottom={spacingBottom} {...other}>
        <Section hasGrid>
        {faqContent &&
            <FaqList
              data-grid-full-width={sectionWidth == 'full-width' ? true : false}
              data-grid-recess={sectionWidth == 'default' ? '1/3' : false}
              type={type}
              title={sectionTitle}
              faqContent={faqContent}
              invertArrow={invertArrow}
              className={css['faq-list-container']}
            />}
        </Section>
      </Wrapper>
    </>
  );
}

export default FaqSection;
