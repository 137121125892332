export const defaultCountriesCodes = [
  {
    id: 1,
    code: '+549',
    name: {
      ar: 'Argentina',
      br: 'Argentina',
      us: 'Argentina'
    },
    abbreviation: 'AR',
    flag: '/images/flags/flag_of_argentina.svg.webp'
  },
  {
    id: 2,
    code: '+591',
    name: {
      ar: 'Bolivia',
      br: 'Bolívia',
      us: 'Bolivia'
    },
    abbreviation: 'BOL',
    flag: '/images/flags/flag_of_bolivia.svg.webp'
  },
  {
    id: 3,
    code: '+55',
    name: {
      ar: 'Brasil',
      br: 'Brasil',
      us: 'Brazil'
    },
    abbreviation: 'BR',
    flag: '/images/flags/flag_of_brazil.svg.webp'
  },
  {
    id: 4,
    code: '+569',
    name: {
      ar: 'Chile',
      br: 'Chile',
      us: 'Chile'
    },
    abbreviation: 'CL',
    flag: '/images/flags/flag_of_chile.svg.webp'
  },
  {
    id: 5,
    code: '+595',
    name: {
      ar: 'Paraguay',
      br: 'Paraguai',
      us: 'Paraguay'
    },
    abbreviation: 'PY',
    flag: '/images/flags/flag_of_paraguay.svg.webp'
  },
  {
    id: 6,
    code: '+598',
    name: {
      ar: 'Uruguay',
      br: 'Uruguai',
      us: 'Uruguay'
    },
    abbreviation: 'UY',
    flag: '/images/flags/flag_of_uruguay.svg.webp'
  }
];
