import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

class CarDetailsActionCard implements ContentBlock {
  label = 'Car Details Action Card';

  component = 'CarDetailsActionCard';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [];

  properties = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    return {};
  }
}

export { CarDetailsActionCard };
