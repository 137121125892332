import { dateBr2Iso, empty, getAttrMFromSessionStorage, getDateAndTime, isValidCPF, slugfy } from 'utils/functions';
import { formReasons as contactFormReasons } from 'utils/repo/salesForce';
import { captchaToken } from 'utils/captcha/token';
import { isTextMask, removeSpecialCharacters, sendRequest } from 'utils/forms';
import { cpfMask, phoneMask } from 'utils/validations';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import ContentDivider from 'components/atoms/content-divider';
import FormCheckbox from 'components/atoms/form-checkbox';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import getConfig from 'next/config';
import i18n from 'utils/i18n';
import React, { useState, useEffect, useCallback } from 'react';
import Script from 'next/script';
import Section from 'components/utils/section';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import Title from 'components/atoms/title';
import Wrapper from 'components/atoms/wrapper';
import SectionHeader from 'components/molecules/section-header';

// loading the sass style fot the component
import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

/**
 * Molecule InterestForm
 *
 * <!-- TODO: add a description here! -->
 */

function InterestForm(props) {
  //const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.interest-form');

  const {
    className = '',
    models = [],
    dealers = [],
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    title,
    description,
    headerSpacing,
    attractingMedia = undefined,
    locale,
    contacted = 'Concesionarios oficiales',
    // settings of ContentBlock not need here, just to drop from 'other'
    allowedModels,
    allowedDealers,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    checkFormStatus = () => {},
    ...other
  } = props;

  const attractingMediaParam = getAttrMFromSessionStorage() || attractingMedia || null;

  const country = `${locale}`.slice(-2);
  const contactReasons = country === 'br' ? {} : contactFormReasons;
  const isLocaleTdb = locale === 'pt-br';
  const isLocaleTasa = locale === 'es-ar';

  const validationSchemaConfig = {
    firstName: yup
      .string()
      .trim()
      .required(t('message_error_required_firstName'))
      .min(3, t('message_error_min_char_firstName')),
    lastName: yup
      .string()
      .trim()
      .required(t('message_error_required_lastName'))
      .min(3, t('message_error_min_char_lastName')),
    email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
    state: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_state'))
      .required(t('message_error_required_state')),
    city: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_city'))
      .required(t('message_error_required_city')),
    dealership: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_dealership'))
      .required(t('message_error_required_dealership')),
    agreement: yup.boolean().oneOf([true], t('message_error_required_agreement')),
    isToyotaCustomer: yup.boolean(),
    wantATestDrive: yup.boolean(),
    wantToFinance: yup.boolean(),
    usedVehicleInExchange: yup.boolean()
  };

  if (isLocaleTasa) {
    validationSchemaConfig['consultReason'] = yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_reason'))
      .required(t('message_error_required_reason'));
  }

  if (isLocaleTdb) {
    validationSchemaConfig['carModel'] = yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_model'))
      .required(t('message_error_required_model'));
    validationSchemaConfig['telephone'] = yup
      .string()
      .required(t('message_error_required_telephone'))
      .min(14, t('message_error_min_char_telephone'));
    validationSchemaConfig['customerId'] = yup
      .string()
      .required(t('message_error_required_cpf'))
      .test('test-invalid-cpf', t('message_error_invalid_cpf'), (cpf) => isValidCPF(cpf));
  }

  const validationSchema = yup.object().shape(validationSchemaConfig);

  const {
    register,
    reset,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      isToyotaCustomer: false,
      agreement: false,
      wantATestDrive: false,
      wantToFinance: false,
      usedVehicleInExchange: false
    }
  });

  const watchedFields = watch([
    'firstName',
    'lastName',
    'email',
    'state',
    'city',
    'dealership'
  ]);

  const watchedTelephone = watch('telephone');
  const watchedCpf = watch('customerId');
  const watchedModel = watch('carModel');
  const watchedState = watch('state');
  const watchedCity = watch('city');

  const [isDisable, setIsDisable] = useState(true);
  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedModels, setSelectedModels] = useState('');
  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);

  const requestDate = getDateAndTime();
  const formatRequestDate = dateBr2Iso(requestDate.currentDate).replace(/-/g, '/');

  useEffect(() => {
    verifyModelFromURL();
  }, []);

  function verifyModelFromURL() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });

    const modelFromURL = params.model || null;

    if (!modelFromURL) {
      return null;
    }

    const selectedModel = models.find((item) => {
      const itemName = slugfy(item?.name).toLowerCase().trim();
      const urlName = slugfy(modelFromURL).toLowerCase().trim();

      return itemName === urlName;
    });

    if (selectedModel) {
      setValue('carModel', selectedModel?.name);
      setSelectedModels(watchedModel);
    }
  }

  const resetForm = useCallback(() => {
    const resultData = {};
    reset(resultData);
  }, [reset]);

  function onBackForm() {
    setIsStatusComponent('default');
  }

  function validateForm() {
    let isFormEmpty = watchedFields.some(
      (value) => empty(value) || value === 'placeholder' || (typeof value === 'string' && value?.trim() === '')
    );

    if (isLocaleTdb) {
      isFormEmpty = isFormEmpty || empty(watchedModel) || watchedModel === 'placeholder';
      isFormEmpty = isFormEmpty || empty(watchedCpf) || !watchedCpf.trim();
      isFormEmpty = isFormEmpty || empty(watchedTelephone) || !watchedTelephone.trim();
    }

    setIsDisable(isFormEmpty);
  }

  function loadStates() {
    setListStates([...new Set(dealers?.map((state) => state?.address?.state))].sort());
  }

  function loadCities(selectedState) {
    const filtered = dealers?.filter((state) => {
      return state?.address?.state.toUpperCase() === selectedState?.toUpperCase();
    });
    const resultCities = [...new Set(filtered?.map((city) => city?.address?.city?.toUpperCase()))].sort();

    setListCities(resultCities);
  }

  function loadDealers(selectedCity) {
    const filtered = dealers?.filter((dealer) => {
      return dealer?.address?.city.toUpperCase() === selectedCity?.toUpperCase();
    });
    setListDealers(filtered);
  }

  function onChangeModel(e) {
    const selected = e.currentTarget.value;
    setValue('carModel', selected);
    validateForm();
  }

  function onChangeState(e) {
    const selected = e.currentTarget.value;
    setValue('city', 'placeholder');
    setValue('dealership', 'placeholder');
    setValue('state', selected);
  }

  function onChangeCity(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', 'placeholder');
    setValue('city', selected);
  }

  function onChangeDealers(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', selected);
  }

  const findAndSetSelectedModels = (modelList, watchedModel) => {
    const model = modelList.find((model) => model.name === watchedModel);

    setSelectedModels(model || null);
  };

  function generateCommentsByCheckboxes() {
    let comments = '';

    comments = getValues('isToyotaCustomer') ? t('label_client') : comments;
    comments = getValues('wantATestDrive') ? `${comments}; ${t('label_drive')}` : comments;
    comments = getValues('wantToFinance') ? `${comments}; ${t('label_finance')}` : comments;
    comments = getValues('usedVehicleInExchange') ? `${comments}; ${t('label_used_car')}` : comments;

    return comments;
  }

  const getRecordTypeValue = (selectedOption) => {
    switch (selectedOption) {
      case 'Plan de ahorro':
        return '0123i000000UtrX';
      case 'Información de producto':
      case 'Financiación':
      case 'Test Drive':
        return '0123i000000UtrS';
      default:
        return '';
    }
  };

  const isFormPosVenta = (consultReason) => String(consultReason) === 'Pos Venta';

  const getActionAndUrl = (consultReason) => {
    const isPosVenta = isFormPosVenta(consultReason);

    const actionMapping = {
      false: { action: 'LEAD', url: '/api/lead' },
      true: { action: 'CONTACT', url: '/api/contact' }
    };

    return actionMapping[isPosVenta];
  };

  function getDealerSelect(selectedDealerName) {
    const selectedDealer = listDealers?.find(
      (dealer) => dealer?.name.toUpperCase() === selectedDealerName?.toUpperCase()
    );

    return selectedDealer;
  }

  function createUpdatedData(data) {
    const selectedDealer = getDealerSelect(data?.dealership);
    const selectedDealerDn = selectedDealer?.dn;

    const isPosVenta = isFormPosVenta(data?.consultReason);

    data.concessionaireOfInterest = data?.dealership;
    data.dn = selectedDealerDn;
    data.locale = locale;
    data.attractingMedia = attractingMediaParam;
    data.externalColor = {
      name: selectedModels?.externalColor?.name,
      code: selectedModels?.externalColor?.code
    };
    data.internalColor = {
      name: selectedModels?.internalColor?.name,
      code: selectedModels?.internalColor?.code
    };
    data.price = selectedModels?.price;
    data.quantity = 1;
    data.carModel = isLocaleTdb ? selectedModels?.name : selectedModels?.vehicleExternalId || '';
    data.version = selectedModels?.version;
    data.concessionaireOfInterest = selectedDealer?.name;
    data.dn = selectedDealer?.dn;
    data.katashikiCode = selectedModels?.katashiki;
    data.prodYear = selectedModels?.prodYear;
    data.lastUpdateDate = formatRequestDate;
    data.requestDate = formatRequestDate;
    data.type = '01 – VEÍCULO DE INTERESSE';
    data.salesforce = true;
    data.suffixCode = selectedModels?.suffixCode;
    data.comments = generateCommentsByCheckboxes() || '';

    if (isLocaleTasa) {
      data.recordType = getRecordTypeValue(data.consultReason);
      data.consultReason = getValues('consultReason') || '';
      data.contacted = contacted;
      delete data?.type;
      delete data?.lastUpdateDate;
      delete data?.requestDate;
    }

    if (isLocaleTasa && !isPosVenta) {
      delete data?.concessionaireOfInterest;
      delete data?.externalColor;
      delete data?.internalColor;
      delete data?.price;
      delete data?.version;
      delete data?.katashikiCode;
      delete data?.prodYear;
      delete data?.salesforce;
      delete data?.suffixCode;
    }

    if (isLocaleTasa && isPosVenta) {
      data = {
        ...data,
        dealerId: selectedDealer?.dealerId,
        type: 'POS_VENTA'
      };

      delete data.nome;
    }

    delete data.dealership;
    delete data.agreement;

    if (isLocaleTdb) {
      data.customerId = removeSpecialCharacters(data?.customerId);
    }

    return data;
  }

  const handleResponse = (success) => {
    if (success) {
      setIsStatusComponent('success');
      resetForm(reset);
      return;
    }
    setIsStatusComponent('error');
    return;
  };

  const onSubmit = async (fields, event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const consultReasonValue = fields?.consultReason;
      const { action, url } = getActionAndUrl(consultReasonValue);

      const captchaPayload = await captchaToken(action, publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
      if (!captchaPayload) {
        console.debug('Recaptcha verification failed.');
        handleResponse(false);
        return;
      }

      fields.captchaPayload = captchaPayload;

      const updateData = createUpdatedData(fields);

      const response = await sendRequest('POST', url, updateData);

      handleResponse(response.ok && response.status === 200);
    } catch (error) {
      handleResponse(false);
      console.error('Error while submitting the form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onError = (errors, e) => console.log(errors, e);

  useEffect(() => {
    checkFormStatus(isStatusComponent);
  }, [isStatusComponent]);

  useEffect(() => {
    validateForm();
  }, [watchedFields]);

  useEffect(() => {
    findAndSetSelectedModels(models, watchedModel);
  }, [watchedModel]);

  useEffect(() => {
    loadCities(watchedState);
  }, [watchedState]);

  useEffect(() => {
    loadDealers(watchedCity);
  }, [watchedCity]);

  useEffect(() => {
    loadStates();
  }, []);

  return (
    <Wrapper spacing={headerSpacing} className={`${css['molecule__interest-form-container']} ${className}`} {...other}>
      <Section>
        <Script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
        ></Script>

        {isStatusComponent === 'success' && (
          <SuccessSubmitForm
            className={css['result_submit']}
            onBackForm={onBackForm}
            titleSuccess={titleSuccess ? titleSuccess : t('success_title')}
            contentSuccess={contentSuccess ? contentSuccess : t('success_content')}
            contentBackForm={t('interest_content_back_form')}
            contentBackHome={t('interest_content_back_home')}
          />
        )}
        {isStatusComponent === 'error' && (
          <SuccessSubmitForm
            className={css['result_submit']}
            onBackForm={onBackForm}
            titleSuccess={titleError ? titleError : t('error_title')}
            contentSuccess={contentError ? contentError : t('error_content')}
            contentBackForm={t('interest_content_back_form')}
            contentBackHome={t('interest_content_back_home')}
          />
        )}
        {isStatusComponent === 'default' && (
          <>
            {title && (
              <SectionHeader
                title={title ? title : t('title_form')}
                className={css['form-container_header']}
                description={{
                  value: description ? description : t('description_form'),
                  textColor: 'gray-4'
                }}
              />
            )}

            <form onSubmit={handleSubmit(onSubmit, onError)} className={css['form-container']}>
              {isLocaleTasa && (
                <div className={css['form-container__section']}>
                  <Title className={css['form-container__title']} variant={5}>
                    {t('select_consult_reason')}
                  </Title>
                  <FormSelect
                    className={css['form-container__input']}
                    id="consultReason"
                    placeholder={t('placeholder_consult_reason')}
                    dataRegister={register('consultReason')}
                    displayLabel={false}
                    dataErrors={errors['consultReason']}
                  >
                    {Object.keys(contactReasons)?.map((v, i) => {
                      return (
                        <option key={`contact-reason-${i}`} value={contactReasons[v]}>
                          {v}
                        </option>
                      );
                    })}
                  </FormSelect>
                </div>
              )}

              <div className={css['form-container__section']}>
                <Title className={css['form-container__title']} variant={5}>
                  {t('select_model_interest')}
                </Title>
                <FormSelect
                  className={css['form-container__input']}
                  id="carModel"
                  placeholder={t('placeholder_model_interest')}
                  onChange={onChangeModel}
                  dataRegister={register('carModel')}
                  displayLabel={false}
                  dataErrors={errors['carModel']}
                >
                  {models &&
                    models?.map((model, index) => {
                      return (
                        <option key={index} value={model?.name}>
                          {model?.name}
                        </option>
                      );
                    })}
                </FormSelect>
              </div>
              <div className={css['form-container__section']}>
                <Title className={css['form-container__title']} variant={5}>
                  {t('title_section_personal_data')}
                </Title>
                <div className={css['form-container__list-input']}>
                  <FormInput
                    className={css['form-container__input']}
                    id="firstName"
                    label={t('label_first_name')}
                    placeholder={t('placeholder_first_name')}
                    dataRegister={register('firstName')}
                    dataErrors={errors['firstName']}
                    maxLength="50"
                    type="text"
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isTextMask(value);
                    }}
                  />
                  <FormInput
                    className={css['form-container__input']}
                    id="lastName"
                    label={t('label_last_name')}
                    placeholder={t('placeholder_last_name')}
                    dataRegister={register('lastName')}
                    dataErrors={errors['lastName']}
                    maxLength="50"
                    type="text"
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isTextMask(value);
                    }}
                  />

                  {isLocaleTdb && (
                    <FormInput
                      displayLabel={true}
                      className={css['form-container__input']}
                      id="customerId"
                      label={t('label_cpf')}
                      placeholder={t('placeholder_cpf')}
                      type="tel"
                      dataRegister={register('customerId')}
                      dataErrors={errors['customerId']}
                      maxLength="14"
                      masking={(event) => {
                        const value = event.target.value;
                        event.target.value = cpfMask(value);
                      }}
                    />
                  )}
                  <FormInput
                    className={css['form-container__input']}
                    id="email"
                    label={t('label-email')}
                    placeholder={t('placeholder_email')}
                    dataRegister={register('email')}
                    dataErrors={errors['email']}
                    type="email"
                    maxLength="50"
                  />

                  <FormInput
                    className={css['form-container__input']}
                    id="telephone"
                    label={t('label_telephone')}
                    placeholder={t('placeholder_telephone')}
                    dataRegister={register('telephone')}
                    dataErrors={errors['telephone']}
                    type="tel"
                    maxLength={isLocaleTdb ? '15' : '17'}
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isLocaleTdb ? phoneMask(value) : value;
                    }}
                  />
                </div>
              </div>

              <div className={css['form-container__section']}>
                <Title className={css['form-container__title']} variant={5}>
                  {t('title_section_dealership')}
                </Title>
                <div className={css['form-container__list-input']}>
                  <FormSelect
                    className={css['form-container__input']}
                    id="state"
                    label={t('label_state')}
                    placeholder={t('placeholder_state')}
                    onChange={onChangeState}
                    dataRegister={register('state')}
                    dataErrors={errors['state']}
                  >
                    {listStates?.length > 0 &&
                      listStates?.map((state, index) => {
                        return (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    className={css['form-container__input']}
                    id="city"
                    label={t('label_city')}
                    placeholder={t('placeholder_city')}
                    onChange={onChangeCity}
                    dataRegister={register('city')}
                    dataErrors={errors['city']}
                  >
                    {watchedState &&
                      listCities?.map((city, index) => {
                        return (
                          <option key={index} value={city}>
                            {city}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    className={css['form-container__input']}
                    id="dealership"
                    label={t('label_dealership')}
                    placeholder={t('placeholder_dealership')}
                    onChange={onChangeDealers}
                    dataRegister={register('dealership')}
                    dataErrors={errors['dealership']}
                  >
                    {watchedCity &&
                      listDealers?.map((dealer, index) => {
                        return (
                          <option key={index} value={dealer?.name}>
                            {dealer?.name?.toUpperCase()}
                          </option>
                        );
                      })}
                  </FormSelect>
                </div>
              </div>

              <div className={css['form-container__section']}>
                {isLocaleTdb && (
                  <>
                    <Title className={css['form-container__title']} variant={5}>
                      Selecione quando necessário
                    </Title>
                    <div className={css['form-container__checkbox']}>
                      <FormCheckbox
                        className={css['form-container__checkbox-client']}
                        id="isToyotaCustomer"
                        label={t('label_client')}
                        dataRegister={register('isToyotaCustomer')}
                        dataErrors={errors['isToyotaCustomer']}
                        disabled={isDisable}
                      />
                      <FormCheckbox
                        className={css['form-container__checkbox-finance']}
                        id="wantToFinance"
                        label={t('label_finance')}
                        dataRegister={register('wantToFinance')}
                        onClick={validateForm}
                        dataErrors={errors['wantToFinance']}
                        disabled={isDisable}
                      />

                      <FormCheckbox
                        className={css['form-container__checkbox-used_car']}
                        id="usedVehicleInExchange"
                        label={t('label_used_car')}
                        dataRegister={register('usedVehicleInExchange')}
                        dataErrors={errors['usedVehicleInExchange']}
                        disabled={isDisable}
                      />

                      <FormCheckbox
                        className={css['form-container__checkbox-test']}
                        id="wantATestDrive"
                        label={t('label_drive')}
                        dataRegister={register('wantATestDrive')}
                        placeholder=""
                        dataErrors={errors['wantATestDrive']}
                        disabled={isDisable}
                      />
                    </div>
                  </>
                )}

                <ContentDivider />
                <FormCheckbox
                  className={css['form-container__checkbox-agreement']}
                  id="agreement"
                  dataRegister={register('agreement')}
                  dataErrors={errors['agreement']}
                  disabled={isDisable}
                >
                  {t('label_agreement')}&nbsp;
                  <AnchorLink
                    link={isLocaleTdb ? '/politica-de-privacidade' : '/terminos-y-condiciones'}
                    hasArrow={false}
                    disabled={isDisable}
                    linkLabel={t('link_label_agreement')}
                  />
                </FormCheckbox>
              </div>

              <div className={css['form-container__section']}>
                <Button disabled={!formState?.isValid || isSubmitting} type="submit">
                  {t('button_submit_form')}
                </Button>
              </div>
            </form>
          </>
        )}
      </Section>
    </Wrapper>
  );
}

export default InterestForm;
