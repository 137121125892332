import { formatDateByCountry } from 'utils/functions';

const baseFunctions = {
  onError: (error) => {
    console.log('Error: ', error);
  }
};

const functions = {
  'es-VE': {
    onSubmit: async function onSubmit(params, setRecallData, reset) {
      try {
        const response = await fetch(
          `/api/recall/getRecallTDV?documentType=V&document=${params?.customerId}&name=${params?.name}&lastName=${params?.lastName}&phone=${params?.cellphone}&email=${params?.email}&serialVin=${params?.vehicleIdentificationNumber}`
        );

        if (!response?.ok) {
          throw new Error(`Request error: ${response?.statusText}`);
        }

        const { data } = await response.json();

        if (!data || !data?.recallFromTDVApi) {
          throw new Error('Invalid response format: Missing recallFromTDVApi');
        }

        const { recallFromTDVApi } = data || null;

        let recallSearchData;

        if (recallFromTDVApi?.exists && !recallFromTDVApi?.attended) {
          recallSearchData = {
            cardVariation: 1,
            recallCampaigns: recallFromTDVApi?.recallCampaigns,
            fields: {
              title:
                'Tu vehículo cuenta con una, o más, campañas de servicio pendiente. Comunícate con el concesionario oficial de tu zona y coordina una visita.',
              recall_title: 'Recall',
              tag: {
                type: 'attention',
                text: 'Pendiente'
              }
            }
          };
        } else if (recallFromTDVApi?.exists && recallFromTDVApi?.attended) {
          recallSearchData = {
            cardVariation: 2,
            fields: {
              title: 'Las campañas de tu vehículo ya fueron reparadas.',
              dealer_title: 'Concesionario donde se realizó',
              date_title: 'Fecha',
              tag: {
                type: 'success',
                text: 'Reparado'
              }
            },
            dealer: recallFromTDVApi?.dealer,
            approvationDate: recallFromTDVApi?.approvationDate
          };
        } else {
          recallSearchData = {
            cardVariation: 3,
            fields: {
              title: 'Las campañas de tu vehículo ya fueron reparadas.',
              // eslint-disable-next-line max-len
              text: 'Ante cualquier duda, sugerimos comunicarte con nuestro Centro de Atención al Cliente: [rclientes@toyota.com.ve](mailto:rclientes@toyota.com.ve) (lunes a viernes de 8:00 a.m. a 12:00 p.m. y de 1:00 p.m. a 5:00 p.m.)',
              tag: {
                type: 'success',
                text: null
              }
            }
          };
        }

        setRecallData({
          searched: true,
          data: {
            vin: params.vehicleIdentificationNumber,
            ...recallSearchData
          }
        });

        reset();
      } catch (error) {
        console.error('Error fetching vehicle data:', error);
      }
    },
    onError: baseFunctions['onError']
  },

  'es-AR': {
    onSubmit: async function onSubmit(params, setRecallData, reset) {
      try {
        const response = await fetch(`/api/recall?chassiOrPatent=${params?.vehicleIdentificationNumber}&locale=es-AR`);

        if (!response.ok) {
          throw new Error(`Request error: ${response.statusText}`);
        }

        const { data } = await response.json();

        if (!data || !data.recallVehiclesByChassiOrPatent) {
          throw new Error('Invalid response format: Missing recallVehiclesByChassiOrPatent');
        }

        const { recallVehiclesByChassiOrPatent } = data || null;

        let recallSearchData;

        if (recallVehiclesByChassiOrPatent.status) {
          recallSearchData = {
            cardVariation: 3,
            fields: {
              title: 'Tu vehículo cuenta con una campaña especial de servicio pendiente de revisión.',
              // eslint-disable-next-line max-len
              text: 'Es necesario que te comuniques con el concesionario oficial de tu zona y coordines una visita, la revisión es gratuita. Ante cualquier duda comunícate con nuestro Centro de Atención al cliente:0800-888-8696',
              tag: {
                type: 'attention',
                text: null
              }
            },
            showContactButton: true
          };
        } else {
          recallSearchData = {
            cardVariation: 3,
            fields: {
              title:
                'Tu vehículo no se encuentra alcanzado por ninguna de las campañas especiales de servicio vigente.',
              // eslint-disable-next-line max-len
              text: 'Ante cualquier duda sugerimos comunicarse con nuestro Centro de Atención al Cliente al: 0800-888-8696(Lunes a Viernes de 9:00 a 13:00 hs. y de 14:00 a 17:00 hs.).',
              tag: {
                type: 'success',
                text: null
              }
            },
            showContactButton: false
          };
        }

        setRecallData({
          searched: true,
          data: {
            vin: params.vehicleIdentificationNumber,
            ...recallSearchData
          }
        });

        reset();
      } catch (error) {
        console.error('Error fetching vehicle data:', error);
      }
    },
    onError: baseFunctions['onError']
  },

  'pt-BR': {
    onSubmit: async function onSubmit(params, setRecallData, reset) {
      try {
        const response = await fetch(`/api/recall/detran?chassiOrBoard=${params?.vehicleIdentificationNumber}`);

        if (!response.ok) {
          throw new Error(`Request error: ${response.statusText}`);
        }

        const { data } = await response.json();

        if (!data || !data.recallByChassiOrBoardViaDetran) {
          throw new Error('Invalid response format: Missing recallByChassiOrBoardViaDetran');
        }

        const { recallByChassiOrBoardViaDetran } = data || null;

        let recallSearchData;

        if (recallByChassiOrBoardViaDetran.length > 0) {
          if (recallByChassiOrBoardViaDetran[0]?.repairStatus != 'DONE') {
            recallSearchData = {
              cardVariation: 1,
              fields: {
                tag: {
                  type: 'attention',
                  text: 'Pendente'
                },
                risk_title_link: 'Aviso de risco',
                recall_title: 'Recall'
              },
              recallCampaigns: [
                {
                  riskLink: recallByChassiOrBoardViaDetran[0]?.file?.url,
                  name: recallByChassiOrBoardViaDetran[0]?.name
                }
              ]
            };
          } else {
            recallSearchData = {
              cardVariation: 2,
              fields: {
                tag: {
                  type: 'success',
                  text: 'Reparado'
                },
                recall_title: 'Recall',
                dealer_title: 'Concessionária',
                date_title: 'Data e Hora',
                risk_title_link: 'Aviso de risco',
                repair_title: 'Certificado de Reparo'
              },
              dealer: recallByChassiOrBoardViaDetran[0]?.dealership?.name,
              approvationDate: formatDateByCountry(recallByChassiOrBoardViaDetran[0]?.repairDate, 'pt-BR'),
              recall: recallByChassiOrBoardViaDetran[0]?.name,
              riskLink: recallByChassiOrBoardViaDetran[0]?.file?.url,
              repairLink: {
                chassi: recallByChassiOrBoardViaDetran[0]?.chassi,
                model: recallByChassiOrBoardViaDetran[0]?.model,
                name: recallByChassiOrBoardViaDetran[0]?.name,
                denatranCode: recallByChassiOrBoardViaDetran[0]?.denatranCode,
                description: recallByChassiOrBoardViaDetran[0]?.description,
                repairDate: formatDateByCountry(recallByChassiOrBoardViaDetran[0]?.repairDate, 'pt-BR'),
                duration: recallByChassiOrBoardViaDetran[0]?.duration,
                dealership: recallByChassiOrBoardViaDetran[0]?.dealership
              }
            };
          }
        } else {
          recallSearchData = {
            fields: {
              title: 'O seu veículo não está abrangido por nenhuma das campanhas de serviços especiais em vigor.',
              tag: {
                type: 'success',
                text: null
              }
            },
            cardVariation: 3
          };
        }

        setRecallData({
          searched: true,
          data: {
            vin: params.vehicleIdentificationNumber,
            ...recallSearchData
          }
        });

        reset();
      } catch (error) {
        console.error('Error fetching vehicle data:', error);
      }
    },
    onError: baseFunctions['onError']
  }
};

export default functions;
