import React, { useState } from 'react';
import SvgIconArrowUp from 'components/icons/ArrowUp';
import SvgIconArrowDown from 'components/icons/ArrowDown';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom CustomSelectorColor
 *
 * <!-- TODO: add a description here! -->
 */
function CustomSelectorColor(props) {
  const [isOpen, setIsOpen] = useState(false);


  const {
    className = "",
    children,
    onColorChange,
    listColors = [],
    selectedColor,
    ...other
  } = props;


  return <div
    className={`${css["atom__custom-selector-color-container"]} ${className}`}
    {...other}
  >

    <div className={css["custom-selector-color-selector"]}>
      <div
        className={css["custom-selector-color-selected"]}
        onClick={() => setIsOpen(!isOpen)}
        role="button"
        aria-haspopup="true"
        aria-expanded={isOpen}
        tabIndex={0}
        onKeyDown={(e) => e.key === 'Enter' && setIsOpen(!isOpen)}
      >
        <div className={css["custom-selector-color-selected-content"]}>
          <p className={css["custom-selector-color-selected-content-color-name"]}>{selectedColor?.name}</p>
          {selectedColor?.rgb && <span className={css['custom-selector-color-circle']} style={{ backgroundColor: selectedColor?.rgb }}></span>}
        </div>
        <span className={css["custom-selector-color-arrows"]}>
          {isOpen ? (
            <SvgIconArrowUp />
          ) : (
            <SvgIconArrowDown />
          )}
        </span>
      </div>
      {isOpen && (
        <div className={css["custom-selector-color-color-options"]}>
          {listColors.length > 0 && listColors?.filter((color) => color?.name !== selectedColor?.name)
            .map((color) => (
              <div
                key={color?.name}
                className={css["custom-selector-color-color-option"]}
                onClick={() => {
                  onColorChange(color);
                  setIsOpen(false);
                }}
                role="option"
                aria-selected={selectedColor?.name === color.name}
                tabIndex={0}
                onKeyDown={(e) => e.key === 'Enter' && onColorChange(color) && setIsOpen(false)}
              >
                <span>{color.name}</span>
                {color?.rgb && <span className={css['custom-selector-option-circle']} style={{ backgroundColor: color?.rgb }}></span>}
              </div>
            ))}
        </div>
      )}
    </div>

    {children}
  </div>;
}

export default CustomSelectorColor;
