import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';
import { currency } from 'utils/functions';

import Button from 'components/atoms/button';
import InputNumber from 'components/atoms/input-number';
import { IconClose, IconDelete, IconVehicle } from 'components/icons';

// loading the sass style fot the component
import css from './styles.module.scss';




/**
 * Molecule CarCartSummary
 *
 * <!-- TODO: add a description here! -->
 */
function CarCartSummary(props) {
  const t = i18n.useTranslations('components.car-cart-summary');

  const {
    className = "",
    children,
    items = [],
    onQuantityChange,
    onRemoveItem,
    hidePrice = false,
    locale = "pt-br",
    ...other
  } = props;

  const isTDB = String(locale).toUpperCase() === 'PT-BR';
  const currencySymbol = isTDB ? 'BRL' : 'USD';

  const [isOpen, setIsOpen] = useState(false);
  const defaultNotImage = '/images/image-car-not-available-text.webp';

  useEffect(() => {
    document.addEventListener('mousedown', closeCartOnClickOutside);
    return () => {
      document.removeEventListener('mousedown', closeCartOnClickOutside);
    };
  }, []);

  useEffect(() => {
    if (items?.length === 0) {
      setIsOpen(false);
    }
  }, [items]);

  const toggleCartVisibility = () => {
    setIsOpen(!isOpen);
  };

  const closeCartOnClickOutside = (event) => {
    if (!event.target.closest(`.${css["molecule__car-cart-summary-container"]}`)) {
      setIsOpen(false);
    }
  };

  const getTotalQuantity = (items) => {
    return items.reduce((total, item) => total + item.quantity, 0);
  };

  const getTotalPrice = (items) => {
    return items.reduce((total, item) => {
      return total + (item.price || 0) * (item.quantity || 0);
    }, 0);
  };


  const updateQuantity = (itemId, newQuantity) => {
    if (onQuantityChange) {
      onQuantityChange(itemId, newQuantity);
    }
  };

  const removeItemFromCart = (itemId) => {
    if (onRemoveItem) {
      onRemoveItem(itemId);
    }
  };

  const findMobileMedia = (medias, colorCode) => {
    if (!medias || !Array.isArray(medias)) { return defaultNotImage; }
    const mobileMedia = medias.find(media => media.tag === `model:mob:${colorCode}`);
    return mobileMedia;
  };

  const continueToNextStep = () => {
    console.log("Total de itens no carrinho:", getTotalQuantity(items));
    console.log("Preço total:", getTotalPrice(items));
    console.log("Lista de itens selecionados:", items);
    // Add logic here to advance to the next step
  };

  return <div
    className={`${css["molecule__car-cart-summary-container"]} ${className}`}
    {...other}
  >
    {!isOpen &&
      <Button className={css["cart-summary-button"]} color="ghost-2" disabled={items.length === 0} onClick={toggleCartVisibility}>
        <span className={css["cart-icon"]}></span>
        <IconVehicle />
        <span>{t('your_car_list')}</span>
        {items?.length > 0 && <span className={css["cart-count"]}>{getTotalQuantity(items)}</span>}
      </Button>
    }

    {isOpen && (
      <div className={css["container-open"]}>
        <div className={css["cart-header"]}>
          <span className={css["cart-header-text"]}>{t('your_car_list')}</span>
          <IconClose className={css["icon-close"]} onClick={toggleCartVisibility} />
        </div>
        <div className={css["cart-list"]}>

          {items.map((item, index) => {
            const mobileMediaUrl = findMobileMedia(item.medias, item?.color?.code);
            const isPrice = item?.price;
            return (
              <div key={index}>
                <div className={css["cart-item"]}>
                  <img className={css["cart-item-image"]} src={mobileMediaUrl?.media?.url || defaultNotImage} alt={item?.model || ""} />
                  <div className={css["cart-item-details"]}>
                    <div className={css['cart-item-details-info']}>
                      <p className={css['details-info-model']}>{item?.model}</p>
                      <p className={css['details-info-color']}>{item?.color?.name}</p>
                    </div>
                    {!hidePrice && isPrice &&
                      <p className={css['details-info-price']}> {currency(isPrice, locale, currencySymbol)}</p>
                    }
                    <div className={css["details-input-remove"]}>
                      <InputNumber
                        className={css["input-number"]}
                        min={1}
                        initialValue={item.quantity}
                        incrementStep={1}
                        decrementStep={1}
                        onChange={(newQuantity) => updateQuantity(index, newQuantity)}
                      />
                      <IconDelete className={css["remove-item-button"]} onClick={() => removeItemFromCart(index)} />
                    </div>
                  </div>
                </div>
                {<hr className={css["divider"]} />}
              </div>
            );
          })}
        </div>
        <div className={css["cart-footer"]}>
          {hidePrice ? (
            <p className={css["price-message"]}>{t('price_message')}</p>
          ) : (
            <div className={css["total-price"]}>
              <span>{t("total")}</span>
              <span>{currency(getTotalPrice(items), locale, currencySymbol)}</span>
            </div>
          )}
          <Button className={css["continue-button"]} onClick={continueToNextStep}>{t('label_continue_button')}</Button>
        </div>
      </div>
    )
    }
    {children}
  </div >;
}

export default CarCartSummary;
