export const vehicleDataMock = {
  "id": "0c032e9b-fc4d-425c-8fa4-db0e1566e374",
  "name": "Corolla",
  "vehicleExternalId": null,
  "status": "ENABLED",
  "versions": [
    {
      "name": "GLi",
      "order": 1,
      "modelYears": [
        {
          "vehicleColors": [
            {
              "price": 149290,
              "offers": {
                "afterPrice": 149290,
                "beforePrice": 139290
              },
              "type": "fleet",
              "color": {
                "name": "Branco Polar (040)",
                "code": "040",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "2",
                      "path": "f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "url": "https://staging-media.toyota.com.br/f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "corolla-gr-sport",
                      "path": "de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "url": "https://staging-media.toyota.com.br/de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "2",
                      "path": "f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "url": "https://staging-media.toyota.com.br/f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 151310,
              "offers": {
                "afterPrice": 149295,
                "beforePrice": 139280
              },
              "type": "fleet",
              "color": {
                "name": "Cinza Celestial (1K3)",
                "code": "1K3",
                "rgb": "#8E9DA7"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "2",
                      "path": "f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "url": "https://staging-media.toyota.com.br/f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "corolla-gr-sport",
                      "path": "de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "url": "https://staging-media.toyota.com.br/de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "2",
                      "path": "f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "url": "https://staging-media.toyota.com.br/f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 151310,
              "offers": null,
              "color": {
                "name": "Prata Supernova (1E7)",
                "code": "1E7",
                "rgb": "#D5D6D8"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "2",
                      "path": "f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "url": "https://staging-media.toyota.com.br/f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "corolla-gr-sport",
                      "path": "de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "url": "https://staging-media.toyota.com.br/de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "2",
                      "path": "f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "url": "https://staging-media.toyota.com.br/f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 151310,
              "offers": null,
              "color": {
                "name": "Vermelho Granada (3R3)",
                "code": "3R3",
                "rgb": "#7E1F23"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "2",
                      "path": "f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "url": "https://staging-media.toyota.com.br/f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "corolla-gr-sport",
                      "path": "de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "url": "https://staging-media.toyota.com.br/de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "2",
                      "path": "f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "url": "https://staging-media.toyota.com.br/f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 151310,
              "offers": null,
              "color": {
                "name": "Preto Eclipse (209) ",
                "code": "209",
                "rgb": "#202021"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "2",
                      "path": "f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "url": "https://staging-media.toyota.com.br/f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "corolla-gr-sport",
                      "path": "de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "url": "https://staging-media.toyota.com.br/de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "2",
                      "path": "f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "url": "https://staging-media.toyota.com.br/f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 151310,
              "offers": null,
              "color": {
                "name": "Cinza Granito (1G3)",
                "code": "1G3",
                "rgb": "#8E9DA7"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "2",
                      "path": "f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "url": "https://staging-media.toyota.com.br/f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "corolla-gr-sport",
                      "path": "de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "url": "https://staging-media.toyota.com.br/de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "2",
                      "path": "f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "url": "https://staging-media.toyota.com.br/f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 151620,
              "offers": null,
              "color": {
                "name": "Branco Lunar (089)",
                "code": "089",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "2",
                      "path": "f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "url": "https://staging-media.toyota.com.br/f0126271-b2de-40d6-9bdd-f4cc4cd36952.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "corolla-gr-sport",
                      "path": "de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "url": "https://staging-media.toyota.com.br/de7f5379-3efd-4916-8f17-59da857e42a6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "2",
                      "path": "f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "url": "https://staging-media.toyota.com.br/f68828c3-7b08-4b58-8c00-23a9706c5fee.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            }
          ]
        },
        {
          "vehicleColors": [
            {
              "price": 158490,
              "offers": null,
              "color": {
                "name": "Branco Polar (040)",
                "code": "040",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "1",
                      "path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "2",
                      "path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "2",
                      "path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1K3",
                    "media": {
                      "title": "2",
                      "path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1E7",
                    "media": {
                      "title": "2",
                      "path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:3R3",
                    "media": {
                      "title": "2",
                      "path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:209",
                    "media": {
                      "title": "2",
                      "path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1G3",
                    "media": {
                      "title": "2",
                      "path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:089",
                    "media": {
                      "title": "2",
                      "path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "1",
                      "path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "2",
                      "path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "3",
                      "path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "4",
                      "path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "5",
                      "path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "6",
                      "path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "8",
                      "path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "1",
                      "path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "2",
                      "path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "2",
                      "path": "c19925f9-c174-45b0-964e-70c372990b15.png",
                      "url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "1",
                      "path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "2",
                      "path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 160510,
              "offers": null,
              "color": {
                "name": "Cinza Celestial (1K3)",
                "code": "1K3",
                "rgb": "#8E9DA7"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "1",
                      "path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "2",
                      "path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "2",
                      "path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1K3",
                    "media": {
                      "title": "2",
                      "path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1E7",
                    "media": {
                      "title": "2",
                      "path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:3R3",
                    "media": {
                      "title": "2",
                      "path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:209",
                    "media": {
                      "title": "2",
                      "path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1G3",
                    "media": {
                      "title": "2",
                      "path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:089",
                    "media": {
                      "title": "2",
                      "path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "1",
                      "path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "2",
                      "path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "3",
                      "path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "4",
                      "path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "5",
                      "path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "6",
                      "path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "8",
                      "path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "1",
                      "path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "2",
                      "path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "2",
                      "path": "c19925f9-c174-45b0-964e-70c372990b15.png",
                      "url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "1",
                      "path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "2",
                      "path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 160510,
              "offers": null,
              "color": {
                "name": "Prata Supernova (1E7)",
                "code": "1E7",
                "rgb": "#D5D6D8"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "1",
                      "path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "2",
                      "path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "2",
                      "path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1K3",
                    "media": {
                      "title": "2",
                      "path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1E7",
                    "media": {
                      "title": "2",
                      "path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:3R3",
                    "media": {
                      "title": "2",
                      "path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:209",
                    "media": {
                      "title": "2",
                      "path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1G3",
                    "media": {
                      "title": "2",
                      "path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:089",
                    "media": {
                      "title": "2",
                      "path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "1",
                      "path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "2",
                      "path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "3",
                      "path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "4",
                      "path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "5",
                      "path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "6",
                      "path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "8",
                      "path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "1",
                      "path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "2",
                      "path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "2",
                      "path": "c19925f9-c174-45b0-964e-70c372990b15.png",
                      "url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "1",
                      "path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "2",
                      "path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 160510,
              "offers": null,
              "color": {
                "name": "Vermelho Granada (3R3)",
                "code": "3R3",
                "rgb": "#7E1F23"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "1",
                      "path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "2",
                      "path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "2",
                      "path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1K3",
                    "media": {
                      "title": "2",
                      "path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1E7",
                    "media": {
                      "title": "2",
                      "path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:3R3",
                    "media": {
                      "title": "2",
                      "path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:209",
                    "media": {
                      "title": "2",
                      "path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1G3",
                    "media": {
                      "title": "2",
                      "path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:089",
                    "media": {
                      "title": "2",
                      "path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "1",
                      "path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "2",
                      "path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "3",
                      "path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "4",
                      "path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "5",
                      "path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "6",
                      "path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "8",
                      "path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "1",
                      "path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "2",
                      "path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "2",
                      "path": "c19925f9-c174-45b0-964e-70c372990b15.png",
                      "url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "1",
                      "path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "2",
                      "path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 160510,
              "offers": null,
              "color": {
                "name": "Preto Eclipse (209) ",
                "code": "209",
                "rgb": "#202021"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "1",
                      "path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "2",
                      "path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "2",
                      "path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1K3",
                    "media": {
                      "title": "2",
                      "path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1E7",
                    "media": {
                      "title": "2",
                      "path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:3R3",
                    "media": {
                      "title": "2",
                      "path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:209",
                    "media": {
                      "title": "2",
                      "path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1G3",
                    "media": {
                      "title": "2",
                      "path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:089",
                    "media": {
                      "title": "2",
                      "path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "1",
                      "path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "2",
                      "path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "3",
                      "path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "4",
                      "path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "5",
                      "path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "6",
                      "path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "8",
                      "path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "1",
                      "path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "2",
                      "path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "2",
                      "path": "c19925f9-c174-45b0-964e-70c372990b15.png",
                      "url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "1",
                      "path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "2",
                      "path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 160510,
              "offers": null,
              "color": {
                "name": "Cinza Granito (1G3)",
                "code": "1G3",
                "rgb": "#8E9DA7"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "1",
                      "path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "2",
                      "path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "2",
                      "path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1K3",
                    "media": {
                      "title": "2",
                      "path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1E7",
                    "media": {
                      "title": "2",
                      "path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:3R3",
                    "media": {
                      "title": "2",
                      "path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:209",
                    "media": {
                      "title": "2",
                      "path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1G3",
                    "media": {
                      "title": "2",
                      "path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:089",
                    "media": {
                      "title": "2",
                      "path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "1",
                      "path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "2",
                      "path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "3",
                      "path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "4",
                      "path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "5",
                      "path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "6",
                      "path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "8",
                      "path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "1",
                      "path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "2",
                      "path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "2",
                      "path": "c19925f9-c174-45b0-964e-70c372990b15.png",
                      "url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "1",
                      "path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "2",
                      "path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 160820,
              "offers": null,
              "color": {
                "name": "Branco Lunar (089)",
                "code": "089",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "1",
                      "path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "2",
                      "path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:040",
                    "media": {
                      "title": "1",
                      "path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:040",
                    "media": {
                      "title": "2",
                      "path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1K3",
                    "media": {
                      "title": "1",
                      "path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1K3",
                    "media": {
                      "title": "2",
                      "path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1E7",
                    "media": {
                      "title": "1",
                      "path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1E7",
                    "media": {
                      "title": "2",
                      "path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:3R3",
                    "media": {
                      "title": "1",
                      "path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:3R3",
                    "media": {
                      "title": "2",
                      "path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:209",
                    "media": {
                      "title": "1",
                      "path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:209",
                    "media": {
                      "title": "2",
                      "path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:1G3",
                    "media": {
                      "title": "1",
                      "path": "185e20fa-273f-4055-be18-5570094987b0.png",
                      "url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:1G3",
                    "media": {
                      "title": "2",
                      "path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:desk:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:mob:089",
                    "media": {
                      "title": "1",
                      "path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "model:deskhover:089",
                    "media": {
                      "title": "2",
                      "path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "1",
                      "path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "2",
                      "path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "3",
                      "path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "4",
                      "path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "5",
                      "path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "6",
                      "path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "8",
                      "path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "1",
                      "path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "2",
                      "path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "1",
                      "path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "2",
                      "path": "c19925f9-c174-45b0-964e-70c372990b15.png",
                      "url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "1",
                      "path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "2",
                      "path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "1",
                      "path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "name": "XEi",
      "order": 2,
      "modelYears": [
        {
          "vehicleColors": [
            {
              "price": 155890,
              "offers": null,
              "color": {
                "name": "Branco Polar (040)",
                "code": "040",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "desk",
                      "path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "mobi",
                      "path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "desk",
                      "path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "mobi",
                      "path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "desk",
                      "path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "mobi",
                      "path": "6950db5f-921c-495b-a9df-605eff268778.png",
                      "url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "desk",
                      "path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "mobi",
                      "path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "desk",
                      "path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "mobi",
                      "path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "desk",
                      "path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "mobi",
                      "path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "desk",
                      "path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "mobi",
                      "path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "display1-Corolla-XEI-040",
                      "path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "display2-Corolla-XEI-040",
                      "path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "50808fec-cdbc-4382-9398-2576566b5052.png",
                      "url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "144f1113-cd82-4c9b-9271-439045606691.png",
                      "url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "desktop",
                      "path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "mobile",
                      "path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 157910,
              "offers": null,
              "color": {
                "name": "Cinza Celestial (1K3)",
                "code": "1K3",
                "rgb": "#8E9DA7"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "desk",
                      "path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "mobi",
                      "path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "desk",
                      "path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "mobi",
                      "path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "desk",
                      "path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "mobi",
                      "path": "6950db5f-921c-495b-a9df-605eff268778.png",
                      "url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "desk",
                      "path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "mobi",
                      "path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "desk",
                      "path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "mobi",
                      "path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "desk",
                      "path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "mobi",
                      "path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "desk",
                      "path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "mobi",
                      "path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "display1-Corolla-XEI-040",
                      "path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "display2-Corolla-XEI-040",
                      "path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "50808fec-cdbc-4382-9398-2576566b5052.png",
                      "url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "144f1113-cd82-4c9b-9271-439045606691.png",
                      "url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "desktop",
                      "path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "mobile",
                      "path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 157910,
              "offers": null,
              "color": {
                "name": "Prata Supernova (1E7)",
                "code": "1E7",
                "rgb": "#D5D6D8"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "desk",
                      "path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "mobi",
                      "path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "desk",
                      "path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "mobi",
                      "path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "desk",
                      "path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "mobi",
                      "path": "6950db5f-921c-495b-a9df-605eff268778.png",
                      "url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "desk",
                      "path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "mobi",
                      "path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "desk",
                      "path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "mobi",
                      "path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "desk",
                      "path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "mobi",
                      "path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "desk",
                      "path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "mobi",
                      "path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "display1-Corolla-XEI-040",
                      "path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "display2-Corolla-XEI-040",
                      "path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "50808fec-cdbc-4382-9398-2576566b5052.png",
                      "url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "144f1113-cd82-4c9b-9271-439045606691.png",
                      "url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "desktop",
                      "path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "mobile",
                      "path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 157910,
              "offers": null,
              "color": {
                "name": "Vermelho Granada (3R3)",
                "code": "3R3",
                "rgb": "#7E1F23"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "desk",
                      "path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "mobi",
                      "path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "desk",
                      "path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "mobi",
                      "path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "desk",
                      "path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "mobi",
                      "path": "6950db5f-921c-495b-a9df-605eff268778.png",
                      "url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "desk",
                      "path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "mobi",
                      "path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "desk",
                      "path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "mobi",
                      "path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "desk",
                      "path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "mobi",
                      "path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "desk",
                      "path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "mobi",
                      "path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "display1-Corolla-XEI-040",
                      "path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "display2-Corolla-XEI-040",
                      "path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "50808fec-cdbc-4382-9398-2576566b5052.png",
                      "url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "144f1113-cd82-4c9b-9271-439045606691.png",
                      "url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "desktop",
                      "path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "mobile",
                      "path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 157910,
              "offers": null,
              "color": {
                "name": "Preto Eclipse (209) ",
                "code": "209",
                "rgb": "#202021"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "desk",
                      "path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "mobi",
                      "path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "desk",
                      "path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "mobi",
                      "path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "desk",
                      "path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "mobi",
                      "path": "6950db5f-921c-495b-a9df-605eff268778.png",
                      "url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "desk",
                      "path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "mobi",
                      "path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "desk",
                      "path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "mobi",
                      "path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "desk",
                      "path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "mobi",
                      "path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "desk",
                      "path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "mobi",
                      "path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "display1-Corolla-XEI-040",
                      "path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "display2-Corolla-XEI-040",
                      "path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "50808fec-cdbc-4382-9398-2576566b5052.png",
                      "url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "144f1113-cd82-4c9b-9271-439045606691.png",
                      "url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "desktop",
                      "path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "mobile",
                      "path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 157910,
              "offers": null,
              "color": {
                "name": "Cinza Granito (1G3)",
                "code": "1G3",
                "rgb": "#8E9DA7"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "desk",
                      "path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "mobi",
                      "path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "desk",
                      "path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "mobi",
                      "path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "desk",
                      "path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "mobi",
                      "path": "6950db5f-921c-495b-a9df-605eff268778.png",
                      "url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "desk",
                      "path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "mobi",
                      "path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "desk",
                      "path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "mobi",
                      "path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "desk",
                      "path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "mobi",
                      "path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "desk",
                      "path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "mobi",
                      "path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "display1-Corolla-XEI-040",
                      "path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "display2-Corolla-XEI-040",
                      "path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "50808fec-cdbc-4382-9398-2576566b5052.png",
                      "url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "144f1113-cd82-4c9b-9271-439045606691.png",
                      "url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "desktop",
                      "path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "mobile",
                      "path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 158220,
              "offers": null,
              "color": {
                "name": "Branco Lunar (089)",
                "code": "089",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "desk",
                      "path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "mobi",
                      "path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "desk",
                      "path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "mobi",
                      "path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "desk",
                      "path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "mobi",
                      "path": "6950db5f-921c-495b-a9df-605eff268778.png",
                      "url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "desk",
                      "path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "mobi",
                      "path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "desk",
                      "path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "mobi",
                      "path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "desk",
                      "path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "mobi",
                      "path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "desk",
                      "path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "mobi",
                      "path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "display1-Corolla-XEI-040",
                      "path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "display2-Corolla-XEI-040",
                      "path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "50808fec-cdbc-4382-9398-2576566b5052.png",
                      "url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla GLi",
                      "path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "144f1113-cd82-4c9b-9271-439045606691.png",
                      "url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla XEi",
                      "path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "desktop",
                      "path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "mobile",
                      "path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Altis Premium",
      "order": 3,
      "modelYears": [
        {
          "vehicleColors": [
            {
              "price": 178990,
              "offers": null,
              "color": {
                "name": "Branco Polar (040)",
                "code": "040",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f9097437-754f-4c6c-887f-168568653cc5.png",
                      "url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 181010,
              "offers": null,
              "color": {
                "name": "Cinza Celestial (1K3)",
                "code": "1K3",
                "rgb": "#8E9DA7"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f9097437-754f-4c6c-887f-168568653cc5.png",
                      "url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 181010,
              "offers": null,
              "color": {
                "name": "Prata Supernova (1E7)",
                "code": "1E7",
                "rgb": "#D5D6D8"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f9097437-754f-4c6c-887f-168568653cc5.png",
                      "url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 181010,
              "offers": null,
              "color": {
                "name": "Vermelho Granada (3R3)",
                "code": "3R3",
                "rgb": "#7E1F23"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f9097437-754f-4c6c-887f-168568653cc5.png",
                      "url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 181010,
              "offers": null,
              "color": {
                "name": "Preto Eclipse (209) ",
                "code": "209",
                "rgb": "#202021"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f9097437-754f-4c6c-887f-168568653cc5.png",
                      "url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 181010,
              "offers": null,
              "color": {
                "name": "Cinza Granito (1G3)",
                "code": "1G3",
                "rgb": "#8E9DA7"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f9097437-754f-4c6c-887f-168568653cc5.png",
                      "url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 181320,
              "offers": null,
              "color": {
                "name": "Branco Lunar (089)",
                "code": "089",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "f9097437-754f-4c6c-887f-168568653cc5.png",
                      "url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Premium",
                      "path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "name": "GR-Sport",
      "order": 4,
      "modelYears": [
        {
          "vehicleColors": [
            {
              "price": 178390,
              "offers": null,
              "color": {
                "name": "Vermelho Granada (2NU)",
                "code": "2NU",
                "rgb": "#7E1F23"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 6",
                      "path": "db5adee8-f9e3-4a2b-a089-6b7302219e3b.jpeg",
                      "url": "https://staging-media.toyota.com.ar/db5adee8-f9e3-4a2b-a089-6b7302219e3b.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 7",
                      "path": "7cf34f85-8b60-4734-bfa0-607625bff47a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/7cf34f85-8b60-4734-bfa0-607625bff47a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 1",
                      "path": "96f8e9f9-cf87-41fd-9e6b-48abbd42a3fd.png",
                      "url": "https://staging-media.toyota.com.ar/96f8e9f9-cf87-41fd-9e6b-48abbd42a3fd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 2",
                      "path": "ebf78d82-ef44-4da1-8d25-0da64c8a7497.png",
                      "url": "https://staging-media.toyota.com.ar/ebf78d82-ef44-4da1-8d25-0da64c8a7497.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 3",
                      "path": "d674dbb1-365f-41a6-b484-0e414a1dda43.png",
                      "url": "https://staging-media.toyota.com.ar/d674dbb1-365f-41a6-b484-0e414a1dda43.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 4",
                      "path": "492fb261-b18a-4997-8538-f06b3202f490.jpeg",
                      "url": "https://staging-media.toyota.com.ar/492fb261-b18a-4997-8538-f06b3202f490.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 5",
                      "path": "f58b5011-6467-4d11-802f-d0f24db4c030.png",
                      "url": "https://staging-media.toyota.com.ar/f58b5011-6467-4d11-802f-d0f24db4c030.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 1",
                      "path": "d58a3471-e1d6-4622-ae76-b89d3fd6ffce.png",
                      "url": "https://staging-media.toyota.com.ar/d58a3471-e1d6-4622-ae76-b89d3fd6ffce.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 2",
                      "path": "710de1de-47da-42ea-a6b3-fb07c17c3b38.jpeg",
                      "url": "https://staging-media.toyota.com.ar/710de1de-47da-42ea-a6b3-fb07c17c3b38.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 3",
                      "path": "4ff9b71f-ea59-433e-a8ab-bdddb01b0452.png",
                      "url": "https://staging-media.toyota.com.ar/4ff9b71f-ea59-433e-a8ab-bdddb01b0452.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 4",
                      "path": "459d1f0e-6bd0-4ce1-846a-13a43f2db27c.png",
                      "url": "https://staging-media.toyota.com.ar/459d1f0e-6bd0-4ce1-846a-13a43f2db27c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 5",
                      "path": "9369cb06-ad3a-47a2-b2cd-7f9c1be212f6.jpeg",
                      "url": "https://staging-media.toyota.com.ar/9369cb06-ad3a-47a2-b2cd-7f9c1be212f6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 6",
                      "path": "169adfd9-ff02-46e7-a815-7059def37407.jpeg",
                      "url": "https://staging-media.toyota.com.ar/169adfd9-ff02-46e7-a815-7059def37407.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 7",
                      "path": "04883670-cefb-40c1-8b65-7bda06c61fb7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/04883670-cefb-40c1-8b65-7bda06c61fb7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "724a93c6-70d5-4318-9fb6-264d1219c652.png",
                      "url": "https://staging-media.toyota.com.ar/724a93c6-70d5-4318-9fb6-264d1219c652.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "normal",
                      "path": "220f14c2-1d50-4c51-92b8-90371e588867.png",
                      "url": "https://staging-media.toyota.com.ar/220f14c2-1d50-4c51-92b8-90371e588867.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "mouse hover",
                      "path": "cdcac2f1-bf67-4fac-ab5b-85667bd106bc.png",
                      "url": "https://staging-media.toyota.com.ar/cdcac2f1-bf67-4fac-ab5b-85667bd106bc.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "desk",
                      "path": "aff042c7-c9e9-4a60-bda1-d11c09ef43ad.jpeg",
                      "url": "https://staging-media.toyota.com.ar/aff042c7-c9e9-4a60-bda1-d11c09ef43ad.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "mobile",
                      "path": "781346d1-d627-447c-bf18-c23907f00e95.jpeg",
                      "url": "https://staging-media.toyota.com.ar/781346d1-d627-447c-bf18-c23907f00e95.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:2NU",
                    "media": {
                      "title": "desk",
                      "path": "59c3c1e8-ef31-4084-ae68-ee11c926d88d.png",
                      "url": "https://staging-media.toyota.com.ar/59c3c1e8-ef31-4084-ae68-ee11c926d88d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:2NU",
                    "media": {
                      "title": "mobile",
                      "path": "58fb3be8-272f-413a-a842-e26cfdafdbd0.png",
                      "url": "https://staging-media.toyota.com.ar/58fb3be8-272f-413a-a842-e26cfdafdbd0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "desk",
                      "path": "726921a0-c353-49e3-9f3d-477f31247a54.png",
                      "url": "https://staging-media.toyota.com.ar/726921a0-c353-49e3-9f3d-477f31247a54.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "mobile",
                      "path": "7c84c390-aa84-49a2-a7ee-f69da7bf3780.png",
                      "url": "https://staging-media.toyota.com.ar/7c84c390-aa84-49a2-a7ee-f69da7bf3780.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:2PU",
                    "media": {
                      "title": "desk",
                      "path": "1768fb0c-7a1a-4184-b514-fbdba9d1f779.png",
                      "url": "https://staging-media.toyota.com.ar/1768fb0c-7a1a-4184-b514-fbdba9d1f779.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:2PU",
                    "media": {
                      "title": "mobile",
                      "path": "e66c9015-f10b-44a4-8982-7d9cd53d18a1.png",
                      "url": "https://staging-media.toyota.com.ar/e66c9015-f10b-44a4-8982-7d9cd53d18a1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 178390,
              "offers": null,
              "color": {
                "name": "Preto Eclipse (209) ",
                "code": "209",
                "rgb": "#202021"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 6",
                      "path": "db5adee8-f9e3-4a2b-a089-6b7302219e3b.jpeg",
                      "url": "https://staging-media.toyota.com.ar/db5adee8-f9e3-4a2b-a089-6b7302219e3b.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 7",
                      "path": "7cf34f85-8b60-4734-bfa0-607625bff47a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/7cf34f85-8b60-4734-bfa0-607625bff47a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 1",
                      "path": "96f8e9f9-cf87-41fd-9e6b-48abbd42a3fd.png",
                      "url": "https://staging-media.toyota.com.ar/96f8e9f9-cf87-41fd-9e6b-48abbd42a3fd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 2",
                      "path": "ebf78d82-ef44-4da1-8d25-0da64c8a7497.png",
                      "url": "https://staging-media.toyota.com.ar/ebf78d82-ef44-4da1-8d25-0da64c8a7497.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 3",
                      "path": "d674dbb1-365f-41a6-b484-0e414a1dda43.png",
                      "url": "https://staging-media.toyota.com.ar/d674dbb1-365f-41a6-b484-0e414a1dda43.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 4",
                      "path": "492fb261-b18a-4997-8538-f06b3202f490.jpeg",
                      "url": "https://staging-media.toyota.com.ar/492fb261-b18a-4997-8538-f06b3202f490.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 5",
                      "path": "f58b5011-6467-4d11-802f-d0f24db4c030.png",
                      "url": "https://staging-media.toyota.com.ar/f58b5011-6467-4d11-802f-d0f24db4c030.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 1",
                      "path": "d58a3471-e1d6-4622-ae76-b89d3fd6ffce.png",
                      "url": "https://staging-media.toyota.com.ar/d58a3471-e1d6-4622-ae76-b89d3fd6ffce.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 2",
                      "path": "710de1de-47da-42ea-a6b3-fb07c17c3b38.jpeg",
                      "url": "https://staging-media.toyota.com.ar/710de1de-47da-42ea-a6b3-fb07c17c3b38.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 3",
                      "path": "4ff9b71f-ea59-433e-a8ab-bdddb01b0452.png",
                      "url": "https://staging-media.toyota.com.ar/4ff9b71f-ea59-433e-a8ab-bdddb01b0452.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 4",
                      "path": "459d1f0e-6bd0-4ce1-846a-13a43f2db27c.png",
                      "url": "https://staging-media.toyota.com.ar/459d1f0e-6bd0-4ce1-846a-13a43f2db27c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 5",
                      "path": "9369cb06-ad3a-47a2-b2cd-7f9c1be212f6.jpeg",
                      "url": "https://staging-media.toyota.com.ar/9369cb06-ad3a-47a2-b2cd-7f9c1be212f6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 6",
                      "path": "169adfd9-ff02-46e7-a815-7059def37407.jpeg",
                      "url": "https://staging-media.toyota.com.ar/169adfd9-ff02-46e7-a815-7059def37407.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 7",
                      "path": "04883670-cefb-40c1-8b65-7bda06c61fb7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/04883670-cefb-40c1-8b65-7bda06c61fb7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "724a93c6-70d5-4318-9fb6-264d1219c652.png",
                      "url": "https://staging-media.toyota.com.ar/724a93c6-70d5-4318-9fb6-264d1219c652.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "normal",
                      "path": "220f14c2-1d50-4c51-92b8-90371e588867.png",
                      "url": "https://staging-media.toyota.com.ar/220f14c2-1d50-4c51-92b8-90371e588867.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "mouse hover",
                      "path": "cdcac2f1-bf67-4fac-ab5b-85667bd106bc.png",
                      "url": "https://staging-media.toyota.com.ar/cdcac2f1-bf67-4fac-ab5b-85667bd106bc.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "desk",
                      "path": "aff042c7-c9e9-4a60-bda1-d11c09ef43ad.jpeg",
                      "url": "https://staging-media.toyota.com.ar/aff042c7-c9e9-4a60-bda1-d11c09ef43ad.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "mobile",
                      "path": "781346d1-d627-447c-bf18-c23907f00e95.jpeg",
                      "url": "https://staging-media.toyota.com.ar/781346d1-d627-447c-bf18-c23907f00e95.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:2NU",
                    "media": {
                      "title": "desk",
                      "path": "59c3c1e8-ef31-4084-ae68-ee11c926d88d.png",
                      "url": "https://staging-media.toyota.com.ar/59c3c1e8-ef31-4084-ae68-ee11c926d88d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:2NU",
                    "media": {
                      "title": "mobile",
                      "path": "58fb3be8-272f-413a-a842-e26cfdafdbd0.png",
                      "url": "https://staging-media.toyota.com.ar/58fb3be8-272f-413a-a842-e26cfdafdbd0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "desk",
                      "path": "726921a0-c353-49e3-9f3d-477f31247a54.png",
                      "url": "https://staging-media.toyota.com.ar/726921a0-c353-49e3-9f3d-477f31247a54.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "mobile",
                      "path": "7c84c390-aa84-49a2-a7ee-f69da7bf3780.png",
                      "url": "https://staging-media.toyota.com.ar/7c84c390-aa84-49a2-a7ee-f69da7bf3780.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:2PU",
                    "media": {
                      "title": "desk",
                      "path": "1768fb0c-7a1a-4184-b514-fbdba9d1f779.png",
                      "url": "https://staging-media.toyota.com.ar/1768fb0c-7a1a-4184-b514-fbdba9d1f779.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:2PU",
                    "media": {
                      "title": "mobile",
                      "path": "e66c9015-f10b-44a4-8982-7d9cd53d18a1.png",
                      "url": "https://staging-media.toyota.com.ar/e66c9015-f10b-44a4-8982-7d9cd53d18a1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 178710,
              "offers": null,
              "color": {
                "name": "Branco Lunar (2PU) ",
                "code": "2PU",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 6",
                      "path": "db5adee8-f9e3-4a2b-a089-6b7302219e3b.jpeg",
                      "url": "https://staging-media.toyota.com.ar/db5adee8-f9e3-4a2b-a089-6b7302219e3b.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 7",
                      "path": "7cf34f85-8b60-4734-bfa0-607625bff47a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/7cf34f85-8b60-4734-bfa0-607625bff47a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 1",
                      "path": "96f8e9f9-cf87-41fd-9e6b-48abbd42a3fd.png",
                      "url": "https://staging-media.toyota.com.ar/96f8e9f9-cf87-41fd-9e6b-48abbd42a3fd.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 2",
                      "path": "ebf78d82-ef44-4da1-8d25-0da64c8a7497.png",
                      "url": "https://staging-media.toyota.com.ar/ebf78d82-ef44-4da1-8d25-0da64c8a7497.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 3",
                      "path": "d674dbb1-365f-41a6-b484-0e414a1dda43.png",
                      "url": "https://staging-media.toyota.com.ar/d674dbb1-365f-41a6-b484-0e414a1dda43.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 4",
                      "path": "492fb261-b18a-4997-8538-f06b3202f490.jpeg",
                      "url": "https://staging-media.toyota.com.ar/492fb261-b18a-4997-8538-f06b3202f490.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "int 5",
                      "path": "f58b5011-6467-4d11-802f-d0f24db4c030.png",
                      "url": "https://staging-media.toyota.com.ar/f58b5011-6467-4d11-802f-d0f24db4c030.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 1",
                      "path": "d58a3471-e1d6-4622-ae76-b89d3fd6ffce.png",
                      "url": "https://staging-media.toyota.com.ar/d58a3471-e1d6-4622-ae76-b89d3fd6ffce.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 2",
                      "path": "710de1de-47da-42ea-a6b3-fb07c17c3b38.jpeg",
                      "url": "https://staging-media.toyota.com.ar/710de1de-47da-42ea-a6b3-fb07c17c3b38.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 3",
                      "path": "4ff9b71f-ea59-433e-a8ab-bdddb01b0452.png",
                      "url": "https://staging-media.toyota.com.ar/4ff9b71f-ea59-433e-a8ab-bdddb01b0452.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 4",
                      "path": "459d1f0e-6bd0-4ce1-846a-13a43f2db27c.png",
                      "url": "https://staging-media.toyota.com.ar/459d1f0e-6bd0-4ce1-846a-13a43f2db27c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 5",
                      "path": "9369cb06-ad3a-47a2-b2cd-7f9c1be212f6.jpeg",
                      "url": "https://staging-media.toyota.com.ar/9369cb06-ad3a-47a2-b2cd-7f9c1be212f6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 6",
                      "path": "169adfd9-ff02-46e7-a815-7059def37407.jpeg",
                      "url": "https://staging-media.toyota.com.ar/169adfd9-ff02-46e7-a815-7059def37407.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "ext 7",
                      "path": "04883670-cefb-40c1-8b65-7bda06c61fb7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/04883670-cefb-40c1-8b65-7bda06c61fb7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo",
                      "path": "724a93c6-70d5-4318-9fb6-264d1219c652.png",
                      "url": "https://staging-media.toyota.com.ar/724a93c6-70d5-4318-9fb6-264d1219c652.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "normal",
                      "path": "220f14c2-1d50-4c51-92b8-90371e588867.png",
                      "url": "https://staging-media.toyota.com.ar/220f14c2-1d50-4c51-92b8-90371e588867.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "mouse hover",
                      "path": "cdcac2f1-bf67-4fac-ab5b-85667bd106bc.png",
                      "url": "https://staging-media.toyota.com.ar/cdcac2f1-bf67-4fac-ab5b-85667bd106bc.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "desk",
                      "path": "aff042c7-c9e9-4a60-bda1-d11c09ef43ad.jpeg",
                      "url": "https://staging-media.toyota.com.ar/aff042c7-c9e9-4a60-bda1-d11c09ef43ad.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "mobile",
                      "path": "781346d1-d627-447c-bf18-c23907f00e95.jpeg",
                      "url": "https://staging-media.toyota.com.ar/781346d1-d627-447c-bf18-c23907f00e95.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:2NU",
                    "media": {
                      "title": "desk",
                      "path": "59c3c1e8-ef31-4084-ae68-ee11c926d88d.png",
                      "url": "https://staging-media.toyota.com.ar/59c3c1e8-ef31-4084-ae68-ee11c926d88d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:2NU",
                    "media": {
                      "title": "mobile",
                      "path": "58fb3be8-272f-413a-a842-e26cfdafdbd0.png",
                      "url": "https://staging-media.toyota.com.ar/58fb3be8-272f-413a-a842-e26cfdafdbd0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "desk",
                      "path": "726921a0-c353-49e3-9f3d-477f31247a54.png",
                      "url": "https://staging-media.toyota.com.ar/726921a0-c353-49e3-9f3d-477f31247a54.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "mobile",
                      "path": "7c84c390-aa84-49a2-a7ee-f69da7bf3780.png",
                      "url": "https://staging-media.toyota.com.ar/7c84c390-aa84-49a2-a7ee-f69da7bf3780.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:2PU",
                    "media": {
                      "title": "desk",
                      "path": "1768fb0c-7a1a-4184-b514-fbdba9d1f779.png",
                      "url": "https://staging-media.toyota.com.ar/1768fb0c-7a1a-4184-b514-fbdba9d1f779.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:2PU",
                    "media": {
                      "title": "mobile",
                      "path": "e66c9015-f10b-44a4-8982-7d9cd53d18a1.png",
                      "url": "https://staging-media.toyota.com.ar/e66c9015-f10b-44a4-8982-7d9cd53d18a1.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Altis Hybrid",
      "order": 5,
      "modelYears": [
        {
          "vehicleColors": [
            {
              "price": 183890,
              "offers": null,
              "color": {
                "name": "Branco Polar (040)",
                "code": "040",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "572dd007-b107-4468-871a-21f2eb583f09.png",
                      "url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 185910,
              "offers": null,
              "color": {
                "name": "Cinza Celestial (1K3)",
                "code": "1K3",
                "rgb": "#8E9DA7"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "572dd007-b107-4468-871a-21f2eb583f09.png",
                      "url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 185910,
              "offers": null,
              "color": {
                "name": "Prata Supernova (1E7)",
                "code": "1E7",
                "rgb": "#D5D6D8"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "572dd007-b107-4468-871a-21f2eb583f09.png",
                      "url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 185910,
              "offers": null,
              "color": {
                "name": "Vermelho Granada (3R3)",
                "code": "3R3",
                "rgb": "#7E1F23"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "572dd007-b107-4468-871a-21f2eb583f09.png",
                      "url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 185910,
              "offers": null,
              "color": {
                "name": "Preto Eclipse (209) ",
                "code": "209",
                "rgb": "#202021"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "572dd007-b107-4468-871a-21f2eb583f09.png",
                      "url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 185910,
              "offers": null,
              "color": {
                "name": "Cinza Granito (1G3)",
                "code": "1G3",
                "rgb": "#8E9DA7"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "572dd007-b107-4468-871a-21f2eb583f09.png",
                      "url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 186220,
              "offers": null,
              "color": {
                "name": "Branco Lunar (089)",
                "code": "089",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Corolla Altis Hybrid - 1E7",
                      "path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "572dd007-b107-4468-871a-21f2eb583f09.png",
                      "url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Corolla Altis Hybrid",
                      "path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Altis Hybrid Premium",
      "order": 6,
      "modelYears": [
        {
          "vehicleColors": [
            {
              "price": 193690,
              "offers": null,
              "color": {
                "name": "Branco Polar (040)",
                "code": "040",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3",
                      "path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3 mobile",
                      "path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7",
                      "path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7 mobile",
                      "path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3",
                      "path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3 mobile",
                      "path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209",
                      "path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209 mobile",
                      "path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3",
                      "path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3 mobile",
                      "path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089",
                      "path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089 mobile",
                      "path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo corolla altis hybrid premiun",
                      "path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "01",
                      "path": "6ea24391-2037-4051-a133-54214f4712da.png",
                      "url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "02",
                      "path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "03",
                      "path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "04",
                      "path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "05",
                      "path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "06",
                      "path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "07",
                      "path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "08",
                      "path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "01",
                      "path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "02",
                      "path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "04",
                      "path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "05",
                      "path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "06",
                      "path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "07",
                      "path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "08",
                      "path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040",
                      "path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040 mobile",
                      "path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "01",
                      "path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "02",
                      "path": "03651604-1121-4025-a5cf-575e510385f6.png",
                      "url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Banner_Corolla_TDB_Desktop",
                      "path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Banner_Corolla_TDB_Mobile",
                      "path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 195710,
              "offers": null,
              "color": {
                "name": "Cinza Celestial (1K3)",
                "code": "1K3",
                "rgb": "#8E9DA7"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3",
                      "path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3 mobile",
                      "path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7",
                      "path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7 mobile",
                      "path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3",
                      "path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3 mobile",
                      "path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209",
                      "path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209 mobile",
                      "path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3",
                      "path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3 mobile",
                      "path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089",
                      "path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089 mobile",
                      "path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo corolla altis hybrid premiun",
                      "path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "01",
                      "path": "6ea24391-2037-4051-a133-54214f4712da.png",
                      "url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "02",
                      "path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "03",
                      "path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "04",
                      "path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "05",
                      "path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "06",
                      "path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "07",
                      "path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "08",
                      "path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "01",
                      "path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "02",
                      "path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "04",
                      "path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "05",
                      "path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "06",
                      "path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "07",
                      "path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "08",
                      "path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040",
                      "path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040 mobile",
                      "path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "01",
                      "path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "02",
                      "path": "03651604-1121-4025-a5cf-575e510385f6.png",
                      "url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Banner_Corolla_TDB_Desktop",
                      "path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Banner_Corolla_TDB_Mobile",
                      "path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 195710,
              "offers": null,
              "color": {
                "name": "Prata Supernova (1E7)",
                "code": "1E7",
                "rgb": "#D5D6D8"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3",
                      "path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3 mobile",
                      "path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7",
                      "path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7 mobile",
                      "path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3",
                      "path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3 mobile",
                      "path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209",
                      "path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209 mobile",
                      "path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3",
                      "path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3 mobile",
                      "path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089",
                      "path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089 mobile",
                      "path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo corolla altis hybrid premiun",
                      "path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "01",
                      "path": "6ea24391-2037-4051-a133-54214f4712da.png",
                      "url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "02",
                      "path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "03",
                      "path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "04",
                      "path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "05",
                      "path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "06",
                      "path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "07",
                      "path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "08",
                      "path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "01",
                      "path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "02",
                      "path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "04",
                      "path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "05",
                      "path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "06",
                      "path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "07",
                      "path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "08",
                      "path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040",
                      "path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040 mobile",
                      "path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "01",
                      "path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "02",
                      "path": "03651604-1121-4025-a5cf-575e510385f6.png",
                      "url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Banner_Corolla_TDB_Desktop",
                      "path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Banner_Corolla_TDB_Mobile",
                      "path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 195710,
              "offers": null,
              "color": {
                "name": "Vermelho Granada (3R3)",
                "code": "3R3",
                "rgb": "#7E1F23"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3",
                      "path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3 mobile",
                      "path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7",
                      "path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7 mobile",
                      "path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3",
                      "path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3 mobile",
                      "path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209",
                      "path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209 mobile",
                      "path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3",
                      "path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3 mobile",
                      "path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089",
                      "path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089 mobile",
                      "path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo corolla altis hybrid premiun",
                      "path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "01",
                      "path": "6ea24391-2037-4051-a133-54214f4712da.png",
                      "url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "02",
                      "path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "03",
                      "path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "04",
                      "path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "05",
                      "path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "06",
                      "path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "07",
                      "path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "08",
                      "path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "01",
                      "path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "02",
                      "path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "04",
                      "path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "05",
                      "path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "06",
                      "path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "07",
                      "path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "08",
                      "path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040",
                      "path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040 mobile",
                      "path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "01",
                      "path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "02",
                      "path": "03651604-1121-4025-a5cf-575e510385f6.png",
                      "url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Banner_Corolla_TDB_Desktop",
                      "path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Banner_Corolla_TDB_Mobile",
                      "path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 195710,
              "offers": null,
              "color": {
                "name": "Preto Eclipse (209) ",
                "code": "209",
                "rgb": "#202021"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3",
                      "path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3 mobile",
                      "path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7",
                      "path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7 mobile",
                      "path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3",
                      "path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3 mobile",
                      "path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209",
                      "path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209 mobile",
                      "path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3",
                      "path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3 mobile",
                      "path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089",
                      "path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089 mobile",
                      "path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo corolla altis hybrid premiun",
                      "path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "01",
                      "path": "6ea24391-2037-4051-a133-54214f4712da.png",
                      "url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "02",
                      "path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "03",
                      "path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "04",
                      "path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "05",
                      "path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "06",
                      "path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "07",
                      "path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "08",
                      "path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "01",
                      "path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "02",
                      "path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "04",
                      "path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "05",
                      "path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "06",
                      "path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "07",
                      "path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "08",
                      "path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040",
                      "path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040 mobile",
                      "path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "01",
                      "path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "02",
                      "path": "03651604-1121-4025-a5cf-575e510385f6.png",
                      "url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Banner_Corolla_TDB_Desktop",
                      "path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Banner_Corolla_TDB_Mobile",
                      "path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 195710,
              "offers": null,
              "color": {
                "name": "Cinza Granito (1G3)",
                "code": "1G3",
                "rgb": "#8E9DA7"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3",
                      "path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3 mobile",
                      "path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7",
                      "path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7 mobile",
                      "path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3",
                      "path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3 mobile",
                      "path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209",
                      "path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209 mobile",
                      "path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3",
                      "path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3 mobile",
                      "path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089",
                      "path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089 mobile",
                      "path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo corolla altis hybrid premiun",
                      "path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "01",
                      "path": "6ea24391-2037-4051-a133-54214f4712da.png",
                      "url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "02",
                      "path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "03",
                      "path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "04",
                      "path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "05",
                      "path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "06",
                      "path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "07",
                      "path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "08",
                      "path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "01",
                      "path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "02",
                      "path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "04",
                      "path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "05",
                      "path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "06",
                      "path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "07",
                      "path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "08",
                      "path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040",
                      "path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040 mobile",
                      "path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "01",
                      "path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "02",
                      "path": "03651604-1121-4025-a5cf-575e510385f6.png",
                      "url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Banner_Corolla_TDB_Desktop",
                      "path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Banner_Corolla_TDB_Mobile",
                      "path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            },
            {
              "price": 196020,
              "offers": null,
              "color": {
                "name": "Branco Lunar (089)",
                "code": "089",
                "rgb": "#F4F4F2"
              },
              "modelYear": {
                "medias": [
                  {
                    "tag": "360:desk:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3",
                      "path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1K3",
                    "media": {
                      "title": "Altis Hybrid Premium 1k3 mobile",
                      "path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7",
                      "path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1E7",
                    "media": {
                      "title": "Altis Hybrid Premium 1e7 mobile",
                      "path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3",
                      "path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:3R3",
                    "media": {
                      "title": "Altis Hybrid Premium 3r3 mobile",
                      "path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209",
                      "path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:209",
                    "media": {
                      "title": "Altis Hybrid Premium 209 mobile",
                      "path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3",
                      "path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:1G3",
                    "media": {
                      "title": "Altis Hybrid Premium 1g3 mobile",
                      "path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089",
                      "path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:089",
                    "media": {
                      "title": "Altis Hybrid Premium 089 mobile",
                      "path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "logo",
                    "media": {
                      "title": "logo corolla altis hybrid premiun",
                      "path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "01",
                      "path": "6ea24391-2037-4051-a133-54214f4712da.png",
                      "url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "02",
                      "path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "03",
                      "path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "04",
                      "path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "05",
                      "path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "06",
                      "path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "07",
                      "path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:external",
                    "media": {
                      "title": "08",
                      "path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "01",
                      "path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "02",
                      "path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "04",
                      "path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "05",
                      "path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "06",
                      "path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "07",
                      "path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "gallery:internal",
                    "media": {
                      "title": "08",
                      "path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:desk:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040",
                      "path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "360:mob:040",
                    "media": {
                      "title": "Altis Hybrid Premium 040 mobile",
                      "path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:regular",
                    "media": {
                      "title": "01",
                      "path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "display:hover",
                    "media": {
                      "title": "02",
                      "path": "03651604-1121-4025-a5cf-575e510385f6.png",
                      "url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:desk",
                    "media": {
                      "title": "Banner_Corolla_TDB_Desktop",
                      "path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "banner-image:mob",
                    "media": {
                      "title": "Banner_Corolla_TDB_Mobile",
                      "path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  },
                  {
                    "tag": "internal",
                    "media": {
                      "title": "03",
                      "path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
                      "mediaType": {
                        "title": "image"
                      }
                    }
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "name": "SRX TESTE",
      "order": 7,
      "modelYears": []
    },
    {
      "name": "SRX TESTE",
      "order": null,
      "modelYears": []
    },
    {
      "name": "SRX TESTE 3",
      "order": null,
      "modelYears": [
        {
          "vehicleColors": []
        }
      ]
    }
  ]
};

export const vehiclesCategoriesDataMock = [
  {
    "id": "bf8c0d92-4175-4fb2-8ff1-d6cd46ff881b",
    "name": "Carros de passeio",
    "models": [
      {
        "id": "0c032e9b-fc4d-425c-8fa4-db0e1566e374",
        "name": "Corolla"
      },
      {
        "id": "7c830d7d-94d8-43bc-9e13-1fec4205524d",
        "name": "Yaris Hatch"
      },
      {
        "id": "29a7181a-6015-4fda-b0e2-172212318e14",
        "name": "Yaris Sedã"
      }
    ]
  },
  {
    "id": "39e016f8-3719-439b-91f6-41a1d0d151b6",
    "name": "SUVs",
    "models": [
      {
        "id": "b6397a1e-4f26-45b1-9ab4-f8d7fca948f2",
        "name": "Corolla Cross"
      },
      {
        "id": "43c76ee4-b45b-4a22-9ea4-3c32c8618fbb",
        "name": "SW4"
      },
      {
        "id": "f9d2155e-e291-4ef2-9ce2-1b7d7e88b2d5",
        "name": "RAV4"
      }
    ]
  },
  {
    "id": "269f1279-5f96-4d2f-88e7-82e26ce70aea",
    "name": "Pick-ups",
    "models": [
      {
        "id": "e8aa8a7f-ed4c-4dcc-9b5c-700d9e7bb7b5",
        "name": "Hilux Cabine Dupla"
      },
      {
        "id": "9cb99162-b474-47e7-b548-b221e17d12b3",
        "name": "Hilux Cabine Simples"
      }
    ]
  },
  {
    "id": "a7f40ef5-2c0a-4e3a-afca-0b5ce8e9700f",
    "name": "Esportivos",
    "models": [
      {
        "id": "87cbb53a-8c81-43f8-a00d-2b44b8ac316a",
        "name": "GR Corolla"
      },
      {
        "id": "3c48ac0a-e267-413d-8112-770f8145f61e",
        "name": "Corolla GR-Sport"
      }
    ]
  },
  {
    "id": "e119f9a3-5c11-423a-8a7a-45665e57ce47",
    "name": "Hibridos",
    "models": []
  },
  {
    "id": "f5ea7b7f-48f8-489b-8df0-c6f3f796c24b",
    "name": "Híbridos e Híbridos Plug-in",
    "models": [
      {
        "id": "77a2db46-798a-4399-8a8c-2060fa565a50",
        "name": "Corolla Hybrid"
      }
    ]
  },
  {
    "id": "015a9db9-6b5f-49c1-b543-d9eb40c6514e",
    "name": "Comercial",
    "models": []
  }
];

export const vehiclesDataMock =  [
			{
				"id": "0c032e9b-fc4d-425c-8fa4-db0e1566e374",
				"name": "Corolla",
				"vehicleExternalId": null,
				"status": "ENABLED",
				"category": {
					"name": "Carros de passeio",
					"id": "bf8c0d92-4175-4fb2-8ff1-d6cd46ff881b"
				},
				"versions": [
					{
						"name": "GLi",
						"id": "eacb8309-0d5e-4ccc-aa20-a2cd31acf5d0",
						"order": 1,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 149290,
										"offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 151310,
                    "offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Cinza Celestial (1K3)",
											"code": "1K3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 151310,
                    "offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Prata Supernova (1E7)",
											"code": "1E7",
											"rgb": "#D5D6D8"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 151310,
                    "offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 151310,
										"offers": null,
										"color": {
											"name": "Preto Eclipse (209) ",
											"code": "209",
											"rgb": "#202021"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 151310,
                    "offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 151620,
                    "offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1E7",
														"path": "a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"url": "https://staging-media.toyota.com.br/a9a198e4-be88-47e7-af7a-678b7c6e084d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"url": "https://staging-media.toyota.com.br/ec13a2e2-7c89-47ec-81e0-21bdf06d957d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "209",
														"path": "94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"url": "https://staging-media.toyota.com.br/94fda71d-9a9a-4ecd-a4b7-ad2f96a49f0c.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 158490,
										"offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "2",
														"path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "2",
														"path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
														"url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "2",
														"path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "2",
														"path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "2",
														"path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "2",
														"path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "2",
														"path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "2",
														"path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "3",
														"path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
														"url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "4",
														"path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "5",
														"path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "6",
														"path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
														"url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "8",
														"path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "2",
														"path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "2",
														"path": "c19925f9-c174-45b0-964e-70c372990b15.png",
														"url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "2",
														"path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 160510,
                    "offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Cinza Celestial (1K3)",
											"code": "1K3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "2",
														"path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "2",
														"path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
														"url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "2",
														"path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "2",
														"path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "2",
														"path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "2",
														"path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "2",
														"path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "2",
														"path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "3",
														"path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
														"url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "4",
														"path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "5",
														"path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "6",
														"path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
														"url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "8",
														"path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "2",
														"path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "2",
														"path": "c19925f9-c174-45b0-964e-70c372990b15.png",
														"url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "2",
														"path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 160510,
                    "offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Prata Supernova (1E7)",
											"code": "1E7",
											"rgb": "#D5D6D8"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "2",
														"path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "2",
														"path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
														"url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "2",
														"path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "2",
														"path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "2",
														"path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "2",
														"path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "2",
														"path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "2",
														"path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "3",
														"path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
														"url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "4",
														"path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "5",
														"path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "6",
														"path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
														"url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "8",
														"path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "2",
														"path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "2",
														"path": "c19925f9-c174-45b0-964e-70c372990b15.png",
														"url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "2",
														"path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 160510,
                    "offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "2",
														"path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "2",
														"path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
														"url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "2",
														"path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "2",
														"path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "2",
														"path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "2",
														"path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "2",
														"path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "2",
														"path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "3",
														"path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
														"url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "4",
														"path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "5",
														"path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "6",
														"path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
														"url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "8",
														"path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "2",
														"path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "2",
														"path": "c19925f9-c174-45b0-964e-70c372990b15.png",
														"url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "2",
														"path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 160510,
                    "offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Preto Eclipse (209) ",
											"code": "209",
											"rgb": "#202021"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "2",
														"path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "2",
														"path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
														"url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "2",
														"path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "2",
														"path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "2",
														"path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "2",
														"path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "2",
														"path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "2",
														"path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "3",
														"path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
														"url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "4",
														"path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "5",
														"path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "6",
														"path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
														"url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "8",
														"path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "2",
														"path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "2",
														"path": "c19925f9-c174-45b0-964e-70c372990b15.png",
														"url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "2",
														"path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 160510,
                    "offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "2",
														"path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "2",
														"path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
														"url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "2",
														"path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "2",
														"path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "2",
														"path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "2",
														"path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "2",
														"path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "2",
														"path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "3",
														"path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
														"url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "4",
														"path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "5",
														"path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "6",
														"path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
														"url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "8",
														"path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "2",
														"path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "2",
														"path": "c19925f9-c174-45b0-964e-70c372990b15.png",
														"url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "2",
														"path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 160820,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"url": "https://staging-media.toyota.com.br/6d372500-58af-47c3-9f99-a39c6f94ed7b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"url": "https://staging-media.toyota.com.br/0c14de94-4449-4eb7-ad60-4b188b05a519.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"url": "https://staging-media.toyota.com.br/e402aaef-1155-4c9f-be2f-a7da5a72caea.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"url": "https://staging-media.toyota.com.br/81e028c4-d7cf-48c8-8df2-36bb35f856de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "2",
														"path": "2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"url": "https://staging-media.toyota.com.br/2f48af97-75d1-40c1-bdfd-2bfd8449fe3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1",
														"path": "d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"url": "https://staging-media.toyota.com.br/d8d96584-695f-40d6-ab13-aff6cbd6f15e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "2",
														"path": "a272f3dc-1532-49b6-8301-981c7133da86.png",
														"url": "https://staging-media.toyota.com.br/a272f3dc-1532-49b6-8301-981c7133da86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1E7",
													"media": {
														"title": "1",
														"path": "c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"url": "https://staging-media.toyota.com.br/c2785bf6-c5da-4a59-b5b8-47eee4312923.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1E7",
													"media": {
														"title": "2",
														"path": "714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"url": "https://staging-media.toyota.com.br/714fa3db-af4a-4f7d-8366-65a8dc4981d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"url": "https://staging-media.toyota.com.br/22b7781f-d31a-46a6-bfe6-c79f2df42fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "2",
														"path": "2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"url": "https://staging-media.toyota.com.br/2c4f1fa2-348e-4f72-aa0d-6205f50836a5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:209",
													"media": {
														"title": "1",
														"path": "f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"url": "https://staging-media.toyota.com.br/f4a3e6c3-2888-4775-a7df-5c09e3fea36a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:209",
													"media": {
														"title": "2",
														"path": "7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"url": "https://staging-media.toyota.com.br/7eb19657-4a95-4bfd-b9e6-460777c212de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1",
														"path": "185e20fa-273f-4055-be18-5570094987b0.png",
														"url": "https://staging-media.toyota.com.br/185e20fa-273f-4055-be18-5570094987b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "2",
														"path": "4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"url": "https://staging-media.toyota.com.br/4c562881-964c-4c3e-afcb-7b104ba0a142.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "1",
														"path": "4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"url": "https://staging-media.toyota.com.br/4c84b596-0c86-4fe7-932b-f7e0c0b6e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "2",
														"path": "31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"url": "https://staging-media.toyota.com.br/31a8e293-eeac-43d4-af98-ff41ce1dfc3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"url": "https://staging-media.toyota.com.br/8b05089c-cc1e-458e-8db8-4262cc3bb031.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "2",
														"path": "40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"url": "https://staging-media.toyota.com.br/40769f66-a621-43cc-be37-6dfd3e12bbc1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "3",
														"path": "09660131-68df-4c9e-9fd0-85072752d03e.png",
														"url": "https://staging-media.toyota.com.br/09660131-68df-4c9e-9fd0-85072752d03e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "4",
														"path": "0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"url": "https://staging-media.toyota.com.br/0df884d2-48e1-4f88-ae60-67b85206a56a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "5",
														"path": "cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"url": "https://staging-media.toyota.com.br/cc1ad18e-ec6e-4378-ac11-5b359793a236.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "6",
														"path": "d075c419-42c4-4640-b858-afc7e80683ec.png",
														"url": "https://staging-media.toyota.com.br/d075c419-42c4-4640-b858-afc7e80683ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "8",
														"path": "3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"url": "https://staging-media.toyota.com.br/3e051567-460f-4c91-91b2-b24ba3a50770.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"url": "https://staging-media.toyota.com.br/88fb8d6e-cc2a-4315-8010-4488387b475d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "2",
														"path": "78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"url": "https://staging-media.toyota.com.br/78c70813-4db2-4a17-9ad0-d3b618fe894d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"url": "https://staging-media.toyota.com.br/9a33090f-3738-4abd-9b13-9ea1fb3a2a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "2",
														"path": "c19925f9-c174-45b0-964e-70c372990b15.png",
														"url": "https://staging-media.toyota.com.br/c19925f9-c174-45b0-964e-70c372990b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"url": "https://staging-media.toyota.com.br/247641a8-c8a0-4cdb-b7cd-a2c01417ad63.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "2",
														"path": "0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"url": "https://staging-media.toyota.com.br/0a4f4f70-4ce5-4e8c-a069-c55a5ed6a2c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"url": "https://staging-media.toyota.com.br/f6f37576-4a0a-4fcd-b8af-fd4983b20368.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "XEi",
						"id": "e99445b6-0aab-40ff-a612-c02d424382bc",
						"order": 2,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 155890,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "desk",
														"path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "mobi",
														"path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "desk",
														"path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "mobi",
														"path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "desk",
														"path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "mobi",
														"path": "6950db5f-921c-495b-a9df-605eff268778.png",
														"url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "desk",
														"path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "mobi",
														"path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "desk",
														"path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "mobi",
														"path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobi",
														"path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "desk",
														"path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "mobi",
														"path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-XEI-040",
														"path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-XEI-040",
														"path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "50808fec-cdbc-4382-9398-2576566b5052.png",
														"url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla XEi",
														"path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "144f1113-cd82-4c9b-9271-439045606691.png",
														"url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
														"url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 157910,
										"offers": null,
										"color": {
											"name": "Cinza Celestial (1K3)",
											"code": "1K3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "desk",
														"path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "mobi",
														"path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "desk",
														"path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "mobi",
														"path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "desk",
														"path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "mobi",
														"path": "6950db5f-921c-495b-a9df-605eff268778.png",
														"url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "desk",
														"path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "mobi",
														"path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "desk",
														"path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "mobi",
														"path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobi",
														"path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "desk",
														"path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "mobi",
														"path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-XEI-040",
														"path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-XEI-040",
														"path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "50808fec-cdbc-4382-9398-2576566b5052.png",
														"url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla XEi",
														"path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "144f1113-cd82-4c9b-9271-439045606691.png",
														"url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
														"url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 157910,
										"offers": null,
										"color": {
											"name": "Prata Supernova (1E7)",
											"code": "1E7",
											"rgb": "#D5D6D8"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "desk",
														"path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "mobi",
														"path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "desk",
														"path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "mobi",
														"path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "desk",
														"path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "mobi",
														"path": "6950db5f-921c-495b-a9df-605eff268778.png",
														"url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "desk",
														"path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "mobi",
														"path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "desk",
														"path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "mobi",
														"path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobi",
														"path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "desk",
														"path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "mobi",
														"path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-XEI-040",
														"path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-XEI-040",
														"path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "50808fec-cdbc-4382-9398-2576566b5052.png",
														"url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla XEi",
														"path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "144f1113-cd82-4c9b-9271-439045606691.png",
														"url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
														"url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 157910,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "desk",
														"path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "mobi",
														"path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "desk",
														"path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "mobi",
														"path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "desk",
														"path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "mobi",
														"path": "6950db5f-921c-495b-a9df-605eff268778.png",
														"url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "desk",
														"path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "mobi",
														"path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "desk",
														"path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "mobi",
														"path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobi",
														"path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "desk",
														"path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "mobi",
														"path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-XEI-040",
														"path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-XEI-040",
														"path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "50808fec-cdbc-4382-9398-2576566b5052.png",
														"url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla XEi",
														"path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "144f1113-cd82-4c9b-9271-439045606691.png",
														"url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
														"url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 157910,
                    "offers": {
                      "afterPrice": 149290,
                      "beforePrice": 149210
                    },
										"color": {
											"name": "Preto Eclipse (209) ",
											"code": "209",
											"rgb": "#202021"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "desk",
														"path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "mobi",
														"path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "desk",
														"path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "mobi",
														"path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "desk",
														"path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "mobi",
														"path": "6950db5f-921c-495b-a9df-605eff268778.png",
														"url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "desk",
														"path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "mobi",
														"path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "desk",
														"path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "mobi",
														"path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobi",
														"path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "desk",
														"path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "mobi",
														"path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-XEI-040",
														"path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-XEI-040",
														"path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "50808fec-cdbc-4382-9398-2576566b5052.png",
														"url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla XEi",
														"path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "144f1113-cd82-4c9b-9271-439045606691.png",
														"url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
														"url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 157910,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "desk",
														"path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "mobi",
														"path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "desk",
														"path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "mobi",
														"path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "desk",
														"path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "mobi",
														"path": "6950db5f-921c-495b-a9df-605eff268778.png",
														"url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "desk",
														"path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "mobi",
														"path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "desk",
														"path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "mobi",
														"path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobi",
														"path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "desk",
														"path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "mobi",
														"path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-XEI-040",
														"path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-XEI-040",
														"path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "50808fec-cdbc-4382-9398-2576566b5052.png",
														"url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla XEi",
														"path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "144f1113-cd82-4c9b-9271-439045606691.png",
														"url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
														"url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 158220,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "desk",
														"path": "50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"url": "https://staging-media.toyota.com.ar/50657be9-ecf3-42bb-b81c-bd54a78d8fd6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "mobi",
														"path": "2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"url": "https://staging-media.toyota.com.ar/2ed6744f-ec5e-443a-b903-f4a76e2f066b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "desk",
														"path": "8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"url": "https://staging-media.toyota.com.ar/8cdd46a4-3033-471c-bbe2-dddde7ff33f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "mobi",
														"path": "497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"url": "https://staging-media.toyota.com.ar/497e2dc3-d71e-47d0-9238-349aac4e9ed8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "desk",
														"path": "de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"url": "https://staging-media.toyota.com.ar/de24c935-ce36-43c6-9b6b-7ff8c387c392.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "mobi",
														"path": "6950db5f-921c-495b-a9df-605eff268778.png",
														"url": "https://staging-media.toyota.com.ar/6950db5f-921c-495b-a9df-605eff268778.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "desk",
														"path": "a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"url": "https://staging-media.toyota.com.ar/a76f1ec9-ed7f-4b1e-9544-b459f011ef09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "mobi",
														"path": "07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"url": "https://staging-media.toyota.com.ar/07c96f2c-8d1e-41a5-99e4-7ad6dac53752.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "desk",
														"path": "857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"url": "https://staging-media.toyota.com.ar/857b39b4-a430-4951-aedf-4ac655bee0a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "mobi",
														"path": "bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"url": "https://staging-media.toyota.com.ar/bb376843-11b3-4d0f-b45c-3d8326e6fdf5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"url": "https://staging-media.toyota.com.ar/fe81cbeb-675e-4bcf-aea0-b90c37d6f252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobi",
														"path": "adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"url": "https://staging-media.toyota.com.ar/adb7d837-0778-4bc6-aed1-57e91e3e1c8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "desk",
														"path": "7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"url": "https://staging-media.toyota.com.ar/7e6b651f-7d6e-44c5-8ade-7ab43e13fe03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "mobi",
														"path": "cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"url": "https://staging-media.toyota.com.ar/cd5b7231-6c1b-4c86-8e4c-166f20a133ee.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-XEI-040",
														"path": "9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"url": "https://staging-media.toyota.com.ar/9ae72cf5-150a-41ab-ad79-97ad0f51adae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-XEI-040",
														"path": "8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"url": "https://staging-media.toyota.com.ar/8daf9520-f9fd-4eca-8d54-11bd51d93c8e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"url": "https://staging-media.toyota.com.ar/2c14e6d3-e268-45f0-a6f9-0ede993ae5df.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"url": "https://staging-media.toyota.com.ar/e5b6854a-cdba-452a-853d-1560c52ac03c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"url": "https://staging-media.toyota.com.ar/9d9588f0-ebdb-4ada-a9cc-8ca4def4dacd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"url": "https://staging-media.toyota.com.ar/baac6483-ad8c-4875-8191-26f2d474ca90.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "50808fec-cdbc-4382-9398-2576566b5052.png",
														"url": "https://staging-media.toyota.com.ar/50808fec-cdbc-4382-9398-2576566b5052.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla GLi",
														"path": "281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/281f841e-10cb-4c72-8c8d-0da529c1c4a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla XEi",
														"path": "22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"url": "https://staging-media.toyota.com.ar/22b829fb-2c73-4456-b0b6-c0be6e435c17.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"url": "https://staging-media.toyota.com.ar/531ec49f-84b6-4728-94ae-b05a3f7700c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"url": "https://staging-media.toyota.com.ar/cae1dd04-7e96-433d-aaeb-478cadd19b84.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"url": "https://staging-media.toyota.com.ar/29b87c7b-2e64-4dc9-8975-9915f182a398.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb7aa763-4165-4fe6-a428-7b2fc4b952fc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a28cb4f6-88c6-45b3-a704-ce762f0f52dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "144f1113-cd82-4c9b-9271-439045606691.png",
														"url": "https://staging-media.toyota.com.ar/144f1113-cd82-4c9b-9271-439045606691.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"url": "https://staging-media.toyota.com.ar/ee338cbb-f2a4-4f4b-8705-a4def1d8e669.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla XEi",
														"path": "a5416432-be45-4191-a726-dd79d1e5006b.png",
														"url": "https://staging-media.toyota.com.ar/a5416432-be45-4191-a726-dd79d1e5006b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"url": "https://staging-media.toyota.com.ar/f34fa07d-0706-45a0-9015-a9826cbb5d00.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"url": "https://staging-media.toyota.com.ar/7a66b0eb-57bf-4eb6-a6d2-f16ebab5e34c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"url": "https://staging-media.toyota.com.ar/e6030242-fffc-49cf-acae-001aee56d3bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "Altis Premium",
						"id": "4f48ee38-e28a-44c9-931d-6516d43c1a44",
						"order": 3,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 178990,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f9097437-754f-4c6c-887f-168568653cc5.png",
														"url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 181010,
										"offers": null,
										"color": {
											"name": "Cinza Celestial (1K3)",
											"code": "1K3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f9097437-754f-4c6c-887f-168568653cc5.png",
														"url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 181010,
										"offers": null,
										"color": {
											"name": "Prata Supernova (1E7)",
											"code": "1E7",
											"rgb": "#D5D6D8"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f9097437-754f-4c6c-887f-168568653cc5.png",
														"url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 181010,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f9097437-754f-4c6c-887f-168568653cc5.png",
														"url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 181010,
										"offers": null,
										"color": {
											"name": "Preto Eclipse (209) ",
											"code": "209",
											"rgb": "#202021"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f9097437-754f-4c6c-887f-168568653cc5.png",
														"url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 181010,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f9097437-754f-4c6c-887f-168568653cc5.png",
														"url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 181320,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"url": "https://staging-media.toyota.com.ar/b8a6df28-fd11-4a4b-a9ad-984d74373607.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1d4e25e1-d9c9-48c0-9ade-aca6dfb8efe9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/ac9b43a6-960d-4fce-b8fe-b49fbe2498c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"url": "https://staging-media.toyota.com.ar/1eb15e67-c888-4a4c-bc96-239685d928e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfd5c513-b7bf-4899-a8dc-9e965f363a9f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"url": "https://staging-media.toyota.com.ar/a5cf8e5a-1a76-43b6-88cb-710da734697e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"url": "https://staging-media.toyota.com.ar/28769e93-b60e-4b7a-b62a-c0469c6eed3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"url": "https://staging-media.toyota.com.ar/1053ef88-7735-4e2b-a15d-a0da36e8ef59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"url": "https://staging-media.toyota.com.ar/ff9260b8-7f1c-4f9b-8796-2161cb65d6a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"url": "https://staging-media.toyota.com.ar/ef6564da-6e4f-4a7d-9162-9ce3e514eb50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"url": "https://staging-media.toyota.com.ar/19706191-5157-4f6f-9ea4-bb1ef5a47741.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"url": "https://staging-media.toyota.com.ar/94b06664-05e9-4329-b234-01bef0fd5d27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"url": "https://staging-media.toyota.com.ar/f25e3af8-a65f-45a6-b66c-ebd69b1d3dd5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"url": "https://staging-media.toyota.com.ar/ec6e79e9-e7ae-4c19-a564-91424cd0ef58.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"url": "https://staging-media.toyota.com.ar/4c850591-5e91-483b-a609-ecc57ee7187b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"url": "https://staging-media.toyota.com.ar/282df811-b4c7-4c31-bc7e-061522d8502e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"url": "https://staging-media.toyota.com.ar/1c4a1342-4d3c-44bf-9bb3-95147a2cd95e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"url": "https://staging-media.toyota.com.ar/b0611e82-91fd-41d4-87a3-623eb1e582eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"url": "https://staging-media.toyota.com.ar/c343134a-b6c0-4b37-af0e-056f77ccc3ce.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"url": "https://staging-media.toyota.com.ar/4f11135d-a092-4790-8f5c-7aa8ddfa159f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"url": "https://staging-media.toyota.com.ar/cf3d7a7e-0593-437e-bfc0-76d890c4f1fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"url": "https://staging-media.toyota.com.ar/14f926dc-fcad-4cc2-abe1-e196904e0242.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"url": "https://staging-media.toyota.com.ar/01dc26c6-f3ec-4436-ad59-2233fd4981ac.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"url": "https://staging-media.toyota.com.ar/1de754e1-4201-457c-a4b1-700f335b97b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"url": "https://staging-media.toyota.com.ar/f1396c7b-aa0e-4d0c-9033-93a7eb6e41fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"url": "https://staging-media.toyota.com.ar/73ab4a4e-ab14-4788-9fad-dd2cb1c81da9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"url": "https://staging-media.toyota.com.ar/49e551e9-1660-4f78-be4a-984ec2a6836a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"url": "https://staging-media.toyota.com.ar/2bce00d1-2bba-4d28-83bf-82b4d7df54e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"url": "https://staging-media.toyota.com.ar/591ad7e3-eab4-4782-8274-2f6922f070c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"url": "https://staging-media.toyota.com.ar/dfeaa10d-ff26-474b-b8e9-10357e9a66e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"url": "https://staging-media.toyota.com.ar/85a14f9c-6137-4f08-85b2-2d68cbb58463.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "f9097437-754f-4c6c-887f-168568653cc5.png",
														"url": "https://staging-media.toyota.com.ar/f9097437-754f-4c6c-887f-168568653cc5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"url": "https://staging-media.toyota.com.ar/774e5d9e-25a0-4185-b3f8-4b7165661fbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Premium",
														"path": "9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"url": "https://staging-media.toyota.com.ar/9c988496-bfdf-4f2a-bb68-1b75ddb40ffd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040",
														"path": "a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"url": "https://staging-media.toyota.com.br/a31986b4-33f6-4e8f-8acc-94adf1fb0019.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1K3",
													"media": {
														"title": "1k3",
														"path": "10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"url": "https://staging-media.toyota.com.br/10b2af9c-3348-4e0b-8b5a-7b5e02ead490.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "GR-Sport",
						"id": "cf6d5f8f-9c73-4c5d-9cf0-d8f953ad1fe9",
						"order": 4,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 178390,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (2NU)",
											"code": "2NU",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 6",
														"path": "db5adee8-f9e3-4a2b-a089-6b7302219e3b.jpeg",
														"url": "https://staging-media.toyota.com.ar/db5adee8-f9e3-4a2b-a089-6b7302219e3b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 7",
														"path": "7cf34f85-8b60-4734-bfa0-607625bff47a.jpeg",
														"url": "https://staging-media.toyota.com.ar/7cf34f85-8b60-4734-bfa0-607625bff47a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 1",
														"path": "96f8e9f9-cf87-41fd-9e6b-48abbd42a3fd.png",
														"url": "https://staging-media.toyota.com.ar/96f8e9f9-cf87-41fd-9e6b-48abbd42a3fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 2",
														"path": "ebf78d82-ef44-4da1-8d25-0da64c8a7497.png",
														"url": "https://staging-media.toyota.com.ar/ebf78d82-ef44-4da1-8d25-0da64c8a7497.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 3",
														"path": "d674dbb1-365f-41a6-b484-0e414a1dda43.png",
														"url": "https://staging-media.toyota.com.ar/d674dbb1-365f-41a6-b484-0e414a1dda43.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 4",
														"path": "492fb261-b18a-4997-8538-f06b3202f490.jpeg",
														"url": "https://staging-media.toyota.com.ar/492fb261-b18a-4997-8538-f06b3202f490.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 5",
														"path": "f58b5011-6467-4d11-802f-d0f24db4c030.png",
														"url": "https://staging-media.toyota.com.ar/f58b5011-6467-4d11-802f-d0f24db4c030.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 1",
														"path": "d58a3471-e1d6-4622-ae76-b89d3fd6ffce.png",
														"url": "https://staging-media.toyota.com.ar/d58a3471-e1d6-4622-ae76-b89d3fd6ffce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 2",
														"path": "710de1de-47da-42ea-a6b3-fb07c17c3b38.jpeg",
														"url": "https://staging-media.toyota.com.ar/710de1de-47da-42ea-a6b3-fb07c17c3b38.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 3",
														"path": "4ff9b71f-ea59-433e-a8ab-bdddb01b0452.png",
														"url": "https://staging-media.toyota.com.ar/4ff9b71f-ea59-433e-a8ab-bdddb01b0452.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 4",
														"path": "459d1f0e-6bd0-4ce1-846a-13a43f2db27c.png",
														"url": "https://staging-media.toyota.com.ar/459d1f0e-6bd0-4ce1-846a-13a43f2db27c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 5",
														"path": "9369cb06-ad3a-47a2-b2cd-7f9c1be212f6.jpeg",
														"url": "https://staging-media.toyota.com.ar/9369cb06-ad3a-47a2-b2cd-7f9c1be212f6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 6",
														"path": "169adfd9-ff02-46e7-a815-7059def37407.jpeg",
														"url": "https://staging-media.toyota.com.ar/169adfd9-ff02-46e7-a815-7059def37407.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 7",
														"path": "04883670-cefb-40c1-8b65-7bda06c61fb7.jpeg",
														"url": "https://staging-media.toyota.com.ar/04883670-cefb-40c1-8b65-7bda06c61fb7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "724a93c6-70d5-4318-9fb6-264d1219c652.png",
														"url": "https://staging-media.toyota.com.ar/724a93c6-70d5-4318-9fb6-264d1219c652.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "220f14c2-1d50-4c51-92b8-90371e588867.png",
														"url": "https://staging-media.toyota.com.ar/220f14c2-1d50-4c51-92b8-90371e588867.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "cdcac2f1-bf67-4fac-ab5b-85667bd106bc.png",
														"url": "https://staging-media.toyota.com.ar/cdcac2f1-bf67-4fac-ab5b-85667bd106bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desk",
														"path": "aff042c7-c9e9-4a60-bda1-d11c09ef43ad.jpeg",
														"url": "https://staging-media.toyota.com.ar/aff042c7-c9e9-4a60-bda1-d11c09ef43ad.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "781346d1-d627-447c-bf18-c23907f00e95.jpeg",
														"url": "https://staging-media.toyota.com.ar/781346d1-d627-447c-bf18-c23907f00e95.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2NU",
													"media": {
														"title": "desk",
														"path": "59c3c1e8-ef31-4084-ae68-ee11c926d88d.png",
														"url": "https://staging-media.toyota.com.ar/59c3c1e8-ef31-4084-ae68-ee11c926d88d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2NU",
													"media": {
														"title": "mobile",
														"path": "58fb3be8-272f-413a-a842-e26cfdafdbd0.png",
														"url": "https://staging-media.toyota.com.ar/58fb3be8-272f-413a-a842-e26cfdafdbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "desk",
														"path": "726921a0-c353-49e3-9f3d-477f31247a54.png",
														"url": "https://staging-media.toyota.com.ar/726921a0-c353-49e3-9f3d-477f31247a54.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "mobile",
														"path": "7c84c390-aa84-49a2-a7ee-f69da7bf3780.png",
														"url": "https://staging-media.toyota.com.ar/7c84c390-aa84-49a2-a7ee-f69da7bf3780.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PU",
													"media": {
														"title": "desk",
														"path": "1768fb0c-7a1a-4184-b514-fbdba9d1f779.png",
														"url": "https://staging-media.toyota.com.ar/1768fb0c-7a1a-4184-b514-fbdba9d1f779.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PU",
													"media": {
														"title": "mobile",
														"path": "e66c9015-f10b-44a4-8982-7d9cd53d18a1.png",
														"url": "https://staging-media.toyota.com.ar/e66c9015-f10b-44a4-8982-7d9cd53d18a1.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 178390,
										"offers": null,
										"color": {
											"name": "Preto Eclipse (209) ",
											"code": "209",
											"rgb": "#202021"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 6",
														"path": "db5adee8-f9e3-4a2b-a089-6b7302219e3b.jpeg",
														"url": "https://staging-media.toyota.com.ar/db5adee8-f9e3-4a2b-a089-6b7302219e3b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 7",
														"path": "7cf34f85-8b60-4734-bfa0-607625bff47a.jpeg",
														"url": "https://staging-media.toyota.com.ar/7cf34f85-8b60-4734-bfa0-607625bff47a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 1",
														"path": "96f8e9f9-cf87-41fd-9e6b-48abbd42a3fd.png",
														"url": "https://staging-media.toyota.com.ar/96f8e9f9-cf87-41fd-9e6b-48abbd42a3fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 2",
														"path": "ebf78d82-ef44-4da1-8d25-0da64c8a7497.png",
														"url": "https://staging-media.toyota.com.ar/ebf78d82-ef44-4da1-8d25-0da64c8a7497.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 3",
														"path": "d674dbb1-365f-41a6-b484-0e414a1dda43.png",
														"url": "https://staging-media.toyota.com.ar/d674dbb1-365f-41a6-b484-0e414a1dda43.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 4",
														"path": "492fb261-b18a-4997-8538-f06b3202f490.jpeg",
														"url": "https://staging-media.toyota.com.ar/492fb261-b18a-4997-8538-f06b3202f490.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 5",
														"path": "f58b5011-6467-4d11-802f-d0f24db4c030.png",
														"url": "https://staging-media.toyota.com.ar/f58b5011-6467-4d11-802f-d0f24db4c030.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 1",
														"path": "d58a3471-e1d6-4622-ae76-b89d3fd6ffce.png",
														"url": "https://staging-media.toyota.com.ar/d58a3471-e1d6-4622-ae76-b89d3fd6ffce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 2",
														"path": "710de1de-47da-42ea-a6b3-fb07c17c3b38.jpeg",
														"url": "https://staging-media.toyota.com.ar/710de1de-47da-42ea-a6b3-fb07c17c3b38.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 3",
														"path": "4ff9b71f-ea59-433e-a8ab-bdddb01b0452.png",
														"url": "https://staging-media.toyota.com.ar/4ff9b71f-ea59-433e-a8ab-bdddb01b0452.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 4",
														"path": "459d1f0e-6bd0-4ce1-846a-13a43f2db27c.png",
														"url": "https://staging-media.toyota.com.ar/459d1f0e-6bd0-4ce1-846a-13a43f2db27c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 5",
														"path": "9369cb06-ad3a-47a2-b2cd-7f9c1be212f6.jpeg",
														"url": "https://staging-media.toyota.com.ar/9369cb06-ad3a-47a2-b2cd-7f9c1be212f6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 6",
														"path": "169adfd9-ff02-46e7-a815-7059def37407.jpeg",
														"url": "https://staging-media.toyota.com.ar/169adfd9-ff02-46e7-a815-7059def37407.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 7",
														"path": "04883670-cefb-40c1-8b65-7bda06c61fb7.jpeg",
														"url": "https://staging-media.toyota.com.ar/04883670-cefb-40c1-8b65-7bda06c61fb7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "724a93c6-70d5-4318-9fb6-264d1219c652.png",
														"url": "https://staging-media.toyota.com.ar/724a93c6-70d5-4318-9fb6-264d1219c652.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "220f14c2-1d50-4c51-92b8-90371e588867.png",
														"url": "https://staging-media.toyota.com.ar/220f14c2-1d50-4c51-92b8-90371e588867.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "cdcac2f1-bf67-4fac-ab5b-85667bd106bc.png",
														"url": "https://staging-media.toyota.com.ar/cdcac2f1-bf67-4fac-ab5b-85667bd106bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desk",
														"path": "aff042c7-c9e9-4a60-bda1-d11c09ef43ad.jpeg",
														"url": "https://staging-media.toyota.com.ar/aff042c7-c9e9-4a60-bda1-d11c09ef43ad.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "781346d1-d627-447c-bf18-c23907f00e95.jpeg",
														"url": "https://staging-media.toyota.com.ar/781346d1-d627-447c-bf18-c23907f00e95.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2NU",
													"media": {
														"title": "desk",
														"path": "59c3c1e8-ef31-4084-ae68-ee11c926d88d.png",
														"url": "https://staging-media.toyota.com.ar/59c3c1e8-ef31-4084-ae68-ee11c926d88d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2NU",
													"media": {
														"title": "mobile",
														"path": "58fb3be8-272f-413a-a842-e26cfdafdbd0.png",
														"url": "https://staging-media.toyota.com.ar/58fb3be8-272f-413a-a842-e26cfdafdbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "desk",
														"path": "726921a0-c353-49e3-9f3d-477f31247a54.png",
														"url": "https://staging-media.toyota.com.ar/726921a0-c353-49e3-9f3d-477f31247a54.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "mobile",
														"path": "7c84c390-aa84-49a2-a7ee-f69da7bf3780.png",
														"url": "https://staging-media.toyota.com.ar/7c84c390-aa84-49a2-a7ee-f69da7bf3780.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PU",
													"media": {
														"title": "desk",
														"path": "1768fb0c-7a1a-4184-b514-fbdba9d1f779.png",
														"url": "https://staging-media.toyota.com.ar/1768fb0c-7a1a-4184-b514-fbdba9d1f779.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PU",
													"media": {
														"title": "mobile",
														"path": "e66c9015-f10b-44a4-8982-7d9cd53d18a1.png",
														"url": "https://staging-media.toyota.com.ar/e66c9015-f10b-44a4-8982-7d9cd53d18a1.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 178710,
										"offers": null,
										"color": {
											"name": "Branco Lunar (2PU) ",
											"code": "2PU",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 6",
														"path": "db5adee8-f9e3-4a2b-a089-6b7302219e3b.jpeg",
														"url": "https://staging-media.toyota.com.ar/db5adee8-f9e3-4a2b-a089-6b7302219e3b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 7",
														"path": "7cf34f85-8b60-4734-bfa0-607625bff47a.jpeg",
														"url": "https://staging-media.toyota.com.ar/7cf34f85-8b60-4734-bfa0-607625bff47a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 1",
														"path": "96f8e9f9-cf87-41fd-9e6b-48abbd42a3fd.png",
														"url": "https://staging-media.toyota.com.ar/96f8e9f9-cf87-41fd-9e6b-48abbd42a3fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 2",
														"path": "ebf78d82-ef44-4da1-8d25-0da64c8a7497.png",
														"url": "https://staging-media.toyota.com.ar/ebf78d82-ef44-4da1-8d25-0da64c8a7497.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 3",
														"path": "d674dbb1-365f-41a6-b484-0e414a1dda43.png",
														"url": "https://staging-media.toyota.com.ar/d674dbb1-365f-41a6-b484-0e414a1dda43.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 4",
														"path": "492fb261-b18a-4997-8538-f06b3202f490.jpeg",
														"url": "https://staging-media.toyota.com.ar/492fb261-b18a-4997-8538-f06b3202f490.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "int 5",
														"path": "f58b5011-6467-4d11-802f-d0f24db4c030.png",
														"url": "https://staging-media.toyota.com.ar/f58b5011-6467-4d11-802f-d0f24db4c030.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 1",
														"path": "d58a3471-e1d6-4622-ae76-b89d3fd6ffce.png",
														"url": "https://staging-media.toyota.com.ar/d58a3471-e1d6-4622-ae76-b89d3fd6ffce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 2",
														"path": "710de1de-47da-42ea-a6b3-fb07c17c3b38.jpeg",
														"url": "https://staging-media.toyota.com.ar/710de1de-47da-42ea-a6b3-fb07c17c3b38.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 3",
														"path": "4ff9b71f-ea59-433e-a8ab-bdddb01b0452.png",
														"url": "https://staging-media.toyota.com.ar/4ff9b71f-ea59-433e-a8ab-bdddb01b0452.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 4",
														"path": "459d1f0e-6bd0-4ce1-846a-13a43f2db27c.png",
														"url": "https://staging-media.toyota.com.ar/459d1f0e-6bd0-4ce1-846a-13a43f2db27c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 5",
														"path": "9369cb06-ad3a-47a2-b2cd-7f9c1be212f6.jpeg",
														"url": "https://staging-media.toyota.com.ar/9369cb06-ad3a-47a2-b2cd-7f9c1be212f6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 6",
														"path": "169adfd9-ff02-46e7-a815-7059def37407.jpeg",
														"url": "https://staging-media.toyota.com.ar/169adfd9-ff02-46e7-a815-7059def37407.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 7",
														"path": "04883670-cefb-40c1-8b65-7bda06c61fb7.jpeg",
														"url": "https://staging-media.toyota.com.ar/04883670-cefb-40c1-8b65-7bda06c61fb7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "724a93c6-70d5-4318-9fb6-264d1219c652.png",
														"url": "https://staging-media.toyota.com.ar/724a93c6-70d5-4318-9fb6-264d1219c652.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "220f14c2-1d50-4c51-92b8-90371e588867.png",
														"url": "https://staging-media.toyota.com.ar/220f14c2-1d50-4c51-92b8-90371e588867.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "cdcac2f1-bf67-4fac-ab5b-85667bd106bc.png",
														"url": "https://staging-media.toyota.com.ar/cdcac2f1-bf67-4fac-ab5b-85667bd106bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desk",
														"path": "aff042c7-c9e9-4a60-bda1-d11c09ef43ad.jpeg",
														"url": "https://staging-media.toyota.com.ar/aff042c7-c9e9-4a60-bda1-d11c09ef43ad.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "781346d1-d627-447c-bf18-c23907f00e95.jpeg",
														"url": "https://staging-media.toyota.com.ar/781346d1-d627-447c-bf18-c23907f00e95.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2NU",
													"media": {
														"title": "desk",
														"path": "59c3c1e8-ef31-4084-ae68-ee11c926d88d.png",
														"url": "https://staging-media.toyota.com.ar/59c3c1e8-ef31-4084-ae68-ee11c926d88d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2NU",
													"media": {
														"title": "mobile",
														"path": "58fb3be8-272f-413a-a842-e26cfdafdbd0.png",
														"url": "https://staging-media.toyota.com.ar/58fb3be8-272f-413a-a842-e26cfdafdbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "desk",
														"path": "726921a0-c353-49e3-9f3d-477f31247a54.png",
														"url": "https://staging-media.toyota.com.ar/726921a0-c353-49e3-9f3d-477f31247a54.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "mobile",
														"path": "7c84c390-aa84-49a2-a7ee-f69da7bf3780.png",
														"url": "https://staging-media.toyota.com.ar/7c84c390-aa84-49a2-a7ee-f69da7bf3780.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PU",
													"media": {
														"title": "desk",
														"path": "1768fb0c-7a1a-4184-b514-fbdba9d1f779.png",
														"url": "https://staging-media.toyota.com.ar/1768fb0c-7a1a-4184-b514-fbdba9d1f779.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PU",
													"media": {
														"title": "mobile",
														"path": "e66c9015-f10b-44a4-8982-7d9cd53d18a1.png",
														"url": "https://staging-media.toyota.com.ar/e66c9015-f10b-44a4-8982-7d9cd53d18a1.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "Altis Hybrid",
						"id": "6b11e2eb-2f3a-498a-86cd-12ab3123d7c8",
						"order": 5,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 183890,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
														"url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "572dd007-b107-4468-871a-21f2eb583f09.png",
														"url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 185910,
										"offers": null,
										"color": {
											"name": "Cinza Celestial (1K3)",
											"code": "1K3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
														"url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "572dd007-b107-4468-871a-21f2eb583f09.png",
														"url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 185910,
										"offers": null,
										"color": {
											"name": "Prata Supernova (1E7)",
											"code": "1E7",
											"rgb": "#D5D6D8"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
														"url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "572dd007-b107-4468-871a-21f2eb583f09.png",
														"url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 185910,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
														"url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "572dd007-b107-4468-871a-21f2eb583f09.png",
														"url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 185910,
										"offers": null,
										"color": {
											"name": "Preto Eclipse (209) ",
											"code": "209",
											"rgb": "#202021"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
														"url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "572dd007-b107-4468-871a-21f2eb583f09.png",
														"url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 185910,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
														"url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "572dd007-b107-4468-871a-21f2eb583f09.png",
														"url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 186220,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/6e10a090-2b2f-4a75-9dd6-fa36aefb88c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"url": "https://staging-media.toyota.com.ar/4347c907-af54-44a4-9ce5-5b125cf6fc7e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"url": "https://staging-media.toyota.com.ar/612ea3a5-e7dd-4412-8656-e4b1b94ca95f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"url": "https://staging-media.toyota.com.ar/07d017e5-3cc8-439a-b5ce-68020fc54515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"url": "https://staging-media.toyota.com.ar/ad980caa-01a8-4705-8100-54d5e29e6f2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"url": "https://staging-media.toyota.com.ar/7b9147aa-b794-4378-a97f-e4c81a6323fb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Corolla Altis Hybrid - 1E7",
														"path": "03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"url": "https://staging-media.toyota.com.ar/03d8cd9c-8c89-4dc3-8f30-e1ee38c4b0ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"url": "https://staging-media.toyota.com.ar/7f60a0eb-3702-4829-a075-d2230d450ec5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "38edae88-3e81-4348-92a3-c300790b6a71.png",
														"url": "https://staging-media.toyota.com.ar/38edae88-3e81-4348-92a3-c300790b6a71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"url": "https://staging-media.toyota.com.ar/b1c76ddf-92b7-4e54-b9e8-a96ad4712179.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "572dd007-b107-4468-871a-21f2eb583f09.png",
														"url": "https://staging-media.toyota.com.ar/572dd007-b107-4468-871a-21f2eb583f09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"url": "https://staging-media.toyota.com.ar/175d80a4-f1a9-4b3b-824a-22416f5580d5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"url": "https://staging-media.toyota.com.ar/c64033e1-aa71-48ca-aeab-a6da5abd6287.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"url": "https://staging-media.toyota.com.ar/f89ab739-e12b-42f9-adf9-86a4ac79831d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"url": "https://staging-media.toyota.com.ar/438b2207-4953-4eda-9047-70b9277c9ad3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea93da6c-5c99-4338-84c5-cca375df59ef.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"url": "https://staging-media.toyota.com.ar/65f215c7-aa85-4ccd-86bc-75ca78b2a99b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"url": "https://staging-media.toyota.com.ar/eff4b08c-b5c0-4aed-ba08-dc05677dd832.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"url": "https://staging-media.toyota.com.ar/3fd9a934-6e66-4bbf-a466-c7555145a75c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"url": "https://staging-media.toyota.com.ar/c90f901c-ecba-4ce6-a011-a70551c83649.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"url": "https://staging-media.toyota.com.ar/40f3bf08-bc69-4a58-8b52-e09319a346b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"url": "https://staging-media.toyota.com.ar/61d98d00-13ee-4b50-8b71-c5cded4ef8a9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"url": "https://staging-media.toyota.com.br/00718f61-f847-4652-9ec1-e0879fdeaf1d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"url": "https://staging-media.toyota.com.ar/ff7e091c-67c3-4a2b-8b5b-6142f1a931ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"url": "https://staging-media.toyota.com.ar/a5aface1-1f94-41d8-b1f4-305bba745a60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"url": "https://staging-media.toyota.com.ar/c18333a7-f03c-4b06-b507-bae6c834be5c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"url": "https://staging-media.toyota.com.ar/c7688310-ca2a-4abe-a4a4-2705f10a39c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"url": "https://staging-media.toyota.com.ar/2624ad1b-aa5a-4a81-b748-dc58e8923731.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"url": "https://staging-media.toyota.com.ar/dedee2e0-5fff-4cf6-aa3f-70c1cb61cddf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/51882ea1-6685-4564-92cf-6ddc303703e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"url": "https://staging-media.toyota.com.ar/43353a41-fbd2-4bf3-983d-5e7a9b81bf05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"url": "https://staging-media.toyota.com.ar/67bd8b86-b37d-4f2e-b628-daf1195fd8f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"url": "https://staging-media.toyota.com.ar/4ab3aa2f-40cb-4ac3-a65f-c6cd67193826.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Altis Hybrid",
														"path": "9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cf429c4-0346-498e-9000-d1531185ba8e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "Altis Hybrid Premium",
						"id": "a47f3e1c-14e6-4c64-bdd4-33f6583758da",
						"order": 6,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 193690,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3",
														"path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3 mobile",
														"path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7",
														"path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7 mobile",
														"path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3",
														"path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3 mobile",
														"path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Altis Hybrid Premium 209",
														"path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Altis Hybrid Premium 209 mobile",
														"path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3",
														"path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3 mobile",
														"path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Altis Hybrid Premium 089",
														"path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Altis Hybrid Premium 089 mobile",
														"path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo corolla altis hybrid premiun",
														"path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "6ea24391-2037-4051-a133-54214f4712da.png",
														"url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Altis Hybrid Premium 040",
														"path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Altis Hybrid Premium 040 mobile",
														"path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "03651604-1121-4025-a5cf-575e510385f6.png",
														"url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Corolla_TDB_Desktop",
														"path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Corolla_TDB_Mobile",
														"path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 195710,
										"offers": null,
										"color": {
											"name": "Cinza Celestial (1K3)",
											"code": "1K3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3",
														"path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3 mobile",
														"path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7",
														"path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7 mobile",
														"path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3",
														"path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3 mobile",
														"path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Altis Hybrid Premium 209",
														"path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Altis Hybrid Premium 209 mobile",
														"path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3",
														"path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3 mobile",
														"path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Altis Hybrid Premium 089",
														"path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Altis Hybrid Premium 089 mobile",
														"path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo corolla altis hybrid premiun",
														"path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "6ea24391-2037-4051-a133-54214f4712da.png",
														"url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Altis Hybrid Premium 040",
														"path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Altis Hybrid Premium 040 mobile",
														"path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "03651604-1121-4025-a5cf-575e510385f6.png",
														"url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Corolla_TDB_Desktop",
														"path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Corolla_TDB_Mobile",
														"path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 195710,
										"offers": null,
										"color": {
											"name": "Prata Supernova (1E7)",
											"code": "1E7",
											"rgb": "#D5D6D8"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3",
														"path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3 mobile",
														"path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7",
														"path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7 mobile",
														"path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3",
														"path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3 mobile",
														"path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Altis Hybrid Premium 209",
														"path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Altis Hybrid Premium 209 mobile",
														"path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3",
														"path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3 mobile",
														"path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Altis Hybrid Premium 089",
														"path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Altis Hybrid Premium 089 mobile",
														"path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo corolla altis hybrid premiun",
														"path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "6ea24391-2037-4051-a133-54214f4712da.png",
														"url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Altis Hybrid Premium 040",
														"path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Altis Hybrid Premium 040 mobile",
														"path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "03651604-1121-4025-a5cf-575e510385f6.png",
														"url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Corolla_TDB_Desktop",
														"path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Corolla_TDB_Mobile",
														"path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 195710,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3",
														"path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3 mobile",
														"path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7",
														"path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7 mobile",
														"path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3",
														"path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3 mobile",
														"path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Altis Hybrid Premium 209",
														"path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Altis Hybrid Premium 209 mobile",
														"path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3",
														"path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3 mobile",
														"path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Altis Hybrid Premium 089",
														"path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Altis Hybrid Premium 089 mobile",
														"path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo corolla altis hybrid premiun",
														"path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "6ea24391-2037-4051-a133-54214f4712da.png",
														"url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Altis Hybrid Premium 040",
														"path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Altis Hybrid Premium 040 mobile",
														"path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "03651604-1121-4025-a5cf-575e510385f6.png",
														"url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Corolla_TDB_Desktop",
														"path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Corolla_TDB_Mobile",
														"path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 195710,
										"offers": null,
										"color": {
											"name": "Preto Eclipse (209) ",
											"code": "209",
											"rgb": "#202021"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3",
														"path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3 mobile",
														"path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7",
														"path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7 mobile",
														"path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3",
														"path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3 mobile",
														"path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Altis Hybrid Premium 209",
														"path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Altis Hybrid Premium 209 mobile",
														"path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3",
														"path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3 mobile",
														"path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Altis Hybrid Premium 089",
														"path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Altis Hybrid Premium 089 mobile",
														"path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo corolla altis hybrid premiun",
														"path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "6ea24391-2037-4051-a133-54214f4712da.png",
														"url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Altis Hybrid Premium 040",
														"path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Altis Hybrid Premium 040 mobile",
														"path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "03651604-1121-4025-a5cf-575e510385f6.png",
														"url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Corolla_TDB_Desktop",
														"path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Corolla_TDB_Mobile",
														"path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 195710,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3",
														"path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3 mobile",
														"path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7",
														"path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7 mobile",
														"path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3",
														"path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3 mobile",
														"path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Altis Hybrid Premium 209",
														"path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Altis Hybrid Premium 209 mobile",
														"path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3",
														"path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3 mobile",
														"path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Altis Hybrid Premium 089",
														"path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Altis Hybrid Premium 089 mobile",
														"path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo corolla altis hybrid premiun",
														"path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "6ea24391-2037-4051-a133-54214f4712da.png",
														"url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Altis Hybrid Premium 040",
														"path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Altis Hybrid Premium 040 mobile",
														"path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "03651604-1121-4025-a5cf-575e510385f6.png",
														"url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Corolla_TDB_Desktop",
														"path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Corolla_TDB_Mobile",
														"path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 196020,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3",
														"path": "011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"url": "https://staging-media.toyota.com.ar/011f7cdc-3af0-49b4-aa7c-bf5022e8d9e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "Altis Hybrid Premium 1k3 mobile",
														"path": "58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"url": "https://staging-media.toyota.com.ar/58c569b9-f669-4a67-970a-7a2e2d50f893.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7",
														"path": "ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"url": "https://staging-media.toyota.com.ar/ddfe1c8e-a499-4010-a6ea-e032b82558c8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "Altis Hybrid Premium 1e7 mobile",
														"path": "e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"url": "https://staging-media.toyota.com.ar/e70d0f65-2dd5-4de2-b884-c90aa36465b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3",
														"path": "fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"url": "https://staging-media.toyota.com.ar/fc10596e-b00c-4bd9-a29a-a58acbbc5352.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "Altis Hybrid Premium 3r3 mobile",
														"path": "977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"url": "https://staging-media.toyota.com.ar/977f9a7f-1feb-4d9a-bbdb-bd45431bf08f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "Altis Hybrid Premium 209",
														"path": "103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"url": "https://staging-media.toyota.com.ar/103bd935-2bb6-4dfc-90e4-5235ca5498d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "Altis Hybrid Premium 209 mobile",
														"path": "84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"url": "https://staging-media.toyota.com.ar/84c2df9a-c7e6-4e23-aa61-627ee4d0bb9f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3",
														"path": "3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"url": "https://staging-media.toyota.com.ar/3fbacd77-ab44-4415-9bbd-712dee813c97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Altis Hybrid Premium 1g3 mobile",
														"path": "4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"url": "https://staging-media.toyota.com.ar/4df82c1e-7d9f-4d09-b9ce-8e5177ec2940.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Altis Hybrid Premium 089",
														"path": "01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"url": "https://staging-media.toyota.com.ar/01f6b84a-d9c7-47a5-8aa1-5c3598d82a4b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Altis Hybrid Premium 089 mobile",
														"path": "0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"url": "https://staging-media.toyota.com.ar/0cd3edad-ecf2-4e6f-a959-275f3a73448d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo corolla altis hybrid premiun",
														"path": "d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"url": "https://staging-media.toyota.com.ar/d48ebe2c-afa6-43d3-8093-4d49c8883e89.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "6ea24391-2037-4051-a133-54214f4712da.png",
														"url": "https://staging-media.toyota.com.br/6ea24391-2037-4051-a133-54214f4712da.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"url": "https://staging-media.toyota.com.ar/130c926a-e243-41fa-a96d-51c87e1e01d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"url": "https://staging-media.toyota.com.ar/5fe90e7d-11db-4073-9df2-e483523c8457.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"url": "https://staging-media.toyota.com.ar/92d43d0e-217d-4276-aacf-00e70e3a15a1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"url": "https://staging-media.toyota.com.ar/d240e30c-78b2-4fc7-af7e-4ad4fcbe6b8a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/d0996402-1d24-4e12-834b-5fc532b72bd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/17fe5109-b4a2-4e0f-b3e4-550be489f5f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"url": "https://staging-media.toyota.com.ar/35e405e6-88b4-4279-b4c4-5740aaca7cf0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"url": "https://staging-media.toyota.com.ar/25ad8faf-5b93-4124-82d8-7652ad67220d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"url": "https://staging-media.toyota.com.ar/27553830-5733-4ace-a862-3eec4c071da6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"url": "https://staging-media.toyota.com.ar/170b9528-0b84-4109-b234-dd2f82f6666f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"url": "https://staging-media.toyota.com.ar/b2230ed8-171b-4705-9c75-7ba609a1e2f9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/a102de53-33bf-417a-b238-27b0b0bbf6b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"url": "https://staging-media.toyota.com.ar/611746d4-f0e0-47ac-89b5-b26d1a1ee191.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"url": "https://staging-media.toyota.com.ar/171697e8-363d-4262-a445-b295ad9dfcc7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Altis Hybrid Premium 040",
														"path": "ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"url": "https://staging-media.toyota.com.ar/ed0e3596-7532-48ad-86ec-7bf560e19597.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Altis Hybrid Premium 040 mobile",
														"path": "f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"url": "https://staging-media.toyota.com.ar/f4200b5b-7552-4ab4-8f26-e973efb834b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"url": "https://staging-media.toyota.com.ar/dbb5a117-ac47-48e0-bba9-d138c4590f2d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "03651604-1121-4025-a5cf-575e510385f6.png",
														"url": "https://staging-media.toyota.com.ar/03651604-1121-4025-a5cf-575e510385f6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Corolla_TDB_Desktop",
														"path": "c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"url": "https://staging-media.toyota.com.ar/c3c31076-67e7-4f87-bb2d-9495e49b8378.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Corolla_TDB_Mobile",
														"path": "8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d109b9e-c831-4962-82a3-16af54e3038c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "03",
														"path": "ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef5b1109-b86f-41f7-8d8c-4cdc9f01ed79.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "SRX TESTE",
						"id": "20c12bfe-9add-41ad-bfba-21ddbc349aba",
						"order": 7,
						"modelYears": []
					},
					{
						"name": "SRX TESTE",
						"id": "7f5e8a54-63e9-473f-a4f3-2beb378d8f77",
						"order": null,
						"modelYears": []
					},
					{
						"name": "SRX TESTE 3",
						"id": "fb7e1a96-e4d8-4bfd-80b6-3cf4d2497788",
						"order": null,
						"modelYears": [
							{
								"vehicleColors": []
							}
						]
					}
				]
			},
			{
				"id": "7c830d7d-94d8-43bc-9e13-1fec4205524d",
				"name": "Yaris Hatch",
				"vehicleExternalId": null,
				"status": "ENABLED",
				"category": {
					"name": "Carros de passeio",
					"id": "bf8c0d92-4175-4fb2-8ff1-d6cd46ff881b"
				},
				"versions": [
					{
						"name": "XL",
						"id": "4894e3cf-e609-48ad-9cb6-5af1f938ca24",
						"order": 1,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 96090,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "1",
														"path": "7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"url": "https://staging-media.toyota.com.br/7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "banner ",
														"path": "3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"url": "https://staging-media.toyota.com.br/3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 97650,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "1",
														"path": "7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"url": "https://staging-media.toyota.com.br/7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "banner ",
														"path": "3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"url": "https://staging-media.toyota.com.br/3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 97650,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "1",
														"path": "7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"url": "https://staging-media.toyota.com.br/7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "banner ",
														"path": "3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"url": "https://staging-media.toyota.com.br/3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 97650,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "1",
														"path": "7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"url": "https://staging-media.toyota.com.br/7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "banner ",
														"path": "3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"url": "https://staging-media.toyota.com.br/3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 97650,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "1",
														"path": "7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"url": "https://staging-media.toyota.com.br/7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "banner ",
														"path": "3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"url": "https://staging-media.toyota.com.br/3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 97650,
										"offers": null,
										"color": {
											"name": "Azul Titã (8V5) ",
											"code": "8V5",
											"rgb": "#3D4954"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "1",
														"path": "7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"url": "https://staging-media.toyota.com.br/7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "banner ",
														"path": "3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"url": "https://staging-media.toyota.com.br/3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 97950,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:040",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "1",
														"path": "7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"url": "https://staging-media.toyota.com.br/7f13accb-667d-4f6b-9625-ca3eb712944a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "banner ",
														"path": "3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"url": "https://staging-media.toyota.com.br/3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 97990,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "DISPLAY 1",
														"path": "a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"url": "https://staging-media.toyota.com.ar/a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "DISPLAY 2",
														"path": "c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"url": "https://staging-media.toyota.com.ar/c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"url": "https://staging-media.toyota.com.ar/c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"url": "https://staging-media.toyota.com.ar/792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"url": "https://staging-media.toyota.com.ar/9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"url": "https://staging-media.toyota.com.ar/6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"url": "https://staging-media.toyota.com.ar/5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"url": "https://staging-media.toyota.com.ar/65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"url": "https://staging-media.toyota.com.ar/0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"url": "https://staging-media.toyota.com.ar/b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"url": "https://staging-media.toyota.com.ar/9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"url": "https://staging-media.toyota.com.ar/52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"url": "https://staging-media.toyota.com.ar/b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"url": "https://staging-media.toyota.com.ar/c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"url": "https://staging-media.toyota.com.ar/bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"url": "https://staging-media.toyota.com.ar/55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99550,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "DISPLAY 1",
														"path": "a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"url": "https://staging-media.toyota.com.ar/a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "DISPLAY 2",
														"path": "c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"url": "https://staging-media.toyota.com.ar/c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"url": "https://staging-media.toyota.com.ar/c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"url": "https://staging-media.toyota.com.ar/792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"url": "https://staging-media.toyota.com.ar/9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"url": "https://staging-media.toyota.com.ar/6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"url": "https://staging-media.toyota.com.ar/5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"url": "https://staging-media.toyota.com.ar/65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"url": "https://staging-media.toyota.com.ar/0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"url": "https://staging-media.toyota.com.ar/b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"url": "https://staging-media.toyota.com.ar/9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"url": "https://staging-media.toyota.com.ar/52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"url": "https://staging-media.toyota.com.ar/b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"url": "https://staging-media.toyota.com.ar/c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"url": "https://staging-media.toyota.com.ar/bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"url": "https://staging-media.toyota.com.ar/55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99550,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "DISPLAY 1",
														"path": "a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"url": "https://staging-media.toyota.com.ar/a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "DISPLAY 2",
														"path": "c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"url": "https://staging-media.toyota.com.ar/c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"url": "https://staging-media.toyota.com.ar/c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"url": "https://staging-media.toyota.com.ar/792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"url": "https://staging-media.toyota.com.ar/9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"url": "https://staging-media.toyota.com.ar/6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"url": "https://staging-media.toyota.com.ar/5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"url": "https://staging-media.toyota.com.ar/65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"url": "https://staging-media.toyota.com.ar/0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"url": "https://staging-media.toyota.com.ar/b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"url": "https://staging-media.toyota.com.ar/9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"url": "https://staging-media.toyota.com.ar/52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"url": "https://staging-media.toyota.com.ar/b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"url": "https://staging-media.toyota.com.ar/c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"url": "https://staging-media.toyota.com.ar/bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"url": "https://staging-media.toyota.com.ar/55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99550,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "DISPLAY 1",
														"path": "a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"url": "https://staging-media.toyota.com.ar/a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "DISPLAY 2",
														"path": "c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"url": "https://staging-media.toyota.com.ar/c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"url": "https://staging-media.toyota.com.ar/c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"url": "https://staging-media.toyota.com.ar/792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"url": "https://staging-media.toyota.com.ar/9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"url": "https://staging-media.toyota.com.ar/6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"url": "https://staging-media.toyota.com.ar/5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"url": "https://staging-media.toyota.com.ar/65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"url": "https://staging-media.toyota.com.ar/0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"url": "https://staging-media.toyota.com.ar/b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"url": "https://staging-media.toyota.com.ar/9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"url": "https://staging-media.toyota.com.ar/52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"url": "https://staging-media.toyota.com.ar/b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"url": "https://staging-media.toyota.com.ar/c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"url": "https://staging-media.toyota.com.ar/bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"url": "https://staging-media.toyota.com.ar/55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99550,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "DISPLAY 1",
														"path": "a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"url": "https://staging-media.toyota.com.ar/a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "DISPLAY 2",
														"path": "c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"url": "https://staging-media.toyota.com.ar/c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"url": "https://staging-media.toyota.com.ar/c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"url": "https://staging-media.toyota.com.ar/792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"url": "https://staging-media.toyota.com.ar/9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"url": "https://staging-media.toyota.com.ar/6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"url": "https://staging-media.toyota.com.ar/5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"url": "https://staging-media.toyota.com.ar/65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"url": "https://staging-media.toyota.com.ar/0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"url": "https://staging-media.toyota.com.ar/b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"url": "https://staging-media.toyota.com.ar/9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"url": "https://staging-media.toyota.com.ar/52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"url": "https://staging-media.toyota.com.ar/b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"url": "https://staging-media.toyota.com.ar/c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"url": "https://staging-media.toyota.com.ar/bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"url": "https://staging-media.toyota.com.ar/55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99550,
										"offers": null,
										"color": {
											"name": "Azul Titã (8V5) ",
											"code": "8V5",
											"rgb": "#3D4954"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "DISPLAY 1",
														"path": "a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"url": "https://staging-media.toyota.com.ar/a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "DISPLAY 2",
														"path": "c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"url": "https://staging-media.toyota.com.ar/c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"url": "https://staging-media.toyota.com.ar/c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"url": "https://staging-media.toyota.com.ar/792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"url": "https://staging-media.toyota.com.ar/9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"url": "https://staging-media.toyota.com.ar/6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"url": "https://staging-media.toyota.com.ar/5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"url": "https://staging-media.toyota.com.ar/65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"url": "https://staging-media.toyota.com.ar/0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"url": "https://staging-media.toyota.com.ar/b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"url": "https://staging-media.toyota.com.ar/9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"url": "https://staging-media.toyota.com.ar/52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"url": "https://staging-media.toyota.com.ar/b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"url": "https://staging-media.toyota.com.ar/c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"url": "https://staging-media.toyota.com.ar/bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"url": "https://staging-media.toyota.com.ar/55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99850,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "DISPLAY 1",
														"path": "a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"url": "https://staging-media.toyota.com.ar/a0668a23-e5c7-40a3-a81c-249ced64ed73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "DISPLAY 2",
														"path": "c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"url": "https://staging-media.toyota.com.ar/c4c569d1-1522-4027-af7d-c57b6b85fb6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"url": "https://staging-media.toyota.com.ar/c41fdbe2-cfa6-4f11-a988-aa8651297d21.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"url": "https://staging-media.toyota.com.ar/792e1fd1-1b5a-4520-8423-f445ab125c68.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"url": "https://staging-media.toyota.com.ar/9d7128c6-a1da-4575-a922-1bdbf7a7fe67.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"url": "https://staging-media.toyota.com.ar/6110bf82-4f61-48e6-baa5-a71fa5396de0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"url": "https://staging-media.toyota.com.ar/5c58eec6-857b-4a1b-8ccd-5fc06c09d4ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"url": "https://staging-media.toyota.com.ar/65d383d5-fa77-4c79-8862-5d7b209428e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"url": "https://staging-media.toyota.com.ar/0387e9a7-7980-4bb4-866b-fe6ee0319683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"url": "https://staging-media.toyota.com.ar/b1b3e4ac-db1c-4294-9a8f-159f6ebfaba5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"url": "https://staging-media.toyota.com.ar/9779cb96-6662-4953-b6c2-8cdcaca038fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"url": "https://staging-media.toyota.com.ar/52dda4fd-b200-4231-8c34-8d7c57bd526e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"url": "https://staging-media.toyota.com.ar/b5ff0531-1e7d-4338-9788-f9ed563a0062.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"url": "https://staging-media.toyota.com.ar/c427d42d-ba7a-477b-8129-20de74517fc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"url": "https://staging-media.toyota.com.ar/bf25275d-1d41-44ce-a66d-2ee0ffd2ad5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"url": "https://staging-media.toyota.com.ar/55f7cbe4-3eff-453d-bfbe-9ab4a49580eb.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "XS",
						"id": "d9b557fa-e8af-4566-887e-92efcc26f191",
						"order": 2,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 105790,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large Yaris HB",
														"path": "5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"url": "https://staging-media.toyota.com.ar/5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner Yaris HB",
														"path": "ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 5",
														"path": "5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"url": "https://staging-media.toyota.com.ar/5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 4",
														"path": "3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"url": "https://staging-media.toyota.com.ar/3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 3",
														"path": "ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 2",
														"path": "712be374-0178-490e-ba57-f8ad627ff843.png",
														"url": "https://staging-media.toyota.com.ar/712be374-0178-490e-ba57-f8ad627ff843.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 1",
														"path": "8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 1",
														"path": "863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"url": "https://staging-media.toyota.com.ar/863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 7",
														"path": "a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"url": "https://staging-media.toyota.com.ar/a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 3",
														"path": "3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"url": "https://staging-media.toyota.com.ar/3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 2",
														"path": "e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"url": "https://staging-media.toyota.com.ar/e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 5",
														"path": "f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"url": "https://staging-media.toyota.com.ar/f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 4",
														"path": "abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"url": "https://staging-media.toyota.com.ar/abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 6",
														"path": "1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"url": "https://staging-media.toyota.com.ar/1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"url": "https://staging-media.toyota.com.ar/d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile",
														"path": "19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"url": "https://staging-media.toyota.com.ar/19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"url": "https://staging-media.toyota.com.ar/4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"url": "https://staging-media.toyota.com.ar/59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"url": "https://staging-media.toyota.com.ar/e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"url": "https://staging-media.toyota.com.ar/e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"url": "https://staging-media.toyota.com.ar/74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"url": "https://staging-media.toyota.com.ar/96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"url": "https://staging-media.toyota.com.ar/720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"url": "https://staging-media.toyota.com.ar/91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"url": "https://staging-media.toyota.com.ar/03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"url": "https://staging-media.toyota.com.ar/c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"url": "https://staging-media.toyota.com.ar/1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"url": "https://staging-media.toyota.com.ar/0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "fa524d7a-07db-4330-ba60-aca05f588081.png",
														"url": "https://staging-media.toyota.com.ar/fa524d7a-07db-4330-ba60-aca05f588081.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-HB-XS-040",
														"path": "44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"url": "https://staging-media.toyota.com.ar/44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-HB-XS-040",
														"path": "1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"url": "https://staging-media.toyota.com.ar/1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 107350,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large Yaris HB",
														"path": "5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"url": "https://staging-media.toyota.com.ar/5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner Yaris HB",
														"path": "ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 5",
														"path": "5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"url": "https://staging-media.toyota.com.ar/5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 4",
														"path": "3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"url": "https://staging-media.toyota.com.ar/3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 3",
														"path": "ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 2",
														"path": "712be374-0178-490e-ba57-f8ad627ff843.png",
														"url": "https://staging-media.toyota.com.ar/712be374-0178-490e-ba57-f8ad627ff843.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 1",
														"path": "8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 1",
														"path": "863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"url": "https://staging-media.toyota.com.ar/863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 7",
														"path": "a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"url": "https://staging-media.toyota.com.ar/a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 3",
														"path": "3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"url": "https://staging-media.toyota.com.ar/3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 2",
														"path": "e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"url": "https://staging-media.toyota.com.ar/e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 5",
														"path": "f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"url": "https://staging-media.toyota.com.ar/f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 4",
														"path": "abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"url": "https://staging-media.toyota.com.ar/abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 6",
														"path": "1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"url": "https://staging-media.toyota.com.ar/1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"url": "https://staging-media.toyota.com.ar/d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile",
														"path": "19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"url": "https://staging-media.toyota.com.ar/19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"url": "https://staging-media.toyota.com.ar/4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"url": "https://staging-media.toyota.com.ar/59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"url": "https://staging-media.toyota.com.ar/e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"url": "https://staging-media.toyota.com.ar/e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"url": "https://staging-media.toyota.com.ar/74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"url": "https://staging-media.toyota.com.ar/96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"url": "https://staging-media.toyota.com.ar/720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"url": "https://staging-media.toyota.com.ar/91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"url": "https://staging-media.toyota.com.ar/03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"url": "https://staging-media.toyota.com.ar/c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"url": "https://staging-media.toyota.com.ar/1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"url": "https://staging-media.toyota.com.ar/0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "fa524d7a-07db-4330-ba60-aca05f588081.png",
														"url": "https://staging-media.toyota.com.ar/fa524d7a-07db-4330-ba60-aca05f588081.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-HB-XS-040",
														"path": "44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"url": "https://staging-media.toyota.com.ar/44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-HB-XS-040",
														"path": "1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"url": "https://staging-media.toyota.com.ar/1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 107350,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large Yaris HB",
														"path": "5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"url": "https://staging-media.toyota.com.ar/5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner Yaris HB",
														"path": "ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 5",
														"path": "5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"url": "https://staging-media.toyota.com.ar/5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 4",
														"path": "3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"url": "https://staging-media.toyota.com.ar/3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 3",
														"path": "ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 2",
														"path": "712be374-0178-490e-ba57-f8ad627ff843.png",
														"url": "https://staging-media.toyota.com.ar/712be374-0178-490e-ba57-f8ad627ff843.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 1",
														"path": "8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 1",
														"path": "863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"url": "https://staging-media.toyota.com.ar/863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 7",
														"path": "a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"url": "https://staging-media.toyota.com.ar/a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 3",
														"path": "3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"url": "https://staging-media.toyota.com.ar/3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 2",
														"path": "e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"url": "https://staging-media.toyota.com.ar/e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 5",
														"path": "f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"url": "https://staging-media.toyota.com.ar/f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 4",
														"path": "abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"url": "https://staging-media.toyota.com.ar/abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 6",
														"path": "1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"url": "https://staging-media.toyota.com.ar/1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"url": "https://staging-media.toyota.com.ar/d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile",
														"path": "19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"url": "https://staging-media.toyota.com.ar/19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"url": "https://staging-media.toyota.com.ar/4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"url": "https://staging-media.toyota.com.ar/59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"url": "https://staging-media.toyota.com.ar/e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"url": "https://staging-media.toyota.com.ar/e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"url": "https://staging-media.toyota.com.ar/74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"url": "https://staging-media.toyota.com.ar/96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"url": "https://staging-media.toyota.com.ar/720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"url": "https://staging-media.toyota.com.ar/91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"url": "https://staging-media.toyota.com.ar/03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"url": "https://staging-media.toyota.com.ar/c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"url": "https://staging-media.toyota.com.ar/1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"url": "https://staging-media.toyota.com.ar/0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "fa524d7a-07db-4330-ba60-aca05f588081.png",
														"url": "https://staging-media.toyota.com.ar/fa524d7a-07db-4330-ba60-aca05f588081.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-HB-XS-040",
														"path": "44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"url": "https://staging-media.toyota.com.ar/44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-HB-XS-040",
														"path": "1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"url": "https://staging-media.toyota.com.ar/1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 107350,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large Yaris HB",
														"path": "5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"url": "https://staging-media.toyota.com.ar/5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner Yaris HB",
														"path": "ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 5",
														"path": "5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"url": "https://staging-media.toyota.com.ar/5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 4",
														"path": "3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"url": "https://staging-media.toyota.com.ar/3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 3",
														"path": "ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 2",
														"path": "712be374-0178-490e-ba57-f8ad627ff843.png",
														"url": "https://staging-media.toyota.com.ar/712be374-0178-490e-ba57-f8ad627ff843.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 1",
														"path": "8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 1",
														"path": "863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"url": "https://staging-media.toyota.com.ar/863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 7",
														"path": "a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"url": "https://staging-media.toyota.com.ar/a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 3",
														"path": "3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"url": "https://staging-media.toyota.com.ar/3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 2",
														"path": "e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"url": "https://staging-media.toyota.com.ar/e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 5",
														"path": "f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"url": "https://staging-media.toyota.com.ar/f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 4",
														"path": "abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"url": "https://staging-media.toyota.com.ar/abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 6",
														"path": "1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"url": "https://staging-media.toyota.com.ar/1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"url": "https://staging-media.toyota.com.ar/d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile",
														"path": "19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"url": "https://staging-media.toyota.com.ar/19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"url": "https://staging-media.toyota.com.ar/4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"url": "https://staging-media.toyota.com.ar/59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"url": "https://staging-media.toyota.com.ar/e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"url": "https://staging-media.toyota.com.ar/e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"url": "https://staging-media.toyota.com.ar/74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"url": "https://staging-media.toyota.com.ar/96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"url": "https://staging-media.toyota.com.ar/720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"url": "https://staging-media.toyota.com.ar/91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"url": "https://staging-media.toyota.com.ar/03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"url": "https://staging-media.toyota.com.ar/c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"url": "https://staging-media.toyota.com.ar/1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"url": "https://staging-media.toyota.com.ar/0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "fa524d7a-07db-4330-ba60-aca05f588081.png",
														"url": "https://staging-media.toyota.com.ar/fa524d7a-07db-4330-ba60-aca05f588081.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-HB-XS-040",
														"path": "44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"url": "https://staging-media.toyota.com.ar/44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-HB-XS-040",
														"path": "1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"url": "https://staging-media.toyota.com.ar/1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 107350,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large Yaris HB",
														"path": "5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"url": "https://staging-media.toyota.com.ar/5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner Yaris HB",
														"path": "ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 5",
														"path": "5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"url": "https://staging-media.toyota.com.ar/5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 4",
														"path": "3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"url": "https://staging-media.toyota.com.ar/3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 3",
														"path": "ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 2",
														"path": "712be374-0178-490e-ba57-f8ad627ff843.png",
														"url": "https://staging-media.toyota.com.ar/712be374-0178-490e-ba57-f8ad627ff843.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 1",
														"path": "8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 1",
														"path": "863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"url": "https://staging-media.toyota.com.ar/863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 7",
														"path": "a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"url": "https://staging-media.toyota.com.ar/a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 3",
														"path": "3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"url": "https://staging-media.toyota.com.ar/3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 2",
														"path": "e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"url": "https://staging-media.toyota.com.ar/e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 5",
														"path": "f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"url": "https://staging-media.toyota.com.ar/f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 4",
														"path": "abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"url": "https://staging-media.toyota.com.ar/abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 6",
														"path": "1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"url": "https://staging-media.toyota.com.ar/1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"url": "https://staging-media.toyota.com.ar/d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile",
														"path": "19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"url": "https://staging-media.toyota.com.ar/19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"url": "https://staging-media.toyota.com.ar/4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"url": "https://staging-media.toyota.com.ar/59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"url": "https://staging-media.toyota.com.ar/e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"url": "https://staging-media.toyota.com.ar/e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"url": "https://staging-media.toyota.com.ar/74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"url": "https://staging-media.toyota.com.ar/96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"url": "https://staging-media.toyota.com.ar/720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"url": "https://staging-media.toyota.com.ar/91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"url": "https://staging-media.toyota.com.ar/03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"url": "https://staging-media.toyota.com.ar/c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"url": "https://staging-media.toyota.com.ar/1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"url": "https://staging-media.toyota.com.ar/0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "fa524d7a-07db-4330-ba60-aca05f588081.png",
														"url": "https://staging-media.toyota.com.ar/fa524d7a-07db-4330-ba60-aca05f588081.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-HB-XS-040",
														"path": "44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"url": "https://staging-media.toyota.com.ar/44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-HB-XS-040",
														"path": "1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"url": "https://staging-media.toyota.com.ar/1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 107350,
										"offers": null,
										"color": {
											"name": "Azul Titã (8V5) ",
											"code": "8V5",
											"rgb": "#3D4954"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large Yaris HB",
														"path": "5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"url": "https://staging-media.toyota.com.ar/5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner Yaris HB",
														"path": "ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 5",
														"path": "5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"url": "https://staging-media.toyota.com.ar/5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 4",
														"path": "3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"url": "https://staging-media.toyota.com.ar/3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 3",
														"path": "ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 2",
														"path": "712be374-0178-490e-ba57-f8ad627ff843.png",
														"url": "https://staging-media.toyota.com.ar/712be374-0178-490e-ba57-f8ad627ff843.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 1",
														"path": "8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 1",
														"path": "863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"url": "https://staging-media.toyota.com.ar/863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 7",
														"path": "a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"url": "https://staging-media.toyota.com.ar/a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 3",
														"path": "3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"url": "https://staging-media.toyota.com.ar/3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 2",
														"path": "e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"url": "https://staging-media.toyota.com.ar/e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 5",
														"path": "f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"url": "https://staging-media.toyota.com.ar/f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 4",
														"path": "abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"url": "https://staging-media.toyota.com.ar/abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 6",
														"path": "1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"url": "https://staging-media.toyota.com.ar/1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"url": "https://staging-media.toyota.com.ar/d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile",
														"path": "19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"url": "https://staging-media.toyota.com.ar/19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"url": "https://staging-media.toyota.com.ar/4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"url": "https://staging-media.toyota.com.ar/59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"url": "https://staging-media.toyota.com.ar/e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"url": "https://staging-media.toyota.com.ar/e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"url": "https://staging-media.toyota.com.ar/74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"url": "https://staging-media.toyota.com.ar/96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"url": "https://staging-media.toyota.com.ar/720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"url": "https://staging-media.toyota.com.ar/91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"url": "https://staging-media.toyota.com.ar/03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"url": "https://staging-media.toyota.com.ar/c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"url": "https://staging-media.toyota.com.ar/1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"url": "https://staging-media.toyota.com.ar/0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "fa524d7a-07db-4330-ba60-aca05f588081.png",
														"url": "https://staging-media.toyota.com.ar/fa524d7a-07db-4330-ba60-aca05f588081.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-HB-XS-040",
														"path": "44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"url": "https://staging-media.toyota.com.ar/44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-HB-XS-040",
														"path": "1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"url": "https://staging-media.toyota.com.ar/1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 107650,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large Yaris HB",
														"path": "5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"url": "https://staging-media.toyota.com.ar/5f4c7d93-9a22-46fb-b9b9-1a6535baa157.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner Yaris HB",
														"path": "ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec86f0ca-7420-455f-851f-586eedc4fa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 5",
														"path": "5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"url": "https://staging-media.toyota.com.ar/5e4edc55-9fdd-4e7f-9dd7-787795a3e6b4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 4",
														"path": "3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"url": "https://staging-media.toyota.com.ar/3c712714-35c2-4fd7-bd44-e2c72a50be72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 3",
														"path": "ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"url": "https://staging-media.toyota.com.ar/ec401fcc-1c4a-43a7-a91f-fc5f98c8cc9b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 2",
														"path": "712be374-0178-490e-ba57-f8ad627ff843.png",
														"url": "https://staging-media.toyota.com.ar/712be374-0178-490e-ba57-f8ad627ff843.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris interna 1",
														"path": "8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"url": "https://staging-media.toyota.com.ar/8d54775c-b713-44e9-b2d9-0423233f385c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 1",
														"path": "863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"url": "https://staging-media.toyota.com.ar/863462ac-8068-4077-8a63-d1a311d24ea4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 7",
														"path": "a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"url": "https://staging-media.toyota.com.ar/a71ad69a-3ff8-4372-9137-acbfbe1bce8b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 3",
														"path": "3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"url": "https://staging-media.toyota.com.ar/3fee929c-04f6-489a-b22f-492b71c57ca9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 2",
														"path": "e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"url": "https://staging-media.toyota.com.ar/e4807e6e-8bf0-4077-aa91-156e2429cac7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 5",
														"path": "f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"url": "https://staging-media.toyota.com.ar/f85ee3e3-c2b4-4035-8216-c130fbe3f2bb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 4",
														"path": "abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"url": "https://staging-media.toyota.com.ar/abd268a7-092f-4723-92cf-ffc092d5be96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 6",
														"path": "1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"url": "https://staging-media.toyota.com.ar/1065d2da-e584-4fea-9617-7f90acf5e7ca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"url": "https://staging-media.toyota.com.ar/d6f19699-bdcf-4b55-ad04-7907282f4c0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile",
														"path": "19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"url": "https://staging-media.toyota.com.ar/19c5e81f-0a6f-4fa6-9f9a-682876cc436d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"url": "https://staging-media.toyota.com.ar/4f6019f5-4a37-4b10-99ac-5b78dd296e33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"url": "https://staging-media.toyota.com.ar/59ea23f7-14bf-4846-8a2d-bab1f3e49093.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"url": "https://staging-media.toyota.com.ar/e424a2c7-27c8-4605-8e17-1a769d2c7a96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"url": "https://staging-media.toyota.com.ar/e80a894c-9766-4534-b993-6f2f50e37c93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"url": "https://staging-media.toyota.com.ar/74de629e-b7a8-449c-8aac-7c949a48b5c2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"url": "https://staging-media.toyota.com.ar/96f3d916-d984-4d74-b068-39a9013f8afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"url": "https://staging-media.toyota.com.ar/720e617f-2751-4fe6-8c64-824a5439eb41.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"url": "https://staging-media.toyota.com.ar/91c6d32e-8e31-4609-9023-4a648c85fddd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"url": "https://staging-media.toyota.com.ar/03b07e70-ea2b-4aa9-a440-1b9ed757711d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"url": "https://staging-media.toyota.com.ar/c07d97c8-36af-4dd2-a855-5dcc4da214ef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"url": "https://staging-media.toyota.com.ar/1e52544e-58ea-4059-b5d8-cc2f04852308.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"url": "https://staging-media.toyota.com.ar/0ac5a750-dd53-4adf-a23e-abeb812053b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "fa524d7a-07db-4330-ba60-aca05f588081.png",
														"url": "https://staging-media.toyota.com.ar/fa524d7a-07db-4330-ba60-aca05f588081.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-HB-XS-040",
														"path": "44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"url": "https://staging-media.toyota.com.ar/44ad8dbe-fcf8-4f5f-9241-88eb91e2fd90.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-HB-XS-040",
														"path": "1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"url": "https://staging-media.toyota.com.ar/1262bf1c-a4e0-4d79-b7d7-154e6d2e2141.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 110290,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"url": "https://staging-media.toyota.com.ar/eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"url": "https://staging-media.toyota.com.ar/71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"url": "https://staging-media.toyota.com.ar/193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"url": "https://staging-media.toyota.com.ar/17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"url": "https://staging-media.toyota.com.ar/3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"url": "https://staging-media.toyota.com.ar/bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"url": "https://staging-media.toyota.com.ar/92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"url": "https://staging-media.toyota.com.ar/c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"url": "https://staging-media.toyota.com.ar/900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"url": "https://staging-media.toyota.com.ar/9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "8c62754c-4bc9-467e-b9af-83128590727f.png",
														"url": "https://staging-media.toyota.com.ar/8c62754c-4bc9-467e-b9af-83128590727f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"url": "https://staging-media.toyota.com.ar/3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"url": "https://staging-media.toyota.com.ar/9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"url": "https://staging-media.toyota.com.ar/4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"url": "https://staging-media.toyota.com.br/9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"url": "https://staging-media.toyota.com.br/7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 111850,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"url": "https://staging-media.toyota.com.ar/eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"url": "https://staging-media.toyota.com.ar/71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"url": "https://staging-media.toyota.com.ar/193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"url": "https://staging-media.toyota.com.ar/17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"url": "https://staging-media.toyota.com.ar/3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"url": "https://staging-media.toyota.com.ar/bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"url": "https://staging-media.toyota.com.ar/92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"url": "https://staging-media.toyota.com.ar/c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"url": "https://staging-media.toyota.com.ar/900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"url": "https://staging-media.toyota.com.ar/9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "8c62754c-4bc9-467e-b9af-83128590727f.png",
														"url": "https://staging-media.toyota.com.ar/8c62754c-4bc9-467e-b9af-83128590727f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"url": "https://staging-media.toyota.com.ar/3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"url": "https://staging-media.toyota.com.ar/9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"url": "https://staging-media.toyota.com.ar/4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"url": "https://staging-media.toyota.com.br/9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"url": "https://staging-media.toyota.com.br/7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 111850,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"url": "https://staging-media.toyota.com.ar/eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"url": "https://staging-media.toyota.com.ar/71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"url": "https://staging-media.toyota.com.ar/193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"url": "https://staging-media.toyota.com.ar/17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"url": "https://staging-media.toyota.com.ar/3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"url": "https://staging-media.toyota.com.ar/bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"url": "https://staging-media.toyota.com.ar/92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"url": "https://staging-media.toyota.com.ar/c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"url": "https://staging-media.toyota.com.ar/900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"url": "https://staging-media.toyota.com.ar/9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "8c62754c-4bc9-467e-b9af-83128590727f.png",
														"url": "https://staging-media.toyota.com.ar/8c62754c-4bc9-467e-b9af-83128590727f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"url": "https://staging-media.toyota.com.ar/3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"url": "https://staging-media.toyota.com.ar/9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"url": "https://staging-media.toyota.com.ar/4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"url": "https://staging-media.toyota.com.br/9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"url": "https://staging-media.toyota.com.br/7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 111850,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"url": "https://staging-media.toyota.com.ar/eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"url": "https://staging-media.toyota.com.ar/71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"url": "https://staging-media.toyota.com.ar/193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"url": "https://staging-media.toyota.com.ar/17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"url": "https://staging-media.toyota.com.ar/3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"url": "https://staging-media.toyota.com.ar/bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"url": "https://staging-media.toyota.com.ar/92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"url": "https://staging-media.toyota.com.ar/c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"url": "https://staging-media.toyota.com.ar/900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"url": "https://staging-media.toyota.com.ar/9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "8c62754c-4bc9-467e-b9af-83128590727f.png",
														"url": "https://staging-media.toyota.com.ar/8c62754c-4bc9-467e-b9af-83128590727f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"url": "https://staging-media.toyota.com.ar/3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"url": "https://staging-media.toyota.com.ar/9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"url": "https://staging-media.toyota.com.ar/4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"url": "https://staging-media.toyota.com.br/9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"url": "https://staging-media.toyota.com.br/7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 111850,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"url": "https://staging-media.toyota.com.ar/eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"url": "https://staging-media.toyota.com.ar/71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"url": "https://staging-media.toyota.com.ar/193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"url": "https://staging-media.toyota.com.ar/17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"url": "https://staging-media.toyota.com.ar/3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"url": "https://staging-media.toyota.com.ar/bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"url": "https://staging-media.toyota.com.ar/92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"url": "https://staging-media.toyota.com.ar/c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"url": "https://staging-media.toyota.com.ar/900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"url": "https://staging-media.toyota.com.ar/9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "8c62754c-4bc9-467e-b9af-83128590727f.png",
														"url": "https://staging-media.toyota.com.ar/8c62754c-4bc9-467e-b9af-83128590727f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"url": "https://staging-media.toyota.com.ar/3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"url": "https://staging-media.toyota.com.ar/9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"url": "https://staging-media.toyota.com.ar/4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"url": "https://staging-media.toyota.com.br/9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"url": "https://staging-media.toyota.com.br/7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 111850,
										"offers": null,
										"color": {
											"name": "Azul Titã (8V5) ",
											"code": "8V5",
											"rgb": "#3D4954"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"url": "https://staging-media.toyota.com.ar/eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"url": "https://staging-media.toyota.com.ar/71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"url": "https://staging-media.toyota.com.ar/193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"url": "https://staging-media.toyota.com.ar/17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"url": "https://staging-media.toyota.com.ar/3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"url": "https://staging-media.toyota.com.ar/bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"url": "https://staging-media.toyota.com.ar/92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"url": "https://staging-media.toyota.com.ar/c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"url": "https://staging-media.toyota.com.ar/900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"url": "https://staging-media.toyota.com.ar/9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "8c62754c-4bc9-467e-b9af-83128590727f.png",
														"url": "https://staging-media.toyota.com.ar/8c62754c-4bc9-467e-b9af-83128590727f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"url": "https://staging-media.toyota.com.ar/3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"url": "https://staging-media.toyota.com.ar/9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"url": "https://staging-media.toyota.com.ar/4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"url": "https://staging-media.toyota.com.br/9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"url": "https://staging-media.toyota.com.br/7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 112150,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "BANNER DESK",
														"path": "5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"url": "https://staging-media.toyota.com.ar/5498a2a4-b8cb-4ba5-a3d3-c2c6e53e89e7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "BANNER MOBILE",
														"path": "468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"url": "https://staging-media.toyota.com.ar/468a2fd7-2680-4074-a115-e9c558c3c468.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"url": "https://staging-media.toyota.com.ar/eec12ea5-2783-4f53-bee8-6821c9fadc6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"url": "https://staging-media.toyota.com.ar/71c7aaa5-26e4-4458-8fe1-8f6e0841d700.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"url": "https://staging-media.toyota.com.ar/193f9e53-7184-4813-a1d8-6208e2e053fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"url": "https://staging-media.toyota.com.ar/17ea05fe-bc9b-4adb-a284-80e95eedf0aa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"url": "https://staging-media.toyota.com.ar/3ed2f8b1-1631-4d02-a067-cfac0b8c54bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"url": "https://staging-media.toyota.com.ar/bcc97297-7656-4e22-8a29-b9292d43ecf4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"url": "https://staging-media.toyota.com.ar/92cec96b-95cd-4b71-a706-ae3843ab2360.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"url": "https://staging-media.toyota.com.ar/c3d70198-6f65-464b-9511-3a029ae087c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"url": "https://staging-media.toyota.com.ar/900aff26-e20d-4198-9e59-7da63d6f6e97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"url": "https://staging-media.toyota.com.ar/9072ac0e-d3a0-4b50-b68d-ae7ae94b3cfd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "8c62754c-4bc9-467e-b9af-83128590727f.png",
														"url": "https://staging-media.toyota.com.ar/8c62754c-4bc9-467e-b9af-83128590727f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"url": "https://staging-media.toyota.com.ar/3efe296c-317a-4e72-bd84-f441685c6ff7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"url": "https://staging-media.toyota.com.ar/9798b1be-f0e9-4d8b-9aa8-df4e6126a06b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"url": "https://staging-media.toyota.com.ar/4d7c49db-9d94-4e2a-9f1f-71f5cfe84905.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"url": "https://staging-media.toyota.com.br/9f411860-5ceb-4b63-99ce-b1c6aa597cf8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"url": "https://staging-media.toyota.com.br/7b8fd39f-0dec-4c10-bcde-6593b308f509.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "XLS",
						"id": "bbc2b3c5-df8f-4b4b-94a7-7ff51088540e",
						"order": 3,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 117490,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"url": "https://staging-media.toyota.com.ar/5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"url": "https://staging-media.toyota.com.ar/2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"url": "https://staging-media.toyota.com.ar/de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "459feec6-1530-4371-84e5-39b20df952d8.png",
														"url": "https://staging-media.toyota.com.ar/459feec6-1530-4371-84e5-39b20df952d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"url": "https://staging-media.toyota.com.ar/7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"url": "https://staging-media.toyota.com.ar/dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"url": "https://staging-media.toyota.com.ar/1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile",
														"path": "747bbf48-0be5-4212-9857-c465f6220362.png",
														"url": "https://staging-media.toyota.com.ar/747bbf48-0be5-4212-9857-c465f6220362.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"url": "https://staging-media.toyota.com.ar/1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"url": "https://staging-media.toyota.com.ar/fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"url": "https://staging-media.toyota.com.ar/ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"url": "https://staging-media.toyota.com.ar/aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"url": "https://staging-media.toyota.com.ar/a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile",
														"path": "8341113c-95b5-4236-80aa-932d09067568.png",
														"url": "https://staging-media.toyota.com.ar/8341113c-95b5-4236-80aa-932d09067568.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"url": "https://staging-media.toyota.com.ar/e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"url": "https://staging-media.toyota.com.ar/4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"url": "https://staging-media.toyota.com.ar/3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"url": "https://staging-media.toyota.com.ar/9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"url": "https://staging-media.toyota.com.ar/23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"url": "https://staging-media.toyota.com.ar/293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"url": "https://staging-media.toyota.com.ar/0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"url": "https://staging-media.toyota.com.ar/be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"url": "https://staging-media.toyota.com.ar/cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"url": "https://staging-media.toyota.com.ar/f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"url": "https://staging-media.toyota.com.ar/fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"url": "https://staging-media.toyota.com.ar/e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "34552806-695c-4b20-aac2-f5062ab0677b.png",
														"url": "https://staging-media.toyota.com.ar/34552806-695c-4b20-aac2-f5062ab0677b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"url": "https://staging-media.toyota.com.ar/92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Desktop",
														"path": "18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"url": "https://staging-media.toyota.com.ar/18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Mobile",
														"path": "74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"url": "https://staging-media.toyota.com.ar/74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 119050,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"url": "https://staging-media.toyota.com.ar/5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"url": "https://staging-media.toyota.com.ar/2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"url": "https://staging-media.toyota.com.ar/de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "459feec6-1530-4371-84e5-39b20df952d8.png",
														"url": "https://staging-media.toyota.com.ar/459feec6-1530-4371-84e5-39b20df952d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"url": "https://staging-media.toyota.com.ar/7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"url": "https://staging-media.toyota.com.ar/dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"url": "https://staging-media.toyota.com.ar/1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile",
														"path": "747bbf48-0be5-4212-9857-c465f6220362.png",
														"url": "https://staging-media.toyota.com.ar/747bbf48-0be5-4212-9857-c465f6220362.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"url": "https://staging-media.toyota.com.ar/1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"url": "https://staging-media.toyota.com.ar/fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"url": "https://staging-media.toyota.com.ar/ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"url": "https://staging-media.toyota.com.ar/aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"url": "https://staging-media.toyota.com.ar/a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile",
														"path": "8341113c-95b5-4236-80aa-932d09067568.png",
														"url": "https://staging-media.toyota.com.ar/8341113c-95b5-4236-80aa-932d09067568.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"url": "https://staging-media.toyota.com.ar/e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"url": "https://staging-media.toyota.com.ar/4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"url": "https://staging-media.toyota.com.ar/3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"url": "https://staging-media.toyota.com.ar/9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"url": "https://staging-media.toyota.com.ar/23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"url": "https://staging-media.toyota.com.ar/293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"url": "https://staging-media.toyota.com.ar/0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"url": "https://staging-media.toyota.com.ar/be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"url": "https://staging-media.toyota.com.ar/cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"url": "https://staging-media.toyota.com.ar/f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"url": "https://staging-media.toyota.com.ar/fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"url": "https://staging-media.toyota.com.ar/e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "34552806-695c-4b20-aac2-f5062ab0677b.png",
														"url": "https://staging-media.toyota.com.ar/34552806-695c-4b20-aac2-f5062ab0677b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"url": "https://staging-media.toyota.com.ar/92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Desktop",
														"path": "18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"url": "https://staging-media.toyota.com.ar/18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Mobile",
														"path": "74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"url": "https://staging-media.toyota.com.ar/74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 119050,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"url": "https://staging-media.toyota.com.ar/5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"url": "https://staging-media.toyota.com.ar/2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"url": "https://staging-media.toyota.com.ar/de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "459feec6-1530-4371-84e5-39b20df952d8.png",
														"url": "https://staging-media.toyota.com.ar/459feec6-1530-4371-84e5-39b20df952d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"url": "https://staging-media.toyota.com.ar/7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"url": "https://staging-media.toyota.com.ar/dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"url": "https://staging-media.toyota.com.ar/1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile",
														"path": "747bbf48-0be5-4212-9857-c465f6220362.png",
														"url": "https://staging-media.toyota.com.ar/747bbf48-0be5-4212-9857-c465f6220362.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"url": "https://staging-media.toyota.com.ar/1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"url": "https://staging-media.toyota.com.ar/fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"url": "https://staging-media.toyota.com.ar/ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"url": "https://staging-media.toyota.com.ar/aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"url": "https://staging-media.toyota.com.ar/a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile",
														"path": "8341113c-95b5-4236-80aa-932d09067568.png",
														"url": "https://staging-media.toyota.com.ar/8341113c-95b5-4236-80aa-932d09067568.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"url": "https://staging-media.toyota.com.ar/e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"url": "https://staging-media.toyota.com.ar/4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"url": "https://staging-media.toyota.com.ar/3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"url": "https://staging-media.toyota.com.ar/9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"url": "https://staging-media.toyota.com.ar/23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"url": "https://staging-media.toyota.com.ar/293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"url": "https://staging-media.toyota.com.ar/0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"url": "https://staging-media.toyota.com.ar/be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"url": "https://staging-media.toyota.com.ar/cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"url": "https://staging-media.toyota.com.ar/f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"url": "https://staging-media.toyota.com.ar/fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"url": "https://staging-media.toyota.com.ar/e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "34552806-695c-4b20-aac2-f5062ab0677b.png",
														"url": "https://staging-media.toyota.com.ar/34552806-695c-4b20-aac2-f5062ab0677b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"url": "https://staging-media.toyota.com.ar/92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Desktop",
														"path": "18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"url": "https://staging-media.toyota.com.ar/18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Mobile",
														"path": "74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"url": "https://staging-media.toyota.com.ar/74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 119050,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"url": "https://staging-media.toyota.com.ar/5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"url": "https://staging-media.toyota.com.ar/2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"url": "https://staging-media.toyota.com.ar/de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "459feec6-1530-4371-84e5-39b20df952d8.png",
														"url": "https://staging-media.toyota.com.ar/459feec6-1530-4371-84e5-39b20df952d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"url": "https://staging-media.toyota.com.ar/7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"url": "https://staging-media.toyota.com.ar/dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"url": "https://staging-media.toyota.com.ar/1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile",
														"path": "747bbf48-0be5-4212-9857-c465f6220362.png",
														"url": "https://staging-media.toyota.com.ar/747bbf48-0be5-4212-9857-c465f6220362.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"url": "https://staging-media.toyota.com.ar/1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"url": "https://staging-media.toyota.com.ar/fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"url": "https://staging-media.toyota.com.ar/ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"url": "https://staging-media.toyota.com.ar/aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"url": "https://staging-media.toyota.com.ar/a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile",
														"path": "8341113c-95b5-4236-80aa-932d09067568.png",
														"url": "https://staging-media.toyota.com.ar/8341113c-95b5-4236-80aa-932d09067568.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"url": "https://staging-media.toyota.com.ar/e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"url": "https://staging-media.toyota.com.ar/4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"url": "https://staging-media.toyota.com.ar/3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"url": "https://staging-media.toyota.com.ar/9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"url": "https://staging-media.toyota.com.ar/23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"url": "https://staging-media.toyota.com.ar/293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"url": "https://staging-media.toyota.com.ar/0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"url": "https://staging-media.toyota.com.ar/be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"url": "https://staging-media.toyota.com.ar/cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"url": "https://staging-media.toyota.com.ar/f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"url": "https://staging-media.toyota.com.ar/fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"url": "https://staging-media.toyota.com.ar/e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "34552806-695c-4b20-aac2-f5062ab0677b.png",
														"url": "https://staging-media.toyota.com.ar/34552806-695c-4b20-aac2-f5062ab0677b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"url": "https://staging-media.toyota.com.ar/92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Desktop",
														"path": "18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"url": "https://staging-media.toyota.com.ar/18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Mobile",
														"path": "74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"url": "https://staging-media.toyota.com.ar/74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 119050,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"url": "https://staging-media.toyota.com.ar/5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"url": "https://staging-media.toyota.com.ar/2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"url": "https://staging-media.toyota.com.ar/de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "459feec6-1530-4371-84e5-39b20df952d8.png",
														"url": "https://staging-media.toyota.com.ar/459feec6-1530-4371-84e5-39b20df952d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"url": "https://staging-media.toyota.com.ar/7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"url": "https://staging-media.toyota.com.ar/dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"url": "https://staging-media.toyota.com.ar/1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile",
														"path": "747bbf48-0be5-4212-9857-c465f6220362.png",
														"url": "https://staging-media.toyota.com.ar/747bbf48-0be5-4212-9857-c465f6220362.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"url": "https://staging-media.toyota.com.ar/1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"url": "https://staging-media.toyota.com.ar/fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"url": "https://staging-media.toyota.com.ar/ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"url": "https://staging-media.toyota.com.ar/aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"url": "https://staging-media.toyota.com.ar/a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile",
														"path": "8341113c-95b5-4236-80aa-932d09067568.png",
														"url": "https://staging-media.toyota.com.ar/8341113c-95b5-4236-80aa-932d09067568.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"url": "https://staging-media.toyota.com.ar/e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"url": "https://staging-media.toyota.com.ar/4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"url": "https://staging-media.toyota.com.ar/3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"url": "https://staging-media.toyota.com.ar/9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"url": "https://staging-media.toyota.com.ar/23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"url": "https://staging-media.toyota.com.ar/293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"url": "https://staging-media.toyota.com.ar/0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"url": "https://staging-media.toyota.com.ar/be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"url": "https://staging-media.toyota.com.ar/cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"url": "https://staging-media.toyota.com.ar/f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"url": "https://staging-media.toyota.com.ar/fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"url": "https://staging-media.toyota.com.ar/e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "34552806-695c-4b20-aac2-f5062ab0677b.png",
														"url": "https://staging-media.toyota.com.ar/34552806-695c-4b20-aac2-f5062ab0677b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"url": "https://staging-media.toyota.com.ar/92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Desktop",
														"path": "18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"url": "https://staging-media.toyota.com.ar/18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Mobile",
														"path": "74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"url": "https://staging-media.toyota.com.ar/74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 119050,
										"offers": null,
										"color": {
											"name": "Azul Titã (8V5) ",
											"code": "8V5",
											"rgb": "#3D4954"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"url": "https://staging-media.toyota.com.ar/5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"url": "https://staging-media.toyota.com.ar/2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"url": "https://staging-media.toyota.com.ar/de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "459feec6-1530-4371-84e5-39b20df952d8.png",
														"url": "https://staging-media.toyota.com.ar/459feec6-1530-4371-84e5-39b20df952d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"url": "https://staging-media.toyota.com.ar/7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"url": "https://staging-media.toyota.com.ar/dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"url": "https://staging-media.toyota.com.ar/1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile",
														"path": "747bbf48-0be5-4212-9857-c465f6220362.png",
														"url": "https://staging-media.toyota.com.ar/747bbf48-0be5-4212-9857-c465f6220362.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"url": "https://staging-media.toyota.com.ar/1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"url": "https://staging-media.toyota.com.ar/fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"url": "https://staging-media.toyota.com.ar/ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"url": "https://staging-media.toyota.com.ar/aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"url": "https://staging-media.toyota.com.ar/a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile",
														"path": "8341113c-95b5-4236-80aa-932d09067568.png",
														"url": "https://staging-media.toyota.com.ar/8341113c-95b5-4236-80aa-932d09067568.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"url": "https://staging-media.toyota.com.ar/e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"url": "https://staging-media.toyota.com.ar/4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"url": "https://staging-media.toyota.com.ar/3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"url": "https://staging-media.toyota.com.ar/9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"url": "https://staging-media.toyota.com.ar/23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"url": "https://staging-media.toyota.com.ar/293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"url": "https://staging-media.toyota.com.ar/0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"url": "https://staging-media.toyota.com.ar/be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"url": "https://staging-media.toyota.com.ar/cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"url": "https://staging-media.toyota.com.ar/f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"url": "https://staging-media.toyota.com.ar/fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"url": "https://staging-media.toyota.com.ar/e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "34552806-695c-4b20-aac2-f5062ab0677b.png",
														"url": "https://staging-media.toyota.com.ar/34552806-695c-4b20-aac2-f5062ab0677b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"url": "https://staging-media.toyota.com.ar/92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Desktop",
														"path": "18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"url": "https://staging-media.toyota.com.ar/18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Mobile",
														"path": "74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"url": "https://staging-media.toyota.com.ar/74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 117550,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "sprite",
														"path": "5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"url": "https://staging-media.toyota.com.ar/5c598fd2-ffc1-420d-83e0-95a73fe088f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"url": "https://staging-media.toyota.com.ar/2bf78ff7-921b-4305-a464-44f36ed58f3b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "sprite",
														"path": "de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"url": "https://staging-media.toyota.com.ar/de0d81a0-2be4-4db6-a188-d8fb8d67ce8d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "459feec6-1530-4371-84e5-39b20df952d8.png",
														"url": "https://staging-media.toyota.com.ar/459feec6-1530-4371-84e5-39b20df952d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "sprite",
														"path": "7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"url": "https://staging-media.toyota.com.ar/7a1907c3-ef5e-49d2-8fd4-4ba0ecb01bb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"url": "https://staging-media.toyota.com.ar/dfa3b2dc-90d5-4985-817d-368b4c189ebf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "sprite",
														"path": "1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"url": "https://staging-media.toyota.com.ar/1aab7275-db05-425b-8793-2a8f1fa9e055.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "sprite-mobile",
														"path": "747bbf48-0be5-4212-9857-c465f6220362.png",
														"url": "https://staging-media.toyota.com.ar/747bbf48-0be5-4212-9857-c465f6220362.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "sprite",
														"path": "1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"url": "https://staging-media.toyota.com.ar/1ca904b9-c48d-4377-a3de-437435bb0cd4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"url": "https://staging-media.toyota.com.ar/fe8b42e4-e69a-4168-8f75-a9e8cad0ab59.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "sprite",
														"path": "ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"url": "https://staging-media.toyota.com.ar/ab1db88e-ea59-484c-a7db-38515bc6c65d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "sprite-mobile (1)",
														"path": "aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"url": "https://staging-media.toyota.com.ar/aa76fbdd-fb24-49ea-8e7f-76bf63779838.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "sprite",
														"path": "a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"url": "https://staging-media.toyota.com.ar/a310702d-df9b-46e1-b939-7a3c2c04278c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "sprite-mobile",
														"path": "8341113c-95b5-4236-80aa-932d09067568.png",
														"url": "https://staging-media.toyota.com.ar/8341113c-95b5-4236-80aa-932d09067568.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"url": "https://staging-media.toyota.com.ar/e047c912-62cf-4e82-8fc2-f63cb864b96c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"url": "https://staging-media.toyota.com.ar/4517ca6a-eae2-4aaf-935d-81ab7f772720.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"url": "https://staging-media.toyota.com.ar/3e5caee3-d7f0-4763-9815-f830ae885282.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"url": "https://staging-media.toyota.com.ar/9e843e04-81cb-4ef4-879d-f68e1369af38.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"url": "https://staging-media.toyota.com.ar/23d09744-8e10-4bea-99f7-0ec242126d6e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"url": "https://staging-media.toyota.com.ar/293b3173-517d-4954-9e68-7c70bd89e442.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"url": "https://staging-media.toyota.com.ar/0a43e7dd-f8ea-47d8-b441-9a8173b0469c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"url": "https://staging-media.toyota.com.ar/be66dd99-0bae-4b97-872a-5f68dade5dbe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"url": "https://staging-media.toyota.com.ar/cb1caa7e-19dc-41fb-a4c0-0c3d1bf956d5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"url": "https://staging-media.toyota.com.ar/f544fc44-f8eb-491f-822f-4269910d554c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"url": "https://staging-media.toyota.com.ar/fac4abbf-2fe7-4f3c-a06e-0a084a2de7a7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9d65b59-22d6-436e-8b64-5c65e4f34ad9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4407247-d1bb-4e59-9c34-466e6fb44f31.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"url": "https://staging-media.toyota.com.ar/e939198a-31ad-4f72-ba85-43a5c5570ff7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "Yaris externa 8",
														"path": "6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"url": "https://staging-media.toyota.com.ar/6ddf2a05-6962-4dca-8899-0d36ebf93661.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "34552806-695c-4b20-aac2-f5062ab0677b.png",
														"url": "https://staging-media.toyota.com.ar/34552806-695c-4b20-aac2-f5062ab0677b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"url": "https://staging-media.toyota.com.ar/92b69803-4690-42bb-aca7-de7caab79fe2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Desktop",
														"path": "18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"url": "https://staging-media.toyota.com.ar/18de7684-6714-4d70-8cd2-40bae29fec88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner_Yaris_HB_TDB_Mobile",
														"path": "74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"url": "https://staging-media.toyota.com.ar/74d724a5-e8a1-4a4a-83a8-689ff4ce06bd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 122090,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"url": "https://staging-media.toyota.com.ar/4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"url": "https://staging-media.toyota.com.ar/bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"url": "https://staging-media.toyota.com.ar/299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"url": "https://staging-media.toyota.com.ar/5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"url": "https://staging-media.toyota.com.ar/b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"url": "https://staging-media.toyota.com.ar/c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"url": "https://staging-media.toyota.com.ar/dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"url": "https://staging-media.toyota.com.ar/cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"url": "https://staging-media.toyota.com.ar/196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"url": "https://staging-media.toyota.com.ar/367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"url": "https://staging-media.toyota.com.ar/7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "be63e940-9c01-416f-ae9d-df708adbc775.png",
														"url": "https://staging-media.toyota.com.ar/be63e940-9c01-416f-ae9d-df708adbc775.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"url": "https://staging-media.toyota.com.ar/d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"url": "https://staging-media.toyota.com.ar/1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"url": "https://staging-media.toyota.com.ar/1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:8V5",
													"media": {
														"title": "02",
														"path": "3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"url": "https://staging-media.toyota.com.br/3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"url": "https://staging-media.toyota.com.br/30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"url": "https://staging-media.toyota.com.br/9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"url": "https://staging-media.toyota.com.br/db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"url": "https://staging-media.toyota.com.br/95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"url": "https://staging-media.toyota.com.br/7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"url": "https://staging-media.toyota.com.br/142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"url": "https://staging-media.toyota.com.br/93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"url": "https://staging-media.toyota.com.br/8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 123650,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"url": "https://staging-media.toyota.com.ar/4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"url": "https://staging-media.toyota.com.ar/bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"url": "https://staging-media.toyota.com.ar/299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"url": "https://staging-media.toyota.com.ar/5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"url": "https://staging-media.toyota.com.ar/b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"url": "https://staging-media.toyota.com.ar/c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"url": "https://staging-media.toyota.com.ar/dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"url": "https://staging-media.toyota.com.ar/cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"url": "https://staging-media.toyota.com.ar/196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"url": "https://staging-media.toyota.com.ar/367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"url": "https://staging-media.toyota.com.ar/7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "be63e940-9c01-416f-ae9d-df708adbc775.png",
														"url": "https://staging-media.toyota.com.ar/be63e940-9c01-416f-ae9d-df708adbc775.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"url": "https://staging-media.toyota.com.ar/d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"url": "https://staging-media.toyota.com.ar/1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"url": "https://staging-media.toyota.com.ar/1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:8V5",
													"media": {
														"title": "02",
														"path": "3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"url": "https://staging-media.toyota.com.br/3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"url": "https://staging-media.toyota.com.br/30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"url": "https://staging-media.toyota.com.br/9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"url": "https://staging-media.toyota.com.br/db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"url": "https://staging-media.toyota.com.br/95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"url": "https://staging-media.toyota.com.br/7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"url": "https://staging-media.toyota.com.br/142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"url": "https://staging-media.toyota.com.br/93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"url": "https://staging-media.toyota.com.br/8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 123650,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"url": "https://staging-media.toyota.com.ar/4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"url": "https://staging-media.toyota.com.ar/bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"url": "https://staging-media.toyota.com.ar/299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"url": "https://staging-media.toyota.com.ar/5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"url": "https://staging-media.toyota.com.ar/b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"url": "https://staging-media.toyota.com.ar/c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"url": "https://staging-media.toyota.com.ar/dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"url": "https://staging-media.toyota.com.ar/cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"url": "https://staging-media.toyota.com.ar/196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"url": "https://staging-media.toyota.com.ar/367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"url": "https://staging-media.toyota.com.ar/7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "be63e940-9c01-416f-ae9d-df708adbc775.png",
														"url": "https://staging-media.toyota.com.ar/be63e940-9c01-416f-ae9d-df708adbc775.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"url": "https://staging-media.toyota.com.ar/d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"url": "https://staging-media.toyota.com.ar/1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"url": "https://staging-media.toyota.com.ar/1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:8V5",
													"media": {
														"title": "02",
														"path": "3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"url": "https://staging-media.toyota.com.br/3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"url": "https://staging-media.toyota.com.br/30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"url": "https://staging-media.toyota.com.br/9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"url": "https://staging-media.toyota.com.br/db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"url": "https://staging-media.toyota.com.br/95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"url": "https://staging-media.toyota.com.br/7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"url": "https://staging-media.toyota.com.br/142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"url": "https://staging-media.toyota.com.br/93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"url": "https://staging-media.toyota.com.br/8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 123650,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"url": "https://staging-media.toyota.com.ar/4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"url": "https://staging-media.toyota.com.ar/bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"url": "https://staging-media.toyota.com.ar/299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"url": "https://staging-media.toyota.com.ar/5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"url": "https://staging-media.toyota.com.ar/b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"url": "https://staging-media.toyota.com.ar/c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"url": "https://staging-media.toyota.com.ar/dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"url": "https://staging-media.toyota.com.ar/cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"url": "https://staging-media.toyota.com.ar/196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"url": "https://staging-media.toyota.com.ar/367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"url": "https://staging-media.toyota.com.ar/7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "be63e940-9c01-416f-ae9d-df708adbc775.png",
														"url": "https://staging-media.toyota.com.ar/be63e940-9c01-416f-ae9d-df708adbc775.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"url": "https://staging-media.toyota.com.ar/d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"url": "https://staging-media.toyota.com.ar/1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"url": "https://staging-media.toyota.com.ar/1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:8V5",
													"media": {
														"title": "02",
														"path": "3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"url": "https://staging-media.toyota.com.br/3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"url": "https://staging-media.toyota.com.br/30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"url": "https://staging-media.toyota.com.br/9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"url": "https://staging-media.toyota.com.br/db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"url": "https://staging-media.toyota.com.br/95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"url": "https://staging-media.toyota.com.br/7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"url": "https://staging-media.toyota.com.br/142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"url": "https://staging-media.toyota.com.br/93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"url": "https://staging-media.toyota.com.br/8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 123650,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"url": "https://staging-media.toyota.com.ar/4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"url": "https://staging-media.toyota.com.ar/bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"url": "https://staging-media.toyota.com.ar/299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"url": "https://staging-media.toyota.com.ar/5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"url": "https://staging-media.toyota.com.ar/b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"url": "https://staging-media.toyota.com.ar/c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"url": "https://staging-media.toyota.com.ar/dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"url": "https://staging-media.toyota.com.ar/cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"url": "https://staging-media.toyota.com.ar/196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"url": "https://staging-media.toyota.com.ar/367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"url": "https://staging-media.toyota.com.ar/7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "be63e940-9c01-416f-ae9d-df708adbc775.png",
														"url": "https://staging-media.toyota.com.ar/be63e940-9c01-416f-ae9d-df708adbc775.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"url": "https://staging-media.toyota.com.ar/d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"url": "https://staging-media.toyota.com.ar/1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"url": "https://staging-media.toyota.com.ar/1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:8V5",
													"media": {
														"title": "02",
														"path": "3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"url": "https://staging-media.toyota.com.br/3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"url": "https://staging-media.toyota.com.br/30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"url": "https://staging-media.toyota.com.br/9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"url": "https://staging-media.toyota.com.br/db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"url": "https://staging-media.toyota.com.br/95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"url": "https://staging-media.toyota.com.br/7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"url": "https://staging-media.toyota.com.br/142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"url": "https://staging-media.toyota.com.br/93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"url": "https://staging-media.toyota.com.br/8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 123650,
										"offers": null,
										"color": {
											"name": "Azul Titã (8V5) ",
											"code": "8V5",
											"rgb": "#3D4954"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"url": "https://staging-media.toyota.com.ar/4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"url": "https://staging-media.toyota.com.ar/bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"url": "https://staging-media.toyota.com.ar/299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"url": "https://staging-media.toyota.com.ar/5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"url": "https://staging-media.toyota.com.ar/b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"url": "https://staging-media.toyota.com.ar/c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"url": "https://staging-media.toyota.com.ar/dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"url": "https://staging-media.toyota.com.ar/cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"url": "https://staging-media.toyota.com.ar/196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"url": "https://staging-media.toyota.com.ar/367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"url": "https://staging-media.toyota.com.ar/7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "be63e940-9c01-416f-ae9d-df708adbc775.png",
														"url": "https://staging-media.toyota.com.ar/be63e940-9c01-416f-ae9d-df708adbc775.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"url": "https://staging-media.toyota.com.ar/d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"url": "https://staging-media.toyota.com.ar/1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"url": "https://staging-media.toyota.com.ar/1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:8V5",
													"media": {
														"title": "02",
														"path": "3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"url": "https://staging-media.toyota.com.br/3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"url": "https://staging-media.toyota.com.br/30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"url": "https://staging-media.toyota.com.br/9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"url": "https://staging-media.toyota.com.br/db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"url": "https://staging-media.toyota.com.br/95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"url": "https://staging-media.toyota.com.br/7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"url": "https://staging-media.toyota.com.br/142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"url": "https://staging-media.toyota.com.br/93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"url": "https://staging-media.toyota.com.br/8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 123950,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"url": "https://staging-media.toyota.com.ar/4a1cdc75-359f-429f-acc4-c962c6850eb4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"url": "https://staging-media.toyota.com.ar/bd2dd24e-f40f-400d-96dc-97673e9c7afa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"url": "https://staging-media.toyota.com.ar/b8cbb300-fadd-42cd-93bc-0993579326c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"url": "https://staging-media.toyota.com.ar/9ce171eb-a877-466a-bcef-eaf0b9ce9c9c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/10037dc9-90bf-4957-81d1-97cbf1e675c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"url": "https://staging-media.toyota.com.ar/270bde77-4462-47ef-8d49-6357f12b0e5a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"url": "https://staging-media.toyota.com.ar/ea78b0ba-66f2-4191-839e-bef0b5435902.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"url": "https://staging-media.toyota.com.ar/e8f65e30-0f67-442b-8d42-7503c93fe829.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"url": "https://staging-media.toyota.com.ar/9cfc7e81-6715-4475-bd25-a887ec5adbd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"url": "https://staging-media.toyota.com.ar/762fc214-ee35-4a39-bc86-f84b0c988795.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"url": "https://staging-media.toyota.com.ar/a9dcfa82-09c0-4951-9995-489f3a132345.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"url": "https://staging-media.toyota.com.ar/75859708-079c-4fe3-8fed-bdcb2546fa14.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"url": "https://staging-media.toyota.com.ar/6a287d3d-30a6-4e90-bce1-25a833c8f193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"url": "https://staging-media.toyota.com.ar/76a89abb-9390-4b15-8983-b03ca031c55d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "07c0729e-703c-427f-af78-180042a77edc.png",
														"url": "https://staging-media.toyota.com.ar/07c0729e-703c-427f-af78-180042a77edc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"url": "https://staging-media.toyota.com.ar/299ef1b3-f72e-4a0f-b85e-7b92b69c9a50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"url": "https://staging-media.toyota.com.ar/5cb1f3e6-5e98-4bf7-a5d6-57b97a3eba52.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"url": "https://staging-media.toyota.com.ar/b001d3e3-92cc-494c-abd6-60201c5451e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"url": "https://staging-media.toyota.com.ar/c930130b-1d20-436a-bcb4-b4b3ac7031a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"url": "https://staging-media.toyota.com.ar/dc71f730-9dcc-4c81-9a96-c7af4d28b2d1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"url": "https://staging-media.toyota.com.ar/cb3b896f-fa7f-406a-8d6f-5dd130a141f3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"url": "https://staging-media.toyota.com.ar/196ff0a1-9ec8-46b7-b331-e76f2df62c1f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"url": "https://staging-media.toyota.com.ar/367b1947-c20c-4f17-aa50-8f9924802a6e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3",
														"path": "7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"url": "https://staging-media.toyota.com.ar/7964ba6e-9b74-49f9-9b99-c6bf58fe3aaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "be63e940-9c01-416f-ae9d-df708adbc775.png",
														"url": "https://staging-media.toyota.com.ar/be63e940-9c01-416f-ae9d-df708adbc775.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"url": "https://staging-media.toyota.com.ar/d67f81d9-d1c3-4b29-b982-6cdba8022136.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"url": "https://staging-media.toyota.com.ar/1cb5a47b-3128-4b7f-abf6-a507a7194b15.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"url": "https://staging-media.toyota.com.ar/1048e2c3-c8e0-4fc5-ad9c-072373c07e10.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:8V5",
													"media": {
														"title": "02",
														"path": "3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"url": "https://staging-media.toyota.com.br/3f4b0254-cdcd-42bc-9ba5-e4147673ae38.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:8V5",
													"media": {
														"title": "01",
														"path": "c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"url": "https://staging-media.toyota.com.br/c15790b4-4a0f-43e4-a67b-031e7d89d2f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"url": "https://staging-media.toyota.com.br/30d89a5c-ee04-4b32-b421-5d51b90ae0e1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "01",
														"path": "4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"url": "https://staging-media.toyota.com.br/4496cbba-f159-4bd4-a736-5ea00bf85d6b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"url": "https://staging-media.toyota.com.br/9bd5dcab-48b7-4db6-9f0a-9b6efddfb48f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "01",
														"path": "f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"url": "https://staging-media.toyota.com.br/f93988c8-05cb-4b56-b0ea-30c4e5e827a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"url": "https://staging-media.toyota.com.br/48962664-625b-4a33-a2cc-4f8e8f1dc9b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"url": "https://staging-media.toyota.com.br/db737570-19d9-4c0b-acb9-98803e34d99e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"url": "https://staging-media.toyota.com.br/a8e73255-beb9-43a4-9396-f21255389a1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"url": "https://staging-media.toyota.com.br/95fa1eab-ae86-4e59-ab74-1f3068db93cf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"url": "https://staging-media.toyota.com.br/254fd042-7327-4abf-aa0a-e08e5c9758cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"url": "https://staging-media.toyota.com.br/7e9830b2-71e4-492f-9688-9d1e2f9799bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"url": "https://staging-media.toyota.com.br/73de76e6-f86e-4b7e-97e4-5ff360c41f5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"url": "https://staging-media.toyota.com.br/142f5f60-9671-4116-a869-9dcd1beb2745.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"url": "https://staging-media.toyota.com.br/93c9ca44-c4c6-473a-abb1-0f5c5f3eb8ee.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"url": "https://staging-media.toyota.com.br/8f5a0fb6-9406-488d-9424-d280b4f890f3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					}
				]
			},
			{
				"id": "29a7181a-6015-4fda-b0e2-172212318e14",
				"name": "Yaris Sedã",
				"vehicleExternalId": null,
				"status": "ENABLED",
				"category": {
					"name": "Carros de passeio",
					"id": "bf8c0d92-4175-4fb2-8ff1-d6cd46ff881b"
				},
				"versions": [
					{
						"name": "XL",
						"id": "6198b40f-022e-4e91-a611-4aca0d01caa7",
						"order": 1,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 97990,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "1",
														"path": "23ad0763-4242-46bb-b3b5-917a4108e0b7.mp4",
														"url": "https://staging-media.toyota.com.br/23ad0763-4242-46bb-b3b5-917a4108e0b7.mp4",
														"mediaType": {
															"title": "video"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99620,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "1",
														"path": "23ad0763-4242-46bb-b3b5-917a4108e0b7.mp4",
														"url": "https://staging-media.toyota.com.br/23ad0763-4242-46bb-b3b5-917a4108e0b7.mp4",
														"mediaType": {
															"title": "video"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99620,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "1",
														"path": "23ad0763-4242-46bb-b3b5-917a4108e0b7.mp4",
														"url": "https://staging-media.toyota.com.br/23ad0763-4242-46bb-b3b5-917a4108e0b7.mp4",
														"mediaType": {
															"title": "video"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99620,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "1",
														"path": "23ad0763-4242-46bb-b3b5-917a4108e0b7.mp4",
														"url": "https://staging-media.toyota.com.br/23ad0763-4242-46bb-b3b5-917a4108e0b7.mp4",
														"mediaType": {
															"title": "video"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99620,
										"offers": null,
										"color": {
											"name": "Azul Titã (8V5) ",
											"code": "8V5",
											"rgb": "#3D4954"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "1",
														"path": "23ad0763-4242-46bb-b3b5-917a4108e0b7.mp4",
														"url": "https://staging-media.toyota.com.br/23ad0763-4242-46bb-b3b5-917a4108e0b7.mp4",
														"mediaType": {
															"title": "video"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99920,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "1",
														"path": "23ad0763-4242-46bb-b3b5-917a4108e0b7.mp4",
														"url": "https://staging-media.toyota.com.br/23ad0763-4242-46bb-b3b5-917a4108e0b7.mp4",
														"mediaType": {
															"title": "video"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "2",
														"path": "7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"url": "https://staging-media.toyota.com.br/7b759b8a-bb98-483a-a8df-9c16249eec37.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 97990,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"url": "https://staging-media.toyota.com.ar/08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"url": "https://staging-media.toyota.com.ar/8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"url": "https://staging-media.toyota.com.ar/c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "279f410d-fd4f-4136-8300-a95939829d09.png",
														"url": "https://staging-media.toyota.com.ar/279f410d-fd4f-4136-8300-a95939829d09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"url": "https://staging-media.toyota.com.ar/ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"url": "https://staging-media.toyota.com.ar/dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "93bc8daf-ed44-413f-a6eb-5724dd5b6ec1.png",
														"url": "https://staging-media.toyota.com.ar/93bc8daf-ed44-413f-a6eb-5724dd5b6ec1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "83a778a3-0639-4985-a2a0-7c818bbd44b0.png",
														"url": "https://staging-media.toyota.com.ar/83a778a3-0639-4985-a2a0-7c818bbd44b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "aaf0c9ea-0eec-44eb-8e9b-5139800a2e1b.png",
														"url": "https://staging-media.toyota.com.ar/aaf0c9ea-0eec-44eb-8e9b-5139800a2e1b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "c9972dbd-3884-478a-9b91-f266247108c0.png",
														"url": "https://staging-media.toyota.com.ar/c9972dbd-3884-478a-9b91-f266247108c0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "35493519-bf06-4ccd-a155-45fe4af9b402.png",
														"url": "https://staging-media.toyota.com.ar/35493519-bf06-4ccd-a155-45fe4af9b402.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "a238b797-2bd1-4a87-81c2-758e635e9134.png",
														"url": "https://staging-media.toyota.com.ar/a238b797-2bd1-4a87-81c2-758e635e9134.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "32c9bcf9-5855-4f1c-b106-10e0f3c0da8f.png",
														"url": "https://staging-media.toyota.com.ar/32c9bcf9-5855-4f1c-b106-10e0f3c0da8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "81b88677-2d00-4ae9-8150-c826b9fe30cd.png",
														"url": "https://staging-media.toyota.com.ar/81b88677-2d00-4ae9-8150-c826b9fe30cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "a0a93f53-9026-411c-8dda-cb575a536efa.png",
														"url": "https://staging-media.toyota.com.ar/a0a93f53-9026-411c-8dda-cb575a536efa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "bb1b0567-5c8c-4d06-ad73-ca80d3413564.png",
														"url": "https://staging-media.toyota.com.ar/bb1b0567-5c8c-4d06-ad73-ca80d3413564.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "e5566f5c-351e-45ff-b8be-236842c13f4f.png",
														"url": "https://staging-media.toyota.com.ar/e5566f5c-351e-45ff-b8be-236842c13f4f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"url": "https://staging-media.toyota.com.ar/d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "2",
														"path": "6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"url": "https://staging-media.toyota.com.ar/6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "1",
														"path": "b05b2211-f4bc-4b4e-9c9f-fd111ec1c54d.png",
														"url": "https://staging-media.toyota.com.br/b05b2211-f4bc-4b4e-9c9f-fd111ec1c54d.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99620,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"url": "https://staging-media.toyota.com.ar/08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"url": "https://staging-media.toyota.com.ar/8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"url": "https://staging-media.toyota.com.ar/c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "279f410d-fd4f-4136-8300-a95939829d09.png",
														"url": "https://staging-media.toyota.com.ar/279f410d-fd4f-4136-8300-a95939829d09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"url": "https://staging-media.toyota.com.ar/ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"url": "https://staging-media.toyota.com.ar/dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "93bc8daf-ed44-413f-a6eb-5724dd5b6ec1.png",
														"url": "https://staging-media.toyota.com.ar/93bc8daf-ed44-413f-a6eb-5724dd5b6ec1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "83a778a3-0639-4985-a2a0-7c818bbd44b0.png",
														"url": "https://staging-media.toyota.com.ar/83a778a3-0639-4985-a2a0-7c818bbd44b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "aaf0c9ea-0eec-44eb-8e9b-5139800a2e1b.png",
														"url": "https://staging-media.toyota.com.ar/aaf0c9ea-0eec-44eb-8e9b-5139800a2e1b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "c9972dbd-3884-478a-9b91-f266247108c0.png",
														"url": "https://staging-media.toyota.com.ar/c9972dbd-3884-478a-9b91-f266247108c0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "35493519-bf06-4ccd-a155-45fe4af9b402.png",
														"url": "https://staging-media.toyota.com.ar/35493519-bf06-4ccd-a155-45fe4af9b402.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "a238b797-2bd1-4a87-81c2-758e635e9134.png",
														"url": "https://staging-media.toyota.com.ar/a238b797-2bd1-4a87-81c2-758e635e9134.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "32c9bcf9-5855-4f1c-b106-10e0f3c0da8f.png",
														"url": "https://staging-media.toyota.com.ar/32c9bcf9-5855-4f1c-b106-10e0f3c0da8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "81b88677-2d00-4ae9-8150-c826b9fe30cd.png",
														"url": "https://staging-media.toyota.com.ar/81b88677-2d00-4ae9-8150-c826b9fe30cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "a0a93f53-9026-411c-8dda-cb575a536efa.png",
														"url": "https://staging-media.toyota.com.ar/a0a93f53-9026-411c-8dda-cb575a536efa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "bb1b0567-5c8c-4d06-ad73-ca80d3413564.png",
														"url": "https://staging-media.toyota.com.ar/bb1b0567-5c8c-4d06-ad73-ca80d3413564.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "e5566f5c-351e-45ff-b8be-236842c13f4f.png",
														"url": "https://staging-media.toyota.com.ar/e5566f5c-351e-45ff-b8be-236842c13f4f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"url": "https://staging-media.toyota.com.ar/d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "2",
														"path": "6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"url": "https://staging-media.toyota.com.ar/6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "1",
														"path": "b05b2211-f4bc-4b4e-9c9f-fd111ec1c54d.png",
														"url": "https://staging-media.toyota.com.br/b05b2211-f4bc-4b4e-9c9f-fd111ec1c54d.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99620,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"url": "https://staging-media.toyota.com.ar/08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"url": "https://staging-media.toyota.com.ar/8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"url": "https://staging-media.toyota.com.ar/c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "279f410d-fd4f-4136-8300-a95939829d09.png",
														"url": "https://staging-media.toyota.com.ar/279f410d-fd4f-4136-8300-a95939829d09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"url": "https://staging-media.toyota.com.ar/ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"url": "https://staging-media.toyota.com.ar/dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "93bc8daf-ed44-413f-a6eb-5724dd5b6ec1.png",
														"url": "https://staging-media.toyota.com.ar/93bc8daf-ed44-413f-a6eb-5724dd5b6ec1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "83a778a3-0639-4985-a2a0-7c818bbd44b0.png",
														"url": "https://staging-media.toyota.com.ar/83a778a3-0639-4985-a2a0-7c818bbd44b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "aaf0c9ea-0eec-44eb-8e9b-5139800a2e1b.png",
														"url": "https://staging-media.toyota.com.ar/aaf0c9ea-0eec-44eb-8e9b-5139800a2e1b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "c9972dbd-3884-478a-9b91-f266247108c0.png",
														"url": "https://staging-media.toyota.com.ar/c9972dbd-3884-478a-9b91-f266247108c0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "35493519-bf06-4ccd-a155-45fe4af9b402.png",
														"url": "https://staging-media.toyota.com.ar/35493519-bf06-4ccd-a155-45fe4af9b402.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "a238b797-2bd1-4a87-81c2-758e635e9134.png",
														"url": "https://staging-media.toyota.com.ar/a238b797-2bd1-4a87-81c2-758e635e9134.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "32c9bcf9-5855-4f1c-b106-10e0f3c0da8f.png",
														"url": "https://staging-media.toyota.com.ar/32c9bcf9-5855-4f1c-b106-10e0f3c0da8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "81b88677-2d00-4ae9-8150-c826b9fe30cd.png",
														"url": "https://staging-media.toyota.com.ar/81b88677-2d00-4ae9-8150-c826b9fe30cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "a0a93f53-9026-411c-8dda-cb575a536efa.png",
														"url": "https://staging-media.toyota.com.ar/a0a93f53-9026-411c-8dda-cb575a536efa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "bb1b0567-5c8c-4d06-ad73-ca80d3413564.png",
														"url": "https://staging-media.toyota.com.ar/bb1b0567-5c8c-4d06-ad73-ca80d3413564.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "e5566f5c-351e-45ff-b8be-236842c13f4f.png",
														"url": "https://staging-media.toyota.com.ar/e5566f5c-351e-45ff-b8be-236842c13f4f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"url": "https://staging-media.toyota.com.ar/d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "2",
														"path": "6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"url": "https://staging-media.toyota.com.ar/6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "1",
														"path": "b05b2211-f4bc-4b4e-9c9f-fd111ec1c54d.png",
														"url": "https://staging-media.toyota.com.br/b05b2211-f4bc-4b4e-9c9f-fd111ec1c54d.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99620,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"url": "https://staging-media.toyota.com.ar/08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"url": "https://staging-media.toyota.com.ar/8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"url": "https://staging-media.toyota.com.ar/c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "279f410d-fd4f-4136-8300-a95939829d09.png",
														"url": "https://staging-media.toyota.com.ar/279f410d-fd4f-4136-8300-a95939829d09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"url": "https://staging-media.toyota.com.ar/ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"url": "https://staging-media.toyota.com.ar/dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "93bc8daf-ed44-413f-a6eb-5724dd5b6ec1.png",
														"url": "https://staging-media.toyota.com.ar/93bc8daf-ed44-413f-a6eb-5724dd5b6ec1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "83a778a3-0639-4985-a2a0-7c818bbd44b0.png",
														"url": "https://staging-media.toyota.com.ar/83a778a3-0639-4985-a2a0-7c818bbd44b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "aaf0c9ea-0eec-44eb-8e9b-5139800a2e1b.png",
														"url": "https://staging-media.toyota.com.ar/aaf0c9ea-0eec-44eb-8e9b-5139800a2e1b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "c9972dbd-3884-478a-9b91-f266247108c0.png",
														"url": "https://staging-media.toyota.com.ar/c9972dbd-3884-478a-9b91-f266247108c0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "35493519-bf06-4ccd-a155-45fe4af9b402.png",
														"url": "https://staging-media.toyota.com.ar/35493519-bf06-4ccd-a155-45fe4af9b402.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "a238b797-2bd1-4a87-81c2-758e635e9134.png",
														"url": "https://staging-media.toyota.com.ar/a238b797-2bd1-4a87-81c2-758e635e9134.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "32c9bcf9-5855-4f1c-b106-10e0f3c0da8f.png",
														"url": "https://staging-media.toyota.com.ar/32c9bcf9-5855-4f1c-b106-10e0f3c0da8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "81b88677-2d00-4ae9-8150-c826b9fe30cd.png",
														"url": "https://staging-media.toyota.com.ar/81b88677-2d00-4ae9-8150-c826b9fe30cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "a0a93f53-9026-411c-8dda-cb575a536efa.png",
														"url": "https://staging-media.toyota.com.ar/a0a93f53-9026-411c-8dda-cb575a536efa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "bb1b0567-5c8c-4d06-ad73-ca80d3413564.png",
														"url": "https://staging-media.toyota.com.ar/bb1b0567-5c8c-4d06-ad73-ca80d3413564.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "e5566f5c-351e-45ff-b8be-236842c13f4f.png",
														"url": "https://staging-media.toyota.com.ar/e5566f5c-351e-45ff-b8be-236842c13f4f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"url": "https://staging-media.toyota.com.ar/d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "2",
														"path": "6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"url": "https://staging-media.toyota.com.ar/6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "1",
														"path": "b05b2211-f4bc-4b4e-9c9f-fd111ec1c54d.png",
														"url": "https://staging-media.toyota.com.br/b05b2211-f4bc-4b4e-9c9f-fd111ec1c54d.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99620,
										"offers": null,
										"color": {
											"name": "Azul Titã (8V5) ",
											"code": "8V5",
											"rgb": "#3D4954"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"url": "https://staging-media.toyota.com.ar/08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"url": "https://staging-media.toyota.com.ar/8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"url": "https://staging-media.toyota.com.ar/c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "279f410d-fd4f-4136-8300-a95939829d09.png",
														"url": "https://staging-media.toyota.com.ar/279f410d-fd4f-4136-8300-a95939829d09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"url": "https://staging-media.toyota.com.ar/ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"url": "https://staging-media.toyota.com.ar/dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "93bc8daf-ed44-413f-a6eb-5724dd5b6ec1.png",
														"url": "https://staging-media.toyota.com.ar/93bc8daf-ed44-413f-a6eb-5724dd5b6ec1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "83a778a3-0639-4985-a2a0-7c818bbd44b0.png",
														"url": "https://staging-media.toyota.com.ar/83a778a3-0639-4985-a2a0-7c818bbd44b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "aaf0c9ea-0eec-44eb-8e9b-5139800a2e1b.png",
														"url": "https://staging-media.toyota.com.ar/aaf0c9ea-0eec-44eb-8e9b-5139800a2e1b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "c9972dbd-3884-478a-9b91-f266247108c0.png",
														"url": "https://staging-media.toyota.com.ar/c9972dbd-3884-478a-9b91-f266247108c0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "35493519-bf06-4ccd-a155-45fe4af9b402.png",
														"url": "https://staging-media.toyota.com.ar/35493519-bf06-4ccd-a155-45fe4af9b402.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "a238b797-2bd1-4a87-81c2-758e635e9134.png",
														"url": "https://staging-media.toyota.com.ar/a238b797-2bd1-4a87-81c2-758e635e9134.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "32c9bcf9-5855-4f1c-b106-10e0f3c0da8f.png",
														"url": "https://staging-media.toyota.com.ar/32c9bcf9-5855-4f1c-b106-10e0f3c0da8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "81b88677-2d00-4ae9-8150-c826b9fe30cd.png",
														"url": "https://staging-media.toyota.com.ar/81b88677-2d00-4ae9-8150-c826b9fe30cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "a0a93f53-9026-411c-8dda-cb575a536efa.png",
														"url": "https://staging-media.toyota.com.ar/a0a93f53-9026-411c-8dda-cb575a536efa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "bb1b0567-5c8c-4d06-ad73-ca80d3413564.png",
														"url": "https://staging-media.toyota.com.ar/bb1b0567-5c8c-4d06-ad73-ca80d3413564.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "e5566f5c-351e-45ff-b8be-236842c13f4f.png",
														"url": "https://staging-media.toyota.com.ar/e5566f5c-351e-45ff-b8be-236842c13f4f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"url": "https://staging-media.toyota.com.ar/d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "2",
														"path": "6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"url": "https://staging-media.toyota.com.ar/6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "1",
														"path": "b05b2211-f4bc-4b4e-9c9f-fd111ec1c54d.png",
														"url": "https://staging-media.toyota.com.br/b05b2211-f4bc-4b4e-9c9f-fd111ec1c54d.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 99920,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"url": "https://staging-media.toyota.com.ar/08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"url": "https://staging-media.toyota.com.ar/8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"url": "https://staging-media.toyota.com.ar/c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "279f410d-fd4f-4136-8300-a95939829d09.png",
														"url": "https://staging-media.toyota.com.ar/279f410d-fd4f-4136-8300-a95939829d09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"url": "https://staging-media.toyota.com.ar/ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"url": "https://staging-media.toyota.com.ar/dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "93bc8daf-ed44-413f-a6eb-5724dd5b6ec1.png",
														"url": "https://staging-media.toyota.com.ar/93bc8daf-ed44-413f-a6eb-5724dd5b6ec1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "83a778a3-0639-4985-a2a0-7c818bbd44b0.png",
														"url": "https://staging-media.toyota.com.ar/83a778a3-0639-4985-a2a0-7c818bbd44b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "aaf0c9ea-0eec-44eb-8e9b-5139800a2e1b.png",
														"url": "https://staging-media.toyota.com.ar/aaf0c9ea-0eec-44eb-8e9b-5139800a2e1b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "c9972dbd-3884-478a-9b91-f266247108c0.png",
														"url": "https://staging-media.toyota.com.ar/c9972dbd-3884-478a-9b91-f266247108c0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "35493519-bf06-4ccd-a155-45fe4af9b402.png",
														"url": "https://staging-media.toyota.com.ar/35493519-bf06-4ccd-a155-45fe4af9b402.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "a238b797-2bd1-4a87-81c2-758e635e9134.png",
														"url": "https://staging-media.toyota.com.ar/a238b797-2bd1-4a87-81c2-758e635e9134.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "32c9bcf9-5855-4f1c-b106-10e0f3c0da8f.png",
														"url": "https://staging-media.toyota.com.ar/32c9bcf9-5855-4f1c-b106-10e0f3c0da8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "81b88677-2d00-4ae9-8150-c826b9fe30cd.png",
														"url": "https://staging-media.toyota.com.ar/81b88677-2d00-4ae9-8150-c826b9fe30cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "a0a93f53-9026-411c-8dda-cb575a536efa.png",
														"url": "https://staging-media.toyota.com.ar/a0a93f53-9026-411c-8dda-cb575a536efa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "bb1b0567-5c8c-4d06-ad73-ca80d3413564.png",
														"url": "https://staging-media.toyota.com.ar/bb1b0567-5c8c-4d06-ad73-ca80d3413564.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "e5566f5c-351e-45ff-b8be-236842c13f4f.png",
														"url": "https://staging-media.toyota.com.ar/e5566f5c-351e-45ff-b8be-236842c13f4f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"url": "https://staging-media.toyota.com.ar/d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "2",
														"path": "6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"url": "https://staging-media.toyota.com.ar/6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "1",
														"path": "b05b2211-f4bc-4b4e-9c9f-fd111ec1c54d.png",
														"url": "https://staging-media.toyota.com.br/b05b2211-f4bc-4b4e-9c9f-fd111ec1c54d.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "XS",
						"id": "8363fb97-c7e1-45b6-9456-51ec62b9c542",
						"order": 2,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 109490,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Yaris Sedã ",
														"path": "8eb4a451-ee07-47c2-af0c-170001ed7c33.png",
														"url": "https://staging-media.toyota.com.ar/8eb4a451-ee07-47c2-af0c-170001ed7c33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Yaris Sedã",
														"path": "b54c8620-2bb3-4a3b-8327-15513609f9e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/b54c8620-2bb3-4a3b-8327-15513609f9e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Yaris Sedã",
														"path": "05bd6bb5-a4a2-46b7-acaa-8f59d42d82ff.jpeg",
														"url": "https://staging-media.toyota.com.ar/05bd6bb5-a4a2-46b7-acaa-8f59d42d82ff.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "520ff24e-0f37-4fd2-a57a-ded776575d88.jpeg",
														"url": "https://staging-media.toyota.com.ar/520ff24e-0f37-4fd2-a57a-ded776575d88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "0fba2db6-5e0b-414d-aa75-e07243cfbbd7.png",
														"url": "https://staging-media.toyota.com.ar/0fba2db6-5e0b-414d-aa75-e07243cfbbd7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "51346e51-4379-4640-ad1b-f8721a29331d.png",
														"url": "https://staging-media.toyota.com.ar/51346e51-4379-4640-ad1b-f8721a29331d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "9d68313c-4406-493d-a26e-ce0684200176.png",
														"url": "https://staging-media.toyota.com.ar/9d68313c-4406-493d-a26e-ce0684200176.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "5d960369-fa1c-45f7-9864-8c277ddbb0f2.png",
														"url": "https://staging-media.toyota.com.ar/5d960369-fa1c-45f7-9864-8c277ddbb0f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "8b8e0586-6530-4970-b592-1d9f5aca1577.png",
														"url": "https://staging-media.toyota.com.ar/8b8e0586-6530-4970-b592-1d9f5aca1577.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "6c582c4a-1db6-4de0-ab22-d00b57a96257.jpeg",
														"url": "https://staging-media.toyota.com.ar/6c582c4a-1db6-4de0-ab22-d00b57a96257.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "99927643-7461-408b-8c37-94149ac4fb5f.jpeg",
														"url": "https://staging-media.toyota.com.ar/99927643-7461-408b-8c37-94149ac4fb5f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "d126ef9f-3faf-4152-b38c-9ea6f95bbb65.jpeg",
														"url": "https://staging-media.toyota.com.ar/d126ef9f-3faf-4152-b38c-9ea6f95bbb65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "cc6e5d34-9087-48e6-b99d-da0734ef9a64.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc6e5d34-9087-48e6-b99d-da0734ef9a64.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "8094b20d-24b9-4036-8050-b56ebac100c6.jpeg",
														"url": "https://staging-media.toyota.com.ar/8094b20d-24b9-4036-8050-b56ebac100c6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "1f5facad-9052-4ff7-a0b4-190e62f283c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/1f5facad-9052-4ff7-a0b4-190e62f283c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "23b78f2c-0865-46e2-be84-5efb07f7f8fa.png",
														"url": "https://staging-media.toyota.com.ar/23b78f2c-0865-46e2-be84-5efb07f7f8fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "77ed2084-42e0-45ae-9c1a-4491e9fcbae7.png",
														"url": "https://staging-media.toyota.com.ar/77ed2084-42e0-45ae-9c1a-4491e9fcbae7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "f306cf6e-b5a2-465a-b124-c0e4b706441a.png",
														"url": "https://staging-media.toyota.com.ar/f306cf6e-b5a2-465a-b124-c0e4b706441a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "4fe7af48-70dd-4c0e-b599-4e564f5c26e9.png",
														"url": "https://staging-media.toyota.com.ar/4fe7af48-70dd-4c0e-b599-4e564f5c26e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "1646036e-9de7-4c96-b80a-0a6c64c02149.png",
														"url": "https://staging-media.toyota.com.ar/1646036e-9de7-4c96-b80a-0a6c64c02149.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "41b3228b-1e0f-476d-8340-b140cee53835.png",
														"url": "https://staging-media.toyota.com.ar/41b3228b-1e0f-476d-8340-b140cee53835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "4fcdfb1d-9768-4b04-a993-d6431357894f.png",
														"url": "https://staging-media.toyota.com.ar/4fcdfb1d-9768-4b04-a993-d6431357894f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "b703d6cc-c89c-4fc2-85a1-1ad44dd86efb.png",
														"url": "https://staging-media.toyota.com.ar/b703d6cc-c89c-4fc2-85a1-1ad44dd86efb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "8df58063-e258-4e40-a35d-ea0a90d42857.png",
														"url": "https://staging-media.toyota.com.ar/8df58063-e258-4e40-a35d-ea0a90d42857.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "7bdad4e8-f98f-4a9c-a588-de1214b026f4.png",
														"url": "https://staging-media.toyota.com.ar/7bdad4e8-f98f-4a9c-a588-de1214b026f4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1560988c-9c14-4c87-96cb-1420cc1adc45.png",
														"url": "https://staging-media.toyota.com.ar/1560988c-9c14-4c87-96cb-1420cc1adc45.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1881e1bd-e07a-49f1-95de-320133ba8e4e.png",
														"url": "https://staging-media.toyota.com.ar/1881e1bd-e07a-49f1-95de-320133ba8e4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-XS-040",
														"path": "88c38484-33be-4a06-9ac9-7990fa4727b6.png",
														"url": "https://staging-media.toyota.com.ar/88c38484-33be-4a06-9ac9-7990fa4727b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-XS-040",
														"path": "f6dc9dc9-c567-4693-ac96-c37f8c3e5116.png",
														"url": "https://staging-media.toyota.com.ar/f6dc9dc9-c567-4693-ac96-c37f8c3e5116.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 111120,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Yaris Sedã ",
														"path": "8eb4a451-ee07-47c2-af0c-170001ed7c33.png",
														"url": "https://staging-media.toyota.com.ar/8eb4a451-ee07-47c2-af0c-170001ed7c33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Yaris Sedã",
														"path": "b54c8620-2bb3-4a3b-8327-15513609f9e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/b54c8620-2bb3-4a3b-8327-15513609f9e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Yaris Sedã",
														"path": "05bd6bb5-a4a2-46b7-acaa-8f59d42d82ff.jpeg",
														"url": "https://staging-media.toyota.com.ar/05bd6bb5-a4a2-46b7-acaa-8f59d42d82ff.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "520ff24e-0f37-4fd2-a57a-ded776575d88.jpeg",
														"url": "https://staging-media.toyota.com.ar/520ff24e-0f37-4fd2-a57a-ded776575d88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "0fba2db6-5e0b-414d-aa75-e07243cfbbd7.png",
														"url": "https://staging-media.toyota.com.ar/0fba2db6-5e0b-414d-aa75-e07243cfbbd7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "51346e51-4379-4640-ad1b-f8721a29331d.png",
														"url": "https://staging-media.toyota.com.ar/51346e51-4379-4640-ad1b-f8721a29331d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "9d68313c-4406-493d-a26e-ce0684200176.png",
														"url": "https://staging-media.toyota.com.ar/9d68313c-4406-493d-a26e-ce0684200176.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "5d960369-fa1c-45f7-9864-8c277ddbb0f2.png",
														"url": "https://staging-media.toyota.com.ar/5d960369-fa1c-45f7-9864-8c277ddbb0f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "8b8e0586-6530-4970-b592-1d9f5aca1577.png",
														"url": "https://staging-media.toyota.com.ar/8b8e0586-6530-4970-b592-1d9f5aca1577.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "6c582c4a-1db6-4de0-ab22-d00b57a96257.jpeg",
														"url": "https://staging-media.toyota.com.ar/6c582c4a-1db6-4de0-ab22-d00b57a96257.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "99927643-7461-408b-8c37-94149ac4fb5f.jpeg",
														"url": "https://staging-media.toyota.com.ar/99927643-7461-408b-8c37-94149ac4fb5f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "d126ef9f-3faf-4152-b38c-9ea6f95bbb65.jpeg",
														"url": "https://staging-media.toyota.com.ar/d126ef9f-3faf-4152-b38c-9ea6f95bbb65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "cc6e5d34-9087-48e6-b99d-da0734ef9a64.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc6e5d34-9087-48e6-b99d-da0734ef9a64.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "8094b20d-24b9-4036-8050-b56ebac100c6.jpeg",
														"url": "https://staging-media.toyota.com.ar/8094b20d-24b9-4036-8050-b56ebac100c6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "1f5facad-9052-4ff7-a0b4-190e62f283c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/1f5facad-9052-4ff7-a0b4-190e62f283c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "23b78f2c-0865-46e2-be84-5efb07f7f8fa.png",
														"url": "https://staging-media.toyota.com.ar/23b78f2c-0865-46e2-be84-5efb07f7f8fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "77ed2084-42e0-45ae-9c1a-4491e9fcbae7.png",
														"url": "https://staging-media.toyota.com.ar/77ed2084-42e0-45ae-9c1a-4491e9fcbae7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "f306cf6e-b5a2-465a-b124-c0e4b706441a.png",
														"url": "https://staging-media.toyota.com.ar/f306cf6e-b5a2-465a-b124-c0e4b706441a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "4fe7af48-70dd-4c0e-b599-4e564f5c26e9.png",
														"url": "https://staging-media.toyota.com.ar/4fe7af48-70dd-4c0e-b599-4e564f5c26e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "1646036e-9de7-4c96-b80a-0a6c64c02149.png",
														"url": "https://staging-media.toyota.com.ar/1646036e-9de7-4c96-b80a-0a6c64c02149.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "41b3228b-1e0f-476d-8340-b140cee53835.png",
														"url": "https://staging-media.toyota.com.ar/41b3228b-1e0f-476d-8340-b140cee53835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "4fcdfb1d-9768-4b04-a993-d6431357894f.png",
														"url": "https://staging-media.toyota.com.ar/4fcdfb1d-9768-4b04-a993-d6431357894f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "b703d6cc-c89c-4fc2-85a1-1ad44dd86efb.png",
														"url": "https://staging-media.toyota.com.ar/b703d6cc-c89c-4fc2-85a1-1ad44dd86efb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "8df58063-e258-4e40-a35d-ea0a90d42857.png",
														"url": "https://staging-media.toyota.com.ar/8df58063-e258-4e40-a35d-ea0a90d42857.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "7bdad4e8-f98f-4a9c-a588-de1214b026f4.png",
														"url": "https://staging-media.toyota.com.ar/7bdad4e8-f98f-4a9c-a588-de1214b026f4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1560988c-9c14-4c87-96cb-1420cc1adc45.png",
														"url": "https://staging-media.toyota.com.ar/1560988c-9c14-4c87-96cb-1420cc1adc45.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1881e1bd-e07a-49f1-95de-320133ba8e4e.png",
														"url": "https://staging-media.toyota.com.ar/1881e1bd-e07a-49f1-95de-320133ba8e4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-XS-040",
														"path": "88c38484-33be-4a06-9ac9-7990fa4727b6.png",
														"url": "https://staging-media.toyota.com.ar/88c38484-33be-4a06-9ac9-7990fa4727b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-XS-040",
														"path": "f6dc9dc9-c567-4693-ac96-c37f8c3e5116.png",
														"url": "https://staging-media.toyota.com.ar/f6dc9dc9-c567-4693-ac96-c37f8c3e5116.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 111120,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Yaris Sedã ",
														"path": "8eb4a451-ee07-47c2-af0c-170001ed7c33.png",
														"url": "https://staging-media.toyota.com.ar/8eb4a451-ee07-47c2-af0c-170001ed7c33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Yaris Sedã",
														"path": "b54c8620-2bb3-4a3b-8327-15513609f9e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/b54c8620-2bb3-4a3b-8327-15513609f9e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Yaris Sedã",
														"path": "05bd6bb5-a4a2-46b7-acaa-8f59d42d82ff.jpeg",
														"url": "https://staging-media.toyota.com.ar/05bd6bb5-a4a2-46b7-acaa-8f59d42d82ff.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "520ff24e-0f37-4fd2-a57a-ded776575d88.jpeg",
														"url": "https://staging-media.toyota.com.ar/520ff24e-0f37-4fd2-a57a-ded776575d88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "0fba2db6-5e0b-414d-aa75-e07243cfbbd7.png",
														"url": "https://staging-media.toyota.com.ar/0fba2db6-5e0b-414d-aa75-e07243cfbbd7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "51346e51-4379-4640-ad1b-f8721a29331d.png",
														"url": "https://staging-media.toyota.com.ar/51346e51-4379-4640-ad1b-f8721a29331d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "9d68313c-4406-493d-a26e-ce0684200176.png",
														"url": "https://staging-media.toyota.com.ar/9d68313c-4406-493d-a26e-ce0684200176.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "5d960369-fa1c-45f7-9864-8c277ddbb0f2.png",
														"url": "https://staging-media.toyota.com.ar/5d960369-fa1c-45f7-9864-8c277ddbb0f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "8b8e0586-6530-4970-b592-1d9f5aca1577.png",
														"url": "https://staging-media.toyota.com.ar/8b8e0586-6530-4970-b592-1d9f5aca1577.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "6c582c4a-1db6-4de0-ab22-d00b57a96257.jpeg",
														"url": "https://staging-media.toyota.com.ar/6c582c4a-1db6-4de0-ab22-d00b57a96257.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "99927643-7461-408b-8c37-94149ac4fb5f.jpeg",
														"url": "https://staging-media.toyota.com.ar/99927643-7461-408b-8c37-94149ac4fb5f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "d126ef9f-3faf-4152-b38c-9ea6f95bbb65.jpeg",
														"url": "https://staging-media.toyota.com.ar/d126ef9f-3faf-4152-b38c-9ea6f95bbb65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "cc6e5d34-9087-48e6-b99d-da0734ef9a64.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc6e5d34-9087-48e6-b99d-da0734ef9a64.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "8094b20d-24b9-4036-8050-b56ebac100c6.jpeg",
														"url": "https://staging-media.toyota.com.ar/8094b20d-24b9-4036-8050-b56ebac100c6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "1f5facad-9052-4ff7-a0b4-190e62f283c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/1f5facad-9052-4ff7-a0b4-190e62f283c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "23b78f2c-0865-46e2-be84-5efb07f7f8fa.png",
														"url": "https://staging-media.toyota.com.ar/23b78f2c-0865-46e2-be84-5efb07f7f8fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "77ed2084-42e0-45ae-9c1a-4491e9fcbae7.png",
														"url": "https://staging-media.toyota.com.ar/77ed2084-42e0-45ae-9c1a-4491e9fcbae7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "f306cf6e-b5a2-465a-b124-c0e4b706441a.png",
														"url": "https://staging-media.toyota.com.ar/f306cf6e-b5a2-465a-b124-c0e4b706441a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "4fe7af48-70dd-4c0e-b599-4e564f5c26e9.png",
														"url": "https://staging-media.toyota.com.ar/4fe7af48-70dd-4c0e-b599-4e564f5c26e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "1646036e-9de7-4c96-b80a-0a6c64c02149.png",
														"url": "https://staging-media.toyota.com.ar/1646036e-9de7-4c96-b80a-0a6c64c02149.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "41b3228b-1e0f-476d-8340-b140cee53835.png",
														"url": "https://staging-media.toyota.com.ar/41b3228b-1e0f-476d-8340-b140cee53835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "4fcdfb1d-9768-4b04-a993-d6431357894f.png",
														"url": "https://staging-media.toyota.com.ar/4fcdfb1d-9768-4b04-a993-d6431357894f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "b703d6cc-c89c-4fc2-85a1-1ad44dd86efb.png",
														"url": "https://staging-media.toyota.com.ar/b703d6cc-c89c-4fc2-85a1-1ad44dd86efb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "8df58063-e258-4e40-a35d-ea0a90d42857.png",
														"url": "https://staging-media.toyota.com.ar/8df58063-e258-4e40-a35d-ea0a90d42857.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "7bdad4e8-f98f-4a9c-a588-de1214b026f4.png",
														"url": "https://staging-media.toyota.com.ar/7bdad4e8-f98f-4a9c-a588-de1214b026f4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1560988c-9c14-4c87-96cb-1420cc1adc45.png",
														"url": "https://staging-media.toyota.com.ar/1560988c-9c14-4c87-96cb-1420cc1adc45.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1881e1bd-e07a-49f1-95de-320133ba8e4e.png",
														"url": "https://staging-media.toyota.com.ar/1881e1bd-e07a-49f1-95de-320133ba8e4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-XS-040",
														"path": "88c38484-33be-4a06-9ac9-7990fa4727b6.png",
														"url": "https://staging-media.toyota.com.ar/88c38484-33be-4a06-9ac9-7990fa4727b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-XS-040",
														"path": "f6dc9dc9-c567-4693-ac96-c37f8c3e5116.png",
														"url": "https://staging-media.toyota.com.ar/f6dc9dc9-c567-4693-ac96-c37f8c3e5116.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 111120,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Yaris Sedã ",
														"path": "8eb4a451-ee07-47c2-af0c-170001ed7c33.png",
														"url": "https://staging-media.toyota.com.ar/8eb4a451-ee07-47c2-af0c-170001ed7c33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Yaris Sedã",
														"path": "b54c8620-2bb3-4a3b-8327-15513609f9e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/b54c8620-2bb3-4a3b-8327-15513609f9e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Yaris Sedã",
														"path": "05bd6bb5-a4a2-46b7-acaa-8f59d42d82ff.jpeg",
														"url": "https://staging-media.toyota.com.ar/05bd6bb5-a4a2-46b7-acaa-8f59d42d82ff.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "520ff24e-0f37-4fd2-a57a-ded776575d88.jpeg",
														"url": "https://staging-media.toyota.com.ar/520ff24e-0f37-4fd2-a57a-ded776575d88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "0fba2db6-5e0b-414d-aa75-e07243cfbbd7.png",
														"url": "https://staging-media.toyota.com.ar/0fba2db6-5e0b-414d-aa75-e07243cfbbd7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "51346e51-4379-4640-ad1b-f8721a29331d.png",
														"url": "https://staging-media.toyota.com.ar/51346e51-4379-4640-ad1b-f8721a29331d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "9d68313c-4406-493d-a26e-ce0684200176.png",
														"url": "https://staging-media.toyota.com.ar/9d68313c-4406-493d-a26e-ce0684200176.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "5d960369-fa1c-45f7-9864-8c277ddbb0f2.png",
														"url": "https://staging-media.toyota.com.ar/5d960369-fa1c-45f7-9864-8c277ddbb0f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "8b8e0586-6530-4970-b592-1d9f5aca1577.png",
														"url": "https://staging-media.toyota.com.ar/8b8e0586-6530-4970-b592-1d9f5aca1577.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "6c582c4a-1db6-4de0-ab22-d00b57a96257.jpeg",
														"url": "https://staging-media.toyota.com.ar/6c582c4a-1db6-4de0-ab22-d00b57a96257.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "99927643-7461-408b-8c37-94149ac4fb5f.jpeg",
														"url": "https://staging-media.toyota.com.ar/99927643-7461-408b-8c37-94149ac4fb5f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "d126ef9f-3faf-4152-b38c-9ea6f95bbb65.jpeg",
														"url": "https://staging-media.toyota.com.ar/d126ef9f-3faf-4152-b38c-9ea6f95bbb65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "cc6e5d34-9087-48e6-b99d-da0734ef9a64.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc6e5d34-9087-48e6-b99d-da0734ef9a64.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "8094b20d-24b9-4036-8050-b56ebac100c6.jpeg",
														"url": "https://staging-media.toyota.com.ar/8094b20d-24b9-4036-8050-b56ebac100c6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "1f5facad-9052-4ff7-a0b4-190e62f283c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/1f5facad-9052-4ff7-a0b4-190e62f283c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "23b78f2c-0865-46e2-be84-5efb07f7f8fa.png",
														"url": "https://staging-media.toyota.com.ar/23b78f2c-0865-46e2-be84-5efb07f7f8fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "77ed2084-42e0-45ae-9c1a-4491e9fcbae7.png",
														"url": "https://staging-media.toyota.com.ar/77ed2084-42e0-45ae-9c1a-4491e9fcbae7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "f306cf6e-b5a2-465a-b124-c0e4b706441a.png",
														"url": "https://staging-media.toyota.com.ar/f306cf6e-b5a2-465a-b124-c0e4b706441a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "4fe7af48-70dd-4c0e-b599-4e564f5c26e9.png",
														"url": "https://staging-media.toyota.com.ar/4fe7af48-70dd-4c0e-b599-4e564f5c26e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "1646036e-9de7-4c96-b80a-0a6c64c02149.png",
														"url": "https://staging-media.toyota.com.ar/1646036e-9de7-4c96-b80a-0a6c64c02149.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "41b3228b-1e0f-476d-8340-b140cee53835.png",
														"url": "https://staging-media.toyota.com.ar/41b3228b-1e0f-476d-8340-b140cee53835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "4fcdfb1d-9768-4b04-a993-d6431357894f.png",
														"url": "https://staging-media.toyota.com.ar/4fcdfb1d-9768-4b04-a993-d6431357894f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "b703d6cc-c89c-4fc2-85a1-1ad44dd86efb.png",
														"url": "https://staging-media.toyota.com.ar/b703d6cc-c89c-4fc2-85a1-1ad44dd86efb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "8df58063-e258-4e40-a35d-ea0a90d42857.png",
														"url": "https://staging-media.toyota.com.ar/8df58063-e258-4e40-a35d-ea0a90d42857.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "7bdad4e8-f98f-4a9c-a588-de1214b026f4.png",
														"url": "https://staging-media.toyota.com.ar/7bdad4e8-f98f-4a9c-a588-de1214b026f4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1560988c-9c14-4c87-96cb-1420cc1adc45.png",
														"url": "https://staging-media.toyota.com.ar/1560988c-9c14-4c87-96cb-1420cc1adc45.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1881e1bd-e07a-49f1-95de-320133ba8e4e.png",
														"url": "https://staging-media.toyota.com.ar/1881e1bd-e07a-49f1-95de-320133ba8e4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-XS-040",
														"path": "88c38484-33be-4a06-9ac9-7990fa4727b6.png",
														"url": "https://staging-media.toyota.com.ar/88c38484-33be-4a06-9ac9-7990fa4727b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-XS-040",
														"path": "f6dc9dc9-c567-4693-ac96-c37f8c3e5116.png",
														"url": "https://staging-media.toyota.com.ar/f6dc9dc9-c567-4693-ac96-c37f8c3e5116.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 111120,
										"offers": null,
										"color": {
											"name": "Azul Titã (8V5) ",
											"code": "8V5",
											"rgb": "#3D4954"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Yaris Sedã ",
														"path": "8eb4a451-ee07-47c2-af0c-170001ed7c33.png",
														"url": "https://staging-media.toyota.com.ar/8eb4a451-ee07-47c2-af0c-170001ed7c33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Yaris Sedã",
														"path": "b54c8620-2bb3-4a3b-8327-15513609f9e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/b54c8620-2bb3-4a3b-8327-15513609f9e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Yaris Sedã",
														"path": "05bd6bb5-a4a2-46b7-acaa-8f59d42d82ff.jpeg",
														"url": "https://staging-media.toyota.com.ar/05bd6bb5-a4a2-46b7-acaa-8f59d42d82ff.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "520ff24e-0f37-4fd2-a57a-ded776575d88.jpeg",
														"url": "https://staging-media.toyota.com.ar/520ff24e-0f37-4fd2-a57a-ded776575d88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "0fba2db6-5e0b-414d-aa75-e07243cfbbd7.png",
														"url": "https://staging-media.toyota.com.ar/0fba2db6-5e0b-414d-aa75-e07243cfbbd7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "51346e51-4379-4640-ad1b-f8721a29331d.png",
														"url": "https://staging-media.toyota.com.ar/51346e51-4379-4640-ad1b-f8721a29331d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "9d68313c-4406-493d-a26e-ce0684200176.png",
														"url": "https://staging-media.toyota.com.ar/9d68313c-4406-493d-a26e-ce0684200176.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "5d960369-fa1c-45f7-9864-8c277ddbb0f2.png",
														"url": "https://staging-media.toyota.com.ar/5d960369-fa1c-45f7-9864-8c277ddbb0f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "8b8e0586-6530-4970-b592-1d9f5aca1577.png",
														"url": "https://staging-media.toyota.com.ar/8b8e0586-6530-4970-b592-1d9f5aca1577.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "6c582c4a-1db6-4de0-ab22-d00b57a96257.jpeg",
														"url": "https://staging-media.toyota.com.ar/6c582c4a-1db6-4de0-ab22-d00b57a96257.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "99927643-7461-408b-8c37-94149ac4fb5f.jpeg",
														"url": "https://staging-media.toyota.com.ar/99927643-7461-408b-8c37-94149ac4fb5f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "d126ef9f-3faf-4152-b38c-9ea6f95bbb65.jpeg",
														"url": "https://staging-media.toyota.com.ar/d126ef9f-3faf-4152-b38c-9ea6f95bbb65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "cc6e5d34-9087-48e6-b99d-da0734ef9a64.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc6e5d34-9087-48e6-b99d-da0734ef9a64.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "8094b20d-24b9-4036-8050-b56ebac100c6.jpeg",
														"url": "https://staging-media.toyota.com.ar/8094b20d-24b9-4036-8050-b56ebac100c6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "1f5facad-9052-4ff7-a0b4-190e62f283c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/1f5facad-9052-4ff7-a0b4-190e62f283c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "23b78f2c-0865-46e2-be84-5efb07f7f8fa.png",
														"url": "https://staging-media.toyota.com.ar/23b78f2c-0865-46e2-be84-5efb07f7f8fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "77ed2084-42e0-45ae-9c1a-4491e9fcbae7.png",
														"url": "https://staging-media.toyota.com.ar/77ed2084-42e0-45ae-9c1a-4491e9fcbae7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "f306cf6e-b5a2-465a-b124-c0e4b706441a.png",
														"url": "https://staging-media.toyota.com.ar/f306cf6e-b5a2-465a-b124-c0e4b706441a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "4fe7af48-70dd-4c0e-b599-4e564f5c26e9.png",
														"url": "https://staging-media.toyota.com.ar/4fe7af48-70dd-4c0e-b599-4e564f5c26e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "1646036e-9de7-4c96-b80a-0a6c64c02149.png",
														"url": "https://staging-media.toyota.com.ar/1646036e-9de7-4c96-b80a-0a6c64c02149.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "41b3228b-1e0f-476d-8340-b140cee53835.png",
														"url": "https://staging-media.toyota.com.ar/41b3228b-1e0f-476d-8340-b140cee53835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "4fcdfb1d-9768-4b04-a993-d6431357894f.png",
														"url": "https://staging-media.toyota.com.ar/4fcdfb1d-9768-4b04-a993-d6431357894f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "b703d6cc-c89c-4fc2-85a1-1ad44dd86efb.png",
														"url": "https://staging-media.toyota.com.ar/b703d6cc-c89c-4fc2-85a1-1ad44dd86efb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "8df58063-e258-4e40-a35d-ea0a90d42857.png",
														"url": "https://staging-media.toyota.com.ar/8df58063-e258-4e40-a35d-ea0a90d42857.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "7bdad4e8-f98f-4a9c-a588-de1214b026f4.png",
														"url": "https://staging-media.toyota.com.ar/7bdad4e8-f98f-4a9c-a588-de1214b026f4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1560988c-9c14-4c87-96cb-1420cc1adc45.png",
														"url": "https://staging-media.toyota.com.ar/1560988c-9c14-4c87-96cb-1420cc1adc45.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1881e1bd-e07a-49f1-95de-320133ba8e4e.png",
														"url": "https://staging-media.toyota.com.ar/1881e1bd-e07a-49f1-95de-320133ba8e4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-XS-040",
														"path": "88c38484-33be-4a06-9ac9-7990fa4727b6.png",
														"url": "https://staging-media.toyota.com.ar/88c38484-33be-4a06-9ac9-7990fa4727b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-XS-040",
														"path": "f6dc9dc9-c567-4693-ac96-c37f8c3e5116.png",
														"url": "https://staging-media.toyota.com.ar/f6dc9dc9-c567-4693-ac96-c37f8c3e5116.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 111420,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Yaris Sedã ",
														"path": "8eb4a451-ee07-47c2-af0c-170001ed7c33.png",
														"url": "https://staging-media.toyota.com.ar/8eb4a451-ee07-47c2-af0c-170001ed7c33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Yaris Sedã",
														"path": "b54c8620-2bb3-4a3b-8327-15513609f9e1.jpeg",
														"url": "https://staging-media.toyota.com.ar/b54c8620-2bb3-4a3b-8327-15513609f9e1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Yaris Sedã",
														"path": "05bd6bb5-a4a2-46b7-acaa-8f59d42d82ff.jpeg",
														"url": "https://staging-media.toyota.com.ar/05bd6bb5-a4a2-46b7-acaa-8f59d42d82ff.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "520ff24e-0f37-4fd2-a57a-ded776575d88.jpeg",
														"url": "https://staging-media.toyota.com.ar/520ff24e-0f37-4fd2-a57a-ded776575d88.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "0fba2db6-5e0b-414d-aa75-e07243cfbbd7.png",
														"url": "https://staging-media.toyota.com.ar/0fba2db6-5e0b-414d-aa75-e07243cfbbd7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "51346e51-4379-4640-ad1b-f8721a29331d.png",
														"url": "https://staging-media.toyota.com.ar/51346e51-4379-4640-ad1b-f8721a29331d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "9d68313c-4406-493d-a26e-ce0684200176.png",
														"url": "https://staging-media.toyota.com.ar/9d68313c-4406-493d-a26e-ce0684200176.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "5d960369-fa1c-45f7-9864-8c277ddbb0f2.png",
														"url": "https://staging-media.toyota.com.ar/5d960369-fa1c-45f7-9864-8c277ddbb0f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "8b8e0586-6530-4970-b592-1d9f5aca1577.png",
														"url": "https://staging-media.toyota.com.ar/8b8e0586-6530-4970-b592-1d9f5aca1577.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "6c582c4a-1db6-4de0-ab22-d00b57a96257.jpeg",
														"url": "https://staging-media.toyota.com.ar/6c582c4a-1db6-4de0-ab22-d00b57a96257.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "99927643-7461-408b-8c37-94149ac4fb5f.jpeg",
														"url": "https://staging-media.toyota.com.ar/99927643-7461-408b-8c37-94149ac4fb5f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "d126ef9f-3faf-4152-b38c-9ea6f95bbb65.jpeg",
														"url": "https://staging-media.toyota.com.ar/d126ef9f-3faf-4152-b38c-9ea6f95bbb65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "cc6e5d34-9087-48e6-b99d-da0734ef9a64.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc6e5d34-9087-48e6-b99d-da0734ef9a64.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "8094b20d-24b9-4036-8050-b56ebac100c6.jpeg",
														"url": "https://staging-media.toyota.com.ar/8094b20d-24b9-4036-8050-b56ebac100c6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "1f5facad-9052-4ff7-a0b4-190e62f283c3.jpeg",
														"url": "https://staging-media.toyota.com.ar/1f5facad-9052-4ff7-a0b4-190e62f283c3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "23b78f2c-0865-46e2-be84-5efb07f7f8fa.png",
														"url": "https://staging-media.toyota.com.ar/23b78f2c-0865-46e2-be84-5efb07f7f8fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "77ed2084-42e0-45ae-9c1a-4491e9fcbae7.png",
														"url": "https://staging-media.toyota.com.ar/77ed2084-42e0-45ae-9c1a-4491e9fcbae7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "f306cf6e-b5a2-465a-b124-c0e4b706441a.png",
														"url": "https://staging-media.toyota.com.ar/f306cf6e-b5a2-465a-b124-c0e4b706441a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "4fe7af48-70dd-4c0e-b599-4e564f5c26e9.png",
														"url": "https://staging-media.toyota.com.ar/4fe7af48-70dd-4c0e-b599-4e564f5c26e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "1646036e-9de7-4c96-b80a-0a6c64c02149.png",
														"url": "https://staging-media.toyota.com.ar/1646036e-9de7-4c96-b80a-0a6c64c02149.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "41b3228b-1e0f-476d-8340-b140cee53835.png",
														"url": "https://staging-media.toyota.com.ar/41b3228b-1e0f-476d-8340-b140cee53835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "4fcdfb1d-9768-4b04-a993-d6431357894f.png",
														"url": "https://staging-media.toyota.com.ar/4fcdfb1d-9768-4b04-a993-d6431357894f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "b703d6cc-c89c-4fc2-85a1-1ad44dd86efb.png",
														"url": "https://staging-media.toyota.com.ar/b703d6cc-c89c-4fc2-85a1-1ad44dd86efb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "8df58063-e258-4e40-a35d-ea0a90d42857.png",
														"url": "https://staging-media.toyota.com.ar/8df58063-e258-4e40-a35d-ea0a90d42857.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "7bdad4e8-f98f-4a9c-a588-de1214b026f4.png",
														"url": "https://staging-media.toyota.com.ar/7bdad4e8-f98f-4a9c-a588-de1214b026f4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1560988c-9c14-4c87-96cb-1420cc1adc45.png",
														"url": "https://staging-media.toyota.com.ar/1560988c-9c14-4c87-96cb-1420cc1adc45.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1881e1bd-e07a-49f1-95de-320133ba8e4e.png",
														"url": "https://staging-media.toyota.com.ar/1881e1bd-e07a-49f1-95de-320133ba8e4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Yaris-XS-040",
														"path": "88c38484-33be-4a06-9ac9-7990fa4727b6.png",
														"url": "https://staging-media.toyota.com.ar/88c38484-33be-4a06-9ac9-7990fa4727b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Yaris-XS-040",
														"path": "f6dc9dc9-c567-4693-ac96-c37f8c3e5116.png",
														"url": "https://staging-media.toyota.com.ar/f6dc9dc9-c567-4693-ac96-c37f8c3e5116.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 113790,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"url": "https://staging-media.toyota.com.ar/08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"url": "https://staging-media.toyota.com.ar/8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"url": "https://staging-media.toyota.com.ar/c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "279f410d-fd4f-4136-8300-a95939829d09.png",
														"url": "https://staging-media.toyota.com.ar/279f410d-fd4f-4136-8300-a95939829d09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"url": "https://staging-media.toyota.com.ar/ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"url": "https://staging-media.toyota.com.ar/dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "36252e80-9d05-4cf6-9afa-0f0f8911367e.png",
														"url": "https://staging-media.toyota.com.ar/36252e80-9d05-4cf6-9afa-0f0f8911367e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d067abf-15e2-4a1c-8fdc-59417d2e83cd.png",
														"url": "https://staging-media.toyota.com.ar/9d067abf-15e2-4a1c-8fdc-59417d2e83cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "e9a974bc-a6d3-4c1c-8edc-d0ec2344e1f1.png",
														"url": "https://staging-media.toyota.com.ar/e9a974bc-a6d3-4c1c-8edc-d0ec2344e1f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "f07c540d-2739-4893-a51a-2e3b4896d22b.png",
														"url": "https://staging-media.toyota.com.ar/f07c540d-2739-4893-a51a-2e3b4896d22b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "8f23a69a-5a26-4c0c-8386-0a64435b2c9e.png",
														"url": "https://staging-media.toyota.com.ar/8f23a69a-5a26-4c0c-8386-0a64435b2c9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "451324ec-1821-4a4c-bc7d-954806aa0f91.png",
														"url": "https://staging-media.toyota.com.ar/451324ec-1821-4a4c-bc7d-954806aa0f91.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "f05e98aa-56aa-47c3-83cb-c7f04cc5723c.png",
														"url": "https://staging-media.toyota.com.ar/f05e98aa-56aa-47c3-83cb-c7f04cc5723c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "1dcb1e93-1207-4a98-84e1-d3a33bd61487.png",
														"url": "https://staging-media.toyota.com.ar/1dcb1e93-1207-4a98-84e1-d3a33bd61487.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "5595ff77-9830-4468-bd0f-f6a4c716ebbc.png",
														"url": "https://staging-media.toyota.com.ar/5595ff77-9830-4468-bd0f-f6a4c716ebbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "fcf47edc-be25-47b7-b856-c99eab2fe201.png",
														"url": "https://staging-media.toyota.com.ar/fcf47edc-be25-47b7-b856-c99eab2fe201.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "acc07611-ac18-4691-a159-a3e59917d981.png",
														"url": "https://staging-media.toyota.com.ar/acc07611-ac18-4691-a159-a3e59917d981.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "f4e62ad8-93c1-431f-9047-b430182240c1.png",
														"url": "https://staging-media.toyota.com.ar/f4e62ad8-93c1-431f-9047-b430182240c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"url": "https://staging-media.toyota.com.ar/d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "2",
														"path": "6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"url": "https://staging-media.toyota.com.ar/6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 115420,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"url": "https://staging-media.toyota.com.ar/08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"url": "https://staging-media.toyota.com.ar/8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"url": "https://staging-media.toyota.com.ar/c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "279f410d-fd4f-4136-8300-a95939829d09.png",
														"url": "https://staging-media.toyota.com.ar/279f410d-fd4f-4136-8300-a95939829d09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"url": "https://staging-media.toyota.com.ar/ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"url": "https://staging-media.toyota.com.ar/dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "36252e80-9d05-4cf6-9afa-0f0f8911367e.png",
														"url": "https://staging-media.toyota.com.ar/36252e80-9d05-4cf6-9afa-0f0f8911367e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d067abf-15e2-4a1c-8fdc-59417d2e83cd.png",
														"url": "https://staging-media.toyota.com.ar/9d067abf-15e2-4a1c-8fdc-59417d2e83cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "e9a974bc-a6d3-4c1c-8edc-d0ec2344e1f1.png",
														"url": "https://staging-media.toyota.com.ar/e9a974bc-a6d3-4c1c-8edc-d0ec2344e1f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "f07c540d-2739-4893-a51a-2e3b4896d22b.png",
														"url": "https://staging-media.toyota.com.ar/f07c540d-2739-4893-a51a-2e3b4896d22b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "8f23a69a-5a26-4c0c-8386-0a64435b2c9e.png",
														"url": "https://staging-media.toyota.com.ar/8f23a69a-5a26-4c0c-8386-0a64435b2c9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "451324ec-1821-4a4c-bc7d-954806aa0f91.png",
														"url": "https://staging-media.toyota.com.ar/451324ec-1821-4a4c-bc7d-954806aa0f91.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "f05e98aa-56aa-47c3-83cb-c7f04cc5723c.png",
														"url": "https://staging-media.toyota.com.ar/f05e98aa-56aa-47c3-83cb-c7f04cc5723c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "1dcb1e93-1207-4a98-84e1-d3a33bd61487.png",
														"url": "https://staging-media.toyota.com.ar/1dcb1e93-1207-4a98-84e1-d3a33bd61487.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "5595ff77-9830-4468-bd0f-f6a4c716ebbc.png",
														"url": "https://staging-media.toyota.com.ar/5595ff77-9830-4468-bd0f-f6a4c716ebbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "fcf47edc-be25-47b7-b856-c99eab2fe201.png",
														"url": "https://staging-media.toyota.com.ar/fcf47edc-be25-47b7-b856-c99eab2fe201.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "acc07611-ac18-4691-a159-a3e59917d981.png",
														"url": "https://staging-media.toyota.com.ar/acc07611-ac18-4691-a159-a3e59917d981.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "f4e62ad8-93c1-431f-9047-b430182240c1.png",
														"url": "https://staging-media.toyota.com.ar/f4e62ad8-93c1-431f-9047-b430182240c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"url": "https://staging-media.toyota.com.ar/d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "2",
														"path": "6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"url": "https://staging-media.toyota.com.ar/6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 115420,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"url": "https://staging-media.toyota.com.ar/08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"url": "https://staging-media.toyota.com.ar/8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"url": "https://staging-media.toyota.com.ar/c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "279f410d-fd4f-4136-8300-a95939829d09.png",
														"url": "https://staging-media.toyota.com.ar/279f410d-fd4f-4136-8300-a95939829d09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"url": "https://staging-media.toyota.com.ar/ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"url": "https://staging-media.toyota.com.ar/dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "36252e80-9d05-4cf6-9afa-0f0f8911367e.png",
														"url": "https://staging-media.toyota.com.ar/36252e80-9d05-4cf6-9afa-0f0f8911367e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d067abf-15e2-4a1c-8fdc-59417d2e83cd.png",
														"url": "https://staging-media.toyota.com.ar/9d067abf-15e2-4a1c-8fdc-59417d2e83cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "e9a974bc-a6d3-4c1c-8edc-d0ec2344e1f1.png",
														"url": "https://staging-media.toyota.com.ar/e9a974bc-a6d3-4c1c-8edc-d0ec2344e1f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "f07c540d-2739-4893-a51a-2e3b4896d22b.png",
														"url": "https://staging-media.toyota.com.ar/f07c540d-2739-4893-a51a-2e3b4896d22b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "8f23a69a-5a26-4c0c-8386-0a64435b2c9e.png",
														"url": "https://staging-media.toyota.com.ar/8f23a69a-5a26-4c0c-8386-0a64435b2c9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "451324ec-1821-4a4c-bc7d-954806aa0f91.png",
														"url": "https://staging-media.toyota.com.ar/451324ec-1821-4a4c-bc7d-954806aa0f91.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "f05e98aa-56aa-47c3-83cb-c7f04cc5723c.png",
														"url": "https://staging-media.toyota.com.ar/f05e98aa-56aa-47c3-83cb-c7f04cc5723c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "1dcb1e93-1207-4a98-84e1-d3a33bd61487.png",
														"url": "https://staging-media.toyota.com.ar/1dcb1e93-1207-4a98-84e1-d3a33bd61487.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "5595ff77-9830-4468-bd0f-f6a4c716ebbc.png",
														"url": "https://staging-media.toyota.com.ar/5595ff77-9830-4468-bd0f-f6a4c716ebbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "fcf47edc-be25-47b7-b856-c99eab2fe201.png",
														"url": "https://staging-media.toyota.com.ar/fcf47edc-be25-47b7-b856-c99eab2fe201.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "acc07611-ac18-4691-a159-a3e59917d981.png",
														"url": "https://staging-media.toyota.com.ar/acc07611-ac18-4691-a159-a3e59917d981.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "f4e62ad8-93c1-431f-9047-b430182240c1.png",
														"url": "https://staging-media.toyota.com.ar/f4e62ad8-93c1-431f-9047-b430182240c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"url": "https://staging-media.toyota.com.ar/d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "2",
														"path": "6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"url": "https://staging-media.toyota.com.ar/6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 115420,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"url": "https://staging-media.toyota.com.ar/08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"url": "https://staging-media.toyota.com.ar/8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"url": "https://staging-media.toyota.com.ar/c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "279f410d-fd4f-4136-8300-a95939829d09.png",
														"url": "https://staging-media.toyota.com.ar/279f410d-fd4f-4136-8300-a95939829d09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"url": "https://staging-media.toyota.com.ar/ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"url": "https://staging-media.toyota.com.ar/dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "36252e80-9d05-4cf6-9afa-0f0f8911367e.png",
														"url": "https://staging-media.toyota.com.ar/36252e80-9d05-4cf6-9afa-0f0f8911367e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d067abf-15e2-4a1c-8fdc-59417d2e83cd.png",
														"url": "https://staging-media.toyota.com.ar/9d067abf-15e2-4a1c-8fdc-59417d2e83cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "e9a974bc-a6d3-4c1c-8edc-d0ec2344e1f1.png",
														"url": "https://staging-media.toyota.com.ar/e9a974bc-a6d3-4c1c-8edc-d0ec2344e1f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "f07c540d-2739-4893-a51a-2e3b4896d22b.png",
														"url": "https://staging-media.toyota.com.ar/f07c540d-2739-4893-a51a-2e3b4896d22b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "8f23a69a-5a26-4c0c-8386-0a64435b2c9e.png",
														"url": "https://staging-media.toyota.com.ar/8f23a69a-5a26-4c0c-8386-0a64435b2c9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "451324ec-1821-4a4c-bc7d-954806aa0f91.png",
														"url": "https://staging-media.toyota.com.ar/451324ec-1821-4a4c-bc7d-954806aa0f91.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "f05e98aa-56aa-47c3-83cb-c7f04cc5723c.png",
														"url": "https://staging-media.toyota.com.ar/f05e98aa-56aa-47c3-83cb-c7f04cc5723c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "1dcb1e93-1207-4a98-84e1-d3a33bd61487.png",
														"url": "https://staging-media.toyota.com.ar/1dcb1e93-1207-4a98-84e1-d3a33bd61487.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "5595ff77-9830-4468-bd0f-f6a4c716ebbc.png",
														"url": "https://staging-media.toyota.com.ar/5595ff77-9830-4468-bd0f-f6a4c716ebbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "fcf47edc-be25-47b7-b856-c99eab2fe201.png",
														"url": "https://staging-media.toyota.com.ar/fcf47edc-be25-47b7-b856-c99eab2fe201.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "acc07611-ac18-4691-a159-a3e59917d981.png",
														"url": "https://staging-media.toyota.com.ar/acc07611-ac18-4691-a159-a3e59917d981.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "f4e62ad8-93c1-431f-9047-b430182240c1.png",
														"url": "https://staging-media.toyota.com.ar/f4e62ad8-93c1-431f-9047-b430182240c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"url": "https://staging-media.toyota.com.ar/d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "2",
														"path": "6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"url": "https://staging-media.toyota.com.ar/6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 115420,
										"offers": null,
										"color": {
											"name": "Azul Titã (8V5) ",
											"code": "8V5",
											"rgb": "#3D4954"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"url": "https://staging-media.toyota.com.ar/08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"url": "https://staging-media.toyota.com.ar/8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"url": "https://staging-media.toyota.com.ar/c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "279f410d-fd4f-4136-8300-a95939829d09.png",
														"url": "https://staging-media.toyota.com.ar/279f410d-fd4f-4136-8300-a95939829d09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"url": "https://staging-media.toyota.com.ar/ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"url": "https://staging-media.toyota.com.ar/dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "36252e80-9d05-4cf6-9afa-0f0f8911367e.png",
														"url": "https://staging-media.toyota.com.ar/36252e80-9d05-4cf6-9afa-0f0f8911367e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d067abf-15e2-4a1c-8fdc-59417d2e83cd.png",
														"url": "https://staging-media.toyota.com.ar/9d067abf-15e2-4a1c-8fdc-59417d2e83cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "e9a974bc-a6d3-4c1c-8edc-d0ec2344e1f1.png",
														"url": "https://staging-media.toyota.com.ar/e9a974bc-a6d3-4c1c-8edc-d0ec2344e1f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "f07c540d-2739-4893-a51a-2e3b4896d22b.png",
														"url": "https://staging-media.toyota.com.ar/f07c540d-2739-4893-a51a-2e3b4896d22b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "8f23a69a-5a26-4c0c-8386-0a64435b2c9e.png",
														"url": "https://staging-media.toyota.com.ar/8f23a69a-5a26-4c0c-8386-0a64435b2c9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "451324ec-1821-4a4c-bc7d-954806aa0f91.png",
														"url": "https://staging-media.toyota.com.ar/451324ec-1821-4a4c-bc7d-954806aa0f91.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "f05e98aa-56aa-47c3-83cb-c7f04cc5723c.png",
														"url": "https://staging-media.toyota.com.ar/f05e98aa-56aa-47c3-83cb-c7f04cc5723c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "1dcb1e93-1207-4a98-84e1-d3a33bd61487.png",
														"url": "https://staging-media.toyota.com.ar/1dcb1e93-1207-4a98-84e1-d3a33bd61487.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "5595ff77-9830-4468-bd0f-f6a4c716ebbc.png",
														"url": "https://staging-media.toyota.com.ar/5595ff77-9830-4468-bd0f-f6a4c716ebbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "fcf47edc-be25-47b7-b856-c99eab2fe201.png",
														"url": "https://staging-media.toyota.com.ar/fcf47edc-be25-47b7-b856-c99eab2fe201.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "acc07611-ac18-4691-a159-a3e59917d981.png",
														"url": "https://staging-media.toyota.com.ar/acc07611-ac18-4691-a159-a3e59917d981.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "f4e62ad8-93c1-431f-9047-b430182240c1.png",
														"url": "https://staging-media.toyota.com.ar/f4e62ad8-93c1-431f-9047-b430182240c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"url": "https://staging-media.toyota.com.ar/d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "2",
														"path": "6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"url": "https://staging-media.toyota.com.ar/6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 115720,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"url": "https://staging-media.toyota.com.ar/08a29c63-3820-445a-a7a1-374ad8650fb1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"url": "https://staging-media.toyota.com.ar/8932d857-18a9-42e6-9cc4-a9622c505d50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"url": "https://staging-media.toyota.com.ar/a0145785-c9d7-491e-b3f4-8b042d1262bc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"url": "https://staging-media.toyota.com.ar/c8e55a6b-48e6-478b-8929-18ee8f41bfbf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "279f410d-fd4f-4136-8300-a95939829d09.png",
														"url": "https://staging-media.toyota.com.ar/279f410d-fd4f-4136-8300-a95939829d09.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"url": "https://staging-media.toyota.com.ar/ddb09618-8cbd-4af7-abc0-aecbece217b1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"url": "https://staging-media.toyota.com.ar/dc13421e-46dd-4dd7-8ab3-e5590e18d721.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "36252e80-9d05-4cf6-9afa-0f0f8911367e.png",
														"url": "https://staging-media.toyota.com.ar/36252e80-9d05-4cf6-9afa-0f0f8911367e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "9d067abf-15e2-4a1c-8fdc-59417d2e83cd.png",
														"url": "https://staging-media.toyota.com.ar/9d067abf-15e2-4a1c-8fdc-59417d2e83cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "e9a974bc-a6d3-4c1c-8edc-d0ec2344e1f1.png",
														"url": "https://staging-media.toyota.com.ar/e9a974bc-a6d3-4c1c-8edc-d0ec2344e1f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "f07c540d-2739-4893-a51a-2e3b4896d22b.png",
														"url": "https://staging-media.toyota.com.ar/f07c540d-2739-4893-a51a-2e3b4896d22b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "8f23a69a-5a26-4c0c-8386-0a64435b2c9e.png",
														"url": "https://staging-media.toyota.com.ar/8f23a69a-5a26-4c0c-8386-0a64435b2c9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "451324ec-1821-4a4c-bc7d-954806aa0f91.png",
														"url": "https://staging-media.toyota.com.ar/451324ec-1821-4a4c-bc7d-954806aa0f91.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "f05e98aa-56aa-47c3-83cb-c7f04cc5723c.png",
														"url": "https://staging-media.toyota.com.ar/f05e98aa-56aa-47c3-83cb-c7f04cc5723c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "1dcb1e93-1207-4a98-84e1-d3a33bd61487.png",
														"url": "https://staging-media.toyota.com.ar/1dcb1e93-1207-4a98-84e1-d3a33bd61487.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "5595ff77-9830-4468-bd0f-f6a4c716ebbc.png",
														"url": "https://staging-media.toyota.com.ar/5595ff77-9830-4468-bd0f-f6a4c716ebbc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "fcf47edc-be25-47b7-b856-c99eab2fe201.png",
														"url": "https://staging-media.toyota.com.ar/fcf47edc-be25-47b7-b856-c99eab2fe201.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "acc07611-ac18-4691-a159-a3e59917d981.png",
														"url": "https://staging-media.toyota.com.ar/acc07611-ac18-4691-a159-a3e59917d981.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "f4e62ad8-93c1-431f-9047-b430182240c1.png",
														"url": "https://staging-media.toyota.com.ar/f4e62ad8-93c1-431f-9047-b430182240c1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"url": "https://staging-media.toyota.com.ar/d1d0a946-a7dc-44bd-a06e-81e0dcd4cdd6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "2",
														"path": "6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"url": "https://staging-media.toyota.com.ar/6778fec7-381e-4a50-a332-20b73bc66dc4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "XLS",
						"id": "d8b1d823-4aab-446a-aed6-389459e47d3c",
						"order": 3,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 121990,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Yaris Sedã",
														"path": "3b22552f-cede-4a8f-9994-c3a0d6743525.png",
														"url": "https://staging-media.toyota.com.ar/3b22552f-cede-4a8f-9994-c3a0d6743525.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Yaris Sedã",
														"path": "a6fe3289-0288-4804-8f96-29c1b7ac0565.jpeg",
														"url": "https://staging-media.toyota.com.ar/a6fe3289-0288-4804-8f96-29c1b7ac0565.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Yaris Sedã",
														"path": "642195cf-6655-4ced-8b50-3ab985a076c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/642195cf-6655-4ced-8b50-3ab985a076c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "95f34f20-1dac-45a5-9d20-9971556076a8.jpeg",
														"url": "https://staging-media.toyota.com.ar/95f34f20-1dac-45a5-9d20-9971556076a8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "c0ac3a6d-3956-4fb0-9cab-0d98b0ec5512.png",
														"url": "https://staging-media.toyota.com.ar/c0ac3a6d-3956-4fb0-9cab-0d98b0ec5512.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "039cc846-445b-4174-b08b-8f7ab2eef4e7.png",
														"url": "https://staging-media.toyota.com.ar/039cc846-445b-4174-b08b-8f7ab2eef4e7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "9f77a8db-7230-4790-b0aa-779820b2b7b5.png",
														"url": "https://staging-media.toyota.com.ar/9f77a8db-7230-4790-b0aa-779820b2b7b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "7c23b155-413b-4c4d-aa13-8fa74ad7d6d2.png",
														"url": "https://staging-media.toyota.com.ar/7c23b155-413b-4c4d-aa13-8fa74ad7d6d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "d673ebbf-81e0-4bbd-8e93-6e61639e5029.png",
														"url": "https://staging-media.toyota.com.ar/d673ebbf-81e0-4bbd-8e93-6e61639e5029.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "805aaf67-b621-4af1-a869-e1670f422175.jpeg",
														"url": "https://staging-media.toyota.com.ar/805aaf67-b621-4af1-a869-e1670f422175.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Externo",
														"path": "9684beef-4ff1-44c8-9a28-299ed2039121.jpeg",
														"url": "https://staging-media.toyota.com.ar/9684beef-4ff1-44c8-9a28-299ed2039121.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "637b997e-4ee1-4937-a8a4-d75da7c067de.jpeg",
														"url": "https://staging-media.toyota.com.ar/637b997e-4ee1-4937-a8a4-d75da7c067de.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "811635fa-4d2d-4000-80a1-a2e92538bc72.jpeg",
														"url": "https://staging-media.toyota.com.ar/811635fa-4d2d-4000-80a1-a2e92538bc72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "1af23665-33e6-4f13-b2ed-f99e5bfa3499.jpeg",
														"url": "https://staging-media.toyota.com.ar/1af23665-33e6-4f13-b2ed-f99e5bfa3499.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "bce36b26-b93b-4113-886a-b2ba63eed13e.jpeg",
														"url": "https://staging-media.toyota.com.ar/bce36b26-b93b-4113-886a-b2ba63eed13e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "822c5ce0-fc2a-4123-a8b7-ed3a09cc93b7.png",
														"url": "https://staging-media.toyota.com.ar/822c5ce0-fc2a-4123-a8b7-ed3a09cc93b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "06c736d0-decd-42ac-8c05-d744c8074eaf.png",
														"url": "https://staging-media.toyota.com.ar/06c736d0-decd-42ac-8c05-d744c8074eaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "88d37ba4-d456-445e-b45c-7a086782d22d.png",
														"url": "https://staging-media.toyota.com.ar/88d37ba4-d456-445e-b45c-7a086782d22d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "38e722c9-6f47-4081-8088-8988b8cef231.png",
														"url": "https://staging-media.toyota.com.ar/38e722c9-6f47-4081-8088-8988b8cef231.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "40c3bdfb-920d-46e4-9b9b-bbc90153eefc.png",
														"url": "https://staging-media.toyota.com.ar/40c3bdfb-920d-46e4-9b9b-bbc90153eefc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "fb900d25-5fcc-4376-9826-d796f52ac884.png",
														"url": "https://staging-media.toyota.com.ar/fb900d25-5fcc-4376-9826-d796f52ac884.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "8425fb51-880c-4b2a-826b-b2474f5e7c5e.png",
														"url": "https://staging-media.toyota.com.ar/8425fb51-880c-4b2a-826b-b2474f5e7c5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "f7397986-4e34-42b4-9d3b-f2c92bd5ef50.png",
														"url": "https://staging-media.toyota.com.ar/f7397986-4e34-42b4-9d3b-f2c92bd5ef50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "9ae90b43-e8d2-4f4e-a46c-919d5472ed3f.png",
														"url": "https://staging-media.toyota.com.ar/9ae90b43-e8d2-4f4e-a46c-919d5472ed3f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "dc4c2fac-7afe-4ba7-9900-792cb5f05687.png",
														"url": "https://staging-media.toyota.com.ar/dc4c2fac-7afe-4ba7-9900-792cb5f05687.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1f7a9895-2f0b-4df2-a1c7-e1447e754603.png",
														"url": "https://staging-media.toyota.com.ar/1f7a9895-2f0b-4df2-a1c7-e1447e754603.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "0481d5a4-1388-4df4-84b3-3b629d9790b6.png",
														"url": "https://staging-media.toyota.com.ar/0481d5a4-1388-4df4-84b3-3b629d9790b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "3b4ca91a-0871-433b-9dbd-1d11edfaa5e3.png",
														"url": "https://staging-media.toyota.com.ar/3b4ca91a-0871-433b-9dbd-1d11edfaa5e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "56e1977e-538e-4104-b6e8-8b14382cc87f.png",
														"url": "https://staging-media.toyota.com.ar/56e1977e-538e-4104-b6e8-8b14382cc87f.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 123620,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Yaris Sedã",
														"path": "3b22552f-cede-4a8f-9994-c3a0d6743525.png",
														"url": "https://staging-media.toyota.com.ar/3b22552f-cede-4a8f-9994-c3a0d6743525.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Yaris Sedã",
														"path": "a6fe3289-0288-4804-8f96-29c1b7ac0565.jpeg",
														"url": "https://staging-media.toyota.com.ar/a6fe3289-0288-4804-8f96-29c1b7ac0565.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Yaris Sedã",
														"path": "642195cf-6655-4ced-8b50-3ab985a076c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/642195cf-6655-4ced-8b50-3ab985a076c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "95f34f20-1dac-45a5-9d20-9971556076a8.jpeg",
														"url": "https://staging-media.toyota.com.ar/95f34f20-1dac-45a5-9d20-9971556076a8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "c0ac3a6d-3956-4fb0-9cab-0d98b0ec5512.png",
														"url": "https://staging-media.toyota.com.ar/c0ac3a6d-3956-4fb0-9cab-0d98b0ec5512.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "039cc846-445b-4174-b08b-8f7ab2eef4e7.png",
														"url": "https://staging-media.toyota.com.ar/039cc846-445b-4174-b08b-8f7ab2eef4e7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "9f77a8db-7230-4790-b0aa-779820b2b7b5.png",
														"url": "https://staging-media.toyota.com.ar/9f77a8db-7230-4790-b0aa-779820b2b7b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "7c23b155-413b-4c4d-aa13-8fa74ad7d6d2.png",
														"url": "https://staging-media.toyota.com.ar/7c23b155-413b-4c4d-aa13-8fa74ad7d6d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "d673ebbf-81e0-4bbd-8e93-6e61639e5029.png",
														"url": "https://staging-media.toyota.com.ar/d673ebbf-81e0-4bbd-8e93-6e61639e5029.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "805aaf67-b621-4af1-a869-e1670f422175.jpeg",
														"url": "https://staging-media.toyota.com.ar/805aaf67-b621-4af1-a869-e1670f422175.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Externo",
														"path": "9684beef-4ff1-44c8-9a28-299ed2039121.jpeg",
														"url": "https://staging-media.toyota.com.ar/9684beef-4ff1-44c8-9a28-299ed2039121.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "637b997e-4ee1-4937-a8a4-d75da7c067de.jpeg",
														"url": "https://staging-media.toyota.com.ar/637b997e-4ee1-4937-a8a4-d75da7c067de.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "811635fa-4d2d-4000-80a1-a2e92538bc72.jpeg",
														"url": "https://staging-media.toyota.com.ar/811635fa-4d2d-4000-80a1-a2e92538bc72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "1af23665-33e6-4f13-b2ed-f99e5bfa3499.jpeg",
														"url": "https://staging-media.toyota.com.ar/1af23665-33e6-4f13-b2ed-f99e5bfa3499.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "bce36b26-b93b-4113-886a-b2ba63eed13e.jpeg",
														"url": "https://staging-media.toyota.com.ar/bce36b26-b93b-4113-886a-b2ba63eed13e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "822c5ce0-fc2a-4123-a8b7-ed3a09cc93b7.png",
														"url": "https://staging-media.toyota.com.ar/822c5ce0-fc2a-4123-a8b7-ed3a09cc93b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "06c736d0-decd-42ac-8c05-d744c8074eaf.png",
														"url": "https://staging-media.toyota.com.ar/06c736d0-decd-42ac-8c05-d744c8074eaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "88d37ba4-d456-445e-b45c-7a086782d22d.png",
														"url": "https://staging-media.toyota.com.ar/88d37ba4-d456-445e-b45c-7a086782d22d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "38e722c9-6f47-4081-8088-8988b8cef231.png",
														"url": "https://staging-media.toyota.com.ar/38e722c9-6f47-4081-8088-8988b8cef231.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "40c3bdfb-920d-46e4-9b9b-bbc90153eefc.png",
														"url": "https://staging-media.toyota.com.ar/40c3bdfb-920d-46e4-9b9b-bbc90153eefc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "fb900d25-5fcc-4376-9826-d796f52ac884.png",
														"url": "https://staging-media.toyota.com.ar/fb900d25-5fcc-4376-9826-d796f52ac884.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "8425fb51-880c-4b2a-826b-b2474f5e7c5e.png",
														"url": "https://staging-media.toyota.com.ar/8425fb51-880c-4b2a-826b-b2474f5e7c5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "f7397986-4e34-42b4-9d3b-f2c92bd5ef50.png",
														"url": "https://staging-media.toyota.com.ar/f7397986-4e34-42b4-9d3b-f2c92bd5ef50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "9ae90b43-e8d2-4f4e-a46c-919d5472ed3f.png",
														"url": "https://staging-media.toyota.com.ar/9ae90b43-e8d2-4f4e-a46c-919d5472ed3f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "dc4c2fac-7afe-4ba7-9900-792cb5f05687.png",
														"url": "https://staging-media.toyota.com.ar/dc4c2fac-7afe-4ba7-9900-792cb5f05687.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1f7a9895-2f0b-4df2-a1c7-e1447e754603.png",
														"url": "https://staging-media.toyota.com.ar/1f7a9895-2f0b-4df2-a1c7-e1447e754603.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "0481d5a4-1388-4df4-84b3-3b629d9790b6.png",
														"url": "https://staging-media.toyota.com.ar/0481d5a4-1388-4df4-84b3-3b629d9790b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "3b4ca91a-0871-433b-9dbd-1d11edfaa5e3.png",
														"url": "https://staging-media.toyota.com.ar/3b4ca91a-0871-433b-9dbd-1d11edfaa5e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "56e1977e-538e-4104-b6e8-8b14382cc87f.png",
														"url": "https://staging-media.toyota.com.ar/56e1977e-538e-4104-b6e8-8b14382cc87f.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 123620,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Yaris Sedã",
														"path": "3b22552f-cede-4a8f-9994-c3a0d6743525.png",
														"url": "https://staging-media.toyota.com.ar/3b22552f-cede-4a8f-9994-c3a0d6743525.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Yaris Sedã",
														"path": "a6fe3289-0288-4804-8f96-29c1b7ac0565.jpeg",
														"url": "https://staging-media.toyota.com.ar/a6fe3289-0288-4804-8f96-29c1b7ac0565.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Yaris Sedã",
														"path": "642195cf-6655-4ced-8b50-3ab985a076c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/642195cf-6655-4ced-8b50-3ab985a076c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "95f34f20-1dac-45a5-9d20-9971556076a8.jpeg",
														"url": "https://staging-media.toyota.com.ar/95f34f20-1dac-45a5-9d20-9971556076a8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "c0ac3a6d-3956-4fb0-9cab-0d98b0ec5512.png",
														"url": "https://staging-media.toyota.com.ar/c0ac3a6d-3956-4fb0-9cab-0d98b0ec5512.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "039cc846-445b-4174-b08b-8f7ab2eef4e7.png",
														"url": "https://staging-media.toyota.com.ar/039cc846-445b-4174-b08b-8f7ab2eef4e7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "9f77a8db-7230-4790-b0aa-779820b2b7b5.png",
														"url": "https://staging-media.toyota.com.ar/9f77a8db-7230-4790-b0aa-779820b2b7b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "7c23b155-413b-4c4d-aa13-8fa74ad7d6d2.png",
														"url": "https://staging-media.toyota.com.ar/7c23b155-413b-4c4d-aa13-8fa74ad7d6d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "d673ebbf-81e0-4bbd-8e93-6e61639e5029.png",
														"url": "https://staging-media.toyota.com.ar/d673ebbf-81e0-4bbd-8e93-6e61639e5029.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "805aaf67-b621-4af1-a869-e1670f422175.jpeg",
														"url": "https://staging-media.toyota.com.ar/805aaf67-b621-4af1-a869-e1670f422175.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Externo",
														"path": "9684beef-4ff1-44c8-9a28-299ed2039121.jpeg",
														"url": "https://staging-media.toyota.com.ar/9684beef-4ff1-44c8-9a28-299ed2039121.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "637b997e-4ee1-4937-a8a4-d75da7c067de.jpeg",
														"url": "https://staging-media.toyota.com.ar/637b997e-4ee1-4937-a8a4-d75da7c067de.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "811635fa-4d2d-4000-80a1-a2e92538bc72.jpeg",
														"url": "https://staging-media.toyota.com.ar/811635fa-4d2d-4000-80a1-a2e92538bc72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "1af23665-33e6-4f13-b2ed-f99e5bfa3499.jpeg",
														"url": "https://staging-media.toyota.com.ar/1af23665-33e6-4f13-b2ed-f99e5bfa3499.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "bce36b26-b93b-4113-886a-b2ba63eed13e.jpeg",
														"url": "https://staging-media.toyota.com.ar/bce36b26-b93b-4113-886a-b2ba63eed13e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "822c5ce0-fc2a-4123-a8b7-ed3a09cc93b7.png",
														"url": "https://staging-media.toyota.com.ar/822c5ce0-fc2a-4123-a8b7-ed3a09cc93b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "06c736d0-decd-42ac-8c05-d744c8074eaf.png",
														"url": "https://staging-media.toyota.com.ar/06c736d0-decd-42ac-8c05-d744c8074eaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "88d37ba4-d456-445e-b45c-7a086782d22d.png",
														"url": "https://staging-media.toyota.com.ar/88d37ba4-d456-445e-b45c-7a086782d22d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "38e722c9-6f47-4081-8088-8988b8cef231.png",
														"url": "https://staging-media.toyota.com.ar/38e722c9-6f47-4081-8088-8988b8cef231.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "40c3bdfb-920d-46e4-9b9b-bbc90153eefc.png",
														"url": "https://staging-media.toyota.com.ar/40c3bdfb-920d-46e4-9b9b-bbc90153eefc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "fb900d25-5fcc-4376-9826-d796f52ac884.png",
														"url": "https://staging-media.toyota.com.ar/fb900d25-5fcc-4376-9826-d796f52ac884.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "8425fb51-880c-4b2a-826b-b2474f5e7c5e.png",
														"url": "https://staging-media.toyota.com.ar/8425fb51-880c-4b2a-826b-b2474f5e7c5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "f7397986-4e34-42b4-9d3b-f2c92bd5ef50.png",
														"url": "https://staging-media.toyota.com.ar/f7397986-4e34-42b4-9d3b-f2c92bd5ef50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "9ae90b43-e8d2-4f4e-a46c-919d5472ed3f.png",
														"url": "https://staging-media.toyota.com.ar/9ae90b43-e8d2-4f4e-a46c-919d5472ed3f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "dc4c2fac-7afe-4ba7-9900-792cb5f05687.png",
														"url": "https://staging-media.toyota.com.ar/dc4c2fac-7afe-4ba7-9900-792cb5f05687.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1f7a9895-2f0b-4df2-a1c7-e1447e754603.png",
														"url": "https://staging-media.toyota.com.ar/1f7a9895-2f0b-4df2-a1c7-e1447e754603.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "0481d5a4-1388-4df4-84b3-3b629d9790b6.png",
														"url": "https://staging-media.toyota.com.ar/0481d5a4-1388-4df4-84b3-3b629d9790b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "3b4ca91a-0871-433b-9dbd-1d11edfaa5e3.png",
														"url": "https://staging-media.toyota.com.ar/3b4ca91a-0871-433b-9dbd-1d11edfaa5e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "56e1977e-538e-4104-b6e8-8b14382cc87f.png",
														"url": "https://staging-media.toyota.com.ar/56e1977e-538e-4104-b6e8-8b14382cc87f.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 123620,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Yaris Sedã",
														"path": "3b22552f-cede-4a8f-9994-c3a0d6743525.png",
														"url": "https://staging-media.toyota.com.ar/3b22552f-cede-4a8f-9994-c3a0d6743525.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Yaris Sedã",
														"path": "a6fe3289-0288-4804-8f96-29c1b7ac0565.jpeg",
														"url": "https://staging-media.toyota.com.ar/a6fe3289-0288-4804-8f96-29c1b7ac0565.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Yaris Sedã",
														"path": "642195cf-6655-4ced-8b50-3ab985a076c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/642195cf-6655-4ced-8b50-3ab985a076c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "95f34f20-1dac-45a5-9d20-9971556076a8.jpeg",
														"url": "https://staging-media.toyota.com.ar/95f34f20-1dac-45a5-9d20-9971556076a8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "c0ac3a6d-3956-4fb0-9cab-0d98b0ec5512.png",
														"url": "https://staging-media.toyota.com.ar/c0ac3a6d-3956-4fb0-9cab-0d98b0ec5512.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "039cc846-445b-4174-b08b-8f7ab2eef4e7.png",
														"url": "https://staging-media.toyota.com.ar/039cc846-445b-4174-b08b-8f7ab2eef4e7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "9f77a8db-7230-4790-b0aa-779820b2b7b5.png",
														"url": "https://staging-media.toyota.com.ar/9f77a8db-7230-4790-b0aa-779820b2b7b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "7c23b155-413b-4c4d-aa13-8fa74ad7d6d2.png",
														"url": "https://staging-media.toyota.com.ar/7c23b155-413b-4c4d-aa13-8fa74ad7d6d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "d673ebbf-81e0-4bbd-8e93-6e61639e5029.png",
														"url": "https://staging-media.toyota.com.ar/d673ebbf-81e0-4bbd-8e93-6e61639e5029.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "805aaf67-b621-4af1-a869-e1670f422175.jpeg",
														"url": "https://staging-media.toyota.com.ar/805aaf67-b621-4af1-a869-e1670f422175.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Externo",
														"path": "9684beef-4ff1-44c8-9a28-299ed2039121.jpeg",
														"url": "https://staging-media.toyota.com.ar/9684beef-4ff1-44c8-9a28-299ed2039121.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "637b997e-4ee1-4937-a8a4-d75da7c067de.jpeg",
														"url": "https://staging-media.toyota.com.ar/637b997e-4ee1-4937-a8a4-d75da7c067de.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "811635fa-4d2d-4000-80a1-a2e92538bc72.jpeg",
														"url": "https://staging-media.toyota.com.ar/811635fa-4d2d-4000-80a1-a2e92538bc72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "1af23665-33e6-4f13-b2ed-f99e5bfa3499.jpeg",
														"url": "https://staging-media.toyota.com.ar/1af23665-33e6-4f13-b2ed-f99e5bfa3499.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "bce36b26-b93b-4113-886a-b2ba63eed13e.jpeg",
														"url": "https://staging-media.toyota.com.ar/bce36b26-b93b-4113-886a-b2ba63eed13e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "822c5ce0-fc2a-4123-a8b7-ed3a09cc93b7.png",
														"url": "https://staging-media.toyota.com.ar/822c5ce0-fc2a-4123-a8b7-ed3a09cc93b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "06c736d0-decd-42ac-8c05-d744c8074eaf.png",
														"url": "https://staging-media.toyota.com.ar/06c736d0-decd-42ac-8c05-d744c8074eaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "88d37ba4-d456-445e-b45c-7a086782d22d.png",
														"url": "https://staging-media.toyota.com.ar/88d37ba4-d456-445e-b45c-7a086782d22d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "38e722c9-6f47-4081-8088-8988b8cef231.png",
														"url": "https://staging-media.toyota.com.ar/38e722c9-6f47-4081-8088-8988b8cef231.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "40c3bdfb-920d-46e4-9b9b-bbc90153eefc.png",
														"url": "https://staging-media.toyota.com.ar/40c3bdfb-920d-46e4-9b9b-bbc90153eefc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "fb900d25-5fcc-4376-9826-d796f52ac884.png",
														"url": "https://staging-media.toyota.com.ar/fb900d25-5fcc-4376-9826-d796f52ac884.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "8425fb51-880c-4b2a-826b-b2474f5e7c5e.png",
														"url": "https://staging-media.toyota.com.ar/8425fb51-880c-4b2a-826b-b2474f5e7c5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "f7397986-4e34-42b4-9d3b-f2c92bd5ef50.png",
														"url": "https://staging-media.toyota.com.ar/f7397986-4e34-42b4-9d3b-f2c92bd5ef50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "9ae90b43-e8d2-4f4e-a46c-919d5472ed3f.png",
														"url": "https://staging-media.toyota.com.ar/9ae90b43-e8d2-4f4e-a46c-919d5472ed3f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "dc4c2fac-7afe-4ba7-9900-792cb5f05687.png",
														"url": "https://staging-media.toyota.com.ar/dc4c2fac-7afe-4ba7-9900-792cb5f05687.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1f7a9895-2f0b-4df2-a1c7-e1447e754603.png",
														"url": "https://staging-media.toyota.com.ar/1f7a9895-2f0b-4df2-a1c7-e1447e754603.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "0481d5a4-1388-4df4-84b3-3b629d9790b6.png",
														"url": "https://staging-media.toyota.com.ar/0481d5a4-1388-4df4-84b3-3b629d9790b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "3b4ca91a-0871-433b-9dbd-1d11edfaa5e3.png",
														"url": "https://staging-media.toyota.com.ar/3b4ca91a-0871-433b-9dbd-1d11edfaa5e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "56e1977e-538e-4104-b6e8-8b14382cc87f.png",
														"url": "https://staging-media.toyota.com.ar/56e1977e-538e-4104-b6e8-8b14382cc87f.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 123620,
										"offers": null,
										"color": {
											"name": "Azul Titã (8V5) ",
											"code": "8V5",
											"rgb": "#3D4954"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Yaris Sedã",
														"path": "3b22552f-cede-4a8f-9994-c3a0d6743525.png",
														"url": "https://staging-media.toyota.com.ar/3b22552f-cede-4a8f-9994-c3a0d6743525.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Yaris Sedã",
														"path": "a6fe3289-0288-4804-8f96-29c1b7ac0565.jpeg",
														"url": "https://staging-media.toyota.com.ar/a6fe3289-0288-4804-8f96-29c1b7ac0565.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Yaris Sedã",
														"path": "642195cf-6655-4ced-8b50-3ab985a076c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/642195cf-6655-4ced-8b50-3ab985a076c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "95f34f20-1dac-45a5-9d20-9971556076a8.jpeg",
														"url": "https://staging-media.toyota.com.ar/95f34f20-1dac-45a5-9d20-9971556076a8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "c0ac3a6d-3956-4fb0-9cab-0d98b0ec5512.png",
														"url": "https://staging-media.toyota.com.ar/c0ac3a6d-3956-4fb0-9cab-0d98b0ec5512.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "039cc846-445b-4174-b08b-8f7ab2eef4e7.png",
														"url": "https://staging-media.toyota.com.ar/039cc846-445b-4174-b08b-8f7ab2eef4e7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "9f77a8db-7230-4790-b0aa-779820b2b7b5.png",
														"url": "https://staging-media.toyota.com.ar/9f77a8db-7230-4790-b0aa-779820b2b7b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "7c23b155-413b-4c4d-aa13-8fa74ad7d6d2.png",
														"url": "https://staging-media.toyota.com.ar/7c23b155-413b-4c4d-aa13-8fa74ad7d6d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "d673ebbf-81e0-4bbd-8e93-6e61639e5029.png",
														"url": "https://staging-media.toyota.com.ar/d673ebbf-81e0-4bbd-8e93-6e61639e5029.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "805aaf67-b621-4af1-a869-e1670f422175.jpeg",
														"url": "https://staging-media.toyota.com.ar/805aaf67-b621-4af1-a869-e1670f422175.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Externo",
														"path": "9684beef-4ff1-44c8-9a28-299ed2039121.jpeg",
														"url": "https://staging-media.toyota.com.ar/9684beef-4ff1-44c8-9a28-299ed2039121.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "637b997e-4ee1-4937-a8a4-d75da7c067de.jpeg",
														"url": "https://staging-media.toyota.com.ar/637b997e-4ee1-4937-a8a4-d75da7c067de.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "811635fa-4d2d-4000-80a1-a2e92538bc72.jpeg",
														"url": "https://staging-media.toyota.com.ar/811635fa-4d2d-4000-80a1-a2e92538bc72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "1af23665-33e6-4f13-b2ed-f99e5bfa3499.jpeg",
														"url": "https://staging-media.toyota.com.ar/1af23665-33e6-4f13-b2ed-f99e5bfa3499.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "bce36b26-b93b-4113-886a-b2ba63eed13e.jpeg",
														"url": "https://staging-media.toyota.com.ar/bce36b26-b93b-4113-886a-b2ba63eed13e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "822c5ce0-fc2a-4123-a8b7-ed3a09cc93b7.png",
														"url": "https://staging-media.toyota.com.ar/822c5ce0-fc2a-4123-a8b7-ed3a09cc93b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "06c736d0-decd-42ac-8c05-d744c8074eaf.png",
														"url": "https://staging-media.toyota.com.ar/06c736d0-decd-42ac-8c05-d744c8074eaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "88d37ba4-d456-445e-b45c-7a086782d22d.png",
														"url": "https://staging-media.toyota.com.ar/88d37ba4-d456-445e-b45c-7a086782d22d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "38e722c9-6f47-4081-8088-8988b8cef231.png",
														"url": "https://staging-media.toyota.com.ar/38e722c9-6f47-4081-8088-8988b8cef231.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "40c3bdfb-920d-46e4-9b9b-bbc90153eefc.png",
														"url": "https://staging-media.toyota.com.ar/40c3bdfb-920d-46e4-9b9b-bbc90153eefc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "fb900d25-5fcc-4376-9826-d796f52ac884.png",
														"url": "https://staging-media.toyota.com.ar/fb900d25-5fcc-4376-9826-d796f52ac884.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "8425fb51-880c-4b2a-826b-b2474f5e7c5e.png",
														"url": "https://staging-media.toyota.com.ar/8425fb51-880c-4b2a-826b-b2474f5e7c5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "f7397986-4e34-42b4-9d3b-f2c92bd5ef50.png",
														"url": "https://staging-media.toyota.com.ar/f7397986-4e34-42b4-9d3b-f2c92bd5ef50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "9ae90b43-e8d2-4f4e-a46c-919d5472ed3f.png",
														"url": "https://staging-media.toyota.com.ar/9ae90b43-e8d2-4f4e-a46c-919d5472ed3f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "dc4c2fac-7afe-4ba7-9900-792cb5f05687.png",
														"url": "https://staging-media.toyota.com.ar/dc4c2fac-7afe-4ba7-9900-792cb5f05687.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1f7a9895-2f0b-4df2-a1c7-e1447e754603.png",
														"url": "https://staging-media.toyota.com.ar/1f7a9895-2f0b-4df2-a1c7-e1447e754603.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "0481d5a4-1388-4df4-84b3-3b629d9790b6.png",
														"url": "https://staging-media.toyota.com.ar/0481d5a4-1388-4df4-84b3-3b629d9790b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "3b4ca91a-0871-433b-9dbd-1d11edfaa5e3.png",
														"url": "https://staging-media.toyota.com.ar/3b4ca91a-0871-433b-9dbd-1d11edfaa5e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "56e1977e-538e-4104-b6e8-8b14382cc87f.png",
														"url": "https://staging-media.toyota.com.ar/56e1977e-538e-4104-b6e8-8b14382cc87f.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 123920,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Yaris Sedã",
														"path": "3b22552f-cede-4a8f-9994-c3a0d6743525.png",
														"url": "https://staging-media.toyota.com.ar/3b22552f-cede-4a8f-9994-c3a0d6743525.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Yaris Sedã",
														"path": "a6fe3289-0288-4804-8f96-29c1b7ac0565.jpeg",
														"url": "https://staging-media.toyota.com.ar/a6fe3289-0288-4804-8f96-29c1b7ac0565.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Yaris Sedã",
														"path": "642195cf-6655-4ced-8b50-3ab985a076c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/642195cf-6655-4ced-8b50-3ab985a076c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "95f34f20-1dac-45a5-9d20-9971556076a8.jpeg",
														"url": "https://staging-media.toyota.com.ar/95f34f20-1dac-45a5-9d20-9971556076a8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "c0ac3a6d-3956-4fb0-9cab-0d98b0ec5512.png",
														"url": "https://staging-media.toyota.com.ar/c0ac3a6d-3956-4fb0-9cab-0d98b0ec5512.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "039cc846-445b-4174-b08b-8f7ab2eef4e7.png",
														"url": "https://staging-media.toyota.com.ar/039cc846-445b-4174-b08b-8f7ab2eef4e7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "9f77a8db-7230-4790-b0aa-779820b2b7b5.png",
														"url": "https://staging-media.toyota.com.ar/9f77a8db-7230-4790-b0aa-779820b2b7b5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "7c23b155-413b-4c4d-aa13-8fa74ad7d6d2.png",
														"url": "https://staging-media.toyota.com.ar/7c23b155-413b-4c4d-aa13-8fa74ad7d6d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Yaris Sedã",
														"path": "d673ebbf-81e0-4bbd-8e93-6e61639e5029.png",
														"url": "https://staging-media.toyota.com.ar/d673ebbf-81e0-4bbd-8e93-6e61639e5029.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "805aaf67-b621-4af1-a869-e1670f422175.jpeg",
														"url": "https://staging-media.toyota.com.ar/805aaf67-b621-4af1-a869-e1670f422175.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Externo",
														"path": "9684beef-4ff1-44c8-9a28-299ed2039121.jpeg",
														"url": "https://staging-media.toyota.com.ar/9684beef-4ff1-44c8-9a28-299ed2039121.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "637b997e-4ee1-4937-a8a4-d75da7c067de.jpeg",
														"url": "https://staging-media.toyota.com.ar/637b997e-4ee1-4937-a8a4-d75da7c067de.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "811635fa-4d2d-4000-80a1-a2e92538bc72.jpeg",
														"url": "https://staging-media.toyota.com.ar/811635fa-4d2d-4000-80a1-a2e92538bc72.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "1af23665-33e6-4f13-b2ed-f99e5bfa3499.jpeg",
														"url": "https://staging-media.toyota.com.ar/1af23665-33e6-4f13-b2ed-f99e5bfa3499.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Yaris Sedã",
														"path": "bce36b26-b93b-4113-886a-b2ba63eed13e.jpeg",
														"url": "https://staging-media.toyota.com.ar/bce36b26-b93b-4113-886a-b2ba63eed13e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "822c5ce0-fc2a-4123-a8b7-ed3a09cc93b7.png",
														"url": "https://staging-media.toyota.com.ar/822c5ce0-fc2a-4123-a8b7-ed3a09cc93b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "Yaris Sedã",
														"path": "06c736d0-decd-42ac-8c05-d744c8074eaf.png",
														"url": "https://staging-media.toyota.com.ar/06c736d0-decd-42ac-8c05-d744c8074eaf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "88d37ba4-d456-445e-b45c-7a086782d22d.png",
														"url": "https://staging-media.toyota.com.ar/88d37ba4-d456-445e-b45c-7a086782d22d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "Yaris Sedã",
														"path": "38e722c9-6f47-4081-8088-8988b8cef231.png",
														"url": "https://staging-media.toyota.com.ar/38e722c9-6f47-4081-8088-8988b8cef231.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "40c3bdfb-920d-46e4-9b9b-bbc90153eefc.png",
														"url": "https://staging-media.toyota.com.ar/40c3bdfb-920d-46e4-9b9b-bbc90153eefc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "Yaris Sedã",
														"path": "fb900d25-5fcc-4376-9826-d796f52ac884.png",
														"url": "https://staging-media.toyota.com.ar/fb900d25-5fcc-4376-9826-d796f52ac884.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "8425fb51-880c-4b2a-826b-b2474f5e7c5e.png",
														"url": "https://staging-media.toyota.com.ar/8425fb51-880c-4b2a-826b-b2474f5e7c5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Yaris Sedã",
														"path": "f7397986-4e34-42b4-9d3b-f2c92bd5ef50.png",
														"url": "https://staging-media.toyota.com.ar/f7397986-4e34-42b4-9d3b-f2c92bd5ef50.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "9ae90b43-e8d2-4f4e-a46c-919d5472ed3f.png",
														"url": "https://staging-media.toyota.com.ar/9ae90b43-e8d2-4f4e-a46c-919d5472ed3f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "Yaris Sedã",
														"path": "dc4c2fac-7afe-4ba7-9900-792cb5f05687.png",
														"url": "https://staging-media.toyota.com.ar/dc4c2fac-7afe-4ba7-9900-792cb5f05687.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "1f7a9895-2f0b-4df2-a1c7-e1447e754603.png",
														"url": "https://staging-media.toyota.com.ar/1f7a9895-2f0b-4df2-a1c7-e1447e754603.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "Yaris Sedã",
														"path": "0481d5a4-1388-4df4-84b3-3b629d9790b6.png",
														"url": "https://staging-media.toyota.com.ar/0481d5a4-1388-4df4-84b3-3b629d9790b6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "3b4ca91a-0871-433b-9dbd-1d11edfaa5e3.png",
														"url": "https://staging-media.toyota.com.ar/3b4ca91a-0871-433b-9dbd-1d11edfaa5e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "56e1977e-538e-4104-b6e8-8b14382cc87f.png",
														"url": "https://staging-media.toyota.com.ar/56e1977e-538e-4104-b6e8-8b14382cc87f.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 126690,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "3e2ce3a0-6803-4f6e-9715-36df0cfa4bbd.png",
														"url": "https://staging-media.toyota.com.ar/3e2ce3a0-6803-4f6e-9715-36df0cfa4bbd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "d13ae09d-e9e4-413e-9f8c-e0b50d922ed5.png",
														"url": "https://staging-media.toyota.com.ar/d13ae09d-e9e4-413e-9f8c-e0b50d922ed5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "307e88a5-b264-4e76-8f38-876655956780.png",
														"url": "https://staging-media.toyota.com.ar/307e88a5-b264-4e76-8f38-876655956780.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5d23e437-1096-4fb0-8662-f2a292889c39.png",
														"url": "https://staging-media.toyota.com.ar/5d23e437-1096-4fb0-8662-f2a292889c39.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "ab9f5bd1-85cb-4b66-9718-78137ee22a34.png",
														"url": "https://staging-media.toyota.com.ar/ab9f5bd1-85cb-4b66-9718-78137ee22a34.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "f788470d-83a5-48d8-8b5c-4f2429ecffcd.png",
														"url": "https://staging-media.toyota.com.ar/f788470d-83a5-48d8-8b5c-4f2429ecffcd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "1bd8f35f-315c-4ae5-a72e-3229429e7f8f.png",
														"url": "https://staging-media.toyota.com.ar/1bd8f35f-315c-4ae5-a72e-3229429e7f8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "4465426a-3693-4fa0-bb15-bd16e21f9683.png",
														"url": "https://staging-media.toyota.com.ar/4465426a-3693-4fa0-bb15-bd16e21f9683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "f7b727a6-7544-412a-ac92-b09163f1cec6.jpeg",
														"url": "https://staging-media.toyota.com.ar/f7b727a6-7544-412a-ac92-b09163f1cec6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "c7537b2f-322c-482a-9f40-54239c6a15a0.png",
														"url": "https://staging-media.toyota.com.ar/c7537b2f-322c-482a-9f40-54239c6a15a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "84e2941f-8670-4898-9ce6-be81986ba38f.png",
														"url": "https://staging-media.toyota.com.ar/84e2941f-8670-4898-9ce6-be81986ba38f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "d3a2380a-edc2-4326-a964-3f71818150a6.png",
														"url": "https://staging-media.toyota.com.ar/d3a2380a-edc2-4326-a964-3f71818150a6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "607befcd-8baa-4a09-ab20-335287b2759e.png",
														"url": "https://staging-media.toyota.com.ar/607befcd-8baa-4a09-ab20-335287b2759e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"url": "https://staging-media.toyota.com.ar/23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "7d52d86e-9a1d-4ef0-bbad-cdd875be952c.png",
														"url": "https://staging-media.toyota.com.ar/7d52d86e-9a1d-4ef0-bbad-cdd875be952c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "2f6f5ed5-fd6f-4039-a7bf-60dcab783266.png",
														"url": "https://staging-media.toyota.com.ar/2f6f5ed5-fd6f-4039-a7bf-60dcab783266.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "fd672e0c-6788-4512-b13c-6b08d8d9018f.png",
														"url": "https://staging-media.toyota.com.ar/fd672e0c-6788-4512-b13c-6b08d8d9018f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d4ef0b74-f982-487e-9be1-576ad66ae64d.png",
														"url": "https://staging-media.toyota.com.ar/d4ef0b74-f982-487e-9be1-576ad66ae64d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "6f7a27b0-0654-4a95-b50f-f349195e5887.png",
														"url": "https://staging-media.toyota.com.ar/6f7a27b0-0654-4a95-b50f-f349195e5887.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "ef5989d9-e4d2-44e7-a664-a0c6e3cdc7e7.png",
														"url": "https://staging-media.toyota.com.ar/ef5989d9-e4d2-44e7-a664-a0c6e3cdc7e7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "Galeria Interior",
														"path": "23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"url": "https://staging-media.toyota.com.ar/23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "c07aea2b-572c-41ff-af37-b439e64cd44b.jpeg",
														"url": "https://staging-media.toyota.com.br/c07aea2b-572c-41ff-af37-b439e64cd44b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "c90aba64-4175-40b6-b6a3-e04f97c3b6d9.jpeg",
														"url": "https://staging-media.toyota.com.br/c90aba64-4175-40b6-b6a3-e04f97c3b6d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 128320,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "3e2ce3a0-6803-4f6e-9715-36df0cfa4bbd.png",
														"url": "https://staging-media.toyota.com.ar/3e2ce3a0-6803-4f6e-9715-36df0cfa4bbd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "d13ae09d-e9e4-413e-9f8c-e0b50d922ed5.png",
														"url": "https://staging-media.toyota.com.ar/d13ae09d-e9e4-413e-9f8c-e0b50d922ed5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "307e88a5-b264-4e76-8f38-876655956780.png",
														"url": "https://staging-media.toyota.com.ar/307e88a5-b264-4e76-8f38-876655956780.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5d23e437-1096-4fb0-8662-f2a292889c39.png",
														"url": "https://staging-media.toyota.com.ar/5d23e437-1096-4fb0-8662-f2a292889c39.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "ab9f5bd1-85cb-4b66-9718-78137ee22a34.png",
														"url": "https://staging-media.toyota.com.ar/ab9f5bd1-85cb-4b66-9718-78137ee22a34.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "f788470d-83a5-48d8-8b5c-4f2429ecffcd.png",
														"url": "https://staging-media.toyota.com.ar/f788470d-83a5-48d8-8b5c-4f2429ecffcd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "1bd8f35f-315c-4ae5-a72e-3229429e7f8f.png",
														"url": "https://staging-media.toyota.com.ar/1bd8f35f-315c-4ae5-a72e-3229429e7f8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "4465426a-3693-4fa0-bb15-bd16e21f9683.png",
														"url": "https://staging-media.toyota.com.ar/4465426a-3693-4fa0-bb15-bd16e21f9683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "f7b727a6-7544-412a-ac92-b09163f1cec6.jpeg",
														"url": "https://staging-media.toyota.com.ar/f7b727a6-7544-412a-ac92-b09163f1cec6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "c7537b2f-322c-482a-9f40-54239c6a15a0.png",
														"url": "https://staging-media.toyota.com.ar/c7537b2f-322c-482a-9f40-54239c6a15a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "84e2941f-8670-4898-9ce6-be81986ba38f.png",
														"url": "https://staging-media.toyota.com.ar/84e2941f-8670-4898-9ce6-be81986ba38f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "d3a2380a-edc2-4326-a964-3f71818150a6.png",
														"url": "https://staging-media.toyota.com.ar/d3a2380a-edc2-4326-a964-3f71818150a6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "607befcd-8baa-4a09-ab20-335287b2759e.png",
														"url": "https://staging-media.toyota.com.ar/607befcd-8baa-4a09-ab20-335287b2759e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"url": "https://staging-media.toyota.com.ar/23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "7d52d86e-9a1d-4ef0-bbad-cdd875be952c.png",
														"url": "https://staging-media.toyota.com.ar/7d52d86e-9a1d-4ef0-bbad-cdd875be952c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "2f6f5ed5-fd6f-4039-a7bf-60dcab783266.png",
														"url": "https://staging-media.toyota.com.ar/2f6f5ed5-fd6f-4039-a7bf-60dcab783266.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "fd672e0c-6788-4512-b13c-6b08d8d9018f.png",
														"url": "https://staging-media.toyota.com.ar/fd672e0c-6788-4512-b13c-6b08d8d9018f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d4ef0b74-f982-487e-9be1-576ad66ae64d.png",
														"url": "https://staging-media.toyota.com.ar/d4ef0b74-f982-487e-9be1-576ad66ae64d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "6f7a27b0-0654-4a95-b50f-f349195e5887.png",
														"url": "https://staging-media.toyota.com.ar/6f7a27b0-0654-4a95-b50f-f349195e5887.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "ef5989d9-e4d2-44e7-a664-a0c6e3cdc7e7.png",
														"url": "https://staging-media.toyota.com.ar/ef5989d9-e4d2-44e7-a664-a0c6e3cdc7e7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "Galeria Interior",
														"path": "23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"url": "https://staging-media.toyota.com.ar/23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "c07aea2b-572c-41ff-af37-b439e64cd44b.jpeg",
														"url": "https://staging-media.toyota.com.br/c07aea2b-572c-41ff-af37-b439e64cd44b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "c90aba64-4175-40b6-b6a3-e04f97c3b6d9.jpeg",
														"url": "https://staging-media.toyota.com.br/c90aba64-4175-40b6-b6a3-e04f97c3b6d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 128320,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "3e2ce3a0-6803-4f6e-9715-36df0cfa4bbd.png",
														"url": "https://staging-media.toyota.com.ar/3e2ce3a0-6803-4f6e-9715-36df0cfa4bbd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "d13ae09d-e9e4-413e-9f8c-e0b50d922ed5.png",
														"url": "https://staging-media.toyota.com.ar/d13ae09d-e9e4-413e-9f8c-e0b50d922ed5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "307e88a5-b264-4e76-8f38-876655956780.png",
														"url": "https://staging-media.toyota.com.ar/307e88a5-b264-4e76-8f38-876655956780.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5d23e437-1096-4fb0-8662-f2a292889c39.png",
														"url": "https://staging-media.toyota.com.ar/5d23e437-1096-4fb0-8662-f2a292889c39.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "ab9f5bd1-85cb-4b66-9718-78137ee22a34.png",
														"url": "https://staging-media.toyota.com.ar/ab9f5bd1-85cb-4b66-9718-78137ee22a34.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "f788470d-83a5-48d8-8b5c-4f2429ecffcd.png",
														"url": "https://staging-media.toyota.com.ar/f788470d-83a5-48d8-8b5c-4f2429ecffcd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "1bd8f35f-315c-4ae5-a72e-3229429e7f8f.png",
														"url": "https://staging-media.toyota.com.ar/1bd8f35f-315c-4ae5-a72e-3229429e7f8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "4465426a-3693-4fa0-bb15-bd16e21f9683.png",
														"url": "https://staging-media.toyota.com.ar/4465426a-3693-4fa0-bb15-bd16e21f9683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "f7b727a6-7544-412a-ac92-b09163f1cec6.jpeg",
														"url": "https://staging-media.toyota.com.ar/f7b727a6-7544-412a-ac92-b09163f1cec6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "c7537b2f-322c-482a-9f40-54239c6a15a0.png",
														"url": "https://staging-media.toyota.com.ar/c7537b2f-322c-482a-9f40-54239c6a15a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "84e2941f-8670-4898-9ce6-be81986ba38f.png",
														"url": "https://staging-media.toyota.com.ar/84e2941f-8670-4898-9ce6-be81986ba38f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "d3a2380a-edc2-4326-a964-3f71818150a6.png",
														"url": "https://staging-media.toyota.com.ar/d3a2380a-edc2-4326-a964-3f71818150a6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "607befcd-8baa-4a09-ab20-335287b2759e.png",
														"url": "https://staging-media.toyota.com.ar/607befcd-8baa-4a09-ab20-335287b2759e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"url": "https://staging-media.toyota.com.ar/23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "7d52d86e-9a1d-4ef0-bbad-cdd875be952c.png",
														"url": "https://staging-media.toyota.com.ar/7d52d86e-9a1d-4ef0-bbad-cdd875be952c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "2f6f5ed5-fd6f-4039-a7bf-60dcab783266.png",
														"url": "https://staging-media.toyota.com.ar/2f6f5ed5-fd6f-4039-a7bf-60dcab783266.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "fd672e0c-6788-4512-b13c-6b08d8d9018f.png",
														"url": "https://staging-media.toyota.com.ar/fd672e0c-6788-4512-b13c-6b08d8d9018f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d4ef0b74-f982-487e-9be1-576ad66ae64d.png",
														"url": "https://staging-media.toyota.com.ar/d4ef0b74-f982-487e-9be1-576ad66ae64d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "6f7a27b0-0654-4a95-b50f-f349195e5887.png",
														"url": "https://staging-media.toyota.com.ar/6f7a27b0-0654-4a95-b50f-f349195e5887.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "ef5989d9-e4d2-44e7-a664-a0c6e3cdc7e7.png",
														"url": "https://staging-media.toyota.com.ar/ef5989d9-e4d2-44e7-a664-a0c6e3cdc7e7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "Galeria Interior",
														"path": "23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"url": "https://staging-media.toyota.com.ar/23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "c07aea2b-572c-41ff-af37-b439e64cd44b.jpeg",
														"url": "https://staging-media.toyota.com.br/c07aea2b-572c-41ff-af37-b439e64cd44b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "c90aba64-4175-40b6-b6a3-e04f97c3b6d9.jpeg",
														"url": "https://staging-media.toyota.com.br/c90aba64-4175-40b6-b6a3-e04f97c3b6d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 128320,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "3e2ce3a0-6803-4f6e-9715-36df0cfa4bbd.png",
														"url": "https://staging-media.toyota.com.ar/3e2ce3a0-6803-4f6e-9715-36df0cfa4bbd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "d13ae09d-e9e4-413e-9f8c-e0b50d922ed5.png",
														"url": "https://staging-media.toyota.com.ar/d13ae09d-e9e4-413e-9f8c-e0b50d922ed5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "307e88a5-b264-4e76-8f38-876655956780.png",
														"url": "https://staging-media.toyota.com.ar/307e88a5-b264-4e76-8f38-876655956780.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5d23e437-1096-4fb0-8662-f2a292889c39.png",
														"url": "https://staging-media.toyota.com.ar/5d23e437-1096-4fb0-8662-f2a292889c39.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "ab9f5bd1-85cb-4b66-9718-78137ee22a34.png",
														"url": "https://staging-media.toyota.com.ar/ab9f5bd1-85cb-4b66-9718-78137ee22a34.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "f788470d-83a5-48d8-8b5c-4f2429ecffcd.png",
														"url": "https://staging-media.toyota.com.ar/f788470d-83a5-48d8-8b5c-4f2429ecffcd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "1bd8f35f-315c-4ae5-a72e-3229429e7f8f.png",
														"url": "https://staging-media.toyota.com.ar/1bd8f35f-315c-4ae5-a72e-3229429e7f8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "4465426a-3693-4fa0-bb15-bd16e21f9683.png",
														"url": "https://staging-media.toyota.com.ar/4465426a-3693-4fa0-bb15-bd16e21f9683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "f7b727a6-7544-412a-ac92-b09163f1cec6.jpeg",
														"url": "https://staging-media.toyota.com.ar/f7b727a6-7544-412a-ac92-b09163f1cec6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "c7537b2f-322c-482a-9f40-54239c6a15a0.png",
														"url": "https://staging-media.toyota.com.ar/c7537b2f-322c-482a-9f40-54239c6a15a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "84e2941f-8670-4898-9ce6-be81986ba38f.png",
														"url": "https://staging-media.toyota.com.ar/84e2941f-8670-4898-9ce6-be81986ba38f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "d3a2380a-edc2-4326-a964-3f71818150a6.png",
														"url": "https://staging-media.toyota.com.ar/d3a2380a-edc2-4326-a964-3f71818150a6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "607befcd-8baa-4a09-ab20-335287b2759e.png",
														"url": "https://staging-media.toyota.com.ar/607befcd-8baa-4a09-ab20-335287b2759e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"url": "https://staging-media.toyota.com.ar/23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "7d52d86e-9a1d-4ef0-bbad-cdd875be952c.png",
														"url": "https://staging-media.toyota.com.ar/7d52d86e-9a1d-4ef0-bbad-cdd875be952c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "2f6f5ed5-fd6f-4039-a7bf-60dcab783266.png",
														"url": "https://staging-media.toyota.com.ar/2f6f5ed5-fd6f-4039-a7bf-60dcab783266.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "fd672e0c-6788-4512-b13c-6b08d8d9018f.png",
														"url": "https://staging-media.toyota.com.ar/fd672e0c-6788-4512-b13c-6b08d8d9018f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d4ef0b74-f982-487e-9be1-576ad66ae64d.png",
														"url": "https://staging-media.toyota.com.ar/d4ef0b74-f982-487e-9be1-576ad66ae64d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "6f7a27b0-0654-4a95-b50f-f349195e5887.png",
														"url": "https://staging-media.toyota.com.ar/6f7a27b0-0654-4a95-b50f-f349195e5887.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "ef5989d9-e4d2-44e7-a664-a0c6e3cdc7e7.png",
														"url": "https://staging-media.toyota.com.ar/ef5989d9-e4d2-44e7-a664-a0c6e3cdc7e7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "Galeria Interior",
														"path": "23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"url": "https://staging-media.toyota.com.ar/23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "c07aea2b-572c-41ff-af37-b439e64cd44b.jpeg",
														"url": "https://staging-media.toyota.com.br/c07aea2b-572c-41ff-af37-b439e64cd44b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "c90aba64-4175-40b6-b6a3-e04f97c3b6d9.jpeg",
														"url": "https://staging-media.toyota.com.br/c90aba64-4175-40b6-b6a3-e04f97c3b6d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 128320,
										"offers": null,
										"color": {
											"name": "Azul Titã (8V5) ",
											"code": "8V5",
											"rgb": "#3D4954"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "3e2ce3a0-6803-4f6e-9715-36df0cfa4bbd.png",
														"url": "https://staging-media.toyota.com.ar/3e2ce3a0-6803-4f6e-9715-36df0cfa4bbd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "d13ae09d-e9e4-413e-9f8c-e0b50d922ed5.png",
														"url": "https://staging-media.toyota.com.ar/d13ae09d-e9e4-413e-9f8c-e0b50d922ed5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "307e88a5-b264-4e76-8f38-876655956780.png",
														"url": "https://staging-media.toyota.com.ar/307e88a5-b264-4e76-8f38-876655956780.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5d23e437-1096-4fb0-8662-f2a292889c39.png",
														"url": "https://staging-media.toyota.com.ar/5d23e437-1096-4fb0-8662-f2a292889c39.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "ab9f5bd1-85cb-4b66-9718-78137ee22a34.png",
														"url": "https://staging-media.toyota.com.ar/ab9f5bd1-85cb-4b66-9718-78137ee22a34.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "f788470d-83a5-48d8-8b5c-4f2429ecffcd.png",
														"url": "https://staging-media.toyota.com.ar/f788470d-83a5-48d8-8b5c-4f2429ecffcd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "1bd8f35f-315c-4ae5-a72e-3229429e7f8f.png",
														"url": "https://staging-media.toyota.com.ar/1bd8f35f-315c-4ae5-a72e-3229429e7f8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "4465426a-3693-4fa0-bb15-bd16e21f9683.png",
														"url": "https://staging-media.toyota.com.ar/4465426a-3693-4fa0-bb15-bd16e21f9683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "f7b727a6-7544-412a-ac92-b09163f1cec6.jpeg",
														"url": "https://staging-media.toyota.com.ar/f7b727a6-7544-412a-ac92-b09163f1cec6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "c7537b2f-322c-482a-9f40-54239c6a15a0.png",
														"url": "https://staging-media.toyota.com.ar/c7537b2f-322c-482a-9f40-54239c6a15a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "84e2941f-8670-4898-9ce6-be81986ba38f.png",
														"url": "https://staging-media.toyota.com.ar/84e2941f-8670-4898-9ce6-be81986ba38f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "d3a2380a-edc2-4326-a964-3f71818150a6.png",
														"url": "https://staging-media.toyota.com.ar/d3a2380a-edc2-4326-a964-3f71818150a6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "607befcd-8baa-4a09-ab20-335287b2759e.png",
														"url": "https://staging-media.toyota.com.ar/607befcd-8baa-4a09-ab20-335287b2759e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"url": "https://staging-media.toyota.com.ar/23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "7d52d86e-9a1d-4ef0-bbad-cdd875be952c.png",
														"url": "https://staging-media.toyota.com.ar/7d52d86e-9a1d-4ef0-bbad-cdd875be952c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "2f6f5ed5-fd6f-4039-a7bf-60dcab783266.png",
														"url": "https://staging-media.toyota.com.ar/2f6f5ed5-fd6f-4039-a7bf-60dcab783266.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "fd672e0c-6788-4512-b13c-6b08d8d9018f.png",
														"url": "https://staging-media.toyota.com.ar/fd672e0c-6788-4512-b13c-6b08d8d9018f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d4ef0b74-f982-487e-9be1-576ad66ae64d.png",
														"url": "https://staging-media.toyota.com.ar/d4ef0b74-f982-487e-9be1-576ad66ae64d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "6f7a27b0-0654-4a95-b50f-f349195e5887.png",
														"url": "https://staging-media.toyota.com.ar/6f7a27b0-0654-4a95-b50f-f349195e5887.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "ef5989d9-e4d2-44e7-a664-a0c6e3cdc7e7.png",
														"url": "https://staging-media.toyota.com.ar/ef5989d9-e4d2-44e7-a664-a0c6e3cdc7e7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "Galeria Interior",
														"path": "23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"url": "https://staging-media.toyota.com.ar/23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "c07aea2b-572c-41ff-af37-b439e64cd44b.jpeg",
														"url": "https://staging-media.toyota.com.br/c07aea2b-572c-41ff-af37-b439e64cd44b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "c90aba64-4175-40b6-b6a3-e04f97c3b6d9.jpeg",
														"url": "https://staging-media.toyota.com.br/c90aba64-4175-40b6-b6a3-e04f97c3b6d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 128620,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"url": "https://staging-media.toyota.com.ar/bcbfb4de-ceaa-400f-b8a3-45caf433294c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"url": "https://staging-media.toyota.com.ar/d23bee43-f51d-46a6-85c6-ad92a0936559.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"url": "https://staging-media.toyota.com.ar/17536925-aa9e-430d-b713-b43fac708b14.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"url": "https://staging-media.toyota.com.ar/ef4a3e15-1a97-433f-beaf-5572aab05c48.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"url": "https://staging-media.toyota.com.ar/1a6a1c76-8787-4a5d-978c-7c02f86f81a9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"url": "https://staging-media.toyota.com.ar/a82bf446-d780-4821-8219-45699e6a7dfa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "3e2ce3a0-6803-4f6e-9715-36df0cfa4bbd.png",
														"url": "https://staging-media.toyota.com.ar/3e2ce3a0-6803-4f6e-9715-36df0cfa4bbd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3",
														"path": "d13ae09d-e9e4-413e-9f8c-e0b50d922ed5.png",
														"url": "https://staging-media.toyota.com.ar/d13ae09d-e9e4-413e-9f8c-e0b50d922ed5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6",
														"path": "307e88a5-b264-4e76-8f38-876655956780.png",
														"url": "https://staging-media.toyota.com.ar/307e88a5-b264-4e76-8f38-876655956780.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215",
														"path": "5d23e437-1096-4fb0-8662-f2a292889c39.png",
														"url": "https://staging-media.toyota.com.ar/5d23e437-1096-4fb0-8662-f2a292889c39.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8V5",
													"media": {
														"title": "8V5",
														"path": "ab9f5bd1-85cb-4b66-9718-78137ee22a34.png",
														"url": "https://staging-media.toyota.com.ar/ab9f5bd1-85cb-4b66-9718-78137ee22a34.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089",
														"path": "f788470d-83a5-48d8-8b5c-4f2429ecffcd.png",
														"url": "https://staging-media.toyota.com.ar/f788470d-83a5-48d8-8b5c-4f2429ecffcd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "LOGO YARIS",
														"path": "9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"url": "https://staging-media.toyota.com.ar/9a89d0c9-3c67-4fcc-9cfb-23e5d91f38f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "1bd8f35f-315c-4ae5-a72e-3229429e7f8f.png",
														"url": "https://staging-media.toyota.com.ar/1bd8f35f-315c-4ae5-a72e-3229429e7f8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "4465426a-3693-4fa0-bb15-bd16e21f9683.png",
														"url": "https://staging-media.toyota.com.ar/4465426a-3693-4fa0-bb15-bd16e21f9683.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "f7b727a6-7544-412a-ac92-b09163f1cec6.jpeg",
														"url": "https://staging-media.toyota.com.ar/f7b727a6-7544-412a-ac92-b09163f1cec6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "c7537b2f-322c-482a-9f40-54239c6a15a0.png",
														"url": "https://staging-media.toyota.com.ar/c7537b2f-322c-482a-9f40-54239c6a15a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "84e2941f-8670-4898-9ce6-be81986ba38f.png",
														"url": "https://staging-media.toyota.com.ar/84e2941f-8670-4898-9ce6-be81986ba38f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "d3a2380a-edc2-4326-a964-3f71818150a6.png",
														"url": "https://staging-media.toyota.com.ar/d3a2380a-edc2-4326-a964-3f71818150a6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "607befcd-8baa-4a09-ab20-335287b2759e.png",
														"url": "https://staging-media.toyota.com.ar/607befcd-8baa-4a09-ab20-335287b2759e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"url": "https://staging-media.toyota.com.ar/23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "7d52d86e-9a1d-4ef0-bbad-cdd875be952c.png",
														"url": "https://staging-media.toyota.com.ar/7d52d86e-9a1d-4ef0-bbad-cdd875be952c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3",
														"path": "2f6f5ed5-fd6f-4039-a7bf-60dcab783266.png",
														"url": "https://staging-media.toyota.com.ar/2f6f5ed5-fd6f-4039-a7bf-60dcab783266.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6",
														"path": "fd672e0c-6788-4512-b13c-6b08d8d9018f.png",
														"url": "https://staging-media.toyota.com.ar/fd672e0c-6788-4512-b13c-6b08d8d9018f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d4ef0b74-f982-487e-9be1-576ad66ae64d.png",
														"url": "https://staging-media.toyota.com.ar/d4ef0b74-f982-487e-9be1-576ad66ae64d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8V5",
													"media": {
														"title": "8V5",
														"path": "6f7a27b0-0654-4a95-b50f-f349195e5887.png",
														"url": "https://staging-media.toyota.com.ar/6f7a27b0-0654-4a95-b50f-f349195e5887.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089",
														"path": "ef5989d9-e4d2-44e7-a664-a0c6e3cdc7e7.png",
														"url": "https://staging-media.toyota.com.ar/ef5989d9-e4d2-44e7-a664-a0c6e3cdc7e7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "Galeria Interior",
														"path": "23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"url": "https://staging-media.toyota.com.ar/23f90008-6073-4b48-bca5-99970dc4a6e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "yaris",
														"path": "c07aea2b-572c-41ff-af37-b439e64cd44b.jpeg",
														"url": "https://staging-media.toyota.com.br/c07aea2b-572c-41ff-af37-b439e64cd44b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "yaris",
														"path": "c90aba64-4175-40b6-b6a3-e04f97c3b6d9.jpeg",
														"url": "https://staging-media.toyota.com.br/c90aba64-4175-40b6-b6a3-e04f97c3b6d9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					}
				]
			},
			{
				"id": "b6397a1e-4f26-45b1-9ab4-f8d7fca948f2",
				"name": "Corolla Cross",
				"vehicleExternalId": null,
				"status": "ENABLED",
				"category": {
					"name": "SUVs",
					"id": "39e016f8-3719-439b-91f6-41a1d0d151b6"
				},
				"versions": [
					{
						"name": "XR",
						"id": "19a02fb6-88c6-4fd6-9a44-11bdbff2e7d7",
						"order": 1,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 160390,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360",
														"path": "f10ebc5f-84c1-4e73-9df6-829980a3b3ff.png",
														"url": "https://staging-media.toyota.com.ar/f10ebc5f-84c1-4e73-9df6-829980a3b3ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "9c000e2d-0e4e-474b-a60e-861dd12676ce.png",
														"url": "https://staging-media.toyota.com.ar/9c000e2d-0e4e-474b-a60e-861dd12676ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 360",
														"path": "e65184b8-477b-4497-852a-dfb9ed41f999.png",
														"url": "https://staging-media.toyota.com.ar/e65184b8-477b-4497-852a-dfb9ed41f999.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "e8ee768e-ba98-484f-ba0e-3f279ff6ff03.png",
														"url": "https://staging-media.toyota.com.ar/e8ee768e-ba98-484f-ba0e-3f279ff6ff03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3",
														"path": "2fa9530f-03dc-45ad-9740-e2ccb7808b51.png",
														"url": "https://staging-media.toyota.com.ar/2fa9530f-03dc-45ad-9740-e2ccb7808b51.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "858dc57b-47d1-44c8-82d5-8edbeac88252.png",
														"url": "https://staging-media.toyota.com.ar/858dc57b-47d1-44c8-82d5-8edbeac88252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d98a404e-e924-48fb-9651-761c6f75a445.png",
														"url": "https://staging-media.toyota.com.ar/d98a404e-e924-48fb-9651-761c6f75a445.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "61404b14-9eaa-4e0e-86ae-a0e8a19f5458.png",
														"url": "https://staging-media.toyota.com.ar/61404b14-9eaa-4e0e-86ae-a0e8a19f5458.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6",
														"path": "280c1195-1a00-41ed-b690-70a1dd814e69.png",
														"url": "https://staging-media.toyota.com.ar/280c1195-1a00-41ed-b690-70a1dd814e69.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 2",
														"path": "80b4db3c-2f6a-4495-b4db-fbc76e13b77f.jpeg",
														"url": "https://staging-media.toyota.com.br/80b4db3c-2f6a-4495-b4db-fbc76e13b77f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 3",
														"path": "7c5539b6-dd85-48ed-a1b6-c6b61e5978dd.jpeg",
														"url": "https://staging-media.toyota.com.ar/7c5539b6-dd85-48ed-a1b6-c6b61e5978dd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 4",
														"path": "a33eadca-f310-425b-9eb5-4e91cb96dcd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/a33eadca-f310-425b-9eb5-4e91cb96dcd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 5",
														"path": "960341af-4b03-46a0-8c24-777ba9916313.jpeg",
														"url": "https://staging-media.toyota.com.ar/960341af-4b03-46a0-8c24-777ba9916313.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 6",
														"path": "36354508-ab61-4c7e-bc91-0e6cc761364f.jpeg",
														"url": "https://staging-media.toyota.com.ar/36354508-ab61-4c7e-bc91-0e6cc761364f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR ",
														"path": "cc94a455-6d86-4304-807c-85bd000aaece.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc94a455-6d86-4304-807c-85bd000aaece.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 1",
														"path": "f9e8da53-2c2a-4828-ae2b-d7cb5b2bdd02.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9e8da53-2c2a-4828-ae2b-d7cb5b2bdd02.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 1",
														"path": "554b112c-e3b3-4f39-b623-c672d2222a85.jpeg",
														"url": "https://staging-media.toyota.com.ar/554b112c-e3b3-4f39-b623-c672d2222a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 2",
														"path": "510c2e40-5171-4106-b5e1-196e5f5bf692.jpeg",
														"url": "https://staging-media.toyota.com.ar/510c2e40-5171-4106-b5e1-196e5f5bf692.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR ",
														"path": "49893591-66e3-4102-904d-d95060abd8a5.jpeg",
														"url": "https://staging-media.toyota.com.ar/49893591-66e3-4102-904d-d95060abd8a5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 4",
														"path": "d8f12dd0-8a43-4303-8109-7e9f29b3a1b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/d8f12dd0-8a43-4303-8109-7e9f29b3a1b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 5",
														"path": "2c8b7548-259e-4cb6-a242-5a947c599216.jpeg",
														"url": "https://staging-media.toyota.com.ar/2c8b7548-259e-4cb6-a242-5a947c599216.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 6",
														"path": "5537d344-deb0-458d-bc73-f2eaa8c7b353.jpeg",
														"url": "https://staging-media.toyota.com.ar/5537d344-deb0-458d-bc73-f2eaa8c7b353.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 7",
														"path": "bdc06ca3-fd1b-491a-a457-544550bc6a65.jpeg",
														"url": "https://staging-media.toyota.com.ar/bdc06ca3-fd1b-491a-a457-544550bc6a65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 8",
														"path": "98c2171a-a33e-4763-b5fa-8875ea68403c.jpeg",
														"url": "https://staging-media.toyota.com.ar/98c2171a-a33e-4763-b5fa-8875ea68403c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6",
														"path": "212139d0-744d-4eda-9473-ff58961afa3e.png",
														"url": "https://staging-media.toyota.com.ar/212139d0-744d-4eda-9473-ff58961afa3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 ",
														"path": "d2d38be0-2375-473a-b9ce-74edd52185f0.png",
														"url": "https://staging-media.toyota.com.ar/d2d38be0-2375-473a-b9ce-74edd52185f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "16058157-9880-4e19-951d-27227a99b28a.png",
														"url": "https://staging-media.toyota.com.ar/16058157-9880-4e19-951d-27227a99b28a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross",
														"path": "c9f16ffe-e73f-482e-8ac4-dfa394ca05ab.png",
														"url": "https://staging-media.toyota.com.ar/c9f16ffe-e73f-482e-8ac4-dfa394ca05ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Cross - Banner Dsk",
														"path": "589b79e1-ea8c-4990-b557-2435c8d96ea5.jpeg",
														"url": "https://staging-media.toyota.com.ar/589b79e1-ea8c-4990-b557-2435c8d96ea5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Cross - Banner mobile",
														"path": "b4442f2f-e17f-4509-8062-7729a9676fea.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4442f2f-e17f-4509-8062-7729a9676fea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Cross - Sem Hover",
														"path": "2594f8f7-8c2e-4583-b6a9-8dc9108824e4.png",
														"url": "https://staging-media.toyota.com.ar/2594f8f7-8c2e-4583-b6a9-8dc9108824e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Cross - Hover",
														"path": "9e32bd69-9fb5-407c-8fdd-4ed51913ab72.png",
														"url": "https://staging-media.toyota.com.ar/9e32bd69-9fb5-407c-8fdd-4ed51913ab72.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 162410,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360",
														"path": "f10ebc5f-84c1-4e73-9df6-829980a3b3ff.png",
														"url": "https://staging-media.toyota.com.ar/f10ebc5f-84c1-4e73-9df6-829980a3b3ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "9c000e2d-0e4e-474b-a60e-861dd12676ce.png",
														"url": "https://staging-media.toyota.com.ar/9c000e2d-0e4e-474b-a60e-861dd12676ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 360",
														"path": "e65184b8-477b-4497-852a-dfb9ed41f999.png",
														"url": "https://staging-media.toyota.com.ar/e65184b8-477b-4497-852a-dfb9ed41f999.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "e8ee768e-ba98-484f-ba0e-3f279ff6ff03.png",
														"url": "https://staging-media.toyota.com.ar/e8ee768e-ba98-484f-ba0e-3f279ff6ff03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3",
														"path": "2fa9530f-03dc-45ad-9740-e2ccb7808b51.png",
														"url": "https://staging-media.toyota.com.ar/2fa9530f-03dc-45ad-9740-e2ccb7808b51.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "858dc57b-47d1-44c8-82d5-8edbeac88252.png",
														"url": "https://staging-media.toyota.com.ar/858dc57b-47d1-44c8-82d5-8edbeac88252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d98a404e-e924-48fb-9651-761c6f75a445.png",
														"url": "https://staging-media.toyota.com.ar/d98a404e-e924-48fb-9651-761c6f75a445.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "61404b14-9eaa-4e0e-86ae-a0e8a19f5458.png",
														"url": "https://staging-media.toyota.com.ar/61404b14-9eaa-4e0e-86ae-a0e8a19f5458.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6",
														"path": "280c1195-1a00-41ed-b690-70a1dd814e69.png",
														"url": "https://staging-media.toyota.com.ar/280c1195-1a00-41ed-b690-70a1dd814e69.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 2",
														"path": "80b4db3c-2f6a-4495-b4db-fbc76e13b77f.jpeg",
														"url": "https://staging-media.toyota.com.br/80b4db3c-2f6a-4495-b4db-fbc76e13b77f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 3",
														"path": "7c5539b6-dd85-48ed-a1b6-c6b61e5978dd.jpeg",
														"url": "https://staging-media.toyota.com.ar/7c5539b6-dd85-48ed-a1b6-c6b61e5978dd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 4",
														"path": "a33eadca-f310-425b-9eb5-4e91cb96dcd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/a33eadca-f310-425b-9eb5-4e91cb96dcd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 5",
														"path": "960341af-4b03-46a0-8c24-777ba9916313.jpeg",
														"url": "https://staging-media.toyota.com.ar/960341af-4b03-46a0-8c24-777ba9916313.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 6",
														"path": "36354508-ab61-4c7e-bc91-0e6cc761364f.jpeg",
														"url": "https://staging-media.toyota.com.ar/36354508-ab61-4c7e-bc91-0e6cc761364f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR ",
														"path": "cc94a455-6d86-4304-807c-85bd000aaece.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc94a455-6d86-4304-807c-85bd000aaece.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 1",
														"path": "f9e8da53-2c2a-4828-ae2b-d7cb5b2bdd02.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9e8da53-2c2a-4828-ae2b-d7cb5b2bdd02.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 1",
														"path": "554b112c-e3b3-4f39-b623-c672d2222a85.jpeg",
														"url": "https://staging-media.toyota.com.ar/554b112c-e3b3-4f39-b623-c672d2222a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 2",
														"path": "510c2e40-5171-4106-b5e1-196e5f5bf692.jpeg",
														"url": "https://staging-media.toyota.com.ar/510c2e40-5171-4106-b5e1-196e5f5bf692.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR ",
														"path": "49893591-66e3-4102-904d-d95060abd8a5.jpeg",
														"url": "https://staging-media.toyota.com.ar/49893591-66e3-4102-904d-d95060abd8a5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 4",
														"path": "d8f12dd0-8a43-4303-8109-7e9f29b3a1b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/d8f12dd0-8a43-4303-8109-7e9f29b3a1b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 5",
														"path": "2c8b7548-259e-4cb6-a242-5a947c599216.jpeg",
														"url": "https://staging-media.toyota.com.ar/2c8b7548-259e-4cb6-a242-5a947c599216.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 6",
														"path": "5537d344-deb0-458d-bc73-f2eaa8c7b353.jpeg",
														"url": "https://staging-media.toyota.com.ar/5537d344-deb0-458d-bc73-f2eaa8c7b353.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 7",
														"path": "bdc06ca3-fd1b-491a-a457-544550bc6a65.jpeg",
														"url": "https://staging-media.toyota.com.ar/bdc06ca3-fd1b-491a-a457-544550bc6a65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 8",
														"path": "98c2171a-a33e-4763-b5fa-8875ea68403c.jpeg",
														"url": "https://staging-media.toyota.com.ar/98c2171a-a33e-4763-b5fa-8875ea68403c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6",
														"path": "212139d0-744d-4eda-9473-ff58961afa3e.png",
														"url": "https://staging-media.toyota.com.ar/212139d0-744d-4eda-9473-ff58961afa3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 ",
														"path": "d2d38be0-2375-473a-b9ce-74edd52185f0.png",
														"url": "https://staging-media.toyota.com.ar/d2d38be0-2375-473a-b9ce-74edd52185f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "16058157-9880-4e19-951d-27227a99b28a.png",
														"url": "https://staging-media.toyota.com.ar/16058157-9880-4e19-951d-27227a99b28a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross",
														"path": "c9f16ffe-e73f-482e-8ac4-dfa394ca05ab.png",
														"url": "https://staging-media.toyota.com.ar/c9f16ffe-e73f-482e-8ac4-dfa394ca05ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Cross - Banner Dsk",
														"path": "589b79e1-ea8c-4990-b557-2435c8d96ea5.jpeg",
														"url": "https://staging-media.toyota.com.ar/589b79e1-ea8c-4990-b557-2435c8d96ea5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Cross - Banner mobile",
														"path": "b4442f2f-e17f-4509-8062-7729a9676fea.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4442f2f-e17f-4509-8062-7729a9676fea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Cross - Sem Hover",
														"path": "2594f8f7-8c2e-4583-b6a9-8dc9108824e4.png",
														"url": "https://staging-media.toyota.com.ar/2594f8f7-8c2e-4583-b6a9-8dc9108824e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Cross - Hover",
														"path": "9e32bd69-9fb5-407c-8fdd-4ed51913ab72.png",
														"url": "https://staging-media.toyota.com.ar/9e32bd69-9fb5-407c-8fdd-4ed51913ab72.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 162410,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360",
														"path": "f10ebc5f-84c1-4e73-9df6-829980a3b3ff.png",
														"url": "https://staging-media.toyota.com.ar/f10ebc5f-84c1-4e73-9df6-829980a3b3ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "9c000e2d-0e4e-474b-a60e-861dd12676ce.png",
														"url": "https://staging-media.toyota.com.ar/9c000e2d-0e4e-474b-a60e-861dd12676ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 360",
														"path": "e65184b8-477b-4497-852a-dfb9ed41f999.png",
														"url": "https://staging-media.toyota.com.ar/e65184b8-477b-4497-852a-dfb9ed41f999.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "e8ee768e-ba98-484f-ba0e-3f279ff6ff03.png",
														"url": "https://staging-media.toyota.com.ar/e8ee768e-ba98-484f-ba0e-3f279ff6ff03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3",
														"path": "2fa9530f-03dc-45ad-9740-e2ccb7808b51.png",
														"url": "https://staging-media.toyota.com.ar/2fa9530f-03dc-45ad-9740-e2ccb7808b51.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "858dc57b-47d1-44c8-82d5-8edbeac88252.png",
														"url": "https://staging-media.toyota.com.ar/858dc57b-47d1-44c8-82d5-8edbeac88252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d98a404e-e924-48fb-9651-761c6f75a445.png",
														"url": "https://staging-media.toyota.com.ar/d98a404e-e924-48fb-9651-761c6f75a445.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "61404b14-9eaa-4e0e-86ae-a0e8a19f5458.png",
														"url": "https://staging-media.toyota.com.ar/61404b14-9eaa-4e0e-86ae-a0e8a19f5458.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6",
														"path": "280c1195-1a00-41ed-b690-70a1dd814e69.png",
														"url": "https://staging-media.toyota.com.ar/280c1195-1a00-41ed-b690-70a1dd814e69.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 2",
														"path": "80b4db3c-2f6a-4495-b4db-fbc76e13b77f.jpeg",
														"url": "https://staging-media.toyota.com.br/80b4db3c-2f6a-4495-b4db-fbc76e13b77f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 3",
														"path": "7c5539b6-dd85-48ed-a1b6-c6b61e5978dd.jpeg",
														"url": "https://staging-media.toyota.com.ar/7c5539b6-dd85-48ed-a1b6-c6b61e5978dd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 4",
														"path": "a33eadca-f310-425b-9eb5-4e91cb96dcd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/a33eadca-f310-425b-9eb5-4e91cb96dcd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 5",
														"path": "960341af-4b03-46a0-8c24-777ba9916313.jpeg",
														"url": "https://staging-media.toyota.com.ar/960341af-4b03-46a0-8c24-777ba9916313.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 6",
														"path": "36354508-ab61-4c7e-bc91-0e6cc761364f.jpeg",
														"url": "https://staging-media.toyota.com.ar/36354508-ab61-4c7e-bc91-0e6cc761364f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR ",
														"path": "cc94a455-6d86-4304-807c-85bd000aaece.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc94a455-6d86-4304-807c-85bd000aaece.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 1",
														"path": "f9e8da53-2c2a-4828-ae2b-d7cb5b2bdd02.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9e8da53-2c2a-4828-ae2b-d7cb5b2bdd02.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 1",
														"path": "554b112c-e3b3-4f39-b623-c672d2222a85.jpeg",
														"url": "https://staging-media.toyota.com.ar/554b112c-e3b3-4f39-b623-c672d2222a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 2",
														"path": "510c2e40-5171-4106-b5e1-196e5f5bf692.jpeg",
														"url": "https://staging-media.toyota.com.ar/510c2e40-5171-4106-b5e1-196e5f5bf692.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR ",
														"path": "49893591-66e3-4102-904d-d95060abd8a5.jpeg",
														"url": "https://staging-media.toyota.com.ar/49893591-66e3-4102-904d-d95060abd8a5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 4",
														"path": "d8f12dd0-8a43-4303-8109-7e9f29b3a1b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/d8f12dd0-8a43-4303-8109-7e9f29b3a1b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 5",
														"path": "2c8b7548-259e-4cb6-a242-5a947c599216.jpeg",
														"url": "https://staging-media.toyota.com.ar/2c8b7548-259e-4cb6-a242-5a947c599216.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 6",
														"path": "5537d344-deb0-458d-bc73-f2eaa8c7b353.jpeg",
														"url": "https://staging-media.toyota.com.ar/5537d344-deb0-458d-bc73-f2eaa8c7b353.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 7",
														"path": "bdc06ca3-fd1b-491a-a457-544550bc6a65.jpeg",
														"url": "https://staging-media.toyota.com.ar/bdc06ca3-fd1b-491a-a457-544550bc6a65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 8",
														"path": "98c2171a-a33e-4763-b5fa-8875ea68403c.jpeg",
														"url": "https://staging-media.toyota.com.ar/98c2171a-a33e-4763-b5fa-8875ea68403c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6",
														"path": "212139d0-744d-4eda-9473-ff58961afa3e.png",
														"url": "https://staging-media.toyota.com.ar/212139d0-744d-4eda-9473-ff58961afa3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 ",
														"path": "d2d38be0-2375-473a-b9ce-74edd52185f0.png",
														"url": "https://staging-media.toyota.com.ar/d2d38be0-2375-473a-b9ce-74edd52185f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "16058157-9880-4e19-951d-27227a99b28a.png",
														"url": "https://staging-media.toyota.com.ar/16058157-9880-4e19-951d-27227a99b28a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross",
														"path": "c9f16ffe-e73f-482e-8ac4-dfa394ca05ab.png",
														"url": "https://staging-media.toyota.com.ar/c9f16ffe-e73f-482e-8ac4-dfa394ca05ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Cross - Banner Dsk",
														"path": "589b79e1-ea8c-4990-b557-2435c8d96ea5.jpeg",
														"url": "https://staging-media.toyota.com.ar/589b79e1-ea8c-4990-b557-2435c8d96ea5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Cross - Banner mobile",
														"path": "b4442f2f-e17f-4509-8062-7729a9676fea.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4442f2f-e17f-4509-8062-7729a9676fea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Cross - Sem Hover",
														"path": "2594f8f7-8c2e-4583-b6a9-8dc9108824e4.png",
														"url": "https://staging-media.toyota.com.ar/2594f8f7-8c2e-4583-b6a9-8dc9108824e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Cross - Hover",
														"path": "9e32bd69-9fb5-407c-8fdd-4ed51913ab72.png",
														"url": "https://staging-media.toyota.com.ar/9e32bd69-9fb5-407c-8fdd-4ed51913ab72.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 162410,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360",
														"path": "f10ebc5f-84c1-4e73-9df6-829980a3b3ff.png",
														"url": "https://staging-media.toyota.com.ar/f10ebc5f-84c1-4e73-9df6-829980a3b3ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "9c000e2d-0e4e-474b-a60e-861dd12676ce.png",
														"url": "https://staging-media.toyota.com.ar/9c000e2d-0e4e-474b-a60e-861dd12676ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 360",
														"path": "e65184b8-477b-4497-852a-dfb9ed41f999.png",
														"url": "https://staging-media.toyota.com.ar/e65184b8-477b-4497-852a-dfb9ed41f999.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "e8ee768e-ba98-484f-ba0e-3f279ff6ff03.png",
														"url": "https://staging-media.toyota.com.ar/e8ee768e-ba98-484f-ba0e-3f279ff6ff03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3",
														"path": "2fa9530f-03dc-45ad-9740-e2ccb7808b51.png",
														"url": "https://staging-media.toyota.com.ar/2fa9530f-03dc-45ad-9740-e2ccb7808b51.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "858dc57b-47d1-44c8-82d5-8edbeac88252.png",
														"url": "https://staging-media.toyota.com.ar/858dc57b-47d1-44c8-82d5-8edbeac88252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d98a404e-e924-48fb-9651-761c6f75a445.png",
														"url": "https://staging-media.toyota.com.ar/d98a404e-e924-48fb-9651-761c6f75a445.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "61404b14-9eaa-4e0e-86ae-a0e8a19f5458.png",
														"url": "https://staging-media.toyota.com.ar/61404b14-9eaa-4e0e-86ae-a0e8a19f5458.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6",
														"path": "280c1195-1a00-41ed-b690-70a1dd814e69.png",
														"url": "https://staging-media.toyota.com.ar/280c1195-1a00-41ed-b690-70a1dd814e69.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 2",
														"path": "80b4db3c-2f6a-4495-b4db-fbc76e13b77f.jpeg",
														"url": "https://staging-media.toyota.com.br/80b4db3c-2f6a-4495-b4db-fbc76e13b77f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 3",
														"path": "7c5539b6-dd85-48ed-a1b6-c6b61e5978dd.jpeg",
														"url": "https://staging-media.toyota.com.ar/7c5539b6-dd85-48ed-a1b6-c6b61e5978dd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 4",
														"path": "a33eadca-f310-425b-9eb5-4e91cb96dcd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/a33eadca-f310-425b-9eb5-4e91cb96dcd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 5",
														"path": "960341af-4b03-46a0-8c24-777ba9916313.jpeg",
														"url": "https://staging-media.toyota.com.ar/960341af-4b03-46a0-8c24-777ba9916313.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 6",
														"path": "36354508-ab61-4c7e-bc91-0e6cc761364f.jpeg",
														"url": "https://staging-media.toyota.com.ar/36354508-ab61-4c7e-bc91-0e6cc761364f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR ",
														"path": "cc94a455-6d86-4304-807c-85bd000aaece.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc94a455-6d86-4304-807c-85bd000aaece.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 1",
														"path": "f9e8da53-2c2a-4828-ae2b-d7cb5b2bdd02.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9e8da53-2c2a-4828-ae2b-d7cb5b2bdd02.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 1",
														"path": "554b112c-e3b3-4f39-b623-c672d2222a85.jpeg",
														"url": "https://staging-media.toyota.com.ar/554b112c-e3b3-4f39-b623-c672d2222a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 2",
														"path": "510c2e40-5171-4106-b5e1-196e5f5bf692.jpeg",
														"url": "https://staging-media.toyota.com.ar/510c2e40-5171-4106-b5e1-196e5f5bf692.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR ",
														"path": "49893591-66e3-4102-904d-d95060abd8a5.jpeg",
														"url": "https://staging-media.toyota.com.ar/49893591-66e3-4102-904d-d95060abd8a5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 4",
														"path": "d8f12dd0-8a43-4303-8109-7e9f29b3a1b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/d8f12dd0-8a43-4303-8109-7e9f29b3a1b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 5",
														"path": "2c8b7548-259e-4cb6-a242-5a947c599216.jpeg",
														"url": "https://staging-media.toyota.com.ar/2c8b7548-259e-4cb6-a242-5a947c599216.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 6",
														"path": "5537d344-deb0-458d-bc73-f2eaa8c7b353.jpeg",
														"url": "https://staging-media.toyota.com.ar/5537d344-deb0-458d-bc73-f2eaa8c7b353.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 7",
														"path": "bdc06ca3-fd1b-491a-a457-544550bc6a65.jpeg",
														"url": "https://staging-media.toyota.com.ar/bdc06ca3-fd1b-491a-a457-544550bc6a65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 8",
														"path": "98c2171a-a33e-4763-b5fa-8875ea68403c.jpeg",
														"url": "https://staging-media.toyota.com.ar/98c2171a-a33e-4763-b5fa-8875ea68403c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6",
														"path": "212139d0-744d-4eda-9473-ff58961afa3e.png",
														"url": "https://staging-media.toyota.com.ar/212139d0-744d-4eda-9473-ff58961afa3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 ",
														"path": "d2d38be0-2375-473a-b9ce-74edd52185f0.png",
														"url": "https://staging-media.toyota.com.ar/d2d38be0-2375-473a-b9ce-74edd52185f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "16058157-9880-4e19-951d-27227a99b28a.png",
														"url": "https://staging-media.toyota.com.ar/16058157-9880-4e19-951d-27227a99b28a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross",
														"path": "c9f16ffe-e73f-482e-8ac4-dfa394ca05ab.png",
														"url": "https://staging-media.toyota.com.ar/c9f16ffe-e73f-482e-8ac4-dfa394ca05ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Cross - Banner Dsk",
														"path": "589b79e1-ea8c-4990-b557-2435c8d96ea5.jpeg",
														"url": "https://staging-media.toyota.com.ar/589b79e1-ea8c-4990-b557-2435c8d96ea5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Cross - Banner mobile",
														"path": "b4442f2f-e17f-4509-8062-7729a9676fea.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4442f2f-e17f-4509-8062-7729a9676fea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Cross - Sem Hover",
														"path": "2594f8f7-8c2e-4583-b6a9-8dc9108824e4.png",
														"url": "https://staging-media.toyota.com.ar/2594f8f7-8c2e-4583-b6a9-8dc9108824e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Cross - Hover",
														"path": "9e32bd69-9fb5-407c-8fdd-4ed51913ab72.png",
														"url": "https://staging-media.toyota.com.ar/9e32bd69-9fb5-407c-8fdd-4ed51913ab72.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 162410,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360",
														"path": "f10ebc5f-84c1-4e73-9df6-829980a3b3ff.png",
														"url": "https://staging-media.toyota.com.ar/f10ebc5f-84c1-4e73-9df6-829980a3b3ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "9c000e2d-0e4e-474b-a60e-861dd12676ce.png",
														"url": "https://staging-media.toyota.com.ar/9c000e2d-0e4e-474b-a60e-861dd12676ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 360",
														"path": "e65184b8-477b-4497-852a-dfb9ed41f999.png",
														"url": "https://staging-media.toyota.com.ar/e65184b8-477b-4497-852a-dfb9ed41f999.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "e8ee768e-ba98-484f-ba0e-3f279ff6ff03.png",
														"url": "https://staging-media.toyota.com.ar/e8ee768e-ba98-484f-ba0e-3f279ff6ff03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3",
														"path": "2fa9530f-03dc-45ad-9740-e2ccb7808b51.png",
														"url": "https://staging-media.toyota.com.ar/2fa9530f-03dc-45ad-9740-e2ccb7808b51.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "858dc57b-47d1-44c8-82d5-8edbeac88252.png",
														"url": "https://staging-media.toyota.com.ar/858dc57b-47d1-44c8-82d5-8edbeac88252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d98a404e-e924-48fb-9651-761c6f75a445.png",
														"url": "https://staging-media.toyota.com.ar/d98a404e-e924-48fb-9651-761c6f75a445.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "61404b14-9eaa-4e0e-86ae-a0e8a19f5458.png",
														"url": "https://staging-media.toyota.com.ar/61404b14-9eaa-4e0e-86ae-a0e8a19f5458.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6",
														"path": "280c1195-1a00-41ed-b690-70a1dd814e69.png",
														"url": "https://staging-media.toyota.com.ar/280c1195-1a00-41ed-b690-70a1dd814e69.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 2",
														"path": "80b4db3c-2f6a-4495-b4db-fbc76e13b77f.jpeg",
														"url": "https://staging-media.toyota.com.br/80b4db3c-2f6a-4495-b4db-fbc76e13b77f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 3",
														"path": "7c5539b6-dd85-48ed-a1b6-c6b61e5978dd.jpeg",
														"url": "https://staging-media.toyota.com.ar/7c5539b6-dd85-48ed-a1b6-c6b61e5978dd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 4",
														"path": "a33eadca-f310-425b-9eb5-4e91cb96dcd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/a33eadca-f310-425b-9eb5-4e91cb96dcd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 5",
														"path": "960341af-4b03-46a0-8c24-777ba9916313.jpeg",
														"url": "https://staging-media.toyota.com.ar/960341af-4b03-46a0-8c24-777ba9916313.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 6",
														"path": "36354508-ab61-4c7e-bc91-0e6cc761364f.jpeg",
														"url": "https://staging-media.toyota.com.ar/36354508-ab61-4c7e-bc91-0e6cc761364f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR ",
														"path": "cc94a455-6d86-4304-807c-85bd000aaece.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc94a455-6d86-4304-807c-85bd000aaece.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 1",
														"path": "f9e8da53-2c2a-4828-ae2b-d7cb5b2bdd02.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9e8da53-2c2a-4828-ae2b-d7cb5b2bdd02.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 1",
														"path": "554b112c-e3b3-4f39-b623-c672d2222a85.jpeg",
														"url": "https://staging-media.toyota.com.ar/554b112c-e3b3-4f39-b623-c672d2222a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 2",
														"path": "510c2e40-5171-4106-b5e1-196e5f5bf692.jpeg",
														"url": "https://staging-media.toyota.com.ar/510c2e40-5171-4106-b5e1-196e5f5bf692.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR ",
														"path": "49893591-66e3-4102-904d-d95060abd8a5.jpeg",
														"url": "https://staging-media.toyota.com.ar/49893591-66e3-4102-904d-d95060abd8a5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 4",
														"path": "d8f12dd0-8a43-4303-8109-7e9f29b3a1b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/d8f12dd0-8a43-4303-8109-7e9f29b3a1b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 5",
														"path": "2c8b7548-259e-4cb6-a242-5a947c599216.jpeg",
														"url": "https://staging-media.toyota.com.ar/2c8b7548-259e-4cb6-a242-5a947c599216.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 6",
														"path": "5537d344-deb0-458d-bc73-f2eaa8c7b353.jpeg",
														"url": "https://staging-media.toyota.com.ar/5537d344-deb0-458d-bc73-f2eaa8c7b353.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 7",
														"path": "bdc06ca3-fd1b-491a-a457-544550bc6a65.jpeg",
														"url": "https://staging-media.toyota.com.ar/bdc06ca3-fd1b-491a-a457-544550bc6a65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 8",
														"path": "98c2171a-a33e-4763-b5fa-8875ea68403c.jpeg",
														"url": "https://staging-media.toyota.com.ar/98c2171a-a33e-4763-b5fa-8875ea68403c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6",
														"path": "212139d0-744d-4eda-9473-ff58961afa3e.png",
														"url": "https://staging-media.toyota.com.ar/212139d0-744d-4eda-9473-ff58961afa3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 ",
														"path": "d2d38be0-2375-473a-b9ce-74edd52185f0.png",
														"url": "https://staging-media.toyota.com.ar/d2d38be0-2375-473a-b9ce-74edd52185f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "16058157-9880-4e19-951d-27227a99b28a.png",
														"url": "https://staging-media.toyota.com.ar/16058157-9880-4e19-951d-27227a99b28a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross",
														"path": "c9f16ffe-e73f-482e-8ac4-dfa394ca05ab.png",
														"url": "https://staging-media.toyota.com.ar/c9f16ffe-e73f-482e-8ac4-dfa394ca05ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Cross - Banner Dsk",
														"path": "589b79e1-ea8c-4990-b557-2435c8d96ea5.jpeg",
														"url": "https://staging-media.toyota.com.ar/589b79e1-ea8c-4990-b557-2435c8d96ea5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Cross - Banner mobile",
														"path": "b4442f2f-e17f-4509-8062-7729a9676fea.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4442f2f-e17f-4509-8062-7729a9676fea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Cross - Sem Hover",
														"path": "2594f8f7-8c2e-4583-b6a9-8dc9108824e4.png",
														"url": "https://staging-media.toyota.com.ar/2594f8f7-8c2e-4583-b6a9-8dc9108824e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Cross - Hover",
														"path": "9e32bd69-9fb5-407c-8fdd-4ed51913ab72.png",
														"url": "https://staging-media.toyota.com.ar/9e32bd69-9fb5-407c-8fdd-4ed51913ab72.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 162720,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360",
														"path": "f10ebc5f-84c1-4e73-9df6-829980a3b3ff.png",
														"url": "https://staging-media.toyota.com.ar/f10ebc5f-84c1-4e73-9df6-829980a3b3ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "9c000e2d-0e4e-474b-a60e-861dd12676ce.png",
														"url": "https://staging-media.toyota.com.ar/9c000e2d-0e4e-474b-a60e-861dd12676ce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 360",
														"path": "e65184b8-477b-4497-852a-dfb9ed41f999.png",
														"url": "https://staging-media.toyota.com.ar/e65184b8-477b-4497-852a-dfb9ed41f999.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "e8ee768e-ba98-484f-ba0e-3f279ff6ff03.png",
														"url": "https://staging-media.toyota.com.ar/e8ee768e-ba98-484f-ba0e-3f279ff6ff03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3",
														"path": "2fa9530f-03dc-45ad-9740-e2ccb7808b51.png",
														"url": "https://staging-media.toyota.com.ar/2fa9530f-03dc-45ad-9740-e2ccb7808b51.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "858dc57b-47d1-44c8-82d5-8edbeac88252.png",
														"url": "https://staging-media.toyota.com.ar/858dc57b-47d1-44c8-82d5-8edbeac88252.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215",
														"path": "d98a404e-e924-48fb-9651-761c6f75a445.png",
														"url": "https://staging-media.toyota.com.ar/d98a404e-e924-48fb-9651-761c6f75a445.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "61404b14-9eaa-4e0e-86ae-a0e8a19f5458.png",
														"url": "https://staging-media.toyota.com.ar/61404b14-9eaa-4e0e-86ae-a0e8a19f5458.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6",
														"path": "280c1195-1a00-41ed-b690-70a1dd814e69.png",
														"url": "https://staging-media.toyota.com.ar/280c1195-1a00-41ed-b690-70a1dd814e69.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 2",
														"path": "80b4db3c-2f6a-4495-b4db-fbc76e13b77f.jpeg",
														"url": "https://staging-media.toyota.com.br/80b4db3c-2f6a-4495-b4db-fbc76e13b77f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 3",
														"path": "7c5539b6-dd85-48ed-a1b6-c6b61e5978dd.jpeg",
														"url": "https://staging-media.toyota.com.ar/7c5539b6-dd85-48ed-a1b6-c6b61e5978dd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 4",
														"path": "a33eadca-f310-425b-9eb5-4e91cb96dcd7.jpeg",
														"url": "https://staging-media.toyota.com.ar/a33eadca-f310-425b-9eb5-4e91cb96dcd7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 5",
														"path": "960341af-4b03-46a0-8c24-777ba9916313.jpeg",
														"url": "https://staging-media.toyota.com.ar/960341af-4b03-46a0-8c24-777ba9916313.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 6",
														"path": "36354508-ab61-4c7e-bc91-0e6cc761364f.jpeg",
														"url": "https://staging-media.toyota.com.ar/36354508-ab61-4c7e-bc91-0e6cc761364f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR ",
														"path": "cc94a455-6d86-4304-807c-85bd000aaece.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc94a455-6d86-4304-807c-85bd000aaece.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "XR 1",
														"path": "f9e8da53-2c2a-4828-ae2b-d7cb5b2bdd02.jpeg",
														"url": "https://staging-media.toyota.com.ar/f9e8da53-2c2a-4828-ae2b-d7cb5b2bdd02.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 1",
														"path": "554b112c-e3b3-4f39-b623-c672d2222a85.jpeg",
														"url": "https://staging-media.toyota.com.ar/554b112c-e3b3-4f39-b623-c672d2222a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 2",
														"path": "510c2e40-5171-4106-b5e1-196e5f5bf692.jpeg",
														"url": "https://staging-media.toyota.com.ar/510c2e40-5171-4106-b5e1-196e5f5bf692.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR ",
														"path": "49893591-66e3-4102-904d-d95060abd8a5.jpeg",
														"url": "https://staging-media.toyota.com.ar/49893591-66e3-4102-904d-d95060abd8a5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 4",
														"path": "d8f12dd0-8a43-4303-8109-7e9f29b3a1b5.jpeg",
														"url": "https://staging-media.toyota.com.ar/d8f12dd0-8a43-4303-8109-7e9f29b3a1b5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 5",
														"path": "2c8b7548-259e-4cb6-a242-5a947c599216.jpeg",
														"url": "https://staging-media.toyota.com.ar/2c8b7548-259e-4cb6-a242-5a947c599216.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 6",
														"path": "5537d344-deb0-458d-bc73-f2eaa8c7b353.jpeg",
														"url": "https://staging-media.toyota.com.ar/5537d344-deb0-458d-bc73-f2eaa8c7b353.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 7",
														"path": "bdc06ca3-fd1b-491a-a457-544550bc6a65.jpeg",
														"url": "https://staging-media.toyota.com.ar/bdc06ca3-fd1b-491a-a457-544550bc6a65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "XR 8",
														"path": "98c2171a-a33e-4763-b5fa-8875ea68403c.jpeg",
														"url": "https://staging-media.toyota.com.ar/98c2171a-a33e-4763-b5fa-8875ea68403c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6",
														"path": "212139d0-744d-4eda-9473-ff58961afa3e.png",
														"url": "https://staging-media.toyota.com.ar/212139d0-744d-4eda-9473-ff58961afa3e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 ",
														"path": "d2d38be0-2375-473a-b9ce-74edd52185f0.png",
														"url": "https://staging-media.toyota.com.ar/d2d38be0-2375-473a-b9ce-74edd52185f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "16058157-9880-4e19-951d-27227a99b28a.png",
														"url": "https://staging-media.toyota.com.ar/16058157-9880-4e19-951d-27227a99b28a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross",
														"path": "c9f16ffe-e73f-482e-8ac4-dfa394ca05ab.png",
														"url": "https://staging-media.toyota.com.ar/c9f16ffe-e73f-482e-8ac4-dfa394ca05ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Cross - Banner Dsk",
														"path": "589b79e1-ea8c-4990-b557-2435c8d96ea5.jpeg",
														"url": "https://staging-media.toyota.com.ar/589b79e1-ea8c-4990-b557-2435c8d96ea5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Cross - Banner mobile",
														"path": "b4442f2f-e17f-4509-8062-7729a9676fea.jpeg",
														"url": "https://staging-media.toyota.com.ar/b4442f2f-e17f-4509-8062-7729a9676fea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Cross - Sem Hover",
														"path": "2594f8f7-8c2e-4583-b6a9-8dc9108824e4.png",
														"url": "https://staging-media.toyota.com.ar/2594f8f7-8c2e-4583-b6a9-8dc9108824e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Cross - Hover",
														"path": "9e32bd69-9fb5-407c-8fdd-4ed51913ab72.png",
														"url": "https://staging-media.toyota.com.ar/9e32bd69-9fb5-407c-8fdd-4ed51913ab72.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 158290,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Cross - Banner Mobile",
														"path": "89779610-4c0f-43d8-a9a4-3bcca44daf1e.jpeg",
														"url": "https://staging-media.toyota.com.br/89779610-4c0f-43d8-a9a4-3bcca44daf1e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display XR 1",
														"path": "9943f73b-71dc-4083-a100-e66e20482526.png",
														"url": "https://staging-media.toyota.com.ar/9943f73b-71dc-4083-a100-e66e20482526.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display XR 2",
														"path": "6e9fd5d7-855e-423e-b6c8-79fc4a49cbff.png",
														"url": "https://staging-media.toyota.com.ar/6e9fd5d7-855e-423e-b6c8-79fc4a49cbff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 XR DESK",
														"path": "1c8da436-678e-4b8f-9e29-db2a8ed060b0.png",
														"url": "https://staging-media.toyota.com.ar/1c8da436-678e-4b8f-9e29-db2a8ed060b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "d294aa42-61d6-4882-bbea-6c7a3282afca.png",
														"url": "https://staging-media.toyota.com.ar/d294aa42-61d6-4882-bbea-6c7a3282afca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 XR DESK",
														"path": "9ae24d4b-9982-474e-a9ab-53dba1f04d5b.png",
														"url": "https://staging-media.toyota.com.ar/9ae24d4b-9982-474e-a9ab-53dba1f04d5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 XR MOBILE",
														"path": "401770a5-8463-4adc-b00e-1bc14ff7418c.png",
														"url": "https://staging-media.toyota.com.ar/401770a5-8463-4adc-b00e-1bc14ff7418c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 XR DESK",
														"path": "744affd2-998e-4928-8820-2d94aeaec5ba.png",
														"url": "https://staging-media.toyota.com.ar/744affd2-998e-4928-8820-2d94aeaec5ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 XR MOBILE",
														"path": "a378dccf-a9ae-414d-8e1b-6bfb2f385e13.png",
														"url": "https://staging-media.toyota.com.ar/a378dccf-a9ae-414d-8e1b-6bfb2f385e13.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 XR DESK",
														"path": "f06a02e7-fc69-4fbd-88f2-db2652593a93.png",
														"url": "https://staging-media.toyota.com.ar/f06a02e7-fc69-4fbd-88f2-db2652593a93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 XR MOBILE",
														"path": "03b5bcec-bf96-4896-be7e-dc7d94c6a08a.png",
														"url": "https://staging-media.toyota.com.ar/03b5bcec-bf96-4896-be7e-dc7d94c6a08a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 XR DESK",
														"path": "3168f087-aa1f-4ecb-aae5-b4654a05a3c6.png",
														"url": "https://staging-media.toyota.com.ar/3168f087-aa1f-4ecb-aae5-b4654a05a3c6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 XR MOBILE",
														"path": "3edf1d65-c684-4311-af1c-527a826718b0.png",
														"url": "https://staging-media.toyota.com.ar/3edf1d65-c684-4311-af1c-527a826718b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross - Logo",
														"path": "14bddd89-c4bb-4436-a04a-578ac978e2ad.png",
														"url": "https://staging-media.toyota.com.br/14bddd89-c4bb-4436-a04a-578ac978e2ad.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Cross - Banner Dsk",
														"path": "7684fbc8-2feb-46bc-b407-d007b077f50e.jpeg",
														"url": "https://staging-media.toyota.com.br/7684fbc8-2feb-46bc-b407-d007b077f50e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria intena - CR ",
														"path": "36d83c9f-b4fd-4a8e-a53f-b0035f4aa721.jpeg",
														"url": "https://staging-media.toyota.com.br/36d83c9f-b4fd-4a8e-a53f-b0035f4aa721.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria intena - CR ",
														"path": "1be8bcfa-029b-4894-abaf-05bbb8633a51.jpeg",
														"url": "https://staging-media.toyota.com.br/1be8bcfa-029b-4894-abaf-05bbb8633a51.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"url": "https://staging-media.toyota.com.br/5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "06172c85-6632-4881-9df9-b8f555b22fd8.png",
														"url": "https://staging-media.toyota.com.br/06172c85-6632-4881-9df9-b8f555b22fd8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"url": "https://staging-media.toyota.com.br/5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"url": "https://staging-media.toyota.com.br/76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "60f551fe-3bff-4eeb-856c-6f0b0ab3b575.png",
														"url": "https://staging-media.toyota.com.br/60f551fe-3bff-4eeb-856c-6f0b0ab3b575.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"url": "https://staging-media.toyota.com.br/76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"url": "https://staging-media.toyota.com.br/e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "8e3b3137-94b4-4bac-9071-eaf40dc0df84.png",
														"url": "https://staging-media.toyota.com.br/8e3b3137-94b4-4bac-9071-eaf40dc0df84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"url": "https://staging-media.toyota.com.br/e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"url": "https://staging-media.toyota.com.br/3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "6f5d3e61-37c3-41a8-8140-0a2df8ea2343.png",
														"url": "https://staging-media.toyota.com.br/6f5d3e61-37c3-41a8-8140-0a2df8ea2343.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"url": "https://staging-media.toyota.com.br/3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "2c1448e4-40c1-4486-848e-670832559151.png",
														"url": "https://staging-media.toyota.com.br/2c1448e4-40c1-4486-848e-670832559151.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "d3a218cc-c1f1-4de5-8834-4cc4accf290a.png",
														"url": "https://staging-media.toyota.com.br/d3a218cc-c1f1-4de5-8834-4cc4accf290a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "2c1448e4-40c1-4486-848e-670832559151.png",
														"url": "https://staging-media.toyota.com.br/2c1448e4-40c1-4486-848e-670832559151.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"url": "https://staging-media.toyota.com.br/74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "59209bf8-c7b7-46b1-812c-24060a7d8608.png",
														"url": "https://staging-media.toyota.com.br/59209bf8-c7b7-46b1-812c-24060a7d8608.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"url": "https://staging-media.toyota.com.br/74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "01",
														"path": "ad80c28d-8b49-45ee-9025-f826fb0c610d.png",
														"url": "https://staging-media.toyota.com.br/ad80c28d-8b49-45ee-9025-f826fb0c610d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "02",
														"path": "2a4e945e-4e12-4610-999d-98a873306127.png",
														"url": "https://staging-media.toyota.com.br/2a4e945e-4e12-4610-999d-98a873306127.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 160310,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Cross - Banner Mobile",
														"path": "89779610-4c0f-43d8-a9a4-3bcca44daf1e.jpeg",
														"url": "https://staging-media.toyota.com.br/89779610-4c0f-43d8-a9a4-3bcca44daf1e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display XR 1",
														"path": "9943f73b-71dc-4083-a100-e66e20482526.png",
														"url": "https://staging-media.toyota.com.ar/9943f73b-71dc-4083-a100-e66e20482526.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display XR 2",
														"path": "6e9fd5d7-855e-423e-b6c8-79fc4a49cbff.png",
														"url": "https://staging-media.toyota.com.ar/6e9fd5d7-855e-423e-b6c8-79fc4a49cbff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 XR DESK",
														"path": "1c8da436-678e-4b8f-9e29-db2a8ed060b0.png",
														"url": "https://staging-media.toyota.com.ar/1c8da436-678e-4b8f-9e29-db2a8ed060b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "d294aa42-61d6-4882-bbea-6c7a3282afca.png",
														"url": "https://staging-media.toyota.com.ar/d294aa42-61d6-4882-bbea-6c7a3282afca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 XR DESK",
														"path": "9ae24d4b-9982-474e-a9ab-53dba1f04d5b.png",
														"url": "https://staging-media.toyota.com.ar/9ae24d4b-9982-474e-a9ab-53dba1f04d5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 XR MOBILE",
														"path": "401770a5-8463-4adc-b00e-1bc14ff7418c.png",
														"url": "https://staging-media.toyota.com.ar/401770a5-8463-4adc-b00e-1bc14ff7418c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 XR DESK",
														"path": "744affd2-998e-4928-8820-2d94aeaec5ba.png",
														"url": "https://staging-media.toyota.com.ar/744affd2-998e-4928-8820-2d94aeaec5ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 XR MOBILE",
														"path": "a378dccf-a9ae-414d-8e1b-6bfb2f385e13.png",
														"url": "https://staging-media.toyota.com.ar/a378dccf-a9ae-414d-8e1b-6bfb2f385e13.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 XR DESK",
														"path": "f06a02e7-fc69-4fbd-88f2-db2652593a93.png",
														"url": "https://staging-media.toyota.com.ar/f06a02e7-fc69-4fbd-88f2-db2652593a93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 XR MOBILE",
														"path": "03b5bcec-bf96-4896-be7e-dc7d94c6a08a.png",
														"url": "https://staging-media.toyota.com.ar/03b5bcec-bf96-4896-be7e-dc7d94c6a08a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 XR DESK",
														"path": "3168f087-aa1f-4ecb-aae5-b4654a05a3c6.png",
														"url": "https://staging-media.toyota.com.ar/3168f087-aa1f-4ecb-aae5-b4654a05a3c6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 XR MOBILE",
														"path": "3edf1d65-c684-4311-af1c-527a826718b0.png",
														"url": "https://staging-media.toyota.com.ar/3edf1d65-c684-4311-af1c-527a826718b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross - Logo",
														"path": "14bddd89-c4bb-4436-a04a-578ac978e2ad.png",
														"url": "https://staging-media.toyota.com.br/14bddd89-c4bb-4436-a04a-578ac978e2ad.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Cross - Banner Dsk",
														"path": "7684fbc8-2feb-46bc-b407-d007b077f50e.jpeg",
														"url": "https://staging-media.toyota.com.br/7684fbc8-2feb-46bc-b407-d007b077f50e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria intena - CR ",
														"path": "36d83c9f-b4fd-4a8e-a53f-b0035f4aa721.jpeg",
														"url": "https://staging-media.toyota.com.br/36d83c9f-b4fd-4a8e-a53f-b0035f4aa721.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria intena - CR ",
														"path": "1be8bcfa-029b-4894-abaf-05bbb8633a51.jpeg",
														"url": "https://staging-media.toyota.com.br/1be8bcfa-029b-4894-abaf-05bbb8633a51.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"url": "https://staging-media.toyota.com.br/5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "06172c85-6632-4881-9df9-b8f555b22fd8.png",
														"url": "https://staging-media.toyota.com.br/06172c85-6632-4881-9df9-b8f555b22fd8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"url": "https://staging-media.toyota.com.br/5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"url": "https://staging-media.toyota.com.br/76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "60f551fe-3bff-4eeb-856c-6f0b0ab3b575.png",
														"url": "https://staging-media.toyota.com.br/60f551fe-3bff-4eeb-856c-6f0b0ab3b575.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"url": "https://staging-media.toyota.com.br/76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"url": "https://staging-media.toyota.com.br/e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "8e3b3137-94b4-4bac-9071-eaf40dc0df84.png",
														"url": "https://staging-media.toyota.com.br/8e3b3137-94b4-4bac-9071-eaf40dc0df84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"url": "https://staging-media.toyota.com.br/e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"url": "https://staging-media.toyota.com.br/3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "6f5d3e61-37c3-41a8-8140-0a2df8ea2343.png",
														"url": "https://staging-media.toyota.com.br/6f5d3e61-37c3-41a8-8140-0a2df8ea2343.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"url": "https://staging-media.toyota.com.br/3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "2c1448e4-40c1-4486-848e-670832559151.png",
														"url": "https://staging-media.toyota.com.br/2c1448e4-40c1-4486-848e-670832559151.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "d3a218cc-c1f1-4de5-8834-4cc4accf290a.png",
														"url": "https://staging-media.toyota.com.br/d3a218cc-c1f1-4de5-8834-4cc4accf290a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "2c1448e4-40c1-4486-848e-670832559151.png",
														"url": "https://staging-media.toyota.com.br/2c1448e4-40c1-4486-848e-670832559151.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"url": "https://staging-media.toyota.com.br/74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "59209bf8-c7b7-46b1-812c-24060a7d8608.png",
														"url": "https://staging-media.toyota.com.br/59209bf8-c7b7-46b1-812c-24060a7d8608.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"url": "https://staging-media.toyota.com.br/74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "01",
														"path": "ad80c28d-8b49-45ee-9025-f826fb0c610d.png",
														"url": "https://staging-media.toyota.com.br/ad80c28d-8b49-45ee-9025-f826fb0c610d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "02",
														"path": "2a4e945e-4e12-4610-999d-98a873306127.png",
														"url": "https://staging-media.toyota.com.br/2a4e945e-4e12-4610-999d-98a873306127.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 160310,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Cross - Banner Mobile",
														"path": "89779610-4c0f-43d8-a9a4-3bcca44daf1e.jpeg",
														"url": "https://staging-media.toyota.com.br/89779610-4c0f-43d8-a9a4-3bcca44daf1e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display XR 1",
														"path": "9943f73b-71dc-4083-a100-e66e20482526.png",
														"url": "https://staging-media.toyota.com.ar/9943f73b-71dc-4083-a100-e66e20482526.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display XR 2",
														"path": "6e9fd5d7-855e-423e-b6c8-79fc4a49cbff.png",
														"url": "https://staging-media.toyota.com.ar/6e9fd5d7-855e-423e-b6c8-79fc4a49cbff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 XR DESK",
														"path": "1c8da436-678e-4b8f-9e29-db2a8ed060b0.png",
														"url": "https://staging-media.toyota.com.ar/1c8da436-678e-4b8f-9e29-db2a8ed060b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "d294aa42-61d6-4882-bbea-6c7a3282afca.png",
														"url": "https://staging-media.toyota.com.ar/d294aa42-61d6-4882-bbea-6c7a3282afca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 XR DESK",
														"path": "9ae24d4b-9982-474e-a9ab-53dba1f04d5b.png",
														"url": "https://staging-media.toyota.com.ar/9ae24d4b-9982-474e-a9ab-53dba1f04d5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 XR MOBILE",
														"path": "401770a5-8463-4adc-b00e-1bc14ff7418c.png",
														"url": "https://staging-media.toyota.com.ar/401770a5-8463-4adc-b00e-1bc14ff7418c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 XR DESK",
														"path": "744affd2-998e-4928-8820-2d94aeaec5ba.png",
														"url": "https://staging-media.toyota.com.ar/744affd2-998e-4928-8820-2d94aeaec5ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 XR MOBILE",
														"path": "a378dccf-a9ae-414d-8e1b-6bfb2f385e13.png",
														"url": "https://staging-media.toyota.com.ar/a378dccf-a9ae-414d-8e1b-6bfb2f385e13.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 XR DESK",
														"path": "f06a02e7-fc69-4fbd-88f2-db2652593a93.png",
														"url": "https://staging-media.toyota.com.ar/f06a02e7-fc69-4fbd-88f2-db2652593a93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 XR MOBILE",
														"path": "03b5bcec-bf96-4896-be7e-dc7d94c6a08a.png",
														"url": "https://staging-media.toyota.com.ar/03b5bcec-bf96-4896-be7e-dc7d94c6a08a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 XR DESK",
														"path": "3168f087-aa1f-4ecb-aae5-b4654a05a3c6.png",
														"url": "https://staging-media.toyota.com.ar/3168f087-aa1f-4ecb-aae5-b4654a05a3c6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 XR MOBILE",
														"path": "3edf1d65-c684-4311-af1c-527a826718b0.png",
														"url": "https://staging-media.toyota.com.ar/3edf1d65-c684-4311-af1c-527a826718b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross - Logo",
														"path": "14bddd89-c4bb-4436-a04a-578ac978e2ad.png",
														"url": "https://staging-media.toyota.com.br/14bddd89-c4bb-4436-a04a-578ac978e2ad.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Cross - Banner Dsk",
														"path": "7684fbc8-2feb-46bc-b407-d007b077f50e.jpeg",
														"url": "https://staging-media.toyota.com.br/7684fbc8-2feb-46bc-b407-d007b077f50e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria intena - CR ",
														"path": "36d83c9f-b4fd-4a8e-a53f-b0035f4aa721.jpeg",
														"url": "https://staging-media.toyota.com.br/36d83c9f-b4fd-4a8e-a53f-b0035f4aa721.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria intena - CR ",
														"path": "1be8bcfa-029b-4894-abaf-05bbb8633a51.jpeg",
														"url": "https://staging-media.toyota.com.br/1be8bcfa-029b-4894-abaf-05bbb8633a51.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"url": "https://staging-media.toyota.com.br/5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "06172c85-6632-4881-9df9-b8f555b22fd8.png",
														"url": "https://staging-media.toyota.com.br/06172c85-6632-4881-9df9-b8f555b22fd8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"url": "https://staging-media.toyota.com.br/5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"url": "https://staging-media.toyota.com.br/76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "60f551fe-3bff-4eeb-856c-6f0b0ab3b575.png",
														"url": "https://staging-media.toyota.com.br/60f551fe-3bff-4eeb-856c-6f0b0ab3b575.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"url": "https://staging-media.toyota.com.br/76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"url": "https://staging-media.toyota.com.br/e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "8e3b3137-94b4-4bac-9071-eaf40dc0df84.png",
														"url": "https://staging-media.toyota.com.br/8e3b3137-94b4-4bac-9071-eaf40dc0df84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"url": "https://staging-media.toyota.com.br/e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"url": "https://staging-media.toyota.com.br/3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "6f5d3e61-37c3-41a8-8140-0a2df8ea2343.png",
														"url": "https://staging-media.toyota.com.br/6f5d3e61-37c3-41a8-8140-0a2df8ea2343.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"url": "https://staging-media.toyota.com.br/3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "2c1448e4-40c1-4486-848e-670832559151.png",
														"url": "https://staging-media.toyota.com.br/2c1448e4-40c1-4486-848e-670832559151.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "d3a218cc-c1f1-4de5-8834-4cc4accf290a.png",
														"url": "https://staging-media.toyota.com.br/d3a218cc-c1f1-4de5-8834-4cc4accf290a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "2c1448e4-40c1-4486-848e-670832559151.png",
														"url": "https://staging-media.toyota.com.br/2c1448e4-40c1-4486-848e-670832559151.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"url": "https://staging-media.toyota.com.br/74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "59209bf8-c7b7-46b1-812c-24060a7d8608.png",
														"url": "https://staging-media.toyota.com.br/59209bf8-c7b7-46b1-812c-24060a7d8608.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"url": "https://staging-media.toyota.com.br/74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "01",
														"path": "ad80c28d-8b49-45ee-9025-f826fb0c610d.png",
														"url": "https://staging-media.toyota.com.br/ad80c28d-8b49-45ee-9025-f826fb0c610d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "02",
														"path": "2a4e945e-4e12-4610-999d-98a873306127.png",
														"url": "https://staging-media.toyota.com.br/2a4e945e-4e12-4610-999d-98a873306127.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 160310,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Cross - Banner Mobile",
														"path": "89779610-4c0f-43d8-a9a4-3bcca44daf1e.jpeg",
														"url": "https://staging-media.toyota.com.br/89779610-4c0f-43d8-a9a4-3bcca44daf1e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display XR 1",
														"path": "9943f73b-71dc-4083-a100-e66e20482526.png",
														"url": "https://staging-media.toyota.com.ar/9943f73b-71dc-4083-a100-e66e20482526.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display XR 2",
														"path": "6e9fd5d7-855e-423e-b6c8-79fc4a49cbff.png",
														"url": "https://staging-media.toyota.com.ar/6e9fd5d7-855e-423e-b6c8-79fc4a49cbff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 XR DESK",
														"path": "1c8da436-678e-4b8f-9e29-db2a8ed060b0.png",
														"url": "https://staging-media.toyota.com.ar/1c8da436-678e-4b8f-9e29-db2a8ed060b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "d294aa42-61d6-4882-bbea-6c7a3282afca.png",
														"url": "https://staging-media.toyota.com.ar/d294aa42-61d6-4882-bbea-6c7a3282afca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 XR DESK",
														"path": "9ae24d4b-9982-474e-a9ab-53dba1f04d5b.png",
														"url": "https://staging-media.toyota.com.ar/9ae24d4b-9982-474e-a9ab-53dba1f04d5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 XR MOBILE",
														"path": "401770a5-8463-4adc-b00e-1bc14ff7418c.png",
														"url": "https://staging-media.toyota.com.ar/401770a5-8463-4adc-b00e-1bc14ff7418c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 XR DESK",
														"path": "744affd2-998e-4928-8820-2d94aeaec5ba.png",
														"url": "https://staging-media.toyota.com.ar/744affd2-998e-4928-8820-2d94aeaec5ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 XR MOBILE",
														"path": "a378dccf-a9ae-414d-8e1b-6bfb2f385e13.png",
														"url": "https://staging-media.toyota.com.ar/a378dccf-a9ae-414d-8e1b-6bfb2f385e13.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 XR DESK",
														"path": "f06a02e7-fc69-4fbd-88f2-db2652593a93.png",
														"url": "https://staging-media.toyota.com.ar/f06a02e7-fc69-4fbd-88f2-db2652593a93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 XR MOBILE",
														"path": "03b5bcec-bf96-4896-be7e-dc7d94c6a08a.png",
														"url": "https://staging-media.toyota.com.ar/03b5bcec-bf96-4896-be7e-dc7d94c6a08a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 XR DESK",
														"path": "3168f087-aa1f-4ecb-aae5-b4654a05a3c6.png",
														"url": "https://staging-media.toyota.com.ar/3168f087-aa1f-4ecb-aae5-b4654a05a3c6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 XR MOBILE",
														"path": "3edf1d65-c684-4311-af1c-527a826718b0.png",
														"url": "https://staging-media.toyota.com.ar/3edf1d65-c684-4311-af1c-527a826718b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross - Logo",
														"path": "14bddd89-c4bb-4436-a04a-578ac978e2ad.png",
														"url": "https://staging-media.toyota.com.br/14bddd89-c4bb-4436-a04a-578ac978e2ad.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Cross - Banner Dsk",
														"path": "7684fbc8-2feb-46bc-b407-d007b077f50e.jpeg",
														"url": "https://staging-media.toyota.com.br/7684fbc8-2feb-46bc-b407-d007b077f50e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria intena - CR ",
														"path": "36d83c9f-b4fd-4a8e-a53f-b0035f4aa721.jpeg",
														"url": "https://staging-media.toyota.com.br/36d83c9f-b4fd-4a8e-a53f-b0035f4aa721.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria intena - CR ",
														"path": "1be8bcfa-029b-4894-abaf-05bbb8633a51.jpeg",
														"url": "https://staging-media.toyota.com.br/1be8bcfa-029b-4894-abaf-05bbb8633a51.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"url": "https://staging-media.toyota.com.br/5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "06172c85-6632-4881-9df9-b8f555b22fd8.png",
														"url": "https://staging-media.toyota.com.br/06172c85-6632-4881-9df9-b8f555b22fd8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"url": "https://staging-media.toyota.com.br/5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"url": "https://staging-media.toyota.com.br/76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "60f551fe-3bff-4eeb-856c-6f0b0ab3b575.png",
														"url": "https://staging-media.toyota.com.br/60f551fe-3bff-4eeb-856c-6f0b0ab3b575.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"url": "https://staging-media.toyota.com.br/76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"url": "https://staging-media.toyota.com.br/e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "8e3b3137-94b4-4bac-9071-eaf40dc0df84.png",
														"url": "https://staging-media.toyota.com.br/8e3b3137-94b4-4bac-9071-eaf40dc0df84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"url": "https://staging-media.toyota.com.br/e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"url": "https://staging-media.toyota.com.br/3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "6f5d3e61-37c3-41a8-8140-0a2df8ea2343.png",
														"url": "https://staging-media.toyota.com.br/6f5d3e61-37c3-41a8-8140-0a2df8ea2343.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"url": "https://staging-media.toyota.com.br/3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "2c1448e4-40c1-4486-848e-670832559151.png",
														"url": "https://staging-media.toyota.com.br/2c1448e4-40c1-4486-848e-670832559151.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "d3a218cc-c1f1-4de5-8834-4cc4accf290a.png",
														"url": "https://staging-media.toyota.com.br/d3a218cc-c1f1-4de5-8834-4cc4accf290a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "2c1448e4-40c1-4486-848e-670832559151.png",
														"url": "https://staging-media.toyota.com.br/2c1448e4-40c1-4486-848e-670832559151.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"url": "https://staging-media.toyota.com.br/74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "59209bf8-c7b7-46b1-812c-24060a7d8608.png",
														"url": "https://staging-media.toyota.com.br/59209bf8-c7b7-46b1-812c-24060a7d8608.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"url": "https://staging-media.toyota.com.br/74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "01",
														"path": "ad80c28d-8b49-45ee-9025-f826fb0c610d.png",
														"url": "https://staging-media.toyota.com.br/ad80c28d-8b49-45ee-9025-f826fb0c610d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "02",
														"path": "2a4e945e-4e12-4610-999d-98a873306127.png",
														"url": "https://staging-media.toyota.com.br/2a4e945e-4e12-4610-999d-98a873306127.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 160310,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Cross - Banner Mobile",
														"path": "89779610-4c0f-43d8-a9a4-3bcca44daf1e.jpeg",
														"url": "https://staging-media.toyota.com.br/89779610-4c0f-43d8-a9a4-3bcca44daf1e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display XR 1",
														"path": "9943f73b-71dc-4083-a100-e66e20482526.png",
														"url": "https://staging-media.toyota.com.ar/9943f73b-71dc-4083-a100-e66e20482526.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display XR 2",
														"path": "6e9fd5d7-855e-423e-b6c8-79fc4a49cbff.png",
														"url": "https://staging-media.toyota.com.ar/6e9fd5d7-855e-423e-b6c8-79fc4a49cbff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 XR DESK",
														"path": "1c8da436-678e-4b8f-9e29-db2a8ed060b0.png",
														"url": "https://staging-media.toyota.com.ar/1c8da436-678e-4b8f-9e29-db2a8ed060b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "d294aa42-61d6-4882-bbea-6c7a3282afca.png",
														"url": "https://staging-media.toyota.com.ar/d294aa42-61d6-4882-bbea-6c7a3282afca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 XR DESK",
														"path": "9ae24d4b-9982-474e-a9ab-53dba1f04d5b.png",
														"url": "https://staging-media.toyota.com.ar/9ae24d4b-9982-474e-a9ab-53dba1f04d5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 XR MOBILE",
														"path": "401770a5-8463-4adc-b00e-1bc14ff7418c.png",
														"url": "https://staging-media.toyota.com.ar/401770a5-8463-4adc-b00e-1bc14ff7418c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 XR DESK",
														"path": "744affd2-998e-4928-8820-2d94aeaec5ba.png",
														"url": "https://staging-media.toyota.com.ar/744affd2-998e-4928-8820-2d94aeaec5ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 XR MOBILE",
														"path": "a378dccf-a9ae-414d-8e1b-6bfb2f385e13.png",
														"url": "https://staging-media.toyota.com.ar/a378dccf-a9ae-414d-8e1b-6bfb2f385e13.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 XR DESK",
														"path": "f06a02e7-fc69-4fbd-88f2-db2652593a93.png",
														"url": "https://staging-media.toyota.com.ar/f06a02e7-fc69-4fbd-88f2-db2652593a93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 XR MOBILE",
														"path": "03b5bcec-bf96-4896-be7e-dc7d94c6a08a.png",
														"url": "https://staging-media.toyota.com.ar/03b5bcec-bf96-4896-be7e-dc7d94c6a08a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 XR DESK",
														"path": "3168f087-aa1f-4ecb-aae5-b4654a05a3c6.png",
														"url": "https://staging-media.toyota.com.ar/3168f087-aa1f-4ecb-aae5-b4654a05a3c6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 XR MOBILE",
														"path": "3edf1d65-c684-4311-af1c-527a826718b0.png",
														"url": "https://staging-media.toyota.com.ar/3edf1d65-c684-4311-af1c-527a826718b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross - Logo",
														"path": "14bddd89-c4bb-4436-a04a-578ac978e2ad.png",
														"url": "https://staging-media.toyota.com.br/14bddd89-c4bb-4436-a04a-578ac978e2ad.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Cross - Banner Dsk",
														"path": "7684fbc8-2feb-46bc-b407-d007b077f50e.jpeg",
														"url": "https://staging-media.toyota.com.br/7684fbc8-2feb-46bc-b407-d007b077f50e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria intena - CR ",
														"path": "36d83c9f-b4fd-4a8e-a53f-b0035f4aa721.jpeg",
														"url": "https://staging-media.toyota.com.br/36d83c9f-b4fd-4a8e-a53f-b0035f4aa721.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria intena - CR ",
														"path": "1be8bcfa-029b-4894-abaf-05bbb8633a51.jpeg",
														"url": "https://staging-media.toyota.com.br/1be8bcfa-029b-4894-abaf-05bbb8633a51.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"url": "https://staging-media.toyota.com.br/5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "06172c85-6632-4881-9df9-b8f555b22fd8.png",
														"url": "https://staging-media.toyota.com.br/06172c85-6632-4881-9df9-b8f555b22fd8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"url": "https://staging-media.toyota.com.br/5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"url": "https://staging-media.toyota.com.br/76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "60f551fe-3bff-4eeb-856c-6f0b0ab3b575.png",
														"url": "https://staging-media.toyota.com.br/60f551fe-3bff-4eeb-856c-6f0b0ab3b575.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"url": "https://staging-media.toyota.com.br/76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"url": "https://staging-media.toyota.com.br/e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "8e3b3137-94b4-4bac-9071-eaf40dc0df84.png",
														"url": "https://staging-media.toyota.com.br/8e3b3137-94b4-4bac-9071-eaf40dc0df84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"url": "https://staging-media.toyota.com.br/e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"url": "https://staging-media.toyota.com.br/3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "6f5d3e61-37c3-41a8-8140-0a2df8ea2343.png",
														"url": "https://staging-media.toyota.com.br/6f5d3e61-37c3-41a8-8140-0a2df8ea2343.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"url": "https://staging-media.toyota.com.br/3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "2c1448e4-40c1-4486-848e-670832559151.png",
														"url": "https://staging-media.toyota.com.br/2c1448e4-40c1-4486-848e-670832559151.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "d3a218cc-c1f1-4de5-8834-4cc4accf290a.png",
														"url": "https://staging-media.toyota.com.br/d3a218cc-c1f1-4de5-8834-4cc4accf290a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "2c1448e4-40c1-4486-848e-670832559151.png",
														"url": "https://staging-media.toyota.com.br/2c1448e4-40c1-4486-848e-670832559151.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"url": "https://staging-media.toyota.com.br/74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "59209bf8-c7b7-46b1-812c-24060a7d8608.png",
														"url": "https://staging-media.toyota.com.br/59209bf8-c7b7-46b1-812c-24060a7d8608.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"url": "https://staging-media.toyota.com.br/74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "01",
														"path": "ad80c28d-8b49-45ee-9025-f826fb0c610d.png",
														"url": "https://staging-media.toyota.com.br/ad80c28d-8b49-45ee-9025-f826fb0c610d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "02",
														"path": "2a4e945e-4e12-4610-999d-98a873306127.png",
														"url": "https://staging-media.toyota.com.br/2a4e945e-4e12-4610-999d-98a873306127.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 160620,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Corolla Cross - Banner Mobile",
														"path": "89779610-4c0f-43d8-a9a4-3bcca44daf1e.jpeg",
														"url": "https://staging-media.toyota.com.br/89779610-4c0f-43d8-a9a4-3bcca44daf1e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display XR 1",
														"path": "9943f73b-71dc-4083-a100-e66e20482526.png",
														"url": "https://staging-media.toyota.com.ar/9943f73b-71dc-4083-a100-e66e20482526.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display XR 2",
														"path": "6e9fd5d7-855e-423e-b6c8-79fc4a49cbff.png",
														"url": "https://staging-media.toyota.com.ar/6e9fd5d7-855e-423e-b6c8-79fc4a49cbff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 XR DESK",
														"path": "1c8da436-678e-4b8f-9e29-db2a8ed060b0.png",
														"url": "https://staging-media.toyota.com.ar/1c8da436-678e-4b8f-9e29-db2a8ed060b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3",
														"path": "d294aa42-61d6-4882-bbea-6c7a3282afca.png",
														"url": "https://staging-media.toyota.com.ar/d294aa42-61d6-4882-bbea-6c7a3282afca.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 XR DESK",
														"path": "9ae24d4b-9982-474e-a9ab-53dba1f04d5b.png",
														"url": "https://staging-media.toyota.com.ar/9ae24d4b-9982-474e-a9ab-53dba1f04d5b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 XR MOBILE",
														"path": "401770a5-8463-4adc-b00e-1bc14ff7418c.png",
														"url": "https://staging-media.toyota.com.ar/401770a5-8463-4adc-b00e-1bc14ff7418c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 XR DESK",
														"path": "744affd2-998e-4928-8820-2d94aeaec5ba.png",
														"url": "https://staging-media.toyota.com.ar/744affd2-998e-4928-8820-2d94aeaec5ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 XR MOBILE",
														"path": "a378dccf-a9ae-414d-8e1b-6bfb2f385e13.png",
														"url": "https://staging-media.toyota.com.ar/a378dccf-a9ae-414d-8e1b-6bfb2f385e13.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 XR DESK",
														"path": "f06a02e7-fc69-4fbd-88f2-db2652593a93.png",
														"url": "https://staging-media.toyota.com.ar/f06a02e7-fc69-4fbd-88f2-db2652593a93.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 XR MOBILE",
														"path": "03b5bcec-bf96-4896-be7e-dc7d94c6a08a.png",
														"url": "https://staging-media.toyota.com.ar/03b5bcec-bf96-4896-be7e-dc7d94c6a08a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 XR DESK",
														"path": "3168f087-aa1f-4ecb-aae5-b4654a05a3c6.png",
														"url": "https://staging-media.toyota.com.ar/3168f087-aa1f-4ecb-aae5-b4654a05a3c6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 XR MOBILE",
														"path": "3edf1d65-c684-4311-af1c-527a826718b0.png",
														"url": "https://staging-media.toyota.com.ar/3edf1d65-c684-4311-af1c-527a826718b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross - Logo",
														"path": "14bddd89-c4bb-4436-a04a-578ac978e2ad.png",
														"url": "https://staging-media.toyota.com.br/14bddd89-c4bb-4436-a04a-578ac978e2ad.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Corolla Cross - Banner Dsk",
														"path": "7684fbc8-2feb-46bc-b407-d007b077f50e.jpeg",
														"url": "https://staging-media.toyota.com.br/7684fbc8-2feb-46bc-b407-d007b077f50e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria intena - CR ",
														"path": "36d83c9f-b4fd-4a8e-a53f-b0035f4aa721.jpeg",
														"url": "https://staging-media.toyota.com.br/36d83c9f-b4fd-4a8e-a53f-b0035f4aa721.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria intena - CR ",
														"path": "1be8bcfa-029b-4894-abaf-05bbb8633a51.jpeg",
														"url": "https://staging-media.toyota.com.br/1be8bcfa-029b-4894-abaf-05bbb8633a51.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "1",
														"path": "5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"url": "https://staging-media.toyota.com.br/5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "02",
														"path": "06172c85-6632-4881-9df9-b8f555b22fd8.png",
														"url": "https://staging-media.toyota.com.br/06172c85-6632-4881-9df9-b8f555b22fd8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "1",
														"path": "5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"url": "https://staging-media.toyota.com.br/5eae6299-0742-4c34-9453-7ec77d2e70bf.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "1",
														"path": "76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"url": "https://staging-media.toyota.com.br/76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "02",
														"path": "60f551fe-3bff-4eeb-856c-6f0b0ab3b575.png",
														"url": "https://staging-media.toyota.com.br/60f551fe-3bff-4eeb-856c-6f0b0ab3b575.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "1",
														"path": "76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"url": "https://staging-media.toyota.com.br/76d3a7fd-a6ec-459b-8672-ed83c85aaa7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "01",
														"path": "e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"url": "https://staging-media.toyota.com.br/e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "02",
														"path": "8e3b3137-94b4-4bac-9071-eaf40dc0df84.png",
														"url": "https://staging-media.toyota.com.br/8e3b3137-94b4-4bac-9071-eaf40dc0df84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "01",
														"path": "e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"url": "https://staging-media.toyota.com.br/e7579a9a-7171-4f64-8e04-d7e7bc4d8a07.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "01",
														"path": "3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"url": "https://staging-media.toyota.com.br/3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "02",
														"path": "6f5d3e61-37c3-41a8-8140-0a2df8ea2343.png",
														"url": "https://staging-media.toyota.com.br/6f5d3e61-37c3-41a8-8140-0a2df8ea2343.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "01",
														"path": "3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"url": "https://staging-media.toyota.com.br/3bdcec2b-6f35-4790-8ee5-00bd5794cf71.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "01",
														"path": "2c1448e4-40c1-4486-848e-670832559151.png",
														"url": "https://staging-media.toyota.com.br/2c1448e4-40c1-4486-848e-670832559151.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "02",
														"path": "d3a218cc-c1f1-4de5-8834-4cc4accf290a.png",
														"url": "https://staging-media.toyota.com.br/d3a218cc-c1f1-4de5-8834-4cc4accf290a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "01",
														"path": "2c1448e4-40c1-4486-848e-670832559151.png",
														"url": "https://staging-media.toyota.com.br/2c1448e4-40c1-4486-848e-670832559151.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "01",
														"path": "74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"url": "https://staging-media.toyota.com.br/74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "02",
														"path": "59209bf8-c7b7-46b1-812c-24060a7d8608.png",
														"url": "https://staging-media.toyota.com.br/59209bf8-c7b7-46b1-812c-24060a7d8608.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "01",
														"path": "74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"url": "https://staging-media.toyota.com.br/74ff8d79-d918-418f-a68c-65b0ac2969fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "01",
														"path": "ad80c28d-8b49-45ee-9025-f826fb0c610d.png",
														"url": "https://staging-media.toyota.com.br/ad80c28d-8b49-45ee-9025-f826fb0c610d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "02",
														"path": "2a4e945e-4e12-4610-999d-98a873306127.png",
														"url": "https://staging-media.toyota.com.br/2a4e945e-4e12-4610-999d-98a873306127.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 170690,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "61e4a0e9-c2de-4f11-8464-6b3ecaabd637.png",
														"url": "https://staging-media.toyota.com.br/61e4a0e9-c2de-4f11-8464-6b3ecaabd637.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e0b4a17d-2aec-45bc-98e1-00938d08d120.png",
														"url": "https://staging-media.toyota.com.br/e0b4a17d-2aec-45bc-98e1-00938d08d120.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040-corolla-cross-xrx-desktop",
														"path": "28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"url": "https://staging-media.toyota.com.br/28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040-corolla-cross-xrx-desktop",
														"path": "28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"url": "https://staging-media.toyota.com.br/28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040-corolla-cross-xrx-hover",
														"path": "ddc3045b-2fa6-47c3-a1d2-814ce156c262.png",
														"url": "https://staging-media.toyota.com.br/ddc3045b-2fa6-47c3-a1d2-814ce156c262.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-desktop",
														"path": "1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"url": "https://staging-media.toyota.com.br/1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-desktop",
														"path": "1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"url": "https://staging-media.toyota.com.br/1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-hover",
														"path": "9ccbd45a-12ae-49ba-bd8b-871999332c8c.png",
														"url": "https://staging-media.toyota.com.br/9ccbd45a-12ae-49ba-bd8b-871999332c8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-desktop",
														"path": "b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"url": "https://staging-media.toyota.com.br/b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-desktop",
														"path": "b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"url": "https://staging-media.toyota.com.br/b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-hover",
														"path": "62947051-0520-4d21-b43a-3bb0db546270.png",
														"url": "https://staging-media.toyota.com.br/62947051-0520-4d21-b43a-3bb0db546270.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "215-corolla-cross-xrx-desktop",
														"path": "deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"url": "https://staging-media.toyota.com.br/deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "215-corolla-cross-xrx-desktop",
														"path": "deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"url": "https://staging-media.toyota.com.br/deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "215-corolla-cross-xrx-hover",
														"path": "7d0b63ea-8599-42e2-ab8f-6f4d58df64ae.png",
														"url": "https://staging-media.toyota.com.br/7d0b63ea-8599-42e2-ab8f-6f4d58df64ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-desktop",
														"path": "b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"url": "https://staging-media.toyota.com.br/b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-desktop",
														"path": "b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"url": "https://staging-media.toyota.com.br/b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-hover",
														"path": "e28aa743-5753-4005-8d57-8cf571feec83.png",
														"url": "https://staging-media.toyota.com.br/e28aa743-5753-4005-8d57-8cf571feec83.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "089-corolla-cross-xrx-desktop",
														"path": "df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"url": "https://staging-media.toyota.com.br/df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "089-corolla-cross-xrx-desktop",
														"path": "df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"url": "https://staging-media.toyota.com.br/df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "089-corolla-cross-xrx-hover",
														"path": "233da3ca-4656-4e72-9fb6-b64599744c69.png",
														"url": "https://staging-media.toyota.com.br/233da3ca-4656-4e72-9fb6-b64599744c69.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 172710,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "61e4a0e9-c2de-4f11-8464-6b3ecaabd637.png",
														"url": "https://staging-media.toyota.com.br/61e4a0e9-c2de-4f11-8464-6b3ecaabd637.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e0b4a17d-2aec-45bc-98e1-00938d08d120.png",
														"url": "https://staging-media.toyota.com.br/e0b4a17d-2aec-45bc-98e1-00938d08d120.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040-corolla-cross-xrx-desktop",
														"path": "28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"url": "https://staging-media.toyota.com.br/28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040-corolla-cross-xrx-desktop",
														"path": "28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"url": "https://staging-media.toyota.com.br/28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040-corolla-cross-xrx-hover",
														"path": "ddc3045b-2fa6-47c3-a1d2-814ce156c262.png",
														"url": "https://staging-media.toyota.com.br/ddc3045b-2fa6-47c3-a1d2-814ce156c262.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-desktop",
														"path": "1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"url": "https://staging-media.toyota.com.br/1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-desktop",
														"path": "1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"url": "https://staging-media.toyota.com.br/1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-hover",
														"path": "9ccbd45a-12ae-49ba-bd8b-871999332c8c.png",
														"url": "https://staging-media.toyota.com.br/9ccbd45a-12ae-49ba-bd8b-871999332c8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-desktop",
														"path": "b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"url": "https://staging-media.toyota.com.br/b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-desktop",
														"path": "b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"url": "https://staging-media.toyota.com.br/b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-hover",
														"path": "62947051-0520-4d21-b43a-3bb0db546270.png",
														"url": "https://staging-media.toyota.com.br/62947051-0520-4d21-b43a-3bb0db546270.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "215-corolla-cross-xrx-desktop",
														"path": "deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"url": "https://staging-media.toyota.com.br/deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "215-corolla-cross-xrx-desktop",
														"path": "deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"url": "https://staging-media.toyota.com.br/deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "215-corolla-cross-xrx-hover",
														"path": "7d0b63ea-8599-42e2-ab8f-6f4d58df64ae.png",
														"url": "https://staging-media.toyota.com.br/7d0b63ea-8599-42e2-ab8f-6f4d58df64ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-desktop",
														"path": "b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"url": "https://staging-media.toyota.com.br/b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-desktop",
														"path": "b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"url": "https://staging-media.toyota.com.br/b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-hover",
														"path": "e28aa743-5753-4005-8d57-8cf571feec83.png",
														"url": "https://staging-media.toyota.com.br/e28aa743-5753-4005-8d57-8cf571feec83.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "089-corolla-cross-xrx-desktop",
														"path": "df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"url": "https://staging-media.toyota.com.br/df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "089-corolla-cross-xrx-desktop",
														"path": "df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"url": "https://staging-media.toyota.com.br/df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "089-corolla-cross-xrx-hover",
														"path": "233da3ca-4656-4e72-9fb6-b64599744c69.png",
														"url": "https://staging-media.toyota.com.br/233da3ca-4656-4e72-9fb6-b64599744c69.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 172710,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "61e4a0e9-c2de-4f11-8464-6b3ecaabd637.png",
														"url": "https://staging-media.toyota.com.br/61e4a0e9-c2de-4f11-8464-6b3ecaabd637.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e0b4a17d-2aec-45bc-98e1-00938d08d120.png",
														"url": "https://staging-media.toyota.com.br/e0b4a17d-2aec-45bc-98e1-00938d08d120.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040-corolla-cross-xrx-desktop",
														"path": "28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"url": "https://staging-media.toyota.com.br/28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040-corolla-cross-xrx-desktop",
														"path": "28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"url": "https://staging-media.toyota.com.br/28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040-corolla-cross-xrx-hover",
														"path": "ddc3045b-2fa6-47c3-a1d2-814ce156c262.png",
														"url": "https://staging-media.toyota.com.br/ddc3045b-2fa6-47c3-a1d2-814ce156c262.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-desktop",
														"path": "1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"url": "https://staging-media.toyota.com.br/1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-desktop",
														"path": "1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"url": "https://staging-media.toyota.com.br/1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-hover",
														"path": "9ccbd45a-12ae-49ba-bd8b-871999332c8c.png",
														"url": "https://staging-media.toyota.com.br/9ccbd45a-12ae-49ba-bd8b-871999332c8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-desktop",
														"path": "b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"url": "https://staging-media.toyota.com.br/b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-desktop",
														"path": "b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"url": "https://staging-media.toyota.com.br/b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-hover",
														"path": "62947051-0520-4d21-b43a-3bb0db546270.png",
														"url": "https://staging-media.toyota.com.br/62947051-0520-4d21-b43a-3bb0db546270.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "215-corolla-cross-xrx-desktop",
														"path": "deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"url": "https://staging-media.toyota.com.br/deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "215-corolla-cross-xrx-desktop",
														"path": "deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"url": "https://staging-media.toyota.com.br/deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "215-corolla-cross-xrx-hover",
														"path": "7d0b63ea-8599-42e2-ab8f-6f4d58df64ae.png",
														"url": "https://staging-media.toyota.com.br/7d0b63ea-8599-42e2-ab8f-6f4d58df64ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-desktop",
														"path": "b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"url": "https://staging-media.toyota.com.br/b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-desktop",
														"path": "b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"url": "https://staging-media.toyota.com.br/b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-hover",
														"path": "e28aa743-5753-4005-8d57-8cf571feec83.png",
														"url": "https://staging-media.toyota.com.br/e28aa743-5753-4005-8d57-8cf571feec83.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "089-corolla-cross-xrx-desktop",
														"path": "df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"url": "https://staging-media.toyota.com.br/df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "089-corolla-cross-xrx-desktop",
														"path": "df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"url": "https://staging-media.toyota.com.br/df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "089-corolla-cross-xrx-hover",
														"path": "233da3ca-4656-4e72-9fb6-b64599744c69.png",
														"url": "https://staging-media.toyota.com.br/233da3ca-4656-4e72-9fb6-b64599744c69.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 172710,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "61e4a0e9-c2de-4f11-8464-6b3ecaabd637.png",
														"url": "https://staging-media.toyota.com.br/61e4a0e9-c2de-4f11-8464-6b3ecaabd637.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e0b4a17d-2aec-45bc-98e1-00938d08d120.png",
														"url": "https://staging-media.toyota.com.br/e0b4a17d-2aec-45bc-98e1-00938d08d120.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040-corolla-cross-xrx-desktop",
														"path": "28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"url": "https://staging-media.toyota.com.br/28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040-corolla-cross-xrx-desktop",
														"path": "28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"url": "https://staging-media.toyota.com.br/28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040-corolla-cross-xrx-hover",
														"path": "ddc3045b-2fa6-47c3-a1d2-814ce156c262.png",
														"url": "https://staging-media.toyota.com.br/ddc3045b-2fa6-47c3-a1d2-814ce156c262.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-desktop",
														"path": "1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"url": "https://staging-media.toyota.com.br/1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-desktop",
														"path": "1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"url": "https://staging-media.toyota.com.br/1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-hover",
														"path": "9ccbd45a-12ae-49ba-bd8b-871999332c8c.png",
														"url": "https://staging-media.toyota.com.br/9ccbd45a-12ae-49ba-bd8b-871999332c8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-desktop",
														"path": "b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"url": "https://staging-media.toyota.com.br/b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-desktop",
														"path": "b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"url": "https://staging-media.toyota.com.br/b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-hover",
														"path": "62947051-0520-4d21-b43a-3bb0db546270.png",
														"url": "https://staging-media.toyota.com.br/62947051-0520-4d21-b43a-3bb0db546270.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "215-corolla-cross-xrx-desktop",
														"path": "deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"url": "https://staging-media.toyota.com.br/deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "215-corolla-cross-xrx-desktop",
														"path": "deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"url": "https://staging-media.toyota.com.br/deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "215-corolla-cross-xrx-hover",
														"path": "7d0b63ea-8599-42e2-ab8f-6f4d58df64ae.png",
														"url": "https://staging-media.toyota.com.br/7d0b63ea-8599-42e2-ab8f-6f4d58df64ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-desktop",
														"path": "b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"url": "https://staging-media.toyota.com.br/b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-desktop",
														"path": "b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"url": "https://staging-media.toyota.com.br/b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-hover",
														"path": "e28aa743-5753-4005-8d57-8cf571feec83.png",
														"url": "https://staging-media.toyota.com.br/e28aa743-5753-4005-8d57-8cf571feec83.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "089-corolla-cross-xrx-desktop",
														"path": "df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"url": "https://staging-media.toyota.com.br/df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "089-corolla-cross-xrx-desktop",
														"path": "df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"url": "https://staging-media.toyota.com.br/df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "089-corolla-cross-xrx-hover",
														"path": "233da3ca-4656-4e72-9fb6-b64599744c69.png",
														"url": "https://staging-media.toyota.com.br/233da3ca-4656-4e72-9fb6-b64599744c69.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 172710,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "61e4a0e9-c2de-4f11-8464-6b3ecaabd637.png",
														"url": "https://staging-media.toyota.com.br/61e4a0e9-c2de-4f11-8464-6b3ecaabd637.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e0b4a17d-2aec-45bc-98e1-00938d08d120.png",
														"url": "https://staging-media.toyota.com.br/e0b4a17d-2aec-45bc-98e1-00938d08d120.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040-corolla-cross-xrx-desktop",
														"path": "28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"url": "https://staging-media.toyota.com.br/28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040-corolla-cross-xrx-desktop",
														"path": "28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"url": "https://staging-media.toyota.com.br/28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040-corolla-cross-xrx-hover",
														"path": "ddc3045b-2fa6-47c3-a1d2-814ce156c262.png",
														"url": "https://staging-media.toyota.com.br/ddc3045b-2fa6-47c3-a1d2-814ce156c262.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-desktop",
														"path": "1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"url": "https://staging-media.toyota.com.br/1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-desktop",
														"path": "1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"url": "https://staging-media.toyota.com.br/1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-hover",
														"path": "9ccbd45a-12ae-49ba-bd8b-871999332c8c.png",
														"url": "https://staging-media.toyota.com.br/9ccbd45a-12ae-49ba-bd8b-871999332c8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-desktop",
														"path": "b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"url": "https://staging-media.toyota.com.br/b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-desktop",
														"path": "b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"url": "https://staging-media.toyota.com.br/b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-hover",
														"path": "62947051-0520-4d21-b43a-3bb0db546270.png",
														"url": "https://staging-media.toyota.com.br/62947051-0520-4d21-b43a-3bb0db546270.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "215-corolla-cross-xrx-desktop",
														"path": "deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"url": "https://staging-media.toyota.com.br/deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "215-corolla-cross-xrx-desktop",
														"path": "deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"url": "https://staging-media.toyota.com.br/deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "215-corolla-cross-xrx-hover",
														"path": "7d0b63ea-8599-42e2-ab8f-6f4d58df64ae.png",
														"url": "https://staging-media.toyota.com.br/7d0b63ea-8599-42e2-ab8f-6f4d58df64ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-desktop",
														"path": "b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"url": "https://staging-media.toyota.com.br/b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-desktop",
														"path": "b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"url": "https://staging-media.toyota.com.br/b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-hover",
														"path": "e28aa743-5753-4005-8d57-8cf571feec83.png",
														"url": "https://staging-media.toyota.com.br/e28aa743-5753-4005-8d57-8cf571feec83.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "089-corolla-cross-xrx-desktop",
														"path": "df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"url": "https://staging-media.toyota.com.br/df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "089-corolla-cross-xrx-desktop",
														"path": "df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"url": "https://staging-media.toyota.com.br/df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "089-corolla-cross-xrx-hover",
														"path": "233da3ca-4656-4e72-9fb6-b64599744c69.png",
														"url": "https://staging-media.toyota.com.br/233da3ca-4656-4e72-9fb6-b64599744c69.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 173020,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "1",
														"path": "61e4a0e9-c2de-4f11-8464-6b3ecaabd637.png",
														"url": "https://staging-media.toyota.com.br/61e4a0e9-c2de-4f11-8464-6b3ecaabd637.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "2",
														"path": "e0b4a17d-2aec-45bc-98e1-00938d08d120.png",
														"url": "https://staging-media.toyota.com.br/e0b4a17d-2aec-45bc-98e1-00938d08d120.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:040",
													"media": {
														"title": "040-corolla-cross-xrx-desktop",
														"path": "28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"url": "https://staging-media.toyota.com.br/28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:040",
													"media": {
														"title": "040-corolla-cross-xrx-desktop",
														"path": "28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"url": "https://staging-media.toyota.com.br/28ac9aa0-a388-436a-952d-48aafc85b129.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:040",
													"media": {
														"title": "040-corolla-cross-xrx-hover",
														"path": "ddc3045b-2fa6-47c3-a1d2-814ce156c262.png",
														"url": "https://staging-media.toyota.com.br/ddc3045b-2fa6-47c3-a1d2-814ce156c262.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-desktop",
														"path": "1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"url": "https://staging-media.toyota.com.br/1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-desktop",
														"path": "1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"url": "https://staging-media.toyota.com.br/1a7eac91-7be8-4318-a6ea-04d8b7f16515.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:3R3",
													"media": {
														"title": "3R3-corolla-cross-xrx-hover",
														"path": "9ccbd45a-12ae-49ba-bd8b-871999332c8c.png",
														"url": "https://staging-media.toyota.com.br/9ccbd45a-12ae-49ba-bd8b-871999332c8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-desktop",
														"path": "b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"url": "https://staging-media.toyota.com.br/b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-desktop",
														"path": "b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"url": "https://staging-media.toyota.com.br/b8963111-5d9e-4461-bc26-845ccc76ee9e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1G3",
													"media": {
														"title": "1G3-corolla-cross-xrx-hover",
														"path": "62947051-0520-4d21-b43a-3bb0db546270.png",
														"url": "https://staging-media.toyota.com.br/62947051-0520-4d21-b43a-3bb0db546270.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:215",
													"media": {
														"title": "215-corolla-cross-xrx-desktop",
														"path": "deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"url": "https://staging-media.toyota.com.br/deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:215",
													"media": {
														"title": "215-corolla-cross-xrx-desktop",
														"path": "deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"url": "https://staging-media.toyota.com.br/deabe267-a862-427b-bf65-f62c86aa97f5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:215",
													"media": {
														"title": "215-corolla-cross-xrx-hover",
														"path": "7d0b63ea-8599-42e2-ab8f-6f4d58df64ae.png",
														"url": "https://staging-media.toyota.com.br/7d0b63ea-8599-42e2-ab8f-6f4d58df64ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-desktop",
														"path": "b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"url": "https://staging-media.toyota.com.br/b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-desktop",
														"path": "b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"url": "https://staging-media.toyota.com.br/b8128dca-5fab-488c-ab34-fbce3a4cd518.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:1H6",
													"media": {
														"title": "1H6-corolla-cross-xrx-hover",
														"path": "e28aa743-5753-4005-8d57-8cf571feec83.png",
														"url": "https://staging-media.toyota.com.br/e28aa743-5753-4005-8d57-8cf571feec83.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:desk:089",
													"media": {
														"title": "089-corolla-cross-xrx-desktop",
														"path": "df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"url": "https://staging-media.toyota.com.br/df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:mob:089",
													"media": {
														"title": "089-corolla-cross-xrx-desktop",
														"path": "df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"url": "https://staging-media.toyota.com.br/df7ee818-c53d-4e3d-82ff-95929492e84b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "model:deskhover:089",
													"media": {
														"title": "089-corolla-cross-xrx-hover",
														"path": "233da3ca-4656-4e72-9fb6-b64599744c69.png",
														"url": "https://staging-media.toyota.com.br/233da3ca-4656-4e72-9fb6-b64599744c69.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "XRE",
						"id": "95e04729-f408-4e7f-b7ef-3579cad54ac8",
						"order": 2,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 174990,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "14558466-6ff5-40ce-9d3a-fa867646688e.png",
														"url": "https://staging-media.toyota.com.ar/14558466-6ff5-40ce-9d3a-fa867646688e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7b35d666-4dee-49c4-9a5b-3a336d0db518.jpeg",
														"url": "https://staging-media.toyota.com.ar/7b35d666-4dee-49c4-9a5b-3a336d0db518.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "6f687d17-84ba-4ad8-baad-67a1d5df23c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/6f687d17-84ba-4ad8-baad-67a1d5df23c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 desk",
														"path": "16caab8c-8250-4aac-a3c0-19b6b8286e65.png",
														"url": "https://staging-media.toyota.com.ar/16caab8c-8250-4aac-a3c0-19b6b8286e65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "21df3dc7-2574-4ccd-9305-8328089bcf23.png",
														"url": "https://staging-media.toyota.com.ar/21df3dc7-2574-4ccd-9305-8328089bcf23.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 desk",
														"path": "d1227e4f-70c5-422b-aa00-6963b1a02239.png",
														"url": "https://staging-media.toyota.com.ar/d1227e4f-70c5-422b-aa00-6963b1a02239.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "fdb51435-5c5f-4726-87f1-d83b7855857b.png",
														"url": "https://staging-media.toyota.com.ar/fdb51435-5c5f-4726-87f1-d83b7855857b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3 desk",
														"path": "22f324f9-ffdb-4aa4-815f-03d617d18839.png",
														"url": "https://staging-media.toyota.com.ar/22f324f9-ffdb-4aa4-815f-03d617d18839.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "2f11fdbb-a5c3-424d-b554-c6b8ce8909ec.png",
														"url": "https://staging-media.toyota.com.ar/2f11fdbb-a5c3-424d-b554-c6b8ce8909ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "bb7d9d36-7644-4d5c-8b36-7f15bbe694fd.png",
														"url": "https://staging-media.toyota.com.ar/bb7d9d36-7644-4d5c-8b36-7f15bbe694fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "e96710c2-679c-433c-8c89-82948272d315.png",
														"url": "https://staging-media.toyota.com.ar/e96710c2-679c-433c-8c89-82948272d315.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6 desk",
														"path": "bbe9aac9-00be-478b-8f07-c5560efaba0e.png",
														"url": "https://staging-media.toyota.com.ar/bbe9aac9-00be-478b-8f07-c5560efaba0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile",
														"path": "d1dd2a8d-3191-43c0-9804-53782e76630b.png",
														"url": "https://staging-media.toyota.com.ar/d1dd2a8d-3191-43c0-9804-53782e76630b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "58044813-9d85-40fe-bc03-da076011ac0a.png",
														"url": "https://staging-media.toyota.com.ar/58044813-9d85-40fe-bc03-da076011ac0a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "26fc98a7-23c3-4f16-a52f-36ab5fd34bc8.png",
														"url": "https://staging-media.toyota.com.ar/26fc98a7-23c3-4f16-a52f-36ab5fd34bc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "c4f470ff-af34-45fa-bbd4-842ca65e4f54.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f470ff-af34-45fa-bbd4-842ca65e4f54.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "2ea8dceb-4cbf-4356-b9c7-fe8519d74820.jpeg",
														"url": "https://staging-media.toyota.com.ar/2ea8dceb-4cbf-4356-b9c7-fe8519d74820.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "c0151d07-63fc-46ca-9bc7-0a96ba246192.jpeg",
														"url": "https://staging-media.toyota.com.ar/c0151d07-63fc-46ca-9bc7-0a96ba246192.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "a4248de8-135d-4972-87ab-df40e5c6f6d3.jpeg",
														"url": "https://staging-media.toyota.com.ar/a4248de8-135d-4972-87ab-df40e5c6f6d3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "63f97c31-0141-42cc-8e6d-af587931ac5f.jpeg",
														"url": "https://staging-media.toyota.com.ar/63f97c31-0141-42cc-8e6d-af587931ac5f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "39929b57-2131-4dfe-9430-11f726fef8a4.jpeg",
														"url": "https://staging-media.toyota.com.ar/39929b57-2131-4dfe-9430-11f726fef8a4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "0c67c78f-293d-47d5-abfd-c65a0336894b.jpeg",
														"url": "https://staging-media.toyota.com.ar/0c67c78f-293d-47d5-abfd-c65a0336894b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 1",
														"path": "f5527790-77ab-419e-b5e2-e56e9913f85f.jpeg",
														"url": "https://staging-media.toyota.com.ar/f5527790-77ab-419e-b5e2-e56e9913f85f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 2",
														"path": "90616f08-0ca3-4167-8c90-09d816f61732.jpeg",
														"url": "https://staging-media.toyota.com.ar/90616f08-0ca3-4167-8c90-09d816f61732.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 3",
														"path": "20c52616-859b-4c21-92a9-34fafde302f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/20c52616-859b-4c21-92a9-34fafde302f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 4",
														"path": "bdf88987-5a94-44c5-9625-0f315bc5e440.jpeg",
														"url": "https://staging-media.toyota.com.ar/bdf88987-5a94-44c5-9625-0f315bc5e440.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 5",
														"path": "1b50bf62-04c3-4a25-8683-8f531edf7de8.jpeg",
														"url": "https://staging-media.toyota.com.ar/1b50bf62-04c3-4a25-8683-8f531edf7de8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 6",
														"path": "5465dd61-a15f-488c-b5c3-9ce77f6133dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/5465dd61-a15f-488c-b5c3-9ce77f6133dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 7",
														"path": "175e5fcc-7f8d-41ed-aa28-ecb53fca895b.jpeg",
														"url": "https://staging-media.toyota.com.ar/175e5fcc-7f8d-41ed-aa28-ecb53fca895b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 8",
														"path": "5a5790d7-006c-4027-b30a-9332610adce6.jpeg",
														"url": "https://staging-media.toyota.com.ar/5a5790d7-006c-4027-b30a-9332610adce6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-Cross-XRE-040",
														"path": "ed5c4814-1fe5-495b-a29e-7e709e167c31.png",
														"url": "https://staging-media.toyota.com.ar/ed5c4814-1fe5-495b-a29e-7e709e167c31.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-Cross-XRE-040",
														"path": "bfbad87e-529a-4e09-8bd1-568543ce51fd.png",
														"url": "https://staging-media.toyota.com.ar/bfbad87e-529a-4e09-8bd1-568543ce51fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 177010,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "14558466-6ff5-40ce-9d3a-fa867646688e.png",
														"url": "https://staging-media.toyota.com.ar/14558466-6ff5-40ce-9d3a-fa867646688e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7b35d666-4dee-49c4-9a5b-3a336d0db518.jpeg",
														"url": "https://staging-media.toyota.com.ar/7b35d666-4dee-49c4-9a5b-3a336d0db518.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "6f687d17-84ba-4ad8-baad-67a1d5df23c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/6f687d17-84ba-4ad8-baad-67a1d5df23c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 desk",
														"path": "16caab8c-8250-4aac-a3c0-19b6b8286e65.png",
														"url": "https://staging-media.toyota.com.ar/16caab8c-8250-4aac-a3c0-19b6b8286e65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "21df3dc7-2574-4ccd-9305-8328089bcf23.png",
														"url": "https://staging-media.toyota.com.ar/21df3dc7-2574-4ccd-9305-8328089bcf23.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 desk",
														"path": "d1227e4f-70c5-422b-aa00-6963b1a02239.png",
														"url": "https://staging-media.toyota.com.ar/d1227e4f-70c5-422b-aa00-6963b1a02239.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "fdb51435-5c5f-4726-87f1-d83b7855857b.png",
														"url": "https://staging-media.toyota.com.ar/fdb51435-5c5f-4726-87f1-d83b7855857b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3 desk",
														"path": "22f324f9-ffdb-4aa4-815f-03d617d18839.png",
														"url": "https://staging-media.toyota.com.ar/22f324f9-ffdb-4aa4-815f-03d617d18839.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "2f11fdbb-a5c3-424d-b554-c6b8ce8909ec.png",
														"url": "https://staging-media.toyota.com.ar/2f11fdbb-a5c3-424d-b554-c6b8ce8909ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "bb7d9d36-7644-4d5c-8b36-7f15bbe694fd.png",
														"url": "https://staging-media.toyota.com.ar/bb7d9d36-7644-4d5c-8b36-7f15bbe694fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "e96710c2-679c-433c-8c89-82948272d315.png",
														"url": "https://staging-media.toyota.com.ar/e96710c2-679c-433c-8c89-82948272d315.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6 desk",
														"path": "bbe9aac9-00be-478b-8f07-c5560efaba0e.png",
														"url": "https://staging-media.toyota.com.ar/bbe9aac9-00be-478b-8f07-c5560efaba0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile",
														"path": "d1dd2a8d-3191-43c0-9804-53782e76630b.png",
														"url": "https://staging-media.toyota.com.ar/d1dd2a8d-3191-43c0-9804-53782e76630b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "58044813-9d85-40fe-bc03-da076011ac0a.png",
														"url": "https://staging-media.toyota.com.ar/58044813-9d85-40fe-bc03-da076011ac0a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "26fc98a7-23c3-4f16-a52f-36ab5fd34bc8.png",
														"url": "https://staging-media.toyota.com.ar/26fc98a7-23c3-4f16-a52f-36ab5fd34bc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "c4f470ff-af34-45fa-bbd4-842ca65e4f54.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f470ff-af34-45fa-bbd4-842ca65e4f54.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "2ea8dceb-4cbf-4356-b9c7-fe8519d74820.jpeg",
														"url": "https://staging-media.toyota.com.ar/2ea8dceb-4cbf-4356-b9c7-fe8519d74820.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "c0151d07-63fc-46ca-9bc7-0a96ba246192.jpeg",
														"url": "https://staging-media.toyota.com.ar/c0151d07-63fc-46ca-9bc7-0a96ba246192.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "a4248de8-135d-4972-87ab-df40e5c6f6d3.jpeg",
														"url": "https://staging-media.toyota.com.ar/a4248de8-135d-4972-87ab-df40e5c6f6d3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "63f97c31-0141-42cc-8e6d-af587931ac5f.jpeg",
														"url": "https://staging-media.toyota.com.ar/63f97c31-0141-42cc-8e6d-af587931ac5f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "39929b57-2131-4dfe-9430-11f726fef8a4.jpeg",
														"url": "https://staging-media.toyota.com.ar/39929b57-2131-4dfe-9430-11f726fef8a4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "0c67c78f-293d-47d5-abfd-c65a0336894b.jpeg",
														"url": "https://staging-media.toyota.com.ar/0c67c78f-293d-47d5-abfd-c65a0336894b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 1",
														"path": "f5527790-77ab-419e-b5e2-e56e9913f85f.jpeg",
														"url": "https://staging-media.toyota.com.ar/f5527790-77ab-419e-b5e2-e56e9913f85f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 2",
														"path": "90616f08-0ca3-4167-8c90-09d816f61732.jpeg",
														"url": "https://staging-media.toyota.com.ar/90616f08-0ca3-4167-8c90-09d816f61732.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 3",
														"path": "20c52616-859b-4c21-92a9-34fafde302f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/20c52616-859b-4c21-92a9-34fafde302f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 4",
														"path": "bdf88987-5a94-44c5-9625-0f315bc5e440.jpeg",
														"url": "https://staging-media.toyota.com.ar/bdf88987-5a94-44c5-9625-0f315bc5e440.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 5",
														"path": "1b50bf62-04c3-4a25-8683-8f531edf7de8.jpeg",
														"url": "https://staging-media.toyota.com.ar/1b50bf62-04c3-4a25-8683-8f531edf7de8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 6",
														"path": "5465dd61-a15f-488c-b5c3-9ce77f6133dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/5465dd61-a15f-488c-b5c3-9ce77f6133dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 7",
														"path": "175e5fcc-7f8d-41ed-aa28-ecb53fca895b.jpeg",
														"url": "https://staging-media.toyota.com.ar/175e5fcc-7f8d-41ed-aa28-ecb53fca895b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 8",
														"path": "5a5790d7-006c-4027-b30a-9332610adce6.jpeg",
														"url": "https://staging-media.toyota.com.ar/5a5790d7-006c-4027-b30a-9332610adce6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-Cross-XRE-040",
														"path": "ed5c4814-1fe5-495b-a29e-7e709e167c31.png",
														"url": "https://staging-media.toyota.com.ar/ed5c4814-1fe5-495b-a29e-7e709e167c31.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-Cross-XRE-040",
														"path": "bfbad87e-529a-4e09-8bd1-568543ce51fd.png",
														"url": "https://staging-media.toyota.com.ar/bfbad87e-529a-4e09-8bd1-568543ce51fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 177010,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "14558466-6ff5-40ce-9d3a-fa867646688e.png",
														"url": "https://staging-media.toyota.com.ar/14558466-6ff5-40ce-9d3a-fa867646688e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7b35d666-4dee-49c4-9a5b-3a336d0db518.jpeg",
														"url": "https://staging-media.toyota.com.ar/7b35d666-4dee-49c4-9a5b-3a336d0db518.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "6f687d17-84ba-4ad8-baad-67a1d5df23c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/6f687d17-84ba-4ad8-baad-67a1d5df23c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 desk",
														"path": "16caab8c-8250-4aac-a3c0-19b6b8286e65.png",
														"url": "https://staging-media.toyota.com.ar/16caab8c-8250-4aac-a3c0-19b6b8286e65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "21df3dc7-2574-4ccd-9305-8328089bcf23.png",
														"url": "https://staging-media.toyota.com.ar/21df3dc7-2574-4ccd-9305-8328089bcf23.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 desk",
														"path": "d1227e4f-70c5-422b-aa00-6963b1a02239.png",
														"url": "https://staging-media.toyota.com.ar/d1227e4f-70c5-422b-aa00-6963b1a02239.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "fdb51435-5c5f-4726-87f1-d83b7855857b.png",
														"url": "https://staging-media.toyota.com.ar/fdb51435-5c5f-4726-87f1-d83b7855857b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3 desk",
														"path": "22f324f9-ffdb-4aa4-815f-03d617d18839.png",
														"url": "https://staging-media.toyota.com.ar/22f324f9-ffdb-4aa4-815f-03d617d18839.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "2f11fdbb-a5c3-424d-b554-c6b8ce8909ec.png",
														"url": "https://staging-media.toyota.com.ar/2f11fdbb-a5c3-424d-b554-c6b8ce8909ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "bb7d9d36-7644-4d5c-8b36-7f15bbe694fd.png",
														"url": "https://staging-media.toyota.com.ar/bb7d9d36-7644-4d5c-8b36-7f15bbe694fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "e96710c2-679c-433c-8c89-82948272d315.png",
														"url": "https://staging-media.toyota.com.ar/e96710c2-679c-433c-8c89-82948272d315.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6 desk",
														"path": "bbe9aac9-00be-478b-8f07-c5560efaba0e.png",
														"url": "https://staging-media.toyota.com.ar/bbe9aac9-00be-478b-8f07-c5560efaba0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile",
														"path": "d1dd2a8d-3191-43c0-9804-53782e76630b.png",
														"url": "https://staging-media.toyota.com.ar/d1dd2a8d-3191-43c0-9804-53782e76630b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "58044813-9d85-40fe-bc03-da076011ac0a.png",
														"url": "https://staging-media.toyota.com.ar/58044813-9d85-40fe-bc03-da076011ac0a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "26fc98a7-23c3-4f16-a52f-36ab5fd34bc8.png",
														"url": "https://staging-media.toyota.com.ar/26fc98a7-23c3-4f16-a52f-36ab5fd34bc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "c4f470ff-af34-45fa-bbd4-842ca65e4f54.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f470ff-af34-45fa-bbd4-842ca65e4f54.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "2ea8dceb-4cbf-4356-b9c7-fe8519d74820.jpeg",
														"url": "https://staging-media.toyota.com.ar/2ea8dceb-4cbf-4356-b9c7-fe8519d74820.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "c0151d07-63fc-46ca-9bc7-0a96ba246192.jpeg",
														"url": "https://staging-media.toyota.com.ar/c0151d07-63fc-46ca-9bc7-0a96ba246192.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "a4248de8-135d-4972-87ab-df40e5c6f6d3.jpeg",
														"url": "https://staging-media.toyota.com.ar/a4248de8-135d-4972-87ab-df40e5c6f6d3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "63f97c31-0141-42cc-8e6d-af587931ac5f.jpeg",
														"url": "https://staging-media.toyota.com.ar/63f97c31-0141-42cc-8e6d-af587931ac5f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "39929b57-2131-4dfe-9430-11f726fef8a4.jpeg",
														"url": "https://staging-media.toyota.com.ar/39929b57-2131-4dfe-9430-11f726fef8a4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "0c67c78f-293d-47d5-abfd-c65a0336894b.jpeg",
														"url": "https://staging-media.toyota.com.ar/0c67c78f-293d-47d5-abfd-c65a0336894b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 1",
														"path": "f5527790-77ab-419e-b5e2-e56e9913f85f.jpeg",
														"url": "https://staging-media.toyota.com.ar/f5527790-77ab-419e-b5e2-e56e9913f85f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 2",
														"path": "90616f08-0ca3-4167-8c90-09d816f61732.jpeg",
														"url": "https://staging-media.toyota.com.ar/90616f08-0ca3-4167-8c90-09d816f61732.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 3",
														"path": "20c52616-859b-4c21-92a9-34fafde302f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/20c52616-859b-4c21-92a9-34fafde302f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 4",
														"path": "bdf88987-5a94-44c5-9625-0f315bc5e440.jpeg",
														"url": "https://staging-media.toyota.com.ar/bdf88987-5a94-44c5-9625-0f315bc5e440.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 5",
														"path": "1b50bf62-04c3-4a25-8683-8f531edf7de8.jpeg",
														"url": "https://staging-media.toyota.com.ar/1b50bf62-04c3-4a25-8683-8f531edf7de8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 6",
														"path": "5465dd61-a15f-488c-b5c3-9ce77f6133dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/5465dd61-a15f-488c-b5c3-9ce77f6133dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 7",
														"path": "175e5fcc-7f8d-41ed-aa28-ecb53fca895b.jpeg",
														"url": "https://staging-media.toyota.com.ar/175e5fcc-7f8d-41ed-aa28-ecb53fca895b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 8",
														"path": "5a5790d7-006c-4027-b30a-9332610adce6.jpeg",
														"url": "https://staging-media.toyota.com.ar/5a5790d7-006c-4027-b30a-9332610adce6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-Cross-XRE-040",
														"path": "ed5c4814-1fe5-495b-a29e-7e709e167c31.png",
														"url": "https://staging-media.toyota.com.ar/ed5c4814-1fe5-495b-a29e-7e709e167c31.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-Cross-XRE-040",
														"path": "bfbad87e-529a-4e09-8bd1-568543ce51fd.png",
														"url": "https://staging-media.toyota.com.ar/bfbad87e-529a-4e09-8bd1-568543ce51fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 177010,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "14558466-6ff5-40ce-9d3a-fa867646688e.png",
														"url": "https://staging-media.toyota.com.ar/14558466-6ff5-40ce-9d3a-fa867646688e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7b35d666-4dee-49c4-9a5b-3a336d0db518.jpeg",
														"url": "https://staging-media.toyota.com.ar/7b35d666-4dee-49c4-9a5b-3a336d0db518.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "6f687d17-84ba-4ad8-baad-67a1d5df23c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/6f687d17-84ba-4ad8-baad-67a1d5df23c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 desk",
														"path": "16caab8c-8250-4aac-a3c0-19b6b8286e65.png",
														"url": "https://staging-media.toyota.com.ar/16caab8c-8250-4aac-a3c0-19b6b8286e65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "21df3dc7-2574-4ccd-9305-8328089bcf23.png",
														"url": "https://staging-media.toyota.com.ar/21df3dc7-2574-4ccd-9305-8328089bcf23.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 desk",
														"path": "d1227e4f-70c5-422b-aa00-6963b1a02239.png",
														"url": "https://staging-media.toyota.com.ar/d1227e4f-70c5-422b-aa00-6963b1a02239.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "fdb51435-5c5f-4726-87f1-d83b7855857b.png",
														"url": "https://staging-media.toyota.com.ar/fdb51435-5c5f-4726-87f1-d83b7855857b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3 desk",
														"path": "22f324f9-ffdb-4aa4-815f-03d617d18839.png",
														"url": "https://staging-media.toyota.com.ar/22f324f9-ffdb-4aa4-815f-03d617d18839.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "2f11fdbb-a5c3-424d-b554-c6b8ce8909ec.png",
														"url": "https://staging-media.toyota.com.ar/2f11fdbb-a5c3-424d-b554-c6b8ce8909ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "bb7d9d36-7644-4d5c-8b36-7f15bbe694fd.png",
														"url": "https://staging-media.toyota.com.ar/bb7d9d36-7644-4d5c-8b36-7f15bbe694fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "e96710c2-679c-433c-8c89-82948272d315.png",
														"url": "https://staging-media.toyota.com.ar/e96710c2-679c-433c-8c89-82948272d315.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6 desk",
														"path": "bbe9aac9-00be-478b-8f07-c5560efaba0e.png",
														"url": "https://staging-media.toyota.com.ar/bbe9aac9-00be-478b-8f07-c5560efaba0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile",
														"path": "d1dd2a8d-3191-43c0-9804-53782e76630b.png",
														"url": "https://staging-media.toyota.com.ar/d1dd2a8d-3191-43c0-9804-53782e76630b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "58044813-9d85-40fe-bc03-da076011ac0a.png",
														"url": "https://staging-media.toyota.com.ar/58044813-9d85-40fe-bc03-da076011ac0a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "26fc98a7-23c3-4f16-a52f-36ab5fd34bc8.png",
														"url": "https://staging-media.toyota.com.ar/26fc98a7-23c3-4f16-a52f-36ab5fd34bc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "c4f470ff-af34-45fa-bbd4-842ca65e4f54.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f470ff-af34-45fa-bbd4-842ca65e4f54.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "2ea8dceb-4cbf-4356-b9c7-fe8519d74820.jpeg",
														"url": "https://staging-media.toyota.com.ar/2ea8dceb-4cbf-4356-b9c7-fe8519d74820.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "c0151d07-63fc-46ca-9bc7-0a96ba246192.jpeg",
														"url": "https://staging-media.toyota.com.ar/c0151d07-63fc-46ca-9bc7-0a96ba246192.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "a4248de8-135d-4972-87ab-df40e5c6f6d3.jpeg",
														"url": "https://staging-media.toyota.com.ar/a4248de8-135d-4972-87ab-df40e5c6f6d3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "63f97c31-0141-42cc-8e6d-af587931ac5f.jpeg",
														"url": "https://staging-media.toyota.com.ar/63f97c31-0141-42cc-8e6d-af587931ac5f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "39929b57-2131-4dfe-9430-11f726fef8a4.jpeg",
														"url": "https://staging-media.toyota.com.ar/39929b57-2131-4dfe-9430-11f726fef8a4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "0c67c78f-293d-47d5-abfd-c65a0336894b.jpeg",
														"url": "https://staging-media.toyota.com.ar/0c67c78f-293d-47d5-abfd-c65a0336894b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 1",
														"path": "f5527790-77ab-419e-b5e2-e56e9913f85f.jpeg",
														"url": "https://staging-media.toyota.com.ar/f5527790-77ab-419e-b5e2-e56e9913f85f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 2",
														"path": "90616f08-0ca3-4167-8c90-09d816f61732.jpeg",
														"url": "https://staging-media.toyota.com.ar/90616f08-0ca3-4167-8c90-09d816f61732.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 3",
														"path": "20c52616-859b-4c21-92a9-34fafde302f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/20c52616-859b-4c21-92a9-34fafde302f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 4",
														"path": "bdf88987-5a94-44c5-9625-0f315bc5e440.jpeg",
														"url": "https://staging-media.toyota.com.ar/bdf88987-5a94-44c5-9625-0f315bc5e440.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 5",
														"path": "1b50bf62-04c3-4a25-8683-8f531edf7de8.jpeg",
														"url": "https://staging-media.toyota.com.ar/1b50bf62-04c3-4a25-8683-8f531edf7de8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 6",
														"path": "5465dd61-a15f-488c-b5c3-9ce77f6133dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/5465dd61-a15f-488c-b5c3-9ce77f6133dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 7",
														"path": "175e5fcc-7f8d-41ed-aa28-ecb53fca895b.jpeg",
														"url": "https://staging-media.toyota.com.ar/175e5fcc-7f8d-41ed-aa28-ecb53fca895b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 8",
														"path": "5a5790d7-006c-4027-b30a-9332610adce6.jpeg",
														"url": "https://staging-media.toyota.com.ar/5a5790d7-006c-4027-b30a-9332610adce6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-Cross-XRE-040",
														"path": "ed5c4814-1fe5-495b-a29e-7e709e167c31.png",
														"url": "https://staging-media.toyota.com.ar/ed5c4814-1fe5-495b-a29e-7e709e167c31.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-Cross-XRE-040",
														"path": "bfbad87e-529a-4e09-8bd1-568543ce51fd.png",
														"url": "https://staging-media.toyota.com.ar/bfbad87e-529a-4e09-8bd1-568543ce51fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 177010,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "14558466-6ff5-40ce-9d3a-fa867646688e.png",
														"url": "https://staging-media.toyota.com.ar/14558466-6ff5-40ce-9d3a-fa867646688e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7b35d666-4dee-49c4-9a5b-3a336d0db518.jpeg",
														"url": "https://staging-media.toyota.com.ar/7b35d666-4dee-49c4-9a5b-3a336d0db518.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "6f687d17-84ba-4ad8-baad-67a1d5df23c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/6f687d17-84ba-4ad8-baad-67a1d5df23c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 desk",
														"path": "16caab8c-8250-4aac-a3c0-19b6b8286e65.png",
														"url": "https://staging-media.toyota.com.ar/16caab8c-8250-4aac-a3c0-19b6b8286e65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "21df3dc7-2574-4ccd-9305-8328089bcf23.png",
														"url": "https://staging-media.toyota.com.ar/21df3dc7-2574-4ccd-9305-8328089bcf23.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 desk",
														"path": "d1227e4f-70c5-422b-aa00-6963b1a02239.png",
														"url": "https://staging-media.toyota.com.ar/d1227e4f-70c5-422b-aa00-6963b1a02239.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "fdb51435-5c5f-4726-87f1-d83b7855857b.png",
														"url": "https://staging-media.toyota.com.ar/fdb51435-5c5f-4726-87f1-d83b7855857b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3 desk",
														"path": "22f324f9-ffdb-4aa4-815f-03d617d18839.png",
														"url": "https://staging-media.toyota.com.ar/22f324f9-ffdb-4aa4-815f-03d617d18839.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "2f11fdbb-a5c3-424d-b554-c6b8ce8909ec.png",
														"url": "https://staging-media.toyota.com.ar/2f11fdbb-a5c3-424d-b554-c6b8ce8909ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "bb7d9d36-7644-4d5c-8b36-7f15bbe694fd.png",
														"url": "https://staging-media.toyota.com.ar/bb7d9d36-7644-4d5c-8b36-7f15bbe694fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "e96710c2-679c-433c-8c89-82948272d315.png",
														"url": "https://staging-media.toyota.com.ar/e96710c2-679c-433c-8c89-82948272d315.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6 desk",
														"path": "bbe9aac9-00be-478b-8f07-c5560efaba0e.png",
														"url": "https://staging-media.toyota.com.ar/bbe9aac9-00be-478b-8f07-c5560efaba0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile",
														"path": "d1dd2a8d-3191-43c0-9804-53782e76630b.png",
														"url": "https://staging-media.toyota.com.ar/d1dd2a8d-3191-43c0-9804-53782e76630b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "58044813-9d85-40fe-bc03-da076011ac0a.png",
														"url": "https://staging-media.toyota.com.ar/58044813-9d85-40fe-bc03-da076011ac0a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "26fc98a7-23c3-4f16-a52f-36ab5fd34bc8.png",
														"url": "https://staging-media.toyota.com.ar/26fc98a7-23c3-4f16-a52f-36ab5fd34bc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "c4f470ff-af34-45fa-bbd4-842ca65e4f54.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f470ff-af34-45fa-bbd4-842ca65e4f54.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "2ea8dceb-4cbf-4356-b9c7-fe8519d74820.jpeg",
														"url": "https://staging-media.toyota.com.ar/2ea8dceb-4cbf-4356-b9c7-fe8519d74820.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "c0151d07-63fc-46ca-9bc7-0a96ba246192.jpeg",
														"url": "https://staging-media.toyota.com.ar/c0151d07-63fc-46ca-9bc7-0a96ba246192.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "a4248de8-135d-4972-87ab-df40e5c6f6d3.jpeg",
														"url": "https://staging-media.toyota.com.ar/a4248de8-135d-4972-87ab-df40e5c6f6d3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "63f97c31-0141-42cc-8e6d-af587931ac5f.jpeg",
														"url": "https://staging-media.toyota.com.ar/63f97c31-0141-42cc-8e6d-af587931ac5f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "39929b57-2131-4dfe-9430-11f726fef8a4.jpeg",
														"url": "https://staging-media.toyota.com.ar/39929b57-2131-4dfe-9430-11f726fef8a4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "0c67c78f-293d-47d5-abfd-c65a0336894b.jpeg",
														"url": "https://staging-media.toyota.com.ar/0c67c78f-293d-47d5-abfd-c65a0336894b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 1",
														"path": "f5527790-77ab-419e-b5e2-e56e9913f85f.jpeg",
														"url": "https://staging-media.toyota.com.ar/f5527790-77ab-419e-b5e2-e56e9913f85f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 2",
														"path": "90616f08-0ca3-4167-8c90-09d816f61732.jpeg",
														"url": "https://staging-media.toyota.com.ar/90616f08-0ca3-4167-8c90-09d816f61732.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 3",
														"path": "20c52616-859b-4c21-92a9-34fafde302f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/20c52616-859b-4c21-92a9-34fafde302f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 4",
														"path": "bdf88987-5a94-44c5-9625-0f315bc5e440.jpeg",
														"url": "https://staging-media.toyota.com.ar/bdf88987-5a94-44c5-9625-0f315bc5e440.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 5",
														"path": "1b50bf62-04c3-4a25-8683-8f531edf7de8.jpeg",
														"url": "https://staging-media.toyota.com.ar/1b50bf62-04c3-4a25-8683-8f531edf7de8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 6",
														"path": "5465dd61-a15f-488c-b5c3-9ce77f6133dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/5465dd61-a15f-488c-b5c3-9ce77f6133dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 7",
														"path": "175e5fcc-7f8d-41ed-aa28-ecb53fca895b.jpeg",
														"url": "https://staging-media.toyota.com.ar/175e5fcc-7f8d-41ed-aa28-ecb53fca895b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 8",
														"path": "5a5790d7-006c-4027-b30a-9332610adce6.jpeg",
														"url": "https://staging-media.toyota.com.ar/5a5790d7-006c-4027-b30a-9332610adce6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-Cross-XRE-040",
														"path": "ed5c4814-1fe5-495b-a29e-7e709e167c31.png",
														"url": "https://staging-media.toyota.com.ar/ed5c4814-1fe5-495b-a29e-7e709e167c31.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-Cross-XRE-040",
														"path": "bfbad87e-529a-4e09-8bd1-568543ce51fd.png",
														"url": "https://staging-media.toyota.com.ar/bfbad87e-529a-4e09-8bd1-568543ce51fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 177320,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "14558466-6ff5-40ce-9d3a-fa867646688e.png",
														"url": "https://staging-media.toyota.com.ar/14558466-6ff5-40ce-9d3a-fa867646688e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "7b35d666-4dee-49c4-9a5b-3a336d0db518.jpeg",
														"url": "https://staging-media.toyota.com.ar/7b35d666-4dee-49c4-9a5b-3a336d0db518.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "6f687d17-84ba-4ad8-baad-67a1d5df23c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/6f687d17-84ba-4ad8-baad-67a1d5df23c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 desk",
														"path": "16caab8c-8250-4aac-a3c0-19b6b8286e65.png",
														"url": "https://staging-media.toyota.com.ar/16caab8c-8250-4aac-a3c0-19b6b8286e65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "21df3dc7-2574-4ccd-9305-8328089bcf23.png",
														"url": "https://staging-media.toyota.com.ar/21df3dc7-2574-4ccd-9305-8328089bcf23.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 desk",
														"path": "d1227e4f-70c5-422b-aa00-6963b1a02239.png",
														"url": "https://staging-media.toyota.com.ar/d1227e4f-70c5-422b-aa00-6963b1a02239.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "fdb51435-5c5f-4726-87f1-d83b7855857b.png",
														"url": "https://staging-media.toyota.com.ar/fdb51435-5c5f-4726-87f1-d83b7855857b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3 desk",
														"path": "22f324f9-ffdb-4aa4-815f-03d617d18839.png",
														"url": "https://staging-media.toyota.com.ar/22f324f9-ffdb-4aa4-815f-03d617d18839.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "2f11fdbb-a5c3-424d-b554-c6b8ce8909ec.png",
														"url": "https://staging-media.toyota.com.ar/2f11fdbb-a5c3-424d-b554-c6b8ce8909ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "bb7d9d36-7644-4d5c-8b36-7f15bbe694fd.png",
														"url": "https://staging-media.toyota.com.ar/bb7d9d36-7644-4d5c-8b36-7f15bbe694fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "e96710c2-679c-433c-8c89-82948272d315.png",
														"url": "https://staging-media.toyota.com.ar/e96710c2-679c-433c-8c89-82948272d315.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6 desk",
														"path": "bbe9aac9-00be-478b-8f07-c5560efaba0e.png",
														"url": "https://staging-media.toyota.com.ar/bbe9aac9-00be-478b-8f07-c5560efaba0e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile",
														"path": "d1dd2a8d-3191-43c0-9804-53782e76630b.png",
														"url": "https://staging-media.toyota.com.ar/d1dd2a8d-3191-43c0-9804-53782e76630b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "58044813-9d85-40fe-bc03-da076011ac0a.png",
														"url": "https://staging-media.toyota.com.ar/58044813-9d85-40fe-bc03-da076011ac0a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "26fc98a7-23c3-4f16-a52f-36ab5fd34bc8.png",
														"url": "https://staging-media.toyota.com.ar/26fc98a7-23c3-4f16-a52f-36ab5fd34bc8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "c4f470ff-af34-45fa-bbd4-842ca65e4f54.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f470ff-af34-45fa-bbd4-842ca65e4f54.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "2ea8dceb-4cbf-4356-b9c7-fe8519d74820.jpeg",
														"url": "https://staging-media.toyota.com.ar/2ea8dceb-4cbf-4356-b9c7-fe8519d74820.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "c0151d07-63fc-46ca-9bc7-0a96ba246192.jpeg",
														"url": "https://staging-media.toyota.com.ar/c0151d07-63fc-46ca-9bc7-0a96ba246192.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "a4248de8-135d-4972-87ab-df40e5c6f6d3.jpeg",
														"url": "https://staging-media.toyota.com.ar/a4248de8-135d-4972-87ab-df40e5c6f6d3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "63f97c31-0141-42cc-8e6d-af587931ac5f.jpeg",
														"url": "https://staging-media.toyota.com.ar/63f97c31-0141-42cc-8e6d-af587931ac5f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "39929b57-2131-4dfe-9430-11f726fef8a4.jpeg",
														"url": "https://staging-media.toyota.com.ar/39929b57-2131-4dfe-9430-11f726fef8a4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "0c67c78f-293d-47d5-abfd-c65a0336894b.jpeg",
														"url": "https://staging-media.toyota.com.ar/0c67c78f-293d-47d5-abfd-c65a0336894b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 1",
														"path": "f5527790-77ab-419e-b5e2-e56e9913f85f.jpeg",
														"url": "https://staging-media.toyota.com.ar/f5527790-77ab-419e-b5e2-e56e9913f85f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 2",
														"path": "90616f08-0ca3-4167-8c90-09d816f61732.jpeg",
														"url": "https://staging-media.toyota.com.ar/90616f08-0ca3-4167-8c90-09d816f61732.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 3",
														"path": "20c52616-859b-4c21-92a9-34fafde302f0.jpeg",
														"url": "https://staging-media.toyota.com.ar/20c52616-859b-4c21-92a9-34fafde302f0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 4",
														"path": "bdf88987-5a94-44c5-9625-0f315bc5e440.jpeg",
														"url": "https://staging-media.toyota.com.ar/bdf88987-5a94-44c5-9625-0f315bc5e440.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 5",
														"path": "1b50bf62-04c3-4a25-8683-8f531edf7de8.jpeg",
														"url": "https://staging-media.toyota.com.ar/1b50bf62-04c3-4a25-8683-8f531edf7de8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 6",
														"path": "5465dd61-a15f-488c-b5c3-9ce77f6133dc.jpeg",
														"url": "https://staging-media.toyota.com.ar/5465dd61-a15f-488c-b5c3-9ce77f6133dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 7",
														"path": "175e5fcc-7f8d-41ed-aa28-ecb53fca895b.jpeg",
														"url": "https://staging-media.toyota.com.ar/175e5fcc-7f8d-41ed-aa28-ecb53fca895b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 8",
														"path": "5a5790d7-006c-4027-b30a-9332610adce6.jpeg",
														"url": "https://staging-media.toyota.com.ar/5a5790d7-006c-4027-b30a-9332610adce6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display1-Corolla-Cross-XRE-040",
														"path": "ed5c4814-1fe5-495b-a29e-7e709e167c31.png",
														"url": "https://staging-media.toyota.com.ar/ed5c4814-1fe5-495b-a29e-7e709e167c31.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display2-Corolla-Cross-XRE-040",
														"path": "bfbad87e-529a-4e09-8bd1-568543ce51fd.png",
														"url": "https://staging-media.toyota.com.ar/bfbad87e-529a-4e09-8bd1-568543ce51fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 172690,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner - DEsk ",
														"path": "755fc560-0faa-4ef7-a9bc-80acebe57a59.jpeg",
														"url": "https://staging-media.toyota.com.br/755fc560-0faa-4ef7-a9bc-80acebe57a59.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner Mobile ",
														"path": "909be93d-2330-4dc9-8d37-d543159dca7b.jpeg",
														"url": "https://staging-media.toyota.com.br/909be93d-2330-4dc9-8d37-d543159dca7b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 XRE DESK",
														"path": "27a008d7-1d50-4f32-a1a6-9e4f8314ff45.png",
														"url": "https://staging-media.toyota.com.ar/27a008d7-1d50-4f32-a1a6-9e4f8314ff45.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 XRE MOBILE",
														"path": "34174fcc-8465-4762-842d-82f74e10e692.png",
														"url": "https://staging-media.toyota.com.ar/34174fcc-8465-4762-842d-82f74e10e692.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 XRE MOBILE",
														"path": "2ebf0ba0-c4be-4057-a9f9-8e4ebd3a1ce7.png",
														"url": "https://staging-media.toyota.com.ar/2ebf0ba0-c4be-4057-a9f9-8e4ebd3a1ce7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 XRE DESK",
														"path": "dd3e430d-c7c2-4745-9bc5-4041a43fe193.png",
														"url": "https://staging-media.toyota.com.ar/dd3e430d-c7c2-4745-9bc5-4041a43fe193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 XRE DESK",
														"path": "258a203a-85e0-4528-b554-b1704901ef08.png",
														"url": "https://staging-media.toyota.com.ar/258a203a-85e0-4528-b554-b1704901ef08.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 XRE MOBILE",
														"path": "37f379ea-7fd7-4c9e-8907-bc6323e3fa73.png",
														"url": "https://staging-media.toyota.com.ar/37f379ea-7fd7-4c9e-8907-bc6323e3fa73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 XRE DESK",
														"path": "4d0f254c-7460-4917-852a-0ca1fa8656cc.png",
														"url": "https://staging-media.toyota.com.ar/4d0f254c-7460-4917-852a-0ca1fa8656cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 XRE MOBILE",
														"path": "d6f258fe-1538-419e-80e2-c8f22e99a9bc.png",
														"url": "https://staging-media.toyota.com.ar/d6f258fe-1538-419e-80e2-c8f22e99a9bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 XRE MOBILE",
														"path": "25e5e0c1-2fd3-492e-9e6c-7afaf50111f2.png",
														"url": "https://staging-media.toyota.com.ar/25e5e0c1-2fd3-492e-9e6c-7afaf50111f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 XRE DESK",
														"path": "1b0f2e64-c2a1-4324-9f17-8eaaea476f64.png",
														"url": "https://staging-media.toyota.com.ar/1b0f2e64-c2a1-4324-9f17-8eaaea476f64.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 XRE MOBILE",
														"path": "56c66c58-290a-49e6-a568-1eed819aa7e4.png",
														"url": "https://staging-media.toyota.com.ar/56c66c58-290a-49e6-a568-1eed819aa7e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 XRE DESK",
														"path": "89c95af5-7e0b-4753-9d6e-e9ed1443aeda.png",
														"url": "https://staging-media.toyota.com.ar/89c95af5-7e0b-4753-9d6e-e9ed1443aeda.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Cross - XRE",
														"path": "070f4f65-a9e0-4731-bb15-6941538db8e9.png",
														"url": "https://staging-media.toyota.com.ar/070f4f65-a9e0-4731-bb15-6941538db8e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Cross - XRE",
														"path": "52e241e1-f067-4496-9e9c-707464217d9d.png",
														"url": "https://staging-media.toyota.com.ar/52e241e1-f067-4496-9e9c-707464217d9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross - Logo 1",
														"path": "f846a24c-343a-4d7a-9c21-cca74d28d605.png",
														"url": "https://staging-media.toyota.com.br/f846a24c-343a-4d7a-9c21-cca74d28d605.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interna - CC",
														"path": "e060e168-6c2a-4b93-9c2a-2f702dafdd61.jpeg",
														"url": "https://staging-media.toyota.com.br/e060e168-6c2a-4b93-9c2a-2f702dafdd61.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria externa - CC",
														"path": "ea5d7cd6-553c-4560-8c95-3c4bdbb81248.jpeg",
														"url": "https://staging-media.toyota.com.br/ea5d7cd6-553c-4560-8c95-3c4bdbb81248.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 174710,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner - DEsk ",
														"path": "755fc560-0faa-4ef7-a9bc-80acebe57a59.jpeg",
														"url": "https://staging-media.toyota.com.br/755fc560-0faa-4ef7-a9bc-80acebe57a59.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner Mobile ",
														"path": "909be93d-2330-4dc9-8d37-d543159dca7b.jpeg",
														"url": "https://staging-media.toyota.com.br/909be93d-2330-4dc9-8d37-d543159dca7b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 XRE DESK",
														"path": "27a008d7-1d50-4f32-a1a6-9e4f8314ff45.png",
														"url": "https://staging-media.toyota.com.ar/27a008d7-1d50-4f32-a1a6-9e4f8314ff45.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 XRE MOBILE",
														"path": "34174fcc-8465-4762-842d-82f74e10e692.png",
														"url": "https://staging-media.toyota.com.ar/34174fcc-8465-4762-842d-82f74e10e692.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 XRE MOBILE",
														"path": "2ebf0ba0-c4be-4057-a9f9-8e4ebd3a1ce7.png",
														"url": "https://staging-media.toyota.com.ar/2ebf0ba0-c4be-4057-a9f9-8e4ebd3a1ce7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 XRE DESK",
														"path": "dd3e430d-c7c2-4745-9bc5-4041a43fe193.png",
														"url": "https://staging-media.toyota.com.ar/dd3e430d-c7c2-4745-9bc5-4041a43fe193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 XRE DESK",
														"path": "258a203a-85e0-4528-b554-b1704901ef08.png",
														"url": "https://staging-media.toyota.com.ar/258a203a-85e0-4528-b554-b1704901ef08.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 XRE MOBILE",
														"path": "37f379ea-7fd7-4c9e-8907-bc6323e3fa73.png",
														"url": "https://staging-media.toyota.com.ar/37f379ea-7fd7-4c9e-8907-bc6323e3fa73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 XRE DESK",
														"path": "4d0f254c-7460-4917-852a-0ca1fa8656cc.png",
														"url": "https://staging-media.toyota.com.ar/4d0f254c-7460-4917-852a-0ca1fa8656cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 XRE MOBILE",
														"path": "d6f258fe-1538-419e-80e2-c8f22e99a9bc.png",
														"url": "https://staging-media.toyota.com.ar/d6f258fe-1538-419e-80e2-c8f22e99a9bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 XRE MOBILE",
														"path": "25e5e0c1-2fd3-492e-9e6c-7afaf50111f2.png",
														"url": "https://staging-media.toyota.com.ar/25e5e0c1-2fd3-492e-9e6c-7afaf50111f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 XRE DESK",
														"path": "1b0f2e64-c2a1-4324-9f17-8eaaea476f64.png",
														"url": "https://staging-media.toyota.com.ar/1b0f2e64-c2a1-4324-9f17-8eaaea476f64.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 XRE MOBILE",
														"path": "56c66c58-290a-49e6-a568-1eed819aa7e4.png",
														"url": "https://staging-media.toyota.com.ar/56c66c58-290a-49e6-a568-1eed819aa7e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 XRE DESK",
														"path": "89c95af5-7e0b-4753-9d6e-e9ed1443aeda.png",
														"url": "https://staging-media.toyota.com.ar/89c95af5-7e0b-4753-9d6e-e9ed1443aeda.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Cross - XRE",
														"path": "070f4f65-a9e0-4731-bb15-6941538db8e9.png",
														"url": "https://staging-media.toyota.com.ar/070f4f65-a9e0-4731-bb15-6941538db8e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Cross - XRE",
														"path": "52e241e1-f067-4496-9e9c-707464217d9d.png",
														"url": "https://staging-media.toyota.com.ar/52e241e1-f067-4496-9e9c-707464217d9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross - Logo 1",
														"path": "f846a24c-343a-4d7a-9c21-cca74d28d605.png",
														"url": "https://staging-media.toyota.com.br/f846a24c-343a-4d7a-9c21-cca74d28d605.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interna - CC",
														"path": "e060e168-6c2a-4b93-9c2a-2f702dafdd61.jpeg",
														"url": "https://staging-media.toyota.com.br/e060e168-6c2a-4b93-9c2a-2f702dafdd61.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria externa - CC",
														"path": "ea5d7cd6-553c-4560-8c95-3c4bdbb81248.jpeg",
														"url": "https://staging-media.toyota.com.br/ea5d7cd6-553c-4560-8c95-3c4bdbb81248.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 174710,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner - DEsk ",
														"path": "755fc560-0faa-4ef7-a9bc-80acebe57a59.jpeg",
														"url": "https://staging-media.toyota.com.br/755fc560-0faa-4ef7-a9bc-80acebe57a59.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner Mobile ",
														"path": "909be93d-2330-4dc9-8d37-d543159dca7b.jpeg",
														"url": "https://staging-media.toyota.com.br/909be93d-2330-4dc9-8d37-d543159dca7b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 XRE DESK",
														"path": "27a008d7-1d50-4f32-a1a6-9e4f8314ff45.png",
														"url": "https://staging-media.toyota.com.ar/27a008d7-1d50-4f32-a1a6-9e4f8314ff45.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 XRE MOBILE",
														"path": "34174fcc-8465-4762-842d-82f74e10e692.png",
														"url": "https://staging-media.toyota.com.ar/34174fcc-8465-4762-842d-82f74e10e692.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 XRE MOBILE",
														"path": "2ebf0ba0-c4be-4057-a9f9-8e4ebd3a1ce7.png",
														"url": "https://staging-media.toyota.com.ar/2ebf0ba0-c4be-4057-a9f9-8e4ebd3a1ce7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 XRE DESK",
														"path": "dd3e430d-c7c2-4745-9bc5-4041a43fe193.png",
														"url": "https://staging-media.toyota.com.ar/dd3e430d-c7c2-4745-9bc5-4041a43fe193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 XRE DESK",
														"path": "258a203a-85e0-4528-b554-b1704901ef08.png",
														"url": "https://staging-media.toyota.com.ar/258a203a-85e0-4528-b554-b1704901ef08.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 XRE MOBILE",
														"path": "37f379ea-7fd7-4c9e-8907-bc6323e3fa73.png",
														"url": "https://staging-media.toyota.com.ar/37f379ea-7fd7-4c9e-8907-bc6323e3fa73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 XRE DESK",
														"path": "4d0f254c-7460-4917-852a-0ca1fa8656cc.png",
														"url": "https://staging-media.toyota.com.ar/4d0f254c-7460-4917-852a-0ca1fa8656cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 XRE MOBILE",
														"path": "d6f258fe-1538-419e-80e2-c8f22e99a9bc.png",
														"url": "https://staging-media.toyota.com.ar/d6f258fe-1538-419e-80e2-c8f22e99a9bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 XRE MOBILE",
														"path": "25e5e0c1-2fd3-492e-9e6c-7afaf50111f2.png",
														"url": "https://staging-media.toyota.com.ar/25e5e0c1-2fd3-492e-9e6c-7afaf50111f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 XRE DESK",
														"path": "1b0f2e64-c2a1-4324-9f17-8eaaea476f64.png",
														"url": "https://staging-media.toyota.com.ar/1b0f2e64-c2a1-4324-9f17-8eaaea476f64.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 XRE MOBILE",
														"path": "56c66c58-290a-49e6-a568-1eed819aa7e4.png",
														"url": "https://staging-media.toyota.com.ar/56c66c58-290a-49e6-a568-1eed819aa7e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 XRE DESK",
														"path": "89c95af5-7e0b-4753-9d6e-e9ed1443aeda.png",
														"url": "https://staging-media.toyota.com.ar/89c95af5-7e0b-4753-9d6e-e9ed1443aeda.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Cross - XRE",
														"path": "070f4f65-a9e0-4731-bb15-6941538db8e9.png",
														"url": "https://staging-media.toyota.com.ar/070f4f65-a9e0-4731-bb15-6941538db8e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Cross - XRE",
														"path": "52e241e1-f067-4496-9e9c-707464217d9d.png",
														"url": "https://staging-media.toyota.com.ar/52e241e1-f067-4496-9e9c-707464217d9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross - Logo 1",
														"path": "f846a24c-343a-4d7a-9c21-cca74d28d605.png",
														"url": "https://staging-media.toyota.com.br/f846a24c-343a-4d7a-9c21-cca74d28d605.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interna - CC",
														"path": "e060e168-6c2a-4b93-9c2a-2f702dafdd61.jpeg",
														"url": "https://staging-media.toyota.com.br/e060e168-6c2a-4b93-9c2a-2f702dafdd61.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria externa - CC",
														"path": "ea5d7cd6-553c-4560-8c95-3c4bdbb81248.jpeg",
														"url": "https://staging-media.toyota.com.br/ea5d7cd6-553c-4560-8c95-3c4bdbb81248.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 174710,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner - DEsk ",
														"path": "755fc560-0faa-4ef7-a9bc-80acebe57a59.jpeg",
														"url": "https://staging-media.toyota.com.br/755fc560-0faa-4ef7-a9bc-80acebe57a59.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner Mobile ",
														"path": "909be93d-2330-4dc9-8d37-d543159dca7b.jpeg",
														"url": "https://staging-media.toyota.com.br/909be93d-2330-4dc9-8d37-d543159dca7b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 XRE DESK",
														"path": "27a008d7-1d50-4f32-a1a6-9e4f8314ff45.png",
														"url": "https://staging-media.toyota.com.ar/27a008d7-1d50-4f32-a1a6-9e4f8314ff45.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 XRE MOBILE",
														"path": "34174fcc-8465-4762-842d-82f74e10e692.png",
														"url": "https://staging-media.toyota.com.ar/34174fcc-8465-4762-842d-82f74e10e692.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 XRE MOBILE",
														"path": "2ebf0ba0-c4be-4057-a9f9-8e4ebd3a1ce7.png",
														"url": "https://staging-media.toyota.com.ar/2ebf0ba0-c4be-4057-a9f9-8e4ebd3a1ce7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 XRE DESK",
														"path": "dd3e430d-c7c2-4745-9bc5-4041a43fe193.png",
														"url": "https://staging-media.toyota.com.ar/dd3e430d-c7c2-4745-9bc5-4041a43fe193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 XRE DESK",
														"path": "258a203a-85e0-4528-b554-b1704901ef08.png",
														"url": "https://staging-media.toyota.com.ar/258a203a-85e0-4528-b554-b1704901ef08.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 XRE MOBILE",
														"path": "37f379ea-7fd7-4c9e-8907-bc6323e3fa73.png",
														"url": "https://staging-media.toyota.com.ar/37f379ea-7fd7-4c9e-8907-bc6323e3fa73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 XRE DESK",
														"path": "4d0f254c-7460-4917-852a-0ca1fa8656cc.png",
														"url": "https://staging-media.toyota.com.ar/4d0f254c-7460-4917-852a-0ca1fa8656cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 XRE MOBILE",
														"path": "d6f258fe-1538-419e-80e2-c8f22e99a9bc.png",
														"url": "https://staging-media.toyota.com.ar/d6f258fe-1538-419e-80e2-c8f22e99a9bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 XRE MOBILE",
														"path": "25e5e0c1-2fd3-492e-9e6c-7afaf50111f2.png",
														"url": "https://staging-media.toyota.com.ar/25e5e0c1-2fd3-492e-9e6c-7afaf50111f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 XRE DESK",
														"path": "1b0f2e64-c2a1-4324-9f17-8eaaea476f64.png",
														"url": "https://staging-media.toyota.com.ar/1b0f2e64-c2a1-4324-9f17-8eaaea476f64.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 XRE MOBILE",
														"path": "56c66c58-290a-49e6-a568-1eed819aa7e4.png",
														"url": "https://staging-media.toyota.com.ar/56c66c58-290a-49e6-a568-1eed819aa7e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 XRE DESK",
														"path": "89c95af5-7e0b-4753-9d6e-e9ed1443aeda.png",
														"url": "https://staging-media.toyota.com.ar/89c95af5-7e0b-4753-9d6e-e9ed1443aeda.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Cross - XRE",
														"path": "070f4f65-a9e0-4731-bb15-6941538db8e9.png",
														"url": "https://staging-media.toyota.com.ar/070f4f65-a9e0-4731-bb15-6941538db8e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Cross - XRE",
														"path": "52e241e1-f067-4496-9e9c-707464217d9d.png",
														"url": "https://staging-media.toyota.com.ar/52e241e1-f067-4496-9e9c-707464217d9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross - Logo 1",
														"path": "f846a24c-343a-4d7a-9c21-cca74d28d605.png",
														"url": "https://staging-media.toyota.com.br/f846a24c-343a-4d7a-9c21-cca74d28d605.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interna - CC",
														"path": "e060e168-6c2a-4b93-9c2a-2f702dafdd61.jpeg",
														"url": "https://staging-media.toyota.com.br/e060e168-6c2a-4b93-9c2a-2f702dafdd61.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria externa - CC",
														"path": "ea5d7cd6-553c-4560-8c95-3c4bdbb81248.jpeg",
														"url": "https://staging-media.toyota.com.br/ea5d7cd6-553c-4560-8c95-3c4bdbb81248.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 174710,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner - DEsk ",
														"path": "755fc560-0faa-4ef7-a9bc-80acebe57a59.jpeg",
														"url": "https://staging-media.toyota.com.br/755fc560-0faa-4ef7-a9bc-80acebe57a59.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner Mobile ",
														"path": "909be93d-2330-4dc9-8d37-d543159dca7b.jpeg",
														"url": "https://staging-media.toyota.com.br/909be93d-2330-4dc9-8d37-d543159dca7b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 XRE DESK",
														"path": "27a008d7-1d50-4f32-a1a6-9e4f8314ff45.png",
														"url": "https://staging-media.toyota.com.ar/27a008d7-1d50-4f32-a1a6-9e4f8314ff45.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 XRE MOBILE",
														"path": "34174fcc-8465-4762-842d-82f74e10e692.png",
														"url": "https://staging-media.toyota.com.ar/34174fcc-8465-4762-842d-82f74e10e692.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 XRE MOBILE",
														"path": "2ebf0ba0-c4be-4057-a9f9-8e4ebd3a1ce7.png",
														"url": "https://staging-media.toyota.com.ar/2ebf0ba0-c4be-4057-a9f9-8e4ebd3a1ce7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 XRE DESK",
														"path": "dd3e430d-c7c2-4745-9bc5-4041a43fe193.png",
														"url": "https://staging-media.toyota.com.ar/dd3e430d-c7c2-4745-9bc5-4041a43fe193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 XRE DESK",
														"path": "258a203a-85e0-4528-b554-b1704901ef08.png",
														"url": "https://staging-media.toyota.com.ar/258a203a-85e0-4528-b554-b1704901ef08.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 XRE MOBILE",
														"path": "37f379ea-7fd7-4c9e-8907-bc6323e3fa73.png",
														"url": "https://staging-media.toyota.com.ar/37f379ea-7fd7-4c9e-8907-bc6323e3fa73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 XRE DESK",
														"path": "4d0f254c-7460-4917-852a-0ca1fa8656cc.png",
														"url": "https://staging-media.toyota.com.ar/4d0f254c-7460-4917-852a-0ca1fa8656cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 XRE MOBILE",
														"path": "d6f258fe-1538-419e-80e2-c8f22e99a9bc.png",
														"url": "https://staging-media.toyota.com.ar/d6f258fe-1538-419e-80e2-c8f22e99a9bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 XRE MOBILE",
														"path": "25e5e0c1-2fd3-492e-9e6c-7afaf50111f2.png",
														"url": "https://staging-media.toyota.com.ar/25e5e0c1-2fd3-492e-9e6c-7afaf50111f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 XRE DESK",
														"path": "1b0f2e64-c2a1-4324-9f17-8eaaea476f64.png",
														"url": "https://staging-media.toyota.com.ar/1b0f2e64-c2a1-4324-9f17-8eaaea476f64.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 XRE MOBILE",
														"path": "56c66c58-290a-49e6-a568-1eed819aa7e4.png",
														"url": "https://staging-media.toyota.com.ar/56c66c58-290a-49e6-a568-1eed819aa7e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 XRE DESK",
														"path": "89c95af5-7e0b-4753-9d6e-e9ed1443aeda.png",
														"url": "https://staging-media.toyota.com.ar/89c95af5-7e0b-4753-9d6e-e9ed1443aeda.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Cross - XRE",
														"path": "070f4f65-a9e0-4731-bb15-6941538db8e9.png",
														"url": "https://staging-media.toyota.com.ar/070f4f65-a9e0-4731-bb15-6941538db8e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Cross - XRE",
														"path": "52e241e1-f067-4496-9e9c-707464217d9d.png",
														"url": "https://staging-media.toyota.com.ar/52e241e1-f067-4496-9e9c-707464217d9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross - Logo 1",
														"path": "f846a24c-343a-4d7a-9c21-cca74d28d605.png",
														"url": "https://staging-media.toyota.com.br/f846a24c-343a-4d7a-9c21-cca74d28d605.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interna - CC",
														"path": "e060e168-6c2a-4b93-9c2a-2f702dafdd61.jpeg",
														"url": "https://staging-media.toyota.com.br/e060e168-6c2a-4b93-9c2a-2f702dafdd61.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria externa - CC",
														"path": "ea5d7cd6-553c-4560-8c95-3c4bdbb81248.jpeg",
														"url": "https://staging-media.toyota.com.br/ea5d7cd6-553c-4560-8c95-3c4bdbb81248.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 175020,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner - DEsk ",
														"path": "755fc560-0faa-4ef7-a9bc-80acebe57a59.jpeg",
														"url": "https://staging-media.toyota.com.br/755fc560-0faa-4ef7-a9bc-80acebe57a59.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner Mobile ",
														"path": "909be93d-2330-4dc9-8d37-d543159dca7b.jpeg",
														"url": "https://staging-media.toyota.com.br/909be93d-2330-4dc9-8d37-d543159dca7b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 XRE DESK",
														"path": "27a008d7-1d50-4f32-a1a6-9e4f8314ff45.png",
														"url": "https://staging-media.toyota.com.ar/27a008d7-1d50-4f32-a1a6-9e4f8314ff45.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 XRE MOBILE",
														"path": "34174fcc-8465-4762-842d-82f74e10e692.png",
														"url": "https://staging-media.toyota.com.ar/34174fcc-8465-4762-842d-82f74e10e692.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 XRE MOBILE",
														"path": "2ebf0ba0-c4be-4057-a9f9-8e4ebd3a1ce7.png",
														"url": "https://staging-media.toyota.com.ar/2ebf0ba0-c4be-4057-a9f9-8e4ebd3a1ce7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 XRE DESK",
														"path": "dd3e430d-c7c2-4745-9bc5-4041a43fe193.png",
														"url": "https://staging-media.toyota.com.ar/dd3e430d-c7c2-4745-9bc5-4041a43fe193.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 XRE DESK",
														"path": "258a203a-85e0-4528-b554-b1704901ef08.png",
														"url": "https://staging-media.toyota.com.ar/258a203a-85e0-4528-b554-b1704901ef08.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 XRE MOBILE",
														"path": "37f379ea-7fd7-4c9e-8907-bc6323e3fa73.png",
														"url": "https://staging-media.toyota.com.ar/37f379ea-7fd7-4c9e-8907-bc6323e3fa73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 XRE DESK",
														"path": "4d0f254c-7460-4917-852a-0ca1fa8656cc.png",
														"url": "https://staging-media.toyota.com.ar/4d0f254c-7460-4917-852a-0ca1fa8656cc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 XRE MOBILE",
														"path": "d6f258fe-1538-419e-80e2-c8f22e99a9bc.png",
														"url": "https://staging-media.toyota.com.ar/d6f258fe-1538-419e-80e2-c8f22e99a9bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 XRE MOBILE",
														"path": "25e5e0c1-2fd3-492e-9e6c-7afaf50111f2.png",
														"url": "https://staging-media.toyota.com.ar/25e5e0c1-2fd3-492e-9e6c-7afaf50111f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 XRE DESK",
														"path": "1b0f2e64-c2a1-4324-9f17-8eaaea476f64.png",
														"url": "https://staging-media.toyota.com.ar/1b0f2e64-c2a1-4324-9f17-8eaaea476f64.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 XRE MOBILE",
														"path": "56c66c58-290a-49e6-a568-1eed819aa7e4.png",
														"url": "https://staging-media.toyota.com.ar/56c66c58-290a-49e6-a568-1eed819aa7e4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 XRE DESK",
														"path": "89c95af5-7e0b-4753-9d6e-e9ed1443aeda.png",
														"url": "https://staging-media.toyota.com.ar/89c95af5-7e0b-4753-9d6e-e9ed1443aeda.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Cross - XRE",
														"path": "070f4f65-a9e0-4731-bb15-6941538db8e9.png",
														"url": "https://staging-media.toyota.com.ar/070f4f65-a9e0-4731-bb15-6941538db8e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Cross - XRE",
														"path": "52e241e1-f067-4496-9e9c-707464217d9d.png",
														"url": "https://staging-media.toyota.com.ar/52e241e1-f067-4496-9e9c-707464217d9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Cross - Logo 1",
														"path": "f846a24c-343a-4d7a-9c21-cca74d28d605.png",
														"url": "https://staging-media.toyota.com.br/f846a24c-343a-4d7a-9c21-cca74d28d605.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interna - CC",
														"path": "e060e168-6c2a-4b93-9c2a-2f702dafdd61.jpeg",
														"url": "https://staging-media.toyota.com.br/e060e168-6c2a-4b93-9c2a-2f702dafdd61.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria externa - CC",
														"path": "ea5d7cd6-553c-4560-8c95-3c4bdbb81248.jpeg",
														"url": "https://staging-media.toyota.com.br/ea5d7cd6-553c-4560-8c95-3c4bdbb81248.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "GR-Sport",
						"id": "08879a88-b1f2-4ced-9550-3f0bf875417c",
						"order": 3,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 193590,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "191248ad-46b0-49cf-b9bf-c7ea2a2a2ef5.png",
														"url": "https://staging-media.toyota.com.ar/191248ad-46b0-49cf-b9bf-c7ea2a2a2ef5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display normal",
														"path": "d114f354-8598-4d57-ac5f-4c9650c3054b.png",
														"url": "https://staging-media.toyota.com.ar/d114f354-8598-4d57-ac5f-4c9650c3054b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display mouse hover",
														"path": "6ffc2802-499b-4296-af3b-cecb7c99a603.png",
														"url": "https://staging-media.toyota.com.ar/6ffc2802-499b-4296-af3b-cecb7c99a603.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "banner desk",
														"path": "6e9fdfd2-59a9-4b1f-8cdd-a213eca5eebb.jpeg",
														"url": "https://staging-media.toyota.com.ar/6e9fdfd2-59a9-4b1f-8cdd-a213eca5eebb.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "banner mobile",
														"path": "353a0152-f771-4787-9925-c94a8aaaca63.jpeg",
														"url": "https://staging-media.toyota.com.ar/353a0152-f771-4787-9925-c94a8aaaca63.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "8897a892-e2fd-414e-beaa-82f6eecbe926.jpeg",
														"url": "https://staging-media.toyota.com.ar/8897a892-e2fd-414e-beaa-82f6eecbe926.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "f37f5a00-c625-4e79-a627-9bba4d9e6a4b.jpeg",
														"url": "https://staging-media.toyota.com.ar/f37f5a00-c625-4e79-a627-9bba4d9e6a4b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "afdbe242-5004-476c-883e-71ab41c95cb2.jpeg",
														"url": "https://staging-media.toyota.com.ar/afdbe242-5004-476c-883e-71ab41c95cb2.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "a582a05b-5406-4080-9b6d-521fac4fc296.jpeg",
														"url": "https://staging-media.toyota.com.ar/a582a05b-5406-4080-9b6d-521fac4fc296.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "c67aa325-ca3c-4ed3-8bfe-76f9fb36b01d.jpeg",
														"url": "https://staging-media.toyota.com.ar/c67aa325-ca3c-4ed3-8bfe-76f9fb36b01d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "c8a2c38a-09a9-4ff2-a745-182ed21f7948.jpeg",
														"url": "https://staging-media.toyota.com.ar/c8a2c38a-09a9-4ff2-a745-182ed21f7948.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "fdc01aae-50ad-41bb-85a5-31342938a2c9.jpeg",
														"url": "https://staging-media.toyota.com.ar/fdc01aae-50ad-41bb-85a5-31342938a2c9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 1",
														"path": "62c663a6-7ad0-4c7d-bd33-7cd35535884a.jpeg",
														"url": "https://staging-media.toyota.com.ar/62c663a6-7ad0-4c7d-bd33-7cd35535884a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 2",
														"path": "143e64a4-f0d3-4dac-b79a-ffd8b9274120.jpeg",
														"url": "https://staging-media.toyota.com.ar/143e64a4-f0d3-4dac-b79a-ffd8b9274120.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 3",
														"path": "f6c777f5-4cec-4eed-8c25-200722514c53.jpeg",
														"url": "https://staging-media.toyota.com.ar/f6c777f5-4cec-4eed-8c25-200722514c53.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 4",
														"path": "c45ec2a6-5a6d-4fd1-a189-57799dbd0dd5.jpeg",
														"url": "https://staging-media.toyota.com.ar/c45ec2a6-5a6d-4fd1-a189-57799dbd0dd5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 5",
														"path": "9c860c18-48d8-421e-8dd1-30a0f4c92613.jpeg",
														"url": "https://staging-media.toyota.com.ar/9c860c18-48d8-421e-8dd1-30a0f4c92613.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 6",
														"path": "c6021b56-01b2-46ee-9c42-4bae228a886f.jpeg",
														"url": "https://staging-media.toyota.com.ar/c6021b56-01b2-46ee-9c42-4bae228a886f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 7",
														"path": "0e766439-df44-4da0-8027-5e3f74701c96.jpeg",
														"url": "https://staging-media.toyota.com.ar/0e766439-df44-4da0-8027-5e3f74701c96.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 8",
														"path": "e1179156-1ca5-460b-83fd-d37571ecbd47.jpeg",
														"url": "https://staging-media.toyota.com.ar/e1179156-1ca5-460b-83fd-d37571ecbd47.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Corolla-Cross-GR-S-_reposicionado_-215-Preto-Infinito",
														"path": "ee9d8f70-f16d-41d3-aee9-cf2dabe65728.png",
														"url": "https://staging-media.toyota.com.ar/ee9d8f70-f16d-41d3-aee9-cf2dabe65728.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Corolla-Cross-GR-S-mobile-_reposicionado_-215-Preto-Infinito",
														"path": "e89e38fe-6840-4b8b-9f65-5c8bc38573b7.png",
														"url": "https://staging-media.toyota.com.ar/e89e38fe-6840-4b8b-9f65-5c8bc38573b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2WH",
													"media": {
														"title": "Corolla-Cross-GR-S-_reposicionado_-2WH-Branco-Lunar-Preto-Infinito",
														"path": "56aa58ba-4f3d-424c-aced-e1fd8e951fe6.png",
														"url": "https://staging-media.toyota.com.ar/56aa58ba-4f3d-424c-aced-e1fd8e951fe6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2WH",
													"media": {
														"title": "Corolla-Cross-GR-S-mobile-_reposicionado_-2WH-Branco-Lunar-Preto-Infinito",
														"path": "2b52bb19-8b5e-40d4-adf5-8cf7b360c6f9.png",
														"url": "https://staging-media.toyota.com.ar/2b52bb19-8b5e-40d4-adf5-8cf7b360c6f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2WK",
													"media": {
														"title": "Corolla-Cross-GR-S-_reposicionado_-2WK-Vermelho-Granada-Preto-Infinito",
														"path": "0a1dde45-0c8f-485a-9d27-0fb41901a678.png",
														"url": "https://staging-media.toyota.com.ar/0a1dde45-0c8f-485a-9d27-0fb41901a678.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2WK",
													"media": {
														"title": "Corolla-Cross-GR-S-mobile-_reposicionado_-2WK-Vermelho-Granada-Preto-Infinito",
														"path": "c9b40914-0eca-4e73-93e8-ee8ac5d15d99.png",
														"url": "https://staging-media.toyota.com.ar/c9b40914-0eca-4e73-93e8-ee8ac5d15d99.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 195390,
										"offers": null,
										"color": {
											"name": "Branco Lunar (2WH)",
											"code": "2WH",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "191248ad-46b0-49cf-b9bf-c7ea2a2a2ef5.png",
														"url": "https://staging-media.toyota.com.ar/191248ad-46b0-49cf-b9bf-c7ea2a2a2ef5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display normal",
														"path": "d114f354-8598-4d57-ac5f-4c9650c3054b.png",
														"url": "https://staging-media.toyota.com.ar/d114f354-8598-4d57-ac5f-4c9650c3054b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display mouse hover",
														"path": "6ffc2802-499b-4296-af3b-cecb7c99a603.png",
														"url": "https://staging-media.toyota.com.ar/6ffc2802-499b-4296-af3b-cecb7c99a603.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "banner desk",
														"path": "6e9fdfd2-59a9-4b1f-8cdd-a213eca5eebb.jpeg",
														"url": "https://staging-media.toyota.com.ar/6e9fdfd2-59a9-4b1f-8cdd-a213eca5eebb.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "banner mobile",
														"path": "353a0152-f771-4787-9925-c94a8aaaca63.jpeg",
														"url": "https://staging-media.toyota.com.ar/353a0152-f771-4787-9925-c94a8aaaca63.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "8897a892-e2fd-414e-beaa-82f6eecbe926.jpeg",
														"url": "https://staging-media.toyota.com.ar/8897a892-e2fd-414e-beaa-82f6eecbe926.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "f37f5a00-c625-4e79-a627-9bba4d9e6a4b.jpeg",
														"url": "https://staging-media.toyota.com.ar/f37f5a00-c625-4e79-a627-9bba4d9e6a4b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "afdbe242-5004-476c-883e-71ab41c95cb2.jpeg",
														"url": "https://staging-media.toyota.com.ar/afdbe242-5004-476c-883e-71ab41c95cb2.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "a582a05b-5406-4080-9b6d-521fac4fc296.jpeg",
														"url": "https://staging-media.toyota.com.ar/a582a05b-5406-4080-9b6d-521fac4fc296.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "c67aa325-ca3c-4ed3-8bfe-76f9fb36b01d.jpeg",
														"url": "https://staging-media.toyota.com.ar/c67aa325-ca3c-4ed3-8bfe-76f9fb36b01d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "c8a2c38a-09a9-4ff2-a745-182ed21f7948.jpeg",
														"url": "https://staging-media.toyota.com.ar/c8a2c38a-09a9-4ff2-a745-182ed21f7948.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "fdc01aae-50ad-41bb-85a5-31342938a2c9.jpeg",
														"url": "https://staging-media.toyota.com.ar/fdc01aae-50ad-41bb-85a5-31342938a2c9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 1",
														"path": "62c663a6-7ad0-4c7d-bd33-7cd35535884a.jpeg",
														"url": "https://staging-media.toyota.com.ar/62c663a6-7ad0-4c7d-bd33-7cd35535884a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 2",
														"path": "143e64a4-f0d3-4dac-b79a-ffd8b9274120.jpeg",
														"url": "https://staging-media.toyota.com.ar/143e64a4-f0d3-4dac-b79a-ffd8b9274120.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 3",
														"path": "f6c777f5-4cec-4eed-8c25-200722514c53.jpeg",
														"url": "https://staging-media.toyota.com.ar/f6c777f5-4cec-4eed-8c25-200722514c53.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 4",
														"path": "c45ec2a6-5a6d-4fd1-a189-57799dbd0dd5.jpeg",
														"url": "https://staging-media.toyota.com.ar/c45ec2a6-5a6d-4fd1-a189-57799dbd0dd5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 5",
														"path": "9c860c18-48d8-421e-8dd1-30a0f4c92613.jpeg",
														"url": "https://staging-media.toyota.com.ar/9c860c18-48d8-421e-8dd1-30a0f4c92613.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 6",
														"path": "c6021b56-01b2-46ee-9c42-4bae228a886f.jpeg",
														"url": "https://staging-media.toyota.com.ar/c6021b56-01b2-46ee-9c42-4bae228a886f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 7",
														"path": "0e766439-df44-4da0-8027-5e3f74701c96.jpeg",
														"url": "https://staging-media.toyota.com.ar/0e766439-df44-4da0-8027-5e3f74701c96.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 8",
														"path": "e1179156-1ca5-460b-83fd-d37571ecbd47.jpeg",
														"url": "https://staging-media.toyota.com.ar/e1179156-1ca5-460b-83fd-d37571ecbd47.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Corolla-Cross-GR-S-_reposicionado_-215-Preto-Infinito",
														"path": "ee9d8f70-f16d-41d3-aee9-cf2dabe65728.png",
														"url": "https://staging-media.toyota.com.ar/ee9d8f70-f16d-41d3-aee9-cf2dabe65728.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Corolla-Cross-GR-S-mobile-_reposicionado_-215-Preto-Infinito",
														"path": "e89e38fe-6840-4b8b-9f65-5c8bc38573b7.png",
														"url": "https://staging-media.toyota.com.ar/e89e38fe-6840-4b8b-9f65-5c8bc38573b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2WH",
													"media": {
														"title": "Corolla-Cross-GR-S-_reposicionado_-2WH-Branco-Lunar-Preto-Infinito",
														"path": "56aa58ba-4f3d-424c-aced-e1fd8e951fe6.png",
														"url": "https://staging-media.toyota.com.ar/56aa58ba-4f3d-424c-aced-e1fd8e951fe6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2WH",
													"media": {
														"title": "Corolla-Cross-GR-S-mobile-_reposicionado_-2WH-Branco-Lunar-Preto-Infinito",
														"path": "2b52bb19-8b5e-40d4-adf5-8cf7b360c6f9.png",
														"url": "https://staging-media.toyota.com.ar/2b52bb19-8b5e-40d4-adf5-8cf7b360c6f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2WK",
													"media": {
														"title": "Corolla-Cross-GR-S-_reposicionado_-2WK-Vermelho-Granada-Preto-Infinito",
														"path": "0a1dde45-0c8f-485a-9d27-0fb41901a678.png",
														"url": "https://staging-media.toyota.com.ar/0a1dde45-0c8f-485a-9d27-0fb41901a678.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2WK",
													"media": {
														"title": "Corolla-Cross-GR-S-mobile-_reposicionado_-2WK-Vermelho-Granada-Preto-Infinito",
														"path": "c9b40914-0eca-4e73-93e8-ee8ac5d15d99.png",
														"url": "https://staging-media.toyota.com.ar/c9b40914-0eca-4e73-93e8-ee8ac5d15d99.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 195390,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (2WK)",
											"code": "2WK",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "191248ad-46b0-49cf-b9bf-c7ea2a2a2ef5.png",
														"url": "https://staging-media.toyota.com.ar/191248ad-46b0-49cf-b9bf-c7ea2a2a2ef5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "display normal",
														"path": "d114f354-8598-4d57-ac5f-4c9650c3054b.png",
														"url": "https://staging-media.toyota.com.ar/d114f354-8598-4d57-ac5f-4c9650c3054b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "display mouse hover",
														"path": "6ffc2802-499b-4296-af3b-cecb7c99a603.png",
														"url": "https://staging-media.toyota.com.ar/6ffc2802-499b-4296-af3b-cecb7c99a603.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "banner desk",
														"path": "6e9fdfd2-59a9-4b1f-8cdd-a213eca5eebb.jpeg",
														"url": "https://staging-media.toyota.com.ar/6e9fdfd2-59a9-4b1f-8cdd-a213eca5eebb.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "banner mobile",
														"path": "353a0152-f771-4787-9925-c94a8aaaca63.jpeg",
														"url": "https://staging-media.toyota.com.ar/353a0152-f771-4787-9925-c94a8aaaca63.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "8897a892-e2fd-414e-beaa-82f6eecbe926.jpeg",
														"url": "https://staging-media.toyota.com.ar/8897a892-e2fd-414e-beaa-82f6eecbe926.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "f37f5a00-c625-4e79-a627-9bba4d9e6a4b.jpeg",
														"url": "https://staging-media.toyota.com.ar/f37f5a00-c625-4e79-a627-9bba4d9e6a4b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "afdbe242-5004-476c-883e-71ab41c95cb2.jpeg",
														"url": "https://staging-media.toyota.com.ar/afdbe242-5004-476c-883e-71ab41c95cb2.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "a582a05b-5406-4080-9b6d-521fac4fc296.jpeg",
														"url": "https://staging-media.toyota.com.ar/a582a05b-5406-4080-9b6d-521fac4fc296.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "c67aa325-ca3c-4ed3-8bfe-76f9fb36b01d.jpeg",
														"url": "https://staging-media.toyota.com.ar/c67aa325-ca3c-4ed3-8bfe-76f9fb36b01d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "c8a2c38a-09a9-4ff2-a745-182ed21f7948.jpeg",
														"url": "https://staging-media.toyota.com.ar/c8a2c38a-09a9-4ff2-a745-182ed21f7948.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "fdc01aae-50ad-41bb-85a5-31342938a2c9.jpeg",
														"url": "https://staging-media.toyota.com.ar/fdc01aae-50ad-41bb-85a5-31342938a2c9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 1",
														"path": "62c663a6-7ad0-4c7d-bd33-7cd35535884a.jpeg",
														"url": "https://staging-media.toyota.com.ar/62c663a6-7ad0-4c7d-bd33-7cd35535884a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 2",
														"path": "143e64a4-f0d3-4dac-b79a-ffd8b9274120.jpeg",
														"url": "https://staging-media.toyota.com.ar/143e64a4-f0d3-4dac-b79a-ffd8b9274120.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 3",
														"path": "f6c777f5-4cec-4eed-8c25-200722514c53.jpeg",
														"url": "https://staging-media.toyota.com.ar/f6c777f5-4cec-4eed-8c25-200722514c53.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 4",
														"path": "c45ec2a6-5a6d-4fd1-a189-57799dbd0dd5.jpeg",
														"url": "https://staging-media.toyota.com.ar/c45ec2a6-5a6d-4fd1-a189-57799dbd0dd5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 5",
														"path": "9c860c18-48d8-421e-8dd1-30a0f4c92613.jpeg",
														"url": "https://staging-media.toyota.com.ar/9c860c18-48d8-421e-8dd1-30a0f4c92613.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 6",
														"path": "c6021b56-01b2-46ee-9c42-4bae228a886f.jpeg",
														"url": "https://staging-media.toyota.com.ar/c6021b56-01b2-46ee-9c42-4bae228a886f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 7",
														"path": "0e766439-df44-4da0-8027-5e3f74701c96.jpeg",
														"url": "https://staging-media.toyota.com.ar/0e766439-df44-4da0-8027-5e3f74701c96.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Ext 8",
														"path": "e1179156-1ca5-460b-83fd-d37571ecbd47.jpeg",
														"url": "https://staging-media.toyota.com.ar/e1179156-1ca5-460b-83fd-d37571ecbd47.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "Corolla-Cross-GR-S-_reposicionado_-215-Preto-Infinito",
														"path": "ee9d8f70-f16d-41d3-aee9-cf2dabe65728.png",
														"url": "https://staging-media.toyota.com.ar/ee9d8f70-f16d-41d3-aee9-cf2dabe65728.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "Corolla-Cross-GR-S-mobile-_reposicionado_-215-Preto-Infinito",
														"path": "e89e38fe-6840-4b8b-9f65-5c8bc38573b7.png",
														"url": "https://staging-media.toyota.com.ar/e89e38fe-6840-4b8b-9f65-5c8bc38573b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2WH",
													"media": {
														"title": "Corolla-Cross-GR-S-_reposicionado_-2WH-Branco-Lunar-Preto-Infinito",
														"path": "56aa58ba-4f3d-424c-aced-e1fd8e951fe6.png",
														"url": "https://staging-media.toyota.com.ar/56aa58ba-4f3d-424c-aced-e1fd8e951fe6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2WH",
													"media": {
														"title": "Corolla-Cross-GR-S-mobile-_reposicionado_-2WH-Branco-Lunar-Preto-Infinito",
														"path": "2b52bb19-8b5e-40d4-adf5-8cf7b360c6f9.png",
														"url": "https://staging-media.toyota.com.ar/2b52bb19-8b5e-40d4-adf5-8cf7b360c6f9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2WK",
													"media": {
														"title": "Corolla-Cross-GR-S-_reposicionado_-2WK-Vermelho-Granada-Preto-Infinito",
														"path": "0a1dde45-0c8f-485a-9d27-0fb41901a678.png",
														"url": "https://staging-media.toyota.com.ar/0a1dde45-0c8f-485a-9d27-0fb41901a678.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2WK",
													"media": {
														"title": "Corolla-Cross-GR-S-mobile-_reposicionado_-2WK-Vermelho-Granada-Preto-Infinito",
														"path": "c9b40914-0eca-4e73-93e8-ee8ac5d15d99.png",
														"url": "https://staging-media.toyota.com.ar/c9b40914-0eca-4e73-93e8-ee8ac5d15d99.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 197790,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "Display GR 1",
														"path": "f07d5a8e-ed8b-4225-b951-ddb50d553ccb.png",
														"url": "https://staging-media.toyota.com.ar/f07d5a8e-ed8b-4225-b951-ddb50d553ccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display GR 2",
														"path": "7a391652-490f-4bc4-86b8-14c8a9f707ec.png",
														"url": "https://staging-media.toyota.com.ar/7a391652-490f-4bc4-86b8-14c8a9f707ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo - Corolla Cross",
														"path": "a3f8ac3f-8e03-45cd-a299-1c11dc73debb.png",
														"url": "https://staging-media.toyota.com.br/a3f8ac3f-8e03-45cd-a299-1c11dc73debb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "18111117-566e-4c71-82a0-5cff3ed82214.jpeg",
														"url": "https://staging-media.toyota.com.br/18111117-566e-4c71-82a0-5cff3ed82214.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "f9e41567-407b-4671-a693-588f124d6a24.jpeg",
														"url": "https://staging-media.toyota.com.br/f9e41567-407b-4671-a693-588f124d6a24.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "4b0ec738-c250-4695-b212-abb0e3a3989c.jpeg",
														"url": "https://staging-media.toyota.com.br/4b0ec738-c250-4695-b212-abb0e3a3989c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2WK",
													"media": {
														"title": "1",
														"path": "80913cba-f5d3-4315-b75a-a0afa2578a41.png",
														"url": "https://staging-media.toyota.com.br/80913cba-f5d3-4315-b75a-a0afa2578a41.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2WK",
													"media": {
														"title": "2",
														"path": "1172a88a-d26c-45ea-8b9c-4224beb58b1f.png",
														"url": "https://staging-media.toyota.com.br/1172a88a-d26c-45ea-8b9c-4224beb58b1f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2WH",
													"media": {
														"title": "3",
														"path": "3fd20cb7-0906-4c0e-8eba-3dd90a4ce64a.png",
														"url": "https://staging-media.toyota.com.br/3fd20cb7-0906-4c0e-8eba-3dd90a4ce64a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2WH",
													"media": {
														"title": "4",
														"path": "e68adc50-5c0d-4bed-bd81-a1096abe483d.png",
														"url": "https://staging-media.toyota.com.br/e68adc50-5c0d-4bed-bd81-a1096abe483d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "gr-s",
														"path": "131b6892-7987-4d8b-b185-1968836a6fa1.png",
														"url": "https://staging-media.toyota.com.br/131b6892-7987-4d8b-b185-1968836a6fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "gr-s mobile",
														"path": "83f839a7-3f7a-43ca-bd3c-013636067a4c.png",
														"url": "https://staging-media.toyota.com.br/83f839a7-3f7a-43ca-bd3c-013636067a4c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "ecdd47da-a9f3-4952-8f19-6b06798ba687.jpeg",
														"url": "https://staging-media.toyota.com.br/ecdd47da-a9f3-4952-8f19-6b06798ba687.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "3b2a301c-17be-4bbe-b187-0bf79425a2e5.jpeg",
														"url": "https://staging-media.toyota.com.br/3b2a301c-17be-4bbe-b187-0bf79425a2e5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "5fb50469-2b1d-4d36-86de-4a601a27cc96.jpeg",
														"url": "https://staging-media.toyota.com.br/5fb50469-2b1d-4d36-86de-4a601a27cc96.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "abfa84fb-38cf-4639-9424-5cd4b9f63e2c.jpeg",
														"url": "https://staging-media.toyota.com.br/abfa84fb-38cf-4639-9424-5cd4b9f63e2c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "29f2eeeb-a932-408e-8096-f883171285fc.jpeg",
														"url": "https://staging-media.toyota.com.br/29f2eeeb-a932-408e-8096-f883171285fc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "6",
														"path": "36e05ea2-aa38-40f9-867b-f3b2a66d818f.jpeg",
														"url": "https://staging-media.toyota.com.br/36e05ea2-aa38-40f9-867b-f3b2a66d818f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "b5006cd8-5daf-4611-838c-30f96a57f85c.jpeg",
														"url": "https://staging-media.toyota.com.br/b5006cd8-5daf-4611-838c-30f96a57f85c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "8",
														"path": "42e84311-24d8-4885-a654-24d87d828cab.jpeg",
														"url": "https://staging-media.toyota.com.br/42e84311-24d8-4885-a654-24d87d828cab.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "18111117-566e-4c71-82a0-5cff3ed82214.jpeg",
														"url": "https://staging-media.toyota.com.br/18111117-566e-4c71-82a0-5cff3ed82214.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "3",
														"path": "f9e41567-407b-4671-a693-588f124d6a24.jpeg",
														"url": "https://staging-media.toyota.com.br/f9e41567-407b-4671-a693-588f124d6a24.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "4b0ec738-c250-4695-b212-abb0e3a3989c.jpeg",
														"url": "https://staging-media.toyota.com.br/4b0ec738-c250-4695-b212-abb0e3a3989c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 199690,
										"offers": null,
										"color": {
											"name": "Branco Lunar (2WH)",
											"code": "2WH",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "Display GR 1",
														"path": "f07d5a8e-ed8b-4225-b951-ddb50d553ccb.png",
														"url": "https://staging-media.toyota.com.ar/f07d5a8e-ed8b-4225-b951-ddb50d553ccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display GR 2",
														"path": "7a391652-490f-4bc4-86b8-14c8a9f707ec.png",
														"url": "https://staging-media.toyota.com.ar/7a391652-490f-4bc4-86b8-14c8a9f707ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo - Corolla Cross",
														"path": "a3f8ac3f-8e03-45cd-a299-1c11dc73debb.png",
														"url": "https://staging-media.toyota.com.br/a3f8ac3f-8e03-45cd-a299-1c11dc73debb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "18111117-566e-4c71-82a0-5cff3ed82214.jpeg",
														"url": "https://staging-media.toyota.com.br/18111117-566e-4c71-82a0-5cff3ed82214.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "f9e41567-407b-4671-a693-588f124d6a24.jpeg",
														"url": "https://staging-media.toyota.com.br/f9e41567-407b-4671-a693-588f124d6a24.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "4b0ec738-c250-4695-b212-abb0e3a3989c.jpeg",
														"url": "https://staging-media.toyota.com.br/4b0ec738-c250-4695-b212-abb0e3a3989c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2WK",
													"media": {
														"title": "1",
														"path": "80913cba-f5d3-4315-b75a-a0afa2578a41.png",
														"url": "https://staging-media.toyota.com.br/80913cba-f5d3-4315-b75a-a0afa2578a41.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2WK",
													"media": {
														"title": "2",
														"path": "1172a88a-d26c-45ea-8b9c-4224beb58b1f.png",
														"url": "https://staging-media.toyota.com.br/1172a88a-d26c-45ea-8b9c-4224beb58b1f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2WH",
													"media": {
														"title": "3",
														"path": "3fd20cb7-0906-4c0e-8eba-3dd90a4ce64a.png",
														"url": "https://staging-media.toyota.com.br/3fd20cb7-0906-4c0e-8eba-3dd90a4ce64a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2WH",
													"media": {
														"title": "4",
														"path": "e68adc50-5c0d-4bed-bd81-a1096abe483d.png",
														"url": "https://staging-media.toyota.com.br/e68adc50-5c0d-4bed-bd81-a1096abe483d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "gr-s",
														"path": "131b6892-7987-4d8b-b185-1968836a6fa1.png",
														"url": "https://staging-media.toyota.com.br/131b6892-7987-4d8b-b185-1968836a6fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "gr-s mobile",
														"path": "83f839a7-3f7a-43ca-bd3c-013636067a4c.png",
														"url": "https://staging-media.toyota.com.br/83f839a7-3f7a-43ca-bd3c-013636067a4c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "ecdd47da-a9f3-4952-8f19-6b06798ba687.jpeg",
														"url": "https://staging-media.toyota.com.br/ecdd47da-a9f3-4952-8f19-6b06798ba687.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "3b2a301c-17be-4bbe-b187-0bf79425a2e5.jpeg",
														"url": "https://staging-media.toyota.com.br/3b2a301c-17be-4bbe-b187-0bf79425a2e5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "5fb50469-2b1d-4d36-86de-4a601a27cc96.jpeg",
														"url": "https://staging-media.toyota.com.br/5fb50469-2b1d-4d36-86de-4a601a27cc96.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "abfa84fb-38cf-4639-9424-5cd4b9f63e2c.jpeg",
														"url": "https://staging-media.toyota.com.br/abfa84fb-38cf-4639-9424-5cd4b9f63e2c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "29f2eeeb-a932-408e-8096-f883171285fc.jpeg",
														"url": "https://staging-media.toyota.com.br/29f2eeeb-a932-408e-8096-f883171285fc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "6",
														"path": "36e05ea2-aa38-40f9-867b-f3b2a66d818f.jpeg",
														"url": "https://staging-media.toyota.com.br/36e05ea2-aa38-40f9-867b-f3b2a66d818f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "b5006cd8-5daf-4611-838c-30f96a57f85c.jpeg",
														"url": "https://staging-media.toyota.com.br/b5006cd8-5daf-4611-838c-30f96a57f85c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "8",
														"path": "42e84311-24d8-4885-a654-24d87d828cab.jpeg",
														"url": "https://staging-media.toyota.com.br/42e84311-24d8-4885-a654-24d87d828cab.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "18111117-566e-4c71-82a0-5cff3ed82214.jpeg",
														"url": "https://staging-media.toyota.com.br/18111117-566e-4c71-82a0-5cff3ed82214.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "3",
														"path": "f9e41567-407b-4671-a693-588f124d6a24.jpeg",
														"url": "https://staging-media.toyota.com.br/f9e41567-407b-4671-a693-588f124d6a24.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "4b0ec738-c250-4695-b212-abb0e3a3989c.jpeg",
														"url": "https://staging-media.toyota.com.br/4b0ec738-c250-4695-b212-abb0e3a3989c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 199690,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (2WK)",
											"code": "2WK",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "Display GR 1",
														"path": "f07d5a8e-ed8b-4225-b951-ddb50d553ccb.png",
														"url": "https://staging-media.toyota.com.ar/f07d5a8e-ed8b-4225-b951-ddb50d553ccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display GR 2",
														"path": "7a391652-490f-4bc4-86b8-14c8a9f707ec.png",
														"url": "https://staging-media.toyota.com.ar/7a391652-490f-4bc4-86b8-14c8a9f707ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo - Corolla Cross",
														"path": "a3f8ac3f-8e03-45cd-a299-1c11dc73debb.png",
														"url": "https://staging-media.toyota.com.br/a3f8ac3f-8e03-45cd-a299-1c11dc73debb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "18111117-566e-4c71-82a0-5cff3ed82214.jpeg",
														"url": "https://staging-media.toyota.com.br/18111117-566e-4c71-82a0-5cff3ed82214.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "f9e41567-407b-4671-a693-588f124d6a24.jpeg",
														"url": "https://staging-media.toyota.com.br/f9e41567-407b-4671-a693-588f124d6a24.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "4b0ec738-c250-4695-b212-abb0e3a3989c.jpeg",
														"url": "https://staging-media.toyota.com.br/4b0ec738-c250-4695-b212-abb0e3a3989c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2WK",
													"media": {
														"title": "1",
														"path": "80913cba-f5d3-4315-b75a-a0afa2578a41.png",
														"url": "https://staging-media.toyota.com.br/80913cba-f5d3-4315-b75a-a0afa2578a41.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2WK",
													"media": {
														"title": "2",
														"path": "1172a88a-d26c-45ea-8b9c-4224beb58b1f.png",
														"url": "https://staging-media.toyota.com.br/1172a88a-d26c-45ea-8b9c-4224beb58b1f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2WH",
													"media": {
														"title": "3",
														"path": "3fd20cb7-0906-4c0e-8eba-3dd90a4ce64a.png",
														"url": "https://staging-media.toyota.com.br/3fd20cb7-0906-4c0e-8eba-3dd90a4ce64a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2WH",
													"media": {
														"title": "4",
														"path": "e68adc50-5c0d-4bed-bd81-a1096abe483d.png",
														"url": "https://staging-media.toyota.com.br/e68adc50-5c0d-4bed-bd81-a1096abe483d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "gr-s",
														"path": "131b6892-7987-4d8b-b185-1968836a6fa1.png",
														"url": "https://staging-media.toyota.com.br/131b6892-7987-4d8b-b185-1968836a6fa1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "gr-s mobile",
														"path": "83f839a7-3f7a-43ca-bd3c-013636067a4c.png",
														"url": "https://staging-media.toyota.com.br/83f839a7-3f7a-43ca-bd3c-013636067a4c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "ecdd47da-a9f3-4952-8f19-6b06798ba687.jpeg",
														"url": "https://staging-media.toyota.com.br/ecdd47da-a9f3-4952-8f19-6b06798ba687.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "3b2a301c-17be-4bbe-b187-0bf79425a2e5.jpeg",
														"url": "https://staging-media.toyota.com.br/3b2a301c-17be-4bbe-b187-0bf79425a2e5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "5fb50469-2b1d-4d36-86de-4a601a27cc96.jpeg",
														"url": "https://staging-media.toyota.com.br/5fb50469-2b1d-4d36-86de-4a601a27cc96.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "abfa84fb-38cf-4639-9424-5cd4b9f63e2c.jpeg",
														"url": "https://staging-media.toyota.com.br/abfa84fb-38cf-4639-9424-5cd4b9f63e2c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "29f2eeeb-a932-408e-8096-f883171285fc.jpeg",
														"url": "https://staging-media.toyota.com.br/29f2eeeb-a932-408e-8096-f883171285fc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "6",
														"path": "36e05ea2-aa38-40f9-867b-f3b2a66d818f.jpeg",
														"url": "https://staging-media.toyota.com.br/36e05ea2-aa38-40f9-867b-f3b2a66d818f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "b5006cd8-5daf-4611-838c-30f96a57f85c.jpeg",
														"url": "https://staging-media.toyota.com.br/b5006cd8-5daf-4611-838c-30f96a57f85c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "8",
														"path": "42e84311-24d8-4885-a654-24d87d828cab.jpeg",
														"url": "https://staging-media.toyota.com.br/42e84311-24d8-4885-a654-24d87d828cab.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "18111117-566e-4c71-82a0-5cff3ed82214.jpeg",
														"url": "https://staging-media.toyota.com.br/18111117-566e-4c71-82a0-5cff3ed82214.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "3",
														"path": "f9e41567-407b-4671-a693-588f124d6a24.jpeg",
														"url": "https://staging-media.toyota.com.br/f9e41567-407b-4671-a693-588f124d6a24.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "4b0ec738-c250-4695-b212-abb0e3a3989c.jpeg",
														"url": "https://staging-media.toyota.com.br/4b0ec738-c250-4695-b212-abb0e3a3989c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "XRV Hybrid",
						"id": "bb405c59-19bc-411d-9f9d-d3bf4e2a1f0c",
						"order": 4,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 202290,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 6",
														"path": "ca66af7a-6b0f-49ee-9853-cceed715fb25.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca66af7a-6b0f-49ee-9853-cceed715fb25.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 7",
														"path": "4829abe5-816a-4330-99a2-ad472bd477a3.jpeg",
														"url": "https://staging-media.toyota.com.ar/4829abe5-816a-4330-99a2-ad472bd477a3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 1",
														"path": "53f38bde-770c-45db-9145-d8108871f77f.jpeg",
														"url": "https://staging-media.toyota.com.ar/53f38bde-770c-45db-9145-d8108871f77f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 2",
														"path": "00681ed7-f4d2-40e7-a494-da0c863dd651.jpeg",
														"url": "https://staging-media.toyota.com.ar/00681ed7-f4d2-40e7-a494-da0c863dd651.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv  3",
														"path": "434fa60a-abef-4a56-9ceb-77458becaa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/434fa60a-abef-4a56-9ceb-77458becaa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 4",
														"path": "67b29a53-0570-4c85-a432-f8b0788ff8c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/67b29a53-0570-4c85-a432-f8b0788ff8c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 5",
														"path": "a89fa5fb-ed27-492a-865f-bb8c5f7ea5a8.jpeg",
														"url": "https://staging-media.toyota.com.ar/a89fa5fb-ed27-492a-865f-bb8c5f7ea5a8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 1",
														"path": "eb587a0b-d0db-4308-8818-df098be571f1.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb587a0b-d0db-4308-8818-df098be571f1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 2",
														"path": "cc97b972-2a32-4a30-ba2c-3ef1bdcdf614.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc97b972-2a32-4a30-ba2c-3ef1bdcdf614.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 3",
														"path": "9cba9f7d-e349-486f-b99e-a9cde8554b08.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cba9f7d-e349-486f-b99e-a9cde8554b08.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 4",
														"path": "de4a7490-aa11-4b91-b5fb-7c690a3356f7.jpeg",
														"url": "https://staging-media.toyota.com.ar/de4a7490-aa11-4b91-b5fb-7c690a3356f7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 5",
														"path": "91f4f4bf-65f2-4882-829c-3b5751241c0c.jpeg",
														"url": "https://staging-media.toyota.com.ar/91f4f4bf-65f2-4882-829c-3b5751241c0c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv ",
														"path": "923b38be-365f-4981-8fd6-8ee76c2ec95c.jpeg",
														"url": "https://staging-media.toyota.com.ar/923b38be-365f-4981-8fd6-8ee76c2ec95c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 7",
														"path": "1edc9776-0288-44aa-b374-2a7ce42485b4.jpeg",
														"url": "https://staging-media.toyota.com.ar/1edc9776-0288-44aa-b374-2a7ce42485b4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 8",
														"path": "306b6ba5-e021-4c60-b5ef-9573b0eb54e0.jpeg",
														"url": "https://staging-media.toyota.com.ar/306b6ba5-e021-4c60-b5ef-9573b0eb54e0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Cross Desktop Banner",
														"path": "fc4a2131-3ed2-4f4e-b83a-a20f489a7c45.jpeg",
														"url": "https://staging-media.toyota.com.ar/fc4a2131-3ed2-4f4e-b83a-a20f489a7c45.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "3637d406-7db7-4201-85e3-289cf11c89af.png",
														"url": "https://staging-media.toyota.com.ar/3637d406-7db7-4201-85e3-289cf11c89af.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display normal",
														"path": "b65ebd97-45ff-474d-b294-599c16997859.png",
														"url": "https://staging-media.toyota.com.ar/b65ebd97-45ff-474d-b294-599c16997859.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display mouse hover",
														"path": "5639745e-91e9-480c-8b31-c4a3d8f8a49d.png",
														"url": "https://staging-media.toyota.com.ar/5639745e-91e9-480c-8b31-c4a3d8f8a49d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner mobile",
														"path": "c993af48-6270-4714-b4e6-f5564292517b.jpeg",
														"url": "https://staging-media.toyota.com.ar/c993af48-6270-4714-b4e6-f5564292517b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360",
														"path": "bff4fc72-4105-46d3-ac74-c70c11cab329.png",
														"url": "https://staging-media.toyota.com.ar/bff4fc72-4105-46d3-ac74-c70c11cab329.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 360mobile",
														"path": "b630477f-bb42-4fa1-a104-28f4afb0d8f7.png",
														"url": "https://staging-media.toyota.com.ar/b630477f-bb42-4fa1-a104-28f4afb0d8f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 360",
														"path": "6f3833b8-09d2-436f-9bb6-fb044b34c992.png",
														"url": "https://staging-media.toyota.com.ar/6f3833b8-09d2-436f-9bb6-fb044b34c992.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "f6395ed6-4bf1-4974-b4db-7a93d34c5ac1.png",
														"url": "https://staging-media.toyota.com.ar/f6395ed6-4bf1-4974-b4db-7a93d34c5ac1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3 360",
														"path": "648b8c84-5a5e-43de-b2d2-fe3012b86dd3.png",
														"url": "https://staging-media.toyota.com.ar/648b8c84-5a5e-43de-b2d2-fe3012b86dd3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "aa4bbceb-e1c5-4bdd-99b8-5b4ec8b1fcef.png",
														"url": "https://staging-media.toyota.com.ar/aa4bbceb-e1c5-4bdd-99b8-5b4ec8b1fcef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 360",
														"path": "32671525-fd4f-4ef7-a876-dbf9c93fd24e.png",
														"url": "https://staging-media.toyota.com.ar/32671525-fd4f-4ef7-a876-dbf9c93fd24e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "2e3f07b9-afb2-4249-bacd-92b1824ec3b8.png",
														"url": "https://staging-media.toyota.com.ar/2e3f07b9-afb2-4249-bacd-92b1824ec3b8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6 360",
														"path": "8d14e9c3-61bb-4025-8cfb-5bac3fe3e76f.png",
														"url": "https://staging-media.toyota.com.ar/8d14e9c3-61bb-4025-8cfb-5bac3fe3e76f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile",
														"path": "f21c617f-2b8a-4b3a-b5af-c08ba4efe213.png",
														"url": "https://staging-media.toyota.com.ar/f21c617f-2b8a-4b3a-b5af-c08ba4efe213.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 360",
														"path": "a63d6ffb-aacf-4fc2-b53e-cfc0b52b29b0.png",
														"url": "https://staging-media.toyota.com.ar/a63d6ffb-aacf-4fc2-b53e-cfc0b52b29b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "f448aa39-14ee-43e5-9079-aea71e971317.png",
														"url": "https://staging-media.toyota.com.ar/f448aa39-14ee-43e5-9079-aea71e971317.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 204310,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 6",
														"path": "ca66af7a-6b0f-49ee-9853-cceed715fb25.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca66af7a-6b0f-49ee-9853-cceed715fb25.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 7",
														"path": "4829abe5-816a-4330-99a2-ad472bd477a3.jpeg",
														"url": "https://staging-media.toyota.com.ar/4829abe5-816a-4330-99a2-ad472bd477a3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 1",
														"path": "53f38bde-770c-45db-9145-d8108871f77f.jpeg",
														"url": "https://staging-media.toyota.com.ar/53f38bde-770c-45db-9145-d8108871f77f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 2",
														"path": "00681ed7-f4d2-40e7-a494-da0c863dd651.jpeg",
														"url": "https://staging-media.toyota.com.ar/00681ed7-f4d2-40e7-a494-da0c863dd651.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv  3",
														"path": "434fa60a-abef-4a56-9ceb-77458becaa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/434fa60a-abef-4a56-9ceb-77458becaa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 4",
														"path": "67b29a53-0570-4c85-a432-f8b0788ff8c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/67b29a53-0570-4c85-a432-f8b0788ff8c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 5",
														"path": "a89fa5fb-ed27-492a-865f-bb8c5f7ea5a8.jpeg",
														"url": "https://staging-media.toyota.com.ar/a89fa5fb-ed27-492a-865f-bb8c5f7ea5a8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 1",
														"path": "eb587a0b-d0db-4308-8818-df098be571f1.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb587a0b-d0db-4308-8818-df098be571f1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 2",
														"path": "cc97b972-2a32-4a30-ba2c-3ef1bdcdf614.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc97b972-2a32-4a30-ba2c-3ef1bdcdf614.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 3",
														"path": "9cba9f7d-e349-486f-b99e-a9cde8554b08.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cba9f7d-e349-486f-b99e-a9cde8554b08.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 4",
														"path": "de4a7490-aa11-4b91-b5fb-7c690a3356f7.jpeg",
														"url": "https://staging-media.toyota.com.ar/de4a7490-aa11-4b91-b5fb-7c690a3356f7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 5",
														"path": "91f4f4bf-65f2-4882-829c-3b5751241c0c.jpeg",
														"url": "https://staging-media.toyota.com.ar/91f4f4bf-65f2-4882-829c-3b5751241c0c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv ",
														"path": "923b38be-365f-4981-8fd6-8ee76c2ec95c.jpeg",
														"url": "https://staging-media.toyota.com.ar/923b38be-365f-4981-8fd6-8ee76c2ec95c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 7",
														"path": "1edc9776-0288-44aa-b374-2a7ce42485b4.jpeg",
														"url": "https://staging-media.toyota.com.ar/1edc9776-0288-44aa-b374-2a7ce42485b4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 8",
														"path": "306b6ba5-e021-4c60-b5ef-9573b0eb54e0.jpeg",
														"url": "https://staging-media.toyota.com.ar/306b6ba5-e021-4c60-b5ef-9573b0eb54e0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Cross Desktop Banner",
														"path": "fc4a2131-3ed2-4f4e-b83a-a20f489a7c45.jpeg",
														"url": "https://staging-media.toyota.com.ar/fc4a2131-3ed2-4f4e-b83a-a20f489a7c45.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "3637d406-7db7-4201-85e3-289cf11c89af.png",
														"url": "https://staging-media.toyota.com.ar/3637d406-7db7-4201-85e3-289cf11c89af.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display normal",
														"path": "b65ebd97-45ff-474d-b294-599c16997859.png",
														"url": "https://staging-media.toyota.com.ar/b65ebd97-45ff-474d-b294-599c16997859.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display mouse hover",
														"path": "5639745e-91e9-480c-8b31-c4a3d8f8a49d.png",
														"url": "https://staging-media.toyota.com.ar/5639745e-91e9-480c-8b31-c4a3d8f8a49d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner mobile",
														"path": "c993af48-6270-4714-b4e6-f5564292517b.jpeg",
														"url": "https://staging-media.toyota.com.ar/c993af48-6270-4714-b4e6-f5564292517b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360",
														"path": "bff4fc72-4105-46d3-ac74-c70c11cab329.png",
														"url": "https://staging-media.toyota.com.ar/bff4fc72-4105-46d3-ac74-c70c11cab329.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 360mobile",
														"path": "b630477f-bb42-4fa1-a104-28f4afb0d8f7.png",
														"url": "https://staging-media.toyota.com.ar/b630477f-bb42-4fa1-a104-28f4afb0d8f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 360",
														"path": "6f3833b8-09d2-436f-9bb6-fb044b34c992.png",
														"url": "https://staging-media.toyota.com.ar/6f3833b8-09d2-436f-9bb6-fb044b34c992.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "f6395ed6-4bf1-4974-b4db-7a93d34c5ac1.png",
														"url": "https://staging-media.toyota.com.ar/f6395ed6-4bf1-4974-b4db-7a93d34c5ac1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3 360",
														"path": "648b8c84-5a5e-43de-b2d2-fe3012b86dd3.png",
														"url": "https://staging-media.toyota.com.ar/648b8c84-5a5e-43de-b2d2-fe3012b86dd3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "aa4bbceb-e1c5-4bdd-99b8-5b4ec8b1fcef.png",
														"url": "https://staging-media.toyota.com.ar/aa4bbceb-e1c5-4bdd-99b8-5b4ec8b1fcef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 360",
														"path": "32671525-fd4f-4ef7-a876-dbf9c93fd24e.png",
														"url": "https://staging-media.toyota.com.ar/32671525-fd4f-4ef7-a876-dbf9c93fd24e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "2e3f07b9-afb2-4249-bacd-92b1824ec3b8.png",
														"url": "https://staging-media.toyota.com.ar/2e3f07b9-afb2-4249-bacd-92b1824ec3b8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6 360",
														"path": "8d14e9c3-61bb-4025-8cfb-5bac3fe3e76f.png",
														"url": "https://staging-media.toyota.com.ar/8d14e9c3-61bb-4025-8cfb-5bac3fe3e76f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile",
														"path": "f21c617f-2b8a-4b3a-b5af-c08ba4efe213.png",
														"url": "https://staging-media.toyota.com.ar/f21c617f-2b8a-4b3a-b5af-c08ba4efe213.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 360",
														"path": "a63d6ffb-aacf-4fc2-b53e-cfc0b52b29b0.png",
														"url": "https://staging-media.toyota.com.ar/a63d6ffb-aacf-4fc2-b53e-cfc0b52b29b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "f448aa39-14ee-43e5-9079-aea71e971317.png",
														"url": "https://staging-media.toyota.com.ar/f448aa39-14ee-43e5-9079-aea71e971317.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 204310,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 6",
														"path": "ca66af7a-6b0f-49ee-9853-cceed715fb25.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca66af7a-6b0f-49ee-9853-cceed715fb25.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 7",
														"path": "4829abe5-816a-4330-99a2-ad472bd477a3.jpeg",
														"url": "https://staging-media.toyota.com.ar/4829abe5-816a-4330-99a2-ad472bd477a3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 1",
														"path": "53f38bde-770c-45db-9145-d8108871f77f.jpeg",
														"url": "https://staging-media.toyota.com.ar/53f38bde-770c-45db-9145-d8108871f77f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 2",
														"path": "00681ed7-f4d2-40e7-a494-da0c863dd651.jpeg",
														"url": "https://staging-media.toyota.com.ar/00681ed7-f4d2-40e7-a494-da0c863dd651.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv  3",
														"path": "434fa60a-abef-4a56-9ceb-77458becaa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/434fa60a-abef-4a56-9ceb-77458becaa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 4",
														"path": "67b29a53-0570-4c85-a432-f8b0788ff8c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/67b29a53-0570-4c85-a432-f8b0788ff8c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 5",
														"path": "a89fa5fb-ed27-492a-865f-bb8c5f7ea5a8.jpeg",
														"url": "https://staging-media.toyota.com.ar/a89fa5fb-ed27-492a-865f-bb8c5f7ea5a8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 1",
														"path": "eb587a0b-d0db-4308-8818-df098be571f1.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb587a0b-d0db-4308-8818-df098be571f1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 2",
														"path": "cc97b972-2a32-4a30-ba2c-3ef1bdcdf614.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc97b972-2a32-4a30-ba2c-3ef1bdcdf614.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 3",
														"path": "9cba9f7d-e349-486f-b99e-a9cde8554b08.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cba9f7d-e349-486f-b99e-a9cde8554b08.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 4",
														"path": "de4a7490-aa11-4b91-b5fb-7c690a3356f7.jpeg",
														"url": "https://staging-media.toyota.com.ar/de4a7490-aa11-4b91-b5fb-7c690a3356f7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 5",
														"path": "91f4f4bf-65f2-4882-829c-3b5751241c0c.jpeg",
														"url": "https://staging-media.toyota.com.ar/91f4f4bf-65f2-4882-829c-3b5751241c0c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv ",
														"path": "923b38be-365f-4981-8fd6-8ee76c2ec95c.jpeg",
														"url": "https://staging-media.toyota.com.ar/923b38be-365f-4981-8fd6-8ee76c2ec95c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 7",
														"path": "1edc9776-0288-44aa-b374-2a7ce42485b4.jpeg",
														"url": "https://staging-media.toyota.com.ar/1edc9776-0288-44aa-b374-2a7ce42485b4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 8",
														"path": "306b6ba5-e021-4c60-b5ef-9573b0eb54e0.jpeg",
														"url": "https://staging-media.toyota.com.ar/306b6ba5-e021-4c60-b5ef-9573b0eb54e0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Cross Desktop Banner",
														"path": "fc4a2131-3ed2-4f4e-b83a-a20f489a7c45.jpeg",
														"url": "https://staging-media.toyota.com.ar/fc4a2131-3ed2-4f4e-b83a-a20f489a7c45.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "3637d406-7db7-4201-85e3-289cf11c89af.png",
														"url": "https://staging-media.toyota.com.ar/3637d406-7db7-4201-85e3-289cf11c89af.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display normal",
														"path": "b65ebd97-45ff-474d-b294-599c16997859.png",
														"url": "https://staging-media.toyota.com.ar/b65ebd97-45ff-474d-b294-599c16997859.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display mouse hover",
														"path": "5639745e-91e9-480c-8b31-c4a3d8f8a49d.png",
														"url": "https://staging-media.toyota.com.ar/5639745e-91e9-480c-8b31-c4a3d8f8a49d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner mobile",
														"path": "c993af48-6270-4714-b4e6-f5564292517b.jpeg",
														"url": "https://staging-media.toyota.com.ar/c993af48-6270-4714-b4e6-f5564292517b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360",
														"path": "bff4fc72-4105-46d3-ac74-c70c11cab329.png",
														"url": "https://staging-media.toyota.com.ar/bff4fc72-4105-46d3-ac74-c70c11cab329.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 360mobile",
														"path": "b630477f-bb42-4fa1-a104-28f4afb0d8f7.png",
														"url": "https://staging-media.toyota.com.ar/b630477f-bb42-4fa1-a104-28f4afb0d8f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 360",
														"path": "6f3833b8-09d2-436f-9bb6-fb044b34c992.png",
														"url": "https://staging-media.toyota.com.ar/6f3833b8-09d2-436f-9bb6-fb044b34c992.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "f6395ed6-4bf1-4974-b4db-7a93d34c5ac1.png",
														"url": "https://staging-media.toyota.com.ar/f6395ed6-4bf1-4974-b4db-7a93d34c5ac1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3 360",
														"path": "648b8c84-5a5e-43de-b2d2-fe3012b86dd3.png",
														"url": "https://staging-media.toyota.com.ar/648b8c84-5a5e-43de-b2d2-fe3012b86dd3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "aa4bbceb-e1c5-4bdd-99b8-5b4ec8b1fcef.png",
														"url": "https://staging-media.toyota.com.ar/aa4bbceb-e1c5-4bdd-99b8-5b4ec8b1fcef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 360",
														"path": "32671525-fd4f-4ef7-a876-dbf9c93fd24e.png",
														"url": "https://staging-media.toyota.com.ar/32671525-fd4f-4ef7-a876-dbf9c93fd24e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "2e3f07b9-afb2-4249-bacd-92b1824ec3b8.png",
														"url": "https://staging-media.toyota.com.ar/2e3f07b9-afb2-4249-bacd-92b1824ec3b8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6 360",
														"path": "8d14e9c3-61bb-4025-8cfb-5bac3fe3e76f.png",
														"url": "https://staging-media.toyota.com.ar/8d14e9c3-61bb-4025-8cfb-5bac3fe3e76f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile",
														"path": "f21c617f-2b8a-4b3a-b5af-c08ba4efe213.png",
														"url": "https://staging-media.toyota.com.ar/f21c617f-2b8a-4b3a-b5af-c08ba4efe213.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 360",
														"path": "a63d6ffb-aacf-4fc2-b53e-cfc0b52b29b0.png",
														"url": "https://staging-media.toyota.com.ar/a63d6ffb-aacf-4fc2-b53e-cfc0b52b29b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "f448aa39-14ee-43e5-9079-aea71e971317.png",
														"url": "https://staging-media.toyota.com.ar/f448aa39-14ee-43e5-9079-aea71e971317.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 204310,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 6",
														"path": "ca66af7a-6b0f-49ee-9853-cceed715fb25.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca66af7a-6b0f-49ee-9853-cceed715fb25.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 7",
														"path": "4829abe5-816a-4330-99a2-ad472bd477a3.jpeg",
														"url": "https://staging-media.toyota.com.ar/4829abe5-816a-4330-99a2-ad472bd477a3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 1",
														"path": "53f38bde-770c-45db-9145-d8108871f77f.jpeg",
														"url": "https://staging-media.toyota.com.ar/53f38bde-770c-45db-9145-d8108871f77f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 2",
														"path": "00681ed7-f4d2-40e7-a494-da0c863dd651.jpeg",
														"url": "https://staging-media.toyota.com.ar/00681ed7-f4d2-40e7-a494-da0c863dd651.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv  3",
														"path": "434fa60a-abef-4a56-9ceb-77458becaa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/434fa60a-abef-4a56-9ceb-77458becaa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 4",
														"path": "67b29a53-0570-4c85-a432-f8b0788ff8c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/67b29a53-0570-4c85-a432-f8b0788ff8c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 5",
														"path": "a89fa5fb-ed27-492a-865f-bb8c5f7ea5a8.jpeg",
														"url": "https://staging-media.toyota.com.ar/a89fa5fb-ed27-492a-865f-bb8c5f7ea5a8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 1",
														"path": "eb587a0b-d0db-4308-8818-df098be571f1.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb587a0b-d0db-4308-8818-df098be571f1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 2",
														"path": "cc97b972-2a32-4a30-ba2c-3ef1bdcdf614.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc97b972-2a32-4a30-ba2c-3ef1bdcdf614.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 3",
														"path": "9cba9f7d-e349-486f-b99e-a9cde8554b08.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cba9f7d-e349-486f-b99e-a9cde8554b08.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 4",
														"path": "de4a7490-aa11-4b91-b5fb-7c690a3356f7.jpeg",
														"url": "https://staging-media.toyota.com.ar/de4a7490-aa11-4b91-b5fb-7c690a3356f7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 5",
														"path": "91f4f4bf-65f2-4882-829c-3b5751241c0c.jpeg",
														"url": "https://staging-media.toyota.com.ar/91f4f4bf-65f2-4882-829c-3b5751241c0c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv ",
														"path": "923b38be-365f-4981-8fd6-8ee76c2ec95c.jpeg",
														"url": "https://staging-media.toyota.com.ar/923b38be-365f-4981-8fd6-8ee76c2ec95c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 7",
														"path": "1edc9776-0288-44aa-b374-2a7ce42485b4.jpeg",
														"url": "https://staging-media.toyota.com.ar/1edc9776-0288-44aa-b374-2a7ce42485b4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 8",
														"path": "306b6ba5-e021-4c60-b5ef-9573b0eb54e0.jpeg",
														"url": "https://staging-media.toyota.com.ar/306b6ba5-e021-4c60-b5ef-9573b0eb54e0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Cross Desktop Banner",
														"path": "fc4a2131-3ed2-4f4e-b83a-a20f489a7c45.jpeg",
														"url": "https://staging-media.toyota.com.ar/fc4a2131-3ed2-4f4e-b83a-a20f489a7c45.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "3637d406-7db7-4201-85e3-289cf11c89af.png",
														"url": "https://staging-media.toyota.com.ar/3637d406-7db7-4201-85e3-289cf11c89af.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display normal",
														"path": "b65ebd97-45ff-474d-b294-599c16997859.png",
														"url": "https://staging-media.toyota.com.ar/b65ebd97-45ff-474d-b294-599c16997859.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display mouse hover",
														"path": "5639745e-91e9-480c-8b31-c4a3d8f8a49d.png",
														"url": "https://staging-media.toyota.com.ar/5639745e-91e9-480c-8b31-c4a3d8f8a49d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner mobile",
														"path": "c993af48-6270-4714-b4e6-f5564292517b.jpeg",
														"url": "https://staging-media.toyota.com.ar/c993af48-6270-4714-b4e6-f5564292517b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360",
														"path": "bff4fc72-4105-46d3-ac74-c70c11cab329.png",
														"url": "https://staging-media.toyota.com.ar/bff4fc72-4105-46d3-ac74-c70c11cab329.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 360mobile",
														"path": "b630477f-bb42-4fa1-a104-28f4afb0d8f7.png",
														"url": "https://staging-media.toyota.com.ar/b630477f-bb42-4fa1-a104-28f4afb0d8f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 360",
														"path": "6f3833b8-09d2-436f-9bb6-fb044b34c992.png",
														"url": "https://staging-media.toyota.com.ar/6f3833b8-09d2-436f-9bb6-fb044b34c992.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "f6395ed6-4bf1-4974-b4db-7a93d34c5ac1.png",
														"url": "https://staging-media.toyota.com.ar/f6395ed6-4bf1-4974-b4db-7a93d34c5ac1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3 360",
														"path": "648b8c84-5a5e-43de-b2d2-fe3012b86dd3.png",
														"url": "https://staging-media.toyota.com.ar/648b8c84-5a5e-43de-b2d2-fe3012b86dd3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "aa4bbceb-e1c5-4bdd-99b8-5b4ec8b1fcef.png",
														"url": "https://staging-media.toyota.com.ar/aa4bbceb-e1c5-4bdd-99b8-5b4ec8b1fcef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 360",
														"path": "32671525-fd4f-4ef7-a876-dbf9c93fd24e.png",
														"url": "https://staging-media.toyota.com.ar/32671525-fd4f-4ef7-a876-dbf9c93fd24e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "2e3f07b9-afb2-4249-bacd-92b1824ec3b8.png",
														"url": "https://staging-media.toyota.com.ar/2e3f07b9-afb2-4249-bacd-92b1824ec3b8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6 360",
														"path": "8d14e9c3-61bb-4025-8cfb-5bac3fe3e76f.png",
														"url": "https://staging-media.toyota.com.ar/8d14e9c3-61bb-4025-8cfb-5bac3fe3e76f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile",
														"path": "f21c617f-2b8a-4b3a-b5af-c08ba4efe213.png",
														"url": "https://staging-media.toyota.com.ar/f21c617f-2b8a-4b3a-b5af-c08ba4efe213.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 360",
														"path": "a63d6ffb-aacf-4fc2-b53e-cfc0b52b29b0.png",
														"url": "https://staging-media.toyota.com.ar/a63d6ffb-aacf-4fc2-b53e-cfc0b52b29b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "f448aa39-14ee-43e5-9079-aea71e971317.png",
														"url": "https://staging-media.toyota.com.ar/f448aa39-14ee-43e5-9079-aea71e971317.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 204310,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 6",
														"path": "ca66af7a-6b0f-49ee-9853-cceed715fb25.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca66af7a-6b0f-49ee-9853-cceed715fb25.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 7",
														"path": "4829abe5-816a-4330-99a2-ad472bd477a3.jpeg",
														"url": "https://staging-media.toyota.com.ar/4829abe5-816a-4330-99a2-ad472bd477a3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 1",
														"path": "53f38bde-770c-45db-9145-d8108871f77f.jpeg",
														"url": "https://staging-media.toyota.com.ar/53f38bde-770c-45db-9145-d8108871f77f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 2",
														"path": "00681ed7-f4d2-40e7-a494-da0c863dd651.jpeg",
														"url": "https://staging-media.toyota.com.ar/00681ed7-f4d2-40e7-a494-da0c863dd651.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv  3",
														"path": "434fa60a-abef-4a56-9ceb-77458becaa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/434fa60a-abef-4a56-9ceb-77458becaa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 4",
														"path": "67b29a53-0570-4c85-a432-f8b0788ff8c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/67b29a53-0570-4c85-a432-f8b0788ff8c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 5",
														"path": "a89fa5fb-ed27-492a-865f-bb8c5f7ea5a8.jpeg",
														"url": "https://staging-media.toyota.com.ar/a89fa5fb-ed27-492a-865f-bb8c5f7ea5a8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 1",
														"path": "eb587a0b-d0db-4308-8818-df098be571f1.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb587a0b-d0db-4308-8818-df098be571f1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 2",
														"path": "cc97b972-2a32-4a30-ba2c-3ef1bdcdf614.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc97b972-2a32-4a30-ba2c-3ef1bdcdf614.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 3",
														"path": "9cba9f7d-e349-486f-b99e-a9cde8554b08.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cba9f7d-e349-486f-b99e-a9cde8554b08.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 4",
														"path": "de4a7490-aa11-4b91-b5fb-7c690a3356f7.jpeg",
														"url": "https://staging-media.toyota.com.ar/de4a7490-aa11-4b91-b5fb-7c690a3356f7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 5",
														"path": "91f4f4bf-65f2-4882-829c-3b5751241c0c.jpeg",
														"url": "https://staging-media.toyota.com.ar/91f4f4bf-65f2-4882-829c-3b5751241c0c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv ",
														"path": "923b38be-365f-4981-8fd6-8ee76c2ec95c.jpeg",
														"url": "https://staging-media.toyota.com.ar/923b38be-365f-4981-8fd6-8ee76c2ec95c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 7",
														"path": "1edc9776-0288-44aa-b374-2a7ce42485b4.jpeg",
														"url": "https://staging-media.toyota.com.ar/1edc9776-0288-44aa-b374-2a7ce42485b4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 8",
														"path": "306b6ba5-e021-4c60-b5ef-9573b0eb54e0.jpeg",
														"url": "https://staging-media.toyota.com.ar/306b6ba5-e021-4c60-b5ef-9573b0eb54e0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Cross Desktop Banner",
														"path": "fc4a2131-3ed2-4f4e-b83a-a20f489a7c45.jpeg",
														"url": "https://staging-media.toyota.com.ar/fc4a2131-3ed2-4f4e-b83a-a20f489a7c45.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "3637d406-7db7-4201-85e3-289cf11c89af.png",
														"url": "https://staging-media.toyota.com.ar/3637d406-7db7-4201-85e3-289cf11c89af.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display normal",
														"path": "b65ebd97-45ff-474d-b294-599c16997859.png",
														"url": "https://staging-media.toyota.com.ar/b65ebd97-45ff-474d-b294-599c16997859.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display mouse hover",
														"path": "5639745e-91e9-480c-8b31-c4a3d8f8a49d.png",
														"url": "https://staging-media.toyota.com.ar/5639745e-91e9-480c-8b31-c4a3d8f8a49d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner mobile",
														"path": "c993af48-6270-4714-b4e6-f5564292517b.jpeg",
														"url": "https://staging-media.toyota.com.ar/c993af48-6270-4714-b4e6-f5564292517b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360",
														"path": "bff4fc72-4105-46d3-ac74-c70c11cab329.png",
														"url": "https://staging-media.toyota.com.ar/bff4fc72-4105-46d3-ac74-c70c11cab329.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 360mobile",
														"path": "b630477f-bb42-4fa1-a104-28f4afb0d8f7.png",
														"url": "https://staging-media.toyota.com.ar/b630477f-bb42-4fa1-a104-28f4afb0d8f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 360",
														"path": "6f3833b8-09d2-436f-9bb6-fb044b34c992.png",
														"url": "https://staging-media.toyota.com.ar/6f3833b8-09d2-436f-9bb6-fb044b34c992.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "f6395ed6-4bf1-4974-b4db-7a93d34c5ac1.png",
														"url": "https://staging-media.toyota.com.ar/f6395ed6-4bf1-4974-b4db-7a93d34c5ac1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3 360",
														"path": "648b8c84-5a5e-43de-b2d2-fe3012b86dd3.png",
														"url": "https://staging-media.toyota.com.ar/648b8c84-5a5e-43de-b2d2-fe3012b86dd3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "aa4bbceb-e1c5-4bdd-99b8-5b4ec8b1fcef.png",
														"url": "https://staging-media.toyota.com.ar/aa4bbceb-e1c5-4bdd-99b8-5b4ec8b1fcef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 360",
														"path": "32671525-fd4f-4ef7-a876-dbf9c93fd24e.png",
														"url": "https://staging-media.toyota.com.ar/32671525-fd4f-4ef7-a876-dbf9c93fd24e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "2e3f07b9-afb2-4249-bacd-92b1824ec3b8.png",
														"url": "https://staging-media.toyota.com.ar/2e3f07b9-afb2-4249-bacd-92b1824ec3b8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6 360",
														"path": "8d14e9c3-61bb-4025-8cfb-5bac3fe3e76f.png",
														"url": "https://staging-media.toyota.com.ar/8d14e9c3-61bb-4025-8cfb-5bac3fe3e76f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile",
														"path": "f21c617f-2b8a-4b3a-b5af-c08ba4efe213.png",
														"url": "https://staging-media.toyota.com.ar/f21c617f-2b8a-4b3a-b5af-c08ba4efe213.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 360",
														"path": "a63d6ffb-aacf-4fc2-b53e-cfc0b52b29b0.png",
														"url": "https://staging-media.toyota.com.ar/a63d6ffb-aacf-4fc2-b53e-cfc0b52b29b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "f448aa39-14ee-43e5-9079-aea71e971317.png",
														"url": "https://staging-media.toyota.com.ar/f448aa39-14ee-43e5-9079-aea71e971317.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 204620,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 6",
														"path": "ca66af7a-6b0f-49ee-9853-cceed715fb25.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca66af7a-6b0f-49ee-9853-cceed715fb25.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 7",
														"path": "4829abe5-816a-4330-99a2-ad472bd477a3.jpeg",
														"url": "https://staging-media.toyota.com.ar/4829abe5-816a-4330-99a2-ad472bd477a3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 1",
														"path": "53f38bde-770c-45db-9145-d8108871f77f.jpeg",
														"url": "https://staging-media.toyota.com.ar/53f38bde-770c-45db-9145-d8108871f77f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 2",
														"path": "00681ed7-f4d2-40e7-a494-da0c863dd651.jpeg",
														"url": "https://staging-media.toyota.com.ar/00681ed7-f4d2-40e7-a494-da0c863dd651.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv  3",
														"path": "434fa60a-abef-4a56-9ceb-77458becaa12.jpeg",
														"url": "https://staging-media.toyota.com.ar/434fa60a-abef-4a56-9ceb-77458becaa12.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 4",
														"path": "67b29a53-0570-4c85-a432-f8b0788ff8c5.jpeg",
														"url": "https://staging-media.toyota.com.ar/67b29a53-0570-4c85-a432-f8b0788ff8c5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "xrv 5",
														"path": "a89fa5fb-ed27-492a-865f-bb8c5f7ea5a8.jpeg",
														"url": "https://staging-media.toyota.com.ar/a89fa5fb-ed27-492a-865f-bb8c5f7ea5a8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 1",
														"path": "eb587a0b-d0db-4308-8818-df098be571f1.jpeg",
														"url": "https://staging-media.toyota.com.ar/eb587a0b-d0db-4308-8818-df098be571f1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 2",
														"path": "cc97b972-2a32-4a30-ba2c-3ef1bdcdf614.jpeg",
														"url": "https://staging-media.toyota.com.ar/cc97b972-2a32-4a30-ba2c-3ef1bdcdf614.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 3",
														"path": "9cba9f7d-e349-486f-b99e-a9cde8554b08.jpeg",
														"url": "https://staging-media.toyota.com.ar/9cba9f7d-e349-486f-b99e-a9cde8554b08.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 4",
														"path": "de4a7490-aa11-4b91-b5fb-7c690a3356f7.jpeg",
														"url": "https://staging-media.toyota.com.ar/de4a7490-aa11-4b91-b5fb-7c690a3356f7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 5",
														"path": "91f4f4bf-65f2-4882-829c-3b5751241c0c.jpeg",
														"url": "https://staging-media.toyota.com.ar/91f4f4bf-65f2-4882-829c-3b5751241c0c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv ",
														"path": "923b38be-365f-4981-8fd6-8ee76c2ec95c.jpeg",
														"url": "https://staging-media.toyota.com.ar/923b38be-365f-4981-8fd6-8ee76c2ec95c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 7",
														"path": "1edc9776-0288-44aa-b374-2a7ce42485b4.jpeg",
														"url": "https://staging-media.toyota.com.ar/1edc9776-0288-44aa-b374-2a7ce42485b4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "xrv 8",
														"path": "306b6ba5-e021-4c60-b5ef-9573b0eb54e0.jpeg",
														"url": "https://staging-media.toyota.com.ar/306b6ba5-e021-4c60-b5ef-9573b0eb54e0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Cross Desktop Banner",
														"path": "fc4a2131-3ed2-4f4e-b83a-a20f489a7c45.jpeg",
														"url": "https://staging-media.toyota.com.ar/fc4a2131-3ed2-4f4e-b83a-a20f489a7c45.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "3637d406-7db7-4201-85e3-289cf11c89af.png",
														"url": "https://staging-media.toyota.com.ar/3637d406-7db7-4201-85e3-289cf11c89af.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display normal",
														"path": "b65ebd97-45ff-474d-b294-599c16997859.png",
														"url": "https://staging-media.toyota.com.ar/b65ebd97-45ff-474d-b294-599c16997859.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display mouse hover",
														"path": "5639745e-91e9-480c-8b31-c4a3d8f8a49d.png",
														"url": "https://staging-media.toyota.com.ar/5639745e-91e9-480c-8b31-c4a3d8f8a49d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner mobile",
														"path": "c993af48-6270-4714-b4e6-f5564292517b.jpeg",
														"url": "https://staging-media.toyota.com.ar/c993af48-6270-4714-b4e6-f5564292517b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360",
														"path": "bff4fc72-4105-46d3-ac74-c70c11cab329.png",
														"url": "https://staging-media.toyota.com.ar/bff4fc72-4105-46d3-ac74-c70c11cab329.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 360mobile",
														"path": "b630477f-bb42-4fa1-a104-28f4afb0d8f7.png",
														"url": "https://staging-media.toyota.com.ar/b630477f-bb42-4fa1-a104-28f4afb0d8f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3r3 360",
														"path": "6f3833b8-09d2-436f-9bb6-fb044b34c992.png",
														"url": "https://staging-media.toyota.com.ar/6f3833b8-09d2-436f-9bb6-fb044b34c992.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3r3 mobile",
														"path": "f6395ed6-4bf1-4974-b4db-7a93d34c5ac1.png",
														"url": "https://staging-media.toyota.com.ar/f6395ed6-4bf1-4974-b4db-7a93d34c5ac1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1g3 360",
														"path": "648b8c84-5a5e-43de-b2d2-fe3012b86dd3.png",
														"url": "https://staging-media.toyota.com.ar/648b8c84-5a5e-43de-b2d2-fe3012b86dd3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1g3 mobile",
														"path": "aa4bbceb-e1c5-4bdd-99b8-5b4ec8b1fcef.png",
														"url": "https://staging-media.toyota.com.ar/aa4bbceb-e1c5-4bdd-99b8-5b4ec8b1fcef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 360",
														"path": "32671525-fd4f-4ef7-a876-dbf9c93fd24e.png",
														"url": "https://staging-media.toyota.com.ar/32671525-fd4f-4ef7-a876-dbf9c93fd24e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "2e3f07b9-afb2-4249-bacd-92b1824ec3b8.png",
														"url": "https://staging-media.toyota.com.ar/2e3f07b9-afb2-4249-bacd-92b1824ec3b8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1h6 360",
														"path": "8d14e9c3-61bb-4025-8cfb-5bac3fe3e76f.png",
														"url": "https://staging-media.toyota.com.ar/8d14e9c3-61bb-4025-8cfb-5bac3fe3e76f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile",
														"path": "f21c617f-2b8a-4b3a-b5af-c08ba4efe213.png",
														"url": "https://staging-media.toyota.com.ar/f21c617f-2b8a-4b3a-b5af-c08ba4efe213.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 360",
														"path": "a63d6ffb-aacf-4fc2-b53e-cfc0b52b29b0.png",
														"url": "https://staging-media.toyota.com.ar/a63d6ffb-aacf-4fc2-b53e-cfc0b52b29b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "f448aa39-14ee-43e5-9079-aea71e971317.png",
														"url": "https://staging-media.toyota.com.ar/f448aa39-14ee-43e5-9079-aea71e971317.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 199690,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "Display XRX desk",
														"path": "648f7c15-4436-4413-83f9-f659f11d58e3.png",
														"url": "https://staging-media.toyota.com.ar/648f7c15-4436-4413-83f9-f659f11d58e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display XRX mobile",
														"path": "f01872bb-a213-413e-b76b-359065df9d7a.png",
														"url": "https://staging-media.toyota.com.ar/f01872bb-a213-413e-b76b-359065df9d7a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360 desk",
														"path": "1af4536b-f666-4d6c-8d51-74a01ec44281.png",
														"url": "https://staging-media.toyota.com.ar/1af4536b-f666-4d6c-8d51-74a01ec44281.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 360 mobile",
														"path": "ff360ee8-8177-48bd-927b-eda89e4854a6.png",
														"url": "https://staging-media.toyota.com.ar/ff360ee8-8177-48bd-927b-eda89e4854a6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 360 desk",
														"path": "78b15e05-62b8-4b00-af27-5e179dce0696.png",
														"url": "https://staging-media.toyota.com.ar/78b15e05-62b8-4b00-af27-5e179dce0696.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 360 mobile",
														"path": "d53d75b3-6f62-47d4-aea0-fcafd7feaac0.png",
														"url": "https://staging-media.toyota.com.ar/d53d75b3-6f62-47d4-aea0-fcafd7feaac0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 360 desk",
														"path": "d1a68e04-97d0-40f0-be75-b5020642fa95.png",
														"url": "https://staging-media.toyota.com.ar/d1a68e04-97d0-40f0-be75-b5020642fa95.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 360 mobile",
														"path": "d0262126-ada6-480b-bdac-a157190a01f8.png",
														"url": "https://staging-media.toyota.com.ar/d0262126-ada6-480b-bdac-a157190a01f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 360 desk",
														"path": "f317e6c5-d313-45dd-a694-0ddf229625fd.png",
														"url": "https://staging-media.toyota.com.ar/f317e6c5-d313-45dd-a694-0ddf229625fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 360 mobile",
														"path": "a8dd496b-b2a4-46d5-9e74-91234fcb1813.png",
														"url": "https://staging-media.toyota.com.ar/a8dd496b-b2a4-46d5-9e74-91234fcb1813.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 360 desk",
														"path": "3e241799-5d17-4d9b-9185-4b9ac118a692.png",
														"url": "https://staging-media.toyota.com.ar/3e241799-5d17-4d9b-9185-4b9ac118a692.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 360 mobile",
														"path": "180bbe81-744e-4e82-beeb-b7eaecc81bc3.png",
														"url": "https://staging-media.toyota.com.ar/180bbe81-744e-4e82-beeb-b7eaecc81bc3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 360 desk",
														"path": "b1333806-80bc-427e-9be2-6fb90fd68ce0.png",
														"url": "https://staging-media.toyota.com.ar/b1333806-80bc-427e-9be2-6fb90fd68ce0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 360 mobile",
														"path": "cbeb837b-1a84-4d7b-8599-03edcc2d7886.png",
														"url": "https://staging-media.toyota.com.ar/cbeb837b-1a84-4d7b-8599-03edcc2d7886.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo - CC",
														"path": "da03222f-f87e-495d-84ce-595cbf05eb51.png",
														"url": "https://staging-media.toyota.com.br/da03222f-f87e-495d-84ce-595cbf05eb51.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner - Desk ",
														"path": "cbb1e412-2901-4910-a765-7f1e6c8c5886.jpeg",
														"url": "https://staging-media.toyota.com.br/cbb1e412-2901-4910-a765-7f1e6c8c5886.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner - Mobile ",
														"path": "2f6a980a-174a-4337-bc08-65b504f3a5bf.jpeg",
														"url": "https://staging-media.toyota.com.br/2f6a980a-174a-4337-bc08-65b504f3a5bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria interna - CC",
														"path": "b577f012-5eb1-443c-a39e-49e5c4da2f11.jpeg",
														"url": "https://staging-media.toyota.com.br/b577f012-5eb1-443c-a39e-49e5c4da2f11.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria interna - CC",
														"path": "0cb244cb-d384-4a8a-970d-cace382ea4fe.jpeg",
														"url": "https://staging-media.toyota.com.br/0cb244cb-d384-4a8a-970d-cace382ea4fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 201710,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "Display XRX desk",
														"path": "648f7c15-4436-4413-83f9-f659f11d58e3.png",
														"url": "https://staging-media.toyota.com.ar/648f7c15-4436-4413-83f9-f659f11d58e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display XRX mobile",
														"path": "f01872bb-a213-413e-b76b-359065df9d7a.png",
														"url": "https://staging-media.toyota.com.ar/f01872bb-a213-413e-b76b-359065df9d7a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360 desk",
														"path": "1af4536b-f666-4d6c-8d51-74a01ec44281.png",
														"url": "https://staging-media.toyota.com.ar/1af4536b-f666-4d6c-8d51-74a01ec44281.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 360 mobile",
														"path": "ff360ee8-8177-48bd-927b-eda89e4854a6.png",
														"url": "https://staging-media.toyota.com.ar/ff360ee8-8177-48bd-927b-eda89e4854a6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 360 desk",
														"path": "78b15e05-62b8-4b00-af27-5e179dce0696.png",
														"url": "https://staging-media.toyota.com.ar/78b15e05-62b8-4b00-af27-5e179dce0696.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 360 mobile",
														"path": "d53d75b3-6f62-47d4-aea0-fcafd7feaac0.png",
														"url": "https://staging-media.toyota.com.ar/d53d75b3-6f62-47d4-aea0-fcafd7feaac0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 360 desk",
														"path": "d1a68e04-97d0-40f0-be75-b5020642fa95.png",
														"url": "https://staging-media.toyota.com.ar/d1a68e04-97d0-40f0-be75-b5020642fa95.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 360 mobile",
														"path": "d0262126-ada6-480b-bdac-a157190a01f8.png",
														"url": "https://staging-media.toyota.com.ar/d0262126-ada6-480b-bdac-a157190a01f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 360 desk",
														"path": "f317e6c5-d313-45dd-a694-0ddf229625fd.png",
														"url": "https://staging-media.toyota.com.ar/f317e6c5-d313-45dd-a694-0ddf229625fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 360 mobile",
														"path": "a8dd496b-b2a4-46d5-9e74-91234fcb1813.png",
														"url": "https://staging-media.toyota.com.ar/a8dd496b-b2a4-46d5-9e74-91234fcb1813.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 360 desk",
														"path": "3e241799-5d17-4d9b-9185-4b9ac118a692.png",
														"url": "https://staging-media.toyota.com.ar/3e241799-5d17-4d9b-9185-4b9ac118a692.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 360 mobile",
														"path": "180bbe81-744e-4e82-beeb-b7eaecc81bc3.png",
														"url": "https://staging-media.toyota.com.ar/180bbe81-744e-4e82-beeb-b7eaecc81bc3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 360 desk",
														"path": "b1333806-80bc-427e-9be2-6fb90fd68ce0.png",
														"url": "https://staging-media.toyota.com.ar/b1333806-80bc-427e-9be2-6fb90fd68ce0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 360 mobile",
														"path": "cbeb837b-1a84-4d7b-8599-03edcc2d7886.png",
														"url": "https://staging-media.toyota.com.ar/cbeb837b-1a84-4d7b-8599-03edcc2d7886.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo - CC",
														"path": "da03222f-f87e-495d-84ce-595cbf05eb51.png",
														"url": "https://staging-media.toyota.com.br/da03222f-f87e-495d-84ce-595cbf05eb51.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner - Desk ",
														"path": "cbb1e412-2901-4910-a765-7f1e6c8c5886.jpeg",
														"url": "https://staging-media.toyota.com.br/cbb1e412-2901-4910-a765-7f1e6c8c5886.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner - Mobile ",
														"path": "2f6a980a-174a-4337-bc08-65b504f3a5bf.jpeg",
														"url": "https://staging-media.toyota.com.br/2f6a980a-174a-4337-bc08-65b504f3a5bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria interna - CC",
														"path": "b577f012-5eb1-443c-a39e-49e5c4da2f11.jpeg",
														"url": "https://staging-media.toyota.com.br/b577f012-5eb1-443c-a39e-49e5c4da2f11.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria interna - CC",
														"path": "0cb244cb-d384-4a8a-970d-cace382ea4fe.jpeg",
														"url": "https://staging-media.toyota.com.br/0cb244cb-d384-4a8a-970d-cace382ea4fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 201710,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "Display XRX desk",
														"path": "648f7c15-4436-4413-83f9-f659f11d58e3.png",
														"url": "https://staging-media.toyota.com.ar/648f7c15-4436-4413-83f9-f659f11d58e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display XRX mobile",
														"path": "f01872bb-a213-413e-b76b-359065df9d7a.png",
														"url": "https://staging-media.toyota.com.ar/f01872bb-a213-413e-b76b-359065df9d7a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360 desk",
														"path": "1af4536b-f666-4d6c-8d51-74a01ec44281.png",
														"url": "https://staging-media.toyota.com.ar/1af4536b-f666-4d6c-8d51-74a01ec44281.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 360 mobile",
														"path": "ff360ee8-8177-48bd-927b-eda89e4854a6.png",
														"url": "https://staging-media.toyota.com.ar/ff360ee8-8177-48bd-927b-eda89e4854a6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 360 desk",
														"path": "78b15e05-62b8-4b00-af27-5e179dce0696.png",
														"url": "https://staging-media.toyota.com.ar/78b15e05-62b8-4b00-af27-5e179dce0696.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 360 mobile",
														"path": "d53d75b3-6f62-47d4-aea0-fcafd7feaac0.png",
														"url": "https://staging-media.toyota.com.ar/d53d75b3-6f62-47d4-aea0-fcafd7feaac0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 360 desk",
														"path": "d1a68e04-97d0-40f0-be75-b5020642fa95.png",
														"url": "https://staging-media.toyota.com.ar/d1a68e04-97d0-40f0-be75-b5020642fa95.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 360 mobile",
														"path": "d0262126-ada6-480b-bdac-a157190a01f8.png",
														"url": "https://staging-media.toyota.com.ar/d0262126-ada6-480b-bdac-a157190a01f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 360 desk",
														"path": "f317e6c5-d313-45dd-a694-0ddf229625fd.png",
														"url": "https://staging-media.toyota.com.ar/f317e6c5-d313-45dd-a694-0ddf229625fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 360 mobile",
														"path": "a8dd496b-b2a4-46d5-9e74-91234fcb1813.png",
														"url": "https://staging-media.toyota.com.ar/a8dd496b-b2a4-46d5-9e74-91234fcb1813.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 360 desk",
														"path": "3e241799-5d17-4d9b-9185-4b9ac118a692.png",
														"url": "https://staging-media.toyota.com.ar/3e241799-5d17-4d9b-9185-4b9ac118a692.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 360 mobile",
														"path": "180bbe81-744e-4e82-beeb-b7eaecc81bc3.png",
														"url": "https://staging-media.toyota.com.ar/180bbe81-744e-4e82-beeb-b7eaecc81bc3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 360 desk",
														"path": "b1333806-80bc-427e-9be2-6fb90fd68ce0.png",
														"url": "https://staging-media.toyota.com.ar/b1333806-80bc-427e-9be2-6fb90fd68ce0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 360 mobile",
														"path": "cbeb837b-1a84-4d7b-8599-03edcc2d7886.png",
														"url": "https://staging-media.toyota.com.ar/cbeb837b-1a84-4d7b-8599-03edcc2d7886.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo - CC",
														"path": "da03222f-f87e-495d-84ce-595cbf05eb51.png",
														"url": "https://staging-media.toyota.com.br/da03222f-f87e-495d-84ce-595cbf05eb51.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner - Desk ",
														"path": "cbb1e412-2901-4910-a765-7f1e6c8c5886.jpeg",
														"url": "https://staging-media.toyota.com.br/cbb1e412-2901-4910-a765-7f1e6c8c5886.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner - Mobile ",
														"path": "2f6a980a-174a-4337-bc08-65b504f3a5bf.jpeg",
														"url": "https://staging-media.toyota.com.br/2f6a980a-174a-4337-bc08-65b504f3a5bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria interna - CC",
														"path": "b577f012-5eb1-443c-a39e-49e5c4da2f11.jpeg",
														"url": "https://staging-media.toyota.com.br/b577f012-5eb1-443c-a39e-49e5c4da2f11.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria interna - CC",
														"path": "0cb244cb-d384-4a8a-970d-cace382ea4fe.jpeg",
														"url": "https://staging-media.toyota.com.br/0cb244cb-d384-4a8a-970d-cace382ea4fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 201710,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "Display XRX desk",
														"path": "648f7c15-4436-4413-83f9-f659f11d58e3.png",
														"url": "https://staging-media.toyota.com.ar/648f7c15-4436-4413-83f9-f659f11d58e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display XRX mobile",
														"path": "f01872bb-a213-413e-b76b-359065df9d7a.png",
														"url": "https://staging-media.toyota.com.ar/f01872bb-a213-413e-b76b-359065df9d7a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360 desk",
														"path": "1af4536b-f666-4d6c-8d51-74a01ec44281.png",
														"url": "https://staging-media.toyota.com.ar/1af4536b-f666-4d6c-8d51-74a01ec44281.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 360 mobile",
														"path": "ff360ee8-8177-48bd-927b-eda89e4854a6.png",
														"url": "https://staging-media.toyota.com.ar/ff360ee8-8177-48bd-927b-eda89e4854a6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 360 desk",
														"path": "78b15e05-62b8-4b00-af27-5e179dce0696.png",
														"url": "https://staging-media.toyota.com.ar/78b15e05-62b8-4b00-af27-5e179dce0696.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 360 mobile",
														"path": "d53d75b3-6f62-47d4-aea0-fcafd7feaac0.png",
														"url": "https://staging-media.toyota.com.ar/d53d75b3-6f62-47d4-aea0-fcafd7feaac0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 360 desk",
														"path": "d1a68e04-97d0-40f0-be75-b5020642fa95.png",
														"url": "https://staging-media.toyota.com.ar/d1a68e04-97d0-40f0-be75-b5020642fa95.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 360 mobile",
														"path": "d0262126-ada6-480b-bdac-a157190a01f8.png",
														"url": "https://staging-media.toyota.com.ar/d0262126-ada6-480b-bdac-a157190a01f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 360 desk",
														"path": "f317e6c5-d313-45dd-a694-0ddf229625fd.png",
														"url": "https://staging-media.toyota.com.ar/f317e6c5-d313-45dd-a694-0ddf229625fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 360 mobile",
														"path": "a8dd496b-b2a4-46d5-9e74-91234fcb1813.png",
														"url": "https://staging-media.toyota.com.ar/a8dd496b-b2a4-46d5-9e74-91234fcb1813.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 360 desk",
														"path": "3e241799-5d17-4d9b-9185-4b9ac118a692.png",
														"url": "https://staging-media.toyota.com.ar/3e241799-5d17-4d9b-9185-4b9ac118a692.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 360 mobile",
														"path": "180bbe81-744e-4e82-beeb-b7eaecc81bc3.png",
														"url": "https://staging-media.toyota.com.ar/180bbe81-744e-4e82-beeb-b7eaecc81bc3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 360 desk",
														"path": "b1333806-80bc-427e-9be2-6fb90fd68ce0.png",
														"url": "https://staging-media.toyota.com.ar/b1333806-80bc-427e-9be2-6fb90fd68ce0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 360 mobile",
														"path": "cbeb837b-1a84-4d7b-8599-03edcc2d7886.png",
														"url": "https://staging-media.toyota.com.ar/cbeb837b-1a84-4d7b-8599-03edcc2d7886.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo - CC",
														"path": "da03222f-f87e-495d-84ce-595cbf05eb51.png",
														"url": "https://staging-media.toyota.com.br/da03222f-f87e-495d-84ce-595cbf05eb51.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner - Desk ",
														"path": "cbb1e412-2901-4910-a765-7f1e6c8c5886.jpeg",
														"url": "https://staging-media.toyota.com.br/cbb1e412-2901-4910-a765-7f1e6c8c5886.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner - Mobile ",
														"path": "2f6a980a-174a-4337-bc08-65b504f3a5bf.jpeg",
														"url": "https://staging-media.toyota.com.br/2f6a980a-174a-4337-bc08-65b504f3a5bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria interna - CC",
														"path": "b577f012-5eb1-443c-a39e-49e5c4da2f11.jpeg",
														"url": "https://staging-media.toyota.com.br/b577f012-5eb1-443c-a39e-49e5c4da2f11.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria interna - CC",
														"path": "0cb244cb-d384-4a8a-970d-cace382ea4fe.jpeg",
														"url": "https://staging-media.toyota.com.br/0cb244cb-d384-4a8a-970d-cace382ea4fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 201710,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "Display XRX desk",
														"path": "648f7c15-4436-4413-83f9-f659f11d58e3.png",
														"url": "https://staging-media.toyota.com.ar/648f7c15-4436-4413-83f9-f659f11d58e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display XRX mobile",
														"path": "f01872bb-a213-413e-b76b-359065df9d7a.png",
														"url": "https://staging-media.toyota.com.ar/f01872bb-a213-413e-b76b-359065df9d7a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360 desk",
														"path": "1af4536b-f666-4d6c-8d51-74a01ec44281.png",
														"url": "https://staging-media.toyota.com.ar/1af4536b-f666-4d6c-8d51-74a01ec44281.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 360 mobile",
														"path": "ff360ee8-8177-48bd-927b-eda89e4854a6.png",
														"url": "https://staging-media.toyota.com.ar/ff360ee8-8177-48bd-927b-eda89e4854a6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 360 desk",
														"path": "78b15e05-62b8-4b00-af27-5e179dce0696.png",
														"url": "https://staging-media.toyota.com.ar/78b15e05-62b8-4b00-af27-5e179dce0696.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 360 mobile",
														"path": "d53d75b3-6f62-47d4-aea0-fcafd7feaac0.png",
														"url": "https://staging-media.toyota.com.ar/d53d75b3-6f62-47d4-aea0-fcafd7feaac0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 360 desk",
														"path": "d1a68e04-97d0-40f0-be75-b5020642fa95.png",
														"url": "https://staging-media.toyota.com.ar/d1a68e04-97d0-40f0-be75-b5020642fa95.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 360 mobile",
														"path": "d0262126-ada6-480b-bdac-a157190a01f8.png",
														"url": "https://staging-media.toyota.com.ar/d0262126-ada6-480b-bdac-a157190a01f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 360 desk",
														"path": "f317e6c5-d313-45dd-a694-0ddf229625fd.png",
														"url": "https://staging-media.toyota.com.ar/f317e6c5-d313-45dd-a694-0ddf229625fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 360 mobile",
														"path": "a8dd496b-b2a4-46d5-9e74-91234fcb1813.png",
														"url": "https://staging-media.toyota.com.ar/a8dd496b-b2a4-46d5-9e74-91234fcb1813.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 360 desk",
														"path": "3e241799-5d17-4d9b-9185-4b9ac118a692.png",
														"url": "https://staging-media.toyota.com.ar/3e241799-5d17-4d9b-9185-4b9ac118a692.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 360 mobile",
														"path": "180bbe81-744e-4e82-beeb-b7eaecc81bc3.png",
														"url": "https://staging-media.toyota.com.ar/180bbe81-744e-4e82-beeb-b7eaecc81bc3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 360 desk",
														"path": "b1333806-80bc-427e-9be2-6fb90fd68ce0.png",
														"url": "https://staging-media.toyota.com.ar/b1333806-80bc-427e-9be2-6fb90fd68ce0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 360 mobile",
														"path": "cbeb837b-1a84-4d7b-8599-03edcc2d7886.png",
														"url": "https://staging-media.toyota.com.ar/cbeb837b-1a84-4d7b-8599-03edcc2d7886.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo - CC",
														"path": "da03222f-f87e-495d-84ce-595cbf05eb51.png",
														"url": "https://staging-media.toyota.com.br/da03222f-f87e-495d-84ce-595cbf05eb51.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner - Desk ",
														"path": "cbb1e412-2901-4910-a765-7f1e6c8c5886.jpeg",
														"url": "https://staging-media.toyota.com.br/cbb1e412-2901-4910-a765-7f1e6c8c5886.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner - Mobile ",
														"path": "2f6a980a-174a-4337-bc08-65b504f3a5bf.jpeg",
														"url": "https://staging-media.toyota.com.br/2f6a980a-174a-4337-bc08-65b504f3a5bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria interna - CC",
														"path": "b577f012-5eb1-443c-a39e-49e5c4da2f11.jpeg",
														"url": "https://staging-media.toyota.com.br/b577f012-5eb1-443c-a39e-49e5c4da2f11.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria interna - CC",
														"path": "0cb244cb-d384-4a8a-970d-cace382ea4fe.jpeg",
														"url": "https://staging-media.toyota.com.br/0cb244cb-d384-4a8a-970d-cace382ea4fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 202020,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:regular",
													"media": {
														"title": "Display XRX desk",
														"path": "648f7c15-4436-4413-83f9-f659f11d58e3.png",
														"url": "https://staging-media.toyota.com.ar/648f7c15-4436-4413-83f9-f659f11d58e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display XRX mobile",
														"path": "f01872bb-a213-413e-b76b-359065df9d7a.png",
														"url": "https://staging-media.toyota.com.ar/f01872bb-a213-413e-b76b-359065df9d7a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 360 desk",
														"path": "1af4536b-f666-4d6c-8d51-74a01ec44281.png",
														"url": "https://staging-media.toyota.com.ar/1af4536b-f666-4d6c-8d51-74a01ec44281.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 360 mobile",
														"path": "ff360ee8-8177-48bd-927b-eda89e4854a6.png",
														"url": "https://staging-media.toyota.com.ar/ff360ee8-8177-48bd-927b-eda89e4854a6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R3 360 desk",
														"path": "78b15e05-62b8-4b00-af27-5e179dce0696.png",
														"url": "https://staging-media.toyota.com.ar/78b15e05-62b8-4b00-af27-5e179dce0696.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 360 mobile",
														"path": "d53d75b3-6f62-47d4-aea0-fcafd7feaac0.png",
														"url": "https://staging-media.toyota.com.ar/d53d75b3-6f62-47d4-aea0-fcafd7feaac0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 360 desk",
														"path": "d1a68e04-97d0-40f0-be75-b5020642fa95.png",
														"url": "https://staging-media.toyota.com.ar/d1a68e04-97d0-40f0-be75-b5020642fa95.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 360 mobile",
														"path": "d0262126-ada6-480b-bdac-a157190a01f8.png",
														"url": "https://staging-media.toyota.com.ar/d0262126-ada6-480b-bdac-a157190a01f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 360 desk",
														"path": "f317e6c5-d313-45dd-a694-0ddf229625fd.png",
														"url": "https://staging-media.toyota.com.ar/f317e6c5-d313-45dd-a694-0ddf229625fd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 360 mobile",
														"path": "a8dd496b-b2a4-46d5-9e74-91234fcb1813.png",
														"url": "https://staging-media.toyota.com.ar/a8dd496b-b2a4-46d5-9e74-91234fcb1813.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 360 desk",
														"path": "3e241799-5d17-4d9b-9185-4b9ac118a692.png",
														"url": "https://staging-media.toyota.com.ar/3e241799-5d17-4d9b-9185-4b9ac118a692.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 360 mobile",
														"path": "180bbe81-744e-4e82-beeb-b7eaecc81bc3.png",
														"url": "https://staging-media.toyota.com.ar/180bbe81-744e-4e82-beeb-b7eaecc81bc3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 360 desk",
														"path": "b1333806-80bc-427e-9be2-6fb90fd68ce0.png",
														"url": "https://staging-media.toyota.com.ar/b1333806-80bc-427e-9be2-6fb90fd68ce0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 360 mobile",
														"path": "cbeb837b-1a84-4d7b-8599-03edcc2d7886.png",
														"url": "https://staging-media.toyota.com.ar/cbeb837b-1a84-4d7b-8599-03edcc2d7886.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo - CC",
														"path": "da03222f-f87e-495d-84ce-595cbf05eb51.png",
														"url": "https://staging-media.toyota.com.br/da03222f-f87e-495d-84ce-595cbf05eb51.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner - Desk ",
														"path": "cbb1e412-2901-4910-a765-7f1e6c8c5886.jpeg",
														"url": "https://staging-media.toyota.com.br/cbb1e412-2901-4910-a765-7f1e6c8c5886.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Banner - Mobile ",
														"path": "2f6a980a-174a-4337-bc08-65b504f3a5bf.jpeg",
														"url": "https://staging-media.toyota.com.br/2f6a980a-174a-4337-bc08-65b504f3a5bf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria interna - CC",
														"path": "b577f012-5eb1-443c-a39e-49e5c4da2f11.jpeg",
														"url": "https://staging-media.toyota.com.br/b577f012-5eb1-443c-a39e-49e5c4da2f11.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria interna - CC",
														"path": "0cb244cb-d384-4a8a-970d-cace382ea4fe.jpeg",
														"url": "https://staging-media.toyota.com.br/0cb244cb-d384-4a8a-970d-cace382ea4fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "XRX Hybrid",
						"id": "53ddd2be-78bf-4aa0-97a2-adda5b75df83",
						"order": 5,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 210490,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner Desktop",
														"path": "54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"url": "https://staging-media.toyota.com.ar/54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Croos Banner Mobile",
														"path": "b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"url": "https://staging-media.toyota.com.ar/b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "croos desktop",
														"path": "f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"url": "https://staging-media.toyota.com.ar/f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "cross mobile",
														"path": "e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"url": "https://staging-media.toyota.com.ar/e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "360 cross dsk",
														"path": "ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"url": "https://staging-media.toyota.com.ar/ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "cross mobile ",
														"path": "08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"url": "https://staging-media.toyota.com.ar/08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "cross dsk 1g3",
														"path": "8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"url": "https://staging-media.toyota.com.ar/8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "cross mobile 1g3",
														"path": "19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"url": "https://staging-media.toyota.com.ar/19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "cross 215 dks",
														"path": "b83e1866-b37b-4443-864a-61b54aa093de.png",
														"url": "https://staging-media.toyota.com.ar/b83e1866-b37b-4443-864a-61b54aa093de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "cross 215 mobile",
														"path": "de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"url": "https://staging-media.toyota.com.ar/de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "cross 1h6",
														"path": "aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"url": "https://staging-media.toyota.com.ar/aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile cross",
														"path": "4c1044fa-1499-4216-bafe-61059aa40994.png",
														"url": "https://staging-media.toyota.com.ar/4c1044fa-1499-4216-bafe-61059aa40994.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "cross dsk 089",
														"path": "8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"url": "https://staging-media.toyota.com.ar/8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "cross mobile 089",
														"path": "44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"url": "https://staging-media.toyota.com.ar/44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "cross dsk 8x2",
														"path": "b8106226-227c-4af7-b42a-7a38100e9346.png",
														"url": "https://staging-media.toyota.com.ar/b8106226-227c-4af7-b42a-7a38100e9346.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "cross mobile 8x2",
														"path": "7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"url": "https://staging-media.toyota.com.ar/7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-1",
														"path": "921d6cf0-8684-4287-8375-8ea84b528919.png",
														"url": "https://staging-media.toyota.com.ar/921d6cf0-8684-4287-8375-8ea84b528919.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-2",
														"path": "09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"url": "https://staging-media.toyota.com.ar/09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-3",
														"path": "d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"url": "https://staging-media.toyota.com.ar/d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-4",
														"path": "614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"url": "https://staging-media.toyota.com.ar/614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-5",
														"path": "dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"url": "https://staging-media.toyota.com.ar/dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-6",
														"path": "09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"url": "https://staging-media.toyota.com.ar/09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-7",
														"path": "de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"url": "https://staging-media.toyota.com.ar/de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-8",
														"path": "26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"url": "https://staging-media.toyota.com.ar/26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-1",
														"path": "86bb167d-d57d-4248-9e58-21f7135def79.png",
														"url": "https://staging-media.toyota.com.ar/86bb167d-d57d-4248-9e58-21f7135def79.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-2",
														"path": "ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"url": "https://staging-media.toyota.com.ar/ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-3",
														"path": "cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"url": "https://staging-media.toyota.com.ar/cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-4",
														"path": "28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"url": "https://staging-media.toyota.com.ar/28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-5",
														"path": "6a720cad-3466-4b36-970f-46f23e74da78.png",
														"url": "https://staging-media.toyota.com.ar/6a720cad-3466-4b36-970f-46f23e74da78.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-6",
														"path": "cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"url": "https://staging-media.toyota.com.ar/cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-7",
														"path": "2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"url": "https://staging-media.toyota.com.ar/2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-8",
														"path": "c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"url": "https://staging-media.toyota.com.ar/c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"url": "https://staging-media.toyota.com.ar/e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display Corolla Cross lateral",
														"path": "69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"url": "https://staging-media.toyota.com.ar/69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display Corolla Cross angulado",
														"path": "d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"url": "https://staging-media.toyota.com.ar/d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 212510,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner Desktop",
														"path": "54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"url": "https://staging-media.toyota.com.ar/54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Croos Banner Mobile",
														"path": "b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"url": "https://staging-media.toyota.com.ar/b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "croos desktop",
														"path": "f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"url": "https://staging-media.toyota.com.ar/f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "cross mobile",
														"path": "e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"url": "https://staging-media.toyota.com.ar/e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "360 cross dsk",
														"path": "ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"url": "https://staging-media.toyota.com.ar/ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "cross mobile ",
														"path": "08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"url": "https://staging-media.toyota.com.ar/08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "cross dsk 1g3",
														"path": "8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"url": "https://staging-media.toyota.com.ar/8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "cross mobile 1g3",
														"path": "19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"url": "https://staging-media.toyota.com.ar/19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "cross 215 dks",
														"path": "b83e1866-b37b-4443-864a-61b54aa093de.png",
														"url": "https://staging-media.toyota.com.ar/b83e1866-b37b-4443-864a-61b54aa093de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "cross 215 mobile",
														"path": "de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"url": "https://staging-media.toyota.com.ar/de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "cross 1h6",
														"path": "aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"url": "https://staging-media.toyota.com.ar/aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile cross",
														"path": "4c1044fa-1499-4216-bafe-61059aa40994.png",
														"url": "https://staging-media.toyota.com.ar/4c1044fa-1499-4216-bafe-61059aa40994.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "cross dsk 089",
														"path": "8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"url": "https://staging-media.toyota.com.ar/8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "cross mobile 089",
														"path": "44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"url": "https://staging-media.toyota.com.ar/44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "cross dsk 8x2",
														"path": "b8106226-227c-4af7-b42a-7a38100e9346.png",
														"url": "https://staging-media.toyota.com.ar/b8106226-227c-4af7-b42a-7a38100e9346.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "cross mobile 8x2",
														"path": "7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"url": "https://staging-media.toyota.com.ar/7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-1",
														"path": "921d6cf0-8684-4287-8375-8ea84b528919.png",
														"url": "https://staging-media.toyota.com.ar/921d6cf0-8684-4287-8375-8ea84b528919.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-2",
														"path": "09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"url": "https://staging-media.toyota.com.ar/09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-3",
														"path": "d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"url": "https://staging-media.toyota.com.ar/d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-4",
														"path": "614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"url": "https://staging-media.toyota.com.ar/614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-5",
														"path": "dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"url": "https://staging-media.toyota.com.ar/dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-6",
														"path": "09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"url": "https://staging-media.toyota.com.ar/09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-7",
														"path": "de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"url": "https://staging-media.toyota.com.ar/de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-8",
														"path": "26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"url": "https://staging-media.toyota.com.ar/26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-1",
														"path": "86bb167d-d57d-4248-9e58-21f7135def79.png",
														"url": "https://staging-media.toyota.com.ar/86bb167d-d57d-4248-9e58-21f7135def79.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-2",
														"path": "ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"url": "https://staging-media.toyota.com.ar/ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-3",
														"path": "cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"url": "https://staging-media.toyota.com.ar/cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-4",
														"path": "28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"url": "https://staging-media.toyota.com.ar/28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-5",
														"path": "6a720cad-3466-4b36-970f-46f23e74da78.png",
														"url": "https://staging-media.toyota.com.ar/6a720cad-3466-4b36-970f-46f23e74da78.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-6",
														"path": "cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"url": "https://staging-media.toyota.com.ar/cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-7",
														"path": "2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"url": "https://staging-media.toyota.com.ar/2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-8",
														"path": "c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"url": "https://staging-media.toyota.com.ar/c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"url": "https://staging-media.toyota.com.ar/e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display Corolla Cross lateral",
														"path": "69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"url": "https://staging-media.toyota.com.ar/69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display Corolla Cross angulado",
														"path": "d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"url": "https://staging-media.toyota.com.ar/d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 212510,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner Desktop",
														"path": "54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"url": "https://staging-media.toyota.com.ar/54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Croos Banner Mobile",
														"path": "b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"url": "https://staging-media.toyota.com.ar/b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "croos desktop",
														"path": "f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"url": "https://staging-media.toyota.com.ar/f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "cross mobile",
														"path": "e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"url": "https://staging-media.toyota.com.ar/e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "360 cross dsk",
														"path": "ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"url": "https://staging-media.toyota.com.ar/ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "cross mobile ",
														"path": "08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"url": "https://staging-media.toyota.com.ar/08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "cross dsk 1g3",
														"path": "8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"url": "https://staging-media.toyota.com.ar/8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "cross mobile 1g3",
														"path": "19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"url": "https://staging-media.toyota.com.ar/19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "cross 215 dks",
														"path": "b83e1866-b37b-4443-864a-61b54aa093de.png",
														"url": "https://staging-media.toyota.com.ar/b83e1866-b37b-4443-864a-61b54aa093de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "cross 215 mobile",
														"path": "de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"url": "https://staging-media.toyota.com.ar/de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "cross 1h6",
														"path": "aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"url": "https://staging-media.toyota.com.ar/aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile cross",
														"path": "4c1044fa-1499-4216-bafe-61059aa40994.png",
														"url": "https://staging-media.toyota.com.ar/4c1044fa-1499-4216-bafe-61059aa40994.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "cross dsk 089",
														"path": "8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"url": "https://staging-media.toyota.com.ar/8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "cross mobile 089",
														"path": "44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"url": "https://staging-media.toyota.com.ar/44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "cross dsk 8x2",
														"path": "b8106226-227c-4af7-b42a-7a38100e9346.png",
														"url": "https://staging-media.toyota.com.ar/b8106226-227c-4af7-b42a-7a38100e9346.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "cross mobile 8x2",
														"path": "7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"url": "https://staging-media.toyota.com.ar/7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-1",
														"path": "921d6cf0-8684-4287-8375-8ea84b528919.png",
														"url": "https://staging-media.toyota.com.ar/921d6cf0-8684-4287-8375-8ea84b528919.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-2",
														"path": "09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"url": "https://staging-media.toyota.com.ar/09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-3",
														"path": "d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"url": "https://staging-media.toyota.com.ar/d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-4",
														"path": "614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"url": "https://staging-media.toyota.com.ar/614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-5",
														"path": "dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"url": "https://staging-media.toyota.com.ar/dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-6",
														"path": "09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"url": "https://staging-media.toyota.com.ar/09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-7",
														"path": "de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"url": "https://staging-media.toyota.com.ar/de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-8",
														"path": "26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"url": "https://staging-media.toyota.com.ar/26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-1",
														"path": "86bb167d-d57d-4248-9e58-21f7135def79.png",
														"url": "https://staging-media.toyota.com.ar/86bb167d-d57d-4248-9e58-21f7135def79.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-2",
														"path": "ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"url": "https://staging-media.toyota.com.ar/ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-3",
														"path": "cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"url": "https://staging-media.toyota.com.ar/cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-4",
														"path": "28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"url": "https://staging-media.toyota.com.ar/28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-5",
														"path": "6a720cad-3466-4b36-970f-46f23e74da78.png",
														"url": "https://staging-media.toyota.com.ar/6a720cad-3466-4b36-970f-46f23e74da78.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-6",
														"path": "cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"url": "https://staging-media.toyota.com.ar/cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-7",
														"path": "2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"url": "https://staging-media.toyota.com.ar/2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-8",
														"path": "c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"url": "https://staging-media.toyota.com.ar/c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"url": "https://staging-media.toyota.com.ar/e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display Corolla Cross lateral",
														"path": "69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"url": "https://staging-media.toyota.com.ar/69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display Corolla Cross angulado",
														"path": "d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"url": "https://staging-media.toyota.com.ar/d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 212510,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner Desktop",
														"path": "54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"url": "https://staging-media.toyota.com.ar/54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Croos Banner Mobile",
														"path": "b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"url": "https://staging-media.toyota.com.ar/b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "croos desktop",
														"path": "f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"url": "https://staging-media.toyota.com.ar/f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "cross mobile",
														"path": "e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"url": "https://staging-media.toyota.com.ar/e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "360 cross dsk",
														"path": "ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"url": "https://staging-media.toyota.com.ar/ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "cross mobile ",
														"path": "08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"url": "https://staging-media.toyota.com.ar/08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "cross dsk 1g3",
														"path": "8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"url": "https://staging-media.toyota.com.ar/8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "cross mobile 1g3",
														"path": "19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"url": "https://staging-media.toyota.com.ar/19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "cross 215 dks",
														"path": "b83e1866-b37b-4443-864a-61b54aa093de.png",
														"url": "https://staging-media.toyota.com.ar/b83e1866-b37b-4443-864a-61b54aa093de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "cross 215 mobile",
														"path": "de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"url": "https://staging-media.toyota.com.ar/de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "cross 1h6",
														"path": "aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"url": "https://staging-media.toyota.com.ar/aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile cross",
														"path": "4c1044fa-1499-4216-bafe-61059aa40994.png",
														"url": "https://staging-media.toyota.com.ar/4c1044fa-1499-4216-bafe-61059aa40994.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "cross dsk 089",
														"path": "8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"url": "https://staging-media.toyota.com.ar/8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "cross mobile 089",
														"path": "44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"url": "https://staging-media.toyota.com.ar/44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "cross dsk 8x2",
														"path": "b8106226-227c-4af7-b42a-7a38100e9346.png",
														"url": "https://staging-media.toyota.com.ar/b8106226-227c-4af7-b42a-7a38100e9346.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "cross mobile 8x2",
														"path": "7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"url": "https://staging-media.toyota.com.ar/7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-1",
														"path": "921d6cf0-8684-4287-8375-8ea84b528919.png",
														"url": "https://staging-media.toyota.com.ar/921d6cf0-8684-4287-8375-8ea84b528919.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-2",
														"path": "09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"url": "https://staging-media.toyota.com.ar/09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-3",
														"path": "d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"url": "https://staging-media.toyota.com.ar/d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-4",
														"path": "614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"url": "https://staging-media.toyota.com.ar/614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-5",
														"path": "dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"url": "https://staging-media.toyota.com.ar/dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-6",
														"path": "09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"url": "https://staging-media.toyota.com.ar/09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-7",
														"path": "de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"url": "https://staging-media.toyota.com.ar/de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-8",
														"path": "26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"url": "https://staging-media.toyota.com.ar/26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-1",
														"path": "86bb167d-d57d-4248-9e58-21f7135def79.png",
														"url": "https://staging-media.toyota.com.ar/86bb167d-d57d-4248-9e58-21f7135def79.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-2",
														"path": "ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"url": "https://staging-media.toyota.com.ar/ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-3",
														"path": "cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"url": "https://staging-media.toyota.com.ar/cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-4",
														"path": "28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"url": "https://staging-media.toyota.com.ar/28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-5",
														"path": "6a720cad-3466-4b36-970f-46f23e74da78.png",
														"url": "https://staging-media.toyota.com.ar/6a720cad-3466-4b36-970f-46f23e74da78.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-6",
														"path": "cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"url": "https://staging-media.toyota.com.ar/cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-7",
														"path": "2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"url": "https://staging-media.toyota.com.ar/2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-8",
														"path": "c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"url": "https://staging-media.toyota.com.ar/c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"url": "https://staging-media.toyota.com.ar/e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display Corolla Cross lateral",
														"path": "69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"url": "https://staging-media.toyota.com.ar/69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display Corolla Cross angulado",
														"path": "d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"url": "https://staging-media.toyota.com.ar/d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 212510,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner Desktop",
														"path": "54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"url": "https://staging-media.toyota.com.ar/54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Croos Banner Mobile",
														"path": "b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"url": "https://staging-media.toyota.com.ar/b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "croos desktop",
														"path": "f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"url": "https://staging-media.toyota.com.ar/f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "cross mobile",
														"path": "e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"url": "https://staging-media.toyota.com.ar/e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "360 cross dsk",
														"path": "ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"url": "https://staging-media.toyota.com.ar/ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "cross mobile ",
														"path": "08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"url": "https://staging-media.toyota.com.ar/08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "cross dsk 1g3",
														"path": "8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"url": "https://staging-media.toyota.com.ar/8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "cross mobile 1g3",
														"path": "19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"url": "https://staging-media.toyota.com.ar/19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "cross 215 dks",
														"path": "b83e1866-b37b-4443-864a-61b54aa093de.png",
														"url": "https://staging-media.toyota.com.ar/b83e1866-b37b-4443-864a-61b54aa093de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "cross 215 mobile",
														"path": "de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"url": "https://staging-media.toyota.com.ar/de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "cross 1h6",
														"path": "aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"url": "https://staging-media.toyota.com.ar/aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile cross",
														"path": "4c1044fa-1499-4216-bafe-61059aa40994.png",
														"url": "https://staging-media.toyota.com.ar/4c1044fa-1499-4216-bafe-61059aa40994.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "cross dsk 089",
														"path": "8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"url": "https://staging-media.toyota.com.ar/8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "cross mobile 089",
														"path": "44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"url": "https://staging-media.toyota.com.ar/44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "cross dsk 8x2",
														"path": "b8106226-227c-4af7-b42a-7a38100e9346.png",
														"url": "https://staging-media.toyota.com.ar/b8106226-227c-4af7-b42a-7a38100e9346.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "cross mobile 8x2",
														"path": "7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"url": "https://staging-media.toyota.com.ar/7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-1",
														"path": "921d6cf0-8684-4287-8375-8ea84b528919.png",
														"url": "https://staging-media.toyota.com.ar/921d6cf0-8684-4287-8375-8ea84b528919.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-2",
														"path": "09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"url": "https://staging-media.toyota.com.ar/09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-3",
														"path": "d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"url": "https://staging-media.toyota.com.ar/d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-4",
														"path": "614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"url": "https://staging-media.toyota.com.ar/614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-5",
														"path": "dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"url": "https://staging-media.toyota.com.ar/dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-6",
														"path": "09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"url": "https://staging-media.toyota.com.ar/09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-7",
														"path": "de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"url": "https://staging-media.toyota.com.ar/de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-8",
														"path": "26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"url": "https://staging-media.toyota.com.ar/26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-1",
														"path": "86bb167d-d57d-4248-9e58-21f7135def79.png",
														"url": "https://staging-media.toyota.com.ar/86bb167d-d57d-4248-9e58-21f7135def79.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-2",
														"path": "ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"url": "https://staging-media.toyota.com.ar/ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-3",
														"path": "cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"url": "https://staging-media.toyota.com.ar/cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-4",
														"path": "28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"url": "https://staging-media.toyota.com.ar/28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-5",
														"path": "6a720cad-3466-4b36-970f-46f23e74da78.png",
														"url": "https://staging-media.toyota.com.ar/6a720cad-3466-4b36-970f-46f23e74da78.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-6",
														"path": "cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"url": "https://staging-media.toyota.com.ar/cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-7",
														"path": "2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"url": "https://staging-media.toyota.com.ar/2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-8",
														"path": "c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"url": "https://staging-media.toyota.com.ar/c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"url": "https://staging-media.toyota.com.ar/e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display Corolla Cross lateral",
														"path": "69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"url": "https://staging-media.toyota.com.ar/69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display Corolla Cross angulado",
														"path": "d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"url": "https://staging-media.toyota.com.ar/d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 212510,
										"offers": null,
										"color": {
											"name": "Azul Netuno (8X2)",
											"code": "8X2",
											"rgb": "#16296D"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner Desktop",
														"path": "54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"url": "https://staging-media.toyota.com.ar/54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Croos Banner Mobile",
														"path": "b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"url": "https://staging-media.toyota.com.ar/b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "croos desktop",
														"path": "f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"url": "https://staging-media.toyota.com.ar/f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "cross mobile",
														"path": "e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"url": "https://staging-media.toyota.com.ar/e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "360 cross dsk",
														"path": "ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"url": "https://staging-media.toyota.com.ar/ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "cross mobile ",
														"path": "08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"url": "https://staging-media.toyota.com.ar/08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "cross dsk 1g3",
														"path": "8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"url": "https://staging-media.toyota.com.ar/8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "cross mobile 1g3",
														"path": "19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"url": "https://staging-media.toyota.com.ar/19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "cross 215 dks",
														"path": "b83e1866-b37b-4443-864a-61b54aa093de.png",
														"url": "https://staging-media.toyota.com.ar/b83e1866-b37b-4443-864a-61b54aa093de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "cross 215 mobile",
														"path": "de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"url": "https://staging-media.toyota.com.ar/de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "cross 1h6",
														"path": "aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"url": "https://staging-media.toyota.com.ar/aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile cross",
														"path": "4c1044fa-1499-4216-bafe-61059aa40994.png",
														"url": "https://staging-media.toyota.com.ar/4c1044fa-1499-4216-bafe-61059aa40994.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "cross dsk 089",
														"path": "8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"url": "https://staging-media.toyota.com.ar/8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "cross mobile 089",
														"path": "44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"url": "https://staging-media.toyota.com.ar/44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "cross dsk 8x2",
														"path": "b8106226-227c-4af7-b42a-7a38100e9346.png",
														"url": "https://staging-media.toyota.com.ar/b8106226-227c-4af7-b42a-7a38100e9346.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "cross mobile 8x2",
														"path": "7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"url": "https://staging-media.toyota.com.ar/7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-1",
														"path": "921d6cf0-8684-4287-8375-8ea84b528919.png",
														"url": "https://staging-media.toyota.com.ar/921d6cf0-8684-4287-8375-8ea84b528919.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-2",
														"path": "09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"url": "https://staging-media.toyota.com.ar/09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-3",
														"path": "d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"url": "https://staging-media.toyota.com.ar/d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-4",
														"path": "614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"url": "https://staging-media.toyota.com.ar/614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-5",
														"path": "dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"url": "https://staging-media.toyota.com.ar/dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-6",
														"path": "09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"url": "https://staging-media.toyota.com.ar/09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-7",
														"path": "de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"url": "https://staging-media.toyota.com.ar/de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-8",
														"path": "26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"url": "https://staging-media.toyota.com.ar/26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-1",
														"path": "86bb167d-d57d-4248-9e58-21f7135def79.png",
														"url": "https://staging-media.toyota.com.ar/86bb167d-d57d-4248-9e58-21f7135def79.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-2",
														"path": "ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"url": "https://staging-media.toyota.com.ar/ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-3",
														"path": "cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"url": "https://staging-media.toyota.com.ar/cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-4",
														"path": "28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"url": "https://staging-media.toyota.com.ar/28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-5",
														"path": "6a720cad-3466-4b36-970f-46f23e74da78.png",
														"url": "https://staging-media.toyota.com.ar/6a720cad-3466-4b36-970f-46f23e74da78.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-6",
														"path": "cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"url": "https://staging-media.toyota.com.ar/cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-7",
														"path": "2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"url": "https://staging-media.toyota.com.ar/2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-8",
														"path": "c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"url": "https://staging-media.toyota.com.ar/c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"url": "https://staging-media.toyota.com.ar/e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display Corolla Cross lateral",
														"path": "69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"url": "https://staging-media.toyota.com.ar/69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display Corolla Cross angulado",
														"path": "d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"url": "https://staging-media.toyota.com.ar/d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 212820,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Banner Desktop",
														"path": "54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"url": "https://staging-media.toyota.com.ar/54973688-2672-47ab-8623-4104011b4d3c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Croos Banner Mobile",
														"path": "b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"url": "https://staging-media.toyota.com.ar/b473f2b8-bbed-403f-a3c5-d0a0042ba935.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "croos desktop",
														"path": "f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"url": "https://staging-media.toyota.com.ar/f4683866-4b02-4ed9-888d-6e79c068081f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "cross mobile",
														"path": "e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"url": "https://staging-media.toyota.com.ar/e1f0c2b3-620e-4fd6-9242-831f29d09b97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "360 cross dsk",
														"path": "ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"url": "https://staging-media.toyota.com.ar/ac457a2c-8fc3-4a34-833b-25cc7d16ca05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "cross mobile ",
														"path": "08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"url": "https://staging-media.toyota.com.ar/08c85f07-bb3b-4b37-9e18-601ac40c5834.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "cross dsk 1g3",
														"path": "8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"url": "https://staging-media.toyota.com.ar/8a7fc7dd-af92-467f-b93e-edbf0bd09921.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "cross mobile 1g3",
														"path": "19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"url": "https://staging-media.toyota.com.ar/19e2e742-2d7d-4f29-a741-fc82c7a80749.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "cross 215 dks",
														"path": "b83e1866-b37b-4443-864a-61b54aa093de.png",
														"url": "https://staging-media.toyota.com.ar/b83e1866-b37b-4443-864a-61b54aa093de.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "cross 215 mobile",
														"path": "de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"url": "https://staging-media.toyota.com.ar/de606aa0-4bcf-43bd-9c9b-43c2362ccc96.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "cross 1h6",
														"path": "aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"url": "https://staging-media.toyota.com.ar/aa361c73-7e04-4399-93ef-0ae4fdbc0fb0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1h6 mobile cross",
														"path": "4c1044fa-1499-4216-bafe-61059aa40994.png",
														"url": "https://staging-media.toyota.com.ar/4c1044fa-1499-4216-bafe-61059aa40994.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "cross dsk 089",
														"path": "8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"url": "https://staging-media.toyota.com.ar/8c05867a-4b82-48d2-9f1b-4a743f51c552.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "cross mobile 089",
														"path": "44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"url": "https://staging-media.toyota.com.ar/44fe2350-7c6b-43fb-853b-5c7e3038409d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "cross dsk 8x2",
														"path": "b8106226-227c-4af7-b42a-7a38100e9346.png",
														"url": "https://staging-media.toyota.com.ar/b8106226-227c-4af7-b42a-7a38100e9346.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "cross mobile 8x2",
														"path": "7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"url": "https://staging-media.toyota.com.ar/7b1774d0-3df5-4ba9-936d-5765a0f687c3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-1",
														"path": "921d6cf0-8684-4287-8375-8ea84b528919.png",
														"url": "https://staging-media.toyota.com.ar/921d6cf0-8684-4287-8375-8ea84b528919.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-2",
														"path": "09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"url": "https://staging-media.toyota.com.ar/09c4a053-5bd6-407f-a44a-099c16d698d8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-3",
														"path": "d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"url": "https://staging-media.toyota.com.ar/d05035dc-1895-4ac1-bec2-a59545301e30.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-4",
														"path": "614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"url": "https://staging-media.toyota.com.ar/614763e0-1a07-4d0e-8893-0c7d696367f8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-5",
														"path": "dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"url": "https://staging-media.toyota.com.ar/dce97ff6-ff78-43a2-b2ef-9838222d8c65.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-6",
														"path": "09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"url": "https://staging-media.toyota.com.ar/09bbf766-4ef6-4ac8-87f5-725010f65c27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-7",
														"path": "de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"url": "https://staging-media.toyota.com.ar/de502d14-5328-4a65-9ac6-8d899fdb8c49.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "corolla-cross-interno-8",
														"path": "26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"url": "https://staging-media.toyota.com.ar/26fc5727-e5cd-4d44-be69-151741eebf8f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-1",
														"path": "86bb167d-d57d-4248-9e58-21f7135def79.png",
														"url": "https://staging-media.toyota.com.ar/86bb167d-d57d-4248-9e58-21f7135def79.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-2",
														"path": "ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"url": "https://staging-media.toyota.com.ar/ffdeab47-e71a-4b72-878d-d80b9c0df4d2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-3",
														"path": "cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"url": "https://staging-media.toyota.com.ar/cd1edad2-cef6-4799-988d-d4a88afac7f0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-4",
														"path": "28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"url": "https://staging-media.toyota.com.ar/28ab6c56-1803-4ff7-b3ff-54ef576ac9ae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-5",
														"path": "6a720cad-3466-4b36-970f-46f23e74da78.png",
														"url": "https://staging-media.toyota.com.ar/6a720cad-3466-4b36-970f-46f23e74da78.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-6",
														"path": "cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"url": "https://staging-media.toyota.com.ar/cc6b00e0-0aad-4232-b65f-29e2b0db3556.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-7",
														"path": "2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"url": "https://staging-media.toyota.com.ar/2e520361-e63d-4f0a-b50a-f81ffbdb406a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "corolla-cross-externo-8",
														"path": "c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"url": "https://staging-media.toyota.com.ar/c3124de4-314c-46de-b99a-5f886b99e5b2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo",
														"path": "e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"url": "https://staging-media.toyota.com.ar/e069ff61-8a6c-4bd3-9285-cf1abaa8d371.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display Corolla Cross lateral",
														"path": "69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"url": "https://staging-media.toyota.com.ar/69336259-6bc2-413a-8f18-e4dd3c053b73.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display Corolla Cross angulado",
														"path": "d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"url": "https://staging-media.toyota.com.ar/d9628a55-b2ba-48a8-a0d9-7e86c40e7ab1.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": null,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Logo Corolla Cross",
														"path": "1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"url": "https://staging-media.toyota.com.ar/1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"url": "https://staging-media.toyota.com.ar/8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"url": "https://staging-media.toyota.com.ar/cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"url": "https://staging-media.toyota.com.ar/230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"url": "https://staging-media.toyota.com.ar/fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"url": "https://staging-media.toyota.com.ar/f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"url": "https://staging-media.toyota.com.ar/83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"url": "https://staging-media.toyota.com.ar/79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"url": "https://staging-media.toyota.com.ar/f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "d75452f1-b373-47df-8958-30a878575442.png",
														"url": "https://staging-media.toyota.com.ar/d75452f1-b373-47df-8958-30a878575442.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"url": "https://staging-media.toyota.com.ar/1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"url": "https://staging-media.toyota.com.ar/a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"url": "https://staging-media.toyota.com.ar/0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"url": "https://staging-media.toyota.com.ar/f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"url": "https://staging-media.toyota.com.ar/c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"url": "https://staging-media.toyota.com.ar/8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"url": "https://staging-media.toyota.com.ar/2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"url": "https://staging-media.toyota.com.ar/e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "24b2131e-9943-4bf0-94ff-37916567456e.png",
														"url": "https://staging-media.toyota.com.ar/24b2131e-9943-4bf0-94ff-37916567456e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R2 desk",
														"path": "c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"url": "https://staging-media.toyota.com.ar/c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 mobile",
														"path": "0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"url": "https://staging-media.toyota.com.ar/0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 desk",
														"path": "07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"url": "https://staging-media.toyota.com.ar/07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 mobile",
														"path": "f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"url": "https://staging-media.toyota.com.ar/f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"url": "https://staging-media.toyota.com.ar/1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"url": "https://staging-media.toyota.com.ar/4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 desk",
														"path": "a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"url": "https://staging-media.toyota.com.ar/a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 mobile",
														"path": "bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"url": "https://staging-media.toyota.com.ar/bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"url": "https://staging-media.toyota.com.ar/0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"url": "https://staging-media.toyota.com.ar/42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "8X2 desk",
														"path": "d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"url": "https://staging-media.toyota.com.ar/d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "8X2 mobile",
														"path": "1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"url": "https://staging-media.toyota.com.ar/1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"url": "https://staging-media.toyota.com.ar/b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"url": "https://staging-media.toyota.com.ar/34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 209810,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Logo Corolla Cross",
														"path": "1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"url": "https://staging-media.toyota.com.ar/1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"url": "https://staging-media.toyota.com.ar/8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"url": "https://staging-media.toyota.com.ar/cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"url": "https://staging-media.toyota.com.ar/230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"url": "https://staging-media.toyota.com.ar/fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"url": "https://staging-media.toyota.com.ar/f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"url": "https://staging-media.toyota.com.ar/83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"url": "https://staging-media.toyota.com.ar/79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"url": "https://staging-media.toyota.com.ar/f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "d75452f1-b373-47df-8958-30a878575442.png",
														"url": "https://staging-media.toyota.com.ar/d75452f1-b373-47df-8958-30a878575442.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"url": "https://staging-media.toyota.com.ar/1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"url": "https://staging-media.toyota.com.ar/a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"url": "https://staging-media.toyota.com.ar/0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"url": "https://staging-media.toyota.com.ar/f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"url": "https://staging-media.toyota.com.ar/c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"url": "https://staging-media.toyota.com.ar/8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"url": "https://staging-media.toyota.com.ar/2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"url": "https://staging-media.toyota.com.ar/e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "24b2131e-9943-4bf0-94ff-37916567456e.png",
														"url": "https://staging-media.toyota.com.ar/24b2131e-9943-4bf0-94ff-37916567456e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R2 desk",
														"path": "c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"url": "https://staging-media.toyota.com.ar/c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 mobile",
														"path": "0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"url": "https://staging-media.toyota.com.ar/0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 desk",
														"path": "07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"url": "https://staging-media.toyota.com.ar/07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 mobile",
														"path": "f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"url": "https://staging-media.toyota.com.ar/f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"url": "https://staging-media.toyota.com.ar/1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"url": "https://staging-media.toyota.com.ar/4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 desk",
														"path": "a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"url": "https://staging-media.toyota.com.ar/a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 mobile",
														"path": "bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"url": "https://staging-media.toyota.com.ar/bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"url": "https://staging-media.toyota.com.ar/0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"url": "https://staging-media.toyota.com.ar/42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "8X2 desk",
														"path": "d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"url": "https://staging-media.toyota.com.ar/d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "8X2 mobile",
														"path": "1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"url": "https://staging-media.toyota.com.ar/1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"url": "https://staging-media.toyota.com.ar/b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"url": "https://staging-media.toyota.com.ar/34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 209810,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Logo Corolla Cross",
														"path": "1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"url": "https://staging-media.toyota.com.ar/1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"url": "https://staging-media.toyota.com.ar/8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"url": "https://staging-media.toyota.com.ar/cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"url": "https://staging-media.toyota.com.ar/230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"url": "https://staging-media.toyota.com.ar/fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"url": "https://staging-media.toyota.com.ar/f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"url": "https://staging-media.toyota.com.ar/83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"url": "https://staging-media.toyota.com.ar/79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"url": "https://staging-media.toyota.com.ar/f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "d75452f1-b373-47df-8958-30a878575442.png",
														"url": "https://staging-media.toyota.com.ar/d75452f1-b373-47df-8958-30a878575442.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"url": "https://staging-media.toyota.com.ar/1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"url": "https://staging-media.toyota.com.ar/a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"url": "https://staging-media.toyota.com.ar/0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"url": "https://staging-media.toyota.com.ar/f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"url": "https://staging-media.toyota.com.ar/c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"url": "https://staging-media.toyota.com.ar/8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"url": "https://staging-media.toyota.com.ar/2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"url": "https://staging-media.toyota.com.ar/e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "24b2131e-9943-4bf0-94ff-37916567456e.png",
														"url": "https://staging-media.toyota.com.ar/24b2131e-9943-4bf0-94ff-37916567456e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R2 desk",
														"path": "c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"url": "https://staging-media.toyota.com.ar/c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 mobile",
														"path": "0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"url": "https://staging-media.toyota.com.ar/0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 desk",
														"path": "07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"url": "https://staging-media.toyota.com.ar/07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 mobile",
														"path": "f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"url": "https://staging-media.toyota.com.ar/f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"url": "https://staging-media.toyota.com.ar/1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"url": "https://staging-media.toyota.com.ar/4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 desk",
														"path": "a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"url": "https://staging-media.toyota.com.ar/a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 mobile",
														"path": "bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"url": "https://staging-media.toyota.com.ar/bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"url": "https://staging-media.toyota.com.ar/0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"url": "https://staging-media.toyota.com.ar/42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "8X2 desk",
														"path": "d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"url": "https://staging-media.toyota.com.ar/d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "8X2 mobile",
														"path": "1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"url": "https://staging-media.toyota.com.ar/1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"url": "https://staging-media.toyota.com.ar/b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"url": "https://staging-media.toyota.com.ar/34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 209810,
										"offers": null,
										"color": {
											"name": "Preto Infinito (215)",
											"code": "215",
											"rgb": "#282E30"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Logo Corolla Cross",
														"path": "1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"url": "https://staging-media.toyota.com.ar/1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"url": "https://staging-media.toyota.com.ar/8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"url": "https://staging-media.toyota.com.ar/cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"url": "https://staging-media.toyota.com.ar/230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"url": "https://staging-media.toyota.com.ar/fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"url": "https://staging-media.toyota.com.ar/f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"url": "https://staging-media.toyota.com.ar/83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"url": "https://staging-media.toyota.com.ar/79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"url": "https://staging-media.toyota.com.ar/f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "d75452f1-b373-47df-8958-30a878575442.png",
														"url": "https://staging-media.toyota.com.ar/d75452f1-b373-47df-8958-30a878575442.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"url": "https://staging-media.toyota.com.ar/1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"url": "https://staging-media.toyota.com.ar/a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"url": "https://staging-media.toyota.com.ar/0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"url": "https://staging-media.toyota.com.ar/f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"url": "https://staging-media.toyota.com.ar/c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"url": "https://staging-media.toyota.com.ar/8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"url": "https://staging-media.toyota.com.ar/2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"url": "https://staging-media.toyota.com.ar/e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "24b2131e-9943-4bf0-94ff-37916567456e.png",
														"url": "https://staging-media.toyota.com.ar/24b2131e-9943-4bf0-94ff-37916567456e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R2 desk",
														"path": "c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"url": "https://staging-media.toyota.com.ar/c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 mobile",
														"path": "0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"url": "https://staging-media.toyota.com.ar/0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 desk",
														"path": "07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"url": "https://staging-media.toyota.com.ar/07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 mobile",
														"path": "f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"url": "https://staging-media.toyota.com.ar/f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"url": "https://staging-media.toyota.com.ar/1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"url": "https://staging-media.toyota.com.ar/4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 desk",
														"path": "a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"url": "https://staging-media.toyota.com.ar/a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 mobile",
														"path": "bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"url": "https://staging-media.toyota.com.ar/bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"url": "https://staging-media.toyota.com.ar/0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"url": "https://staging-media.toyota.com.ar/42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "8X2 desk",
														"path": "d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"url": "https://staging-media.toyota.com.ar/d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "8X2 mobile",
														"path": "1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"url": "https://staging-media.toyota.com.ar/1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"url": "https://staging-media.toyota.com.ar/b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"url": "https://staging-media.toyota.com.ar/34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 209810,
										"offers": null,
										"color": {
											"name": "Prata Lua Nova (1H6)",
											"code": "1H6",
											"rgb": "#C3CBD0"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Logo Corolla Cross",
														"path": "1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"url": "https://staging-media.toyota.com.ar/1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"url": "https://staging-media.toyota.com.ar/8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"url": "https://staging-media.toyota.com.ar/cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"url": "https://staging-media.toyota.com.ar/230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"url": "https://staging-media.toyota.com.ar/fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"url": "https://staging-media.toyota.com.ar/f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"url": "https://staging-media.toyota.com.ar/83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"url": "https://staging-media.toyota.com.ar/79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"url": "https://staging-media.toyota.com.ar/f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "d75452f1-b373-47df-8958-30a878575442.png",
														"url": "https://staging-media.toyota.com.ar/d75452f1-b373-47df-8958-30a878575442.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"url": "https://staging-media.toyota.com.ar/1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"url": "https://staging-media.toyota.com.ar/a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"url": "https://staging-media.toyota.com.ar/0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"url": "https://staging-media.toyota.com.ar/f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"url": "https://staging-media.toyota.com.ar/c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"url": "https://staging-media.toyota.com.ar/8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"url": "https://staging-media.toyota.com.ar/2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"url": "https://staging-media.toyota.com.ar/e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "24b2131e-9943-4bf0-94ff-37916567456e.png",
														"url": "https://staging-media.toyota.com.ar/24b2131e-9943-4bf0-94ff-37916567456e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R2 desk",
														"path": "c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"url": "https://staging-media.toyota.com.ar/c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 mobile",
														"path": "0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"url": "https://staging-media.toyota.com.ar/0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 desk",
														"path": "07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"url": "https://staging-media.toyota.com.ar/07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 mobile",
														"path": "f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"url": "https://staging-media.toyota.com.ar/f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"url": "https://staging-media.toyota.com.ar/1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"url": "https://staging-media.toyota.com.ar/4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 desk",
														"path": "a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"url": "https://staging-media.toyota.com.ar/a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 mobile",
														"path": "bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"url": "https://staging-media.toyota.com.ar/bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"url": "https://staging-media.toyota.com.ar/0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"url": "https://staging-media.toyota.com.ar/42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "8X2 desk",
														"path": "d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"url": "https://staging-media.toyota.com.ar/d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "8X2 mobile",
														"path": "1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"url": "https://staging-media.toyota.com.ar/1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"url": "https://staging-media.toyota.com.ar/b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"url": "https://staging-media.toyota.com.ar/34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 209810,
										"offers": null,
										"color": {
											"name": "Azul Netuno (8X2)",
											"code": "8X2",
											"rgb": "#16296D"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Logo Corolla Cross",
														"path": "1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"url": "https://staging-media.toyota.com.ar/1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"url": "https://staging-media.toyota.com.ar/8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"url": "https://staging-media.toyota.com.ar/cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"url": "https://staging-media.toyota.com.ar/230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"url": "https://staging-media.toyota.com.ar/fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"url": "https://staging-media.toyota.com.ar/f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"url": "https://staging-media.toyota.com.ar/83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"url": "https://staging-media.toyota.com.ar/79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"url": "https://staging-media.toyota.com.ar/f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "d75452f1-b373-47df-8958-30a878575442.png",
														"url": "https://staging-media.toyota.com.ar/d75452f1-b373-47df-8958-30a878575442.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"url": "https://staging-media.toyota.com.ar/1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"url": "https://staging-media.toyota.com.ar/a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"url": "https://staging-media.toyota.com.ar/0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"url": "https://staging-media.toyota.com.ar/f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"url": "https://staging-media.toyota.com.ar/c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"url": "https://staging-media.toyota.com.ar/8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"url": "https://staging-media.toyota.com.ar/2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"url": "https://staging-media.toyota.com.ar/e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "24b2131e-9943-4bf0-94ff-37916567456e.png",
														"url": "https://staging-media.toyota.com.ar/24b2131e-9943-4bf0-94ff-37916567456e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R2 desk",
														"path": "c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"url": "https://staging-media.toyota.com.ar/c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 mobile",
														"path": "0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"url": "https://staging-media.toyota.com.ar/0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 desk",
														"path": "07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"url": "https://staging-media.toyota.com.ar/07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 mobile",
														"path": "f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"url": "https://staging-media.toyota.com.ar/f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"url": "https://staging-media.toyota.com.ar/1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"url": "https://staging-media.toyota.com.ar/4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 desk",
														"path": "a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"url": "https://staging-media.toyota.com.ar/a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 mobile",
														"path": "bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"url": "https://staging-media.toyota.com.ar/bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"url": "https://staging-media.toyota.com.ar/0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"url": "https://staging-media.toyota.com.ar/42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "8X2 desk",
														"path": "d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"url": "https://staging-media.toyota.com.ar/d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "8X2 mobile",
														"path": "1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"url": "https://staging-media.toyota.com.ar/1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"url": "https://staging-media.toyota.com.ar/b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"url": "https://staging-media.toyota.com.ar/34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 210120,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Logo Corolla Cross",
														"path": "1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"url": "https://staging-media.toyota.com.ar/1cab8f31-214c-4524-9cca-3d0659afe11a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"url": "https://staging-media.toyota.com.ar/8e2e3c3c-a9fa-4d2a-8e93-35cf5a50e6bd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"url": "https://staging-media.toyota.com.ar/cb883f3d-ea7a-4929-affd-1532d7724d1d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"url": "https://staging-media.toyota.com.ar/230e4bca-5f74-4cc7-b0fe-cca85c93786b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"url": "https://staging-media.toyota.com.ar/fcf00e46-0ded-4069-ada1-b7fdb98957c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"url": "https://staging-media.toyota.com.ar/f6112354-b6f8-4ab5-ae42-6f58a1c53b22.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"url": "https://staging-media.toyota.com.ar/83109e4d-b770-4d1a-818e-fe6c71c99c2f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"url": "https://staging-media.toyota.com.ar/79ca8afb-9d26-4d37-8d0e-db38040c325e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"url": "https://staging-media.toyota.com.ar/f6eb5daf-242b-43a6-8ccc-95b9a506d3fa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "d75452f1-b373-47df-8958-30a878575442.png",
														"url": "https://staging-media.toyota.com.ar/d75452f1-b373-47df-8958-30a878575442.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"url": "https://staging-media.toyota.com.ar/1f8aeec4-05e8-40fd-9410-f2daa30d1077.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"url": "https://staging-media.toyota.com.ar/a00b8f95-900b-4337-b54c-ea0010a904f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"url": "https://staging-media.toyota.com.ar/0be1c0d8-4273-4d3f-b6e4-787f94e3469a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"url": "https://staging-media.toyota.com.ar/f83dadd1-649b-4012-ad26-2c29753b194c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"url": "https://staging-media.toyota.com.ar/c0c156a0-842d-4fd0-a3c2-bf46d7058a86.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"url": "https://staging-media.toyota.com.ar/8c4a423b-dbba-496f-88cd-54749af0e39a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"url": "https://staging-media.toyota.com.ar/2f698364-91ce-4ecf-bd6b-0fc57ca7e04b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"url": "https://staging-media.toyota.com.ar/e721ee44-e60d-42d7-baa9-c76c4fbf6041.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 mobile",
														"path": "24b2131e-9943-4bf0-94ff-37916567456e.png",
														"url": "https://staging-media.toyota.com.ar/24b2131e-9943-4bf0-94ff-37916567456e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "3R2 desk",
														"path": "c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"url": "https://staging-media.toyota.com.ar/c5225495-6a83-49a3-a3b4-23d967aa11d9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "3R3 mobile",
														"path": "0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"url": "https://staging-media.toyota.com.ar/0b2ae7c6-c8aa-43b4-b812-a7a3506defe3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1G3 desk",
														"path": "07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"url": "https://staging-media.toyota.com.ar/07e4be6c-7161-4624-8a0c-77d490696b2e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1G3 mobile",
														"path": "f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"url": "https://staging-media.toyota.com.ar/f894a7a8-d886-4f8b-ba41-257e77e813ff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:215",
													"media": {
														"title": "215 desk",
														"path": "1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"url": "https://staging-media.toyota.com.ar/1a95643a-e3d8-455b-afd3-bf18a58501a2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:215",
													"media": {
														"title": "215 mobile",
														"path": "4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"url": "https://staging-media.toyota.com.ar/4d23d049-f18e-42aa-a192-a083b669a5ab.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1H6",
													"media": {
														"title": "1H6 desk",
														"path": "a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"url": "https://staging-media.toyota.com.ar/a723f419-7b28-4ab5-b1c9-3448b0bea0b7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1H6",
													"media": {
														"title": "1H6 mobile",
														"path": "bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"url": "https://staging-media.toyota.com.ar/bd588c39-aa91-4cae-8bc6-01febfd54e27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "089 desk",
														"path": "0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"url": "https://staging-media.toyota.com.ar/0324b12c-5ea7-43d7-acca-8a728b76adff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "089 mobile",
														"path": "42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"url": "https://staging-media.toyota.com.ar/42cd63f4-5c7a-4d49-b182-21140f8ebe84.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X2",
													"media": {
														"title": "8X2 desk",
														"path": "d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"url": "https://staging-media.toyota.com.ar/d2781855-c6c3-4c59-946d-e37b1134ae28.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X2",
													"media": {
														"title": "8X2 mobile",
														"path": "1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"url": "https://staging-media.toyota.com.ar/1b9a0795-8290-4ac3-a85c-11d103b5fd7f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"url": "https://staging-media.toyota.com.ar/b96e9c3c-a4db-4e4b-a77e-13802dda47e2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"url": "https://staging-media.toyota.com.ar/34a37bc1-3933-456f-822a-ea114cb89ff9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"url": "https://staging-media.toyota.com.br/c46e288c-7fee-4220-8a72-226f0d1b7624.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					}
				]
			},
			{
				"id": "43c76ee4-b45b-4a22-9ea4-3c32c8618fbb",
				"name": "SW4",
				"vehicleExternalId": null,
				"status": "ENABLED",
				"category": {
					"name": "SUVs",
					"id": "39e016f8-3719-439b-91f6-41a1d0d151b6"
				},
				"versions": [
					{
						"name": "SRX 5 Lugares",
						"id": "02ee00ee-8586-41d2-b232-e3d92a63f172",
						"order": 1,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 378190,
										"offers": null,
										"color": {
											"name": "Prata Névoa (1D6)",
											"code": "1D6",
											"rgb": "#C5CDCB"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "1cca8512-5585-4aca-988a-d03a329a3ff7.jpeg",
														"url": "https://staging-media.toyota.com.ar/1cca8512-5585-4aca-988a-d03a329a3ff7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "9fe997f8-1d26-4b91-980d-f68e2aac8262.jpeg",
														"url": "https://staging-media.toyota.com.ar/9fe997f8-1d26-4b91-980d-f68e2aac8262.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "014b44d9-d1f3-47a1-9eb5-d4cb0701f23a.jpeg",
														"url": "https://staging-media.toyota.com.ar/014b44d9-d1f3-47a1-9eb5-d4cb0701f23a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "00c2855c-8fe2-48ae-8473-8ac9d972e507.jpeg",
														"url": "https://staging-media.toyota.com.ar/00c2855c-8fe2-48ae-8473-8ac9d972e507.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "18bb5576-3eae-4949-87e1-f7ccdf06fa02.jpeg",
														"url": "https://staging-media.toyota.com.ar/18bb5576-3eae-4949-87e1-f7ccdf06fa02.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "bfa71b7b-7260-414a-8a9b-59858c1bf5d0.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfa71b7b-7260-414a-8a9b-59858c1bf5d0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "f3318081-d0be-4d7a-bc3b-c83b22c66269.jpeg",
														"url": "https://staging-media.toyota.com.ar/f3318081-d0be-4d7a-bc3b-c83b22c66269.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "aeda1e7b-3618-43a2-a1b7-d81849e5e8cf.jpeg",
														"url": "https://staging-media.toyota.com.ar/aeda1e7b-3618-43a2-a1b7-d81849e5e8cf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "9e7b4fa6-cb74-4a43-8067-199b26e8818a.jpeg",
														"url": "https://staging-media.toyota.com.ar/9e7b4fa6-cb74-4a43-8067-199b26e8818a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "fd322222-ad88-465b-9915-2956b8c53be3.png",
														"url": "https://staging-media.toyota.com.ar/fd322222-ad88-465b-9915-2956b8c53be3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "b86a2d79-cef7-41ec-9f86-d577e5ebe990.png",
														"url": "https://staging-media.toyota.com.ar/b86a2d79-cef7-41ec-9f86-d577e5ebe990.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"url": "https://staging-media.toyota.com.ar/553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "901c2c0a-6816-4996-a012-9c2e7f23dfd3.jpeg",
														"url": "https://staging-media.toyota.com.ar/901c2c0a-6816-4996-a012-9c2e7f23dfd3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "14e83ed3-4638-43dd-8350-111395784e49.jpeg",
														"url": "https://staging-media.toyota.com.ar/14e83ed3-4638-43dd-8350-111395784e49.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "desk",
														"path": "42f20c66-cfca-41da-a943-24269ae570ba.png",
														"url": "https://staging-media.toyota.com.ar/42f20c66-cfca-41da-a943-24269ae570ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "mobile",
														"path": "a205ec2a-8841-4a8b-a333-17714b9c764c.png",
														"url": "https://staging-media.toyota.com.ar/a205ec2a-8841-4a8b-a333-17714b9c764c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "5a955b4d-4271-44d1-bdc7-e7db6b74aea2.png",
														"url": "https://staging-media.toyota.com.ar/5a955b4d-4271-44d1-bdc7-e7db6b74aea2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "2bda909a-e629-455c-bc7d-2d956d138e1a.png",
														"url": "https://staging-media.toyota.com.ar/2bda909a-e629-455c-bc7d-2d956d138e1a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "45a90aed-24a2-44e8-b5cb-34efa6285dcd.png",
														"url": "https://staging-media.toyota.com.ar/45a90aed-24a2-44e8-b5cb-34efa6285dcd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "bf4b0ed3-486f-46ce-9c9d-cd4630235965.png",
														"url": "https://staging-media.toyota.com.ar/bf4b0ed3-486f-46ce-9c9d-cd4630235965.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "desk",
														"path": "c0ac9ad3-e8cd-435e-bd10-b2cc42967676.png",
														"url": "https://staging-media.toyota.com.ar/c0ac9ad3-e8cd-435e-bd10-b2cc42967676.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "mobile",
														"path": "b1a8312f-db20-4958-8ad9-cd0a8c89e672.png",
														"url": "https://staging-media.toyota.com.ar/b1a8312f-db20-4958-8ad9-cd0a8c89e672.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "desk",
														"path": "cf9d4846-b042-4c9b-85de-d71e3c2a308d.png",
														"url": "https://staging-media.toyota.com.ar/cf9d4846-b042-4c9b-85de-d71e3c2a308d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "mobile",
														"path": "64a33a20-5699-482a-a387-f352f358ff37.png",
														"url": "https://staging-media.toyota.com.ar/64a33a20-5699-482a-a387-f352f358ff37.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "9acaea0d-42dd-48c0-a54f-f38db8303680.jpeg",
														"url": "https://staging-media.toyota.com.ar/9acaea0d-42dd-48c0-a54f-f38db8303680.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "4bc75a64-cae9-4078-8c0b-dd05ef0e8d50.jpeg",
														"url": "https://staging-media.toyota.com.ar/4bc75a64-cae9-4078-8c0b-dd05ef0e8d50.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 378190,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "1cca8512-5585-4aca-988a-d03a329a3ff7.jpeg",
														"url": "https://staging-media.toyota.com.ar/1cca8512-5585-4aca-988a-d03a329a3ff7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "9fe997f8-1d26-4b91-980d-f68e2aac8262.jpeg",
														"url": "https://staging-media.toyota.com.ar/9fe997f8-1d26-4b91-980d-f68e2aac8262.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "014b44d9-d1f3-47a1-9eb5-d4cb0701f23a.jpeg",
														"url": "https://staging-media.toyota.com.ar/014b44d9-d1f3-47a1-9eb5-d4cb0701f23a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "00c2855c-8fe2-48ae-8473-8ac9d972e507.jpeg",
														"url": "https://staging-media.toyota.com.ar/00c2855c-8fe2-48ae-8473-8ac9d972e507.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "18bb5576-3eae-4949-87e1-f7ccdf06fa02.jpeg",
														"url": "https://staging-media.toyota.com.ar/18bb5576-3eae-4949-87e1-f7ccdf06fa02.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "bfa71b7b-7260-414a-8a9b-59858c1bf5d0.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfa71b7b-7260-414a-8a9b-59858c1bf5d0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "f3318081-d0be-4d7a-bc3b-c83b22c66269.jpeg",
														"url": "https://staging-media.toyota.com.ar/f3318081-d0be-4d7a-bc3b-c83b22c66269.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "aeda1e7b-3618-43a2-a1b7-d81849e5e8cf.jpeg",
														"url": "https://staging-media.toyota.com.ar/aeda1e7b-3618-43a2-a1b7-d81849e5e8cf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "9e7b4fa6-cb74-4a43-8067-199b26e8818a.jpeg",
														"url": "https://staging-media.toyota.com.ar/9e7b4fa6-cb74-4a43-8067-199b26e8818a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "fd322222-ad88-465b-9915-2956b8c53be3.png",
														"url": "https://staging-media.toyota.com.ar/fd322222-ad88-465b-9915-2956b8c53be3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "b86a2d79-cef7-41ec-9f86-d577e5ebe990.png",
														"url": "https://staging-media.toyota.com.ar/b86a2d79-cef7-41ec-9f86-d577e5ebe990.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"url": "https://staging-media.toyota.com.ar/553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "901c2c0a-6816-4996-a012-9c2e7f23dfd3.jpeg",
														"url": "https://staging-media.toyota.com.ar/901c2c0a-6816-4996-a012-9c2e7f23dfd3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "14e83ed3-4638-43dd-8350-111395784e49.jpeg",
														"url": "https://staging-media.toyota.com.ar/14e83ed3-4638-43dd-8350-111395784e49.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "desk",
														"path": "42f20c66-cfca-41da-a943-24269ae570ba.png",
														"url": "https://staging-media.toyota.com.ar/42f20c66-cfca-41da-a943-24269ae570ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "mobile",
														"path": "a205ec2a-8841-4a8b-a333-17714b9c764c.png",
														"url": "https://staging-media.toyota.com.ar/a205ec2a-8841-4a8b-a333-17714b9c764c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "5a955b4d-4271-44d1-bdc7-e7db6b74aea2.png",
														"url": "https://staging-media.toyota.com.ar/5a955b4d-4271-44d1-bdc7-e7db6b74aea2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "2bda909a-e629-455c-bc7d-2d956d138e1a.png",
														"url": "https://staging-media.toyota.com.ar/2bda909a-e629-455c-bc7d-2d956d138e1a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "45a90aed-24a2-44e8-b5cb-34efa6285dcd.png",
														"url": "https://staging-media.toyota.com.ar/45a90aed-24a2-44e8-b5cb-34efa6285dcd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "bf4b0ed3-486f-46ce-9c9d-cd4630235965.png",
														"url": "https://staging-media.toyota.com.ar/bf4b0ed3-486f-46ce-9c9d-cd4630235965.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "desk",
														"path": "c0ac9ad3-e8cd-435e-bd10-b2cc42967676.png",
														"url": "https://staging-media.toyota.com.ar/c0ac9ad3-e8cd-435e-bd10-b2cc42967676.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "mobile",
														"path": "b1a8312f-db20-4958-8ad9-cd0a8c89e672.png",
														"url": "https://staging-media.toyota.com.ar/b1a8312f-db20-4958-8ad9-cd0a8c89e672.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "desk",
														"path": "cf9d4846-b042-4c9b-85de-d71e3c2a308d.png",
														"url": "https://staging-media.toyota.com.ar/cf9d4846-b042-4c9b-85de-d71e3c2a308d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "mobile",
														"path": "64a33a20-5699-482a-a387-f352f358ff37.png",
														"url": "https://staging-media.toyota.com.ar/64a33a20-5699-482a-a387-f352f358ff37.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "9acaea0d-42dd-48c0-a54f-f38db8303680.jpeg",
														"url": "https://staging-media.toyota.com.ar/9acaea0d-42dd-48c0-a54f-f38db8303680.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "4bc75a64-cae9-4078-8c0b-dd05ef0e8d50.jpeg",
														"url": "https://staging-media.toyota.com.ar/4bc75a64-cae9-4078-8c0b-dd05ef0e8d50.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 378190,
										"offers": null,
										"color": {
											"name": "Preto Attitude (218)",
											"code": "218",
											"rgb": "#343736"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "1cca8512-5585-4aca-988a-d03a329a3ff7.jpeg",
														"url": "https://staging-media.toyota.com.ar/1cca8512-5585-4aca-988a-d03a329a3ff7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "9fe997f8-1d26-4b91-980d-f68e2aac8262.jpeg",
														"url": "https://staging-media.toyota.com.ar/9fe997f8-1d26-4b91-980d-f68e2aac8262.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "014b44d9-d1f3-47a1-9eb5-d4cb0701f23a.jpeg",
														"url": "https://staging-media.toyota.com.ar/014b44d9-d1f3-47a1-9eb5-d4cb0701f23a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "00c2855c-8fe2-48ae-8473-8ac9d972e507.jpeg",
														"url": "https://staging-media.toyota.com.ar/00c2855c-8fe2-48ae-8473-8ac9d972e507.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "18bb5576-3eae-4949-87e1-f7ccdf06fa02.jpeg",
														"url": "https://staging-media.toyota.com.ar/18bb5576-3eae-4949-87e1-f7ccdf06fa02.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "bfa71b7b-7260-414a-8a9b-59858c1bf5d0.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfa71b7b-7260-414a-8a9b-59858c1bf5d0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "f3318081-d0be-4d7a-bc3b-c83b22c66269.jpeg",
														"url": "https://staging-media.toyota.com.ar/f3318081-d0be-4d7a-bc3b-c83b22c66269.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "aeda1e7b-3618-43a2-a1b7-d81849e5e8cf.jpeg",
														"url": "https://staging-media.toyota.com.ar/aeda1e7b-3618-43a2-a1b7-d81849e5e8cf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "9e7b4fa6-cb74-4a43-8067-199b26e8818a.jpeg",
														"url": "https://staging-media.toyota.com.ar/9e7b4fa6-cb74-4a43-8067-199b26e8818a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "fd322222-ad88-465b-9915-2956b8c53be3.png",
														"url": "https://staging-media.toyota.com.ar/fd322222-ad88-465b-9915-2956b8c53be3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "b86a2d79-cef7-41ec-9f86-d577e5ebe990.png",
														"url": "https://staging-media.toyota.com.ar/b86a2d79-cef7-41ec-9f86-d577e5ebe990.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"url": "https://staging-media.toyota.com.ar/553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "901c2c0a-6816-4996-a012-9c2e7f23dfd3.jpeg",
														"url": "https://staging-media.toyota.com.ar/901c2c0a-6816-4996-a012-9c2e7f23dfd3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "14e83ed3-4638-43dd-8350-111395784e49.jpeg",
														"url": "https://staging-media.toyota.com.ar/14e83ed3-4638-43dd-8350-111395784e49.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "desk",
														"path": "42f20c66-cfca-41da-a943-24269ae570ba.png",
														"url": "https://staging-media.toyota.com.ar/42f20c66-cfca-41da-a943-24269ae570ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "mobile",
														"path": "a205ec2a-8841-4a8b-a333-17714b9c764c.png",
														"url": "https://staging-media.toyota.com.ar/a205ec2a-8841-4a8b-a333-17714b9c764c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "5a955b4d-4271-44d1-bdc7-e7db6b74aea2.png",
														"url": "https://staging-media.toyota.com.ar/5a955b4d-4271-44d1-bdc7-e7db6b74aea2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "2bda909a-e629-455c-bc7d-2d956d138e1a.png",
														"url": "https://staging-media.toyota.com.ar/2bda909a-e629-455c-bc7d-2d956d138e1a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "45a90aed-24a2-44e8-b5cb-34efa6285dcd.png",
														"url": "https://staging-media.toyota.com.ar/45a90aed-24a2-44e8-b5cb-34efa6285dcd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "bf4b0ed3-486f-46ce-9c9d-cd4630235965.png",
														"url": "https://staging-media.toyota.com.ar/bf4b0ed3-486f-46ce-9c9d-cd4630235965.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "desk",
														"path": "c0ac9ad3-e8cd-435e-bd10-b2cc42967676.png",
														"url": "https://staging-media.toyota.com.ar/c0ac9ad3-e8cd-435e-bd10-b2cc42967676.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "mobile",
														"path": "b1a8312f-db20-4958-8ad9-cd0a8c89e672.png",
														"url": "https://staging-media.toyota.com.ar/b1a8312f-db20-4958-8ad9-cd0a8c89e672.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "desk",
														"path": "cf9d4846-b042-4c9b-85de-d71e3c2a308d.png",
														"url": "https://staging-media.toyota.com.ar/cf9d4846-b042-4c9b-85de-d71e3c2a308d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "mobile",
														"path": "64a33a20-5699-482a-a387-f352f358ff37.png",
														"url": "https://staging-media.toyota.com.ar/64a33a20-5699-482a-a387-f352f358ff37.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "9acaea0d-42dd-48c0-a54f-f38db8303680.jpeg",
														"url": "https://staging-media.toyota.com.ar/9acaea0d-42dd-48c0-a54f-f38db8303680.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "4bc75a64-cae9-4078-8c0b-dd05ef0e8d50.jpeg",
														"url": "https://staging-media.toyota.com.ar/4bc75a64-cae9-4078-8c0b-dd05ef0e8d50.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 378190,
										"offers": null,
										"color": {
											"name": "Marrom Urban (4W9)",
											"code": "4W9",
											"rgb": "#472F29"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "1cca8512-5585-4aca-988a-d03a329a3ff7.jpeg",
														"url": "https://staging-media.toyota.com.ar/1cca8512-5585-4aca-988a-d03a329a3ff7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "9fe997f8-1d26-4b91-980d-f68e2aac8262.jpeg",
														"url": "https://staging-media.toyota.com.ar/9fe997f8-1d26-4b91-980d-f68e2aac8262.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "014b44d9-d1f3-47a1-9eb5-d4cb0701f23a.jpeg",
														"url": "https://staging-media.toyota.com.ar/014b44d9-d1f3-47a1-9eb5-d4cb0701f23a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "00c2855c-8fe2-48ae-8473-8ac9d972e507.jpeg",
														"url": "https://staging-media.toyota.com.ar/00c2855c-8fe2-48ae-8473-8ac9d972e507.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "18bb5576-3eae-4949-87e1-f7ccdf06fa02.jpeg",
														"url": "https://staging-media.toyota.com.ar/18bb5576-3eae-4949-87e1-f7ccdf06fa02.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "bfa71b7b-7260-414a-8a9b-59858c1bf5d0.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfa71b7b-7260-414a-8a9b-59858c1bf5d0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "f3318081-d0be-4d7a-bc3b-c83b22c66269.jpeg",
														"url": "https://staging-media.toyota.com.ar/f3318081-d0be-4d7a-bc3b-c83b22c66269.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "aeda1e7b-3618-43a2-a1b7-d81849e5e8cf.jpeg",
														"url": "https://staging-media.toyota.com.ar/aeda1e7b-3618-43a2-a1b7-d81849e5e8cf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "9e7b4fa6-cb74-4a43-8067-199b26e8818a.jpeg",
														"url": "https://staging-media.toyota.com.ar/9e7b4fa6-cb74-4a43-8067-199b26e8818a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "fd322222-ad88-465b-9915-2956b8c53be3.png",
														"url": "https://staging-media.toyota.com.ar/fd322222-ad88-465b-9915-2956b8c53be3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "b86a2d79-cef7-41ec-9f86-d577e5ebe990.png",
														"url": "https://staging-media.toyota.com.ar/b86a2d79-cef7-41ec-9f86-d577e5ebe990.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"url": "https://staging-media.toyota.com.ar/553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "901c2c0a-6816-4996-a012-9c2e7f23dfd3.jpeg",
														"url": "https://staging-media.toyota.com.ar/901c2c0a-6816-4996-a012-9c2e7f23dfd3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "14e83ed3-4638-43dd-8350-111395784e49.jpeg",
														"url": "https://staging-media.toyota.com.ar/14e83ed3-4638-43dd-8350-111395784e49.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "desk",
														"path": "42f20c66-cfca-41da-a943-24269ae570ba.png",
														"url": "https://staging-media.toyota.com.ar/42f20c66-cfca-41da-a943-24269ae570ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "mobile",
														"path": "a205ec2a-8841-4a8b-a333-17714b9c764c.png",
														"url": "https://staging-media.toyota.com.ar/a205ec2a-8841-4a8b-a333-17714b9c764c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "5a955b4d-4271-44d1-bdc7-e7db6b74aea2.png",
														"url": "https://staging-media.toyota.com.ar/5a955b4d-4271-44d1-bdc7-e7db6b74aea2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "2bda909a-e629-455c-bc7d-2d956d138e1a.png",
														"url": "https://staging-media.toyota.com.ar/2bda909a-e629-455c-bc7d-2d956d138e1a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "45a90aed-24a2-44e8-b5cb-34efa6285dcd.png",
														"url": "https://staging-media.toyota.com.ar/45a90aed-24a2-44e8-b5cb-34efa6285dcd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "bf4b0ed3-486f-46ce-9c9d-cd4630235965.png",
														"url": "https://staging-media.toyota.com.ar/bf4b0ed3-486f-46ce-9c9d-cd4630235965.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "desk",
														"path": "c0ac9ad3-e8cd-435e-bd10-b2cc42967676.png",
														"url": "https://staging-media.toyota.com.ar/c0ac9ad3-e8cd-435e-bd10-b2cc42967676.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "mobile",
														"path": "b1a8312f-db20-4958-8ad9-cd0a8c89e672.png",
														"url": "https://staging-media.toyota.com.ar/b1a8312f-db20-4958-8ad9-cd0a8c89e672.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "desk",
														"path": "cf9d4846-b042-4c9b-85de-d71e3c2a308d.png",
														"url": "https://staging-media.toyota.com.ar/cf9d4846-b042-4c9b-85de-d71e3c2a308d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "mobile",
														"path": "64a33a20-5699-482a-a387-f352f358ff37.png",
														"url": "https://staging-media.toyota.com.ar/64a33a20-5699-482a-a387-f352f358ff37.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "9acaea0d-42dd-48c0-a54f-f38db8303680.jpeg",
														"url": "https://staging-media.toyota.com.ar/9acaea0d-42dd-48c0-a54f-f38db8303680.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "4bc75a64-cae9-4078-8c0b-dd05ef0e8d50.jpeg",
														"url": "https://staging-media.toyota.com.ar/4bc75a64-cae9-4078-8c0b-dd05ef0e8d50.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 380620,
										"offers": null,
										"color": {
											"name": "Branco Pérola (070)",
											"code": "070",
											"rgb": "#D1D0CE"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "1cca8512-5585-4aca-988a-d03a329a3ff7.jpeg",
														"url": "https://staging-media.toyota.com.ar/1cca8512-5585-4aca-988a-d03a329a3ff7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "9fe997f8-1d26-4b91-980d-f68e2aac8262.jpeg",
														"url": "https://staging-media.toyota.com.ar/9fe997f8-1d26-4b91-980d-f68e2aac8262.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "014b44d9-d1f3-47a1-9eb5-d4cb0701f23a.jpeg",
														"url": "https://staging-media.toyota.com.ar/014b44d9-d1f3-47a1-9eb5-d4cb0701f23a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "00c2855c-8fe2-48ae-8473-8ac9d972e507.jpeg",
														"url": "https://staging-media.toyota.com.ar/00c2855c-8fe2-48ae-8473-8ac9d972e507.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "18bb5576-3eae-4949-87e1-f7ccdf06fa02.jpeg",
														"url": "https://staging-media.toyota.com.ar/18bb5576-3eae-4949-87e1-f7ccdf06fa02.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "bfa71b7b-7260-414a-8a9b-59858c1bf5d0.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfa71b7b-7260-414a-8a9b-59858c1bf5d0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "f3318081-d0be-4d7a-bc3b-c83b22c66269.jpeg",
														"url": "https://staging-media.toyota.com.ar/f3318081-d0be-4d7a-bc3b-c83b22c66269.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "aeda1e7b-3618-43a2-a1b7-d81849e5e8cf.jpeg",
														"url": "https://staging-media.toyota.com.ar/aeda1e7b-3618-43a2-a1b7-d81849e5e8cf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "9e7b4fa6-cb74-4a43-8067-199b26e8818a.jpeg",
														"url": "https://staging-media.toyota.com.ar/9e7b4fa6-cb74-4a43-8067-199b26e8818a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "fd322222-ad88-465b-9915-2956b8c53be3.png",
														"url": "https://staging-media.toyota.com.ar/fd322222-ad88-465b-9915-2956b8c53be3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "b86a2d79-cef7-41ec-9f86-d577e5ebe990.png",
														"url": "https://staging-media.toyota.com.ar/b86a2d79-cef7-41ec-9f86-d577e5ebe990.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"url": "https://staging-media.toyota.com.ar/553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "901c2c0a-6816-4996-a012-9c2e7f23dfd3.jpeg",
														"url": "https://staging-media.toyota.com.ar/901c2c0a-6816-4996-a012-9c2e7f23dfd3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "14e83ed3-4638-43dd-8350-111395784e49.jpeg",
														"url": "https://staging-media.toyota.com.ar/14e83ed3-4638-43dd-8350-111395784e49.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "desk",
														"path": "42f20c66-cfca-41da-a943-24269ae570ba.png",
														"url": "https://staging-media.toyota.com.ar/42f20c66-cfca-41da-a943-24269ae570ba.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "mobile",
														"path": "a205ec2a-8841-4a8b-a333-17714b9c764c.png",
														"url": "https://staging-media.toyota.com.ar/a205ec2a-8841-4a8b-a333-17714b9c764c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "5a955b4d-4271-44d1-bdc7-e7db6b74aea2.png",
														"url": "https://staging-media.toyota.com.ar/5a955b4d-4271-44d1-bdc7-e7db6b74aea2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "2bda909a-e629-455c-bc7d-2d956d138e1a.png",
														"url": "https://staging-media.toyota.com.ar/2bda909a-e629-455c-bc7d-2d956d138e1a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "45a90aed-24a2-44e8-b5cb-34efa6285dcd.png",
														"url": "https://staging-media.toyota.com.ar/45a90aed-24a2-44e8-b5cb-34efa6285dcd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "bf4b0ed3-486f-46ce-9c9d-cd4630235965.png",
														"url": "https://staging-media.toyota.com.ar/bf4b0ed3-486f-46ce-9c9d-cd4630235965.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "desk",
														"path": "c0ac9ad3-e8cd-435e-bd10-b2cc42967676.png",
														"url": "https://staging-media.toyota.com.ar/c0ac9ad3-e8cd-435e-bd10-b2cc42967676.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "mobile",
														"path": "b1a8312f-db20-4958-8ad9-cd0a8c89e672.png",
														"url": "https://staging-media.toyota.com.ar/b1a8312f-db20-4958-8ad9-cd0a8c89e672.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "desk",
														"path": "cf9d4846-b042-4c9b-85de-d71e3c2a308d.png",
														"url": "https://staging-media.toyota.com.ar/cf9d4846-b042-4c9b-85de-d71e3c2a308d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "mobile",
														"path": "64a33a20-5699-482a-a387-f352f358ff37.png",
														"url": "https://staging-media.toyota.com.ar/64a33a20-5699-482a-a387-f352f358ff37.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "9acaea0d-42dd-48c0-a54f-f38db8303680.jpeg",
														"url": "https://staging-media.toyota.com.ar/9acaea0d-42dd-48c0-a54f-f38db8303680.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "4bc75a64-cae9-4078-8c0b-dd05ef0e8d50.jpeg",
														"url": "https://staging-media.toyota.com.ar/4bc75a64-cae9-4078-8c0b-dd05ef0e8d50.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 374690,
										"offers": null,
										"color": {
											"name": "Prata Névoa (1D6)",
											"code": "1D6",
											"rgb": "#C5CDCB"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "SW4 - Mobile - SRX - 1D6 - Prata-Névoa trans",
														"path": "78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"url": "https://staging-media.toyota.com.ar/78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "SW4 - SRX - 1D6 - Prata-Névoa trans",
														"path": "f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"url": "https://staging-media.toyota.com.ar/f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - SRX - 1G3 - Cinza-Granito trans",
														"path": "0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"url": "https://staging-media.toyota.com.ar/0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - SRX - 1G3 - Cinza-Granito trans",
														"path": "36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"url": "https://staging-media.toyota.com.ar/36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - SRX - 218 - Preto-Attitude trans",
														"path": "ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"url": "https://staging-media.toyota.com.ar/ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2 SW4 - Mobile - SRX - 218 - Preto-Attitude trans",
														"path": "0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"url": "https://staging-media.toyota.com.ar/0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "SW4 - SRX - 4W9 - Marrom-Urban 2",
														"path": "90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"url": "https://staging-media.toyota.com.ar/90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "SW4 - Mobile - SRX - 4W9 - Marrom-Urban 2",
														"path": "7920763c-e450-4daa-b493-2708d7b2c783.png",
														"url": "https://staging-media.toyota.com.ar/7920763c-e450-4daa-b493-2708d7b2c783.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "SW4 - SRX - 089 - Branco-Lunar 2",
														"path": "aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"url": "https://staging-media.toyota.com.ar/aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "SW4 - Mobile - SRX - 089 - Branco-Lunar 2",
														"path": "461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"url": "https://staging-media.toyota.com.ar/461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"url": "https://staging-media.toyota.com.ar/553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "sw4 5 lugares ",
														"path": "597844cf-f156-43a8-98f3-c614c5ae6137.png",
														"url": "https://staging-media.toyota.com.ar/597844cf-f156-43a8-98f3-c614c5ae6137.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 374690,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "SW4 - Mobile - SRX - 1D6 - Prata-Névoa trans",
														"path": "78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"url": "https://staging-media.toyota.com.ar/78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "SW4 - SRX - 1D6 - Prata-Névoa trans",
														"path": "f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"url": "https://staging-media.toyota.com.ar/f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - SRX - 1G3 - Cinza-Granito trans",
														"path": "0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"url": "https://staging-media.toyota.com.ar/0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - SRX - 1G3 - Cinza-Granito trans",
														"path": "36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"url": "https://staging-media.toyota.com.ar/36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - SRX - 218 - Preto-Attitude trans",
														"path": "ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"url": "https://staging-media.toyota.com.ar/ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2 SW4 - Mobile - SRX - 218 - Preto-Attitude trans",
														"path": "0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"url": "https://staging-media.toyota.com.ar/0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "SW4 - SRX - 4W9 - Marrom-Urban 2",
														"path": "90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"url": "https://staging-media.toyota.com.ar/90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "SW4 - Mobile - SRX - 4W9 - Marrom-Urban 2",
														"path": "7920763c-e450-4daa-b493-2708d7b2c783.png",
														"url": "https://staging-media.toyota.com.ar/7920763c-e450-4daa-b493-2708d7b2c783.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "SW4 - SRX - 089 - Branco-Lunar 2",
														"path": "aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"url": "https://staging-media.toyota.com.ar/aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "SW4 - Mobile - SRX - 089 - Branco-Lunar 2",
														"path": "461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"url": "https://staging-media.toyota.com.ar/461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"url": "https://staging-media.toyota.com.ar/553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "sw4 5 lugares ",
														"path": "597844cf-f156-43a8-98f3-c614c5ae6137.png",
														"url": "https://staging-media.toyota.com.ar/597844cf-f156-43a8-98f3-c614c5ae6137.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 374690,
										"offers": null,
										"color": {
											"name": "Preto Attitude (218)",
											"code": "218",
											"rgb": "#343736"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "SW4 - Mobile - SRX - 1D6 - Prata-Névoa trans",
														"path": "78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"url": "https://staging-media.toyota.com.ar/78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "SW4 - SRX - 1D6 - Prata-Névoa trans",
														"path": "f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"url": "https://staging-media.toyota.com.ar/f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - SRX - 1G3 - Cinza-Granito trans",
														"path": "0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"url": "https://staging-media.toyota.com.ar/0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - SRX - 1G3 - Cinza-Granito trans",
														"path": "36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"url": "https://staging-media.toyota.com.ar/36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - SRX - 218 - Preto-Attitude trans",
														"path": "ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"url": "https://staging-media.toyota.com.ar/ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2 SW4 - Mobile - SRX - 218 - Preto-Attitude trans",
														"path": "0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"url": "https://staging-media.toyota.com.ar/0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "SW4 - SRX - 4W9 - Marrom-Urban 2",
														"path": "90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"url": "https://staging-media.toyota.com.ar/90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "SW4 - Mobile - SRX - 4W9 - Marrom-Urban 2",
														"path": "7920763c-e450-4daa-b493-2708d7b2c783.png",
														"url": "https://staging-media.toyota.com.ar/7920763c-e450-4daa-b493-2708d7b2c783.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "SW4 - SRX - 089 - Branco-Lunar 2",
														"path": "aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"url": "https://staging-media.toyota.com.ar/aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "SW4 - Mobile - SRX - 089 - Branco-Lunar 2",
														"path": "461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"url": "https://staging-media.toyota.com.ar/461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"url": "https://staging-media.toyota.com.ar/553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "sw4 5 lugares ",
														"path": "597844cf-f156-43a8-98f3-c614c5ae6137.png",
														"url": "https://staging-media.toyota.com.ar/597844cf-f156-43a8-98f3-c614c5ae6137.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 374690,
										"offers": null,
										"color": {
											"name": "Marrom Urban (4W9)",
											"code": "4W9",
											"rgb": "#472F29"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "SW4 - Mobile - SRX - 1D6 - Prata-Névoa trans",
														"path": "78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"url": "https://staging-media.toyota.com.ar/78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "SW4 - SRX - 1D6 - Prata-Névoa trans",
														"path": "f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"url": "https://staging-media.toyota.com.ar/f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - SRX - 1G3 - Cinza-Granito trans",
														"path": "0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"url": "https://staging-media.toyota.com.ar/0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - SRX - 1G3 - Cinza-Granito trans",
														"path": "36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"url": "https://staging-media.toyota.com.ar/36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - SRX - 218 - Preto-Attitude trans",
														"path": "ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"url": "https://staging-media.toyota.com.ar/ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2 SW4 - Mobile - SRX - 218 - Preto-Attitude trans",
														"path": "0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"url": "https://staging-media.toyota.com.ar/0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "SW4 - SRX - 4W9 - Marrom-Urban 2",
														"path": "90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"url": "https://staging-media.toyota.com.ar/90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "SW4 - Mobile - SRX - 4W9 - Marrom-Urban 2",
														"path": "7920763c-e450-4daa-b493-2708d7b2c783.png",
														"url": "https://staging-media.toyota.com.ar/7920763c-e450-4daa-b493-2708d7b2c783.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "SW4 - SRX - 089 - Branco-Lunar 2",
														"path": "aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"url": "https://staging-media.toyota.com.ar/aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "SW4 - Mobile - SRX - 089 - Branco-Lunar 2",
														"path": "461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"url": "https://staging-media.toyota.com.ar/461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"url": "https://staging-media.toyota.com.ar/553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "sw4 5 lugares ",
														"path": "597844cf-f156-43a8-98f3-c614c5ae6137.png",
														"url": "https://staging-media.toyota.com.ar/597844cf-f156-43a8-98f3-c614c5ae6137.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 377120,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "SW4 - Mobile - SRX - 1D6 - Prata-Névoa trans",
														"path": "78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"url": "https://staging-media.toyota.com.ar/78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "SW4 - SRX - 1D6 - Prata-Névoa trans",
														"path": "f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"url": "https://staging-media.toyota.com.ar/f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - SRX - 1G3 - Cinza-Granito trans",
														"path": "0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"url": "https://staging-media.toyota.com.ar/0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - SRX - 1G3 - Cinza-Granito trans",
														"path": "36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"url": "https://staging-media.toyota.com.ar/36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - SRX - 218 - Preto-Attitude trans",
														"path": "ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"url": "https://staging-media.toyota.com.ar/ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2 SW4 - Mobile - SRX - 218 - Preto-Attitude trans",
														"path": "0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"url": "https://staging-media.toyota.com.ar/0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "SW4 - SRX - 4W9 - Marrom-Urban 2",
														"path": "90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"url": "https://staging-media.toyota.com.ar/90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "SW4 - Mobile - SRX - 4W9 - Marrom-Urban 2",
														"path": "7920763c-e450-4daa-b493-2708d7b2c783.png",
														"url": "https://staging-media.toyota.com.ar/7920763c-e450-4daa-b493-2708d7b2c783.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "SW4 - SRX - 089 - Branco-Lunar 2",
														"path": "aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"url": "https://staging-media.toyota.com.ar/aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "SW4 - Mobile - SRX - 089 - Branco-Lunar 2",
														"path": "461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"url": "https://staging-media.toyota.com.ar/461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"url": "https://staging-media.toyota.com.ar/553dabd8-f344-4562-88ea-c7220fbfbe1c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "sw4 5 lugares ",
														"path": "597844cf-f156-43a8-98f3-c614c5ae6137.png",
														"url": "https://staging-media.toyota.com.ar/597844cf-f156-43a8-98f3-c614c5ae6137.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "SRX 7 Lugares",
						"id": "cb18aeea-5596-40e8-adb6-2d08cd467197",
						"order": 2,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 384690,
										"offers": null,
										"color": {
											"name": "Prata Névoa (1D6)",
											"code": "1D6",
											"rgb": "#C5CDCB"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"url": "https://staging-media.toyota.com.ar/89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"url": "https://staging-media.toyota.com.ar/038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"url": "https://staging-media.toyota.com.ar/17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"url": "https://staging-media.toyota.com.ar/30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"url": "https://staging-media.toyota.com.ar/67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "SW4 Logo",
														"path": "e9cf96b2-f5ef-42b5-a0ad-4ac5342b982f.png",
														"url": "https://staging-media.toyota.com.ar/e9cf96b2-f5ef-42b5-a0ad-4ac5342b982f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "622151da-f58d-4173-9ce6-56e02f6e250a.png",
														"url": "https://staging-media.toyota.com.ar/622151da-f58d-4173-9ce6-56e02f6e250a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse on hover",
														"path": "5dfd2c97-1518-47c2-8b25-459876240626.png",
														"url": "https://staging-media.toyota.com.ar/5dfd2c97-1518-47c2-8b25-459876240626.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "ad62d480-1009-4e83-9b88-63e6d778a78f.jpeg",
														"url": "https://staging-media.toyota.com.ar/ad62d480-1009-4e83-9b88-63e6d778a78f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "dd3d37ec-39d1-4335-a097-345795c79f5a.jpeg",
														"url": "https://staging-media.toyota.com.ar/dd3d37ec-39d1-4335-a097-345795c79f5a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "desk",
														"path": "5958b13b-de60-4683-a33e-1b7c3ffd2bd9.png",
														"url": "https://staging-media.toyota.com.ar/5958b13b-de60-4683-a33e-1b7c3ffd2bd9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "mobile",
														"path": "c24aaabf-0d25-4fa4-a3b1-d997af56b2ac.png",
														"url": "https://staging-media.toyota.com.ar/c24aaabf-0d25-4fa4-a3b1-d997af56b2ac.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "9e7f9245-3efe-4d2b-be9c-1b6896a38221.png",
														"url": "https://staging-media.toyota.com.ar/9e7f9245-3efe-4d2b-be9c-1b6896a38221.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "dee4eec0-5ed8-466c-a26b-275d7e3a8751.png",
														"url": "https://staging-media.toyota.com.ar/dee4eec0-5ed8-466c-a26b-275d7e3a8751.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "8e4f0830-8145-450a-8906-8c5bf25a1ac5.png",
														"url": "https://staging-media.toyota.com.ar/8e4f0830-8145-450a-8906-8c5bf25a1ac5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "952911af-961d-45b4-bd5d-1a0862a5042f.png",
														"url": "https://staging-media.toyota.com.ar/952911af-961d-45b4-bd5d-1a0862a5042f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "desk",
														"path": "3a392b3b-dfa0-4586-9412-afe262ac8149.png",
														"url": "https://staging-media.toyota.com.ar/3a392b3b-dfa0-4586-9412-afe262ac8149.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "mobile",
														"path": "26fc0eab-6b50-41fb-90d8-02d146d679b2.png",
														"url": "https://staging-media.toyota.com.ar/26fc0eab-6b50-41fb-90d8-02d146d679b2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "desk",
														"path": "4eb28522-6ba9-44f3-9b84-a2174105b6a0.png",
														"url": "https://staging-media.toyota.com.ar/4eb28522-6ba9-44f3-9b84-a2174105b6a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "mobile",
														"path": "8fdce779-aef2-46cb-8410-17219bbc75d0.png",
														"url": "https://staging-media.toyota.com.ar/8fdce779-aef2-46cb-8410-17219bbc75d0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"url": "https://staging-media.toyota.com.ar/00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"url": "https://staging-media.toyota.com.ar/6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"url": "https://staging-media.toyota.com.ar/ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"url": "https://staging-media.toyota.com.ar/ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 384690,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"url": "https://staging-media.toyota.com.ar/89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"url": "https://staging-media.toyota.com.ar/038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"url": "https://staging-media.toyota.com.ar/17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"url": "https://staging-media.toyota.com.ar/30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"url": "https://staging-media.toyota.com.ar/67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "SW4 Logo",
														"path": "e9cf96b2-f5ef-42b5-a0ad-4ac5342b982f.png",
														"url": "https://staging-media.toyota.com.ar/e9cf96b2-f5ef-42b5-a0ad-4ac5342b982f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "622151da-f58d-4173-9ce6-56e02f6e250a.png",
														"url": "https://staging-media.toyota.com.ar/622151da-f58d-4173-9ce6-56e02f6e250a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse on hover",
														"path": "5dfd2c97-1518-47c2-8b25-459876240626.png",
														"url": "https://staging-media.toyota.com.ar/5dfd2c97-1518-47c2-8b25-459876240626.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "ad62d480-1009-4e83-9b88-63e6d778a78f.jpeg",
														"url": "https://staging-media.toyota.com.ar/ad62d480-1009-4e83-9b88-63e6d778a78f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "dd3d37ec-39d1-4335-a097-345795c79f5a.jpeg",
														"url": "https://staging-media.toyota.com.ar/dd3d37ec-39d1-4335-a097-345795c79f5a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "desk",
														"path": "5958b13b-de60-4683-a33e-1b7c3ffd2bd9.png",
														"url": "https://staging-media.toyota.com.ar/5958b13b-de60-4683-a33e-1b7c3ffd2bd9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "mobile",
														"path": "c24aaabf-0d25-4fa4-a3b1-d997af56b2ac.png",
														"url": "https://staging-media.toyota.com.ar/c24aaabf-0d25-4fa4-a3b1-d997af56b2ac.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "9e7f9245-3efe-4d2b-be9c-1b6896a38221.png",
														"url": "https://staging-media.toyota.com.ar/9e7f9245-3efe-4d2b-be9c-1b6896a38221.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "dee4eec0-5ed8-466c-a26b-275d7e3a8751.png",
														"url": "https://staging-media.toyota.com.ar/dee4eec0-5ed8-466c-a26b-275d7e3a8751.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "8e4f0830-8145-450a-8906-8c5bf25a1ac5.png",
														"url": "https://staging-media.toyota.com.ar/8e4f0830-8145-450a-8906-8c5bf25a1ac5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "952911af-961d-45b4-bd5d-1a0862a5042f.png",
														"url": "https://staging-media.toyota.com.ar/952911af-961d-45b4-bd5d-1a0862a5042f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "desk",
														"path": "3a392b3b-dfa0-4586-9412-afe262ac8149.png",
														"url": "https://staging-media.toyota.com.ar/3a392b3b-dfa0-4586-9412-afe262ac8149.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "mobile",
														"path": "26fc0eab-6b50-41fb-90d8-02d146d679b2.png",
														"url": "https://staging-media.toyota.com.ar/26fc0eab-6b50-41fb-90d8-02d146d679b2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "desk",
														"path": "4eb28522-6ba9-44f3-9b84-a2174105b6a0.png",
														"url": "https://staging-media.toyota.com.ar/4eb28522-6ba9-44f3-9b84-a2174105b6a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "mobile",
														"path": "8fdce779-aef2-46cb-8410-17219bbc75d0.png",
														"url": "https://staging-media.toyota.com.ar/8fdce779-aef2-46cb-8410-17219bbc75d0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"url": "https://staging-media.toyota.com.ar/00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"url": "https://staging-media.toyota.com.ar/6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"url": "https://staging-media.toyota.com.ar/ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"url": "https://staging-media.toyota.com.ar/ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 384690,
										"offers": null,
										"color": {
											"name": "Preto Attitude (218)",
											"code": "218",
											"rgb": "#343736"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"url": "https://staging-media.toyota.com.ar/89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"url": "https://staging-media.toyota.com.ar/038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"url": "https://staging-media.toyota.com.ar/17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"url": "https://staging-media.toyota.com.ar/30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"url": "https://staging-media.toyota.com.ar/67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "SW4 Logo",
														"path": "e9cf96b2-f5ef-42b5-a0ad-4ac5342b982f.png",
														"url": "https://staging-media.toyota.com.ar/e9cf96b2-f5ef-42b5-a0ad-4ac5342b982f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "622151da-f58d-4173-9ce6-56e02f6e250a.png",
														"url": "https://staging-media.toyota.com.ar/622151da-f58d-4173-9ce6-56e02f6e250a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse on hover",
														"path": "5dfd2c97-1518-47c2-8b25-459876240626.png",
														"url": "https://staging-media.toyota.com.ar/5dfd2c97-1518-47c2-8b25-459876240626.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "ad62d480-1009-4e83-9b88-63e6d778a78f.jpeg",
														"url": "https://staging-media.toyota.com.ar/ad62d480-1009-4e83-9b88-63e6d778a78f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "dd3d37ec-39d1-4335-a097-345795c79f5a.jpeg",
														"url": "https://staging-media.toyota.com.ar/dd3d37ec-39d1-4335-a097-345795c79f5a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "desk",
														"path": "5958b13b-de60-4683-a33e-1b7c3ffd2bd9.png",
														"url": "https://staging-media.toyota.com.ar/5958b13b-de60-4683-a33e-1b7c3ffd2bd9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "mobile",
														"path": "c24aaabf-0d25-4fa4-a3b1-d997af56b2ac.png",
														"url": "https://staging-media.toyota.com.ar/c24aaabf-0d25-4fa4-a3b1-d997af56b2ac.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "9e7f9245-3efe-4d2b-be9c-1b6896a38221.png",
														"url": "https://staging-media.toyota.com.ar/9e7f9245-3efe-4d2b-be9c-1b6896a38221.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "dee4eec0-5ed8-466c-a26b-275d7e3a8751.png",
														"url": "https://staging-media.toyota.com.ar/dee4eec0-5ed8-466c-a26b-275d7e3a8751.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "8e4f0830-8145-450a-8906-8c5bf25a1ac5.png",
														"url": "https://staging-media.toyota.com.ar/8e4f0830-8145-450a-8906-8c5bf25a1ac5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "952911af-961d-45b4-bd5d-1a0862a5042f.png",
														"url": "https://staging-media.toyota.com.ar/952911af-961d-45b4-bd5d-1a0862a5042f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "desk",
														"path": "3a392b3b-dfa0-4586-9412-afe262ac8149.png",
														"url": "https://staging-media.toyota.com.ar/3a392b3b-dfa0-4586-9412-afe262ac8149.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "mobile",
														"path": "26fc0eab-6b50-41fb-90d8-02d146d679b2.png",
														"url": "https://staging-media.toyota.com.ar/26fc0eab-6b50-41fb-90d8-02d146d679b2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "desk",
														"path": "4eb28522-6ba9-44f3-9b84-a2174105b6a0.png",
														"url": "https://staging-media.toyota.com.ar/4eb28522-6ba9-44f3-9b84-a2174105b6a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "mobile",
														"path": "8fdce779-aef2-46cb-8410-17219bbc75d0.png",
														"url": "https://staging-media.toyota.com.ar/8fdce779-aef2-46cb-8410-17219bbc75d0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"url": "https://staging-media.toyota.com.ar/00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"url": "https://staging-media.toyota.com.ar/6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"url": "https://staging-media.toyota.com.ar/ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"url": "https://staging-media.toyota.com.ar/ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 384690,
										"offers": null,
										"color": {
											"name": "Marrom Urban (4W9)",
											"code": "4W9",
											"rgb": "#472F29"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"url": "https://staging-media.toyota.com.ar/89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"url": "https://staging-media.toyota.com.ar/038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"url": "https://staging-media.toyota.com.ar/17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"url": "https://staging-media.toyota.com.ar/30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"url": "https://staging-media.toyota.com.ar/67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "SW4 Logo",
														"path": "e9cf96b2-f5ef-42b5-a0ad-4ac5342b982f.png",
														"url": "https://staging-media.toyota.com.ar/e9cf96b2-f5ef-42b5-a0ad-4ac5342b982f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "622151da-f58d-4173-9ce6-56e02f6e250a.png",
														"url": "https://staging-media.toyota.com.ar/622151da-f58d-4173-9ce6-56e02f6e250a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse on hover",
														"path": "5dfd2c97-1518-47c2-8b25-459876240626.png",
														"url": "https://staging-media.toyota.com.ar/5dfd2c97-1518-47c2-8b25-459876240626.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "ad62d480-1009-4e83-9b88-63e6d778a78f.jpeg",
														"url": "https://staging-media.toyota.com.ar/ad62d480-1009-4e83-9b88-63e6d778a78f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "dd3d37ec-39d1-4335-a097-345795c79f5a.jpeg",
														"url": "https://staging-media.toyota.com.ar/dd3d37ec-39d1-4335-a097-345795c79f5a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "desk",
														"path": "5958b13b-de60-4683-a33e-1b7c3ffd2bd9.png",
														"url": "https://staging-media.toyota.com.ar/5958b13b-de60-4683-a33e-1b7c3ffd2bd9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "mobile",
														"path": "c24aaabf-0d25-4fa4-a3b1-d997af56b2ac.png",
														"url": "https://staging-media.toyota.com.ar/c24aaabf-0d25-4fa4-a3b1-d997af56b2ac.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "9e7f9245-3efe-4d2b-be9c-1b6896a38221.png",
														"url": "https://staging-media.toyota.com.ar/9e7f9245-3efe-4d2b-be9c-1b6896a38221.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "dee4eec0-5ed8-466c-a26b-275d7e3a8751.png",
														"url": "https://staging-media.toyota.com.ar/dee4eec0-5ed8-466c-a26b-275d7e3a8751.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "8e4f0830-8145-450a-8906-8c5bf25a1ac5.png",
														"url": "https://staging-media.toyota.com.ar/8e4f0830-8145-450a-8906-8c5bf25a1ac5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "952911af-961d-45b4-bd5d-1a0862a5042f.png",
														"url": "https://staging-media.toyota.com.ar/952911af-961d-45b4-bd5d-1a0862a5042f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "desk",
														"path": "3a392b3b-dfa0-4586-9412-afe262ac8149.png",
														"url": "https://staging-media.toyota.com.ar/3a392b3b-dfa0-4586-9412-afe262ac8149.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "mobile",
														"path": "26fc0eab-6b50-41fb-90d8-02d146d679b2.png",
														"url": "https://staging-media.toyota.com.ar/26fc0eab-6b50-41fb-90d8-02d146d679b2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "desk",
														"path": "4eb28522-6ba9-44f3-9b84-a2174105b6a0.png",
														"url": "https://staging-media.toyota.com.ar/4eb28522-6ba9-44f3-9b84-a2174105b6a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "mobile",
														"path": "8fdce779-aef2-46cb-8410-17219bbc75d0.png",
														"url": "https://staging-media.toyota.com.ar/8fdce779-aef2-46cb-8410-17219bbc75d0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"url": "https://staging-media.toyota.com.ar/00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"url": "https://staging-media.toyota.com.ar/6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"url": "https://staging-media.toyota.com.ar/ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"url": "https://staging-media.toyota.com.ar/ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 387120,
										"offers": null,
										"color": {
											"name": "Branco Pérola (070)",
											"code": "070",
											"rgb": "#D1D0CE"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"url": "https://staging-media.toyota.com.ar/89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"url": "https://staging-media.toyota.com.ar/038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"url": "https://staging-media.toyota.com.ar/17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"url": "https://staging-media.toyota.com.ar/30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"url": "https://staging-media.toyota.com.ar/67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "SW4 Logo",
														"path": "e9cf96b2-f5ef-42b5-a0ad-4ac5342b982f.png",
														"url": "https://staging-media.toyota.com.ar/e9cf96b2-f5ef-42b5-a0ad-4ac5342b982f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "622151da-f58d-4173-9ce6-56e02f6e250a.png",
														"url": "https://staging-media.toyota.com.ar/622151da-f58d-4173-9ce6-56e02f6e250a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse on hover",
														"path": "5dfd2c97-1518-47c2-8b25-459876240626.png",
														"url": "https://staging-media.toyota.com.ar/5dfd2c97-1518-47c2-8b25-459876240626.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "ad62d480-1009-4e83-9b88-63e6d778a78f.jpeg",
														"url": "https://staging-media.toyota.com.ar/ad62d480-1009-4e83-9b88-63e6d778a78f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "dd3d37ec-39d1-4335-a097-345795c79f5a.jpeg",
														"url": "https://staging-media.toyota.com.ar/dd3d37ec-39d1-4335-a097-345795c79f5a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "desk",
														"path": "5958b13b-de60-4683-a33e-1b7c3ffd2bd9.png",
														"url": "https://staging-media.toyota.com.ar/5958b13b-de60-4683-a33e-1b7c3ffd2bd9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "mobile",
														"path": "c24aaabf-0d25-4fa4-a3b1-d997af56b2ac.png",
														"url": "https://staging-media.toyota.com.ar/c24aaabf-0d25-4fa4-a3b1-d997af56b2ac.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "9e7f9245-3efe-4d2b-be9c-1b6896a38221.png",
														"url": "https://staging-media.toyota.com.ar/9e7f9245-3efe-4d2b-be9c-1b6896a38221.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "dee4eec0-5ed8-466c-a26b-275d7e3a8751.png",
														"url": "https://staging-media.toyota.com.ar/dee4eec0-5ed8-466c-a26b-275d7e3a8751.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "8e4f0830-8145-450a-8906-8c5bf25a1ac5.png",
														"url": "https://staging-media.toyota.com.ar/8e4f0830-8145-450a-8906-8c5bf25a1ac5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "952911af-961d-45b4-bd5d-1a0862a5042f.png",
														"url": "https://staging-media.toyota.com.ar/952911af-961d-45b4-bd5d-1a0862a5042f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "desk",
														"path": "3a392b3b-dfa0-4586-9412-afe262ac8149.png",
														"url": "https://staging-media.toyota.com.ar/3a392b3b-dfa0-4586-9412-afe262ac8149.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "mobile",
														"path": "26fc0eab-6b50-41fb-90d8-02d146d679b2.png",
														"url": "https://staging-media.toyota.com.ar/26fc0eab-6b50-41fb-90d8-02d146d679b2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "desk",
														"path": "4eb28522-6ba9-44f3-9b84-a2174105b6a0.png",
														"url": "https://staging-media.toyota.com.ar/4eb28522-6ba9-44f3-9b84-a2174105b6a0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "mobile",
														"path": "8fdce779-aef2-46cb-8410-17219bbc75d0.png",
														"url": "https://staging-media.toyota.com.ar/8fdce779-aef2-46cb-8410-17219bbc75d0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"url": "https://staging-media.toyota.com.ar/00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"url": "https://staging-media.toyota.com.ar/6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"url": "https://staging-media.toyota.com.ar/ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"url": "https://staging-media.toyota.com.ar/ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 381190,
										"offers": null,
										"color": {
											"name": "Prata Névoa (1D6)",
											"code": "1D6",
											"rgb": "#C5CDCB"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "SW4 - SRX - 1D6 - Prata-Névoa trans",
														"path": "f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"url": "https://staging-media.toyota.com.ar/f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "SW4 - Mobile - SRX - 1D6 - Prata-Névoa trans",
														"path": "78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"url": "https://staging-media.toyota.com.ar/78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - SRX - 1G3 - Cinza-Granito trans",
														"path": "0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"url": "https://staging-media.toyota.com.ar/0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - SRX - 1G3 - Cinza-Granito trans",
														"path": "36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"url": "https://staging-media.toyota.com.ar/36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - SRX - 218 - Preto-Attitude trans",
														"path": "ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"url": "https://staging-media.toyota.com.ar/ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2 SW4 - Mobile - SRX - 218 - Preto-Attitude trans",
														"path": "0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"url": "https://staging-media.toyota.com.ar/0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "SW4 - SRX - 4W9 - Marrom-Urban 2",
														"path": "90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"url": "https://staging-media.toyota.com.ar/90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "SW4 - Mobile - SRX - 4W9 - Marrom-Urban 2",
														"path": "7920763c-e450-4daa-b493-2708d7b2c783.png",
														"url": "https://staging-media.toyota.com.ar/7920763c-e450-4daa-b493-2708d7b2c783.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "SW4 - SRX - 089 - Branco-Lunar 2",
														"path": "aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"url": "https://staging-media.toyota.com.ar/aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "SW4 - Mobile - SRX - 089 - Branco-Lunar 2",
														"path": "461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"url": "https://staging-media.toyota.com.ar/461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "SRX 7 Lugares - 2022",
														"path": "a3365092-1181-4a12-aec4-a213ee377152.png",
														"url": "https://staging-media.toyota.com.ar/a3365092-1181-4a12-aec4-a213ee377152.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "SRX 7 Lugares - 2022",
														"path": "a30dfcba-c2d4-4f61-b3a6-bdc73ca6adfe.png",
														"url": "https://staging-media.toyota.com.ar/a30dfcba-c2d4-4f61-b3a6-bdc73ca6adfe.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 381190,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "SW4 - SRX - 1D6 - Prata-Névoa trans",
														"path": "f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"url": "https://staging-media.toyota.com.ar/f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "SW4 - Mobile - SRX - 1D6 - Prata-Névoa trans",
														"path": "78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"url": "https://staging-media.toyota.com.ar/78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - SRX - 1G3 - Cinza-Granito trans",
														"path": "0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"url": "https://staging-media.toyota.com.ar/0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - SRX - 1G3 - Cinza-Granito trans",
														"path": "36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"url": "https://staging-media.toyota.com.ar/36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - SRX - 218 - Preto-Attitude trans",
														"path": "ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"url": "https://staging-media.toyota.com.ar/ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2 SW4 - Mobile - SRX - 218 - Preto-Attitude trans",
														"path": "0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"url": "https://staging-media.toyota.com.ar/0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "SW4 - SRX - 4W9 - Marrom-Urban 2",
														"path": "90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"url": "https://staging-media.toyota.com.ar/90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "SW4 - Mobile - SRX - 4W9 - Marrom-Urban 2",
														"path": "7920763c-e450-4daa-b493-2708d7b2c783.png",
														"url": "https://staging-media.toyota.com.ar/7920763c-e450-4daa-b493-2708d7b2c783.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "SW4 - SRX - 089 - Branco-Lunar 2",
														"path": "aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"url": "https://staging-media.toyota.com.ar/aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "SW4 - Mobile - SRX - 089 - Branco-Lunar 2",
														"path": "461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"url": "https://staging-media.toyota.com.ar/461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "SRX 7 Lugares - 2022",
														"path": "a3365092-1181-4a12-aec4-a213ee377152.png",
														"url": "https://staging-media.toyota.com.ar/a3365092-1181-4a12-aec4-a213ee377152.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "SRX 7 Lugares - 2022",
														"path": "a30dfcba-c2d4-4f61-b3a6-bdc73ca6adfe.png",
														"url": "https://staging-media.toyota.com.ar/a30dfcba-c2d4-4f61-b3a6-bdc73ca6adfe.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 381190,
										"offers": null,
										"color": {
											"name": "Preto Attitude (218)",
											"code": "218",
											"rgb": "#343736"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "SW4 - SRX - 1D6 - Prata-Névoa trans",
														"path": "f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"url": "https://staging-media.toyota.com.ar/f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "SW4 - Mobile - SRX - 1D6 - Prata-Névoa trans",
														"path": "78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"url": "https://staging-media.toyota.com.ar/78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - SRX - 1G3 - Cinza-Granito trans",
														"path": "0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"url": "https://staging-media.toyota.com.ar/0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - SRX - 1G3 - Cinza-Granito trans",
														"path": "36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"url": "https://staging-media.toyota.com.ar/36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - SRX - 218 - Preto-Attitude trans",
														"path": "ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"url": "https://staging-media.toyota.com.ar/ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2 SW4 - Mobile - SRX - 218 - Preto-Attitude trans",
														"path": "0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"url": "https://staging-media.toyota.com.ar/0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "SW4 - SRX - 4W9 - Marrom-Urban 2",
														"path": "90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"url": "https://staging-media.toyota.com.ar/90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "SW4 - Mobile - SRX - 4W9 - Marrom-Urban 2",
														"path": "7920763c-e450-4daa-b493-2708d7b2c783.png",
														"url": "https://staging-media.toyota.com.ar/7920763c-e450-4daa-b493-2708d7b2c783.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "SW4 - SRX - 089 - Branco-Lunar 2",
														"path": "aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"url": "https://staging-media.toyota.com.ar/aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "SW4 - Mobile - SRX - 089 - Branco-Lunar 2",
														"path": "461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"url": "https://staging-media.toyota.com.ar/461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "SRX 7 Lugares - 2022",
														"path": "a3365092-1181-4a12-aec4-a213ee377152.png",
														"url": "https://staging-media.toyota.com.ar/a3365092-1181-4a12-aec4-a213ee377152.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "SRX 7 Lugares - 2022",
														"path": "a30dfcba-c2d4-4f61-b3a6-bdc73ca6adfe.png",
														"url": "https://staging-media.toyota.com.ar/a30dfcba-c2d4-4f61-b3a6-bdc73ca6adfe.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 381190,
										"offers": null,
										"color": {
											"name": "Marrom Urban (4W9)",
											"code": "4W9",
											"rgb": "#472F29"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "SW4 - SRX - 1D6 - Prata-Névoa trans",
														"path": "f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"url": "https://staging-media.toyota.com.ar/f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "SW4 - Mobile - SRX - 1D6 - Prata-Névoa trans",
														"path": "78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"url": "https://staging-media.toyota.com.ar/78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - SRX - 1G3 - Cinza-Granito trans",
														"path": "0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"url": "https://staging-media.toyota.com.ar/0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - SRX - 1G3 - Cinza-Granito trans",
														"path": "36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"url": "https://staging-media.toyota.com.ar/36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - SRX - 218 - Preto-Attitude trans",
														"path": "ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"url": "https://staging-media.toyota.com.ar/ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2 SW4 - Mobile - SRX - 218 - Preto-Attitude trans",
														"path": "0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"url": "https://staging-media.toyota.com.ar/0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "SW4 - SRX - 4W9 - Marrom-Urban 2",
														"path": "90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"url": "https://staging-media.toyota.com.ar/90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "SW4 - Mobile - SRX - 4W9 - Marrom-Urban 2",
														"path": "7920763c-e450-4daa-b493-2708d7b2c783.png",
														"url": "https://staging-media.toyota.com.ar/7920763c-e450-4daa-b493-2708d7b2c783.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "SW4 - SRX - 089 - Branco-Lunar 2",
														"path": "aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"url": "https://staging-media.toyota.com.ar/aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "SW4 - Mobile - SRX - 089 - Branco-Lunar 2",
														"path": "461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"url": "https://staging-media.toyota.com.ar/461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "SRX 7 Lugares - 2022",
														"path": "a3365092-1181-4a12-aec4-a213ee377152.png",
														"url": "https://staging-media.toyota.com.ar/a3365092-1181-4a12-aec4-a213ee377152.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "SRX 7 Lugares - 2022",
														"path": "a30dfcba-c2d4-4f61-b3a6-bdc73ca6adfe.png",
														"url": "https://staging-media.toyota.com.ar/a30dfcba-c2d4-4f61-b3a6-bdc73ca6adfe.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 383620,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "SW4 - SRX - 1D6 - Prata-Névoa trans",
														"path": "f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"url": "https://staging-media.toyota.com.ar/f7dce182-6ecb-4ec3-8c20-c578064ff39d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "SW4 - Mobile - SRX - 1D6 - Prata-Névoa trans",
														"path": "78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"url": "https://staging-media.toyota.com.ar/78f485da-b1d5-4bdf-aa59-3411c0428afc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - SRX - 1G3 - Cinza-Granito trans",
														"path": "0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"url": "https://staging-media.toyota.com.ar/0667a5b3-80c8-492f-b920-93f7eab2e1c4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - SRX - 1G3 - Cinza-Granito trans",
														"path": "36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"url": "https://staging-media.toyota.com.ar/36c320e5-2e85-4cfd-87bb-c48f4005fb60.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - SRX - 218 - Preto-Attitude trans",
														"path": "ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"url": "https://staging-media.toyota.com.ar/ff08c24a-a7f8-44df-80bd-e9befd181835.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2 SW4 - Mobile - SRX - 218 - Preto-Attitude trans",
														"path": "0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"url": "https://staging-media.toyota.com.ar/0e86b42b-b012-4be9-865a-f993957eaccb.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:4W9",
													"media": {
														"title": "SW4 - SRX - 4W9 - Marrom-Urban 2",
														"path": "90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"url": "https://staging-media.toyota.com.ar/90d955cf-1e2f-40f3-aa0c-dec207dd9623.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:4W9",
													"media": {
														"title": "SW4 - Mobile - SRX - 4W9 - Marrom-Urban 2",
														"path": "7920763c-e450-4daa-b493-2708d7b2c783.png",
														"url": "https://staging-media.toyota.com.ar/7920763c-e450-4daa-b493-2708d7b2c783.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "SW4 - SRX - 089 - Branco-Lunar 2",
														"path": "aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"url": "https://staging-media.toyota.com.ar/aecd4938-4e68-45ea-8141-4ce60135c731.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "SW4 - Mobile - SRX - 089 - Branco-Lunar 2",
														"path": "461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"url": "https://staging-media.toyota.com.ar/461fa6e2-377b-4253-a8a4-0440a2727d18.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "SRX 7 Lugares - 2022",
														"path": "a3365092-1181-4a12-aec4-a213ee377152.png",
														"url": "https://staging-media.toyota.com.ar/a3365092-1181-4a12-aec4-a213ee377152.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "SRX 7 Lugares - 2022",
														"path": "a30dfcba-c2d4-4f61-b3a6-bdc73ca6adfe.png",
														"url": "https://staging-media.toyota.com.ar/a30dfcba-c2d4-4f61-b3a6-bdc73ca6adfe.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "Diamond",
						"id": "a0fabc66-1dbd-41c8-bb80-173bac47c77f",
						"order": 3,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 420090,
										"offers": null,
										"color": {
											"name": "Preto Attitude (218)",
											"code": "218",
											"rgb": "#343736"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - Diamond - 218 - Preto-Attitude trans",
														"path": "bd6cdfa1-9588-45c5-8212-d31d5e800f2a.png",
														"url": "https://staging-media.toyota.com.ar/bd6cdfa1-9588-45c5-8212-d31d5e800f2a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "SW4 - Mobile - Diamond - 218 - Preto-Attitude trans",
														"path": "1edb782e-cd37-4742-9fe5-2bc3871328fc.png",
														"url": "https://staging-media.toyota.com.ar/1edb782e-cd37-4742-9fe5-2bc3871328fc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - Diamond - 1G3 - Cinza-Granito trans",
														"path": "0b4014b3-e154-42c1-b629-3f2ba0919cfa.png",
														"url": "https://staging-media.toyota.com.ar/0b4014b3-e154-42c1-b629-3f2ba0919cfa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - Diamond - 1G3 - Cinza-Granito trans",
														"path": "c4be82f7-a414-44b8-b77d-be658dfa58a7.png",
														"url": "https://staging-media.toyota.com.ar/c4be82f7-a414-44b8-b77d-be658dfa58a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PS",
													"media": {
														"title": "SW4 - Diamond - 2PS - Branco-Lunar-e-Preto-Attitude trans",
														"path": "cc209bcb-d6ce-4b13-9d1d-ce311af6e22a.png",
														"url": "https://staging-media.toyota.com.ar/cc209bcb-d6ce-4b13-9d1d-ce311af6e22a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PS",
													"media": {
														"title": "SW4 - Mobile - Diamond - 2PS - Branco-Lunar-e-Preto-Attitude trans",
														"path": "5a629d98-fcf8-4fd6-914a-b26961d65fd7.png",
														"url": "https://staging-media.toyota.com.ar/5a629d98-fcf8-4fd6-914a-b26961d65fd7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "SW4 - Diamond - 2QY - Prata-Névoa-e-Preto-Attitude trans",
														"path": "a77b8105-c602-460e-af1e-78612e76b7d4.png",
														"url": "https://staging-media.toyota.com.ar/a77b8105-c602-460e-af1e-78612e76b7d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "SW4 - Mobile - Diamond - 2QY - Prata-Névoa-e-Preto-Attitude trans",
														"path": "d7f8525b-eb4e-40a8-bcaa-302cd6233bff.png",
														"url": "https://staging-media.toyota.com.ar/d7f8525b-eb4e-40a8-bcaa-302cd6233bff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "c0781fae-d385-432b-96aa-9b78bc4173e9.jpeg",
														"url": "https://staging-media.toyota.com.ar/c0781fae-d385-432b-96aa-9b78bc4173e9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "0e998fa5-2a93-4861-84d2-4a6212bf28ba.jpeg",
														"url": "https://staging-media.toyota.com.ar/0e998fa5-2a93-4861-84d2-4a6212bf28ba.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "a544329c-bb6d-416d-8ea8-3699cf9e7e9b.jpeg",
														"url": "https://staging-media.toyota.com.ar/a544329c-bb6d-416d-8ea8-3699cf9e7e9b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "bfe9ec96-e988-49cb-adbc-73baf0c14334.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfe9ec96-e988-49cb-adbc-73baf0c14334.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "34e57a76-1429-4a0e-8849-dc10a4ce75d8.jpeg",
														"url": "https://staging-media.toyota.com.ar/34e57a76-1429-4a0e-8849-dc10a4ce75d8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "d9d36d31-6f16-40f0-b415-5fb996ab0459.jpeg",
														"url": "https://staging-media.toyota.com.ar/d9d36d31-6f16-40f0-b415-5fb996ab0459.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "30de35cd-ae69-4027-afe7-6723b599a9c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/30de35cd-ae69-4027-afe7-6723b599a9c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "24b79d79-1336-4c98-9d30-751abe45705f.jpeg",
														"url": "https://staging-media.toyota.com.ar/24b79d79-1336-4c98-9d30-751abe45705f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "b5b791d0-1eec-4466-805f-8714f76e2822.jpeg",
														"url": "https://staging-media.toyota.com.ar/b5b791d0-1eec-4466-805f-8714f76e2822.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "886360cc-212c-4d58-8156-f8d0255f5b1b.jpeg",
														"url": "https://staging-media.toyota.com.ar/886360cc-212c-4d58-8156-f8d0255f5b1b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "40c15f3d-d2cf-4889-be64-1e4c11e87aaf.jpeg",
														"url": "https://staging-media.toyota.com.ar/40c15f3d-d2cf-4889-be64-1e4c11e87aaf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "65935e7a-d321-4b5f-bd9a-4ce784948892.jpeg",
														"url": "https://staging-media.toyota.com.ar/65935e7a-d321-4b5f-bd9a-4ce784948892.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "03e0938a-2295-4fe5-b8e2-2ac977dfb204.jpeg",
														"url": "https://staging-media.toyota.com.ar/03e0938a-2295-4fe5-b8e2-2ac977dfb204.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "e24c2426-5aba-42c8-9eac-16f2a4cdc227.jpeg",
														"url": "https://staging-media.toyota.com.ar/e24c2426-5aba-42c8-9eac-16f2a4cdc227.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "29280412-1436-469b-ad51-e065e144a8b8.jpeg",
														"url": "https://staging-media.toyota.com.ar/29280412-1436-469b-ad51-e065e144a8b8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "f6d4953a-b3ea-46fd-870f-78b21440acbf.jpeg",
														"url": "https://staging-media.toyota.com.ar/f6d4953a-b3ea-46fd-870f-78b21440acbf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "70b00e92-8498-4722-853c-34a16b7b886f.png",
														"url": "https://staging-media.toyota.com.ar/70b00e92-8498-4722-853c-34a16b7b886f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "8c0c53ef-fc22-4362-a3be-bf78104de533.png",
														"url": "https://staging-media.toyota.com.ar/8c0c53ef-fc22-4362-a3be-bf78104de533.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "cross",
														"path": "49894d5d-2cda-4806-9296-45b87f467c62.jpeg",
														"url": "https://staging-media.toyota.com.br/49894d5d-2cda-4806-9296-45b87f467c62.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "sw4",
														"path": "ce9c24f9-c436-4101-ac7a-d5f26b87c13b.jpeg",
														"url": "https://staging-media.toyota.com.br/ce9c24f9-c436-4101-ac7a-d5f26b87c13b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 420090,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - Diamond - 218 - Preto-Attitude trans",
														"path": "bd6cdfa1-9588-45c5-8212-d31d5e800f2a.png",
														"url": "https://staging-media.toyota.com.ar/bd6cdfa1-9588-45c5-8212-d31d5e800f2a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "SW4 - Mobile - Diamond - 218 - Preto-Attitude trans",
														"path": "1edb782e-cd37-4742-9fe5-2bc3871328fc.png",
														"url": "https://staging-media.toyota.com.ar/1edb782e-cd37-4742-9fe5-2bc3871328fc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - Diamond - 1G3 - Cinza-Granito trans",
														"path": "0b4014b3-e154-42c1-b629-3f2ba0919cfa.png",
														"url": "https://staging-media.toyota.com.ar/0b4014b3-e154-42c1-b629-3f2ba0919cfa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - Diamond - 1G3 - Cinza-Granito trans",
														"path": "c4be82f7-a414-44b8-b77d-be658dfa58a7.png",
														"url": "https://staging-media.toyota.com.ar/c4be82f7-a414-44b8-b77d-be658dfa58a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PS",
													"media": {
														"title": "SW4 - Diamond - 2PS - Branco-Lunar-e-Preto-Attitude trans",
														"path": "cc209bcb-d6ce-4b13-9d1d-ce311af6e22a.png",
														"url": "https://staging-media.toyota.com.ar/cc209bcb-d6ce-4b13-9d1d-ce311af6e22a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PS",
													"media": {
														"title": "SW4 - Mobile - Diamond - 2PS - Branco-Lunar-e-Preto-Attitude trans",
														"path": "5a629d98-fcf8-4fd6-914a-b26961d65fd7.png",
														"url": "https://staging-media.toyota.com.ar/5a629d98-fcf8-4fd6-914a-b26961d65fd7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "SW4 - Diamond - 2QY - Prata-Névoa-e-Preto-Attitude trans",
														"path": "a77b8105-c602-460e-af1e-78612e76b7d4.png",
														"url": "https://staging-media.toyota.com.ar/a77b8105-c602-460e-af1e-78612e76b7d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "SW4 - Mobile - Diamond - 2QY - Prata-Névoa-e-Preto-Attitude trans",
														"path": "d7f8525b-eb4e-40a8-bcaa-302cd6233bff.png",
														"url": "https://staging-media.toyota.com.ar/d7f8525b-eb4e-40a8-bcaa-302cd6233bff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "c0781fae-d385-432b-96aa-9b78bc4173e9.jpeg",
														"url": "https://staging-media.toyota.com.ar/c0781fae-d385-432b-96aa-9b78bc4173e9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "0e998fa5-2a93-4861-84d2-4a6212bf28ba.jpeg",
														"url": "https://staging-media.toyota.com.ar/0e998fa5-2a93-4861-84d2-4a6212bf28ba.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "a544329c-bb6d-416d-8ea8-3699cf9e7e9b.jpeg",
														"url": "https://staging-media.toyota.com.ar/a544329c-bb6d-416d-8ea8-3699cf9e7e9b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "bfe9ec96-e988-49cb-adbc-73baf0c14334.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfe9ec96-e988-49cb-adbc-73baf0c14334.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "34e57a76-1429-4a0e-8849-dc10a4ce75d8.jpeg",
														"url": "https://staging-media.toyota.com.ar/34e57a76-1429-4a0e-8849-dc10a4ce75d8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "d9d36d31-6f16-40f0-b415-5fb996ab0459.jpeg",
														"url": "https://staging-media.toyota.com.ar/d9d36d31-6f16-40f0-b415-5fb996ab0459.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "30de35cd-ae69-4027-afe7-6723b599a9c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/30de35cd-ae69-4027-afe7-6723b599a9c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "24b79d79-1336-4c98-9d30-751abe45705f.jpeg",
														"url": "https://staging-media.toyota.com.ar/24b79d79-1336-4c98-9d30-751abe45705f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "b5b791d0-1eec-4466-805f-8714f76e2822.jpeg",
														"url": "https://staging-media.toyota.com.ar/b5b791d0-1eec-4466-805f-8714f76e2822.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "886360cc-212c-4d58-8156-f8d0255f5b1b.jpeg",
														"url": "https://staging-media.toyota.com.ar/886360cc-212c-4d58-8156-f8d0255f5b1b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "40c15f3d-d2cf-4889-be64-1e4c11e87aaf.jpeg",
														"url": "https://staging-media.toyota.com.ar/40c15f3d-d2cf-4889-be64-1e4c11e87aaf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "65935e7a-d321-4b5f-bd9a-4ce784948892.jpeg",
														"url": "https://staging-media.toyota.com.ar/65935e7a-d321-4b5f-bd9a-4ce784948892.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "03e0938a-2295-4fe5-b8e2-2ac977dfb204.jpeg",
														"url": "https://staging-media.toyota.com.ar/03e0938a-2295-4fe5-b8e2-2ac977dfb204.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "e24c2426-5aba-42c8-9eac-16f2a4cdc227.jpeg",
														"url": "https://staging-media.toyota.com.ar/e24c2426-5aba-42c8-9eac-16f2a4cdc227.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "29280412-1436-469b-ad51-e065e144a8b8.jpeg",
														"url": "https://staging-media.toyota.com.ar/29280412-1436-469b-ad51-e065e144a8b8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "f6d4953a-b3ea-46fd-870f-78b21440acbf.jpeg",
														"url": "https://staging-media.toyota.com.ar/f6d4953a-b3ea-46fd-870f-78b21440acbf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "70b00e92-8498-4722-853c-34a16b7b886f.png",
														"url": "https://staging-media.toyota.com.ar/70b00e92-8498-4722-853c-34a16b7b886f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "8c0c53ef-fc22-4362-a3be-bf78104de533.png",
														"url": "https://staging-media.toyota.com.ar/8c0c53ef-fc22-4362-a3be-bf78104de533.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "cross",
														"path": "49894d5d-2cda-4806-9296-45b87f467c62.jpeg",
														"url": "https://staging-media.toyota.com.br/49894d5d-2cda-4806-9296-45b87f467c62.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "sw4",
														"path": "ce9c24f9-c436-4101-ac7a-d5f26b87c13b.jpeg",
														"url": "https://staging-media.toyota.com.br/ce9c24f9-c436-4101-ac7a-d5f26b87c13b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 420090,
										"offers": null,
										"color": {
											"name": "Branco Lunar/Preto Attitude (2PS)",
											"code": "2PS",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - Diamond - 218 - Preto-Attitude trans",
														"path": "bd6cdfa1-9588-45c5-8212-d31d5e800f2a.png",
														"url": "https://staging-media.toyota.com.ar/bd6cdfa1-9588-45c5-8212-d31d5e800f2a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "SW4 - Mobile - Diamond - 218 - Preto-Attitude trans",
														"path": "1edb782e-cd37-4742-9fe5-2bc3871328fc.png",
														"url": "https://staging-media.toyota.com.ar/1edb782e-cd37-4742-9fe5-2bc3871328fc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - Diamond - 1G3 - Cinza-Granito trans",
														"path": "0b4014b3-e154-42c1-b629-3f2ba0919cfa.png",
														"url": "https://staging-media.toyota.com.ar/0b4014b3-e154-42c1-b629-3f2ba0919cfa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - Diamond - 1G3 - Cinza-Granito trans",
														"path": "c4be82f7-a414-44b8-b77d-be658dfa58a7.png",
														"url": "https://staging-media.toyota.com.ar/c4be82f7-a414-44b8-b77d-be658dfa58a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PS",
													"media": {
														"title": "SW4 - Diamond - 2PS - Branco-Lunar-e-Preto-Attitude trans",
														"path": "cc209bcb-d6ce-4b13-9d1d-ce311af6e22a.png",
														"url": "https://staging-media.toyota.com.ar/cc209bcb-d6ce-4b13-9d1d-ce311af6e22a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PS",
													"media": {
														"title": "SW4 - Mobile - Diamond - 2PS - Branco-Lunar-e-Preto-Attitude trans",
														"path": "5a629d98-fcf8-4fd6-914a-b26961d65fd7.png",
														"url": "https://staging-media.toyota.com.ar/5a629d98-fcf8-4fd6-914a-b26961d65fd7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "SW4 - Diamond - 2QY - Prata-Névoa-e-Preto-Attitude trans",
														"path": "a77b8105-c602-460e-af1e-78612e76b7d4.png",
														"url": "https://staging-media.toyota.com.ar/a77b8105-c602-460e-af1e-78612e76b7d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "SW4 - Mobile - Diamond - 2QY - Prata-Névoa-e-Preto-Attitude trans",
														"path": "d7f8525b-eb4e-40a8-bcaa-302cd6233bff.png",
														"url": "https://staging-media.toyota.com.ar/d7f8525b-eb4e-40a8-bcaa-302cd6233bff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "c0781fae-d385-432b-96aa-9b78bc4173e9.jpeg",
														"url": "https://staging-media.toyota.com.ar/c0781fae-d385-432b-96aa-9b78bc4173e9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "0e998fa5-2a93-4861-84d2-4a6212bf28ba.jpeg",
														"url": "https://staging-media.toyota.com.ar/0e998fa5-2a93-4861-84d2-4a6212bf28ba.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "a544329c-bb6d-416d-8ea8-3699cf9e7e9b.jpeg",
														"url": "https://staging-media.toyota.com.ar/a544329c-bb6d-416d-8ea8-3699cf9e7e9b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "bfe9ec96-e988-49cb-adbc-73baf0c14334.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfe9ec96-e988-49cb-adbc-73baf0c14334.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "34e57a76-1429-4a0e-8849-dc10a4ce75d8.jpeg",
														"url": "https://staging-media.toyota.com.ar/34e57a76-1429-4a0e-8849-dc10a4ce75d8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "d9d36d31-6f16-40f0-b415-5fb996ab0459.jpeg",
														"url": "https://staging-media.toyota.com.ar/d9d36d31-6f16-40f0-b415-5fb996ab0459.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "30de35cd-ae69-4027-afe7-6723b599a9c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/30de35cd-ae69-4027-afe7-6723b599a9c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "24b79d79-1336-4c98-9d30-751abe45705f.jpeg",
														"url": "https://staging-media.toyota.com.ar/24b79d79-1336-4c98-9d30-751abe45705f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "b5b791d0-1eec-4466-805f-8714f76e2822.jpeg",
														"url": "https://staging-media.toyota.com.ar/b5b791d0-1eec-4466-805f-8714f76e2822.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "886360cc-212c-4d58-8156-f8d0255f5b1b.jpeg",
														"url": "https://staging-media.toyota.com.ar/886360cc-212c-4d58-8156-f8d0255f5b1b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "40c15f3d-d2cf-4889-be64-1e4c11e87aaf.jpeg",
														"url": "https://staging-media.toyota.com.ar/40c15f3d-d2cf-4889-be64-1e4c11e87aaf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "65935e7a-d321-4b5f-bd9a-4ce784948892.jpeg",
														"url": "https://staging-media.toyota.com.ar/65935e7a-d321-4b5f-bd9a-4ce784948892.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "03e0938a-2295-4fe5-b8e2-2ac977dfb204.jpeg",
														"url": "https://staging-media.toyota.com.ar/03e0938a-2295-4fe5-b8e2-2ac977dfb204.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "e24c2426-5aba-42c8-9eac-16f2a4cdc227.jpeg",
														"url": "https://staging-media.toyota.com.ar/e24c2426-5aba-42c8-9eac-16f2a4cdc227.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "29280412-1436-469b-ad51-e065e144a8b8.jpeg",
														"url": "https://staging-media.toyota.com.ar/29280412-1436-469b-ad51-e065e144a8b8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "f6d4953a-b3ea-46fd-870f-78b21440acbf.jpeg",
														"url": "https://staging-media.toyota.com.ar/f6d4953a-b3ea-46fd-870f-78b21440acbf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "70b00e92-8498-4722-853c-34a16b7b886f.png",
														"url": "https://staging-media.toyota.com.ar/70b00e92-8498-4722-853c-34a16b7b886f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "8c0c53ef-fc22-4362-a3be-bf78104de533.png",
														"url": "https://staging-media.toyota.com.ar/8c0c53ef-fc22-4362-a3be-bf78104de533.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "cross",
														"path": "49894d5d-2cda-4806-9296-45b87f467c62.jpeg",
														"url": "https://staging-media.toyota.com.br/49894d5d-2cda-4806-9296-45b87f467c62.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "sw4",
														"path": "ce9c24f9-c436-4101-ac7a-d5f26b87c13b.jpeg",
														"url": "https://staging-media.toyota.com.br/ce9c24f9-c436-4101-ac7a-d5f26b87c13b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 420090,
										"offers": null,
										"color": {
											"name": "Prata Névoa/Preto Attitude (2QY)",
											"code": "2QY",
											"rgb": "#C5CDCB"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - Diamond - 218 - Preto-Attitude trans",
														"path": "bd6cdfa1-9588-45c5-8212-d31d5e800f2a.png",
														"url": "https://staging-media.toyota.com.ar/bd6cdfa1-9588-45c5-8212-d31d5e800f2a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "SW4 - Mobile - Diamond - 218 - Preto-Attitude trans",
														"path": "1edb782e-cd37-4742-9fe5-2bc3871328fc.png",
														"url": "https://staging-media.toyota.com.ar/1edb782e-cd37-4742-9fe5-2bc3871328fc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "SW4 - Diamond - 1G3 - Cinza-Granito trans",
														"path": "0b4014b3-e154-42c1-b629-3f2ba0919cfa.png",
														"url": "https://staging-media.toyota.com.ar/0b4014b3-e154-42c1-b629-3f2ba0919cfa.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "SW4 - Mobile - Diamond - 1G3 - Cinza-Granito trans",
														"path": "c4be82f7-a414-44b8-b77d-be658dfa58a7.png",
														"url": "https://staging-media.toyota.com.ar/c4be82f7-a414-44b8-b77d-be658dfa58a7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PS",
													"media": {
														"title": "SW4 - Diamond - 2PS - Branco-Lunar-e-Preto-Attitude trans",
														"path": "cc209bcb-d6ce-4b13-9d1d-ce311af6e22a.png",
														"url": "https://staging-media.toyota.com.ar/cc209bcb-d6ce-4b13-9d1d-ce311af6e22a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PS",
													"media": {
														"title": "SW4 - Mobile - Diamond - 2PS - Branco-Lunar-e-Preto-Attitude trans",
														"path": "5a629d98-fcf8-4fd6-914a-b26961d65fd7.png",
														"url": "https://staging-media.toyota.com.ar/5a629d98-fcf8-4fd6-914a-b26961d65fd7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "SW4 - Diamond - 2QY - Prata-Névoa-e-Preto-Attitude trans",
														"path": "a77b8105-c602-460e-af1e-78612e76b7d4.png",
														"url": "https://staging-media.toyota.com.ar/a77b8105-c602-460e-af1e-78612e76b7d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "SW4 - Mobile - Diamond - 2QY - Prata-Névoa-e-Preto-Attitude trans",
														"path": "d7f8525b-eb4e-40a8-bcaa-302cd6233bff.png",
														"url": "https://staging-media.toyota.com.ar/d7f8525b-eb4e-40a8-bcaa-302cd6233bff.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "01",
														"path": "c0781fae-d385-432b-96aa-9b78bc4173e9.jpeg",
														"url": "https://staging-media.toyota.com.ar/c0781fae-d385-432b-96aa-9b78bc4173e9.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "02",
														"path": "0e998fa5-2a93-4861-84d2-4a6212bf28ba.jpeg",
														"url": "https://staging-media.toyota.com.ar/0e998fa5-2a93-4861-84d2-4a6212bf28ba.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "03",
														"path": "a544329c-bb6d-416d-8ea8-3699cf9e7e9b.jpeg",
														"url": "https://staging-media.toyota.com.ar/a544329c-bb6d-416d-8ea8-3699cf9e7e9b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "04",
														"path": "bfe9ec96-e988-49cb-adbc-73baf0c14334.jpeg",
														"url": "https://staging-media.toyota.com.ar/bfe9ec96-e988-49cb-adbc-73baf0c14334.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "05",
														"path": "34e57a76-1429-4a0e-8849-dc10a4ce75d8.jpeg",
														"url": "https://staging-media.toyota.com.ar/34e57a76-1429-4a0e-8849-dc10a4ce75d8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "06",
														"path": "d9d36d31-6f16-40f0-b415-5fb996ab0459.jpeg",
														"url": "https://staging-media.toyota.com.ar/d9d36d31-6f16-40f0-b415-5fb996ab0459.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "07",
														"path": "30de35cd-ae69-4027-afe7-6723b599a9c8.jpeg",
														"url": "https://staging-media.toyota.com.ar/30de35cd-ae69-4027-afe7-6723b599a9c8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "08",
														"path": "24b79d79-1336-4c98-9d30-751abe45705f.jpeg",
														"url": "https://staging-media.toyota.com.ar/24b79d79-1336-4c98-9d30-751abe45705f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "01",
														"path": "b5b791d0-1eec-4466-805f-8714f76e2822.jpeg",
														"url": "https://staging-media.toyota.com.ar/b5b791d0-1eec-4466-805f-8714f76e2822.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "02",
														"path": "886360cc-212c-4d58-8156-f8d0255f5b1b.jpeg",
														"url": "https://staging-media.toyota.com.ar/886360cc-212c-4d58-8156-f8d0255f5b1b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "03",
														"path": "40c15f3d-d2cf-4889-be64-1e4c11e87aaf.jpeg",
														"url": "https://staging-media.toyota.com.ar/40c15f3d-d2cf-4889-be64-1e4c11e87aaf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "04",
														"path": "65935e7a-d321-4b5f-bd9a-4ce784948892.jpeg",
														"url": "https://staging-media.toyota.com.ar/65935e7a-d321-4b5f-bd9a-4ce784948892.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "05",
														"path": "03e0938a-2295-4fe5-b8e2-2ac977dfb204.jpeg",
														"url": "https://staging-media.toyota.com.ar/03e0938a-2295-4fe5-b8e2-2ac977dfb204.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "06",
														"path": "e24c2426-5aba-42c8-9eac-16f2a4cdc227.jpeg",
														"url": "https://staging-media.toyota.com.ar/e24c2426-5aba-42c8-9eac-16f2a4cdc227.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "07",
														"path": "29280412-1436-469b-ad51-e065e144a8b8.jpeg",
														"url": "https://staging-media.toyota.com.ar/29280412-1436-469b-ad51-e065e144a8b8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "08",
														"path": "f6d4953a-b3ea-46fd-870f-78b21440acbf.jpeg",
														"url": "https://staging-media.toyota.com.ar/f6d4953a-b3ea-46fd-870f-78b21440acbf.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "70b00e92-8498-4722-853c-34a16b7b886f.png",
														"url": "https://staging-media.toyota.com.ar/70b00e92-8498-4722-853c-34a16b7b886f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "8c0c53ef-fc22-4362-a3be-bf78104de533.png",
														"url": "https://staging-media.toyota.com.ar/8c0c53ef-fc22-4362-a3be-bf78104de533.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "cross",
														"path": "49894d5d-2cda-4806-9296-45b87f467c62.jpeg",
														"url": "https://staging-media.toyota.com.br/49894d5d-2cda-4806-9296-45b87f467c62.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "sw4",
														"path": "ce9c24f9-c436-4101-ac7a-d5f26b87c13b.jpeg",
														"url": "https://staging-media.toyota.com.br/ce9c24f9-c436-4101-ac7a-d5f26b87c13b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 423890,
										"offers": null,
										"color": {
											"name": "Preto Attitude (218)",
											"code": "218",
											"rgb": "#343736"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "8cf2685e-f5fc-406b-bcff-306fa4b8cc42.jpeg",
														"url": "https://staging-media.toyota.com.ar/8cf2685e-f5fc-406b-bcff-306fa4b8cc42.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "53d85251-68de-450b-ba6d-311a536d5c61.jpeg",
														"url": "https://staging-media.toyota.com.ar/53d85251-68de-450b-ba6d-311a536d5c61.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "0a161a1e-1c33-4bce-bb4f-5b4f75323e52.jpeg",
														"url": "https://staging-media.toyota.com.ar/0a161a1e-1c33-4bce-bb4f-5b4f75323e52.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "9a5d81a3-0676-46d7-b101-44954da624f1.jpeg",
														"url": "https://staging-media.toyota.com.ar/9a5d81a3-0676-46d7-b101-44954da624f1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "a9be5755-17bd-492f-93b0-450f88cc99d4.jpeg",
														"url": "https://staging-media.toyota.com.ar/a9be5755-17bd-492f-93b0-450f88cc99d4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "5017c767-e46b-420e-beed-4dfac586129b.jpeg",
														"url": "https://staging-media.toyota.com.ar/5017c767-e46b-420e-beed-4dfac586129b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "251177c3-b4e8-4a4d-be06-e4c5958b3f1e.jpeg",
														"url": "https://staging-media.toyota.com.ar/251177c3-b4e8-4a4d-be06-e4c5958b3f1e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "21f7493d-58b3-4d8c-b234-6cdd30e1e3d7.jpeg",
														"url": "https://staging-media.toyota.com.ar/21f7493d-58b3-4d8c-b234-6cdd30e1e3d7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "d4ff90af-5d1f-489f-8877-b55f3b4c7103.jpeg",
														"url": "https://staging-media.toyota.com.ar/d4ff90af-5d1f-489f-8877-b55f3b4c7103.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "95fc5100-5e8c-4ca7-9252-d3bc16eabe45.jpeg",
														"url": "https://staging-media.toyota.com.ar/95fc5100-5e8c-4ca7-9252-d3bc16eabe45.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "1707f4d2-234f-47c5-9448-b742631deeb5.jpeg",
														"url": "https://staging-media.toyota.com.ar/1707f4d2-234f-47c5-9448-b742631deeb5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large SW4 Diamond",
														"path": "7482ecee-2286-453d-824b-7696af00f4ce.jpeg",
														"url": "https://staging-media.toyota.com.ar/7482ecee-2286-453d-824b-7696af00f4ce.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner SW4 Diamond",
														"path": "f5938d42-4850-4eb4-8448-312789bb66e4.jpeg",
														"url": "https://staging-media.toyota.com.ar/f5938d42-4850-4eb4-8448-312789bb66e4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "8e0e878d-8657-4294-b037-21552799491d.png",
														"url": "https://staging-media.toyota.com.ar/8e0e878d-8657-4294-b037-21552799491d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "f772d41f-12a9-4cd3-94ad-b63466954ab3.png",
														"url": "https://staging-media.toyota.com.ar/f772d41f-12a9-4cd3-94ad-b63466954ab3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "64c0b350-d02a-41dd-a10f-2b3cb2a0494e.png",
														"url": "https://staging-media.toyota.com.ar/64c0b350-d02a-41dd-a10f-2b3cb2a0494e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "3170c5ad-8256-49d6-943f-126447971137.png",
														"url": "https://staging-media.toyota.com.ar/3170c5ad-8256-49d6-943f-126447971137.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "400c6d8d-eade-4465-935a-ff1f41ca8bd9.png",
														"url": "https://staging-media.toyota.com.ar/400c6d8d-eade-4465-935a-ff1f41ca8bd9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "76f41a43-1bd9-4d1c-a9df-cf631d2bcdfc.png",
														"url": "https://staging-media.toyota.com.ar/76f41a43-1bd9-4d1c-a9df-cf631d2bcdfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QJ",
													"media": {
														"title": "desk",
														"path": "e708e484-cc9f-40fc-aade-51d7eb335077.png",
														"url": "https://staging-media.toyota.com.ar/e708e484-cc9f-40fc-aade-51d7eb335077.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QJ",
													"media": {
														"title": "mobile",
														"path": "0a4ba1e0-cfb7-4a21-ad4a-b713560a408c.png",
														"url": "https://staging-media.toyota.com.ar/0a4ba1e0-cfb7-4a21-ad4a-b713560a408c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "desk",
														"path": "ed5d7840-5506-41f5-9438-ece5e28ab8a8.png",
														"url": "https://staging-media.toyota.com.ar/ed5d7840-5506-41f5-9438-ece5e28ab8a8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "mobile",
														"path": "fd244671-9f84-4493-bf7d-c315a2e817f7.png",
														"url": "https://staging-media.toyota.com.ar/fd244671-9f84-4493-bf7d-c315a2e817f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "SW4 Logo",
														"path": "c6b3c735-33fd-4e28-b2b6-7651a72c4dfc.png",
														"url": "https://staging-media.toyota.com.ar/c6b3c735-33fd-4e28-b2b6-7651a72c4dfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 423890,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "8cf2685e-f5fc-406b-bcff-306fa4b8cc42.jpeg",
														"url": "https://staging-media.toyota.com.ar/8cf2685e-f5fc-406b-bcff-306fa4b8cc42.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "53d85251-68de-450b-ba6d-311a536d5c61.jpeg",
														"url": "https://staging-media.toyota.com.ar/53d85251-68de-450b-ba6d-311a536d5c61.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "0a161a1e-1c33-4bce-bb4f-5b4f75323e52.jpeg",
														"url": "https://staging-media.toyota.com.ar/0a161a1e-1c33-4bce-bb4f-5b4f75323e52.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "9a5d81a3-0676-46d7-b101-44954da624f1.jpeg",
														"url": "https://staging-media.toyota.com.ar/9a5d81a3-0676-46d7-b101-44954da624f1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "a9be5755-17bd-492f-93b0-450f88cc99d4.jpeg",
														"url": "https://staging-media.toyota.com.ar/a9be5755-17bd-492f-93b0-450f88cc99d4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "5017c767-e46b-420e-beed-4dfac586129b.jpeg",
														"url": "https://staging-media.toyota.com.ar/5017c767-e46b-420e-beed-4dfac586129b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "251177c3-b4e8-4a4d-be06-e4c5958b3f1e.jpeg",
														"url": "https://staging-media.toyota.com.ar/251177c3-b4e8-4a4d-be06-e4c5958b3f1e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "21f7493d-58b3-4d8c-b234-6cdd30e1e3d7.jpeg",
														"url": "https://staging-media.toyota.com.ar/21f7493d-58b3-4d8c-b234-6cdd30e1e3d7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "d4ff90af-5d1f-489f-8877-b55f3b4c7103.jpeg",
														"url": "https://staging-media.toyota.com.ar/d4ff90af-5d1f-489f-8877-b55f3b4c7103.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "95fc5100-5e8c-4ca7-9252-d3bc16eabe45.jpeg",
														"url": "https://staging-media.toyota.com.ar/95fc5100-5e8c-4ca7-9252-d3bc16eabe45.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "1707f4d2-234f-47c5-9448-b742631deeb5.jpeg",
														"url": "https://staging-media.toyota.com.ar/1707f4d2-234f-47c5-9448-b742631deeb5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large SW4 Diamond",
														"path": "7482ecee-2286-453d-824b-7696af00f4ce.jpeg",
														"url": "https://staging-media.toyota.com.ar/7482ecee-2286-453d-824b-7696af00f4ce.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner SW4 Diamond",
														"path": "f5938d42-4850-4eb4-8448-312789bb66e4.jpeg",
														"url": "https://staging-media.toyota.com.ar/f5938d42-4850-4eb4-8448-312789bb66e4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "8e0e878d-8657-4294-b037-21552799491d.png",
														"url": "https://staging-media.toyota.com.ar/8e0e878d-8657-4294-b037-21552799491d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "f772d41f-12a9-4cd3-94ad-b63466954ab3.png",
														"url": "https://staging-media.toyota.com.ar/f772d41f-12a9-4cd3-94ad-b63466954ab3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "64c0b350-d02a-41dd-a10f-2b3cb2a0494e.png",
														"url": "https://staging-media.toyota.com.ar/64c0b350-d02a-41dd-a10f-2b3cb2a0494e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "3170c5ad-8256-49d6-943f-126447971137.png",
														"url": "https://staging-media.toyota.com.ar/3170c5ad-8256-49d6-943f-126447971137.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "400c6d8d-eade-4465-935a-ff1f41ca8bd9.png",
														"url": "https://staging-media.toyota.com.ar/400c6d8d-eade-4465-935a-ff1f41ca8bd9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "76f41a43-1bd9-4d1c-a9df-cf631d2bcdfc.png",
														"url": "https://staging-media.toyota.com.ar/76f41a43-1bd9-4d1c-a9df-cf631d2bcdfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QJ",
													"media": {
														"title": "desk",
														"path": "e708e484-cc9f-40fc-aade-51d7eb335077.png",
														"url": "https://staging-media.toyota.com.ar/e708e484-cc9f-40fc-aade-51d7eb335077.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QJ",
													"media": {
														"title": "mobile",
														"path": "0a4ba1e0-cfb7-4a21-ad4a-b713560a408c.png",
														"url": "https://staging-media.toyota.com.ar/0a4ba1e0-cfb7-4a21-ad4a-b713560a408c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "desk",
														"path": "ed5d7840-5506-41f5-9438-ece5e28ab8a8.png",
														"url": "https://staging-media.toyota.com.ar/ed5d7840-5506-41f5-9438-ece5e28ab8a8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "mobile",
														"path": "fd244671-9f84-4493-bf7d-c315a2e817f7.png",
														"url": "https://staging-media.toyota.com.ar/fd244671-9f84-4493-bf7d-c315a2e817f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "SW4 Logo",
														"path": "c6b3c735-33fd-4e28-b2b6-7651a72c4dfc.png",
														"url": "https://staging-media.toyota.com.ar/c6b3c735-33fd-4e28-b2b6-7651a72c4dfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 423890,
										"offers": null,
										"color": {
											"name": "Branco Pérola/Preto Attitude (2QJ)",
											"code": "2QJ",
											"rgb": "#D1D0CE"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "8cf2685e-f5fc-406b-bcff-306fa4b8cc42.jpeg",
														"url": "https://staging-media.toyota.com.ar/8cf2685e-f5fc-406b-bcff-306fa4b8cc42.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "53d85251-68de-450b-ba6d-311a536d5c61.jpeg",
														"url": "https://staging-media.toyota.com.ar/53d85251-68de-450b-ba6d-311a536d5c61.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "0a161a1e-1c33-4bce-bb4f-5b4f75323e52.jpeg",
														"url": "https://staging-media.toyota.com.ar/0a161a1e-1c33-4bce-bb4f-5b4f75323e52.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "9a5d81a3-0676-46d7-b101-44954da624f1.jpeg",
														"url": "https://staging-media.toyota.com.ar/9a5d81a3-0676-46d7-b101-44954da624f1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "a9be5755-17bd-492f-93b0-450f88cc99d4.jpeg",
														"url": "https://staging-media.toyota.com.ar/a9be5755-17bd-492f-93b0-450f88cc99d4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "5017c767-e46b-420e-beed-4dfac586129b.jpeg",
														"url": "https://staging-media.toyota.com.ar/5017c767-e46b-420e-beed-4dfac586129b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "251177c3-b4e8-4a4d-be06-e4c5958b3f1e.jpeg",
														"url": "https://staging-media.toyota.com.ar/251177c3-b4e8-4a4d-be06-e4c5958b3f1e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "21f7493d-58b3-4d8c-b234-6cdd30e1e3d7.jpeg",
														"url": "https://staging-media.toyota.com.ar/21f7493d-58b3-4d8c-b234-6cdd30e1e3d7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "d4ff90af-5d1f-489f-8877-b55f3b4c7103.jpeg",
														"url": "https://staging-media.toyota.com.ar/d4ff90af-5d1f-489f-8877-b55f3b4c7103.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "95fc5100-5e8c-4ca7-9252-d3bc16eabe45.jpeg",
														"url": "https://staging-media.toyota.com.ar/95fc5100-5e8c-4ca7-9252-d3bc16eabe45.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "1707f4d2-234f-47c5-9448-b742631deeb5.jpeg",
														"url": "https://staging-media.toyota.com.ar/1707f4d2-234f-47c5-9448-b742631deeb5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large SW4 Diamond",
														"path": "7482ecee-2286-453d-824b-7696af00f4ce.jpeg",
														"url": "https://staging-media.toyota.com.ar/7482ecee-2286-453d-824b-7696af00f4ce.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner SW4 Diamond",
														"path": "f5938d42-4850-4eb4-8448-312789bb66e4.jpeg",
														"url": "https://staging-media.toyota.com.ar/f5938d42-4850-4eb4-8448-312789bb66e4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "8e0e878d-8657-4294-b037-21552799491d.png",
														"url": "https://staging-media.toyota.com.ar/8e0e878d-8657-4294-b037-21552799491d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "f772d41f-12a9-4cd3-94ad-b63466954ab3.png",
														"url": "https://staging-media.toyota.com.ar/f772d41f-12a9-4cd3-94ad-b63466954ab3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "64c0b350-d02a-41dd-a10f-2b3cb2a0494e.png",
														"url": "https://staging-media.toyota.com.ar/64c0b350-d02a-41dd-a10f-2b3cb2a0494e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "3170c5ad-8256-49d6-943f-126447971137.png",
														"url": "https://staging-media.toyota.com.ar/3170c5ad-8256-49d6-943f-126447971137.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "400c6d8d-eade-4465-935a-ff1f41ca8bd9.png",
														"url": "https://staging-media.toyota.com.ar/400c6d8d-eade-4465-935a-ff1f41ca8bd9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "76f41a43-1bd9-4d1c-a9df-cf631d2bcdfc.png",
														"url": "https://staging-media.toyota.com.ar/76f41a43-1bd9-4d1c-a9df-cf631d2bcdfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QJ",
													"media": {
														"title": "desk",
														"path": "e708e484-cc9f-40fc-aade-51d7eb335077.png",
														"url": "https://staging-media.toyota.com.ar/e708e484-cc9f-40fc-aade-51d7eb335077.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QJ",
													"media": {
														"title": "mobile",
														"path": "0a4ba1e0-cfb7-4a21-ad4a-b713560a408c.png",
														"url": "https://staging-media.toyota.com.ar/0a4ba1e0-cfb7-4a21-ad4a-b713560a408c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "desk",
														"path": "ed5d7840-5506-41f5-9438-ece5e28ab8a8.png",
														"url": "https://staging-media.toyota.com.ar/ed5d7840-5506-41f5-9438-ece5e28ab8a8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "mobile",
														"path": "fd244671-9f84-4493-bf7d-c315a2e817f7.png",
														"url": "https://staging-media.toyota.com.ar/fd244671-9f84-4493-bf7d-c315a2e817f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "SW4 Logo",
														"path": "c6b3c735-33fd-4e28-b2b6-7651a72c4dfc.png",
														"url": "https://staging-media.toyota.com.ar/c6b3c735-33fd-4e28-b2b6-7651a72c4dfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 423890,
										"offers": null,
										"color": {
											"name": "Prata Névoa/Preto Attitude (2QY)",
											"code": "2QY",
											"rgb": "#C5CDCB"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "8cf2685e-f5fc-406b-bcff-306fa4b8cc42.jpeg",
														"url": "https://staging-media.toyota.com.ar/8cf2685e-f5fc-406b-bcff-306fa4b8cc42.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "53d85251-68de-450b-ba6d-311a536d5c61.jpeg",
														"url": "https://staging-media.toyota.com.ar/53d85251-68de-450b-ba6d-311a536d5c61.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "0a161a1e-1c33-4bce-bb4f-5b4f75323e52.jpeg",
														"url": "https://staging-media.toyota.com.ar/0a161a1e-1c33-4bce-bb4f-5b4f75323e52.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "9a5d81a3-0676-46d7-b101-44954da624f1.jpeg",
														"url": "https://staging-media.toyota.com.ar/9a5d81a3-0676-46d7-b101-44954da624f1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "a9be5755-17bd-492f-93b0-450f88cc99d4.jpeg",
														"url": "https://staging-media.toyota.com.ar/a9be5755-17bd-492f-93b0-450f88cc99d4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "5017c767-e46b-420e-beed-4dfac586129b.jpeg",
														"url": "https://staging-media.toyota.com.ar/5017c767-e46b-420e-beed-4dfac586129b.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "251177c3-b4e8-4a4d-be06-e4c5958b3f1e.jpeg",
														"url": "https://staging-media.toyota.com.ar/251177c3-b4e8-4a4d-be06-e4c5958b3f1e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "21f7493d-58b3-4d8c-b234-6cdd30e1e3d7.jpeg",
														"url": "https://staging-media.toyota.com.ar/21f7493d-58b3-4d8c-b234-6cdd30e1e3d7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "d4ff90af-5d1f-489f-8877-b55f3b4c7103.jpeg",
														"url": "https://staging-media.toyota.com.ar/d4ff90af-5d1f-489f-8877-b55f3b4c7103.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "95fc5100-5e8c-4ca7-9252-d3bc16eabe45.jpeg",
														"url": "https://staging-media.toyota.com.ar/95fc5100-5e8c-4ca7-9252-d3bc16eabe45.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "1707f4d2-234f-47c5-9448-b742631deeb5.jpeg",
														"url": "https://staging-media.toyota.com.ar/1707f4d2-234f-47c5-9448-b742631deeb5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large SW4 Diamond",
														"path": "7482ecee-2286-453d-824b-7696af00f4ce.jpeg",
														"url": "https://staging-media.toyota.com.ar/7482ecee-2286-453d-824b-7696af00f4ce.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner SW4 Diamond",
														"path": "f5938d42-4850-4eb4-8448-312789bb66e4.jpeg",
														"url": "https://staging-media.toyota.com.ar/f5938d42-4850-4eb4-8448-312789bb66e4.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "8e0e878d-8657-4294-b037-21552799491d.png",
														"url": "https://staging-media.toyota.com.ar/8e0e878d-8657-4294-b037-21552799491d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "f772d41f-12a9-4cd3-94ad-b63466954ab3.png",
														"url": "https://staging-media.toyota.com.ar/f772d41f-12a9-4cd3-94ad-b63466954ab3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "64c0b350-d02a-41dd-a10f-2b3cb2a0494e.png",
														"url": "https://staging-media.toyota.com.ar/64c0b350-d02a-41dd-a10f-2b3cb2a0494e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "3170c5ad-8256-49d6-943f-126447971137.png",
														"url": "https://staging-media.toyota.com.ar/3170c5ad-8256-49d6-943f-126447971137.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "desk",
														"path": "400c6d8d-eade-4465-935a-ff1f41ca8bd9.png",
														"url": "https://staging-media.toyota.com.ar/400c6d8d-eade-4465-935a-ff1f41ca8bd9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "mobile",
														"path": "76f41a43-1bd9-4d1c-a9df-cf631d2bcdfc.png",
														"url": "https://staging-media.toyota.com.ar/76f41a43-1bd9-4d1c-a9df-cf631d2bcdfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QJ",
													"media": {
														"title": "desk",
														"path": "e708e484-cc9f-40fc-aade-51d7eb335077.png",
														"url": "https://staging-media.toyota.com.ar/e708e484-cc9f-40fc-aade-51d7eb335077.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QJ",
													"media": {
														"title": "mobile",
														"path": "0a4ba1e0-cfb7-4a21-ad4a-b713560a408c.png",
														"url": "https://staging-media.toyota.com.ar/0a4ba1e0-cfb7-4a21-ad4a-b713560a408c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "desk",
														"path": "ed5d7840-5506-41f5-9438-ece5e28ab8a8.png",
														"url": "https://staging-media.toyota.com.ar/ed5d7840-5506-41f5-9438-ece5e28ab8a8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "mobile",
														"path": "fd244671-9f84-4493-bf7d-c315a2e817f7.png",
														"url": "https://staging-media.toyota.com.ar/fd244671-9f84-4493-bf7d-c315a2e817f7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "SW4 Logo",
														"path": "c6b3c735-33fd-4e28-b2b6-7651a72c4dfc.png",
														"url": "https://staging-media.toyota.com.ar/c6b3c735-33fd-4e28-b2b6-7651a72c4dfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "SXR Platinum 5 lugares",
						"id": "e22d23ad-b614-4dd7-97a3-1718be05ea6e",
						"order": 3,
						"modelYears": [
							{
								"vehicleColors": []
							}
						]
					},
					{
						"name": "GR-Sport",
						"id": "1e5b9010-13f9-454e-81b7-3475f22b596c",
						"order": 4,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 433190,
										"offers": null,
										"color": {
											"name": "Preto Attitude (218)",
											"code": "218",
											"rgb": "#343736"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"url": "https://staging-media.toyota.com.ar/00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"url": "https://staging-media.toyota.com.ar/6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"url": "https://staging-media.toyota.com.ar/ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"url": "https://staging-media.toyota.com.ar/ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"url": "https://staging-media.toyota.com.ar/30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"url": "https://staging-media.toyota.com.ar/67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"url": "https://staging-media.toyota.com.ar/89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"url": "https://staging-media.toyota.com.ar/038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"url": "https://staging-media.toyota.com.ar/17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "6807d50e-c8a1-45af-8533-5da00d422b03.png",
														"url": "https://staging-media.toyota.com.ar/6807d50e-c8a1-45af-8533-5da00d422b03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "063e3020-e63c-47bc-b971-b4dcf5ac37f3.png",
														"url": "https://staging-media.toyota.com.ar/063e3020-e63c-47bc-b971-b4dcf5ac37f3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "3d20f150-6e6c-4a94-8aac-bd4782f20916.png",
														"url": "https://staging-media.toyota.com.ar/3d20f150-6e6c-4a94-8aac-bd4782f20916.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "522dd894-dfcc-476f-9a58-bcfb28d15888.jpeg",
														"url": "https://staging-media.toyota.com.ar/522dd894-dfcc-476f-9a58-bcfb28d15888.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "87b833c5-15f9-458e-ad93-91833686ab94.jpeg",
														"url": "https://staging-media.toyota.com.ar/87b833c5-15f9-458e-ad93-91833686ab94.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "7f049d73-2025-4c2e-9677-0835999fa10b.png",
														"url": "https://staging-media.toyota.com.ar/7f049d73-2025-4c2e-9677-0835999fa10b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "5306e582-4d3c-489b-bf60-591ccde9e133.png",
														"url": "https://staging-media.toyota.com.ar/5306e582-4d3c-489b-bf60-591ccde9e133.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QJ",
													"media": {
														"title": "desktop",
														"path": "86fa6be8-88f8-4a50-aa64-0a1707490221.png",
														"url": "https://staging-media.toyota.com.ar/86fa6be8-88f8-4a50-aa64-0a1707490221.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QJ",
													"media": {
														"title": "mobile",
														"path": "935be522-628e-4e32-aebc-e30b0eae0497.png",
														"url": "https://staging-media.toyota.com.ar/935be522-628e-4e32-aebc-e30b0eae0497.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 433190,
										"offers": null,
										"color": {
											"name": "Branco Pérola/Preto Attitude (2QJ)",
											"code": "2QJ",
											"rgb": "#D1D0CE"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 5",
														"path": "00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"url": "https://staging-media.toyota.com.ar/00147824-5803-460f-aa7c-e5e99245671c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 4",
														"path": "6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"url": "https://staging-media.toyota.com.ar/6cf388cf-6df0-40bf-88fc-cec5621cdb99.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 3",
														"path": "ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"url": "https://staging-media.toyota.com.ar/ae87e797-66d8-414e-abb4-dfaf9100cd86.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 2",
														"path": "ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"url": "https://staging-media.toyota.com.ar/ee18f4fd-68ce-44da-a209-7be4dedbde2c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "sw4 interna 1",
														"path": "c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"url": "https://staging-media.toyota.com.ar/c4f7a8a1-c022-4faf-8588-9844ffb0383c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 6",
														"path": "30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"url": "https://staging-media.toyota.com.ar/30e6a7ae-34a3-4488-afb3-db985ed96b42.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 5",
														"path": "67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"url": "https://staging-media.toyota.com.ar/67920cc4-e079-4d77-8788-91e5dd791802.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 4",
														"path": "89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"url": "https://staging-media.toyota.com.ar/89f1f983-e600-4fb1-ab43-51d295919835.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 3",
														"path": "038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"url": "https://staging-media.toyota.com.ar/038c9d62-e397-46af-80b0-dcaaea8cd3e5.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 2",
														"path": "ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"url": "https://staging-media.toyota.com.ar/ca8c36a7-be2c-4380-a5fb-e77a53eaaec7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "sw4 externa 1",
														"path": "17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"url": "https://staging-media.toyota.com.ar/17ad5d23-7880-4e3f-8996-2ddc7e6a9d97.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo",
														"path": "6807d50e-c8a1-45af-8533-5da00d422b03.png",
														"url": "https://staging-media.toyota.com.ar/6807d50e-c8a1-45af-8533-5da00d422b03.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "normal",
														"path": "063e3020-e63c-47bc-b971-b4dcf5ac37f3.png",
														"url": "https://staging-media.toyota.com.ar/063e3020-e63c-47bc-b971-b4dcf5ac37f3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "mouse hover",
														"path": "3d20f150-6e6c-4a94-8aac-bd4782f20916.png",
														"url": "https://staging-media.toyota.com.ar/3d20f150-6e6c-4a94-8aac-bd4782f20916.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "desktop",
														"path": "522dd894-dfcc-476f-9a58-bcfb28d15888.jpeg",
														"url": "https://staging-media.toyota.com.ar/522dd894-dfcc-476f-9a58-bcfb28d15888.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "mobile",
														"path": "87b833c5-15f9-458e-ad93-91833686ab94.jpeg",
														"url": "https://staging-media.toyota.com.ar/87b833c5-15f9-458e-ad93-91833686ab94.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "desk",
														"path": "7f049d73-2025-4c2e-9677-0835999fa10b.png",
														"url": "https://staging-media.toyota.com.ar/7f049d73-2025-4c2e-9677-0835999fa10b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "mobile",
														"path": "5306e582-4d3c-489b-bf60-591ccde9e133.png",
														"url": "https://staging-media.toyota.com.ar/5306e582-4d3c-489b-bf60-591ccde9e133.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QJ",
													"media": {
														"title": "desktop",
														"path": "86fa6be8-88f8-4a50-aa64-0a1707490221.png",
														"url": "https://staging-media.toyota.com.ar/86fa6be8-88f8-4a50-aa64-0a1707490221.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QJ",
													"media": {
														"title": "mobile",
														"path": "935be522-628e-4e32-aebc-e30b0eae0497.png",
														"url": "https://staging-media.toyota.com.ar/935be522-628e-4e32-aebc-e30b0eae0497.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 433790,
										"offers": null,
										"color": {
											"name": "Preto Attitude (218)",
											"code": "218",
											"rgb": "#343736"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - GRS - 218 - Preto-Attitude trans",
														"path": "30528d85-566c-404d-9759-ef6bbb68d5d6.png",
														"url": "https://staging-media.toyota.com.ar/30528d85-566c-404d-9759-ef6bbb68d5d6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "SW4 - Mobile - GRS - 218 - Preto-Attitude trans",
														"path": "45e606b5-5cf7-4030-936e-f01bccd25102.png",
														"url": "https://staging-media.toyota.com.ar/45e606b5-5cf7-4030-936e-f01bccd25102.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PS",
													"media": {
														"title": "SW4 - GRS - 2PS - Branco-Lunar-e-Preto-Attitude trans",
														"path": "fbcbec85-1422-4e52-992b-23bd46157fb9.png",
														"url": "https://staging-media.toyota.com.ar/fbcbec85-1422-4e52-992b-23bd46157fb9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PS",
													"media": {
														"title": "SW4 - Mobile - GRS - 2PS - Branco-Lunar-e-Preto-Attitude trans",
														"path": "134c75d3-450a-4c18-a68b-77ef7d2290ea.png",
														"url": "https://staging-media.toyota.com.ar/134c75d3-450a-4c18-a68b-77ef7d2290ea.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "GR-Sport - 2022",
														"path": "9a144ad5-cff9-41b0-93fb-3e8c77f1e4d4.png",
														"url": "https://staging-media.toyota.com.ar/9a144ad5-cff9-41b0-93fb-3e8c77f1e4d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "GR-Sport - 2022",
														"path": "6d3e98c6-02e2-4e43-859c-05a2abd660a5.png",
														"url": "https://staging-media.toyota.com.ar/6d3e98c6-02e2-4e43-859c-05a2abd660a5.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 433790,
										"offers": null,
										"color": {
											"name": "Branco Lunar/Preto Attitude (2PS)",
											"code": "2PS",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "logo_dimensionado_sw4",
														"path": "374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"url": "https://staging-media.toyota.com.ar/374a9a3a-b987-4798-910a-16c98f16e08d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "SW4 - GRS - 218 - Preto-Attitude trans",
														"path": "30528d85-566c-404d-9759-ef6bbb68d5d6.png",
														"url": "https://staging-media.toyota.com.ar/30528d85-566c-404d-9759-ef6bbb68d5d6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "SW4 - Mobile - GRS - 218 - Preto-Attitude trans",
														"path": "45e606b5-5cf7-4030-936e-f01bccd25102.png",
														"url": "https://staging-media.toyota.com.ar/45e606b5-5cf7-4030-936e-f01bccd25102.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PS",
													"media": {
														"title": "SW4 - GRS - 2PS - Branco-Lunar-e-Preto-Attitude trans",
														"path": "fbcbec85-1422-4e52-992b-23bd46157fb9.png",
														"url": "https://staging-media.toyota.com.ar/fbcbec85-1422-4e52-992b-23bd46157fb9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PS",
													"media": {
														"title": "SW4 - Mobile - GRS - 2PS - Branco-Lunar-e-Preto-Attitude trans",
														"path": "134c75d3-450a-4c18-a68b-77ef7d2290ea.png",
														"url": "https://staging-media.toyota.com.ar/134c75d3-450a-4c18-a68b-77ef7d2290ea.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "GR-Sport - 2022",
														"path": "9a144ad5-cff9-41b0-93fb-3e8c77f1e4d4.png",
														"url": "https://staging-media.toyota.com.ar/9a144ad5-cff9-41b0-93fb-3e8c77f1e4d4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "GR-Sport - 2022",
														"path": "6d3e98c6-02e2-4e43-859c-05a2abd660a5.png",
														"url": "https://staging-media.toyota.com.ar/6d3e98c6-02e2-4e43-859c-05a2abd660a5.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "SRX Platinum 7 lugares",
						"id": "04697294-526c-48d8-90e3-7dc6d75e5410",
						"order": 4,
						"modelYears": [
							{
								"vehicleColors": []
							}
						]
					}
				]
			},
			{
				"id": "f9d2155e-e291-4ef2-9ce2-1b7d7e88b2d5",
				"name": "RAV4",
				"vehicleExternalId": null,
				"status": "ENABLED",
				"category": {
					"name": "SUVs",
					"id": "39e016f8-3719-439b-91f6-41a1d0d151b6"
				},
				"versions": [
					{
						"name": "SX Connect Hybrid",
						"id": "f03f5364-2a5b-4fb3-b47a-04521490222d",
						"order": 1,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 308690,
										"offers": null,
										"color": {
											"name": "Prata Névoa (1D6)",
											"code": "1D6",
											"rgb": "#C5CDCB"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 8",
														"path": "7028337c-fa7e-4ee2-92b4-9a53fa9d07aa.jpeg",
														"url": "https://staging-media.toyota.com.ar/7028337c-fa7e-4ee2-92b4-9a53fa9d07aa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 9",
														"path": "76d43fa3-60cc-4767-98b7-acc223f1031f.jpeg",
														"url": "https://staging-media.toyota.com.ar/76d43fa3-60cc-4767-98b7-acc223f1031f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "00ac517c-5108-4652-b092-a2deb3cd031e.jpeg",
														"url": "https://staging-media.toyota.com.ar/00ac517c-5108-4652-b092-a2deb3cd031e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "863f1255-6506-4424-9721-3af80f6b3e5a.jpeg",
														"url": "https://staging-media.toyota.com.ar/863f1255-6506-4424-9721-3af80f6b3e5a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "28d99b47-b675-4dcc-bbb0-b2369b8b91ea.jpeg",
														"url": "https://staging-media.toyota.com.ar/28d99b47-b675-4dcc-bbb0-b2369b8b91ea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "fb06d4bf-240e-4b76-819e-3f951bdd5c75.jpeg",
														"url": "https://staging-media.toyota.com.ar/fb06d4bf-240e-4b76-819e-3f951bdd5c75.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "20fe6680-980b-481e-8021-d35b6162e81a.jpeg",
														"url": "https://staging-media.toyota.com.ar/20fe6680-980b-481e-8021-d35b6162e81a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "fe4ed795-cf45-4c9b-a33c-56c895513f0e.jpeg",
														"url": "https://staging-media.toyota.com.ar/fe4ed795-cf45-4c9b-a33c-56c895513f0e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "54b14b0f-75ea-4f83-8b7d-ca06514c4ffa.jpeg",
														"url": "https://staging-media.toyota.com.ar/54b14b0f-75ea-4f83-8b7d-ca06514c4ffa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 1",
														"path": "ff73fa0b-31d0-4e8a-a738-8a926c89d8fe.jpeg",
														"url": "https://staging-media.toyota.com.ar/ff73fa0b-31d0-4e8a-a738-8a926c89d8fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 2",
														"path": "d5d66678-2c63-4a08-a881-2efea4cde296.jpeg",
														"url": "https://staging-media.toyota.com.ar/d5d66678-2c63-4a08-a881-2efea4cde296.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 3",
														"path": "b61ad640-f9e5-4ff0-ab68-86f1acdb3278.jpeg",
														"url": "https://staging-media.toyota.com.ar/b61ad640-f9e5-4ff0-ab68-86f1acdb3278.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 4",
														"path": "a29372fa-4deb-406d-9754-3a7ef2d63265.jpeg",
														"url": "https://staging-media.toyota.com.ar/a29372fa-4deb-406d-9754-3a7ef2d63265.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 5",
														"path": "fd33c759-d5e1-4cf4-a994-8bf744e1f4cc.jpeg",
														"url": "https://staging-media.toyota.com.ar/fd33c759-d5e1-4cf4-a994-8bf744e1f4cc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 6",
														"path": "2b858fca-195e-42e0-a3de-a20af85a9e0d.jpeg",
														"url": "https://staging-media.toyota.com.ar/2b858fca-195e-42e0-a3de-a20af85a9e0d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 7",
														"path": "32b40ca9-0e37-4001-875b-10e444a44ba7.jpeg",
														"url": "https://staging-media.toyota.com.ar/32b40ca9-0e37-4001-875b-10e444a44ba7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display Rav4 lateral",
														"path": "b0a1bece-78ab-4776-8de4-e849793a78ec.png",
														"url": "https://staging-media.toyota.com.ar/b0a1bece-78ab-4776-8de4-e849793a78ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display Rav4 angulado",
														"path": "d183ed0f-67bb-44e3-93da-09ca5f89cd3a.png",
														"url": "https://staging-media.toyota.com.ar/d183ed0f-67bb-44e3-93da-09ca5f89cd3a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large RAV4",
														"path": "3add62f2-1412-4ab8-8c4f-f7d41bb47cfd.jpeg",
														"url": "https://staging-media.toyota.com.ar/3add62f2-1412-4ab8-8c4f-f7d41bb47cfd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner Large RAV4",
														"path": "e5e8d90e-1146-48de-b62e-b96d8f7a4f70.jpeg",
														"url": "https://staging-media.toyota.com.ar/e5e8d90e-1146-48de-b62e-b96d8f7a4f70.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo R4VA",
														"path": "572d3beb-9c2b-4cdb-8493-53012ce2a972.png",
														"url": "https://staging-media.toyota.com.ar/572d3beb-9c2b-4cdb-8493-53012ce2a972.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "RAV4-070-Branco-Pérola",
														"path": "b9e4d4c9-48d5-4fa6-bfda-9e094da2707b.png",
														"url": "https://staging-media.toyota.com.ar/b9e4d4c9-48d5-4fa6-bfda-9e094da2707b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "RAV4-mobile-070-Branco-Pérola",
														"path": "d645433e-9025-44e1-93b8-36f1ef55d419.png",
														"url": "https://staging-media.toyota.com.ar/d645433e-9025-44e1-93b8-36f1ef55d419.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X8",
													"media": {
														"title": "RAV4-8X8-Azul-Topázio",
														"path": "2893a23b-4270-4200-8962-3c11cfd18dfe.png",
														"url": "https://staging-media.toyota.com.ar/2893a23b-4270-4200-8962-3c11cfd18dfe.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X8",
													"media": {
														"title": "RAV4-mobile-8X8-Azul-Topázio",
														"path": "c41a038b-3c66-4317-8009-869400a0bd33.png",
														"url": "https://staging-media.toyota.com.ar/c41a038b-3c66-4317-8009-869400a0bd33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "RAV4-mobile-218-Preto-Attitude",
														"path": "c2755a74-853c-4e52-8e35-49406b7d1ae6.png",
														"url": "https://staging-media.toyota.com.ar/c2755a74-853c-4e52-8e35-49406b7d1ae6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "RAV4-218-Preto-Attitude",
														"path": "1370d6de-f0f4-4d96-b0be-9b8ffb26cdd2.png",
														"url": "https://staging-media.toyota.com.ar/1370d6de-f0f4-4d96-b0be-9b8ffb26cdd2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "RAV4-1G3-Cinza-Granito",
														"path": "4245ac06-79d3-46d1-b92a-0ac25f5ee4be.png",
														"url": "https://staging-media.toyota.com.ar/4245ac06-79d3-46d1-b92a-0ac25f5ee4be.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "RAV4-mobile-1G3-Cinza-Granito",
														"path": "0bcee060-9eec-4ddc-990b-f59dc26d1157.png",
														"url": "https://staging-media.toyota.com.ar/0bcee060-9eec-4ddc-990b-f59dc26d1157.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "RAV4-1D6-Prata-Névoa",
														"path": "eeacfc89-d615-4296-9ae9-bd85ccaf469e.png",
														"url": "https://staging-media.toyota.com.ar/eeacfc89-d615-4296-9ae9-bd85ccaf469e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "RAV4-mobile-1D6-Prata-Névoa",
														"path": "80d8f054-6698-44cc-89d0-65afad32699b.png",
														"url": "https://staging-media.toyota.com.ar/80d8f054-6698-44cc-89d0-65afad32699b.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 308690,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 8",
														"path": "7028337c-fa7e-4ee2-92b4-9a53fa9d07aa.jpeg",
														"url": "https://staging-media.toyota.com.ar/7028337c-fa7e-4ee2-92b4-9a53fa9d07aa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 9",
														"path": "76d43fa3-60cc-4767-98b7-acc223f1031f.jpeg",
														"url": "https://staging-media.toyota.com.ar/76d43fa3-60cc-4767-98b7-acc223f1031f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "00ac517c-5108-4652-b092-a2deb3cd031e.jpeg",
														"url": "https://staging-media.toyota.com.ar/00ac517c-5108-4652-b092-a2deb3cd031e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "863f1255-6506-4424-9721-3af80f6b3e5a.jpeg",
														"url": "https://staging-media.toyota.com.ar/863f1255-6506-4424-9721-3af80f6b3e5a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "28d99b47-b675-4dcc-bbb0-b2369b8b91ea.jpeg",
														"url": "https://staging-media.toyota.com.ar/28d99b47-b675-4dcc-bbb0-b2369b8b91ea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "fb06d4bf-240e-4b76-819e-3f951bdd5c75.jpeg",
														"url": "https://staging-media.toyota.com.ar/fb06d4bf-240e-4b76-819e-3f951bdd5c75.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "20fe6680-980b-481e-8021-d35b6162e81a.jpeg",
														"url": "https://staging-media.toyota.com.ar/20fe6680-980b-481e-8021-d35b6162e81a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "fe4ed795-cf45-4c9b-a33c-56c895513f0e.jpeg",
														"url": "https://staging-media.toyota.com.ar/fe4ed795-cf45-4c9b-a33c-56c895513f0e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "54b14b0f-75ea-4f83-8b7d-ca06514c4ffa.jpeg",
														"url": "https://staging-media.toyota.com.ar/54b14b0f-75ea-4f83-8b7d-ca06514c4ffa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 1",
														"path": "ff73fa0b-31d0-4e8a-a738-8a926c89d8fe.jpeg",
														"url": "https://staging-media.toyota.com.ar/ff73fa0b-31d0-4e8a-a738-8a926c89d8fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 2",
														"path": "d5d66678-2c63-4a08-a881-2efea4cde296.jpeg",
														"url": "https://staging-media.toyota.com.ar/d5d66678-2c63-4a08-a881-2efea4cde296.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 3",
														"path": "b61ad640-f9e5-4ff0-ab68-86f1acdb3278.jpeg",
														"url": "https://staging-media.toyota.com.ar/b61ad640-f9e5-4ff0-ab68-86f1acdb3278.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 4",
														"path": "a29372fa-4deb-406d-9754-3a7ef2d63265.jpeg",
														"url": "https://staging-media.toyota.com.ar/a29372fa-4deb-406d-9754-3a7ef2d63265.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 5",
														"path": "fd33c759-d5e1-4cf4-a994-8bf744e1f4cc.jpeg",
														"url": "https://staging-media.toyota.com.ar/fd33c759-d5e1-4cf4-a994-8bf744e1f4cc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 6",
														"path": "2b858fca-195e-42e0-a3de-a20af85a9e0d.jpeg",
														"url": "https://staging-media.toyota.com.ar/2b858fca-195e-42e0-a3de-a20af85a9e0d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 7",
														"path": "32b40ca9-0e37-4001-875b-10e444a44ba7.jpeg",
														"url": "https://staging-media.toyota.com.ar/32b40ca9-0e37-4001-875b-10e444a44ba7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display Rav4 lateral",
														"path": "b0a1bece-78ab-4776-8de4-e849793a78ec.png",
														"url": "https://staging-media.toyota.com.ar/b0a1bece-78ab-4776-8de4-e849793a78ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display Rav4 angulado",
														"path": "d183ed0f-67bb-44e3-93da-09ca5f89cd3a.png",
														"url": "https://staging-media.toyota.com.ar/d183ed0f-67bb-44e3-93da-09ca5f89cd3a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large RAV4",
														"path": "3add62f2-1412-4ab8-8c4f-f7d41bb47cfd.jpeg",
														"url": "https://staging-media.toyota.com.ar/3add62f2-1412-4ab8-8c4f-f7d41bb47cfd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner Large RAV4",
														"path": "e5e8d90e-1146-48de-b62e-b96d8f7a4f70.jpeg",
														"url": "https://staging-media.toyota.com.ar/e5e8d90e-1146-48de-b62e-b96d8f7a4f70.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo R4VA",
														"path": "572d3beb-9c2b-4cdb-8493-53012ce2a972.png",
														"url": "https://staging-media.toyota.com.ar/572d3beb-9c2b-4cdb-8493-53012ce2a972.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "RAV4-070-Branco-Pérola",
														"path": "b9e4d4c9-48d5-4fa6-bfda-9e094da2707b.png",
														"url": "https://staging-media.toyota.com.ar/b9e4d4c9-48d5-4fa6-bfda-9e094da2707b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "RAV4-mobile-070-Branco-Pérola",
														"path": "d645433e-9025-44e1-93b8-36f1ef55d419.png",
														"url": "https://staging-media.toyota.com.ar/d645433e-9025-44e1-93b8-36f1ef55d419.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X8",
													"media": {
														"title": "RAV4-8X8-Azul-Topázio",
														"path": "2893a23b-4270-4200-8962-3c11cfd18dfe.png",
														"url": "https://staging-media.toyota.com.ar/2893a23b-4270-4200-8962-3c11cfd18dfe.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X8",
													"media": {
														"title": "RAV4-mobile-8X8-Azul-Topázio",
														"path": "c41a038b-3c66-4317-8009-869400a0bd33.png",
														"url": "https://staging-media.toyota.com.ar/c41a038b-3c66-4317-8009-869400a0bd33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "RAV4-mobile-218-Preto-Attitude",
														"path": "c2755a74-853c-4e52-8e35-49406b7d1ae6.png",
														"url": "https://staging-media.toyota.com.ar/c2755a74-853c-4e52-8e35-49406b7d1ae6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "RAV4-218-Preto-Attitude",
														"path": "1370d6de-f0f4-4d96-b0be-9b8ffb26cdd2.png",
														"url": "https://staging-media.toyota.com.ar/1370d6de-f0f4-4d96-b0be-9b8ffb26cdd2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "RAV4-1G3-Cinza-Granito",
														"path": "4245ac06-79d3-46d1-b92a-0ac25f5ee4be.png",
														"url": "https://staging-media.toyota.com.ar/4245ac06-79d3-46d1-b92a-0ac25f5ee4be.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "RAV4-mobile-1G3-Cinza-Granito",
														"path": "0bcee060-9eec-4ddc-990b-f59dc26d1157.png",
														"url": "https://staging-media.toyota.com.ar/0bcee060-9eec-4ddc-990b-f59dc26d1157.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "RAV4-1D6-Prata-Névoa",
														"path": "eeacfc89-d615-4296-9ae9-bd85ccaf469e.png",
														"url": "https://staging-media.toyota.com.ar/eeacfc89-d615-4296-9ae9-bd85ccaf469e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "RAV4-mobile-1D6-Prata-Névoa",
														"path": "80d8f054-6698-44cc-89d0-65afad32699b.png",
														"url": "https://staging-media.toyota.com.ar/80d8f054-6698-44cc-89d0-65afad32699b.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 308690,
										"offers": null,
										"color": {
											"name": "Preto Attitude (218)",
											"code": "218",
											"rgb": "#343736"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 8",
														"path": "7028337c-fa7e-4ee2-92b4-9a53fa9d07aa.jpeg",
														"url": "https://staging-media.toyota.com.ar/7028337c-fa7e-4ee2-92b4-9a53fa9d07aa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 9",
														"path": "76d43fa3-60cc-4767-98b7-acc223f1031f.jpeg",
														"url": "https://staging-media.toyota.com.ar/76d43fa3-60cc-4767-98b7-acc223f1031f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "00ac517c-5108-4652-b092-a2deb3cd031e.jpeg",
														"url": "https://staging-media.toyota.com.ar/00ac517c-5108-4652-b092-a2deb3cd031e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "863f1255-6506-4424-9721-3af80f6b3e5a.jpeg",
														"url": "https://staging-media.toyota.com.ar/863f1255-6506-4424-9721-3af80f6b3e5a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "28d99b47-b675-4dcc-bbb0-b2369b8b91ea.jpeg",
														"url": "https://staging-media.toyota.com.ar/28d99b47-b675-4dcc-bbb0-b2369b8b91ea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "fb06d4bf-240e-4b76-819e-3f951bdd5c75.jpeg",
														"url": "https://staging-media.toyota.com.ar/fb06d4bf-240e-4b76-819e-3f951bdd5c75.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "20fe6680-980b-481e-8021-d35b6162e81a.jpeg",
														"url": "https://staging-media.toyota.com.ar/20fe6680-980b-481e-8021-d35b6162e81a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "fe4ed795-cf45-4c9b-a33c-56c895513f0e.jpeg",
														"url": "https://staging-media.toyota.com.ar/fe4ed795-cf45-4c9b-a33c-56c895513f0e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "54b14b0f-75ea-4f83-8b7d-ca06514c4ffa.jpeg",
														"url": "https://staging-media.toyota.com.ar/54b14b0f-75ea-4f83-8b7d-ca06514c4ffa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 1",
														"path": "ff73fa0b-31d0-4e8a-a738-8a926c89d8fe.jpeg",
														"url": "https://staging-media.toyota.com.ar/ff73fa0b-31d0-4e8a-a738-8a926c89d8fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 2",
														"path": "d5d66678-2c63-4a08-a881-2efea4cde296.jpeg",
														"url": "https://staging-media.toyota.com.ar/d5d66678-2c63-4a08-a881-2efea4cde296.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 3",
														"path": "b61ad640-f9e5-4ff0-ab68-86f1acdb3278.jpeg",
														"url": "https://staging-media.toyota.com.ar/b61ad640-f9e5-4ff0-ab68-86f1acdb3278.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 4",
														"path": "a29372fa-4deb-406d-9754-3a7ef2d63265.jpeg",
														"url": "https://staging-media.toyota.com.ar/a29372fa-4deb-406d-9754-3a7ef2d63265.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 5",
														"path": "fd33c759-d5e1-4cf4-a994-8bf744e1f4cc.jpeg",
														"url": "https://staging-media.toyota.com.ar/fd33c759-d5e1-4cf4-a994-8bf744e1f4cc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 6",
														"path": "2b858fca-195e-42e0-a3de-a20af85a9e0d.jpeg",
														"url": "https://staging-media.toyota.com.ar/2b858fca-195e-42e0-a3de-a20af85a9e0d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 7",
														"path": "32b40ca9-0e37-4001-875b-10e444a44ba7.jpeg",
														"url": "https://staging-media.toyota.com.ar/32b40ca9-0e37-4001-875b-10e444a44ba7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display Rav4 lateral",
														"path": "b0a1bece-78ab-4776-8de4-e849793a78ec.png",
														"url": "https://staging-media.toyota.com.ar/b0a1bece-78ab-4776-8de4-e849793a78ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display Rav4 angulado",
														"path": "d183ed0f-67bb-44e3-93da-09ca5f89cd3a.png",
														"url": "https://staging-media.toyota.com.ar/d183ed0f-67bb-44e3-93da-09ca5f89cd3a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large RAV4",
														"path": "3add62f2-1412-4ab8-8c4f-f7d41bb47cfd.jpeg",
														"url": "https://staging-media.toyota.com.ar/3add62f2-1412-4ab8-8c4f-f7d41bb47cfd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner Large RAV4",
														"path": "e5e8d90e-1146-48de-b62e-b96d8f7a4f70.jpeg",
														"url": "https://staging-media.toyota.com.ar/e5e8d90e-1146-48de-b62e-b96d8f7a4f70.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo R4VA",
														"path": "572d3beb-9c2b-4cdb-8493-53012ce2a972.png",
														"url": "https://staging-media.toyota.com.ar/572d3beb-9c2b-4cdb-8493-53012ce2a972.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "RAV4-070-Branco-Pérola",
														"path": "b9e4d4c9-48d5-4fa6-bfda-9e094da2707b.png",
														"url": "https://staging-media.toyota.com.ar/b9e4d4c9-48d5-4fa6-bfda-9e094da2707b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "RAV4-mobile-070-Branco-Pérola",
														"path": "d645433e-9025-44e1-93b8-36f1ef55d419.png",
														"url": "https://staging-media.toyota.com.ar/d645433e-9025-44e1-93b8-36f1ef55d419.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X8",
													"media": {
														"title": "RAV4-8X8-Azul-Topázio",
														"path": "2893a23b-4270-4200-8962-3c11cfd18dfe.png",
														"url": "https://staging-media.toyota.com.ar/2893a23b-4270-4200-8962-3c11cfd18dfe.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X8",
													"media": {
														"title": "RAV4-mobile-8X8-Azul-Topázio",
														"path": "c41a038b-3c66-4317-8009-869400a0bd33.png",
														"url": "https://staging-media.toyota.com.ar/c41a038b-3c66-4317-8009-869400a0bd33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "RAV4-mobile-218-Preto-Attitude",
														"path": "c2755a74-853c-4e52-8e35-49406b7d1ae6.png",
														"url": "https://staging-media.toyota.com.ar/c2755a74-853c-4e52-8e35-49406b7d1ae6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "RAV4-218-Preto-Attitude",
														"path": "1370d6de-f0f4-4d96-b0be-9b8ffb26cdd2.png",
														"url": "https://staging-media.toyota.com.ar/1370d6de-f0f4-4d96-b0be-9b8ffb26cdd2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "RAV4-1G3-Cinza-Granito",
														"path": "4245ac06-79d3-46d1-b92a-0ac25f5ee4be.png",
														"url": "https://staging-media.toyota.com.ar/4245ac06-79d3-46d1-b92a-0ac25f5ee4be.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "RAV4-mobile-1G3-Cinza-Granito",
														"path": "0bcee060-9eec-4ddc-990b-f59dc26d1157.png",
														"url": "https://staging-media.toyota.com.ar/0bcee060-9eec-4ddc-990b-f59dc26d1157.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "RAV4-1D6-Prata-Névoa",
														"path": "eeacfc89-d615-4296-9ae9-bd85ccaf469e.png",
														"url": "https://staging-media.toyota.com.ar/eeacfc89-d615-4296-9ae9-bd85ccaf469e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "RAV4-mobile-1D6-Prata-Névoa",
														"path": "80d8f054-6698-44cc-89d0-65afad32699b.png",
														"url": "https://staging-media.toyota.com.ar/80d8f054-6698-44cc-89d0-65afad32699b.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 308690,
										"offers": null,
										"color": {
											"name": "Azul Topázio (8X8)",
											"code": "8X8",
											"rgb": "#2F3951"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 8",
														"path": "7028337c-fa7e-4ee2-92b4-9a53fa9d07aa.jpeg",
														"url": "https://staging-media.toyota.com.ar/7028337c-fa7e-4ee2-92b4-9a53fa9d07aa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 9",
														"path": "76d43fa3-60cc-4767-98b7-acc223f1031f.jpeg",
														"url": "https://staging-media.toyota.com.ar/76d43fa3-60cc-4767-98b7-acc223f1031f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "00ac517c-5108-4652-b092-a2deb3cd031e.jpeg",
														"url": "https://staging-media.toyota.com.ar/00ac517c-5108-4652-b092-a2deb3cd031e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "863f1255-6506-4424-9721-3af80f6b3e5a.jpeg",
														"url": "https://staging-media.toyota.com.ar/863f1255-6506-4424-9721-3af80f6b3e5a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "28d99b47-b675-4dcc-bbb0-b2369b8b91ea.jpeg",
														"url": "https://staging-media.toyota.com.ar/28d99b47-b675-4dcc-bbb0-b2369b8b91ea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "fb06d4bf-240e-4b76-819e-3f951bdd5c75.jpeg",
														"url": "https://staging-media.toyota.com.ar/fb06d4bf-240e-4b76-819e-3f951bdd5c75.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "20fe6680-980b-481e-8021-d35b6162e81a.jpeg",
														"url": "https://staging-media.toyota.com.ar/20fe6680-980b-481e-8021-d35b6162e81a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "fe4ed795-cf45-4c9b-a33c-56c895513f0e.jpeg",
														"url": "https://staging-media.toyota.com.ar/fe4ed795-cf45-4c9b-a33c-56c895513f0e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "54b14b0f-75ea-4f83-8b7d-ca06514c4ffa.jpeg",
														"url": "https://staging-media.toyota.com.ar/54b14b0f-75ea-4f83-8b7d-ca06514c4ffa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 1",
														"path": "ff73fa0b-31d0-4e8a-a738-8a926c89d8fe.jpeg",
														"url": "https://staging-media.toyota.com.ar/ff73fa0b-31d0-4e8a-a738-8a926c89d8fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 2",
														"path": "d5d66678-2c63-4a08-a881-2efea4cde296.jpeg",
														"url": "https://staging-media.toyota.com.ar/d5d66678-2c63-4a08-a881-2efea4cde296.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 3",
														"path": "b61ad640-f9e5-4ff0-ab68-86f1acdb3278.jpeg",
														"url": "https://staging-media.toyota.com.ar/b61ad640-f9e5-4ff0-ab68-86f1acdb3278.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 4",
														"path": "a29372fa-4deb-406d-9754-3a7ef2d63265.jpeg",
														"url": "https://staging-media.toyota.com.ar/a29372fa-4deb-406d-9754-3a7ef2d63265.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 5",
														"path": "fd33c759-d5e1-4cf4-a994-8bf744e1f4cc.jpeg",
														"url": "https://staging-media.toyota.com.ar/fd33c759-d5e1-4cf4-a994-8bf744e1f4cc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 6",
														"path": "2b858fca-195e-42e0-a3de-a20af85a9e0d.jpeg",
														"url": "https://staging-media.toyota.com.ar/2b858fca-195e-42e0-a3de-a20af85a9e0d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 7",
														"path": "32b40ca9-0e37-4001-875b-10e444a44ba7.jpeg",
														"url": "https://staging-media.toyota.com.ar/32b40ca9-0e37-4001-875b-10e444a44ba7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display Rav4 lateral",
														"path": "b0a1bece-78ab-4776-8de4-e849793a78ec.png",
														"url": "https://staging-media.toyota.com.ar/b0a1bece-78ab-4776-8de4-e849793a78ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display Rav4 angulado",
														"path": "d183ed0f-67bb-44e3-93da-09ca5f89cd3a.png",
														"url": "https://staging-media.toyota.com.ar/d183ed0f-67bb-44e3-93da-09ca5f89cd3a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large RAV4",
														"path": "3add62f2-1412-4ab8-8c4f-f7d41bb47cfd.jpeg",
														"url": "https://staging-media.toyota.com.ar/3add62f2-1412-4ab8-8c4f-f7d41bb47cfd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner Large RAV4",
														"path": "e5e8d90e-1146-48de-b62e-b96d8f7a4f70.jpeg",
														"url": "https://staging-media.toyota.com.ar/e5e8d90e-1146-48de-b62e-b96d8f7a4f70.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo R4VA",
														"path": "572d3beb-9c2b-4cdb-8493-53012ce2a972.png",
														"url": "https://staging-media.toyota.com.ar/572d3beb-9c2b-4cdb-8493-53012ce2a972.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "RAV4-070-Branco-Pérola",
														"path": "b9e4d4c9-48d5-4fa6-bfda-9e094da2707b.png",
														"url": "https://staging-media.toyota.com.ar/b9e4d4c9-48d5-4fa6-bfda-9e094da2707b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "RAV4-mobile-070-Branco-Pérola",
														"path": "d645433e-9025-44e1-93b8-36f1ef55d419.png",
														"url": "https://staging-media.toyota.com.ar/d645433e-9025-44e1-93b8-36f1ef55d419.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X8",
													"media": {
														"title": "RAV4-8X8-Azul-Topázio",
														"path": "2893a23b-4270-4200-8962-3c11cfd18dfe.png",
														"url": "https://staging-media.toyota.com.ar/2893a23b-4270-4200-8962-3c11cfd18dfe.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X8",
													"media": {
														"title": "RAV4-mobile-8X8-Azul-Topázio",
														"path": "c41a038b-3c66-4317-8009-869400a0bd33.png",
														"url": "https://staging-media.toyota.com.ar/c41a038b-3c66-4317-8009-869400a0bd33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "RAV4-mobile-218-Preto-Attitude",
														"path": "c2755a74-853c-4e52-8e35-49406b7d1ae6.png",
														"url": "https://staging-media.toyota.com.ar/c2755a74-853c-4e52-8e35-49406b7d1ae6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "RAV4-218-Preto-Attitude",
														"path": "1370d6de-f0f4-4d96-b0be-9b8ffb26cdd2.png",
														"url": "https://staging-media.toyota.com.ar/1370d6de-f0f4-4d96-b0be-9b8ffb26cdd2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "RAV4-1G3-Cinza-Granito",
														"path": "4245ac06-79d3-46d1-b92a-0ac25f5ee4be.png",
														"url": "https://staging-media.toyota.com.ar/4245ac06-79d3-46d1-b92a-0ac25f5ee4be.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "RAV4-mobile-1G3-Cinza-Granito",
														"path": "0bcee060-9eec-4ddc-990b-f59dc26d1157.png",
														"url": "https://staging-media.toyota.com.ar/0bcee060-9eec-4ddc-990b-f59dc26d1157.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "RAV4-1D6-Prata-Névoa",
														"path": "eeacfc89-d615-4296-9ae9-bd85ccaf469e.png",
														"url": "https://staging-media.toyota.com.ar/eeacfc89-d615-4296-9ae9-bd85ccaf469e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "RAV4-mobile-1D6-Prata-Névoa",
														"path": "80d8f054-6698-44cc-89d0-65afad32699b.png",
														"url": "https://staging-media.toyota.com.ar/80d8f054-6698-44cc-89d0-65afad32699b.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 311120,
										"offers": null,
										"color": {
											"name": "Branco Pérola (070)",
											"code": "070",
											"rgb": "#D1D0CE"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 8",
														"path": "7028337c-fa7e-4ee2-92b4-9a53fa9d07aa.jpeg",
														"url": "https://staging-media.toyota.com.ar/7028337c-fa7e-4ee2-92b4-9a53fa9d07aa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 9",
														"path": "76d43fa3-60cc-4767-98b7-acc223f1031f.jpeg",
														"url": "https://staging-media.toyota.com.ar/76d43fa3-60cc-4767-98b7-acc223f1031f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 1",
														"path": "00ac517c-5108-4652-b092-a2deb3cd031e.jpeg",
														"url": "https://staging-media.toyota.com.ar/00ac517c-5108-4652-b092-a2deb3cd031e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 2",
														"path": "863f1255-6506-4424-9721-3af80f6b3e5a.jpeg",
														"url": "https://staging-media.toyota.com.ar/863f1255-6506-4424-9721-3af80f6b3e5a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 3",
														"path": "28d99b47-b675-4dcc-bbb0-b2369b8b91ea.jpeg",
														"url": "https://staging-media.toyota.com.ar/28d99b47-b675-4dcc-bbb0-b2369b8b91ea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 4",
														"path": "fb06d4bf-240e-4b76-819e-3f951bdd5c75.jpeg",
														"url": "https://staging-media.toyota.com.ar/fb06d4bf-240e-4b76-819e-3f951bdd5c75.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 5",
														"path": "20fe6680-980b-481e-8021-d35b6162e81a.jpeg",
														"url": "https://staging-media.toyota.com.ar/20fe6680-980b-481e-8021-d35b6162e81a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 6",
														"path": "fe4ed795-cf45-4c9b-a33c-56c895513f0e.jpeg",
														"url": "https://staging-media.toyota.com.ar/fe4ed795-cf45-4c9b-a33c-56c895513f0e.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Int 7",
														"path": "54b14b0f-75ea-4f83-8b7d-ca06514c4ffa.jpeg",
														"url": "https://staging-media.toyota.com.ar/54b14b0f-75ea-4f83-8b7d-ca06514c4ffa.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 1",
														"path": "ff73fa0b-31d0-4e8a-a738-8a926c89d8fe.jpeg",
														"url": "https://staging-media.toyota.com.ar/ff73fa0b-31d0-4e8a-a738-8a926c89d8fe.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 2",
														"path": "d5d66678-2c63-4a08-a881-2efea4cde296.jpeg",
														"url": "https://staging-media.toyota.com.ar/d5d66678-2c63-4a08-a881-2efea4cde296.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 3",
														"path": "b61ad640-f9e5-4ff0-ab68-86f1acdb3278.jpeg",
														"url": "https://staging-media.toyota.com.ar/b61ad640-f9e5-4ff0-ab68-86f1acdb3278.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 4",
														"path": "a29372fa-4deb-406d-9754-3a7ef2d63265.jpeg",
														"url": "https://staging-media.toyota.com.ar/a29372fa-4deb-406d-9754-3a7ef2d63265.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 5",
														"path": "fd33c759-d5e1-4cf4-a994-8bf744e1f4cc.jpeg",
														"url": "https://staging-media.toyota.com.ar/fd33c759-d5e1-4cf4-a994-8bf744e1f4cc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 6",
														"path": "2b858fca-195e-42e0-a3de-a20af85a9e0d.jpeg",
														"url": "https://staging-media.toyota.com.ar/2b858fca-195e-42e0-a3de-a20af85a9e0d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "ext 7",
														"path": "32b40ca9-0e37-4001-875b-10e444a44ba7.jpeg",
														"url": "https://staging-media.toyota.com.ar/32b40ca9-0e37-4001-875b-10e444a44ba7.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Display Rav4 lateral",
														"path": "b0a1bece-78ab-4776-8de4-e849793a78ec.png",
														"url": "https://staging-media.toyota.com.ar/b0a1bece-78ab-4776-8de4-e849793a78ec.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Display Rav4 angulado",
														"path": "d183ed0f-67bb-44e3-93da-09ca5f89cd3a.png",
														"url": "https://staging-media.toyota.com.ar/d183ed0f-67bb-44e3-93da-09ca5f89cd3a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "Desktop - Action Banner Large RAV4",
														"path": "3add62f2-1412-4ab8-8c4f-f7d41bb47cfd.jpeg",
														"url": "https://staging-media.toyota.com.ar/3add62f2-1412-4ab8-8c4f-f7d41bb47cfd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "Mobile - Action Banner Large RAV4",
														"path": "e5e8d90e-1146-48de-b62e-b96d8f7a4f70.jpeg",
														"url": "https://staging-media.toyota.com.ar/e5e8d90e-1146-48de-b62e-b96d8f7a4f70.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo R4VA",
														"path": "572d3beb-9c2b-4cdb-8493-53012ce2a972.png",
														"url": "https://staging-media.toyota.com.ar/572d3beb-9c2b-4cdb-8493-53012ce2a972.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:070",
													"media": {
														"title": "RAV4-070-Branco-Pérola",
														"path": "b9e4d4c9-48d5-4fa6-bfda-9e094da2707b.png",
														"url": "https://staging-media.toyota.com.ar/b9e4d4c9-48d5-4fa6-bfda-9e094da2707b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:070",
													"media": {
														"title": "RAV4-mobile-070-Branco-Pérola",
														"path": "d645433e-9025-44e1-93b8-36f1ef55d419.png",
														"url": "https://staging-media.toyota.com.ar/d645433e-9025-44e1-93b8-36f1ef55d419.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X8",
													"media": {
														"title": "RAV4-8X8-Azul-Topázio",
														"path": "2893a23b-4270-4200-8962-3c11cfd18dfe.png",
														"url": "https://staging-media.toyota.com.ar/2893a23b-4270-4200-8962-3c11cfd18dfe.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X8",
													"media": {
														"title": "RAV4-mobile-8X8-Azul-Topázio",
														"path": "c41a038b-3c66-4317-8009-869400a0bd33.png",
														"url": "https://staging-media.toyota.com.ar/c41a038b-3c66-4317-8009-869400a0bd33.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "RAV4-mobile-218-Preto-Attitude",
														"path": "c2755a74-853c-4e52-8e35-49406b7d1ae6.png",
														"url": "https://staging-media.toyota.com.ar/c2755a74-853c-4e52-8e35-49406b7d1ae6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "RAV4-218-Preto-Attitude",
														"path": "1370d6de-f0f4-4d96-b0be-9b8ffb26cdd2.png",
														"url": "https://staging-media.toyota.com.ar/1370d6de-f0f4-4d96-b0be-9b8ffb26cdd2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "RAV4-1G3-Cinza-Granito",
														"path": "4245ac06-79d3-46d1-b92a-0ac25f5ee4be.png",
														"url": "https://staging-media.toyota.com.ar/4245ac06-79d3-46d1-b92a-0ac25f5ee4be.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "RAV4-mobile-1G3-Cinza-Granito",
														"path": "0bcee060-9eec-4ddc-990b-f59dc26d1157.png",
														"url": "https://staging-media.toyota.com.ar/0bcee060-9eec-4ddc-990b-f59dc26d1157.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "RAV4-1D6-Prata-Névoa",
														"path": "eeacfc89-d615-4296-9ae9-bd85ccaf469e.png",
														"url": "https://staging-media.toyota.com.ar/eeacfc89-d615-4296-9ae9-bd85ccaf469e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "RAV4-mobile-1D6-Prata-Névoa",
														"path": "80d8f054-6698-44cc-89d0-65afad32699b.png",
														"url": "https://staging-media.toyota.com.ar/80d8f054-6698-44cc-89d0-65afad32699b.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							},
							{
								"vehicleColors": [
									{
										"price": 343090,
										"offers": null,
										"color": {
											"name": "Prata Névoa (1D6)",
											"code": "1D6",
											"rgb": "#C5CDCB"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "c370c960-bb3d-4e28-a330-83ea96c5419e.png",
														"url": "https://staging-media.toyota.com.ar/c370c960-bb3d-4e28-a330-83ea96c5419e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "INTERNA RAV4 8",
														"path": "ef3a13b7-1275-4253-8c19-ee03c766bd02.png",
														"url": "https://staging-media.toyota.com.ar/ef3a13b7-1275-4253-8c19-ee03c766bd02.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo RAV4",
														"path": "67ba8fc7-a7a6-4480-b095-878d56edd0bd.png",
														"url": "https://staging-media.toyota.com.br/67ba8fc7-a7a6-4480-b095-878d56edd0bd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "22559676-8227-4635-a926-3cd9fbd6ba9f.png",
														"url": "https://staging-media.toyota.com.ar/22559676-8227-4635-a926-3cd9fbd6ba9f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "15ff059f-3f96-48a0-97bc-c81af5e16e8c.png",
														"url": "https://staging-media.toyota.com.ar/15ff059f-3f96-48a0-97bc-c81af5e16e8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "631bb930-d6a1-4c5b-85b3-0b52ba6af8e3.png",
														"url": "https://staging-media.toyota.com.ar/631bb930-d6a1-4c5b-85b3-0b52ba6af8e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "2b03af79-2f6a-4092-ba7c-a4a44ed48723.png",
														"url": "https://staging-media.toyota.com.ar/2b03af79-2f6a-4092-ba7c-a4a44ed48723.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "5800d73b-e09d-4353-8e10-d641f36cb6da.png",
														"url": "https://staging-media.toyota.com.ar/5800d73b-e09d-4353-8e10-d641f36cb6da.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "12f760f1-5615-49a8-a028-f4a2959c0d1b.png",
														"url": "https://staging-media.toyota.com.ar/12f760f1-5615-49a8-a028-f4a2959c0d1b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "dc747f42-7f34-4089-a129-c6cce32c3968.png",
														"url": "https://staging-media.toyota.com.ar/dc747f42-7f34-4089-a129-c6cce32c3968.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "31c3f9d2-d48e-4f4a-bfb2-6467f583d3a4.png",
														"url": "https://staging-media.toyota.com.ar/31c3f9d2-d48e-4f4a-bfb2-6467f583d3a4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e2921adb-a0d0-422a-9f46-ec8b245b39f6.jpeg",
														"url": "https://staging-media.toyota.com.br/e2921adb-a0d0-422a-9f46-ec8b245b39f6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "db44d694-b92f-4f1e-b0d6-87b382afdfd0.jpeg",
														"url": "https://staging-media.toyota.com.br/db44d694-b92f-4f1e-b0d6-87b382afdfd0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "d8d10948-57b3-45ba-8a8c-0bb5c3b4b7c5.png",
														"url": "https://staging-media.toyota.com.ar/d8d10948-57b3-45ba-8a8c-0bb5c3b4b7c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "1",
														"path": "7caeb85b-8126-4b63-8ff7-74d599f1263d.png",
														"url": "https://staging-media.toyota.com.br/7caeb85b-8126-4b63-8ff7-74d599f1263d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "2",
														"path": "149d131e-2152-468f-9374-2af4c0a1b85e.png",
														"url": "https://staging-media.toyota.com.br/149d131e-2152-468f-9374-2af4c0a1b85e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "1436d7a7-482a-4fcf-8491-945c8f601861.png",
														"url": "https://staging-media.toyota.com.br/1436d7a7-482a-4fcf-8491-945c8f601861.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "2",
														"path": "3917cbe0-23ea-433b-9bcc-58a62efb53be.png",
														"url": "https://staging-media.toyota.com.br/3917cbe0-23ea-433b-9bcc-58a62efb53be.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "1",
														"path": "323415ab-8548-49e9-bfaf-a8eea931c8cd.png",
														"url": "https://staging-media.toyota.com.br/323415ab-8548-49e9-bfaf-a8eea931c8cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2",
														"path": "e150bdae-3817-4a84-9ccd-121df68e5bd1.png",
														"url": "https://staging-media.toyota.com.br/e150bdae-3817-4a84-9ccd-121df68e5bd1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X8",
													"media": {
														"title": "1",
														"path": "78fced1f-c52f-4c40-b305-e1015c9fcff6.png",
														"url": "https://staging-media.toyota.com.br/78fced1f-c52f-4c40-b305-e1015c9fcff6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X8",
													"media": {
														"title": "2",
														"path": "d3aa0869-d791-4a5a-9e88-7f6864bf9ad8.png",
														"url": "https://staging-media.toyota.com.br/d3aa0869-d791-4a5a-9e88-7f6864bf9ad8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "d56a63f4-0e86-439a-9fa3-f5d50e9ec5f1.png",
														"url": "https://staging-media.toyota.com.br/d56a63f4-0e86-439a-9fa3-f5d50e9ec5f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "2",
														"path": "18569d6d-dc81-4ad4-ab60-90a09b9b4aaa.png",
														"url": "https://staging-media.toyota.com.br/18569d6d-dc81-4ad4-ab60-90a09b9b4aaa.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 343090,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "c370c960-bb3d-4e28-a330-83ea96c5419e.png",
														"url": "https://staging-media.toyota.com.ar/c370c960-bb3d-4e28-a330-83ea96c5419e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "INTERNA RAV4 8",
														"path": "ef3a13b7-1275-4253-8c19-ee03c766bd02.png",
														"url": "https://staging-media.toyota.com.ar/ef3a13b7-1275-4253-8c19-ee03c766bd02.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo RAV4",
														"path": "67ba8fc7-a7a6-4480-b095-878d56edd0bd.png",
														"url": "https://staging-media.toyota.com.br/67ba8fc7-a7a6-4480-b095-878d56edd0bd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "22559676-8227-4635-a926-3cd9fbd6ba9f.png",
														"url": "https://staging-media.toyota.com.ar/22559676-8227-4635-a926-3cd9fbd6ba9f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "15ff059f-3f96-48a0-97bc-c81af5e16e8c.png",
														"url": "https://staging-media.toyota.com.ar/15ff059f-3f96-48a0-97bc-c81af5e16e8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "631bb930-d6a1-4c5b-85b3-0b52ba6af8e3.png",
														"url": "https://staging-media.toyota.com.ar/631bb930-d6a1-4c5b-85b3-0b52ba6af8e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "2b03af79-2f6a-4092-ba7c-a4a44ed48723.png",
														"url": "https://staging-media.toyota.com.ar/2b03af79-2f6a-4092-ba7c-a4a44ed48723.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "5800d73b-e09d-4353-8e10-d641f36cb6da.png",
														"url": "https://staging-media.toyota.com.ar/5800d73b-e09d-4353-8e10-d641f36cb6da.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "12f760f1-5615-49a8-a028-f4a2959c0d1b.png",
														"url": "https://staging-media.toyota.com.ar/12f760f1-5615-49a8-a028-f4a2959c0d1b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "dc747f42-7f34-4089-a129-c6cce32c3968.png",
														"url": "https://staging-media.toyota.com.ar/dc747f42-7f34-4089-a129-c6cce32c3968.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "31c3f9d2-d48e-4f4a-bfb2-6467f583d3a4.png",
														"url": "https://staging-media.toyota.com.ar/31c3f9d2-d48e-4f4a-bfb2-6467f583d3a4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e2921adb-a0d0-422a-9f46-ec8b245b39f6.jpeg",
														"url": "https://staging-media.toyota.com.br/e2921adb-a0d0-422a-9f46-ec8b245b39f6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "db44d694-b92f-4f1e-b0d6-87b382afdfd0.jpeg",
														"url": "https://staging-media.toyota.com.br/db44d694-b92f-4f1e-b0d6-87b382afdfd0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "d8d10948-57b3-45ba-8a8c-0bb5c3b4b7c5.png",
														"url": "https://staging-media.toyota.com.ar/d8d10948-57b3-45ba-8a8c-0bb5c3b4b7c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "1",
														"path": "7caeb85b-8126-4b63-8ff7-74d599f1263d.png",
														"url": "https://staging-media.toyota.com.br/7caeb85b-8126-4b63-8ff7-74d599f1263d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "2",
														"path": "149d131e-2152-468f-9374-2af4c0a1b85e.png",
														"url": "https://staging-media.toyota.com.br/149d131e-2152-468f-9374-2af4c0a1b85e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "1436d7a7-482a-4fcf-8491-945c8f601861.png",
														"url": "https://staging-media.toyota.com.br/1436d7a7-482a-4fcf-8491-945c8f601861.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "2",
														"path": "3917cbe0-23ea-433b-9bcc-58a62efb53be.png",
														"url": "https://staging-media.toyota.com.br/3917cbe0-23ea-433b-9bcc-58a62efb53be.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "1",
														"path": "323415ab-8548-49e9-bfaf-a8eea931c8cd.png",
														"url": "https://staging-media.toyota.com.br/323415ab-8548-49e9-bfaf-a8eea931c8cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2",
														"path": "e150bdae-3817-4a84-9ccd-121df68e5bd1.png",
														"url": "https://staging-media.toyota.com.br/e150bdae-3817-4a84-9ccd-121df68e5bd1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X8",
													"media": {
														"title": "1",
														"path": "78fced1f-c52f-4c40-b305-e1015c9fcff6.png",
														"url": "https://staging-media.toyota.com.br/78fced1f-c52f-4c40-b305-e1015c9fcff6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X8",
													"media": {
														"title": "2",
														"path": "d3aa0869-d791-4a5a-9e88-7f6864bf9ad8.png",
														"url": "https://staging-media.toyota.com.br/d3aa0869-d791-4a5a-9e88-7f6864bf9ad8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "d56a63f4-0e86-439a-9fa3-f5d50e9ec5f1.png",
														"url": "https://staging-media.toyota.com.br/d56a63f4-0e86-439a-9fa3-f5d50e9ec5f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "2",
														"path": "18569d6d-dc81-4ad4-ab60-90a09b9b4aaa.png",
														"url": "https://staging-media.toyota.com.br/18569d6d-dc81-4ad4-ab60-90a09b9b4aaa.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 343090,
										"offers": null,
										"color": {
											"name": "Preto Attitude (218)",
											"code": "218",
											"rgb": "#343736"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "c370c960-bb3d-4e28-a330-83ea96c5419e.png",
														"url": "https://staging-media.toyota.com.ar/c370c960-bb3d-4e28-a330-83ea96c5419e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "INTERNA RAV4 8",
														"path": "ef3a13b7-1275-4253-8c19-ee03c766bd02.png",
														"url": "https://staging-media.toyota.com.ar/ef3a13b7-1275-4253-8c19-ee03c766bd02.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo RAV4",
														"path": "67ba8fc7-a7a6-4480-b095-878d56edd0bd.png",
														"url": "https://staging-media.toyota.com.br/67ba8fc7-a7a6-4480-b095-878d56edd0bd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "22559676-8227-4635-a926-3cd9fbd6ba9f.png",
														"url": "https://staging-media.toyota.com.ar/22559676-8227-4635-a926-3cd9fbd6ba9f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "15ff059f-3f96-48a0-97bc-c81af5e16e8c.png",
														"url": "https://staging-media.toyota.com.ar/15ff059f-3f96-48a0-97bc-c81af5e16e8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "631bb930-d6a1-4c5b-85b3-0b52ba6af8e3.png",
														"url": "https://staging-media.toyota.com.ar/631bb930-d6a1-4c5b-85b3-0b52ba6af8e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "2b03af79-2f6a-4092-ba7c-a4a44ed48723.png",
														"url": "https://staging-media.toyota.com.ar/2b03af79-2f6a-4092-ba7c-a4a44ed48723.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "5800d73b-e09d-4353-8e10-d641f36cb6da.png",
														"url": "https://staging-media.toyota.com.ar/5800d73b-e09d-4353-8e10-d641f36cb6da.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "12f760f1-5615-49a8-a028-f4a2959c0d1b.png",
														"url": "https://staging-media.toyota.com.ar/12f760f1-5615-49a8-a028-f4a2959c0d1b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "dc747f42-7f34-4089-a129-c6cce32c3968.png",
														"url": "https://staging-media.toyota.com.ar/dc747f42-7f34-4089-a129-c6cce32c3968.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "31c3f9d2-d48e-4f4a-bfb2-6467f583d3a4.png",
														"url": "https://staging-media.toyota.com.ar/31c3f9d2-d48e-4f4a-bfb2-6467f583d3a4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e2921adb-a0d0-422a-9f46-ec8b245b39f6.jpeg",
														"url": "https://staging-media.toyota.com.br/e2921adb-a0d0-422a-9f46-ec8b245b39f6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "db44d694-b92f-4f1e-b0d6-87b382afdfd0.jpeg",
														"url": "https://staging-media.toyota.com.br/db44d694-b92f-4f1e-b0d6-87b382afdfd0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "d8d10948-57b3-45ba-8a8c-0bb5c3b4b7c5.png",
														"url": "https://staging-media.toyota.com.ar/d8d10948-57b3-45ba-8a8c-0bb5c3b4b7c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "1",
														"path": "7caeb85b-8126-4b63-8ff7-74d599f1263d.png",
														"url": "https://staging-media.toyota.com.br/7caeb85b-8126-4b63-8ff7-74d599f1263d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "2",
														"path": "149d131e-2152-468f-9374-2af4c0a1b85e.png",
														"url": "https://staging-media.toyota.com.br/149d131e-2152-468f-9374-2af4c0a1b85e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "1436d7a7-482a-4fcf-8491-945c8f601861.png",
														"url": "https://staging-media.toyota.com.br/1436d7a7-482a-4fcf-8491-945c8f601861.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "2",
														"path": "3917cbe0-23ea-433b-9bcc-58a62efb53be.png",
														"url": "https://staging-media.toyota.com.br/3917cbe0-23ea-433b-9bcc-58a62efb53be.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "1",
														"path": "323415ab-8548-49e9-bfaf-a8eea931c8cd.png",
														"url": "https://staging-media.toyota.com.br/323415ab-8548-49e9-bfaf-a8eea931c8cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2",
														"path": "e150bdae-3817-4a84-9ccd-121df68e5bd1.png",
														"url": "https://staging-media.toyota.com.br/e150bdae-3817-4a84-9ccd-121df68e5bd1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X8",
													"media": {
														"title": "1",
														"path": "78fced1f-c52f-4c40-b305-e1015c9fcff6.png",
														"url": "https://staging-media.toyota.com.br/78fced1f-c52f-4c40-b305-e1015c9fcff6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X8",
													"media": {
														"title": "2",
														"path": "d3aa0869-d791-4a5a-9e88-7f6864bf9ad8.png",
														"url": "https://staging-media.toyota.com.br/d3aa0869-d791-4a5a-9e88-7f6864bf9ad8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "d56a63f4-0e86-439a-9fa3-f5d50e9ec5f1.png",
														"url": "https://staging-media.toyota.com.br/d56a63f4-0e86-439a-9fa3-f5d50e9ec5f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "2",
														"path": "18569d6d-dc81-4ad4-ab60-90a09b9b4aaa.png",
														"url": "https://staging-media.toyota.com.br/18569d6d-dc81-4ad4-ab60-90a09b9b4aaa.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 343090,
										"offers": null,
										"color": {
											"name": "Azul Topázio (8X8)",
											"code": "8X8",
											"rgb": "#2F3951"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "c370c960-bb3d-4e28-a330-83ea96c5419e.png",
														"url": "https://staging-media.toyota.com.ar/c370c960-bb3d-4e28-a330-83ea96c5419e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "INTERNA RAV4 8",
														"path": "ef3a13b7-1275-4253-8c19-ee03c766bd02.png",
														"url": "https://staging-media.toyota.com.ar/ef3a13b7-1275-4253-8c19-ee03c766bd02.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo RAV4",
														"path": "67ba8fc7-a7a6-4480-b095-878d56edd0bd.png",
														"url": "https://staging-media.toyota.com.br/67ba8fc7-a7a6-4480-b095-878d56edd0bd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "22559676-8227-4635-a926-3cd9fbd6ba9f.png",
														"url": "https://staging-media.toyota.com.ar/22559676-8227-4635-a926-3cd9fbd6ba9f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "15ff059f-3f96-48a0-97bc-c81af5e16e8c.png",
														"url": "https://staging-media.toyota.com.ar/15ff059f-3f96-48a0-97bc-c81af5e16e8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "631bb930-d6a1-4c5b-85b3-0b52ba6af8e3.png",
														"url": "https://staging-media.toyota.com.ar/631bb930-d6a1-4c5b-85b3-0b52ba6af8e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "2b03af79-2f6a-4092-ba7c-a4a44ed48723.png",
														"url": "https://staging-media.toyota.com.ar/2b03af79-2f6a-4092-ba7c-a4a44ed48723.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "5800d73b-e09d-4353-8e10-d641f36cb6da.png",
														"url": "https://staging-media.toyota.com.ar/5800d73b-e09d-4353-8e10-d641f36cb6da.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "12f760f1-5615-49a8-a028-f4a2959c0d1b.png",
														"url": "https://staging-media.toyota.com.ar/12f760f1-5615-49a8-a028-f4a2959c0d1b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "dc747f42-7f34-4089-a129-c6cce32c3968.png",
														"url": "https://staging-media.toyota.com.ar/dc747f42-7f34-4089-a129-c6cce32c3968.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "31c3f9d2-d48e-4f4a-bfb2-6467f583d3a4.png",
														"url": "https://staging-media.toyota.com.ar/31c3f9d2-d48e-4f4a-bfb2-6467f583d3a4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e2921adb-a0d0-422a-9f46-ec8b245b39f6.jpeg",
														"url": "https://staging-media.toyota.com.br/e2921adb-a0d0-422a-9f46-ec8b245b39f6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "db44d694-b92f-4f1e-b0d6-87b382afdfd0.jpeg",
														"url": "https://staging-media.toyota.com.br/db44d694-b92f-4f1e-b0d6-87b382afdfd0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "d8d10948-57b3-45ba-8a8c-0bb5c3b4b7c5.png",
														"url": "https://staging-media.toyota.com.ar/d8d10948-57b3-45ba-8a8c-0bb5c3b4b7c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "1",
														"path": "7caeb85b-8126-4b63-8ff7-74d599f1263d.png",
														"url": "https://staging-media.toyota.com.br/7caeb85b-8126-4b63-8ff7-74d599f1263d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "2",
														"path": "149d131e-2152-468f-9374-2af4c0a1b85e.png",
														"url": "https://staging-media.toyota.com.br/149d131e-2152-468f-9374-2af4c0a1b85e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "1436d7a7-482a-4fcf-8491-945c8f601861.png",
														"url": "https://staging-media.toyota.com.br/1436d7a7-482a-4fcf-8491-945c8f601861.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "2",
														"path": "3917cbe0-23ea-433b-9bcc-58a62efb53be.png",
														"url": "https://staging-media.toyota.com.br/3917cbe0-23ea-433b-9bcc-58a62efb53be.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "1",
														"path": "323415ab-8548-49e9-bfaf-a8eea931c8cd.png",
														"url": "https://staging-media.toyota.com.br/323415ab-8548-49e9-bfaf-a8eea931c8cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2",
														"path": "e150bdae-3817-4a84-9ccd-121df68e5bd1.png",
														"url": "https://staging-media.toyota.com.br/e150bdae-3817-4a84-9ccd-121df68e5bd1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X8",
													"media": {
														"title": "1",
														"path": "78fced1f-c52f-4c40-b305-e1015c9fcff6.png",
														"url": "https://staging-media.toyota.com.br/78fced1f-c52f-4c40-b305-e1015c9fcff6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X8",
													"media": {
														"title": "2",
														"path": "d3aa0869-d791-4a5a-9e88-7f6864bf9ad8.png",
														"url": "https://staging-media.toyota.com.br/d3aa0869-d791-4a5a-9e88-7f6864bf9ad8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "d56a63f4-0e86-439a-9fa3-f5d50e9ec5f1.png",
														"url": "https://staging-media.toyota.com.br/d56a63f4-0e86-439a-9fa3-f5d50e9ec5f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "2",
														"path": "18569d6d-dc81-4ad4-ab60-90a09b9b4aaa.png",
														"url": "https://staging-media.toyota.com.br/18569d6d-dc81-4ad4-ab60-90a09b9b4aaa.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 345520,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "display:hover",
													"media": {
														"title": "02",
														"path": "c370c960-bb3d-4e28-a330-83ea96c5419e.png",
														"url": "https://staging-media.toyota.com.ar/c370c960-bb3d-4e28-a330-83ea96c5419e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "INTERNA RAV4 8",
														"path": "ef3a13b7-1275-4253-8c19-ee03c766bd02.png",
														"url": "https://staging-media.toyota.com.ar/ef3a13b7-1275-4253-8c19-ee03c766bd02.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo RAV4",
														"path": "67ba8fc7-a7a6-4480-b095-878d56edd0bd.png",
														"url": "https://staging-media.toyota.com.br/67ba8fc7-a7a6-4480-b095-878d56edd0bd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "22559676-8227-4635-a926-3cd9fbd6ba9f.png",
														"url": "https://staging-media.toyota.com.ar/22559676-8227-4635-a926-3cd9fbd6ba9f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "15ff059f-3f96-48a0-97bc-c81af5e16e8c.png",
														"url": "https://staging-media.toyota.com.ar/15ff059f-3f96-48a0-97bc-c81af5e16e8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "631bb930-d6a1-4c5b-85b3-0b52ba6af8e3.png",
														"url": "https://staging-media.toyota.com.ar/631bb930-d6a1-4c5b-85b3-0b52ba6af8e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "2b03af79-2f6a-4092-ba7c-a4a44ed48723.png",
														"url": "https://staging-media.toyota.com.ar/2b03af79-2f6a-4092-ba7c-a4a44ed48723.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "5800d73b-e09d-4353-8e10-d641f36cb6da.png",
														"url": "https://staging-media.toyota.com.ar/5800d73b-e09d-4353-8e10-d641f36cb6da.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "12f760f1-5615-49a8-a028-f4a2959c0d1b.png",
														"url": "https://staging-media.toyota.com.ar/12f760f1-5615-49a8-a028-f4a2959c0d1b.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "dc747f42-7f34-4089-a129-c6cce32c3968.png",
														"url": "https://staging-media.toyota.com.ar/dc747f42-7f34-4089-a129-c6cce32c3968.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria Interior",
														"path": "31c3f9d2-d48e-4f4a-bfb2-6467f583d3a4.png",
														"url": "https://staging-media.toyota.com.ar/31c3f9d2-d48e-4f4a-bfb2-6467f583d3a4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "e2921adb-a0d0-422a-9f46-ec8b245b39f6.jpeg",
														"url": "https://staging-media.toyota.com.br/e2921adb-a0d0-422a-9f46-ec8b245b39f6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "db44d694-b92f-4f1e-b0d6-87b382afdfd0.jpeg",
														"url": "https://staging-media.toyota.com.br/db44d694-b92f-4f1e-b0d6-87b382afdfd0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "01",
														"path": "d8d10948-57b3-45ba-8a8c-0bb5c3b4b7c5.png",
														"url": "https://staging-media.toyota.com.ar/d8d10948-57b3-45ba-8a8c-0bb5c3b4b7c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"url": "https://staging-media.toyota.com.br/0b979d4d-36a7-459c-ab3d-029aaa1ef0dc.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1D6",
													"media": {
														"title": "1",
														"path": "7caeb85b-8126-4b63-8ff7-74d599f1263d.png",
														"url": "https://staging-media.toyota.com.br/7caeb85b-8126-4b63-8ff7-74d599f1263d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1D6",
													"media": {
														"title": "2",
														"path": "149d131e-2152-468f-9374-2af4c0a1b85e.png",
														"url": "https://staging-media.toyota.com.br/149d131e-2152-468f-9374-2af4c0a1b85e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "1436d7a7-482a-4fcf-8491-945c8f601861.png",
														"url": "https://staging-media.toyota.com.br/1436d7a7-482a-4fcf-8491-945c8f601861.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "2",
														"path": "3917cbe0-23ea-433b-9bcc-58a62efb53be.png",
														"url": "https://staging-media.toyota.com.br/3917cbe0-23ea-433b-9bcc-58a62efb53be.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:218",
													"media": {
														"title": "1",
														"path": "323415ab-8548-49e9-bfaf-a8eea931c8cd.png",
														"url": "https://staging-media.toyota.com.br/323415ab-8548-49e9-bfaf-a8eea931c8cd.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:218",
													"media": {
														"title": "2",
														"path": "e150bdae-3817-4a84-9ccd-121df68e5bd1.png",
														"url": "https://staging-media.toyota.com.br/e150bdae-3817-4a84-9ccd-121df68e5bd1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:8X8",
													"media": {
														"title": "1",
														"path": "78fced1f-c52f-4c40-b305-e1015c9fcff6.png",
														"url": "https://staging-media.toyota.com.br/78fced1f-c52f-4c40-b305-e1015c9fcff6.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:8X8",
													"media": {
														"title": "2",
														"path": "d3aa0869-d791-4a5a-9e88-7f6864bf9ad8.png",
														"url": "https://staging-media.toyota.com.br/d3aa0869-d791-4a5a-9e88-7f6864bf9ad8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "d56a63f4-0e86-439a-9fa3-f5d50e9ec5f1.png",
														"url": "https://staging-media.toyota.com.br/d56a63f4-0e86-439a-9fa3-f5d50e9ec5f1.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "2",
														"path": "18569d6d-dc81-4ad4-ab60-90a09b9b4aaa.png",
														"url": "https://staging-media.toyota.com.br/18569d6d-dc81-4ad4-ab60-90a09b9b4aaa.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "XSE Plug-in Hybrid",
						"id": "ed23b96f-92b5-4e34-a3c3-92b3c6921f52",
						"order": 2,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": null,
										"offers": null,
										"color": {
											"name": "Branco Lunar/Preto Attitude (2PS)",
											"code": "2PS",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:2QZ",
													"media": {
														"title": "1",
														"path": "2881a748-27b8-4bd5-9092-983c689372f4.png",
														"url": "https://staging-media.toyota.com.br/2881a748-27b8-4bd5-9092-983c689372f4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QZ",
													"media": {
														"title": "2",
														"path": "a7f2e616-4e72-495a-bbac-454f89b2586d.png",
														"url": "https://staging-media.toyota.com.br/a7f2e616-4e72-495a-bbac-454f89b2586d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2RA",
													"media": {
														"title": "1",
														"path": "3eda217a-2588-4a20-88f5-1475cd3ca9e8.png",
														"url": "https://staging-media.toyota.com.br/3eda217a-2588-4a20-88f5-1475cd3ca9e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2RA",
													"media": {
														"title": "2",
														"path": "f8a9ef76-5cfc-4c16-9d39-432309eb8f28.png",
														"url": "https://staging-media.toyota.com.br/f8a9ef76-5cfc-4c16-9d39-432309eb8f28.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "1",
														"path": "67bb5fad-eb0f-45bf-a02b-a823c5e17097.png",
														"url": "https://staging-media.toyota.com.br/67bb5fad-eb0f-45bf-a02b-a823c5e17097.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "2",
														"path": "b5470283-021f-4f95-962e-41ce8353a018.png",
														"url": "https://staging-media.toyota.com.br/b5470283-021f-4f95-962e-41ce8353a018.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PS",
													"media": {
														"title": "1",
														"path": "ff7284d0-7d85-42a3-9106-4282ef6239e9.png",
														"url": "https://staging-media.toyota.com.br/ff7284d0-7d85-42a3-9106-4282ef6239e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PS",
													"media": {
														"title": "2",
														"path": "5248a544-3fd4-4160-8cf3-c129c604b4b0.png",
														"url": "https://staging-media.toyota.com.br/5248a544-3fd4-4160-8cf3-c129c604b4b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2SC",
													"media": {
														"title": "1",
														"path": "6f039152-2d4f-43bd-9256-4890d8464ced.png",
														"url": "https://staging-media.toyota.com.br/6f039152-2d4f-43bd-9256-4890d8464ced.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2SC",
													"media": {
														"title": "2",
														"path": "bdd09b96-74de-43ec-b004-377b0e00b2e5.png",
														"url": "https://staging-media.toyota.com.br/bdd09b96-74de-43ec-b004-377b0e00b2e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": null,
										"offers": null,
										"color": {
											"name": "Prata Névoa/Preto Attitude (2QY)",
											"code": "2QY",
											"rgb": "#C5CDCB"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:2QZ",
													"media": {
														"title": "1",
														"path": "2881a748-27b8-4bd5-9092-983c689372f4.png",
														"url": "https://staging-media.toyota.com.br/2881a748-27b8-4bd5-9092-983c689372f4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QZ",
													"media": {
														"title": "2",
														"path": "a7f2e616-4e72-495a-bbac-454f89b2586d.png",
														"url": "https://staging-media.toyota.com.br/a7f2e616-4e72-495a-bbac-454f89b2586d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2RA",
													"media": {
														"title": "1",
														"path": "3eda217a-2588-4a20-88f5-1475cd3ca9e8.png",
														"url": "https://staging-media.toyota.com.br/3eda217a-2588-4a20-88f5-1475cd3ca9e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2RA",
													"media": {
														"title": "2",
														"path": "f8a9ef76-5cfc-4c16-9d39-432309eb8f28.png",
														"url": "https://staging-media.toyota.com.br/f8a9ef76-5cfc-4c16-9d39-432309eb8f28.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "1",
														"path": "67bb5fad-eb0f-45bf-a02b-a823c5e17097.png",
														"url": "https://staging-media.toyota.com.br/67bb5fad-eb0f-45bf-a02b-a823c5e17097.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "2",
														"path": "b5470283-021f-4f95-962e-41ce8353a018.png",
														"url": "https://staging-media.toyota.com.br/b5470283-021f-4f95-962e-41ce8353a018.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PS",
													"media": {
														"title": "1",
														"path": "ff7284d0-7d85-42a3-9106-4282ef6239e9.png",
														"url": "https://staging-media.toyota.com.br/ff7284d0-7d85-42a3-9106-4282ef6239e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PS",
													"media": {
														"title": "2",
														"path": "5248a544-3fd4-4160-8cf3-c129c604b4b0.png",
														"url": "https://staging-media.toyota.com.br/5248a544-3fd4-4160-8cf3-c129c604b4b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2SC",
													"media": {
														"title": "1",
														"path": "6f039152-2d4f-43bd-9256-4890d8464ced.png",
														"url": "https://staging-media.toyota.com.br/6f039152-2d4f-43bd-9256-4890d8464ced.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2SC",
													"media": {
														"title": "2",
														"path": "bdd09b96-74de-43ec-b004-377b0e00b2e5.png",
														"url": "https://staging-media.toyota.com.br/bdd09b96-74de-43ec-b004-377b0e00b2e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": null,
										"offers": null,
										"color": {
											"name": "Cinza Metalizado/Preto (2QZ)",
											"code": "2QZ",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:2QZ",
													"media": {
														"title": "1",
														"path": "2881a748-27b8-4bd5-9092-983c689372f4.png",
														"url": "https://staging-media.toyota.com.br/2881a748-27b8-4bd5-9092-983c689372f4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QZ",
													"media": {
														"title": "2",
														"path": "a7f2e616-4e72-495a-bbac-454f89b2586d.png",
														"url": "https://staging-media.toyota.com.br/a7f2e616-4e72-495a-bbac-454f89b2586d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2RA",
													"media": {
														"title": "1",
														"path": "3eda217a-2588-4a20-88f5-1475cd3ca9e8.png",
														"url": "https://staging-media.toyota.com.br/3eda217a-2588-4a20-88f5-1475cd3ca9e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2RA",
													"media": {
														"title": "2",
														"path": "f8a9ef76-5cfc-4c16-9d39-432309eb8f28.png",
														"url": "https://staging-media.toyota.com.br/f8a9ef76-5cfc-4c16-9d39-432309eb8f28.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "1",
														"path": "67bb5fad-eb0f-45bf-a02b-a823c5e17097.png",
														"url": "https://staging-media.toyota.com.br/67bb5fad-eb0f-45bf-a02b-a823c5e17097.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "2",
														"path": "b5470283-021f-4f95-962e-41ce8353a018.png",
														"url": "https://staging-media.toyota.com.br/b5470283-021f-4f95-962e-41ce8353a018.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PS",
													"media": {
														"title": "1",
														"path": "ff7284d0-7d85-42a3-9106-4282ef6239e9.png",
														"url": "https://staging-media.toyota.com.br/ff7284d0-7d85-42a3-9106-4282ef6239e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PS",
													"media": {
														"title": "2",
														"path": "5248a544-3fd4-4160-8cf3-c129c604b4b0.png",
														"url": "https://staging-media.toyota.com.br/5248a544-3fd4-4160-8cf3-c129c604b4b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2SC",
													"media": {
														"title": "1",
														"path": "6f039152-2d4f-43bd-9256-4890d8464ced.png",
														"url": "https://staging-media.toyota.com.br/6f039152-2d4f-43bd-9256-4890d8464ced.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2SC",
													"media": {
														"title": "2",
														"path": "bdd09b96-74de-43ec-b004-377b0e00b2e5.png",
														"url": "https://staging-media.toyota.com.br/bdd09b96-74de-43ec-b004-377b0e00b2e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": null,
										"offers": null,
										"color": {
											"name": "Azul topázio/Preto (2RA)",
											"code": "2RA",
											"rgb": "#2F3951"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:2QZ",
													"media": {
														"title": "1",
														"path": "2881a748-27b8-4bd5-9092-983c689372f4.png",
														"url": "https://staging-media.toyota.com.br/2881a748-27b8-4bd5-9092-983c689372f4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QZ",
													"media": {
														"title": "2",
														"path": "a7f2e616-4e72-495a-bbac-454f89b2586d.png",
														"url": "https://staging-media.toyota.com.br/a7f2e616-4e72-495a-bbac-454f89b2586d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2RA",
													"media": {
														"title": "1",
														"path": "3eda217a-2588-4a20-88f5-1475cd3ca9e8.png",
														"url": "https://staging-media.toyota.com.br/3eda217a-2588-4a20-88f5-1475cd3ca9e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2RA",
													"media": {
														"title": "2",
														"path": "f8a9ef76-5cfc-4c16-9d39-432309eb8f28.png",
														"url": "https://staging-media.toyota.com.br/f8a9ef76-5cfc-4c16-9d39-432309eb8f28.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "1",
														"path": "67bb5fad-eb0f-45bf-a02b-a823c5e17097.png",
														"url": "https://staging-media.toyota.com.br/67bb5fad-eb0f-45bf-a02b-a823c5e17097.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "2",
														"path": "b5470283-021f-4f95-962e-41ce8353a018.png",
														"url": "https://staging-media.toyota.com.br/b5470283-021f-4f95-962e-41ce8353a018.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PS",
													"media": {
														"title": "1",
														"path": "ff7284d0-7d85-42a3-9106-4282ef6239e9.png",
														"url": "https://staging-media.toyota.com.br/ff7284d0-7d85-42a3-9106-4282ef6239e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PS",
													"media": {
														"title": "2",
														"path": "5248a544-3fd4-4160-8cf3-c129c604b4b0.png",
														"url": "https://staging-media.toyota.com.br/5248a544-3fd4-4160-8cf3-c129c604b4b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2SC",
													"media": {
														"title": "1",
														"path": "6f039152-2d4f-43bd-9256-4890d8464ced.png",
														"url": "https://staging-media.toyota.com.br/6f039152-2d4f-43bd-9256-4890d8464ced.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2SC",
													"media": {
														"title": "2",
														"path": "bdd09b96-74de-43ec-b004-377b0e00b2e5.png",
														"url": "https://staging-media.toyota.com.br/bdd09b96-74de-43ec-b004-377b0e00b2e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": null,
										"offers": null,
										"color": {
											"name": "Vermelho Emoção/Preto (2SC)",
											"code": "2SC",
											"rgb": "#C40000"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:desk:2QZ",
													"media": {
														"title": "1",
														"path": "2881a748-27b8-4bd5-9092-983c689372f4.png",
														"url": "https://staging-media.toyota.com.br/2881a748-27b8-4bd5-9092-983c689372f4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QZ",
													"media": {
														"title": "2",
														"path": "a7f2e616-4e72-495a-bbac-454f89b2586d.png",
														"url": "https://staging-media.toyota.com.br/a7f2e616-4e72-495a-bbac-454f89b2586d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2RA",
													"media": {
														"title": "1",
														"path": "3eda217a-2588-4a20-88f5-1475cd3ca9e8.png",
														"url": "https://staging-media.toyota.com.br/3eda217a-2588-4a20-88f5-1475cd3ca9e8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2RA",
													"media": {
														"title": "2",
														"path": "f8a9ef76-5cfc-4c16-9d39-432309eb8f28.png",
														"url": "https://staging-media.toyota.com.br/f8a9ef76-5cfc-4c16-9d39-432309eb8f28.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2QY",
													"media": {
														"title": "1",
														"path": "67bb5fad-eb0f-45bf-a02b-a823c5e17097.png",
														"url": "https://staging-media.toyota.com.br/67bb5fad-eb0f-45bf-a02b-a823c5e17097.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2QY",
													"media": {
														"title": "2",
														"path": "b5470283-021f-4f95-962e-41ce8353a018.png",
														"url": "https://staging-media.toyota.com.br/b5470283-021f-4f95-962e-41ce8353a018.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PS",
													"media": {
														"title": "1",
														"path": "ff7284d0-7d85-42a3-9106-4282ef6239e9.png",
														"url": "https://staging-media.toyota.com.br/ff7284d0-7d85-42a3-9106-4282ef6239e9.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PS",
													"media": {
														"title": "2",
														"path": "5248a544-3fd4-4160-8cf3-c129c604b4b0.png",
														"url": "https://staging-media.toyota.com.br/5248a544-3fd4-4160-8cf3-c129c604b4b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2SC",
													"media": {
														"title": "1",
														"path": "6f039152-2d4f-43bd-9256-4890d8464ced.png",
														"url": "https://staging-media.toyota.com.br/6f039152-2d4f-43bd-9256-4890d8464ced.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2SC",
													"media": {
														"title": "2",
														"path": "bdd09b96-74de-43ec-b004-377b0e00b2e5.png",
														"url": "https://staging-media.toyota.com.br/bdd09b96-74de-43ec-b004-377b0e00b2e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					}
				]
			},
			{
				"id": "87cbb53a-8c81-43f8-a00d-2b44b8ac316a",
				"name": "GR Corolla",
				"vehicleExternalId": null,
				"status": "ENABLED",
				"category": {
					"name": "Esportivos",
					"id": "a7f40ef5-2c0a-4e3a-afca-0b5ce8e9700f"
				},
				"versions": [
					{
						"name": "Core",
						"id": "e1a16bd3-6167-4b36-b370-ccc116c970f8",
						"order": 1,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 416990,
										"offers": null,
										"color": {
											"name": "Super White (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "GR COROLLA - CORE - BANNER - MOBILE",
														"path": "1f77b131-9071-44e1-a3a8-f9fe752f1ff0.png",
														"url": "https://staging-media.toyota.com.br/1f77b131-9071-44e1-a3a8-f9fe752f1ff0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "c105c67b-0a6e-402d-863a-4cbe37f3a062.png",
														"url": "https://staging-media.toyota.com.br/c105c67b-0a6e-402d-863a-4cbe37f3a062.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "379b87ee-e744-43d5-b9ab-6f6e231a8fef.png",
														"url": "https://staging-media.toyota.com.br/379b87ee-e744-43d5-b9ab-6f6e231a8fef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:202",
													"media": {
														"title": "202",
														"path": "bcec1307-bb06-47fe-91de-644f32416b05.png",
														"url": "https://staging-media.toyota.com.br/bcec1307-bb06-47fe-91de-644f32416b05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:202",
													"media": {
														"title": "202",
														"path": "1d81e02d-592e-487b-a645-bcacff10fb95.png",
														"url": "https://staging-media.toyota.com.br/1d81e02d-592e-487b-a645-bcacff10fb95.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3U5",
													"media": {
														"title": "3U5",
														"path": "123d45a5-d3a5-4470-8d11-84d96c3aa579.png",
														"url": "https://staging-media.toyota.com.br/123d45a5-d3a5-4470-8d11-84d96c3aa579.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3U5",
													"media": {
														"title": "3U5",
														"path": "8998b1ba-7cd0-4d2d-a6db-21be4b3792f2.png",
														"url": "https://staging-media.toyota.com.br/8998b1ba-7cd0-4d2d-a6db-21be4b3792f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1L5",
													"media": {
														"title": "1L5",
														"path": "d2c977b6-a825-4219-a0ad-f551a8862629.png",
														"url": "https://staging-media.toyota.com.br/d2c977b6-a825-4219-a0ad-f551a8862629.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1L5",
													"media": {
														"title": "1L5",
														"path": "e62bbd4f-ca60-4d9c-95d5-debee74cfe97.png",
														"url": "https://staging-media.toyota.com.br/e62bbd4f-ca60-4d9c-95d5-debee74cfe97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "banner farol grande",
														"path": "66fa2865-6cd3-40fb-a357-88de506af378.png",
														"url": "https://staging-media.toyota.com.br/66fa2865-6cd3-40fb-a357-88de506af378.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo gr 2",
														"path": "e10914dc-a027-485f-8637-46d429b7558d.png",
														"url": "https://staging-media.toyota.com.br/e10914dc-a027-485f-8637-46d429b7558d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "CORE DP 1",
														"path": "6338d0a8-b09f-41dd-96ca-5266e4645d2d.png",
														"url": "https://staging-media.toyota.com.ar/6338d0a8-b09f-41dd-96ca-5266e4645d2d.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 416990,
										"offers": null,
										"color": {
											"name": "Solid Black (202)",
											"code": "202",
											"rgb": "#000000"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "GR COROLLA - CORE - BANNER - MOBILE",
														"path": "1f77b131-9071-44e1-a3a8-f9fe752f1ff0.png",
														"url": "https://staging-media.toyota.com.br/1f77b131-9071-44e1-a3a8-f9fe752f1ff0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "c105c67b-0a6e-402d-863a-4cbe37f3a062.png",
														"url": "https://staging-media.toyota.com.br/c105c67b-0a6e-402d-863a-4cbe37f3a062.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "379b87ee-e744-43d5-b9ab-6f6e231a8fef.png",
														"url": "https://staging-media.toyota.com.br/379b87ee-e744-43d5-b9ab-6f6e231a8fef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:202",
													"media": {
														"title": "202",
														"path": "bcec1307-bb06-47fe-91de-644f32416b05.png",
														"url": "https://staging-media.toyota.com.br/bcec1307-bb06-47fe-91de-644f32416b05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:202",
													"media": {
														"title": "202",
														"path": "1d81e02d-592e-487b-a645-bcacff10fb95.png",
														"url": "https://staging-media.toyota.com.br/1d81e02d-592e-487b-a645-bcacff10fb95.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3U5",
													"media": {
														"title": "3U5",
														"path": "123d45a5-d3a5-4470-8d11-84d96c3aa579.png",
														"url": "https://staging-media.toyota.com.br/123d45a5-d3a5-4470-8d11-84d96c3aa579.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3U5",
													"media": {
														"title": "3U5",
														"path": "8998b1ba-7cd0-4d2d-a6db-21be4b3792f2.png",
														"url": "https://staging-media.toyota.com.br/8998b1ba-7cd0-4d2d-a6db-21be4b3792f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1L5",
													"media": {
														"title": "1L5",
														"path": "d2c977b6-a825-4219-a0ad-f551a8862629.png",
														"url": "https://staging-media.toyota.com.br/d2c977b6-a825-4219-a0ad-f551a8862629.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1L5",
													"media": {
														"title": "1L5",
														"path": "e62bbd4f-ca60-4d9c-95d5-debee74cfe97.png",
														"url": "https://staging-media.toyota.com.br/e62bbd4f-ca60-4d9c-95d5-debee74cfe97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "banner farol grande",
														"path": "66fa2865-6cd3-40fb-a357-88de506af378.png",
														"url": "https://staging-media.toyota.com.br/66fa2865-6cd3-40fb-a357-88de506af378.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo gr 2",
														"path": "e10914dc-a027-485f-8637-46d429b7558d.png",
														"url": "https://staging-media.toyota.com.br/e10914dc-a027-485f-8637-46d429b7558d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "CORE DP 1",
														"path": "6338d0a8-b09f-41dd-96ca-5266e4645d2d.png",
														"url": "https://staging-media.toyota.com.ar/6338d0a8-b09f-41dd-96ca-5266e4645d2d.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 416990,
										"offers": null,
										"color": {
											"name": "Emotional Red (3U5)",
											"code": "3U5",
											"rgb": "#C40000"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "GR COROLLA - CORE - BANNER - MOBILE",
														"path": "1f77b131-9071-44e1-a3a8-f9fe752f1ff0.png",
														"url": "https://staging-media.toyota.com.br/1f77b131-9071-44e1-a3a8-f9fe752f1ff0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "c105c67b-0a6e-402d-863a-4cbe37f3a062.png",
														"url": "https://staging-media.toyota.com.br/c105c67b-0a6e-402d-863a-4cbe37f3a062.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "379b87ee-e744-43d5-b9ab-6f6e231a8fef.png",
														"url": "https://staging-media.toyota.com.br/379b87ee-e744-43d5-b9ab-6f6e231a8fef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:202",
													"media": {
														"title": "202",
														"path": "bcec1307-bb06-47fe-91de-644f32416b05.png",
														"url": "https://staging-media.toyota.com.br/bcec1307-bb06-47fe-91de-644f32416b05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:202",
													"media": {
														"title": "202",
														"path": "1d81e02d-592e-487b-a645-bcacff10fb95.png",
														"url": "https://staging-media.toyota.com.br/1d81e02d-592e-487b-a645-bcacff10fb95.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3U5",
													"media": {
														"title": "3U5",
														"path": "123d45a5-d3a5-4470-8d11-84d96c3aa579.png",
														"url": "https://staging-media.toyota.com.br/123d45a5-d3a5-4470-8d11-84d96c3aa579.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3U5",
													"media": {
														"title": "3U5",
														"path": "8998b1ba-7cd0-4d2d-a6db-21be4b3792f2.png",
														"url": "https://staging-media.toyota.com.br/8998b1ba-7cd0-4d2d-a6db-21be4b3792f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1L5",
													"media": {
														"title": "1L5",
														"path": "d2c977b6-a825-4219-a0ad-f551a8862629.png",
														"url": "https://staging-media.toyota.com.br/d2c977b6-a825-4219-a0ad-f551a8862629.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1L5",
													"media": {
														"title": "1L5",
														"path": "e62bbd4f-ca60-4d9c-95d5-debee74cfe97.png",
														"url": "https://staging-media.toyota.com.br/e62bbd4f-ca60-4d9c-95d5-debee74cfe97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "banner farol grande",
														"path": "66fa2865-6cd3-40fb-a357-88de506af378.png",
														"url": "https://staging-media.toyota.com.br/66fa2865-6cd3-40fb-a357-88de506af378.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo gr 2",
														"path": "e10914dc-a027-485f-8637-46d429b7558d.png",
														"url": "https://staging-media.toyota.com.br/e10914dc-a027-485f-8637-46d429b7558d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "CORE DP 1",
														"path": "6338d0a8-b09f-41dd-96ca-5266e4645d2d.png",
														"url": "https://staging-media.toyota.com.ar/6338d0a8-b09f-41dd-96ca-5266e4645d2d.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 416990,
										"offers": null,
										"color": {
											"name": "Precious Metal (1L5)",
											"code": "1L5",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "GR COROLLA - CORE - BANNER - MOBILE",
														"path": "1f77b131-9071-44e1-a3a8-f9fe752f1ff0.png",
														"url": "https://staging-media.toyota.com.br/1f77b131-9071-44e1-a3a8-f9fe752f1ff0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "c105c67b-0a6e-402d-863a-4cbe37f3a062.png",
														"url": "https://staging-media.toyota.com.br/c105c67b-0a6e-402d-863a-4cbe37f3a062.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "379b87ee-e744-43d5-b9ab-6f6e231a8fef.png",
														"url": "https://staging-media.toyota.com.br/379b87ee-e744-43d5-b9ab-6f6e231a8fef.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:202",
													"media": {
														"title": "202",
														"path": "bcec1307-bb06-47fe-91de-644f32416b05.png",
														"url": "https://staging-media.toyota.com.br/bcec1307-bb06-47fe-91de-644f32416b05.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:202",
													"media": {
														"title": "202",
														"path": "1d81e02d-592e-487b-a645-bcacff10fb95.png",
														"url": "https://staging-media.toyota.com.br/1d81e02d-592e-487b-a645-bcacff10fb95.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3U5",
													"media": {
														"title": "3U5",
														"path": "123d45a5-d3a5-4470-8d11-84d96c3aa579.png",
														"url": "https://staging-media.toyota.com.br/123d45a5-d3a5-4470-8d11-84d96c3aa579.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3U5",
													"media": {
														"title": "3U5",
														"path": "8998b1ba-7cd0-4d2d-a6db-21be4b3792f2.png",
														"url": "https://staging-media.toyota.com.br/8998b1ba-7cd0-4d2d-a6db-21be4b3792f2.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1L5",
													"media": {
														"title": "1L5",
														"path": "d2c977b6-a825-4219-a0ad-f551a8862629.png",
														"url": "https://staging-media.toyota.com.br/d2c977b6-a825-4219-a0ad-f551a8862629.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1L5",
													"media": {
														"title": "1L5",
														"path": "e62bbd4f-ca60-4d9c-95d5-debee74cfe97.png",
														"url": "https://staging-media.toyota.com.br/e62bbd4f-ca60-4d9c-95d5-debee74cfe97.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "banner farol grande",
														"path": "66fa2865-6cd3-40fb-a357-88de506af378.png",
														"url": "https://staging-media.toyota.com.br/66fa2865-6cd3-40fb-a357-88de506af378.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo gr 2",
														"path": "e10914dc-a027-485f-8637-46d429b7558d.png",
														"url": "https://staging-media.toyota.com.br/e10914dc-a027-485f-8637-46d429b7558d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "CORE DP 1",
														"path": "6338d0a8-b09f-41dd-96ca-5266e4645d2d.png",
														"url": "https://staging-media.toyota.com.ar/6338d0a8-b09f-41dd-96ca-5266e4645d2d.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "Circuit",
						"id": "76551e15-5a35-496d-a7f1-c8d57ddd2f67",
						"order": 2,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 461990,
										"offers": null,
										"color": {
											"name": "Super White (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "GR Corolla Banner  Mobile",
														"path": "9a77ca8e-7fc7-4145-a938-a5026a33130f.png",
														"url": "https://staging-media.toyota.com.br/9a77ca8e-7fc7-4145-a938-a5026a33130f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "GR COROLLA - 209 - DESK ",
														"path": "3190003f-8e1b-4347-be79-ddb3c6c2e0e5.png",
														"url": "https://staging-media.toyota.com.br/3190003f-8e1b-4347-be79-ddb3c6c2e0e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "GR COROLLA - 209 - MOBILE ",
														"path": "525654d4-47db-4087-8dc4-287955da5cb7.png",
														"url": "https://staging-media.toyota.com.br/525654d4-47db-4087-8dc4-287955da5cb7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1L5",
													"media": {
														"title": "1L5",
														"path": "ee25731a-a826-4c59-a191-a9030f70fb27.png",
														"url": "https://staging-media.toyota.com.br/ee25731a-a826-4c59-a191-a9030f70fb27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1L5",
													"media": {
														"title": "1L5",
														"path": "ec5b0e15-24fc-4d4c-b063-06213b73527e.png",
														"url": "https://staging-media.toyota.com.br/ec5b0e15-24fc-4d4c-b063-06213b73527e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3U5",
													"media": {
														"title": "3U5",
														"path": "467c9a1e-f23c-4514-b571-254a2c280f1e.png",
														"url": "https://staging-media.toyota.com.br/467c9a1e-f23c-4514-b571-254a2c280f1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3U5",
													"media": {
														"title": "3U5",
														"path": "484efcae-b008-4da2-a803-8a90143c1b90.png",
														"url": "https://staging-media.toyota.com.br/484efcae-b008-4da2-a803-8a90143c1b90.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "e22a5c32-3001-4703-9912-56be8fcf1f7c.png",
														"url": "https://staging-media.toyota.com.br/e22a5c32-3001-4703-9912-56be8fcf1f7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "94e596b2-f5a9-4879-a3eb-573cc6473dd0.png",
														"url": "https://staging-media.toyota.com.br/94e596b2-f5a9-4879-a3eb-573cc6473dd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:202",
													"media": {
														"title": "202",
														"path": "d3729353-b7b6-48c4-b9aa-e9dd9641a27a.png",
														"url": "https://staging-media.toyota.com.br/d3729353-b7b6-48c4-b9aa-e9dd9641a27a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:202",
													"media": {
														"title": "202",
														"path": "8887deaa-3b0d-408f-b49e-aa3e94209a5e.png",
														"url": "https://staging-media.toyota.com.br/8887deaa-3b0d-408f-b49e-aa3e94209a5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "banner farol grande",
														"path": "66fa2865-6cd3-40fb-a357-88de506af378.png",
														"url": "https://staging-media.toyota.com.br/66fa2865-6cd3-40fb-a357-88de506af378.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo gr 2",
														"path": "e10914dc-a027-485f-8637-46d429b7558d.png",
														"url": "https://staging-media.toyota.com.br/e10914dc-a027-485f-8637-46d429b7558d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "CIRCUIT DP 1",
														"path": "33df7314-65d4-4e78-a4d7-52e72781b7e3.png",
														"url": "https://staging-media.toyota.com.ar/33df7314-65d4-4e78-a4d7-52e72781b7e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "CIRCUIT DP 2",
														"path": "ad15cd7d-d74f-424e-88a1-2aa886266c9b.png",
														"url": "https://staging-media.toyota.com.ar/ad15cd7d-d74f-424e-88a1-2aa886266c9b.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 461990,
										"offers": null,
										"color": {
											"name": "Solid Black (202)",
											"code": "202",
											"rgb": "#000000"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "GR Corolla Banner  Mobile",
														"path": "9a77ca8e-7fc7-4145-a938-a5026a33130f.png",
														"url": "https://staging-media.toyota.com.br/9a77ca8e-7fc7-4145-a938-a5026a33130f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "GR COROLLA - 209 - DESK ",
														"path": "3190003f-8e1b-4347-be79-ddb3c6c2e0e5.png",
														"url": "https://staging-media.toyota.com.br/3190003f-8e1b-4347-be79-ddb3c6c2e0e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "GR COROLLA - 209 - MOBILE ",
														"path": "525654d4-47db-4087-8dc4-287955da5cb7.png",
														"url": "https://staging-media.toyota.com.br/525654d4-47db-4087-8dc4-287955da5cb7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1L5",
													"media": {
														"title": "1L5",
														"path": "ee25731a-a826-4c59-a191-a9030f70fb27.png",
														"url": "https://staging-media.toyota.com.br/ee25731a-a826-4c59-a191-a9030f70fb27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1L5",
													"media": {
														"title": "1L5",
														"path": "ec5b0e15-24fc-4d4c-b063-06213b73527e.png",
														"url": "https://staging-media.toyota.com.br/ec5b0e15-24fc-4d4c-b063-06213b73527e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3U5",
													"media": {
														"title": "3U5",
														"path": "467c9a1e-f23c-4514-b571-254a2c280f1e.png",
														"url": "https://staging-media.toyota.com.br/467c9a1e-f23c-4514-b571-254a2c280f1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3U5",
													"media": {
														"title": "3U5",
														"path": "484efcae-b008-4da2-a803-8a90143c1b90.png",
														"url": "https://staging-media.toyota.com.br/484efcae-b008-4da2-a803-8a90143c1b90.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "e22a5c32-3001-4703-9912-56be8fcf1f7c.png",
														"url": "https://staging-media.toyota.com.br/e22a5c32-3001-4703-9912-56be8fcf1f7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "94e596b2-f5a9-4879-a3eb-573cc6473dd0.png",
														"url": "https://staging-media.toyota.com.br/94e596b2-f5a9-4879-a3eb-573cc6473dd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:202",
													"media": {
														"title": "202",
														"path": "d3729353-b7b6-48c4-b9aa-e9dd9641a27a.png",
														"url": "https://staging-media.toyota.com.br/d3729353-b7b6-48c4-b9aa-e9dd9641a27a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:202",
													"media": {
														"title": "202",
														"path": "8887deaa-3b0d-408f-b49e-aa3e94209a5e.png",
														"url": "https://staging-media.toyota.com.br/8887deaa-3b0d-408f-b49e-aa3e94209a5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "banner farol grande",
														"path": "66fa2865-6cd3-40fb-a357-88de506af378.png",
														"url": "https://staging-media.toyota.com.br/66fa2865-6cd3-40fb-a357-88de506af378.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo gr 2",
														"path": "e10914dc-a027-485f-8637-46d429b7558d.png",
														"url": "https://staging-media.toyota.com.br/e10914dc-a027-485f-8637-46d429b7558d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "CIRCUIT DP 1",
														"path": "33df7314-65d4-4e78-a4d7-52e72781b7e3.png",
														"url": "https://staging-media.toyota.com.ar/33df7314-65d4-4e78-a4d7-52e72781b7e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "CIRCUIT DP 2",
														"path": "ad15cd7d-d74f-424e-88a1-2aa886266c9b.png",
														"url": "https://staging-media.toyota.com.ar/ad15cd7d-d74f-424e-88a1-2aa886266c9b.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 461990,
										"offers": null,
										"color": {
											"name": "Emotional Red (3U5)",
											"code": "3U5",
											"rgb": "#C40000"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "GR Corolla Banner  Mobile",
														"path": "9a77ca8e-7fc7-4145-a938-a5026a33130f.png",
														"url": "https://staging-media.toyota.com.br/9a77ca8e-7fc7-4145-a938-a5026a33130f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "GR COROLLA - 209 - DESK ",
														"path": "3190003f-8e1b-4347-be79-ddb3c6c2e0e5.png",
														"url": "https://staging-media.toyota.com.br/3190003f-8e1b-4347-be79-ddb3c6c2e0e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "GR COROLLA - 209 - MOBILE ",
														"path": "525654d4-47db-4087-8dc4-287955da5cb7.png",
														"url": "https://staging-media.toyota.com.br/525654d4-47db-4087-8dc4-287955da5cb7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1L5",
													"media": {
														"title": "1L5",
														"path": "ee25731a-a826-4c59-a191-a9030f70fb27.png",
														"url": "https://staging-media.toyota.com.br/ee25731a-a826-4c59-a191-a9030f70fb27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1L5",
													"media": {
														"title": "1L5",
														"path": "ec5b0e15-24fc-4d4c-b063-06213b73527e.png",
														"url": "https://staging-media.toyota.com.br/ec5b0e15-24fc-4d4c-b063-06213b73527e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3U5",
													"media": {
														"title": "3U5",
														"path": "467c9a1e-f23c-4514-b571-254a2c280f1e.png",
														"url": "https://staging-media.toyota.com.br/467c9a1e-f23c-4514-b571-254a2c280f1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3U5",
													"media": {
														"title": "3U5",
														"path": "484efcae-b008-4da2-a803-8a90143c1b90.png",
														"url": "https://staging-media.toyota.com.br/484efcae-b008-4da2-a803-8a90143c1b90.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "e22a5c32-3001-4703-9912-56be8fcf1f7c.png",
														"url": "https://staging-media.toyota.com.br/e22a5c32-3001-4703-9912-56be8fcf1f7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "94e596b2-f5a9-4879-a3eb-573cc6473dd0.png",
														"url": "https://staging-media.toyota.com.br/94e596b2-f5a9-4879-a3eb-573cc6473dd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:202",
													"media": {
														"title": "202",
														"path": "d3729353-b7b6-48c4-b9aa-e9dd9641a27a.png",
														"url": "https://staging-media.toyota.com.br/d3729353-b7b6-48c4-b9aa-e9dd9641a27a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:202",
													"media": {
														"title": "202",
														"path": "8887deaa-3b0d-408f-b49e-aa3e94209a5e.png",
														"url": "https://staging-media.toyota.com.br/8887deaa-3b0d-408f-b49e-aa3e94209a5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "banner farol grande",
														"path": "66fa2865-6cd3-40fb-a357-88de506af378.png",
														"url": "https://staging-media.toyota.com.br/66fa2865-6cd3-40fb-a357-88de506af378.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo gr 2",
														"path": "e10914dc-a027-485f-8637-46d429b7558d.png",
														"url": "https://staging-media.toyota.com.br/e10914dc-a027-485f-8637-46d429b7558d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "CIRCUIT DP 1",
														"path": "33df7314-65d4-4e78-a4d7-52e72781b7e3.png",
														"url": "https://staging-media.toyota.com.ar/33df7314-65d4-4e78-a4d7-52e72781b7e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "CIRCUIT DP 2",
														"path": "ad15cd7d-d74f-424e-88a1-2aa886266c9b.png",
														"url": "https://staging-media.toyota.com.ar/ad15cd7d-d74f-424e-88a1-2aa886266c9b.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 461990,
										"offers": null,
										"color": {
											"name": "Precious Metal (1L5)",
											"code": "1L5",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "GR Corolla Banner  Mobile",
														"path": "9a77ca8e-7fc7-4145-a938-a5026a33130f.png",
														"url": "https://staging-media.toyota.com.br/9a77ca8e-7fc7-4145-a938-a5026a33130f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "GR COROLLA - 209 - DESK ",
														"path": "3190003f-8e1b-4347-be79-ddb3c6c2e0e5.png",
														"url": "https://staging-media.toyota.com.br/3190003f-8e1b-4347-be79-ddb3c6c2e0e5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "GR COROLLA - 209 - MOBILE ",
														"path": "525654d4-47db-4087-8dc4-287955da5cb7.png",
														"url": "https://staging-media.toyota.com.br/525654d4-47db-4087-8dc4-287955da5cb7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1L5",
													"media": {
														"title": "1L5",
														"path": "ee25731a-a826-4c59-a191-a9030f70fb27.png",
														"url": "https://staging-media.toyota.com.br/ee25731a-a826-4c59-a191-a9030f70fb27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1L5",
													"media": {
														"title": "1L5",
														"path": "ec5b0e15-24fc-4d4c-b063-06213b73527e.png",
														"url": "https://staging-media.toyota.com.br/ec5b0e15-24fc-4d4c-b063-06213b73527e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3U5",
													"media": {
														"title": "3U5",
														"path": "467c9a1e-f23c-4514-b571-254a2c280f1e.png",
														"url": "https://staging-media.toyota.com.br/467c9a1e-f23c-4514-b571-254a2c280f1e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3U5",
													"media": {
														"title": "3U5",
														"path": "484efcae-b008-4da2-a803-8a90143c1b90.png",
														"url": "https://staging-media.toyota.com.br/484efcae-b008-4da2-a803-8a90143c1b90.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040",
														"path": "e22a5c32-3001-4703-9912-56be8fcf1f7c.png",
														"url": "https://staging-media.toyota.com.br/e22a5c32-3001-4703-9912-56be8fcf1f7c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040",
														"path": "94e596b2-f5a9-4879-a3eb-573cc6473dd0.png",
														"url": "https://staging-media.toyota.com.br/94e596b2-f5a9-4879-a3eb-573cc6473dd0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:202",
													"media": {
														"title": "202",
														"path": "d3729353-b7b6-48c4-b9aa-e9dd9641a27a.png",
														"url": "https://staging-media.toyota.com.br/d3729353-b7b6-48c4-b9aa-e9dd9641a27a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:202",
													"media": {
														"title": "202",
														"path": "8887deaa-3b0d-408f-b49e-aa3e94209a5e.png",
														"url": "https://staging-media.toyota.com.br/8887deaa-3b0d-408f-b49e-aa3e94209a5e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Gazoo interesse",
														"path": "49d53197-089c-4671-9b59-1c6c125db36e.png",
														"url": "https://staging-media.toyota.com.br/49d53197-089c-4671-9b59-1c6c125db36e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "banner farol grande",
														"path": "66fa2865-6cd3-40fb-a357-88de506af378.png",
														"url": "https://staging-media.toyota.com.br/66fa2865-6cd3-40fb-a357-88de506af378.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "logo gr 2",
														"path": "e10914dc-a027-485f-8637-46d429b7558d.png",
														"url": "https://staging-media.toyota.com.br/e10914dc-a027-485f-8637-46d429b7558d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "CIRCUIT DP 1",
														"path": "33df7314-65d4-4e78-a4d7-52e72781b7e3.png",
														"url": "https://staging-media.toyota.com.ar/33df7314-65d4-4e78-a4d7-52e72781b7e3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "CIRCUIT DP 2",
														"path": "ad15cd7d-d74f-424e-88a1-2aa886266c9b.png",
														"url": "https://staging-media.toyota.com.ar/ad15cd7d-d74f-424e-88a1-2aa886266c9b.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					}
				]
			},
			{
				"id": "77a2db46-798a-4399-8a8c-2060fa565a50",
				"name": "Corolla Hybrid",
				"vehicleExternalId": null,
				"status": "ENABLED",
				"category": {
					"name": "Híbridos e Híbridos Plug-in",
					"id": "f5ea7b7f-48f8-489b-8df0-c6f3f796c24b"
				},
				"versions": [
					{
						"name": "Altis Hybrid",
						"id": "87c7ff86-b1f4-4cf3-b2d9-0ed357c7356c",
						"order": 1,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 187790,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "1k3 - Mobile ",
														"path": "4f7f6b73-6ad1-4b21-905f-fbc0d87dc02e.png",
														"url": "https://staging-media.toyota.com.br/4f7f6b73-6ad1-4b21-905f-fbc0d87dc02e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria interna - Corolla Híbrido ",
														"path": "54005f78-d7a1-48ca-96ca-b38641ba2b87.jpeg",
														"url": "https://staging-media.toyota.com.br/54005f78-d7a1-48ca-96ca-b38641ba2b87.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria externa - Corolla Híbrido ",
														"path": "3a10a10e-777f-45db-b43b-fc285b5e4a95.jpeg",
														"url": "https://staging-media.toyota.com.br/3a10a10e-777f-45db-b43b-fc285b5e4a95.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"url": "https://staging-media.toyota.com.br/63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"url": "https://staging-media.toyota.com.br/d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "corolla",
														"path": "68efaa17-6dd3-43a7-8a84-6064ee0095ad.jpeg",
														"url": "https://staging-media.toyota.com.br/68efaa17-6dd3-43a7-8a84-6064ee0095ad.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "corolla",
														"path": "a36e173e-d8bd-4a4d-ae6b-1c1607c62ce3.jpeg",
														"url": "https://staging-media.toyota.com.br/a36e173e-d8bd-4a4d-ae6b-1c1607c62ce3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Hybrid - Logo",
														"path": "8e00ffb8-934b-4dcb-81c6-c71af442ccb3.png",
														"url": "https://staging-media.toyota.com.br/8e00ffb8-934b-4dcb-81c6-c71af442ccb3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 - Desk ",
														"path": "c9f9198f-84fb-4469-a44e-591bdb204a27.png",
														"url": "https://staging-media.toyota.com.br/c9f9198f-84fb-4469-a44e-591bdb204a27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 - Mobile",
														"path": "b60b46e3-92b5-429d-9902-e83806d7b7b0.png",
														"url": "https://staging-media.toyota.com.br/b60b46e3-92b5-429d-9902-e83806d7b7b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "1k3 - Desk ",
														"path": "1d094177-5dad-466b-be29-6b27c79ce1c9.png",
														"url": "https://staging-media.toyota.com.br/1d094177-5dad-466b-be29-6b27c79ce1c9.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 189810,
										"offers": null,
										"color": {
											"name": "Cinza Celestial (1K3)",
											"code": "1K3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "1k3 - Mobile ",
														"path": "4f7f6b73-6ad1-4b21-905f-fbc0d87dc02e.png",
														"url": "https://staging-media.toyota.com.br/4f7f6b73-6ad1-4b21-905f-fbc0d87dc02e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "Galeria interna - Corolla Híbrido ",
														"path": "54005f78-d7a1-48ca-96ca-b38641ba2b87.jpeg",
														"url": "https://staging-media.toyota.com.br/54005f78-d7a1-48ca-96ca-b38641ba2b87.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "Galeria externa - Corolla Híbrido ",
														"path": "3a10a10e-777f-45db-b43b-fc285b5e4a95.jpeg",
														"url": "https://staging-media.toyota.com.br/3a10a10e-777f-45db-b43b-fc285b5e4a95.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"url": "https://staging-media.toyota.com.br/63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"url": "https://staging-media.toyota.com.br/d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "corolla",
														"path": "68efaa17-6dd3-43a7-8a84-6064ee0095ad.jpeg",
														"url": "https://staging-media.toyota.com.br/68efaa17-6dd3-43a7-8a84-6064ee0095ad.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "corolla",
														"path": "a36e173e-d8bd-4a4d-ae6b-1c1607c62ce3.jpeg",
														"url": "https://staging-media.toyota.com.br/a36e173e-d8bd-4a4d-ae6b-1c1607c62ce3.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Hybrid - Logo",
														"path": "8e00ffb8-934b-4dcb-81c6-c71af442ccb3.png",
														"url": "https://staging-media.toyota.com.br/8e00ffb8-934b-4dcb-81c6-c71af442ccb3.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "040 - Desk ",
														"path": "c9f9198f-84fb-4469-a44e-591bdb204a27.png",
														"url": "https://staging-media.toyota.com.br/c9f9198f-84fb-4469-a44e-591bdb204a27.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "040 - Mobile",
														"path": "b60b46e3-92b5-429d-9902-e83806d7b7b0.png",
														"url": "https://staging-media.toyota.com.br/b60b46e3-92b5-429d-9902-e83806d7b7b0.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "1k3 - Desk ",
														"path": "1d094177-5dad-466b-be29-6b27c79ce1c9.png",
														"url": "https://staging-media.toyota.com.br/1d094177-5dad-466b-be29-6b27c79ce1c9.png",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					},
					{
						"name": "Altis Hybrid Premium",
						"id": "38c079fd-fdc9-4ac6-9538-534be23722c8",
						"order": 2,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 198890,
										"offers": null,
										"color": {
											"name": "Branco Polar (040)",
											"code": "040",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Hybrid - Desk ",
														"path": "321476ff-8aac-4730-9129-71407e057ff8.png",
														"url": "https://staging-media.toyota.com.br/321476ff-8aac-4730-9129-71407e057ff8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"url": "https://staging-media.toyota.com.br/80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"url": "https://staging-media.toyota.com.br/dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"url": "https://staging-media.toyota.com.br/fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"url": "https://staging-media.toyota.com.br/70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"url": "https://staging-media.toyota.com.br/154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "6",
														"path": "796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"url": "https://staging-media.toyota.com.br/796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"url": "https://staging-media.toyota.com.br/e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"url": "https://staging-media.toyota.com.br/769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"url": "https://staging-media.toyota.com.br/852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"url": "https://staging-media.toyota.com.br/d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"url": "https://staging-media.toyota.com.br/63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"url": "https://staging-media.toyota.com.br/22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "1",
														"path": "91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"url": "https://staging-media.toyota.com.br/91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "1",
														"path": "89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"url": "https://staging-media.toyota.com.br/89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "1",
														"path": "af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"url": "https://staging-media.toyota.com.br/af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "1",
														"path": "510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"url": "https://staging-media.toyota.com.br/510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "1",
														"path": "2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"url": "https://staging-media.toyota.com.br/2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"url": "https://staging-media.toyota.com.br/c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "1",
														"path": "285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"url": "https://staging-media.toyota.com.br/285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"url": "https://staging-media.toyota.com.br/62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "1",
														"path": "66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"url": "https://staging-media.toyota.com.br/66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"url": "https://staging-media.toyota.com.br/3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1",
														"path": "de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"url": "https://staging-media.toyota.com.br/de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"url": "https://staging-media.toyota.com.br/71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "1",
														"path": "db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"url": "https://staging-media.toyota.com.br/db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 200910,
										"offers": null,
										"color": {
											"name": "Cinza Celestial (1K3)",
											"code": "1K3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Hybrid - Desk ",
														"path": "321476ff-8aac-4730-9129-71407e057ff8.png",
														"url": "https://staging-media.toyota.com.br/321476ff-8aac-4730-9129-71407e057ff8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"url": "https://staging-media.toyota.com.br/80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"url": "https://staging-media.toyota.com.br/dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"url": "https://staging-media.toyota.com.br/fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"url": "https://staging-media.toyota.com.br/70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"url": "https://staging-media.toyota.com.br/154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "6",
														"path": "796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"url": "https://staging-media.toyota.com.br/796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"url": "https://staging-media.toyota.com.br/e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"url": "https://staging-media.toyota.com.br/769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"url": "https://staging-media.toyota.com.br/852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"url": "https://staging-media.toyota.com.br/d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"url": "https://staging-media.toyota.com.br/63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"url": "https://staging-media.toyota.com.br/22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "1",
														"path": "91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"url": "https://staging-media.toyota.com.br/91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "1",
														"path": "89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"url": "https://staging-media.toyota.com.br/89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "1",
														"path": "af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"url": "https://staging-media.toyota.com.br/af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "1",
														"path": "510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"url": "https://staging-media.toyota.com.br/510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "1",
														"path": "2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"url": "https://staging-media.toyota.com.br/2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"url": "https://staging-media.toyota.com.br/c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "1",
														"path": "285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"url": "https://staging-media.toyota.com.br/285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"url": "https://staging-media.toyota.com.br/62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "1",
														"path": "66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"url": "https://staging-media.toyota.com.br/66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"url": "https://staging-media.toyota.com.br/3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1",
														"path": "de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"url": "https://staging-media.toyota.com.br/de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"url": "https://staging-media.toyota.com.br/71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "1",
														"path": "db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"url": "https://staging-media.toyota.com.br/db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 200910,
										"offers": null,
										"color": {
											"name": "Prata Supernova (1E7)",
											"code": "1E7",
											"rgb": "#D5D6D8"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Hybrid - Desk ",
														"path": "321476ff-8aac-4730-9129-71407e057ff8.png",
														"url": "https://staging-media.toyota.com.br/321476ff-8aac-4730-9129-71407e057ff8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"url": "https://staging-media.toyota.com.br/80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"url": "https://staging-media.toyota.com.br/dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"url": "https://staging-media.toyota.com.br/fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"url": "https://staging-media.toyota.com.br/70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"url": "https://staging-media.toyota.com.br/154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "6",
														"path": "796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"url": "https://staging-media.toyota.com.br/796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"url": "https://staging-media.toyota.com.br/e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"url": "https://staging-media.toyota.com.br/769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"url": "https://staging-media.toyota.com.br/852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"url": "https://staging-media.toyota.com.br/d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"url": "https://staging-media.toyota.com.br/63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"url": "https://staging-media.toyota.com.br/22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "1",
														"path": "91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"url": "https://staging-media.toyota.com.br/91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "1",
														"path": "89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"url": "https://staging-media.toyota.com.br/89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "1",
														"path": "af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"url": "https://staging-media.toyota.com.br/af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "1",
														"path": "510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"url": "https://staging-media.toyota.com.br/510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "1",
														"path": "2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"url": "https://staging-media.toyota.com.br/2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"url": "https://staging-media.toyota.com.br/c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "1",
														"path": "285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"url": "https://staging-media.toyota.com.br/285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"url": "https://staging-media.toyota.com.br/62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "1",
														"path": "66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"url": "https://staging-media.toyota.com.br/66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"url": "https://staging-media.toyota.com.br/3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1",
														"path": "de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"url": "https://staging-media.toyota.com.br/de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"url": "https://staging-media.toyota.com.br/71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "1",
														"path": "db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"url": "https://staging-media.toyota.com.br/db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 200910,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (3R3)",
											"code": "3R3",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Hybrid - Desk ",
														"path": "321476ff-8aac-4730-9129-71407e057ff8.png",
														"url": "https://staging-media.toyota.com.br/321476ff-8aac-4730-9129-71407e057ff8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"url": "https://staging-media.toyota.com.br/80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"url": "https://staging-media.toyota.com.br/dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"url": "https://staging-media.toyota.com.br/fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"url": "https://staging-media.toyota.com.br/70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"url": "https://staging-media.toyota.com.br/154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "6",
														"path": "796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"url": "https://staging-media.toyota.com.br/796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"url": "https://staging-media.toyota.com.br/e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"url": "https://staging-media.toyota.com.br/769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"url": "https://staging-media.toyota.com.br/852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"url": "https://staging-media.toyota.com.br/d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"url": "https://staging-media.toyota.com.br/63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"url": "https://staging-media.toyota.com.br/22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "1",
														"path": "91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"url": "https://staging-media.toyota.com.br/91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "1",
														"path": "89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"url": "https://staging-media.toyota.com.br/89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "1",
														"path": "af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"url": "https://staging-media.toyota.com.br/af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "1",
														"path": "510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"url": "https://staging-media.toyota.com.br/510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "1",
														"path": "2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"url": "https://staging-media.toyota.com.br/2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"url": "https://staging-media.toyota.com.br/c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "1",
														"path": "285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"url": "https://staging-media.toyota.com.br/285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"url": "https://staging-media.toyota.com.br/62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "1",
														"path": "66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"url": "https://staging-media.toyota.com.br/66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"url": "https://staging-media.toyota.com.br/3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1",
														"path": "de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"url": "https://staging-media.toyota.com.br/de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"url": "https://staging-media.toyota.com.br/71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "1",
														"path": "db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"url": "https://staging-media.toyota.com.br/db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 200910,
										"offers": null,
										"color": {
											"name": "Preto Eclipse (209) ",
											"code": "209",
											"rgb": "#202021"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Hybrid - Desk ",
														"path": "321476ff-8aac-4730-9129-71407e057ff8.png",
														"url": "https://staging-media.toyota.com.br/321476ff-8aac-4730-9129-71407e057ff8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"url": "https://staging-media.toyota.com.br/80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"url": "https://staging-media.toyota.com.br/dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"url": "https://staging-media.toyota.com.br/fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"url": "https://staging-media.toyota.com.br/70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"url": "https://staging-media.toyota.com.br/154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "6",
														"path": "796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"url": "https://staging-media.toyota.com.br/796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"url": "https://staging-media.toyota.com.br/e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"url": "https://staging-media.toyota.com.br/769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"url": "https://staging-media.toyota.com.br/852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"url": "https://staging-media.toyota.com.br/d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"url": "https://staging-media.toyota.com.br/63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"url": "https://staging-media.toyota.com.br/22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "1",
														"path": "91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"url": "https://staging-media.toyota.com.br/91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "1",
														"path": "89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"url": "https://staging-media.toyota.com.br/89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "1",
														"path": "af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"url": "https://staging-media.toyota.com.br/af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "1",
														"path": "510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"url": "https://staging-media.toyota.com.br/510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "1",
														"path": "2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"url": "https://staging-media.toyota.com.br/2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"url": "https://staging-media.toyota.com.br/c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "1",
														"path": "285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"url": "https://staging-media.toyota.com.br/285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"url": "https://staging-media.toyota.com.br/62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "1",
														"path": "66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"url": "https://staging-media.toyota.com.br/66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"url": "https://staging-media.toyota.com.br/3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1",
														"path": "de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"url": "https://staging-media.toyota.com.br/de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"url": "https://staging-media.toyota.com.br/71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "1",
														"path": "db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"url": "https://staging-media.toyota.com.br/db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 200910,
										"offers": null,
										"color": {
											"name": "Cinza Granito (1G3)",
											"code": "1G3",
											"rgb": "#8E9DA7"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Hybrid - Desk ",
														"path": "321476ff-8aac-4730-9129-71407e057ff8.png",
														"url": "https://staging-media.toyota.com.br/321476ff-8aac-4730-9129-71407e057ff8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"url": "https://staging-media.toyota.com.br/80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"url": "https://staging-media.toyota.com.br/dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"url": "https://staging-media.toyota.com.br/fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"url": "https://staging-media.toyota.com.br/70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"url": "https://staging-media.toyota.com.br/154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "6",
														"path": "796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"url": "https://staging-media.toyota.com.br/796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"url": "https://staging-media.toyota.com.br/e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"url": "https://staging-media.toyota.com.br/769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"url": "https://staging-media.toyota.com.br/852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"url": "https://staging-media.toyota.com.br/d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"url": "https://staging-media.toyota.com.br/63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"url": "https://staging-media.toyota.com.br/22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "1",
														"path": "91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"url": "https://staging-media.toyota.com.br/91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "1",
														"path": "89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"url": "https://staging-media.toyota.com.br/89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "1",
														"path": "af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"url": "https://staging-media.toyota.com.br/af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "1",
														"path": "510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"url": "https://staging-media.toyota.com.br/510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "1",
														"path": "2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"url": "https://staging-media.toyota.com.br/2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"url": "https://staging-media.toyota.com.br/c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "1",
														"path": "285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"url": "https://staging-media.toyota.com.br/285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"url": "https://staging-media.toyota.com.br/62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "1",
														"path": "66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"url": "https://staging-media.toyota.com.br/66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"url": "https://staging-media.toyota.com.br/3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1",
														"path": "de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"url": "https://staging-media.toyota.com.br/de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"url": "https://staging-media.toyota.com.br/71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "1",
														"path": "db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"url": "https://staging-media.toyota.com.br/db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 201220,
										"offers": null,
										"color": {
											"name": "Branco Lunar (089)",
											"code": "089",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "logo",
													"media": {
														"title": "Corolla Hybrid - Desk ",
														"path": "321476ff-8aac-4730-9129-71407e057ff8.png",
														"url": "https://staging-media.toyota.com.br/321476ff-8aac-4730-9129-71407e057ff8.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"url": "https://staging-media.toyota.com.br/80cc000d-84a1-41fb-aed1-afce8afb20d8.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"url": "https://staging-media.toyota.com.br/dfde925d-13a0-4b5c-8df4-406dd681b4cd.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"url": "https://staging-media.toyota.com.br/fa32de81-84f6-4bf3-84e8-8329ebe55fd1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"url": "https://staging-media.toyota.com.br/70f220c8-c149-4605-b840-a615d23fae81.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"url": "https://staging-media.toyota.com.br/154fce10-1ebb-4566-ad63-1ef71deac159.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "6",
														"path": "796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"url": "https://staging-media.toyota.com.br/796c5ffe-8c81-4c68-83a1-e592c0432cb0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"url": "https://staging-media.toyota.com.br/e759cbe1-dce0-4d23-8a0b-3a62ac719800.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"url": "https://staging-media.toyota.com.br/769d75de-9a98-4b17-9e54-e9d284251a85.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"url": "https://staging-media.toyota.com.br/852cff03-a518-4995-b69c-7106c8837c5c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"url": "https://staging-media.toyota.com.br/d41f3aa7-a80b-413d-a049-2649ea8194bc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla Hyrbid - Lateral ",
														"path": "63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"url": "https://staging-media.toyota.com.br/63b40dfe-8d19-4f3d-b981-2a78b2043fcc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:040",
													"media": {
														"title": "1",
														"path": "22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"url": "https://staging-media.toyota.com.br/22705afb-ee48-40a8-9dfd-f6da99d96e72.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:040",
													"media": {
														"title": "1",
														"path": "91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"url": "https://staging-media.toyota.com.br/91ab16e1-766a-41ff-a456-bae0aa7c2f2c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1K3",
													"media": {
														"title": "1",
														"path": "89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"url": "https://staging-media.toyota.com.br/89c2f83e-2d3f-4942-ae53-554ebe251e5d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1K3",
													"media": {
														"title": "1",
														"path": "af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"url": "https://staging-media.toyota.com.br/af49d839-85a4-41b2-8e3a-33e7702a2e5f.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1E7",
													"media": {
														"title": "1",
														"path": "510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"url": "https://staging-media.toyota.com.br/510addcf-31e8-4178-8ff8-e70ced0af105.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1E7",
													"media": {
														"title": "1",
														"path": "2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"url": "https://staging-media.toyota.com.br/2c829720-697a-4aa1-bd86-1d7ac4a2afae.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:3R3",
													"media": {
														"title": "1",
														"path": "c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"url": "https://staging-media.toyota.com.br/c3a89211-4f02-4a79-9f75-04878d76ea9d.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:3R3",
													"media": {
														"title": "1",
														"path": "285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"url": "https://staging-media.toyota.com.br/285ed2d6-4bf5-49b2-ba8f-dc48a1258837.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"url": "https://staging-media.toyota.com.br/62ccdb9f-a9cd-46bf-9b9f-a37919243341.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "1",
														"path": "66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"url": "https://staging-media.toyota.com.br/66d5df0f-bc7a-471f-a754-b71d65a51abe.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:1G3",
													"media": {
														"title": "1",
														"path": "3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"url": "https://staging-media.toyota.com.br/3fcd8106-a060-4245-9681-322b60ea71d7.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:1G3",
													"media": {
														"title": "1",
														"path": "de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"url": "https://staging-media.toyota.com.br/de2bd434-2ac2-4105-992f-501fcdcc3bfc.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:089",
													"media": {
														"title": "1",
														"path": "71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"url": "https://staging-media.toyota.com.br/71d8fb3f-51c2-4c3e-83e3-df4d584a236e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:089",
													"media": {
														"title": "1",
														"path": "db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"url": "https://staging-media.toyota.com.br/db649c78-8b40-4724-844d-b2578e5f3c4e.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "1",
														"path": "99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"url": "https://staging-media.toyota.com.br/99a550e5-22cd-4810-9dad-bdf2e928ac1c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"url": "https://staging-media.toyota.com.br/d473d1aa-0754-4c1c-8622-0a916092bc65.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					}
				]
			},
			{
				"id": "3c48ac0a-e267-413d-8112-770f8145f61e",
				"name": "Corolla GR-Sport",
				"vehicleExternalId": null,
				"status": "ENABLED",
				"category": {
					"name": "Esportivos",
					"id": "a7f40ef5-2c0a-4e3a-afca-0b5ce8e9700f"
				},
				"versions": [
					{
						"name": "GR-Sport",
						"id": "cb3df496-8a2c-4b80-ab36-3998d1fc1c61",
						"order": 1,
						"modelYears": [
							{
								"vehicleColors": [
									{
										"price": 181590,
										"offers": null,
										"color": {
											"name": "Vermelho Granada (2NU)",
											"code": "2NU",
											"rgb": "#7E1F23"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "2bbaa755-b51b-4cdd-bd30-bebb642172a1.jpeg",
														"url": "https://staging-media.toyota.com.br/2bbaa755-b51b-4cdd-bd30-bebb642172a1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "9",
														"path": "3cb4e09e-1854-400a-835f-42c77f6f5730.jpeg",
														"url": "https://staging-media.toyota.com.br/3cb4e09e-1854-400a-835f-42c77f6f5730.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "687c4822-7c4b-4f5a-81c3-18eb7dc1cd70.jpeg",
														"url": "https://staging-media.toyota.com.br/687c4822-7c4b-4f5a-81c3-18eb7dc1cd70.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "30dc82b4-0827-40b9-8898-39e1966d9941.jpeg",
														"url": "https://staging-media.toyota.com.br/30dc82b4-0827-40b9-8898-39e1966d9941.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ec3b6635-9e6f-498a-8681-1da749462070.jpeg",
														"url": "https://staging-media.toyota.com.br/ec3b6635-9e6f-498a-8681-1da749462070.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "6f5bf538-b0e2-4694-baeb-80ac33ec3935.jpeg",
														"url": "https://staging-media.toyota.com.br/6f5bf538-b0e2-4694-baeb-80ac33ec3935.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "11",
														"path": "c228321e-2688-436e-acd6-4c2300bf872a.jpeg",
														"url": "https://staging-media.toyota.com.br/c228321e-2688-436e-acd6-4c2300bf872a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "13",
														"path": "8e39f81f-1225-4521-bc18-211297330eea.jpeg",
														"url": "https://staging-media.toyota.com.br/8e39f81f-1225-4521-bc18-211297330eea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "a8591fb3-0dde-45eb-b5d1-be3ccf734210.jpeg",
														"url": "https://staging-media.toyota.com.br/a8591fb3-0dde-45eb-b5d1-be3ccf734210.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "8",
														"path": "97c9fa78-afa3-4d0b-a698-0bd9d406190f.jpeg",
														"url": "https://staging-media.toyota.com.br/97c9fa78-afa3-4d0b-a698-0bd9d406190f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "10",
														"path": "4471d97a-6ffd-4957-999f-193eb1af5bf6.jpeg",
														"url": "https://staging-media.toyota.com.br/4471d97a-6ffd-4957-999f-193eb1af5bf6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "17960ae8-78a6-4b8b-8d73-d3cbf662eaf2.jpeg",
														"url": "https://staging-media.toyota.com.br/17960ae8-78a6-4b8b-8d73-d3cbf662eaf2.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "262cffed-9716-495c-a47e-f8b1866dc9a0.jpeg",
														"url": "https://staging-media.toyota.com.br/262cffed-9716-495c-a47e-f8b1866dc9a0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "1d6ca01d-f0ec-40de-b143-3105890df80c.jpeg",
														"url": "https://staging-media.toyota.com.br/1d6ca01d-f0ec-40de-b143-3105890df80c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "c117515a-89d6-410b-a2ad-c01d58eed522.jpeg",
														"url": "https://staging-media.toyota.com.br/c117515a-89d6-410b-a2ad-c01d58eed522.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "5dfbd7e7-2138-4151-931e-4b9ea9102814.jpeg",
														"url": "https://staging-media.toyota.com.br/5dfbd7e7-2138-4151-931e-4b9ea9102814.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "559eec9c-0716-408f-b123-b3f12759344f.jpeg",
														"url": "https://staging-media.toyota.com.br/559eec9c-0716-408f-b123-b3f12759344f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "a6da2f27-0148-4190-83ba-ef606948d11f.jpeg",
														"url": "https://staging-media.toyota.com.br/a6da2f27-0148-4190-83ba-ef606948d11f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "8f517718-0a88-4c8d-9527-e7d38ffa000d.jpeg",
														"url": "https://staging-media.toyota.com.br/8f517718-0a88-4c8d-9527-e7d38ffa000d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "33f6c995-91a9-44f1-8b17-a28936269b09.jpeg",
														"url": "https://staging-media.toyota.com.br/33f6c995-91a9-44f1-8b17-a28936269b09.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo - GR Sport ",
														"path": "d9ab2a2c-94aa-4d9b-9b20-351ff2abecce.png",
														"url": "https://staging-media.toyota.com.br/d9ab2a2c-94aa-4d9b-9b20-351ff2abecce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla GR-Sport Lateral  ",
														"path": "fcbf662e-3569-44e1-bcef-b29d8ac379c5.png",
														"url": "https://staging-media.toyota.com.br/fcbf662e-3569-44e1-bcef-b29d8ac379c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla GR-Sport - 3/4",
														"path": "d9a7e6a3-298e-4de1-bef9-9426f8249b1a.png",
														"url": "https://staging-media.toyota.com.br/d9a7e6a3-298e-4de1-bef9-9426f8249b1a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "effeea1b-4875-4faf-b4c4-f33aa6c29147.png",
														"url": "https://staging-media.toyota.com.br/effeea1b-4875-4faf-b4c4-f33aa6c29147.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "1",
														"path": "8d71bfde-e124-4605-9662-82a4ffad2aa4.png",
														"url": "https://staging-media.toyota.com.br/8d71bfde-e124-4605-9662-82a4ffad2aa4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PU",
													"media": {
														"title": "1",
														"path": "8e9a735c-c356-44d6-84e7-9a5d0f9ed91c.png",
														"url": "https://staging-media.toyota.com.br/8e9a735c-c356-44d6-84e7-9a5d0f9ed91c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PU",
													"media": {
														"title": "1",
														"path": "4a7e457c-6539-4096-8e22-19f7d3d51712.png",
														"url": "https://staging-media.toyota.com.br/4a7e457c-6539-4096-8e22-19f7d3d51712.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "10",
														"path": "4471d97a-6ffd-4957-999f-193eb1af5bf6.jpeg",
														"url": "https://staging-media.toyota.com.br/4471d97a-6ffd-4957-999f-193eb1af5bf6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "33f6c995-91a9-44f1-8b17-a28936269b09.jpeg",
														"url": "https://staging-media.toyota.com.br/33f6c995-91a9-44f1-8b17-a28936269b09.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2NU",
													"media": {
														"title": "1",
														"path": "f027fda6-6927-4d39-8c8c-1e3efbfbcf8c.png",
														"url": "https://staging-media.toyota.com.br/f027fda6-6927-4d39-8c8c-1e3efbfbcf8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2NU",
													"media": {
														"title": "1",
														"path": "11f8f94c-ccac-4efb-b08d-94ba39b4bada.png",
														"url": "https://staging-media.toyota.com.br/11f8f94c-ccac-4efb-b08d-94ba39b4bada.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "banner ",
														"path": "3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"url": "https://staging-media.toyota.com.br/3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "ff031a64-d069-44da-a819-e4bd63ad54ca.jpeg",
														"url": "https://staging-media.toyota.com.br/ff031a64-d069-44da-a819-e4bd63ad54ca.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 181590,
										"offers": null,
										"color": {
											"name": "Preto Eclipse (209) ",
											"code": "209",
											"rgb": "#202021"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "2bbaa755-b51b-4cdd-bd30-bebb642172a1.jpeg",
														"url": "https://staging-media.toyota.com.br/2bbaa755-b51b-4cdd-bd30-bebb642172a1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "9",
														"path": "3cb4e09e-1854-400a-835f-42c77f6f5730.jpeg",
														"url": "https://staging-media.toyota.com.br/3cb4e09e-1854-400a-835f-42c77f6f5730.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "687c4822-7c4b-4f5a-81c3-18eb7dc1cd70.jpeg",
														"url": "https://staging-media.toyota.com.br/687c4822-7c4b-4f5a-81c3-18eb7dc1cd70.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "30dc82b4-0827-40b9-8898-39e1966d9941.jpeg",
														"url": "https://staging-media.toyota.com.br/30dc82b4-0827-40b9-8898-39e1966d9941.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ec3b6635-9e6f-498a-8681-1da749462070.jpeg",
														"url": "https://staging-media.toyota.com.br/ec3b6635-9e6f-498a-8681-1da749462070.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "6f5bf538-b0e2-4694-baeb-80ac33ec3935.jpeg",
														"url": "https://staging-media.toyota.com.br/6f5bf538-b0e2-4694-baeb-80ac33ec3935.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "11",
														"path": "c228321e-2688-436e-acd6-4c2300bf872a.jpeg",
														"url": "https://staging-media.toyota.com.br/c228321e-2688-436e-acd6-4c2300bf872a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "13",
														"path": "8e39f81f-1225-4521-bc18-211297330eea.jpeg",
														"url": "https://staging-media.toyota.com.br/8e39f81f-1225-4521-bc18-211297330eea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "a8591fb3-0dde-45eb-b5d1-be3ccf734210.jpeg",
														"url": "https://staging-media.toyota.com.br/a8591fb3-0dde-45eb-b5d1-be3ccf734210.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "8",
														"path": "97c9fa78-afa3-4d0b-a698-0bd9d406190f.jpeg",
														"url": "https://staging-media.toyota.com.br/97c9fa78-afa3-4d0b-a698-0bd9d406190f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "10",
														"path": "4471d97a-6ffd-4957-999f-193eb1af5bf6.jpeg",
														"url": "https://staging-media.toyota.com.br/4471d97a-6ffd-4957-999f-193eb1af5bf6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "17960ae8-78a6-4b8b-8d73-d3cbf662eaf2.jpeg",
														"url": "https://staging-media.toyota.com.br/17960ae8-78a6-4b8b-8d73-d3cbf662eaf2.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "262cffed-9716-495c-a47e-f8b1866dc9a0.jpeg",
														"url": "https://staging-media.toyota.com.br/262cffed-9716-495c-a47e-f8b1866dc9a0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "1d6ca01d-f0ec-40de-b143-3105890df80c.jpeg",
														"url": "https://staging-media.toyota.com.br/1d6ca01d-f0ec-40de-b143-3105890df80c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "c117515a-89d6-410b-a2ad-c01d58eed522.jpeg",
														"url": "https://staging-media.toyota.com.br/c117515a-89d6-410b-a2ad-c01d58eed522.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "5dfbd7e7-2138-4151-931e-4b9ea9102814.jpeg",
														"url": "https://staging-media.toyota.com.br/5dfbd7e7-2138-4151-931e-4b9ea9102814.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "559eec9c-0716-408f-b123-b3f12759344f.jpeg",
														"url": "https://staging-media.toyota.com.br/559eec9c-0716-408f-b123-b3f12759344f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "a6da2f27-0148-4190-83ba-ef606948d11f.jpeg",
														"url": "https://staging-media.toyota.com.br/a6da2f27-0148-4190-83ba-ef606948d11f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "8f517718-0a88-4c8d-9527-e7d38ffa000d.jpeg",
														"url": "https://staging-media.toyota.com.br/8f517718-0a88-4c8d-9527-e7d38ffa000d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "33f6c995-91a9-44f1-8b17-a28936269b09.jpeg",
														"url": "https://staging-media.toyota.com.br/33f6c995-91a9-44f1-8b17-a28936269b09.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo - GR Sport ",
														"path": "d9ab2a2c-94aa-4d9b-9b20-351ff2abecce.png",
														"url": "https://staging-media.toyota.com.br/d9ab2a2c-94aa-4d9b-9b20-351ff2abecce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla GR-Sport Lateral  ",
														"path": "fcbf662e-3569-44e1-bcef-b29d8ac379c5.png",
														"url": "https://staging-media.toyota.com.br/fcbf662e-3569-44e1-bcef-b29d8ac379c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla GR-Sport - 3/4",
														"path": "d9a7e6a3-298e-4de1-bef9-9426f8249b1a.png",
														"url": "https://staging-media.toyota.com.br/d9a7e6a3-298e-4de1-bef9-9426f8249b1a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "effeea1b-4875-4faf-b4c4-f33aa6c29147.png",
														"url": "https://staging-media.toyota.com.br/effeea1b-4875-4faf-b4c4-f33aa6c29147.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "1",
														"path": "8d71bfde-e124-4605-9662-82a4ffad2aa4.png",
														"url": "https://staging-media.toyota.com.br/8d71bfde-e124-4605-9662-82a4ffad2aa4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PU",
													"media": {
														"title": "1",
														"path": "8e9a735c-c356-44d6-84e7-9a5d0f9ed91c.png",
														"url": "https://staging-media.toyota.com.br/8e9a735c-c356-44d6-84e7-9a5d0f9ed91c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PU",
													"media": {
														"title": "1",
														"path": "4a7e457c-6539-4096-8e22-19f7d3d51712.png",
														"url": "https://staging-media.toyota.com.br/4a7e457c-6539-4096-8e22-19f7d3d51712.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "10",
														"path": "4471d97a-6ffd-4957-999f-193eb1af5bf6.jpeg",
														"url": "https://staging-media.toyota.com.br/4471d97a-6ffd-4957-999f-193eb1af5bf6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "33f6c995-91a9-44f1-8b17-a28936269b09.jpeg",
														"url": "https://staging-media.toyota.com.br/33f6c995-91a9-44f1-8b17-a28936269b09.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2NU",
													"media": {
														"title": "1",
														"path": "f027fda6-6927-4d39-8c8c-1e3efbfbcf8c.png",
														"url": "https://staging-media.toyota.com.br/f027fda6-6927-4d39-8c8c-1e3efbfbcf8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2NU",
													"media": {
														"title": "1",
														"path": "11f8f94c-ccac-4efb-b08d-94ba39b4bada.png",
														"url": "https://staging-media.toyota.com.br/11f8f94c-ccac-4efb-b08d-94ba39b4bada.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "banner ",
														"path": "3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"url": "https://staging-media.toyota.com.br/3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "ff031a64-d069-44da-a819-e4bd63ad54ca.jpeg",
														"url": "https://staging-media.toyota.com.br/ff031a64-d069-44da-a819-e4bd63ad54ca.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									},
									{
										"price": 181910,
										"offers": null,
										"color": {
											"name": "Branco Lunar (2PU) ",
											"code": "2PU",
											"rgb": "#F4F4F2"
										},
										"modelYear": {
											"medias": [
												{
													"tag": "gallery:internal",
													"media": {
														"title": "1",
														"path": "2bbaa755-b51b-4cdd-bd30-bebb642172a1.jpeg",
														"url": "https://staging-media.toyota.com.br/2bbaa755-b51b-4cdd-bd30-bebb642172a1.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "9",
														"path": "3cb4e09e-1854-400a-835f-42c77f6f5730.jpeg",
														"url": "https://staging-media.toyota.com.br/3cb4e09e-1854-400a-835f-42c77f6f5730.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "2",
														"path": "687c4822-7c4b-4f5a-81c3-18eb7dc1cd70.jpeg",
														"url": "https://staging-media.toyota.com.br/687c4822-7c4b-4f5a-81c3-18eb7dc1cd70.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "3",
														"path": "30dc82b4-0827-40b9-8898-39e1966d9941.jpeg",
														"url": "https://staging-media.toyota.com.br/30dc82b4-0827-40b9-8898-39e1966d9941.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "4",
														"path": "ec3b6635-9e6f-498a-8681-1da749462070.jpeg",
														"url": "https://staging-media.toyota.com.br/ec3b6635-9e6f-498a-8681-1da749462070.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "5",
														"path": "6f5bf538-b0e2-4694-baeb-80ac33ec3935.jpeg",
														"url": "https://staging-media.toyota.com.br/6f5bf538-b0e2-4694-baeb-80ac33ec3935.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "11",
														"path": "c228321e-2688-436e-acd6-4c2300bf872a.jpeg",
														"url": "https://staging-media.toyota.com.br/c228321e-2688-436e-acd6-4c2300bf872a.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "13",
														"path": "8e39f81f-1225-4521-bc18-211297330eea.jpeg",
														"url": "https://staging-media.toyota.com.br/8e39f81f-1225-4521-bc18-211297330eea.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "7",
														"path": "a8591fb3-0dde-45eb-b5d1-be3ccf734210.jpeg",
														"url": "https://staging-media.toyota.com.br/a8591fb3-0dde-45eb-b5d1-be3ccf734210.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "8",
														"path": "97c9fa78-afa3-4d0b-a698-0bd9d406190f.jpeg",
														"url": "https://staging-media.toyota.com.br/97c9fa78-afa3-4d0b-a698-0bd9d406190f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:internal",
													"media": {
														"title": "10",
														"path": "4471d97a-6ffd-4957-999f-193eb1af5bf6.jpeg",
														"url": "https://staging-media.toyota.com.br/4471d97a-6ffd-4957-999f-193eb1af5bf6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "2",
														"path": "17960ae8-78a6-4b8b-8d73-d3cbf662eaf2.jpeg",
														"url": "https://staging-media.toyota.com.br/17960ae8-78a6-4b8b-8d73-d3cbf662eaf2.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "3",
														"path": "262cffed-9716-495c-a47e-f8b1866dc9a0.jpeg",
														"url": "https://staging-media.toyota.com.br/262cffed-9716-495c-a47e-f8b1866dc9a0.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "4",
														"path": "1d6ca01d-f0ec-40de-b143-3105890df80c.jpeg",
														"url": "https://staging-media.toyota.com.br/1d6ca01d-f0ec-40de-b143-3105890df80c.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "5",
														"path": "c117515a-89d6-410b-a2ad-c01d58eed522.jpeg",
														"url": "https://staging-media.toyota.com.br/c117515a-89d6-410b-a2ad-c01d58eed522.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "6",
														"path": "5dfbd7e7-2138-4151-931e-4b9ea9102814.jpeg",
														"url": "https://staging-media.toyota.com.br/5dfbd7e7-2138-4151-931e-4b9ea9102814.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "559eec9c-0716-408f-b123-b3f12759344f.jpeg",
														"url": "https://staging-media.toyota.com.br/559eec9c-0716-408f-b123-b3f12759344f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "7",
														"path": "a6da2f27-0148-4190-83ba-ef606948d11f.jpeg",
														"url": "https://staging-media.toyota.com.br/a6da2f27-0148-4190-83ba-ef606948d11f.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "8",
														"path": "8f517718-0a88-4c8d-9527-e7d38ffa000d.jpeg",
														"url": "https://staging-media.toyota.com.br/8f517718-0a88-4c8d-9527-e7d38ffa000d.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "gallery:external",
													"media": {
														"title": "1",
														"path": "33f6c995-91a9-44f1-8b17-a28936269b09.jpeg",
														"url": "https://staging-media.toyota.com.br/33f6c995-91a9-44f1-8b17-a28936269b09.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "logo",
													"media": {
														"title": "Logo - GR Sport ",
														"path": "d9ab2a2c-94aa-4d9b-9b20-351ff2abecce.png",
														"url": "https://staging-media.toyota.com.br/d9ab2a2c-94aa-4d9b-9b20-351ff2abecce.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:regular",
													"media": {
														"title": "Corolla GR-Sport Lateral  ",
														"path": "fcbf662e-3569-44e1-bcef-b29d8ac379c5.png",
														"url": "https://staging-media.toyota.com.br/fcbf662e-3569-44e1-bcef-b29d8ac379c5.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "display:hover",
													"media": {
														"title": "Corolla GR-Sport - 3/4",
														"path": "d9a7e6a3-298e-4de1-bef9-9426f8249b1a.png",
														"url": "https://staging-media.toyota.com.br/d9a7e6a3-298e-4de1-bef9-9426f8249b1a.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:209",
													"media": {
														"title": "1",
														"path": "effeea1b-4875-4faf-b4c4-f33aa6c29147.png",
														"url": "https://staging-media.toyota.com.br/effeea1b-4875-4faf-b4c4-f33aa6c29147.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:209",
													"media": {
														"title": "1",
														"path": "8d71bfde-e124-4605-9662-82a4ffad2aa4.png",
														"url": "https://staging-media.toyota.com.br/8d71bfde-e124-4605-9662-82a4ffad2aa4.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2PU",
													"media": {
														"title": "1",
														"path": "8e9a735c-c356-44d6-84e7-9a5d0f9ed91c.png",
														"url": "https://staging-media.toyota.com.br/8e9a735c-c356-44d6-84e7-9a5d0f9ed91c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2PU",
													"media": {
														"title": "1",
														"path": "4a7e457c-6539-4096-8e22-19f7d3d51712.png",
														"url": "https://staging-media.toyota.com.br/4a7e457c-6539-4096-8e22-19f7d3d51712.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "internal",
													"media": {
														"title": "10",
														"path": "4471d97a-6ffd-4957-999f-193eb1af5bf6.jpeg",
														"url": "https://staging-media.toyota.com.br/4471d97a-6ffd-4957-999f-193eb1af5bf6.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "external",
													"media": {
														"title": "1",
														"path": "33f6c995-91a9-44f1-8b17-a28936269b09.jpeg",
														"url": "https://staging-media.toyota.com.br/33f6c995-91a9-44f1-8b17-a28936269b09.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:desk:2NU",
													"media": {
														"title": "1",
														"path": "f027fda6-6927-4d39-8c8c-1e3efbfbcf8c.png",
														"url": "https://staging-media.toyota.com.br/f027fda6-6927-4d39-8c8c-1e3efbfbcf8c.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "360:mob:2NU",
													"media": {
														"title": "1",
														"path": "11f8f94c-ccac-4efb-b08d-94ba39b4bada.png",
														"url": "https://staging-media.toyota.com.br/11f8f94c-ccac-4efb-b08d-94ba39b4bada.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:mob",
													"media": {
														"title": "banner ",
														"path": "3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"url": "https://staging-media.toyota.com.br/3ae771c4-963b-482f-83e2-07ad2547c9ed.png",
														"mediaType": {
															"title": "image"
														}
													}
												},
												{
													"tag": "banner-image:desk",
													"media": {
														"title": "1",
														"path": "ff031a64-d069-44da-a819-e4bd63ad54ca.jpeg",
														"url": "https://staging-media.toyota.com.br/ff031a64-d069-44da-a819-e4bd63ad54ca.jpeg",
														"mediaType": {
															"title": "image"
														}
													}
												}
											]
										}
									}
								]
							}
						]
					}
				]
			}
		];

