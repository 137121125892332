const fields = {
  'es-VE': {
    showModelYearFilter: false
  },

  'es-AR': {
    showModelYearFilter: false
  },

  'pt-BR': {
    showModelYearFilter: true
  }
};

export default fields;
