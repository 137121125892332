import React, { useEffect, useState } from 'react';
import AcessoryCard from 'components/molecules/acessory-card';
import Carousel from 'components/molecules/carousel';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule AccessoryListCarousel
 *
 * Carousel for accessories list
 */
function AccessoryListCarousel(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.accessory-list-carousel');

  const { className = '', data = [], children, locale = 'pt-BR', ...other } = props;

  const [isMaxDots, setIsMaxDots] = useState(false);

  const settings = {
    speed: 500,
    useTransform: false,
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1080, //$bp-desktop
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 846, //$bp-tablet
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 600, //$bp-phone
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  useEffect(() => {
    setIsMaxDots(data?.length >= 7);
  }, [data]);

  return (
    <>
      <Carousel
        className={`${css['molecule__accessory-list-carousel-container']} ${isMaxDots && css['lower-dots-gap']} ${className}`}
        settings={settings}
        {...other}>
        {data.map((card, key) => {
          return (
            <AcessoryCard key={key}
              className={css['accessory-list-carousel__item']}
              link={card?.page?.fullpath}
              title={card?.name}
              price={card?.price?.value}
              priceWithoutTax={card?.price?.valueWithoutTax}
              media={card?.media}
              category={card?.category}
              partNumber={card?.partNumber}
              installmentValue={card?.monthlyPrice || undefined}
              locale={locale}
              status={card?.status}
            />
          );
        })}
      </Carousel>
    </>
  );
}

export default AccessoryListCarousel;
