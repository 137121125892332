import { dateBr2Iso, empty, getAttrMFromSessionStorage, getDateAndTime } from 'utils/functions';
import { isTextMask, removeSpecialCharacters, sendRequest } from 'utils/forms';
import { captchaToken } from 'utils/captcha/token';
import { phoneMask, cpfMask, isValidCPF } from 'utils/validations';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import ContentDivider from 'components/atoms/content-divider';
import FormCheckbox from 'components/atoms/form-checkbox';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import getConfig from 'next/config';
import i18n from 'utils/i18n';
import React, { useState, useEffect, useCallback } from 'react';
import Script from 'next/script';
import SuccessSubmitForm from 'components/molecules/success-submit-form';

// loading the sass style fot the component
import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

/**
 * Molecule BuildACarForm
 *
 * <!-- TODO: add a description here! -->
 */

function BuildACarForm(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.build-a-car-form');

  const {
    className = '',
    dealers = [],
    attractingMedia = undefined,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    children,
    carModel = null,
    ...other
  } = props;

  const validationSchema = yup
    .object()
    .shape({
      firstName: yup
        .string()
        .trim()
        .required(t('message_error_required_first_name'))
        .min(3, t('message_error_min_char_first_name')),
      lastName: yup
        .string()
        .trim()
        .required(t('message_error_required_last_name'))
        .min(3, t('message_error_min_char_last_name')),
      customerId: yup
        .string()
        .required(t('message_error_required_cpf'))
        .test('test-invalid-cpf', t('message_error_invalid_cpf'), (cpf) => isValidCPF(cpf)),
      email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
      telephone: yup.string().required(t('message_error_required_phone')).min(14, t('message_error_invalid_min_phone')),
      state: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required_state'))
        .required(t('message_error_required_state')),
      city: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required_city'))
        .required(t('message_error_required_city')),
      dealership: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required_dealership'))
        .required(t('message_error_required_dealership')),
      entryCar: yup.boolean(),
      agreement: yup.boolean().oneOf([true], t('message_error_required_agreement')),
      finance: yup.boolean(),
      used_car: yup.boolean()
    })
    .required();

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      client: false,
      agreement: false,
      drive: false,
      entryCar: false,
      finance: false,
      used_car: false
    }
  });

  const [isDisable, setIsDisable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);

  const requestDate = getDateAndTime();
  const formatRequestDate = dateBr2Iso(requestDate.currentDate).replace(/-/g, '/');
  const attractingMediaParam = getAttrMFromSessionStorage() || attractingMedia || null;

  const watchedFields = watch([
    'firstName',
    'lastName',
    'email',
    'telephone',
    'customerId',
    'entryCar',
    'agreement',
    'state',
    'city',
    'dealership'
  ]);

  const watchedState = watch('state');
  const watchedCity = watch('city');

  const resetForm = useCallback(() => {
    const result = {};
    reset(result);
  }, [reset]);

  const onError = (errors, e) => console.log(errors, e);

  function onBackForm() {
    setIsStatusComponent('default');
  }

  function validateForm() {
    const isFormEmpty = watchedFields.some(
      (value) => empty(value) || value === 'placeholder' || (typeof value === 'string' && value?.trim() === '')
    );
    !isFormEmpty ? setIsDisable(false) : setIsDisable(true);
  }

  function loadStates() {
    setListStates([...new Set(dealers.map((state) => state.address.state))].sort());
  }

  function loadCities(selectedState) {
    const filtered = dealers?.filter((state) => {
      return state?.address?.state.toUpperCase() === selectedState?.toUpperCase();
    });
    const resultCities = [...new Set(filtered.map((city) => city.address.city.toUpperCase()))].sort();

    setListCities(resultCities);
  }

  function loadDealers(selectedCity) {
    const filtered = dealers?.filter((dealer) => {
      return dealer?.address?.city.toUpperCase() === selectedCity?.toUpperCase();
    });
    setListDealers(filtered);
  }

  function onChangeState(e) {
    const selected = e.currentTarget.value;
    setValue('city', 'placeholder');
    setValue('dealership', 'placeholder');
    setValue('state', selected);
  }

  function onChangeCity(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', 'placeholder');
    setValue('city', selected);
  }

  function onChangeDealers(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', selected);
  }

  function generateCommentsByCheckboxes() {
    let comments = '';

    comments = getValues('client') ? t('label_client') : comments;
    comments = getValues('drive') ? `${comments}; ${t('label_drive')}` : comments;
    comments = getValues('finance') ? `${comments}; ${t('label_finance')}` : comments;
    comments = getValues('used_car') ? `${comments}; ${t('label_used_car')}` : comments;
    return comments;
  }

  useEffect(() => {
    validateForm();
  }, [watchedFields]);

  useEffect(() => {
    loadCities(watchedState);
  }, [watchedState]);

  useEffect(() => {
    loadDealers(watchedCity);
  }, [watchedCity]);

  useEffect(() => {
    loadStates();
  }, []);

  function getDealerSelect(selectedDealerName) {
    const selectedDealer = listDealers?.find(
      (dealer) => dealer?.name.toUpperCase() === selectedDealerName?.toUpperCase()
    );

    return selectedDealer;
  }

  function createUpdatedData(data) {
    const selectedDealer = getDealerSelect(data?.dealership);
    const selectedDealerDn = selectedDealer?.dn;

    data.concessionaireOfInterest = data?.dealership;
    data.dn = selectedDealerDn;
    data.requestDate = formatRequestDate;
    data.lastUpdateDate = formatRequestDate;
    data.type = '01 – VEÍCULO DE INTERESSE';
    data.salesforce = true;
    data.isToyotaCustomer = data.client;
    data.wantATestDrive = data.drive;
    data.wantToFinance = data.finance;
    data.usedVehicleInExchange = getValues('used_car');
    data.comments = generateCommentsByCheckboxes() || '';
    data.price = carModel?.price;
    data.quantity = 1;
    data.customerId = removeSpecialCharacters(data?.customerId);
    delete data.dealership;
    return data;
  }

  const handleResponse = (success) => {
    if (success) {
      setIsStatusComponent('success');
      resetForm(reset);
      return;
    }
    setIsStatusComponent('error');
    return;
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      const captchaPayload = await captchaToken('LEAD', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');

      if (!captchaPayload) {
        console.debug('Falhou verificação do recaptcha');
        handleResponse(false);
        return;
      }

      data.captchaPayload = captchaPayload;

      const updateData = createUpdatedData(data);

      const response = await sendRequest('POST', '/api/lead', updateData);
      handleResponse(response.ok && response.status === 200);
    } catch (error) {
      handleResponse(false);
      console.error('Erro durante o envio do formulário:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={`${css['molecule__build-a-car-form-container']} ${className}`} {...other}>
      <Script
        async
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>

      {isStatusComponent === 'success' && (
        <SuccessSubmitForm
          onBackForm={onBackForm}
          titleSuccess={titleSuccess ? titleSuccess : t('success_title')}
          contentSuccess={contentSuccess ? contentSuccess : t('success_content')}
          contentBackForm={t('content_back_form')}
          contentBackHome={t('content_back_home')}
        />
      )}
      {isStatusComponent === 'error' && (
        <SuccessSubmitForm
          onBackForm={onBackForm}
          titleSuccess={titleError ? titleError : t('error_title')}
          contentSuccess={contentError ? contentError : t('error_content')}
          contentBackForm={t('content_back_form')}
          contentBackHome={t('content_back_home')}
        />
      )}
      {isStatusComponent === 'default' && (
        <>
          <span className={css['info']}>{t('info_text_fields_form')}</span>

          <form className={css['form']} onSubmit={handleSubmit(onSubmit, onError)}>
            <span className={css['section-title']}>{t('title_personal_data')}</span>

            <FormInput
              displayLabel={true}
              className={css['input-container']}
              id="firstName"
              label={t('label_first_name')}
              placeholder={t('placeholder_first_name')}
              dataRegister={register('firstName')}
              dataErrors={errors['firstName']}
              maxLength="50"
              type="text"
              masking={(event) => {
                const value = event.target.value;
                event.target.value = isTextMask(value);
              }}
            />

            <FormInput
              displayLabel={true}
              className={css['input-container']}
              id="lastName"
              label={t('label_last_name')}
              placeholder={t('placeholder_last_name')}
              dataRegister={register('lastName')}
              dataErrors={errors['lastName']}
              type="text"
              maxLength="50"
              masking={(event) => {
                const value = event.target.value;
                event.target.value = isTextMask(value);
              }}
            />
            <FormInput
              displayLabel={true}
              className={css['input-last-container']}
              id="customerId"
              label={t('label_cpf')}
              placeholder={t('placeholder_cpf')}
              type="tel"
              dataRegister={register('customerId')}
              dataErrors={errors['customerId']}
              maxLength="14"
              masking={(event) => {
                const value = event.target.value;
                event.target.value = cpfMask(value);
              }}
            />

            <FormInput
              displayLabel={true}
              className={css['input-container']}
              id="email"
              label={t('label_email')}
              placeholder={t('placeholder_email')}
              dataRegister={register('email')}
              dataErrors={errors['email']}
              type="email"
              maxLength="50"
            />

            <FormInput
              displayLabel={true}
              className={css['input-container']}
              id="telephone"
              label={t('label_phone')}
              placeholder={t('placeholder_phone')}
              dataRegister={register('telephone')}
              dataErrors={errors['telephone']}
              type="tel"
              maxLength="15"
              masking={(event) => {
                const value = event.target.value;
                event.target.value = phoneMask(value);
              }}
            />

            <span className={css['section-title']}>{t('title_choose_dealership')}</span>

            <FormSelect
              className={css['input-container']}
              id="state"
              label={t('label_state')}
              placeholder={t('placeholder_state')}
              onChange={onChangeState}
              dataRegister={register('state')}
              dataErrors={errors['state']}
            >
              {listStates?.length > 0 &&
                listStates?.map((state, index) => {
                  return (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  );
                })}
            </FormSelect>
            <FormSelect
              className={css['input-container']}
              id="city"
              label={t('label_city')}
              placeholder={t('placeholder_city')}
              onChange={onChangeCity}
              dataRegister={register('city')}
              dataErrors={errors['city']}
            >
              {listCities?.length > 0 &&
                listCities?.map((city, index) => {
                  return (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  );
                })}
            </FormSelect>
            <FormSelect
              className={css['input-last-container']}
              id="dealership"
              label={t('label_dealership')}
              placeholder={t('placeholder_dealership')}
              onChange={onChangeDealers}
              dataRegister={register('dealership')}
              dataErrors={errors['dealership']}
            >
              {listDealers?.length > 0 &&
                listDealers?.map((dealer, index) => {
                  return (
                    <option key={index} value={`${dealer.name}`}>
                      {dealer.name.toUpperCase()}
                    </option>
                  );
                })}
            </FormSelect>

            <span className={css['section-title-needed']}>{t('title_select_needed')}</span>

            <FormCheckbox
              className={css['input-container']}
              id="client"
              label={t('label_client')}
              dataRegister={register('client')}
              dataErrors={errors['client']}
              disabled={isDisable}
            />
            <FormCheckbox
              className={css['input-container']}
              id="finance"
              label={t('label_finance')}
              dataRegister={register('finance')}
              dataErrors={errors['finance']}
              disabled={isDisable}
            />
            <FormCheckbox
              className={css['input-container']}
              id="used_car"
              label={t('label_used_car')}
              dataRegister={register('used_car')}
              dataErrors={errors['used_car']}
              disabled={isDisable}
            />
            <FormCheckbox
              className={css['input-container']}
              id="drive"
              label={t('label_drive')}
              dataRegister={register('drive')}
              placeholder=""
              dataErrors={errors['drive']}
              disabled={isDisable}
            />
            <ContentDivider className={css['form__divisor']} />

            <FormCheckbox
              id="agreement"
              disabled={isDisable}
              dataRegister={register('agreement')}
              dataErrors={errors['agreement']}
              className={css['input-last-container']}
            >
              {t('label_agreement')}
              <AnchorLink
                link="/politica-de-privacidade"
                hasArrow={false}
                color="gray-4"
                linkLabel={t('link_label_agreement')}
              />
            </FormCheckbox>

            <FormInput
              id="attractingMedia"
              type="hidden"
              dataRegister={register('attractingMedia')}
              value={attractingMediaParam}
              displayLabel={false}
              displayError={false}
              className={css['contact-form__field']}
            />

            <FormInput
              id="locale"
              type="hidden"
              dataRegister={register('locale')}
              value="pt-BR"
              displayLabel={false}
              displayError={false}
            />

            <FormInput
              id="katashikiCode"
              type="hidden"
              dataRegister={register('katashikiCode')}
              value={carModel?.modelYear?.katashiki}
              displayLabel={false}
              displayError={false}
            />

            <FormInput
              id="prodYear"
              type="hidden"
              dataRegister={register('prodYear')}
              value={carModel?.modelYear?.year}
              displayLabel={false}
              displayError={false}
            />

            <FormInput
              id="internalColorCode"
              type="hidden"
              dataRegister={register('internalColorCode')}
              value={carModel?.modelYear?.defaultInternalColorCode}
              displayLabel={false}
              displayError={false}
            />

            <FormInput
              id="internalColorName"
              type="hidden"
              dataRegister={register('internalColorName')}
              value={carModel?.modelYear?.defaultInternalColorCode}
              displayLabel={false}
              displayError={false}
            />

            <FormInput
              id="externalColorCode"
              type="hidden"
              dataRegister={register('externalColorCode')}
              value={carModel?.selectedColor?.color?.code}
              displayLabel={false}
              displayError={false}
            />

            <FormInput
              id="externalColorName"
              type="hidden"
              dataRegister={register('externalColorName')}
              value={carModel?.selectedColor?.color?.name}
              displayLabel={false}
              displayError={false}
            />

            <FormInput
              id="model"
              type="hidden"
              dataRegister={register('model')}
              value={carModel?.vehicle?.name}
              displayLabel={false}
              displayError={false}
            />

            <FormInput
              id="version"
              type="hidden"
              dataRegister={register('version')}
              value={carModel?.name}
              displayLabel={false}
              displayError={false}
            />

            <Button type="submit" disabled={!formState.isValid || isSubmitting}>
              {t('button_submit_form')}
            </Button>
          </form>
        </>
      )}
    </div>
  );
}

export default BuildACarForm;
