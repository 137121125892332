import * as React from 'react';

const SvgIconSuccessForm = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
<rect x="1" y="1" width="62" height="62" rx="31" stroke="#598F45" stroke-width="2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.8799 42.9717L18.9395 34.0313L21.0609 31.91L28.1681 39.0172L44.078 23.1073L45.9111 24.9405L27.8799 42.9717Z" fill="#598F45"/>
</svg>
);

export default SvgIconSuccessForm;

