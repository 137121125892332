import React from 'react';
import i18n from 'utils/i18n';
import { useRouter } from 'next/router';

import Button from 'components/atoms/button';
import StatusTag from 'components/atoms/status-tag';
import Title from 'components/atoms/title';
import { generateCertificate } from './generate-cetificate';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule RecallSearchData
 *
 */
function RecallSearchData(props) {
  const t = i18n.useTranslations('components.recall-search-data');

  const { className = '', children, locale, data, ...other } = props;
  const router = useRouter();

  function redirectToContactForm(chassiParam, campaignParam) {
    const query = {};

    if (chassiParam) {
      query.chassi = encodeURIComponent(chassiParam);
    }

    if (campaignParam) {
      query.campaign = encodeURIComponent(campaignParam);
    }
    router.push(
      {
        pathname: t('recall_contact_url'),
        query: query
      },
      t('recall_contact_url')
    );
  }

  return (
    <div className={`${css['molecule__recall-search-data-container']} ${className}`} {...other}>
      {data?.cardVariation == '1' && (
        <div className={css['recall-cards-container']}>
          {data?.fields?.title && (
            <Title level={1} variant={7}>
              {data.fields.title}
            </Title>
          )}

          <ul className={css['card-list']}>
            {data?.recallCampaigns?.map((recall, index) => (
              <li key={index} className={css['card']}>
                 <StatusTag type={data?.fields?.tag?.type}>{data?.fields?.tag?.text ?? null}</StatusTag>
                <div className={css['card-info']}>
                  {recall.name && (
                    <div className={css['card-text']}>
                      {data?.fields?.recall_title && <h2>{data.fields.recall_title}</h2>}
                      <p>{recall.name}</p>
                    </div>
                  )}


                <div className={css['container-links']}>
                  {recall?.riskLink && (
                    <div className={css['card-link']}>
                      <a href={data.riskLink}>{data?.fields?.risk_title_link}</a>
                      <img alt="download icon" src="/images/recall/icn-download.svg" />
                    </div>
                  )}

                  <Button
                    color="ghost-2"
                    onClick={() => {
                      redirectToContactForm(data?.vin, recall?.name);
                    }}
                  >
                    {t('request_contact')}
                    <img src="/images/recall/icn-arrow-right.svg" alt="arrow right" />
                  </Button>
                </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {data?.cardVariation == '2' && (
        <div className={css['recall-cards-container']}>
          {data?.fields?.title && (
            <Title level={1} variant={7}>
              {data.fields.title}
            </Title>
          )}

          <div className={css['card']}>
          <StatusTag type={data?.fields?.tag?.type}>{data?.fields?.tag?.text ?? null}</StatusTag>
            <div className={css['card-info']}>

              <div className={css['card-text-container']}>
                {data?.recall && (
                  <div className={css['card-text']}>
                    {data?.fields?.recall_title && <h2>{data.fields.recall_title}</h2>}
                    <p>{data?.recall}</p>
                  </div>
                )}

                {data?.dealer && (
                  <div className={css['card-text']}>
                    {data?.fields?.dealer_title && <h2>{data.fields.dealer_title}</h2>}
                    <p>{data?.dealer}</p>
                  </div>
                )}

                {data?.approvationDate && (
                  <div className={css['card-text']}>
                    {data?.fields?.date_title && <h2>{data.fields.date_title}</h2>}
                    <p>{data?.approvationDate}</p>
                  </div>
                )}
              </div>

              {(data?.riskLink || data?.repairLink) && (
                <div className={css['container-links']}>
                  {data?.riskLink && (
                    <div className={css['card-link']}>
                      {data?.fields?.risk_title_link && <a className={css['link-text']} href={data.riskLink}>{data.fields.risk_title_link}</a>}
                      <img alt="download icon" src="/images/recall/icn-download.svg" />
                    </div>
                  )}

                  {data?.repairLink && (
                    <button
                      onClick={() => generateCertificate(data?.repairLink)}
                      className={css['card-button-certificate']}
                    >
                      {data?.fields?.repair_title}
                      <img alt="download icon" src="/images/recall/icn-file.png" />
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {data?.cardVariation == '3' && (
        <div className={css['card-no-recall']}>
          <StatusTag type={data?.fields?.tag?.type}>{data?.fields?.tag?.text ?? null}</StatusTag>

          <div className={css['card-body']}>
          <div className={css['card-text-third-variation']}>
            {data?.fields?.title && (
              <h1>
                {data.fields.title}
              </h1>
            )}

            {data?.fields?.text && <ReactMarkdown className={css['markdown-text']} rehypePlugins={[rehypeRaw]}>{data.fields.text}</ReactMarkdown>}
          </div>

          {data?.showContactButton && (
            <div className={css['contact-button']}>
                          <Button
              color="ghost-2"
              className={css['card-button']}
              onClick={() => {
                redirectToContactForm(data?.vin, null);
              }}
            >
              {t('request_contact')}
              <img src="/images/recall/icn-arrow-right.svg" alt="arrow right" />
            </Button>
            </div>

          )}
          </div>

        </div>
      )}
    </div>
  );
}

export default RecallSearchData;
