import React from 'react';
import Title from 'components/atoms/title';
import ExpandedContent from 'components/molecules/expanded-content';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

// loading the sass style fot the component
import css from './styles.module.scss';
import { applyRichTextShortcodes } from 'utils/richText';

/**
 * Molecule DirectSalesFaq
 * 
 * 
 */
function DirectSalesFaq(props) {

  const {
    className = "",
    title,
    description,
    faqContent = [],
    ...other
  } = props;

  const { items } = faqContent;

  return <div
    className={`${css["molecule__direct-sales-faq-container"]} ${className}`}
    {...other}
  >
    {(title || description) && <header className={css['faq-list-header']}>
      {title && (
        <Title level={2} variant={2}>
          {title}
        </Title>
      )}

      {description && (
        <p className={css['faq-list-description']}>
          {description}
        </p>
      )}
    </header>}


    <ul className={css['faq-list-container']}>
      {items && items?.map((item, index) => (
        <li key={index} className={css['faq-list__item']}>
          <ExpandedContent
            className={css['expanded-content']}
            label={<div className={css['expanded-content-label']}>
              {item.media &&
                <img
                  className={css['logo']}
                  src={item.media.url}
                  alt={item.media.title || 'Imagem do item'}
                  role="presentation"
                />}
              <p>{item.title}</p>
            </div>}
            aria-expanded="false"
            role="button"
            aria-labelledby={`faq-item-${index}`}>
           {item.content && <div className={css['rich-text-preserve-styles']}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {applyRichTextShortcodes(item?.content)}
              </ReactMarkdown>
            </div>}
          </ExpandedContent>
        </li>
      ))}
    </ul>
  </div>;
}

export default DirectSalesFaq;
