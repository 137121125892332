import React from 'react';
import Button from 'components/atoms/button';
import Wrapper from 'components/atoms/wrapper';
import IconSuccessForm from 'components/icons/IconSuccessForm';
import IconErrorForm from 'components/icons/IconErrorForm';

//import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule ResultSubmitForm
 *
 * <!-- TODO: add a description here! -->
 */
function ResultSubmitForm(props) {
  //const gt = i18n.useTranslations('global');
  //const t = i18n.useTranslations('components.result-submit-form');

  const {
    className = '',
    status = 'success',
    onBackForm,
    titleResult,
    contentResult,
    contentBackForm,
    contentBackHome,
    spacing = '6',
    ...other
  } = props;

  return (
    <div className={`${css['molecule__result-submit-form-container']} ${className}`} {...other}>
      <Wrapper spacing={spacing}>
        <div className={css['result-container']}>
          {status === 'success' ? <IconSuccessForm /> : <IconErrorForm />}
          <div className={css['result-container__header']}>
            {titleResult && (
              <p className={css['result-text']}>
                {titleResult}
              </p>
            )}
            {contentResult && <p className={css['result-text']}>{contentResult}</p>}
          </div>
          <div className={css['result-container__buttons']}>
            {contentBackHome && (
              <Button color="default" link='/' className={css['button']}>
                {contentBackHome}
              </Button>
            )}
            {contentBackForm && onBackForm && (
              <Button color="ghost-2" onClick={onBackForm} className={css['button']}>
                {contentBackForm}
              </Button>
            )}

          </div>
        </div>
      </Wrapper>
    </div>
  );
}

export default ResultSubmitForm;
