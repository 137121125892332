/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';
import Button from 'components/atoms/button';
import CustomSelectorColor from 'components/atoms/custom-selector-color';
import InputNumber from 'components/atoms/input-number';
import Viwer360 from 'components/molecules/viwer-360';
import { useMediaQuery } from 'react-responsive';
import { currency } from 'utils/functions';
import css from './styles.module.scss';


function CarInfoCard(props) {

  const t = i18n.useTranslations('components.car-info-card');

  const {
    vehicleData = {},
    carList,
    setCarList,
    initialValue = 1,
    hasQuantity = true,
    hidePrice = false,
    locale = "pt-br",
    className = "",
    children,
    ...other
  } = props;

  const isTDB = String(locale).toUpperCase() === 'PT-BR';
  const currencySymbol = isTDB ? 'BRL' : 'USD';
  const [quantity, setQuantity] = useState(initialValue);
  const [selectedColor, setSelectedColor] = useState(vehicleData?.versions?.[0]?.modelYears?.[0]?.vehicleColors?.[0]?.color);
  const [selectedColorData, setSelectedColorData] = useState(vehicleData?.versions?.[0]?.modelYears?.[0]?.vehicleColors?.[0] || []);
  const defaultNotImage = '/images/image-car-not-available-text.webp';
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  useEffect(() => {
    if (carList?.length > 0) {
      console.log('Car list updated:', carList);
    }
  }, [carList]);
  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  const handleColorChange = (color) => {
    const newColorData = vehicleData?.versions?.[0]?.modelYears?.[0]?.vehicleColors?.find(c => c.color.code === color.code);
    if (newColorData) {
      setSelectedColor(newColorData.color);
      setSelectedColorData(newColorData);
    }
  };

  const get360Images = (selectedColor) => {
    const media = selectedColor?.modelYear?.medias || [];
    const selectedMediaDesktop360 = media?.find(m => m.tag.includes(`360:desk:${selectedColor.color.code}`));
    const selectedMediaMobile360 = media?.find(m => m.tag.includes(`360:mob:${selectedColor.color.code}`));
    return { selectedMediaDesktop360, selectedMediaMobile360 };
  };

  const getModelImages = (selectedColor) => {
    const media = selectedColor?.modelYear?.medias || [];
    const selectedMediaDesktop = media?.find(m => m.tag.includes(`model:desk:${selectedColor.color.code}`));
    const selectedMediaMobile = media?.find(m => m.tag.includes(`model:mob:${selectedColor.color.code}`));
    return { selectedMediaDesktop, selectedMediaMobile };
  };

  const { selectedMediaDesktop360, selectedMediaMobile360 } = get360Images(selectedColorData);
  const { selectedMediaDesktop, selectedMediaMobile } = getModelImages(selectedColorData);

  function filterMediasByColorCode(selectedColorData) {
    const colorCode = selectedColorData.color.code;

    console.log(selectedColor.code);
    const filteredMedias = selectedColorData.modelYear.medias.filter(media =>
      media.tag.includes(colorCode)
    );

    return filteredMedias;
  }
  const handleAddToList = () => {
    const idUnico = `${vehicleData?.id}-${vehicleData?.versionId}-${selectedColor?.code}`;

    const newCar = {
      idUnico,
      id: vehicleData?.id,
      versionId: vehicleData?.versionId,
      model: vehicleData?.name,
      version: vehicleData?.version,
      color: selectedColor,
      quantity: quantity,
      medias: filterMediasByColorCode(selectedColorData),
      price: selectedColorData?.price,
      afterPrice: selectedColorData?.offers?.afterPrice,
      beforePrice: selectedColorData?.offers?.beforePrice,
    };

    setCarList((prevList) => {
      const updatedList = [...prevList];
      const existingCarIndex = updatedList.findIndex(car => car.idUnico === idUnico);

      if (existingCarIndex !== -1) {
        updatedList[existingCarIndex].quantity += quantity;
      } else {
        updatedList.push(newCar);
      }

      return updatedList;
    });

    console.log(`Car added to list: ${quantity} x ${vehicleData?.name} - ${vehicleData?.version}`);
  };

  const handleShowCarInfo = () => {

    if (!vehicleData || !selectedColor) {
      console.error("Dados do veículo ou cor selecionada não estão disponíveis.");
      return;
    }

    const isMediasByColor = filterMediasByColorCode(selectedColorData);

    console.log('Car details:', {
      id: vehicleData?.id,
      model: vehicleData?.name,
      color: selectedColor,
      quantity: quantity,
      medias: isMediasByColor,
      price: selectedColorData?.price,
      afterPrice: selectedColorData?.offers?.afterPrice,
      beforePrice: selectedColorData?.offers?.beforePrice,
    });
  };

  return (
    <div className={`${css["molecule__car-info-card-container"]} ${className}`} {...other}>

      <h3 className={css["header-title"]}>{vehicleData?.name}</h3>

      <div className={`${css["container-image"]}`}>
        {selectedMediaDesktop360 && selectedMediaMobile360 ? (
          <Viwer360
            key={selectedColor.code}
            spritesheet={selectedMediaDesktop360?.media?.url || selectedMediaMobile360?.media?.url}
            spritesheetMob={selectedMediaMobile360?.media?.url || selectedMediaDesktop360?.media?.url}
          />
        ) : (
          <img
            src={selectedMediaDesktop?.media?.url || selectedMediaMobile?.media?.url || defaultNotImage}
            alt={selectedMediaMobile?.media?.title || selectedMediaMobile?.media?.title || 'Default'} />
        )}
      </div>
      <div className={`${css["container-selected"]}`}>
        <CustomSelectorColor
          listColors={vehicleData?.versions?.[0]?.modelYears?.[0]?.vehicleColors?.map(c => c?.color) || []}
          onColorChange={handleColorChange}
          selectedColor={selectedColor}
        />
      </div>

      <div className={css["container-prices"]}>
        <div className={css["info-prices"]}>
          {hidePrice ? (
            <div className={css["info-current-price"]}>
              <span className={css["info-no-price"]}>{t('text-no-price')}</span>
            </div>
          ) : selectedColorData?.offers?.beforePrice && selectedColorData?.offers?.afterPrice ? (
            <div className={css["info-prices-offers"]}>
              <div className={css["info-offers-before"]}>
                <span className={css["info-offers-before-text"]}>{t('before_text')}</span>
                <span className={css["info-offers-before-price"]}>
                  {currency(selectedColorData.offers.beforePrice, locale, currencySymbol)}
                </span>
              </div>
              <span className={css["info-offers-border"]}></span>
              <div className={css["info-offers-after"]}>
                <span className={css["info-offers-after-text"]}>{t('after_text')}</span>
                <span className={css["info-offers-after-price"]}>
                  {currency(selectedColorData.offers.afterPrice, locale, currencySymbol)}
                </span>
              </div>
            </div>
          ) : selectedColorData?.price ? (
            <div className={css["info-current-price"]}>
              <span className={css["info-price"]}>
                {currency(selectedColorData.price, locale, currencySymbol)}
              </span>
            </div>
          ) : (
            <div className={css["info-current-price"]}>
              <span className={css["info-no-price"]}>{t('text-no-price')}</span>
            </div>
          )}
        </div>
        <span className={css["info-card-border"]}></span>
        <div className={css["container-button-quantity"]}>
          {hasQuantity &&
            <InputNumber
              min={1}
              initialValue={quantity}
              incrementStep={1}
              decrementStep={1}
              className={css["input-number"]}
              value={quantity}
              onChange={handleQuantityChange}
            />}

          <Button type={hasQuantity} color='ghost-black' className={css["button-add-want"]} onClick={() => {
            hasQuantity
              ?
              handleAddToList()
              :
              handleShowCarInfo();
          }}>
            {hasQuantity
              ? (isMobile ? t('button_add_mobile') : t('button_add_desktop'))
              : t('button_want')}
          </Button>
        </div>

        <p className={css["disclaimer"]}>{t('disclaimer')}</p>

      </div>

      {children}
    </div >
  );
}

export default CarInfoCard;
