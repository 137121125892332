import React from 'react';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import DirectSalesFaq from 'components/molecules/direct-sales-faq';

/**
 * Organism FaqAccordion
 * 
 * <!-- TODO: add a description here! -->
 */
function FaqAccordion(props) {

  const {
    bgColor = 'gray',
    spacingTop = '6',
    spacingBottom = '6',
    title,
    description,
    faqContent,
    ...other
  } = props;

  return (
  <Wrapper bgColor={bgColor} data-spacing-top={spacingTop} data-spacing-bottom={spacingBottom} {...other}>
    <Section>
      <DirectSalesFaq
        title={title}
        description={description}
        faqContent={faqContent}
      />
    </Section>
  </Wrapper>
  );
}

export default FaqAccordion;
