import React from 'react';

import RecallList from 'components/molecules/recall-list';
import RecallSearch from 'components/molecules/recall-search';
import Wrapper from 'components/atoms/wrapper';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism Recall
 */
function Recall(props) {
  const { className = '', children, locale, recalls, searchSectionTitle, filterSectionTitle, ...other } = props;

  const showRecallList = recalls.length > 0;

  return (
    <Wrapper>
      <div className={`${css['organism__recall-container']} ${className}`} {...other}>
        <RecallSearch title={searchSectionTitle} locale={locale} />
        {showRecallList && <RecallList title={filterSectionTitle} recallData={recalls} locale={locale} />}
      </div>
    </Wrapper>
  );
}

export default Recall;
