import React, { useState, useEffect } from 'react';
import i18n from 'utils/i18n';
import { useUser } from '@auth0/nextjs-auth0';
import { useUserCustomer } from 'providers/user-customer';
import { useRouter } from 'next/router';

import states from 'public/docs/es-ar/states';
import {
  isBirthdayBeforeToday,
  isOver18Years,
  isTextMask,
  mapLocaleToCountry,
  isNumberMask,
  updatePageParam
} from 'utils/forms';
import { transformDate } from 'utils/functions';

import Button from 'components/atoms/button';
import FormInput from 'components/atoms/form-input';
import PhoneInput from 'components/atoms/phone-input';
import FormSelect from 'components/atoms/form-select';
import LoaderOverlay from 'components/molecules/loader-overlay';
import Notification from 'components/molecules/notification';
import RichTextSection from 'components/organisms/rich-text-section';
import Section from 'components/utils/section';
import Title from 'components/atoms/title';
import Wrapper from 'components/atoms/wrapper';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule Onelogin
 *
 * Onelogin registration screen
 */
function Onelogin(props) {
  const t = i18n.useTranslations('components.onelogin');
  const router = useRouter();
  const { updateUserData } = useUserCustomer();
  const { user } = useUser();

  const [isStatusComponent, setIsStatusComponent] = useState('loading');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const statesList = Object.keys(states);
  const [citiesList, setCitiesList] = useState([]);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [notificationKey, setNotificationKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [sessionStorageData, setSessionStorageData] = useState({});

  const {
    className = '',
    children,
    userRegisterData = {},
    isFirstAccess = true,
    oneLoginData,
    locale = 'es-ar',
    ...other
  } = props;

  const country = mapLocaleToCountry(locale);

  const validationSchema = yup
    .object()
    .shape({
      dni: yup
        .string()
        .required(t('error_message_required'))
        .min(6, t('error_message_min_char_dni'))
        .max(8, t('error_message_max_char_dni')),
      name: yup
        .string()
        .required(t('error_message_required'))
        .min(3, t('error_message_min_char_name'))
        .max(50, t('error_message_max_char_name'))
        .nullable(),
      lastName: yup
        .string()
        .required(t('error_message_required'))
        .min(3, t('error_message_min_char_last_name'))
        .max(50, t('error_message_max_char_last_name'))
        .nullable(),
      birthday: yup
        .string()
        .required(t('error_message_required'))
        .test('test-invalid-over18-years', t('error_message_invalid_birth_day_age'), (date) => isOver18Years(date))
        .test('test-invalid-birth-before-day', t('error_message_invalid_birth_day'), (date) =>
          isBirthdayBeforeToday(date)
        ),
      email: yup.string().required(t('error_message_required')).email(t('error_message_invalid_email')),
      cellphoneNumber: yup
        .string()
        .required(t('error_message_required'))
        .test('valid-cellphone-number', t('error_message_invalid_mobile_phone_prefix_min'), function (value) {
          if (!value) {
            return false;
          }
          const spaceIndex = value.indexOf(' ');
          if (spaceIndex === -1) {
            return false;
          }
          const rawNumber = value.slice(spaceIndex + 1);
          const cleanedNumber = rawNumber.replace(/\D/g, '');
          if (cleanedNumber.length < 8) {
            return false;
          }
          if (cleanedNumber.startsWith('0')) {
            return false;
          }
          if (cleanedNumber.startsWith('15')) {
            return false;
          }
          return true;
        })
        .test('only-numbers', t('error_message_phone_only_numbers'), function (value) {
          if (!value) {
            return false;
          }
          const spaceIndex = value.indexOf(' ');
          if (spaceIndex === -1) {
            return false;
          }
          const rawNumber = value.slice(spaceIndex + 1);
          const onlyNumbersRegex = /^\d+$/;
          return onlyNumbersRegex.test(rawNumber);
        })

        .test('max-length', t('error_message_phone_max'), function (value) {
          if (!value) {
            return false;
          }
          const spaceIndex = value.indexOf(' ');
          if (spaceIndex === -1) {
            return false;
          }
          const rawNumber = value.slice(spaceIndex + 1);
          const cleanedNumber = rawNumber.replace(/\D/g, '');
          return cleanedNumber.length <= 10;
        })
        .nullable(),
      state: yup.string().notOneOf(['placeholder'], t('error_message_required')),
      city: yup.string().notOneOf(['placeholder'], t('error_message_required')),
      home: yup.string().required(t('error_message_required')),
      postalCode: yup
        .string()
        .required(t('error_message_invalid_postal_code'))
        .matches(/^\d{4}$/, t('error_message_invalid_postal_code'))
        .nullable()
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState,
    formState: { errors },
    getValues
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      city: 'placeholder',
      state: 'placeholder',
      email: user?.email || '',
      cellphoneNumber: userRegisterData?.cellphoneNumber || '',
      formType: isFirstAccess ? 'CREATE_CUSTOMER' : 'UPDATE_CUSTOMER'
    }
  });

  useEffect(() => {
    const delay = 3000;
    setTimeout(() => {
      if (userRegisterData) {
        setValue('formType', isFirstAccess ? 'CREATE_CUSTOMER' : 'UPDATE_CUSTOMER');
        setValue('dni', userRegisterData?.dni || sessionStorageData?.dni || '');
        setValue('name', userRegisterData?.name || sessionStorageData?.name || '');
        setValue('lastName', userRegisterData?.lastName || sessionStorageData?.lastName || '');
        setValue('birthday', transformDate(userRegisterData?.birthday) || sessionStorageData?.birthday || '');
        setValue('email', user?.email || userRegisterData?.email);
        setValue('cellphoneNumber', userRegisterData?.cellphoneNumber || sessionStorageData?.cellphoneNumber || '');
        setValue('state', userRegisterData?.state || sessionStorageData?.state || 'placeholder');
        setValue('city', userRegisterData?.city || sessionStorageData?.city || 'placeholder');
        setValue('home', userRegisterData?.home || sessionStorageData?.home || '');
        setValue('postalCode', userRegisterData?.postalCode || sessionStorageData?.postalCode || '');
      }
      setIsStatusComponent('default');
    }, delay);
  }, [userRegisterData, sessionStorageData]);

  const watchState = watch('state');
  const watchCity = watch('city');
  const watchFormType = watch('formType');
  const isUpdateCustomer = watch('formType') === 'UPDATE_CUSTOMER';
  const watchPhone = watch('cellphoneNumber');

  const formTypeTexts = {
    CREATE_CUSTOMER: {
      title: oneLoginData?.titleCreateAccount || t('form_title_create_account'),
      description: oneLoginData?.descriptionCreateAccount || t('form_description_create_account'),
      legalText: `<h1>${t('form_legal_title_create_account')}</h1><p>${t('form_legal_text_create_account')}</p>`,
      buttonLabel: t('form_continue_button')
    },
    UPDATE_CUSTOMER: {
      title: oneLoginData?.titleMyAccount || t('form_title_my_account'),
      description: oneLoginData?.descriptionMyAccount || t('form_description_my_account'),
      legalText: `${t('form_legal_text_one_my_account')}
          <a href=${t('form_link_legal_text_my_account')}> ${t('form_label_link_legal_text_my_account')}</a>
         ${t('form_legal_text_two_my_account')}`,
      buttonLabel: t('form_update_keep_button')
    }
  };

  const { title, description, legalText, buttonLabel } = formTypeTexts[watchFormType] || {};

  const [notificationData, setNotificationData] = useState({
    content: '',
    title: '',
    type: '',
    isOpen: false
  });

  const handleCloseNotification = () => {
    setNotificationData({
      ...notificationData,
      isOpen: false
    });
  };

  useEffect(() => {
    setCitiesList(states[watchState]);
  }, [watchState]);

  useEffect(() => {
    setValue('city', userRegisterData?.city || sessionStorageData?.city || 'placeholder');
  }, [citiesList]);

  useEffect(() => {
    if (isUpdateCustomer) {
      const isAnyFieldEdited = Object.keys(formState.dirtyFields).some((field) => formState.dirtyFields[field]);
      setIsFormEdited(isAnyFieldEdited);
    }
  }, [formState]);

  useEffect(() => {
    checkAllFieldsFilled();
  });

  const resetFormStatusDefault = () => {
    setIsStatusComponent('default');
  };

  function scrollToTop() {
    const element = document.getElementById('main');

    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, 100);
    }
  }

  const handleContinueClick = async (event) => {
    event.preventDefault();

    if (isUpdateCustomer) {
      await handleSubmit(onSubmit, onError)();
    } else {
      trigger().then((isValid) => {
        if (isValid === true) {
          setIsStatusComponent('continue');
          scrollToTop();
          setValue('city', watchCity);
        } else {
          const fieldErrors = {};

          Object.keys(errors).forEach((fieldName) => {
            fieldErrors[fieldName] = errors[fieldName].message;
          });

          setFormErrors(fieldErrors);
          setIsStatusComponent('default');
        }
      });
    }
  };

  const checkAllFieldsFilled = () => {
    const formValues = watch();
    const isFilled = Object.keys(formValues).every((key) => {
      const value = formValues[key];
      return value !== '' && value !== null && value !== undefined;
    });

    setIsAllFieldsFilled(isFilled);
  };

  const handleSuccessNotification = () => {
    setNotificationKey(notificationKey + 1);
    setNotificationData({
      title: isUpdateCustomer
        ? oneLoginData?.titleNotificationSuccessUpdateAccount || t('form_submit_title_success_update_account')
        : oneLoginData?.titleNotificationSuccessCreateAccount || t('form_submit_title_success_create_account'),
      content: isUpdateCustomer
        ? oneLoginData?.contentNotificationSuccessUpdateAccount || t('form_submit_content_success_update_account')
        : oneLoginData?.contentNotificationSuccessCreateAccount || t('form_submit_content_success_create_account'),
      type: 'success',
      isOpen: true
    });
  };

  const handleErrorNotification = () => {
    setNotificationKey(notificationKey + 1);
    setNotificationData({
      title: isUpdateCustomer
        ? oneLoginData?.titleNotificationErrorUpdateAccount || t('form_submit_title_error_update_account')
        : oneLoginData?.titleNotificationErrorCreateAccount || t('form_submit_title_error_create_account'),
      content: isUpdateCustomer
        ? oneLoginData?.contentNotificationErrorUpadateAccount || t('form_submit_content_error_update_account')
        : oneLoginData?.contentNotificationErrorCreateAccount || t('form_submit_content_error_create_account'),
      type: 'error',
      isOpen: true
    });
  };

  const getSectionFromRouter = (router) => {
    return router?.query?.section || '';
  };

  /*
   * This function saves the filled data from the form for future filling.when the user
   * comes back.
   */
  function saveValuesOnSessionStorage() {
    const filledData = getValues();
    window.sessionStorage.setItem('saFormValues', JSON.stringify(filledData));
  }

  useEffect(() => {
    const currentSection = getSectionFromRouter(router);
    const shouldRender = false;

    if (isSubmitting && currentSection === 'account') {
      updatePageParam('section', 'vehicles', router, shouldRender);
    }
  }, [isSubmitting, router]);

  const onSubmit = async (data) => {
    setIsAllFieldsFilled(!isAllFieldsFilled);
    setIsLoading(true);

    const cleanedPhoneNumber = data.cellphoneNumber.replace(/\s/g, '');

    const updatedData = {
      birthday: data?.birthday,
      cellphoneNumber: cleanedPhoneNumber,
      city: data?.city,
      clubToyota: userRegisterData?.clubToyota || false,
      country: country,
      dni: data?.dni,
      email: data?.email,
      home: data?.home,
      lastName: data?.lastName,
      name: data?.name,
      postalCode: data?.postalCode || '00000000',
      state: data?.state,
      locale: locale
    };

    try {
      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedData)
      };

      const url = '/api/onelogin';

      const response = await fetch(url, opts);

      if (!response.ok) {
        handleErrorNotification();
        throw new Error('Request error. Please try again later.');
      }

      if (response?.status === 200) {
        const responseData = await response.json();

        if (typeof responseData !== 'object' || responseData === null) {
          handleErrorNotification();
          throw new Error('Invalid response format. Please try again later.');
        }

        if (Object.keys(responseData).length === 0) {
          handleErrorNotification();
          throw new Error('Empty response. Please check your input.');
        }

        if (responseData && Object.keys(responseData).length > 0) {
          handleSuccessNotification();

          setTimeout(async () => {
            const success = await updateUserData();

            if (success) {
              setIsSubmitting(true);
            }
          }, 3000);
        }
      } else {
        handleErrorNotification();
        throw new Error(`Request failed with status ${response.status}`);
      }
    } catch (error) {
      console.log(error);
      handleErrorNotification();
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (error) => {
    console.log('Error: ', error);
  };

  /*
   * This useEffect it's to get and format the data saved on the sessionStorage on the
   * correct execution time.
   */
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const data = JSON.parse(sessionStorage.getItem('saFormValues'));
      const shouldGetSessionStorageData =
        user?.email === data?.email || data?.email === '' || data?.email === undefined || data?.email === null;
      shouldGetSessionStorageData && setSessionStorageData(data);
    }
  }, [user]);

  return (
    <div className={`${css['molecule__onelogin-container']} ${className}`} {...other}>
      <Wrapper>
        <Section>
          <form
            onMouseLeave={saveValuesOnSessionStorage}
            onSubmit={handleSubmit(onSubmit, onError)}
            className={css['onelogin-form']}
          >
            {isStatusComponent === 'default' && (
              <>
                <Title level="1" variant={2} className={css['form-title']}>
                  {title}
                </Title>
                <span>{description}</span>

                <fieldset className={css['form-fields']}>
                  <FormInput
                    id="name"
                    type="text"
                    className={css['form-field']}
                    label={t('form_name_label')}
                    placeholder={t('form_name_placeholder')}
                    dataRegister={register('name')}
                    dataErrors={errors['name'] || formErrors.name}
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isTextMask(value);
                    }}
                  />

                  <FormInput
                    id="lastName"
                    type="text"
                    className={css['form-field']}
                    label={t('form_last_name_label')}
                    placeholder={t('form_last_name_placeholder')}
                    dataRegister={register('lastName')}
                    dataErrors={errors['lastName'] || formErrors.lastName}
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isTextMask(value);
                    }}
                  />

                  <FormInput
                    id="dni"
                    type="text"
                    className={css[isUpdateCustomer ? 'disabled-field' : 'form-field']}
                    label={t('form_dni_label')}
                    placeholder={t('form_dni_placeholder')}
                    dataRegister={register('dni')}
                    dataErrors={errors['dni'] || formErrors.dni}
                    disabled={isUpdateCustomer}
                    maxLength="8"
                    minLength="6"
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isNumberMask(value);
                    }}
                  />

                  <FormInput
                    id="birthday"
                    type="date"
                    className={css['form-field']}
                    label={t('form_birth_day_label')}
                    placeholder={t('form_birth_day_placeholder')}
                    dataRegister={register('birthday')}
                    dataErrors={errors['birthday'] || formErrors.birthday}
                    isLongMessage={true}
                  />

                  <FormInput
                    id="email"
                    type="text"
                    className={css['disabled-field']}
                    label={t('form_email_label')}
                    placeholder={t('form_email_placeholder')}
                    dataRegister={register('email')}
                    dataErrors={errors['email'] || formErrors.email}
                    disabled={true}
                  />

                  <PhoneInput
                    className={css['form-field-mobile']}
                    id="cellphoneNumber"
                    locale="ar"
                    defaultValue={watchPhone}
                    label={t('form_mobile_phone_label')}
                    placeholder={t('form_mobile_phone_placeholder')}
                    dataRegister={register('cellphoneNumber')}
                    dataErrors={
                      errors['cellphoneNumber'] || formErrors.cellphoneNumber || errors?.cellphoneNumber?.message
                    }
                    maxLength={16}
                  />
                </fieldset>

                <fieldset className={css['form-fields']}>
                  <FormSelect
                    id="state"
                    className={css['form-field']}
                    label={t('form_state_label')}
                    placeholder={t('form_state_placeholder')}
                    dataRegister={register('state')}
                    dataErrors={errors['state'] || formErrors.state}
                    defaultValue={userRegisterData?.state || 'placeholder'}
                  >
                    {statesList?.map((state, index) => {
                      return (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      );
                    })}
                  </FormSelect>

                  <FormSelect
                    id="city"
                    className={css['form-field']}
                    label={t('form_city_label')}
                    placeholder={t('form_city_placeholder')}
                    dataRegister={register('city')}
                    dataErrors={errors['city'] || formErrors.city}
                  >
                    {citiesList?.map((city, index) => {
                      return (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      );
                    })}
                  </FormSelect>

                  <FormInput
                    id="home"
                    type="text"
                    className={css['form-field']}
                    label={t('form_address_label')}
                    placeholder={t('form_address_placeholder')}
                    dataRegister={register('home')}
                    dataErrors={errors['home'] || formErrors.home}
                  />

                  <FormInput
                    id="postalCode"
                    type="text"
                    className={css['form-field']}
                    label={t('form_postal_code_label')}
                    placeholder={t('form_postal_code_placeholder')}
                    dataRegister={register('postalCode')}
                    dataErrors={errors['postalCode'] || formErrors.postalCode}
                    maxLength="4"
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isNumberMask(value);
                    }}
                  />
                </fieldset>

                <div>
                  {isUpdateCustomer && <RichTextSection textContent={legalText} isLegalText fullScreen={true} />}

                  <Button
                    type="button"
                    onClick={handleContinueClick}
                    className={css['form_button_submit']}
                    disabled={
                      !isAllFieldsFilled ||
                      Object.keys(errors).length > 0 ||
                      (isUpdateCustomer && (!isFormEdited || !isAllFieldsFilled))
                    }
                  >
                    {buttonLabel}
                  </Button>
                </div>
              </>
            )}
            {isStatusComponent === 'continue' && Object.keys(errors).length === 0  && (
              <div className={css['form_container_legal_text']}>
                <RichTextSection textContent={legalText} spacingBottom={3} fullScreen={true} />

                <div className={css['form_container_buttons']}>
                  <Button
                    className={css['form_button_submit']}
                    type="submit"
                    disabled={!isAllFieldsFilled || isLoading}
                  >
                    {isLoading ? t('form_accept_loader') : t('form_accept_button')}
                  </Button>
                  <Button className={css['form_button_go_back']} color="ghost-2" onClick={resetFormStatusDefault}>
                    {t('form_to_go_back')}
                  </Button>
                </div>
              </div>
            )}
            {isStatusComponent === 'loading' && <LoaderOverlay content={t('content_loading')} />}
          </form>
        </Section>
        {notificationData.isOpen && (
          <Notification
            key={notificationKey}
            spacing={4}
            className={css['notification']}
            fullScreen={false}
            contentNotification={notificationData.content}
            titleNotification={notificationData.title}
            typeNotification={notificationData.type}
            isOpen={notificationData.isOpen}
            handleClose={handleCloseNotification}
          />
        )}
      </Wrapper>
    </div>
  );
}

export default Onelogin;
