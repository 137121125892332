import React from 'react';
import i18n from 'utils/i18n';
import { useServiceAppointment } from 'providers/service-appointment';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { IconDelete, IconMySchedule, IconNewSchedule } from 'components/icons';
import Title from 'components/atoms/title';

// loading the sass style fot the component
import css from './styles.module.scss';

function CardMyVehicleDetails(props) {
  const t = i18n.useTranslations('components.card-my-vehicle-details');

  const { className = '', vehicle = {}, onOpenModalDelete, children, ...other } = props;
  const router = useRouter();
  const { dispatch } = useServiceAppointment();

  const servicesData = [
    {
      name: t('service_new_schedule'),
      icon: <IconNewSchedule />,
      param: {
        name: 'newSchedule',
        value: 'new_schedule'
      }
    },
    {
      name: t('service_my_schedule'),
      icon: <IconMySchedule />,
      param: {
        name: 'mySchedule',
        value: 'my_schedule'
      }
    }
    // This button is commented out as per the decision made in the task RWST-3052 (Jira) to hide the History button.
    // TASA requested this change for the production test in the week of 03/02/2025.
    // {
    //   name: t('service_history'),
    //   icon: <IconServiceHistory />,
    //   param: {
    //     name: 'history',
    //     value: 'history'
    //   }
    // }
  ];

  const buildVehicleInfoData = () => {
    return [
      {
        label: t('vehicle_patent'),
        value: vehicle?.patent
      },
      {
        label: t('vehicle_chassis'),
        value: vehicle?.chassi
      },
      {
        label: t('vehicle_year'),
        value: vehicle?.year
      }
    ];
  };

  function getImageUrl(vehicle, isMobile) {
    return (
      (isMobile && vehicle?.images?.small) || vehicle?.images?.large || '/images/my-vehicle/image-unavailable-tasa.png'
    );
  }

  const vehicleInfoData = buildVehicleInfoData();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const selectedImage = getImageUrl(vehicle, isMobile);

  const updateCustomerVehicle = () => {
    dispatch({ type: 'SET_CUSTOMER_VEHICLE_ID', payload: vehicle?.id });
    dispatch({ type: 'SET_SELECTED_VEHICLE_INFO', payload: vehicle });
  };

  const redirectAndUpdate = (paramName, paramValue) => {
    if (paramName === 'newSchedule') {
      updateCustomerVehicle();
    }

    router.push(
      {
        pathname: '/mi-toyota/login',
        query: { section: 'turnos', [paramName]: paramValue }
      },
      `/mi-toyota/login?section=turnos`
    );
  };

  return (
    <div className={`${css['molecule__card-my-vehicle-details-container']} ${className}`} {...other}>
      <div className={css['container-vehicle']}>
        <Title className={css['container-vehicle-title']} variant="3">
          {vehicle?.name}
        </Title>
        {selectedImage && (
          <img
            className={css['container-vehicle-image']}
            src={selectedImage}
            title={vehicle?.name || ''}
            alt={vehicle?.name || ''}
          />
        )}
      </div>
      <div className={css['container-info']}>
        <div className={css['container-info-list']}>
          {vehicleInfoData?.map(
            (info, index) =>
              info?.value && (
                <div className={css['container-info-list-item']} key={index}>
                  <p className={css['container-info-list-item-label']}>{info?.label}</p>
                  <p className={css['container-info-list-item-value']}>{info?.value}</p>
                </div>
              )
          )}
        </div>

        <div className={css['container-info-services']}>
          <Title className={css['container-info-services-title']}>{t('vehicle_services')}</Title>
          <div className={css['container-info-services-list']}>
            {servicesData?.map((service, index) => (
              <button
                className={css['container-info-services-button']}
                key={index}
                onClick={() => redirectAndUpdate(service?.param?.name, service?.param?.value)}
              >
                {service?.icon}
                <p className={css['container-info-services-button-name']}>{service?.name}</p>
              </button>
            ))}
          </div>
        </div>
      </div>

      <button className={css['button-delete']} type="button" onClick={() => onOpenModalDelete()}>
        <IconDelete />
      </button>
      {children}
    </div>
  );
}

export default CardMyVehicleDetails;
