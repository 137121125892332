import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useServiceAppointment } from 'providers/service-appointment';
import FormRadioGroup from 'components/atoms/form-radio-group';
import FormRadioButton from 'components/atoms/form-radio-button';
import Title from 'components/atoms/title';
import FormInput from 'components/atoms/form-input';
import Button from 'components/atoms/button';
import SimpleModal from 'components/molecules/simple-modal';
import { defaultCountriesCodes } from 'utils/__data__/default-countries-codes';
import { isNumberMask, phoneNoDddTasaMask, removeSpecialCharactersAndNumbers } from 'utils/forms';
import { empty, extractCountryCodes, removePhoneCodes } from 'utils/functions';
import css from './styles.module.scss';

function ServiceAppointmentAttendeeStep(props) {
  const t = i18n.useTranslations('components.service-appointment-attendee-step');
  const { state, dispatch } = useServiceAppointment();
  const { className = '', children, userRegisterData, onNextStep, ...other } = props;
  const [disabledFields, setDisabledFields] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormIncomplete, setIsFormIncomplete] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    attendee: yup.string(),
    firstName: yup
      .string()
      .trim()
      .required(t('message_error_required_firstName'))
      .min(2, t('message_error_min_char_firstName'))
      .max(40, t('message_error_max_char_firstName')),
    lastName: yup
      .string()
      .trim()
      .required(t('message_error_required_lastName'))
      .min(2, t('message_error_min_char_lastName'))
      .max(80, t('message_error_max_char_lastName')),
    email: yup
      .string()
      .required(t('message_error_required_email'))
      .email(t('message_error_invalid_email'))
      .max(80, t('message_error_max_char_email')),
    dni: yup
      .string()
      .required(t('message_error_required_dni'))
      .min(6, t('message_error_min_char_dni'))
      .max(8, t('message_error_max_char_dni')),
    mobile: yup
      .string()
      .required(t('message_error_required_mobile'))
      .test('test-invalid-phone', t('message_error_invalid_mobile'), (phone) => isNumberMask(phone))
      .min(8, t('message_error_min_char_mobile'))
      .test('no-ddd', t('message_error_invalid_mobile'), (value) => {
        if (!value) {
          return true;
        }
        return phoneNoDddTasaMask(value);
      })
  });

  const {
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
    clearErrors
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      attendee: 'Yo'
    }
  });

  const watchAttendee = watch('attendee');
  const watchedFields = watch([
    'firstName',
    'lastName',
    'email',
    'dni',
    'mobile'
  ]);

  function clearFields() {
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('email', '');
    setValue('dni', '');
    setValue('mobile', '');
  }

  function updateFieldsWithUserData() {
    const codesList = extractCountryCodes(defaultCountriesCodes);
    const phoneWithoutCode = removePhoneCodes(userRegisterData.cellphoneNumber, codesList);

    setValue('firstName', userRegisterData.name);
    setValue('lastName', userRegisterData.lastName);
    setValue('email', userRegisterData.email);
    setValue('dni', userRegisterData.dni);
    setValue('mobile', phoneWithoutCode);
  }

  function validateForm() {
    const isFormEmpty = watchedFields.some((value) => empty(value) || value === 'placeholder');
    isFormEmpty ? setIsFormIncomplete(true) : setIsFormIncomplete(false);
  }

  useEffect(() => {
    validateForm();
  }, [watchedFields]);
  useEffect(() => {
    if (watchAttendee === 'Yo' && userRegisterData) {
      clearErrors();
      updateFieldsWithUserData();
      setDisabledFields(true);
    }
    if (watchAttendee === 'Otra persona') {
      clearFields();
      setDisabledFields(false);
    }
  }, [userRegisterData, watchAttendee]);

  function dispatchNotification(type, content, title) {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        content,
        title,
        type,
        isOpen: true
      }
    });
  }

  function prepareAppointmentData(state) {
    return {
      openDate: state?.selectedSlot?.openDate,
      endDate: state?.selectedSlot?.endDate,
      orderId: state?.selectedSlot?.orderId,
      serviceAppointmentIdParent: state?.selectedSlot?.serviceAppointmentIdParent,
      waitForVehicle: state?.selectedWaitForVehicle === 'true',
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
      email: getValues('email'),
      dni: getValues('dni'),
      mobile: getValues('mobile')
    };
  }

  async function onConfirm() {
    setIsLoading(true);

    if (!state?.selectedSlot) {
      dispatchNotification('error', t('content_appointment_error'), t('title_appointment_error'));
      setIsLoading(false);
      return;
    }

    try {
      const data = prepareAppointmentData(state);

      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      const response = await fetch('/api/appointment', opts);

      const makeAppointment = await response.json();

      if (!response.ok || !makeAppointment.data.makeAppointment?.scheduledAppointment) {
        throw new Error('Failed to make appointment');
      }
      dispatch({ type: 'SET_MAKE_APPOINTMENT_COMPLETED', payload: true });
      dispatchNotification('success', t('content_appointment_success'), t('title_appointment_success'));
    } catch (error) {
      dispatchNotification('error', t('content_appointment_error'), t('title_appointment_error'));
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className={`${css['molecule__service-appointment-attendee-step-container']} ${className}`} {...other}>
      <FormRadioGroup
        name="attendee"
        className={css['radio-group']}
        label={t('radio_input_label')}
        dataRegister={register('attendee')}
        spacingGap="standard"
      >
        <FormRadioButton key="1" id="1" value={t('option_1')} defaultChecked>
          {t('option_1')}
        </FormRadioButton>
        <FormRadioButton key="2" id="2" value={t('option_2')}>
          {t('option_2')}
        </FormRadioButton>
      </FormRadioGroup>

      <Title>{watchAttendee === 'Yo' ? t('title') : t('title_other_person')}</Title>
      <div className={css['form']}>
        <FormInput
          id="firstName"
          className={disabledFields ? css['disabled'] : null}
          placeholder={t('firstName_placeholder')}
          label={t('firstName_label')}
          dataRegister={register('firstName')}
          dataErrors={errors['firstName']}
          maxLength="40"
          masking={(event) => {
            const value = event.target.value;
            event.target.value = removeSpecialCharactersAndNumbers(value);
          }}
        />

        <FormInput
          id="lastName"
          className={disabledFields ? css['disabled'] : null}
          placeholder={t('lastName_placeholder')}
          label={t('lastName_label')}
          dataRegister={register('lastName')}
          dataErrors={errors['lastName']}
          maxLength="80"
          masking={(event) => {
            const value = event.target.value;
            event.target.value = removeSpecialCharactersAndNumbers(value);
          }}
        />

        <FormInput
          id="email"
          className={disabledFields ? css['disabled'] : null}
          placeholder={t('email_placeholder')}
          label={t('email_label')}
          dataRegister={register('email')}
          dataErrors={errors['email']}
          maxLength="80"
        />

        <FormInput
          id="dni"
          className={disabledFields ? css['disabled'] : null}
          placeholder={t('dni_placeholder')}
          label={t('dni_label')}
          dataRegister={register('dni')}
          dataErrors={errors['dni']}
          maxLength="8"
          masking={(event) => {
            const value = event.target.value;
            event.target.value = isNumberMask(value);
          }}
        />

        <FormInput
          id="mobile"
          className={disabledFields ? css['disabled'] : null}
          placeholder={t('mobile_placeholder')}
          label={t('mobile_label')}
          dataRegister={register('mobile')}
          dataErrors={errors['mobile']}
          maxLength="10"
          masking={(event) => {
            const value = event.target.value;
            event.target.value = isNumberMask(value);
          }}
        />
      </div>
      <div className={css['buttons']}>
        <Button
          onClick={openModal}
          disabled={Object.keys(errors).length > 0 || isFormIncomplete || isLoading || isModalOpen}
        >
          {t('confirm_button')}
        </Button>
      </div>

      {isModalOpen && (
        <SimpleModal className={css['modal-container']} isOpen={isModalOpen} onClose={closeModal}>
          <Title variant={2}>{t('title_confirm_modal')}</Title>
          <div className={css['modal-container-content']}>{t('content_confirm_modal')}</div>
          <div className={css['modal-container-buttons']}>
            <Button onClick={closeModal} disabled={isLoading} color="ghost-2">
              {t('label_button_cancel')}
            </Button>
            <Button onClick={onConfirm} disabled={isLoading}>
              {t('label_button_confirm')}
            </Button>
          </div>
        </SimpleModal>
      )}
    </div>
  );
}

export default ServiceAppointmentAttendeeStep;
