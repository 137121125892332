import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import Title from 'components/atoms/title';
import React, { useState } from 'react';
import i18n from 'utils/i18n';
import { currency, currencyPriceWithoutTax } from 'utils/functions';

// loading the sass style fot the component
import css from './styles.module.scss';
import Viwer360 from 'components/molecules/viwer-360';
import ModelLegalText from 'components/molecules/model-legal-text';
import ThumbHover from 'components/atoms/thumb-hover';
import CarPaymentBanner from 'components/molecules/car-payment-banner';
import { useMediaQuery } from 'react-responsive';
import Modal from 'components/molecules/simple-modal';
/**
 * Molecule CarDetail
 *
 * <!-- TODO: add a description here! -->
 */
function CarDetail(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.car-detail');

  const {
    className = '',
    title = null,
    version,
    vehicleMedia = null,
    onlyDisplayBasePrice = false,
    showToyotaCicleBanner = false,
    locale,
    templateData,
    model = ''
  } = props;

  const [selectedColor, setSelectedColor] = React.useState(version?.display?.[0]);
  const country = String(locale).toLowerCase().substring(3);
  const displayLoan = country === 'br';
  const currencySymbol = country === 'br' ? 'BRL' : 'USD';
  const use360 = !version?.disabled360;
  const repurchaseManual = '/docs/manual-de-recompra.pdf';
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const removePricingOptions = templateData?.removeModelPrice;
  const [isLegalTextOpen, setIsLegalTextOpen] = useState(false);
  const images =
    (isTabletOrMobile ? selectedColor?.thumbHoverMobImages : selectedColor?.thumbHoverImages) ||
    selectedColor?.thumbHoverImages;
  const isTDV = String(locale).toLowerCase() === 'es-ve';
  const isTASA = String(locale).toLowerCase() === 'es-ar';


  return (
    <div className={`${css['molecule-car-detail-container']} ${className}`} data-grid>
      {title && (
        <div className={css['section-title-container']}>
          <Title variant={2}>
            <strong className={css['bold']}>{title}</strong>
          </Title>
        </div>
      )}

      <div className={css['image-container']}>
        {use360 && (
          <Viwer360
            key={selectedColor?.spriteDesktop}
            spritesheet={selectedColor?.spriteDesktop}
            spritesheetMob={selectedColor?.spriteMobile}
          />
        )}

        {!use360 && <ThumbHover image={images} />}
        <div className={css['container-color-list']}>
          <div className={css['container-color-list_select']}>
            <p className={css['select-color']}>{t('select_color')} </p>
            <ul className={css['color-list']}>
              {version?.display?.length > 0 &&
                version?.display?.map((color, index) => {
                  return (
                    <li key={`car-version-color-${index}`} className={css['color-listitem']}>
                      <button
                        key={index}
                        className={css['color-listcolor']}
                        data-is-selected={selectedColor?.code === color?.code ? true : undefined}
                        style={color.name ? { backgroundColor: color.rgb } : undefined}
                        onClick={(_) => setSelectedColor(color)}
                        title={color?.name}
                      ></button>
                    </li>
                  );
                })}
            </ul>
          </div>

          {selectedColor?.name && (
            <div className={css['container-color-active']}>
              <span className={css['name-color-active']}>{selectedColor?.name ? selectedColor?.name : null}</span>
            </div>
          )}
        </div>
      </div>

      {onlyDisplayBasePrice && (
        <div className={css['extra-info-container']}>
          <span className={css['extra-info__label']}>{t('price_title')} </span>
          <span className={css['extra-info__value']} data-testid="car-price">
            {!version?.hidePrice && currency(selectedColor?.price, locale, currencySymbol)}
          </span>
          <span className={css['extra-info__extra']}>Preço base: Brasília¹</span>
        </div>
      )}

      {displayLoan && !onlyDisplayBasePrice && (
        <>
          <div className={css['title-container']}>
            <Title variant={2}>
              {selectedColor?.installments} Parcelas de&nbsp;
              <strong className={css['emphasis']}>
                {!version?.hidePrice && currency(selectedColor?.installmentValue, locale, currencySymbol)}
              </strong>
            </Title>
          </div>

          <ul className={css['datails-list-container']}>
            <li className={css['datails-list-container__item']}>
              <span className={css['datails-list-container__label']}>Entrada de</span>
              <span className={css['datails-list-container__value']}>
                {!version?.hidePrice && currency(selectedColor?.downValue, locale, currencySymbol)}
              </span>
            </li>
            <li className={css['datails-list-container__item']}>
              <span className={css['datails-list-container__label']}>Parcela final de</span>
              <span className={css['datails-list-container__value']}>
                {!version?.hidePrice && currency(selectedColor?.residualValue, locale, currencySymbol)}
              </span>
              <span className={css['datails-list-container__extra']}>O seu usado como entrada de um Toyota 0 km</span>
            </li>
          </ul>

          <div className={css['extra-info-container']}>
            <span className={css['extra-info__label']}>{t('price_title')} </span>
            <span className={css['extra-info__value']} data-testid="car-price">
              {!version?.hidePrice && currency(selectedColor?.price, locale, currencySymbol)}
            </span>
            <span className={css['extra-info__extra']}>Preço base: Brasília¹</span>
          </div>

          {version?.fullpath && (
            <div className={css['first-button-container']}>
              <Button
                size={'large'}
                link={
                  isTDV
                    ? `/pide-tu-presupuesto?model=${model}&version=${version?.name}&color=${encodeURIComponent(
                        selectedColor?.name
                      )}`
                    : `${version?.fullpath}/monte?version=${version?.name}&color=${encodeURIComponent(
                        selectedColor?.name
                      )}`
                }
              >
                {t('want_it')}
              </Button>
              {version?.url_pdf && String(locale).toLowerCase() === 'pt-br' && (
                <Button color="ghost-2" size={'large'} link={version?.url_pdf} target="_blank">
                  {t('check_specs')}
                </Button>
              )}
            </div>
          )}

          {vehicleMedia && (
            <div className={css['second-button-container']}>
              <Button color={'ghost-2'} link={vehicleMedia.url} target="_blank">
                {vehicleMedia.title}
              </Button>
            </div>
          )}

          <ul className={css['links-list-container']}>
            <li className={css['links-list-container__item']}>
              <ModelLegalText
                vehicleName={version?.model}
                modelName={version?.name}
                colorName={selectedColor?.name}
                installmentsValue={selectedColor?.installmentValue}
                btbData={selectedColor?.btbData}
              />
            </li>
            <li className={css['links-list-container__item']}>
              <AnchorLink
                size="small"
                target="_blank"
                rel="noreferrer"
                link={repurchaseManual}
                linkLabel="Manual de recompra"
              />
            </li>
          </ul>
        </>
      )}

      {!displayLoan && (
        <>
          <div className={css['container-display-ar']} data-remove-pricing-options={removePricingOptions}>
            {version?.hidePrice ? (
              <div className={css['extra-info-container']}>
                <span className={css['extra-info__label']}>{t('check_availability')}</span>
              </div>
            ) : (
              <div className={css['extra-info-container']}>
              <div className={css['info-container']}>
              <span className={css['extra-info__label']}>
                  {t('price_title')}
                  {!isTDV && <sup>1</sup>}
                </span>
                <span className={css['extra-info__value']}>
                  {currency(selectedColor?.price, locale, currencySymbol)}
                </span>
              </div>
                {isTASA && <div className={css['info-container']}>
                 <span className={css['extra-info__label_without-tax']}>
                  {t('price_without_tax')}
                </span>
                <span className={css['extra-info__value_without-tax']}>
                  {currencyPriceWithoutTax(selectedColor?.priceWithoutTax)}
                </span>
                </div>}
              </div>
            )}
            <div className={css['container-display-ar__list-container']}>
              <div
                className={
                  isTDV
                    ? `${css['first-button-container']} ${css['first-button-container-width']}`
                    : css['first-button-container']
                }
              >
                <Button
                  size={'large'}
                  link={
                    isTDV
                      ? `/pide-tu-presupuesto?model=${model}&version=${version?.name}&color=${encodeURIComponent(
                          selectedColor?.name
                        )}`
                      : version?.vehicleExternalUrl
                  }
                >
                  {t('want_it')}
                </Button>
              </div>

              <ul className={css['links-list-container']}>
                {isTDV ? (
                  <li className={css['links-list-container__item']}>
                    <AnchorLink size="small" linkLabel={t('legal_text')} onClick={() => setIsLegalTextOpen(true)} />
                  </li>
                ) : (
                  <li className={css['links-list-container__item']}>
                    <AnchorLink
                      size="small"
                      link={`${t('link_contact_me')}/?model=${model}`}
                      linkLabel={t('contact_me')}
                    />
                  </li>
                )}
                {vehicleMedia && (
                  <li className={css['links-list-container__item']}>
                    <AnchorLink size="small" target="_blank" link={vehicleMedia.url} linkLabel={vehicleMedia.title} />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </>
      )}

      {showToyotaCicleBanner && (
        <CarPaymentBanner
          hasIcon
          hasExtraInfo
          btbData={selectedColor?.btbData}
          model={{
            vehicle: {
              name: version?.model
            },
            name: version?.name,
            selectedColor: { color: { name: selectedColor?.name } }
          }}
        />
      )}

      {templateData?.extraLinkTarget && (
        <Button size={'large'} link={templateData?.extraLinkTarget}>
          {templateData?.extraLinkLabel}
        </Button>
      )}

      {isLegalTextOpen && (
        <Modal
          className={css['modal-container-legal']}
          isOpen={isLegalTextOpen}
          onClose={() => setIsLegalTextOpen(false)}
        >
          <Title variant={2}>{templateData?.legalTitle ? templateData.legalTitle : t('legal_title')}</Title>
          <div className={css['modal-container-content-legal']}>
            {templateData?.legalDescription ? templateData.legalDescription : t('legal_text_modal')}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default CarDetail;
