import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom StatusTag
 *
 */
function StatusTag(props) {
  const { type, children, ...other } = props;

  const icon = {
    attention: { path: '/images/recall/icn-attention.svg', alt: 'attention icon' },
    success: { path: '/images/recall/icn-check.svg', alt: 'success icon' }
  };

  return (
    <div className={`${css['atom__status-tag-container']} ${children && css[type]}`} {...other}>
      <img src={icon[type].path || null} alt={icon[type].alt || null} />
      {children && <span>{children}</span>}
    </div>
  );
}

export default StatusTag;
