/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import i18n from 'utils/i18n';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';

import { IconFilter } from 'components/icons';

import css from './styles.module.scss';


/**
 * Molecule FilterGroupPanel
 *
 * <!-- TODO: add a description here! -->
 */
function FilterGroupPanel(props) {
  const t = i18n.useTranslations('components.filter-group-panel');

  const {
    className = "",
    vehiclesCategories = [],
    setFilters = () => { },
    onApplyFilters = () => { },
    clearFilters = () => { },
    children,
    ...other
  } = props;

  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});

  const handleFilterChange = (categoryId, vehicleId) => {
    setSelectedFilters((prevSelectedFilters) => {
      const updatedFilters = { ...prevSelectedFilters };

      if (updatedFilters[categoryId]?.includes(vehicleId)) {
        updatedFilters[categoryId] = updatedFilters[categoryId].filter((id) => id !== vehicleId);
        if (updatedFilters[categoryId].length === 0) {
          delete updatedFilters[categoryId];
        }
      } else {
        updatedFilters[categoryId] = [...(updatedFilters[categoryId] || []), vehicleId];
      }
      return updatedFilters;
    });
  };


  const applyFilters = () => {
    const filteredVehicleIds = Object.values(selectedFilters).flat();
    setFilters(filteredVehicleIds);
    onApplyFilters(filteredVehicleIds);
  };

  const handleClearFilters = () => {
    setSelectedFilters({});
    setFilters([]);
    clearFilters();
  };

  return (
    <div
      className={`${css["molecule__filter-group-panel-container"]} ${className}`}
      {...other}
    >
      {!showFilters ? (
        <Button
          className={css["mobile-filter-button"]}
          color="ghost-2"
          data-testid="filter-button"
          onClick={() => setShowFilters(true)}
        >
          <span>{t("label_show_filters")}</span>
          <IconFilter className={css["icon-filter"]} />
        </Button>
      ) : (
        <AnchorLink
          className={css["mobile-filter-button-back"]}
          linkLabel={t("label_back_filters")}
          arrowPosition="left"
          invertArrow={true}
          data-testid="back-filters-button"
          onClick={() => setShowFilters(false)}
        />
      )}

      <div className={`${css["container-filters"]} ${showFilters ? css["show"] : ""}`}>
        <div className={css["header-filters"]}>
          <h3 className={css["title-filters"]}>{t("title_filters")}</h3>
          <AnchorLink
            className={css["clear-button-filters"]}
            onClick={handleClearFilters}
            linkLabel={t("label_clear_button")}
            hasArrow={false}
            data-testid="clear-filters-button"
          />
        </div>

        <div className={css["container-categories"]}>
          {vehiclesCategories?.length > 0 && vehiclesCategories
            .filter(({ models }) => models?.length > 0)
            .map(({ id: categoryId, name, models }) => (
              <div key={categoryId} className={css["category-item"]}>
                <h3 className={css["category-item-title"]}>{name}</h3>
                {models.map(({ id: vehicleId, name: vehicleName }) => (
                  <label key={vehicleId} className={css["vehicle-item"]}>
                    <input
                      type="checkbox"
                      data-testid={`vehicle-${vehicleId}`}
                      checked={selectedFilters[categoryId]?.includes(vehicleId) || false}
                      onChange={() => handleFilterChange(categoryId, vehicleId)}
                    />
                    {vehicleName}
                  </label>
                ))}
              </div>
            ))}
        </div>

        <Button
          className={css["apply-button"]}
          color="ghost-black"
          data-testid="apply-button"
          onClick={applyFilters}
          disabled={Object.keys(selectedFilters).length === 0}
        >
          {t("label_apply_button")}
          {Object.values(selectedFilters).flat().length > 0 && (
            <span className={css["bullet"]}>{Object.values(selectedFilters).flat().length}</span>
          )}
        </Button>
      </div>
      {children}
    </div>
  );
}

export default FilterGroupPanel;
