import { checkAccessoryStatus, currency, dateBr2Iso, getAttrMFromSessionStorage, getDateAndTime } from 'utils/functions';
import { captchaToken } from 'utils/captcha/token';
import { phoneNumber, phoneMask, cpfMask, isValidCPF, phoneMaskTDV } from 'utils/validations';
import { removeSpecialCharacters, isTextMask } from 'utils/forms';
import { empty } from 'utils/functions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import FormCheckbox from 'components/atoms/form-checkbox';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import getConfig from 'next/config';
import i18n from 'utils/i18n';
import Media from 'components/molecules/media';
import Pill from 'components/atoms/pill';
import React, { useState, useEffect, useCallback } from 'react';
import Script from 'next/script';
import Section from 'components/utils/section';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import Title from 'components/atoms/title';

// loading the sass style fot the component
import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

/**
 * Molecule AccessoryForm
 *
 * Contact form
 */
function AccessoryForm(props) {
  const t = i18n.useTranslations('components.accessory-form');
  const {
    className = '',
    dealers = [],
    headerSpacing,
    accessory,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    attractingMedia = undefined,
    children,
    locale = 'pt-BR',
    ...other
  } = props;

  const attractingMediaParam = getAttrMFromSessionStorage() || attractingMedia || null;
  const isTasa = locale.toUpperCase() === 'ES-AR';

  const [originURL, setOriginUrl] = useState('');
  const [isDisable, setIsDisable] = useState(false);
  const [isFormIncomplete, setIsFormIncomplete] = useState(true);
  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);

  const requestDate = getDateAndTime();

  const formatRequestDate = dateBr2Iso(requestDate.currentDate).replace(/-/g, '/');

  let validationSchema = yup
    .object()
    .shape({
      name: yup.string().required(t('message_error_required_name')).min(3, t('message_error_min_char_name')),
      email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
      state: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required_state'))
        .required(t('message_error_required_state')),
      city: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required_city'))
        .required(t('message_error_required_city')),
      dealership: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required_dealership'))
        .required(t('message_error_required_dealership')),
      agreement: yup.boolean().oneOf([true], t('message_error_required_agreement'))
    })
    .required();

  if (locale === 'pt-BR') {
    validationSchema = yup
      .object()
      .shape({
        name: yup.string().trim().required(t('message_error_required_name')).min(3, t('message_error_min_char_name')),
        email: yup
          .string()
          .trim()
          .required(t('message_error_required_email'))
          .email(t('message_error_invalid_email'))
          .max(50, t('message_error_max_char_email')),
        state: yup
          .string()
          .notOneOf(['placeholder'], t('message_error_required_state'))
          .required(t('message_error_required_state')),
        city: yup
          .string()
          .notOneOf(['placeholder'], t('message_error_required_city'))
          .required(t('message_error_required_city')),
        dealership: yup
          .string()
          .notOneOf(['placeholder'], t('message_error_required_dealership'))
          .required(t('message_error_required_dealership')),
        agreement: yup.boolean().oneOf([true], t('message_error_required_agreement')),
        phone: yup
          .string()
          .required(t('message_error_required_phone'))
          .matches(phoneNumber, t('message_error_invalid_phone'))
          .min(14, t('message_error_min_char_phone')),
        lastName: yup
          .string()
          .trim()
          .required(t('message_error_required_lastName'))
          .min(3, t('message_error_min_char_lastName')),
        customerId: yup
          .string()
          .required(t('message_error_required_customerId'))
          .test('test-invalid-cpf', t('message_error_invalid_cpf'), (cpf) => isValidCPF(cpf))
      })
      .required();
  }

  if (locale === 'es-VE') {
    validationSchema = yup
      .object()
      .shape({
        name: yup.string().trim().required(t('message_error_required_name')),
        email: yup.string().trim().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
        state: yup
          .string()
          .notOneOf(['placeholder'], t('message_error_required_state'))
          .required(t('message_error_required_state')),
        city: yup
          .string()
          .notOneOf(['placeholder'], t('message_error_required_city'))
          .required(t('message_error_required_city')),
        dealership: yup
          .string()
          .notOneOf(['placeholder'], t('message_error_required_dealership'))
          .required(t('message_error_required_dealership')),
        agreement: yup.boolean().oneOf([true], ''),
        phone: yup
          .string()
          .required(t('message_error_required_phone'))
          .test('is-11-digits', t('message_error_min_char_phone'), (value) => value.length === 16),
        lastName: yup.string().trim().required(t('message_error_required_lastName'))
      })
      .required();
  }

  const {
    register,
    reset,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema)
  });

  const watchedFieldsTDB = watch([
    'name',
    'lastName',
    'customerId',
    'email',
    'phone',
    'state',
    'city',
    'dealership',
    'agreement'
  ]);

  const watchedFieldsTASA = watch([
    'name',
    'email',
    'state',
    'city',
    'dealership',
    'agreement'
  ]);

  const watchedFieldsTDV = watch([
    'name',
    'lastName',
    'email',
    'state',
    'city',
    'dealership',
    'agreement'
  ]);

  const watchAgreement = watch('agreement');
  const watchState = watch('state');
  const watchdCity = watch('city');

  const currencySymbol = locale === 'pt-BR' ? 'BRL' : isTasa ? 'ARS' : 'USD';
  function validateForm() {
    let isFormEmpty;

    if (locale === 'pt-BR') {
      isFormEmpty = watchedFieldsTDB.some((value) => empty(value) || value === 'placeholder');
    }
    if (isTasa) {
      isFormEmpty = watchedFieldsTASA.some((value) => empty(value) || value === 'placeholder');
    }
    if (locale === 'es-VE') {
      isFormEmpty = watchedFieldsTDV.some((value) => empty(value) || value === 'placeholder');
    }

    isFormEmpty || watchAgreement === false ? setIsFormIncomplete(true) : setIsFormIncomplete(false);
  }

  function getOriginalUrl() {
    if (typeof window === 'undefined') {
      return '';
    }
    const baseURL = new URL(document.location).origin.replace(/^https?:\/\//, '');
    setOriginUrl(baseURL);
  }

  useEffect(() => {
    validateForm();
  }, [watchedFieldsTDB, watchedFieldsTASA]);

  const resetForm = useCallback(() => {
    const resultDataFields = {
      name: '',
      lastName: '',
      customerId: '',
      email: '',
      phone: '',
      state: 'placeholder',
      city: 'placeholder',
      dealership: 'placeholder',
      agreement: false
    };
    reset(resultDataFields);
  }, [reset]);

  function onBackForm() {
    setIsStatusComponent('default');
  }

  function loadStates() {
    setListStates([...new Set(dealers.map((state) => state?.address?.state))].sort());
  }

  useEffect(() => {
    loadCities(watchState);
  }, [watchState]);

  useEffect(() => {
    loadDealers(watchdCity);
  }, [watchdCity]);

  useEffect(() => {
    getOriginalUrl();
  }, []);

  function loadCities(selectedState) {
    const filtered = dealers?.filter((state) => {
      return state?.address?.state.toUpperCase() == selectedState?.toUpperCase();
    });
    const resultCities = [...new Set(filtered.map((city) => city?.address?.city.toUpperCase()))].sort();

    setListCities(resultCities);
  }

  function loadDealers(selectedCity) {
    if (selectedCity) {
      const filtered = dealers?.filter((dealer) => {
        return dealer?.address?.city.toUpperCase() == selectedCity.toUpperCase();
      });
      setListDealers(filtered);
    }
  }

  function onChangeState(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', 'placeholder');
    setValue('city', 'placeholder');
    setListDealers([]);
    setValue('state', selected);
  }

  function onChangeCity(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', 'placeholder');
    setValue('city', selected);
  }

  function onChangeDealers(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', selected);
  }

  function getSelectedDealerData(data) {
    if (!data?.dealership) {
      return null;
    }

    return dealers?.find((v) => v.name === data.dealership);
  }

  function onError(error) {
    console.log('Error: ', error);
  }

  function formatRequestData(locale, data) {
    const dealer = getSelectedDealerData(data);
    switch (locale) {
      case 'es-AR':
        return {
          locale: locale,
          type: 'ACCESSORY_SALES',
          attractingMedia: attractingMediaParam,
          personalName: data?.name,
          email: data?.email,
          personalPhone: data?.phone,
          dealershipCity: data?.city,
          dealershipState: data?.state,
          dealershipName: data?.dealership,
          dealershipDn: dealer?.dn,
          accessorySalesEmail: dealer?.accessorySalesEmail,
          dealerId: dealer?.id,
          vehicleColor: accessory?.modelYears[0]?.color?.[0]?.name,
          vehicleModel: accessory?.model?.name,
          vehicleVersion: accessory?.model?.versionName,
          vehicleModelYear: accessory?.modelYears[0]?.year,
          katashikiCode: accessory?.modelYears[0]?.katashiki,
          accessoryName: accessory?.name,
          accessoryPartNumber: accessory?.partNumber,
          accessoryPrice: currency(accessory?.price?.value, locale, currencySymbol)
        };
      case 'pt-BR':
        return {
          locale: locale,
          type: '02 – VEÍCULO ATUAL',
          salesforce: true,
          attractingMedia: attractingMediaParam,
          model: accessory?.model?.name,
          version: accessory?.model?.versionName,
          katashikiCode: accessory?.modelYears[0]?.katashiki,
          suffixCode: accessory?.modelYears[0]?.suffixCode,
          prodYear: accessory?.modelYears[0]?.year,
          internalColor: {
            code: accessory?.modelYears[0]?.defaultInternalColorCode,
            name: accessory?.modelYears[0]?.defaultInternalColorCode
          },
          externalColor: {
            code: accessory?.modelYears[0]?.vehicleColors[0]?.color?.code,
            name: accessory?.modelYears[0]?.vehicleColors[0]?.color?.name
          },
          state: data?.state,
          price: accessory?.modelYears[0]?.vehicleColors[0]?.price,
          quantity: 1,
          city: data?.city,
          name: data?.name,
          lastName: data?.lastName,
          customerId: removeSpecialCharacters(data?.customerId),
          email: data?.email,
          telephone: data?.phone,
          concessionaireOfInterest: data?.dealership,
          lastUpdateDate: formatRequestDate,
          requestDate: formatRequestDate,
          dn: dealer?.dn,
          comments: data?.comments || '',
          accessories: {
            code: accessory?.partNumber,
            color: '',
            name: accessory?.name,
            quantity: 1,
            value: accessory?.price?.value
          }
        };
      case 'es-VE':
        return {
          locale: locale,
          type: 'ACCESSORIES',
          name: data?.name,
          surname: data?.lastName,
          email: data?.email,
          phone: removeSpecialCharacters(data?.phone).replace(/\s+/g, ''),
          state: data?.state,
          city: data?.city,
          dealerId: dealer?.id
        };
    }
  }

  const onSubmit = async (data) => {
    setIsDisable(true);

    const dataSource = formatRequestData(locale, data);

    let url = '/api/contact';
    let formData = { ...dataSource, type: dataSource?.type };
    let formSubmissionType = 'CONTACT';

    if (locale === 'pt-BR') {
      url = '/api/lead';
      formSubmissionType = 'LEAD';
    }

    const captchaPayload = await captchaToken(formSubmissionType, publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsStatusComponent('error');
      setIsDisable(true);

      return false;
    }

    formData.captchaPayload = captchaPayload;

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    };
    fetch(url, opts)
      .then((res) => {
        if (res.status === 200) {
          setIsDisable(false);
          resetForm();
          setIsStatusComponent('success');
          return;
        }
        setIsDisable(false);
        setIsStatusComponent('error');
      })
      .catch((err) => {
        setIsDisable(false);
        setIsStatusComponent('error');
        console.log(err);
      });
  };
  return (
    <>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>
      <Section hasGrid className={`${css['molecule__accessory-form-container']} ${className}`} {...other}>
        <div className={css['container-one']}>
          {accessory?.category?.name && <Pill label={accessory?.category?.name} isButton={false} isSelected />}

          <Title level={1} variant={4}>
            {accessory?.name}
          </Title>

          <div className={css['container-one__info-container']}>
            {accessory?.model?.name && (
              <strong className={css['container-one__info-container__about']}>
                {`${accessory?.model?.name} ${accessory?.model?.versionName}`}
              </strong>
            )}
            <span className={css['container-one__info-container__extra']}>{accessory?.partNumber}</span>
          </div>

          {accessory?.media?.url && (
            <Media url={accessory?.media?.url} type="img" alt={accessory?.media?.title || ''} />
          )}

          {accessory?.downloadMedias && (
            <ul className={css['links-list-container']}>
              {accessory.downloadMedias.map((media, index) => {
                return (
                  <li key={index} className={css['links-list-container__item']}>
                    <AnchorLink
                      size={'small'}
                      target="_blank"
                      rel="noreferrer"
                      title={media?.description}
                      linkLabel={media?.title}
                      link={media?.url}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <div className={css['container-two']}>
          <div className={css['container-two__info-container']}>
            {!isTasa
              &&
              <Title level={2} variant="4">
                {checkAccessoryStatus(
                  accessory?.status,
                  currency(accessory?.price?.value, locale, currencySymbol),
                  t('accessory-status-unavailable')
                )}

              </Title>
            }
            {isTasa && (
              <div className={css['accessory-prices-container']}>
                {[
                  { title: t("final-price"), price: accessory?.price?.value, className: css['info-final-price'] },
                  { title: t("price-without-national-taxes"), price: accessory?.price?.valueWithoutTax, className: css['info-without-tax'] },
                ]
                  .map(({ title, price, className }) => (
                    <div key={title} className={className}>
                      <h4>{title}</h4>
                      <p>{currency(price, locale, currencySymbol)}</p>
                    </div>
                  ))}
              </div>
            )}

            <p className={`${css['container-two__info-container__notice']} ${isTasa ? css['bold-black-text'] : ''}`}>
              {isTasa && '*'}
              {accessory?.summary}
            </p>

            {locale === 'pt-BR' && (
              <>
                <Button
                  link={`https://www.pecasredetoyota.com.br/?origin=${originURL}&partno=${accessory?.partNumber}`}
                  className={css['container-two__info-container__button']}
                >
                  {t('buy_button_label')}
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5663 8.1V12H3.33301V3.76667H7.23301" stroke="currentColor" />
                    <path
                      d="M7.2334 8.1L12.0001 3.33333M12.0001 3.33333H8.96673M12.0001 3.33333V6.36667"
                      stroke="currentColor"
                    />
                  </svg>
                </Button>

                <span className={css['separator']}>ou</span>
              </>
            )}
          </div>

          <form className={css['contact-form']} onSubmit={handleSubmit(onSubmit, onError)}>
            {isStatusComponent === 'success' && (
              <SuccessSubmitForm
                onBackForm={onBackForm}
                titleSuccess={titleSuccess ? titleSuccess : t('success_title')}
                contentSuccess={contentSuccess ? contentSuccess : t('success_content')}
                contentBackForm={t('content_back_form')}
                contentBackHome={t('content_back_home')}
              />
            )}

            {isStatusComponent === 'error' && (
              <SuccessSubmitForm
                onBackForm={onBackForm}
                titleSuccess={titleError ? titleError : t('error_title')}
                contentSuccess={contentError ? contentError : t('error_content')}
                contentBackForm={t('content_back_form')}
                contentBackHome={t('content_back_home')}
              />
            )}
            {isStatusComponent === 'default' && (
              <>
                <div className={css['success-container']}>
                  <Title level={2} variant="5" className={css['contact-form__title']}>
                    {t('form_title')}
                  </Title>

                  <FormInput
                    id="name"
                    label={t('label_user_name_field')}
                    placeholder={t('placeholder_user_name_field')}
                    dataErrors={errors['name']}
                    type="text"
                    dataRegister={register('name')}
                    displayLabel={true}
                    className={css['contact-form__field']}
                    maxLength={locale === 'es-VE' ? '40' : '50'}
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isTextMask(value);
                    }}
                  />
                  {locale === 'pt-BR' && (
                    <>
                      <FormInput
                        id="lastName"
                        label={t('label_user_lastName_field')}
                        placeholder={t('placeholder_user_lastName_field')}
                        dataErrors={errors['lastName']}
                        type="text"
                        dataRegister={register('lastName')}
                        displayLabel={true}
                        className={css['contact-form__field']}
                        masking={(event) => {
                          const value = event.target.value;
                          event.target.value = isTextMask(value);
                        }}
                        maxLength="50"
                      />
                      <FormInput
                        id="customerId"
                        label={t('label_user_customerId_field')}
                        placeholder={t('placeholder_user_customerId_field')}
                        dataErrors={errors['customerId']}
                        dataRegister={register('customerId')}
                        displayLabel={true}
                        className={css['contact-form__field']}
                        maxLength="14"
                        type="tel"
                        masking={(event) => {
                          const value = event.target.value;
                          event.target.value = cpfMask(value);
                        }}
                      />
                    </>
                  )}

                  {locale === 'es-VE' && (
                    <FormInput
                      id="lastName"
                      label={t('label_user_lastName_field')}
                      placeholder={t('placeholder_user_lastName_field')}
                      dataErrors={errors['lastName']}
                      type="text"
                      dataRegister={register('lastName')}
                      displayLabel={true}
                      className={css['contact-form__field']}
                      masking={(event) => {
                        const value = event.target.value;
                        event.target.value = isTextMask(value);
                      }}
                      maxLength="80"
                    />
                  )}

                  <FormInput
                    id="phone"
                    label={t('label_phone_field')}
                    dataErrors={errors['phone']}
                    type="tel"
                    maxLength="15"
                    placeholder={t('placeholder_phone')}
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = locale === 'es-VE' ? phoneMaskTDV(value) : phoneMask(value);
                    }}
                    dataRegister={register('phone')}
                    displayLabel={true}
                    className={css['contact-form__field']}
                  />
                  <FormInput
                    id="email"
                    label={t('label-email')}
                    dataErrors={errors['email']}
                    type="text"
                    placeholder={t('placeholder-email')}
                    dataRegister={register('email')}
                    displayLabel={true}
                    maxLength={locale === 'es-VE' ? '200' : '50'}
                    className={css['contact-form__field']}
                  />

                  <legend className={css['contact-form_title_section_dealership']}>
                    {t('title_section_dealership')}
                  </legend>
                  <FormSelect
                    id="state"
                    label={t('label_state')}
                    placeholder={t('placeholder_state')}
                    dataErrors={errors['state']}
                    dataRegister={register('state')}
                    onFocus={loadStates}
                    onChange={onChangeState}
                    displayLabel={true}
                    className={css['contact-form__field_state']}
                  >
                    {listStates.length > 0 &&
                      listStates?.map((state, index) => {
                        return (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        );
                      })}
                  </FormSelect>

                  <FormSelect
                    id="city"
                    label={t('label_city')}
                    placeholder={t('placeholder_city')}
                    dataErrors={errors['city']}
                    dataRegister={register('city')}
                    onChange={onChangeCity}
                    displayLabel={true}
                    className={css['contact-form__field_city']}
                  >
                    {watchState &&
                      listCities.length > 0 &&
                      listCities?.map((city, index) => {
                        return (
                          <option key={index} value={city}>
                            {city}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    id="dealership"
                    label={t('label_dealership')}
                    placeholder={t('placeholder_dealership')}
                    dataErrors={errors['dealership']}
                    dataRegister={register('dealership')}
                    onChange={onChangeDealers}
                    displayLabel={true}
                    className={css['contact-form__field_dealer']}
                  >
                    {watchdCity &&
                      listDealers.length > 0 &&
                      listDealers?.map((dealer, index) => {
                        return (
                          <option key={`dealer-${index}`} value={dealer.name}>
                            {dealer.name.toUpperCase()}
                          </option>
                        );
                      })}
                  </FormSelect>

                  <FormCheckbox
                    id="agreement"
                    dataErrors={errors['agreement']}
                    className={css['contact-form__agreement']}
                    dataRegister={register('agreement')}
                  >
                    {t('label_agreement')}&nbsp;
                    <AnchorLink
                      link={locale === 'pt-br' ? '/politica-de-privacidade' : '/politica-de-privacidad'}
                      hasArrow={false}
                      linkLabel={t('link_label_agreement')}
                    />
                  </FormCheckbox>

                  <Button
                    type="submit"
                    disabled={Object.keys(errors).length > 0 || isFormIncomplete || isDisable}
                    className={css['contact-form_submit_button']}
                  >
                    {t('button_submit_form')}
                  </Button>
                </div>
              </>
            )}
          </form>
        </div>
      </Section>
    </>
  );
}

export default AccessoryForm;
